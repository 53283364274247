import {
  IconButton,
  Switch,
  TableCell,
  TableRow,
  TextField,
} from "@material-ui/core";
import React, { useState } from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import ConfirmationDialogActions from "../../Shared/ConfirmationDialogActions";
import CircleColourPicker from "../../Shared/ColourPicker/CircleColourPicker";
const MediaSuppliersTableRow = ({
  supplier,
  handleChangeSupplier,
  archiveSuppplier,
}) => {
  const [showArchiveSupplier, setShowArchiveSupplier] = useState(false);
  const handleChange = (field, value) => {
    const _supplier = { ...supplier, [field]: value };
    handleChangeSupplier(_supplier);
  };

  const handleConfirmArchive = async () => {
    await archiveSuppplier(supplier.id);
    setShowArchiveSupplier(false);
  };
  return (
    <>
      {showArchiveSupplier && (
        <ConfirmationDialogActions
          title={"Are you sure?"}
          open={showArchiveSupplier}
          contentText={"This action will archive the supplier."}
          button1Action={() => setShowArchiveSupplier(false)}
          button1Text={"Cancel"}
          button2Text={"Confirm"}
          button2Action={handleConfirmArchive}
        />
      )}
      <TableRow>
        <TableCell className="add-background text-alignment">
          <TextField
            value={supplier.supplierName}
            fullWidth
            size="small"
            onChange={(e) => handleChange("supplierName", e.target.value)}
            InputLabelProps={{ shrink: true }}
          />
        </TableCell>
        <TableCell className="add-background text-alignment" align="center">
          <Switch
            size="small"
            color="primary"
            checked={
              supplier.marginRebate !== undefined &&
              supplier.marginRebate !== null
            }
            onChange={(e) => {
              e.target.checked
                ? handleChange("marginRebate", 10)
                : handleChange("marginRebate", null);
            }}
          />
        </TableCell>
        <TableCell className="add-background text-alignment">
          <TextField
            value={supplier.marginRebate === null ? "" : supplier.marginRebate}
            size="small"
            onChange={(e) => handleChange("marginRebate", e.target.value)}
            fullWidth
            disabled={
              supplier.marginRebate === undefined ||
              supplier.marginRebate === null
            }
            InputLabelProps={{ shrink: true }}
          />
        </TableCell>
        <TableCell className="add-background text-alignment" align="center">
          <Switch
            size="small"
            color="primary"
            checked={
              supplier.xeroGstRateCode === "INPUT2" ||
              supplier.xeroGstRateCode === "OUTPUT2"
            }
            onChange={(e) =>
              handleChange(
                "xeroGstRateCode",
                e.target.checked ? "INPUT2" : "NONE"
              )
            }
          />
        </TableCell>
        <TableCell align="center" >
          <CircleColourPicker
            selectedColor={supplier.colour}
            colors={["#4259EB", "#AB42EB", "#42C2EB", "#EBBC42"]}
            onChange={(color) => handleChange("colour", color || "#4259EB")}
          />
        </TableCell>
        <TableCell align="center">
          <IconButton
            size="small"
            aria-label="delete"
            onClick={(e) => setShowArchiveSupplier(true)}
          >
            <DeleteIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  );
};

export default MediaSuppliersTableRow;
