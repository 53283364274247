import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { format } from "date-fns";
import {
  Modal,
  withStyles,
  Checkbox,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  CircularProgress,
  Badge,
  Tooltip
} from "@material-ui/core";
import "./SendSchedule.scss";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const PeopleList = (props) => {
  const [allCheckbox, setAllCheckbox] = useState(true);

  const handleCheckbox = (event, person) => {
    if (event.target.checked) {
      person.willReceive = true;
    } else {
      person.willReceive = false;
      setAllCheckbox(false);
    }
    props.updatePerson(person);
  };

  const handleAllCheckbox = (event) => {
    if (event.target.checked) {
      props.handleAllCheckedClick();
      setAllCheckbox(true);
    } else {
      props.handleAllUncheckedClick();
      setAllCheckbox(false);
    }
  };

  useEffect(() => {
    console.log("PeopleList props");
    console.log(props);
  });

  return (
    <React.Fragment>
      <Table className="people-list-table" size="small">
        <TableHead>
          <TableCell padding="checkbox">
            <Checkbox
              className={allCheckbox && "schedule-checkbox"}
              checked={allCheckbox}
              onChange={handleAllCheckbox}
            />
          </TableCell>
          <TableCell>
            <b>Name</b>
          </TableCell>
          <TableCell>
            <b>Email</b>
          </TableCell>
          <TableCell>
            <b>Type</b>
          </TableCell>
          <TableCell>
            <b>Preview</b>
          </TableCell>
          <TableCell>
            <b>Last Sent</b>
          </TableCell>
        </TableHead>
        <TableBody>
          {props.peopleList.map((person, index) => {
            //className="checkbox-cell-width"
            return (
              <TableRow key={person.id}>
                <TableCell padding="checkbox">
                  <Checkbox
                    className={person.willReceive && "schedule-checkbox"}
                    checked={person.willReceive}
                    onChange={(event) => handleCheckbox(event, person)}
                  />
                </TableCell>
                <TableCell>
                  {`${person.firstName} ${person.lastName}`}
                </TableCell>
                <TableCell>
                  {/*  */}
                  {person.hasNoEmail == 1 && (
                    <HtmlTooltip
                      title={"No personal email detected. Will use Agent Email"}
                      interactive
                      arrow
                      placement="top"
                    >
                      <Badge badgeContent={"!"} color="secondary">
                        {person.email}
                      </Badge>
                    </HtmlTooltip>
                  )}
                  {person.hasNoEmail != 1 && (
                    `${person.email}`
                  )}
                </TableCell>
                <TableCell>{`${person.personCategory}`}</TableCell>
                <TableCell>
                  {props.enablePreview ? (
                    <Button
                      size="small"
                      variant="contained"
                      color="primary"
                      onClick={() => props.handlePreview(person)}
                    >
                      Preview
                    </Button>
                  ) : (
                    <Button
                      size="small"
                      variant="contained"
                      color="primary"
                      onClick={() => props.handlePreview(person)}
                      disabled
                    >
                      Preview
                      <CircularProgress className="people-list-enable-preview-button" />
                    </Button>
                  )}
                </TableCell>
                <TableCell>
                  {person.scheduleLastSent == null
                    ? "Not Sent"
                    : format(new Date(person.scheduleLastSent), "dd/MM/yy")}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </React.Fragment>
  );
};

export default PeopleList;
