import React from "react";
import "./heading.scss"
const Heading = ({ title }) => {
  return (
    <div className="heading-container-root">
      <span>{title}</span>
    </div>
  );
};

export default Heading;
