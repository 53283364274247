import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
    marginLeft: "30px"
  },
}));

const SharedSimpleAlerts = (props) => {
  const classes = useStyles();
  const { simpleAlertsText, heigth } = props;

  return (
    <div className={classes.root}>
      <Alert icon={<ArrowBackIcon />} severity="error" style={{heigth: heigth, padding: "0px 0px 0px 15px"}}>
        {simpleAlertsText}
      </Alert>
    </div>
  );
};

export default SharedSimpleAlerts;
