import React, { useEffect, useState } from "react";
import Axios from "axios";
import {
  FormControl,
  Grid,
  TextField,
  Button,
  Select,
  MenuItem
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useUserContext } from "../../../../context/user/UserContext";
import { useAuth } from "../../../../context/auth/AuthContext";

const useStyles = makeStyles(theme => ({
  root: {
    textAlign: "start"
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "start",
    color: theme.palette.text.secondary
  }
}));

export default function AddNewContact(props) {
  const classes = useStyles();
  const { user } = useAuth();

  const [requiredInfo, setRequiredInfo] = useState({
    firstName: "",
    lastName: "",
    emailAddress: "",
    phoneNumber: ""
  });

  const handleRequiredInfoChange = field => event => {
    if (field === "emailAddress") {
      setRequiredInfo({ ...requiredInfo, [field]: event.target.value.trim() });
    } else {
      setRequiredInfo({ ...requiredInfo, [field]: event.target.value });
    }
  };

  const handleSaveContact = () => {
    Axios.post(`/People/PostPerson?user=${user?.email}`, {
      firstName: requiredInfo.firstName,
      lastName: requiredInfo.lastName,
      emailAddress: requiredInfo.emailAddress,
      phoneNumber: requiredInfo.phoneNumber,
      fullName: requiredInfo.firstName + " " + requiredInfo.lastName,
      PersonCategory: "Location Contact"

    })
      .then(response => {
        console.log(response);
        props.setNewContact(response.data);
      })
      .catch(error => console.log(error));
  };

  return (
    <div className={classes.root}>
      <h2 style={{ fontFamily: "'Montserrat', 'sans-serif'", textAlign: 'center', marginBottom: '30px' }}>Create New Location Contact</h2>
      <Grid container spacing={1}>
        <Grid item xs={12} md={12} lg={12}>
          <FormControl style={{ width: "100%" }}>
            <TextField
              multiline={true}
              variant="outlined"
              id="fname-input"
              label="First Name"
              value={requiredInfo.firstName}
              onChange={handleRequiredInfoChange("firstName")}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <FormControl style={{ width: "100%" }}>
            <TextField
              id="lname-input"
              multiline={true}
              variant="outlined"
              label="Last Name"
              aria-describedby="Last Name is Required"
              value={requiredInfo.lastName}
              onChange={handleRequiredInfoChange("lastName")}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <FormControl style={{ width: "100%" }}>
            <TextField
              multiline={true}
              variant="outlined"
              id="email-input"
              label="Email Address"
              aria-describedby="Email Address is Required"
              value={requiredInfo.emailAddress}
              onChange={handleRequiredInfoChange("emailAddress")}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <FormControl style={{ width: "100%" }}>
            <TextField
              multiline={true}
              variant="outlined"
              id="phone-number-input"
              label="Phone Number"
              aria-describedby="Email Address is Required"
              value={requiredInfo.phoneNumber}
              onChange={handleRequiredInfoChange("phoneNumber")}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Button onClick={handleSaveContact} variant="contained" color="primary" style={{ float: 'right', marginLeft: '15px' }}>Submit</Button>
        <Button onClick={props.closeAddNewContact}variant="contained" style={{ float: 'right' }}>Cancel</Button>
        </Grid>
      </Grid>
    </div>
  );
};
