export const getComparator = (order, orderBy) => {
    var sortOrder = 1;
    if (order === "asc") {
        sortOrder = -1;
    }
    return function (a, b) {
        var result = 0;
        if (orderBy.fieldType === "date") {
            result = new Date(b[orderBy.field]) - new Date(a[orderBy.field]);
        } else if (orderBy.fieldType === "invoiceNumber") {
            var invoiceA = a[orderBy.field] == null ? 0 : a[orderBy.field].replace("INV-", "")
            var invoiceB = b[orderBy.field] == null ? 0 : b[orderBy.field].replace("INV-", "")
            result = (invoiceA < invoiceB) ? -1 : (invoiceA > invoiceB) ? 1 : 0;
        } else if (orderBy.field === "allocatedScheduleItem") {
            result = getAllocatedBudgetForClient(a) < getAllocatedBudgetForClient(b)
                ? -1 : (getAllocatedBudgetForClient(a) > getAllocatedBudgetForClient(b) ? 1 : 0)
        } else if (orderBy.field === "remaining") {
            result = getRemainingForClient(a) < getRemainingForClient(b) ? -1
                : (getRemainingForClient(a) > getRemainingForClient(b) ? 1 : 0)
        }
        else {
            /* next line works with strings and numbers*/
            result = (a[orderBy.field] < b[orderBy.field]) ? -1 : (a[orderBy.field] > b[orderBy.field]) ? 1 : 0;
        }

        return result * sortOrder;
    }
};

const getAllocatedBudgetForClient = (x) => {
    return x.allocatedScheduleItem + x.allocatedMediaSpend + x.allocatedPo + x.allocatedMediaAdDocument
}

const getRemainingForClient = (x) => {
    return x.quoted - (x.allocatedScheduleItem + x.allocatedMediaSpend
        + x.allocatedPo + x.allocatedMediaAdDocument)
} 