import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Axios from "axios";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  List,
  ListSubheader,
  ListItem,
  ListItemText,
  TextField,
  Button,
} from "@material-ui/core";
import { Delete } from "@material-ui/icons";

const HandSContactsList = () => {
  const [newHandSContact, setNewHandSContact] = useState();
  const [handSContacts, setHandSContacts] = useState([]);
  const [people, setPeople] = useState([]);
  const [reloadPeople, setReloadPeople] = useState(true);
  const [savePrecedence, setSavePrecedence] = useState(false);

  const onDragEnd = (result) => {
    // dropped outside the list
    console.log(result);
    if (!result.destination) {
      return;
    }
    let tempList = [...handSContacts];
    console.log(tempList);
    let rm = handSContacts[result.source.index];
    console.log(rm);
    console.log(tempList);
    tempList.splice(result.source.index, 1);
    console.log(tempList);
    tempList.splice(result.destination.index, 0, rm);
    console.log(tempList);
    setHandSContacts(tempList);
    setSavePrecedence(true);
  };

  useEffect(() => {
    console.log(handSContacts);
  }, [handSContacts]);

  useEffect(() => {
    if (savePrecedence) {
      Axios.patch(`People/UpdateHandSSeniority`, handSContacts)
        .then((res) => {
          console.log(res);
          setSavePrecedence(false);
        })
        .catch((err) => console.log(err));
    }
  }, [savePrecedence]);

  useEffect(() => {
    if (reloadPeople === true) {
      let rOne = Axios.get(`People/GetHealthAndSafetyContacts`);
      let rTwo = Axios.get(`People/GetContractors`);
      let rThree = Axios.get(`People/GetStaff`);
      Axios.all([rOne, rTwo, rThree])
        .then(
          Axios.spread((...responses) => {
            setHandSContacts(
              responses[0].data.sort((a, b) => a.isFirstAider - b.isFirstAider)
            );
            setPeople([...responses[1].data, ...responses[2].data]);
            setReloadPeople(false);
          })
        )
        .catch((err) => console.log(err));
    }
  }, [reloadPeople]);

  const addNewHandSContact = () => {
    if (newHandSContact != null) {
      Axios.patch(
        `People/MakeHandSContact/${newHandSContact.id}/${
          handSContacts.length + 1
        }`
      )
        .then((res) => {
          setReloadPeople(true);
        })
        .catch((err) => console.log(err));
    }
    setNewHandSContact(null);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="list">
        {(provided) => (
          <List
            {...provided.droppableProps}
            ref={provided.innerRef}
            subheader={
              <ListSubheader component="div" id="nested-list-subheader">
                {`Qualified H and S Shoot Contacts Ranked`}
              </ListSubheader>
            }
          >
            {handSContacts.map((contact, index) => (
              <Draggable
                key={contact.id}
                draggableId={contact.id.toString()}
                index={index}
              >
                {(provided) => (
                  <ListItem
                    key={contact.id}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <ListItemText
                      primary={`${contact.firstName + " " + contact.lastName}`}
                      secondary={index + 1}
                    />
                    <Delete
                      className="remove-from-hands-list"
                      onClick={() => {
                        Axios.patch(`People/MakeHandSContact/${contact.id}/0`);
                        setReloadPeople(true);
                      }}
                    />
                  </ListItem>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </List>
        )}
      </Droppable>
      <Autocomplete
        className="h-and-s-contact-autocomplete"
        options={people}
        getOptionLabel={(option) => option.firstName + " " + option.lastName}
        value={newHandSContact}
        onChange={(event, newValue) => {
          setNewHandSContact(newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Add New H&S Contact"
            margin="normal"
          />
        )}
      />
      <Button
        onClick={() => addNewHandSContact()}
        variant="contained"
        color="primary"
        className="add-new-hazard-btn"
      >
        Add To List
      </Button>
    </DragDropContext>
  );
};

export default HandSContactsList;
