import React, { useEffect, useState } from "react";
import "./payablesTable.scss";
import Header from "../../../Header";
import {
  Button,
  Container,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import Snacky from "../../../Shared/Snacky";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useHistory } from "react-router-dom";
import axios from "axios";
import CustomPayableTableRow from "./CustomPayableTableRow";
import ClientBudget from "../../../Client/ClientBudget";
import BudgetTable from "./BudgetTable";
import TransferBudgetButton from "../TransferBudgetButton/TransferBudgetButton";
const PayablesTable = () => {
  const history = useHistory();
  const [payables, setPayables] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [accountCodes, setAccountCodes] = useState([]);
  const [isLoadingPayables, setIsLoadingPayables] = useState(false);
  const [selectedPayable, setSelectedPayable] = useState(null);
  const [budget, setBudget] = useState([]);
  const [isLoadingBudget, setIsLoadingBudget] = useState(false);
  const [snackBarProps, setSnackBarProps] = useState({
    open: false,
    severity: "success",
    text: "",
  });

  useEffect(() => {
    fetchCompaniesWithProjects();
    fetchPayables();
    fetchAccountCodes();
  }, []);

  const fetchCompaniesWithProjects = () => {
    axios
      .get(`/companies/GetCompaniesWithProjects`)
      .then(({ data }) => {
        setCompanies(data);
      })
      .catch((err) =>
        setSnackBarProps({
          open: true,
          severity: "warning",
          text: "Unable to load companies",
        })
      );
  };

  const handleGoBackClick = (e) => {
    e.preventDefault();
    history.push(`/Finance/BillsDashboard`);
  };

  const fetchPayables = () => {
    setIsLoadingPayables(true);
    axios
      .get(`/MediaAdDocuments/GetMediaAdDocuments`, {
        params: {
          organisationFk: 2,
          statuses: ["Pending","Submitted"]
        },
      })
      .then(({ data }) => {
        setPayables(data);
      })
      .catch((err) =>
        setSnackBarProps({
          open: true,
          severity: "warning",
          text: "Unable to find payables",
        })
      )
      .finally(() => setIsLoadingPayables(false));
  };

  const handleUpdatePayablesList = (payable, shouldRemove = false) => {
    if (shouldRemove) {
      setPayables(payables.filter((p) => p.id !== payable.id));
    } else {
      setPayables(payables.map((p) => (p.id === payable.id ? payable : p)));
    }
  };

  const handleSelectPayable = (payable) => {
    setSelectedPayable(payable);
    if (payable?.companyId) {
      fetchAllBudget(payable?.companyId);
    } else {
      setBudget(null);
    }
  };

  const fetchAllBudget = (campaignId) => {
    setIsLoadingBudget(true);
    axios
      .get(`/budgetitems/GetBudgetForCompanyByProject/${campaignId}`)
      .then(({ data }) => {
        setBudget(data);
      })
      .catch((err) =>
        setSnackBarProps({
          open: true,
          severity: "warning",
          text: "Unable to find budget",
        })
      )
      .finally(() => setIsLoadingBudget(false));
  };

  const handleAllocatePayableToBudget = (budget) => {
    axios
      .post(
        `/MediaAdDocuments/AllocatedMediaAdToBudget/${budget?.budgetId}/${selectedPayable?.id}`
      )
      .then(({ data }) => {
        setSnackBarProps({
          open: true,
          severity: "success",
          text: "Awesome! The invoice has been allocated.",
        });
        handleUpdatePayablesList({
          ...selectedPayable,
          budgetId: budget?.budgetId,
        });
        fetchAllBudget(selectedPayable?.companyId);
      })
      .catch((err) =>
        setSnackBarProps({
          open: true,
          severity: "warning",
          text: "Unable to allocate budget",
        })
      );
  };

  const handleTransferBudgetCallback = async () => {
    await fetchAllBudget(selectedPayable?.companyId);
  };

  const fetchAccountCodes = () => {
    setIsLoadingBudget(true);
    axios
      .get(`/expenseCodes/SearchExpenseCodes`)
      .then(({ data }) => {
        setAccountCodes(data);
      })
      .catch((err) =>
        setSnackBarProps({
          open: true,
          severity: "warning",
          text: "Unable to find account codes",
        })
      );
  };

  return (
    <div className="payables-table-root">
      <Container maxWidth="lg" className="payables-table-gap-container">
        <Snacky
          snackprops={snackBarProps}
          setSnackBarProps={setSnackBarProps}
        />
        <div className="payables-table-nav-header">
          <Button
            size="small"
            startIcon={<ArrowBackIcon />}
            className="payables-table-nav-header-btn"
            onClick={handleGoBackClick}
          >
            Go Back
          </Button>
        </div>
        <div>
          <Typography variant="h6">Payables Table</Typography>
          <Divider />
        </div>
        {payables?.length > 0 ? (
          <div className="payables-table-wrapper">
            <div className="payables-table-list-container">
              <TableContainer component={Paper}>
                <Table
                  size="small"
                  className="payables-table-records-container"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell className="client-budget-table-icon-column"></TableCell>
                      <TableCell>Details</TableCell>
                      <TableCell>Total</TableCell>
                      <TableCell>Allocated</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {payables.map((payable, indexBill) => (
                      <CustomPayableTableRow
                        key={indexBill}
                        payable={payable}
                        setSnackBarProps={setSnackBarProps}
                        handleUpdatePayablesList={handleUpdatePayablesList}
                        companies={companies}
                        handleSelectPayable={handleSelectPayable}
                        accountCodes={accountCodes}
                      />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <div className="payables-table-allocated-budget-container">
              {selectedPayable && budget?.length > 0 ? (
                <>
                  <div className="payables-table-transfer-budget-container">
                    <TransferBudgetButton
                      budgetItems={budget}
                      handleSaveCallback={handleTransferBudgetCallback}
                    />
                  </div>
                  <BudgetTable
                    budget={budget}
                    selectedPayable={selectedPayable}
                    handleAllocatePayableToBudget={
                      handleAllocatePayableToBudget
                    }
                  />
                </>
              ) : (
                <div className="payables-table-no-records">
                  <Typography variant="caption">
                    {isLoadingBudget
                      ? "Loading..."
                      : selectedPayable
                      ? "This client has no budget."
                      : "Please select a bill to see budget"}
                  </Typography>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="payables-table-no-records">
            <Typography variant="h6">Nothing here...</Typography>
          </div>
        )}
      </Container>
    </div>
  );
};

export default PayablesTable;
