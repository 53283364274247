import React, { useState, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Header from '../../Header';
import Axios from "axios";
import {
  Grid,
  Tooltip,
  Button,
  CircularProgress,
  Card,
  CardHeader,
  CardContent,
  CssBaseline,
  Divider,
  TextField,
  InputAdornment,
  Typography
} from "@material-ui/core";
import {
  Search as SearchIcon
} from '@material-ui/icons';
import "../Admin.css";
import Loader from "../../Loader";
import Snacky from "../../Shared/Snacky";
import { connect } from "react-redux";
import { setSnackBarProps } from "../../../actions/snackbar";
import ContactCenterTable from "./ContactCenterTable";
import "./ContactCenter.css";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: 12,
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const ContactCenter = (props) => {
  let { setSnackBarProps, snackbar } = props;
  const [contactList, setContactList] = useState(null);
  const [rowUpdating, setRowUpdating] = useState(false);

  const [initialFilters, setInitialFilters] = useState({});
  const [searchValue, setSearchValue] = useState("");

  const handleEditAgent = (newContact) => {
    setContactList(contactList.map((c) => {
      if (c.id === newContact.id) {
        console.log("HandleEditContact")
        Axios.put(`Agents/UpdateAgent/${newContact.agentId}`, {
          id: newContact.agentId,
          firstName: newContact.firstName,
          lastName: newContact.lastName,
          emailAddress: newContact.emailAddress,
          phoneNumber: newContact.phoneNumber,
          enableSms: newContact.enableSms
        })
          .then(res => {
            console.log(res)
          })
          .catch(e => {
            console.log(e)
          })
        console.log(`Old: ${c.firstName}`)
        console.log(`New: ${newContact.firstName}`)
      }
      return (
        c.id === newContact.id ? {
          ...c,
          ["firstName"]: newContact.firstName,
          ["lastName"]: newContact.lastName,
          ["name"]: `${newContact.firstName} ${newContact.lastName}`,
          ["emailAddress"]: newContact.emailAddress,
          ["phoneNumber"]: newContact.phoneNumber,
          ["bankAccount"]: newContact.bankAccount,
          ["enableSms"]: newContact.enableSms,
        } : c
      )
    }));
  };

  const handleEditPerson = (newContact) => {
    setContactList(contactList.map((c) => {
      return (
        c.id === newContact.id ? {
          ...c,
          ["firstName"]: newContact.firstName,
          ["lastName"]: newContact.lastName,
          ["name"]: `${newContact.firstName} ${newContact.lastName}`,
          ["emailAddress"]: newContact.emailAddress,
          ["phoneNumber"]: newContact.phoneNumber,
          ["bankAccount"]: newContact.bankAccount,
          ["enableSms"]: newContact.enableSms,
        } : c
      )
    }));
  };

  useEffect(() => {
    Axios.get(`ContactCenter/GetContacts`)
      .then(res => {
        setContactList(res.data);
      })
      .catch(e => {
        console.log(e);
      })
  }, []);

  useEffect(() => {
    console.log("List Changed!")
    console.log(contactList)
    setRowUpdating(false);
  }, [contactList])

  return (
    <div
      style={{
        height: "93vh"
      }}
    >
      <Card elevation={9} style={{ maxHeight: "100%", minHeight: "100%", overflow: "scroll" }}>
        <CardHeader style={{ margin: 0, }} title={<h1 className="contact-center-header" >Contact Center</h1>} />
        <Divider />
        <CardContent>
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              )
            }}
            placeholder="Search here"
            size="small"
            value={searchValue}
            variant="outlined"
            fullWidth
            onChange={(e) => {
              setSearchValue(e.target.value)
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                var searchText = searchValue;
                if (searchValue.trim() === "") {
                  searchText = "resetSearch";
                }
                Axios.get(`ContactCenter/SearchContacts/${searchText}`)
                  .then(res => {
                    setContactList(res.data);
                    setRowUpdating(true);
                  })
                  .catch(e => console.log(e))
              }
            }}
            onKeyUp={(e) => {
              if (e.key === 'Backspace') {
                if (e.target.value === "") {
                  Axios.get(`ContactCenter/SearchContacts/resetSearch`)
                    .then(res => {
                      setContactList(res.data);
                      setRowUpdating(true);
                    })
                    .catch(e => console.log(e))
                }
              }
            }}
          />
          {
            (contactList !== undefined && contactList !== null && !rowUpdating) &&
            <ContactCenterTable
              contactList={contactList}
              initialFilters={initialFilters}
              setInitialFilters={setInitialFilters}
              handleEditAgent={handleEditAgent}
              handleEditPerson={handleEditPerson}
              rowUpdating={rowUpdating}
              rowUpdated={() => setRowUpdating(true)}
            />
          }
        </CardContent>
      </Card>
    </div>
  )
};

const mapStateToProps = (state, ownProps) => ({
  snackbar: state.snackbar,
});

export default connect(mapStateToProps, {
  setSnackBarProps,
})(ContactCenter);
