import * as React from 'react';
import {
    Card,
    CardHeader,
    CardMedia,
    CardContent,
    CardActions,
    Collapse,
    Avatar,
    IconButton,
    Typography,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    Table
} from "@material-ui/core";

const InfluencerCard = (props) => {
    const {
        influencer,
        type
    } = props;

    //------- Instagram----------------//
    const InstagramHeadSection = () => {
        return (
            <React.Fragment>
                <CardHeader
                    avatar={
                        <Avatar alt={`${influencer.basicInstagram.instagramName}`} src={`${influencer.basicInstagram.avatar}`}>
                        </Avatar>
                    }
                    title={`${influencer.basicInstagram.instagramName}`}
                    subheader={`${influencer.basicInstagram.instagramId}`}
                />
                <CardContent>
                    <Typography variant="body2" color="text.secondary">
                        {influencer.basicInstagram.country}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {`${influencer.basicInstagram.description}`}
                    </Typography>
                </CardContent>
            </React.Fragment>
        )
    }
    const InstagramStatisticsTable = () => {
        return (
            <Table width={"100%"}>
                <TableHead >
                    <TableRow>
                        <TableCell>Posts</TableCell>
                        <TableCell>Followers</TableCell>
                        <TableCell>Engagment Rate</TableCell>
                        <TableCell>Average Likes</TableCell>
                        <TableCell>Average Comments</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>{`${influencer.basicInstagram.posts}`}</TableCell>
                        <TableCell>{`${influencer.basicInstagram.followers}`}</TableCell>
                        <TableCell>{`${influencer.basicInstagram.engageRate}`}</TableCell>
                        <TableCell>{`${influencer.basicInstagram.avgLikes}`}</TableCell>
                        <TableCell>{`${influencer.basicInstagram.avgComments}`}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        )
    }
    const InstagramGrowthRateTable = () => {
        return (
            <Table width={"100%"}>
                <TableHead className="thead-magnesium">
                    <TableRow>
                        <TableCell>Followers</TableCell>
                        <TableCell>Likes</TableCell>
                        <TableCell>Comments</TableCell>
                        <TableCell>Average Likes</TableCell>
                        <TableCell>Average Comments</TableCell>
                        <TableCell>Engagment Rate</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>{`${influencer.basicInstagram.gRateFollowers}`}</TableCell>
                        <TableCell>{`${influencer.basicInstagram.gRateLikes}`}</TableCell>
                        <TableCell>{`${influencer.basicInstagram.gRateComments}`}</TableCell>
                        <TableCell>{`${influencer.basicInstagram.gRateAvgLikes}`}</TableCell>
                        <TableCell>{`${influencer.basicInstagram.gRateAvgComments}`}</TableCell>
                        <TableCell>{`${influencer.basicInstagram.gRateEngageRate}`}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        )
    }
    //------- End of Instagram----------------//
    
    //---------------- TikTok ----------------//
    const TikTokStatisticsTable = () => {
        return (
            <Table width={"100%"}>
                <TableHead >
                    <TableRow>
                        <TableCell>Followers</TableCell>
                        <TableCell>Hearts</TableCell>
                        <TableCell>Videos</TableCell>
                        <TableCell>Average Plays</TableCell>
                        <TableCell>Average Shares</TableCell>
                        <TableCell>Average Hearts</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>{`${influencer.basicTikTok.followers}`}</TableCell>
                        <TableCell>{`${influencer.basicTikTok.hearts}`}</TableCell>
                        <TableCell>{`${influencer.basicTikTok.videos}`}</TableCell>
                        <TableCell>{`${influencer.basicTikTok.avgPlays}`}</TableCell>
                        <TableCell>{`${influencer.basicTikTok.avgShares}`}</TableCell>
                        <TableCell>{`${influencer.basicTikTok.avgHearts}`}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        )
    }
    const TikTokGrowthRateTable = () => {
        return (
            <Table width={"100%"}>
                <TableHead className="thead-magnesium">
                    <TableRow>
                        <TableCell>Followers</TableCell>
                        <TableCell>Hearts</TableCell>
                        <TableCell>Average Plays</TableCell>
                        <TableCell>Average Hearts</TableCell>
                        <TableCell>Average Comments</TableCell>
                        <TableCell>Engagment Rate</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>{`${influencer.basicTikTok.gRateFollowers}`}</TableCell>
                        <TableCell>{`${influencer.basicTikTok.gRateHearts}`}</TableCell>
                        <TableCell>{`${influencer.basicTikTok.gRateAvgPlays}`}</TableCell>
                        <TableCell>{`${influencer.basicTikTok.gRateAvgHearts}`}</TableCell>
                        <TableCell>{`${influencer.basicTikTok.gRateAvgComments}`}</TableCell>
                        <TableCell>{`${influencer.basicTikTok.gRateEngageRate}`}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        )
    }
    const TikTokHeadSection = () => {
        return (
            <React.Fragment>
                <CardHeader
                    avatar={
                        <Avatar alt={`${influencer.basicTikTok.tiktokName}`} src={`${influencer.basicTikTok.avatar}`}>
                        </Avatar>
                    }
                    title={`${influencer.basicTikTok.tiktokName}`}
                    subheader={`${influencer.basicTikTok.tiktokId}`}
                />
                <CardContent>
                    <Typography variant="body2" color="text.secondary">
                        {influencer.basicTikTok.country}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {`${influencer.basicTikTok.description}`}
                    </Typography>
                </CardContent>
            </React.Fragment>
        )
    }
    //-------------End of TikTok ------------//

    return (
        <Card>
            {type === "instagram" ? InstagramHeadSection() : TikTokHeadSection()}
            <CardContent>
                <Typography variant="subtitle1">
                    Statistics
                </Typography>
                {type === "instagram" ? InstagramStatisticsTable() : TikTokStatisticsTable()}
            </CardContent>
            <CardContent>
                <Typography variant="subtitle1">
                    Growth Rate
                </Typography>
                {type === "instagram" ? InstagramGrowthRateTable() : TikTokGrowthRateTable()}
            </CardContent>
        </Card>
    );
}

export default InfluencerCard;