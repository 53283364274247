import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  budget: {},
  tasks: [],
  expenseCodes: [],
  errorMessage: "",
  budgetLoading: true,
};
const budget = createReducer(initialState, {
  GET_BUDGET: (state, action) => {
    return {
      ...state,
      budget: action.payload.budget,
      budgetLoading: false,
    };
  },
  GET_TASKS: (state, action) => {
    return {
      ...state,
      tasks: action.payload.tasks,
    };
  },
  EDIT_BUDGET: (state, action) => {
    var expense = state.budget.find(
      (t) => t.category === action.payload.category
    );
    expense.totalAmount += action.payload.amount;
  },
  RESET_BUDGET: (state, action) => {
    return {
      ...initialState,
    };
  },
  GET_ALL_EXPENSE_CODES_SUCCESS: (state, action) => {
    return {
      ...state,
      expenseCodes: action.payload,
    };
  },
  GET_ALL_EXPENSE_CODES_FAILURE: (state, action) => {
    return {
      ...state,
      errorMessage: action.payload,
    };
  },
});

export default budget;
