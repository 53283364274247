import React, { Fragment } from 'react'

const Spinner = ({
    loaderClass = ""
}) => {

    const getRandomLoader = () => {
        var loaderClasses = ["dot-revolution", "dot-bricks", "dot-floating", "dot-windmill"];
        return loaderClasses[(Math.floor(Math.random() * loaderClasses.length))]
    }

    return (
        <Fragment>
            {/* <img src={spinner} alt="Loading..." style={{ width: '200px', margin: 'auto', display: 'block' }} /> */}
            <div style={{
                display: "flex",
                justifyContent: "center",
                padding: "30px 0px",
                overflow: "hidden"
            }}>
                <div className={loaderClass == "" ? getRandomLoader() : loaderClass}></div>
            </div>
        </Fragment>
    )
}


export default Spinner;