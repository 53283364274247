import React, { useState, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
    Modal,
    AppBar,
    Toolbar,
    Typography,
    IconButton,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Button,
    Grid
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import EditMediaAd from "./EditMediaAd";
import { format } from "date-fns";
import { getValueOrEmptyString, numberWithThousandsSeperator } from "../../../utils/utils";
import "./MediaAds.css";

const useStyles = makeStyles(theme => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflow: "hidden",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        minHeight: 390,
        maxHeight: "80vH",
        maxWidth: "70vW",
        overflow: "auto"
    },
}));

const DuplicatesModal = (props) => {
    const classes = useStyles();

    const [isEditing, setIsEditing] = useState(false);
    const [duplicateMediaAds, setDuplicateMediaAds] = useState([]);
    const [viewDuplicate, setViewDuplicate] = useState(false);
    const [selectedDuplicate, setSelectedDuplicate] = useState(null);

    const generateHeaders = () => {
        return (
            <TableHead>
                <TableRow>
                    <TableCell align="left" className="bold-header">Client</TableCell>
                    <TableCell align="left" className="bold-header">Platform</TableCell>
                    <TableCell align="left" className="bold-header">Reference</TableCell>
                    <TableCell align="left" className="bold-header">Transaction Date</TableCell>
                    <TableCell align="left" className="bold-header">NZD</TableCell>
                    <TableCell align="left" className="bold-header">GST Code</TableCell>
                    <TableCell align="left" className="bold-header">Type</TableCell>
                    <TableCell align="left" className="bold-header">Document</TableCell>
                    <TableCell align="left" className="bold-header">Status</TableCell>
                </TableRow>
            </TableHead>
        )
    }

    const generateRow = (mediaAd) => {
        return (
            <TableRow>
                <TableCell align="left" className="">{mediaAd.client}</TableCell>
                <TableCell align="left" className="">{mediaAd.platform}</TableCell>
                <TableCell align="left" className="">{mediaAd.transactionId}</TableCell>
                <TableCell align="left" className="">
                    {mediaAd.transactionDate !== null && format(new Date(mediaAd.transactionDate), "dd/MM/yyyy")}
                </TableCell>
                <TableCell align="center" className="">
                    {mediaAd.amount !== null ? `$${numberWithThousandsSeperator(mediaAd.amount)}` : "N/A"}
                </TableCell>
                <TableCell align="center" className="">{mediaAd.gstRateCode}</TableCell>
                <TableCell align="center" className="">{mediaAd.type}</TableCell>
                <TableCell align="center" className="">
                    <Button
                        size="small"
                        color="primary"
                        variant="contained"
                        onClick={() => {
                            setSelectedDuplicate(mediaAd);
                            setViewDuplicate(true);
                        }}
                    >
                        View
                                    </Button>
                </TableCell>
                <TableCell>{mediaAd.status}</TableCell>
            </TableRow>
        )
    }

    const duplicatesTable = () => {
        return (
            <div>
                <Table
                    id="duplicates-table"
                >
                    {generateHeaders()}
                    <TableBody>
                        {duplicateMediaAds.map(mediaAd => generateRow(mediaAd))}
                    </TableBody>
                </Table>
            </div>
        )
    }

    const selectedMediaAdTable = () => {
        var mediaAd = props.selectedAd;
        return (
            <div>
                <Table
                    id="selected-media-ad-table"
                >
                    {generateHeaders()}
                    <TableBody>
                        {generateRow(mediaAd)}
                    </TableBody>
                </Table>
            </div>
        )
    }

    useEffect(() => {
        var duplicates = []
        for (var i = 0; i < props.adList.length; i++) {
            if (props.adList[i].transactionId === props.selectedAd.transactionId && props.adList[i].id !== props.selectedAd.id 
                && props.adList[i].client === props.selectedAd.client && props.adList[i].type === props.selectedAd.type) {
                duplicates.push(props.adList[i]);
            }
        }
        setDuplicateMediaAds(duplicates)
    }, [])

    useEffect(() => {
        console.log("duplicateMediaAds")
        console.log(duplicateMediaAds)
    }, [duplicateMediaAds])

    return (
        <React.Fragment>
            <EditMediaAd
                modalState={viewDuplicate}
                handleModal={() => {
                    setSelectedDuplicate(null)
                    setViewDuplicate(false);
                }}
                docType="PDF"
                driveId={props.selectedAd.driveId}
                headerTitle={`${props.selectedAd.client} ${props.selectedAd.type}`}
                headerColor="#3d4977"
                selectedAd={props.selectedAd}
                setRowUpdating={() => ""}
                updateMultipleValuesMediaAd={() => ""}
                updateMediaAd={() => ""}
                setSnackBarProps={() => ""}
                type="View"
            />
            <Modal
                open={props.modalState}
                onClose={props.handleModal}
                className={classes.modal}
            >
                <div>
                    <AppBar position="static" style={{ background: "#217F8B" }}>
                        <Toolbar variant="dense" style={{ paddingRight: 0 }}>
                            <Typography
                                variant="h6"
                                align="center"
                                style={{ flexGrow: 1 }}
                            >
                                Duplicates
                        </Typography>
                            <span style={{ display: "flex" }}>
                                <IconButton
                                    onClick={props.handleModal}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </span>
                        </Toolbar>
                    </AppBar>
                    <div
                        className={classes.paper}
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={12} lg={12}>
                                <Typography variant="h6" align="center">Current Media Ad</Typography>
                                {selectedMediaAdTable()}
                            </Grid>
                            <Grid item xs={12} md={12} lg={12}>
                                <Typography variant="h6" align="center">Duplicates</Typography>
                                {duplicatesTable()}
                            </Grid>
                        </Grid>
                        {/* {selectedMediaAdTable()} */}

                    </div>
                </div>
            </Modal>
        </React.Fragment>
    )
}

export default DuplicatesModal;