import React, { useEffect, useState } from "react";
import { TextField, Typography } from "@material-ui/core";
import { Autocomplete, Skeleton } from "@material-ui/lab";
import moment from "moment";
import { currencyFormat, isNumeric } from "../../../utils/GeneralUtils";

const PullFromBudgetAutocomplete = ({
  options,
  value,
  handleOnChange,
  isReadOnly = false,
  style = {},
  renderInput = null,
  classes = "",
  variant = "outlined",
  errorMessage = "",
}) => {
  return (
    <Autocomplete
      className={classes}
      style={style}
      options={options}
      value={value}
      getOptionLabel={(option) =>
        `${option.jobId ? `[${option.jobId}] ` : ""}${option.projectCategory} ${
          !!option.billingDate
            ? " - " + moment(option.billingDate).format("MMM/YY")
            : ""
        } - ${currencyFormat(Number(option.remaining))} remaining`
      }
      onChange={handleOnChange}
      renderOption={(option) => {
        return (
          <li key={option.budgetId}>
            <Typography variant="caption">
              {`${option.jobId ? `[${option.jobId}] ` : ""}${
                option.projectCategory
              } ${
                !!option.billingDate
                  ? " - " + moment(option.billingDate).format("MMM/YY")
                  : ""
              } - ${currencyFormat(Number(option.remaining))} remaining`}
            </Typography>
          </li>
        );
      }}
      renderInput={(params) => {
        if (renderInput === null) {
          return (
            <TextField
              {...params}
              size="small"
              label="Pull From Budget"
              variant={variant}
              error={!!errorMessage}
              helperText={errorMessage || ""}
            />
          );
        } else {
          renderInput(params);
        }
      }}
      disableClearable
      fullWidth
      disabled={isReadOnly}
    />
  );
};

export default PullFromBudgetAutocomplete;
