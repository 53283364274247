import {
  AppBar,
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  Modal,
  Toolbar,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Close } from "@material-ui/icons";
import axios from "axios";
import { useUserContext } from "../../../../../../context/user/UserContext";
import EmailEditor from "../../../SendSchedule/EmailEditor";
import ProcessingSpinner from "../../../../../Shared/Spinner/ProcessingSpinner";
import "./contentApprovedDialog.scss";
import Snacky from "../../../../../Shared/Snacky";
import { useAuth } from "../../../../../../context/auth/AuthContext";

const ContentApprovedDialog = ({ open, handleClose, scheduleItemId, handleUpdateCallback, deliverableId = null }) => {
  const { user } = useAuth();
  const [scheduleItem, setScheduleItem] = useState(null);
  const [emailTemplate, setEmailTemplate] = useState(null);
  const [isSendingEmail, setIsSendingEmail] = useState(false);
  const [snackBarProps, setSnackBarProps] = useState({
    open: false,
    severity: "success",
    text: "",
  });

  useEffect(() => {
    if (scheduleItemId) {
      getPersonDetails();
      getEmailTemplate();
    }
  }, []);

  const getPersonDetails = () => {
    axios
      .get(`ScheduleItems/GetScheduleItemPersonDetails/${scheduleItemId}`)
      .then(({ data }) => {
        setScheduleItem(data);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const getEmailTemplate = () => {
    axios
      .get(`HtmlTemplate/GetHtmlTemplate/InfluencerApprovedContentEmail`)
      .then(({ data }) => {
        setEmailTemplate(data);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const handleSendEmail = () => {
    setIsSendingEmail(true);
    var url = `ScheduleItems/UpdateContentApprovalStatus/${scheduleItemId}/approved`;
    if (deliverableId != null) {
      url = `ScheduleItems/UpdateDeliverableApprovalStatus/${scheduleItemId}/approved/${deliverableId}`;
    }
    axios
      .post(url, {
        scheduleItemId: scheduleItemId,
        htmlBody: emailTemplate.template,
        subject: emailTemplate.name,
        senderEmail: user.email,
        senderFirstName: user.firstName,
        senderFullName: user.firstName + " " + user.lastName,
      })
      .then(({ data }) => {
        if (data) {
          setSnackBarProps({
            open: true,
            severity: "success",
            text: "Beauty! We have notified the influencer.",
          });
          handleUpdateCallback(
            scheduleItemId,
            data.scheduleItemStatusFkNavigation
          );
        } else {
          setSnackBarProps({
            open: true,
            severity: "warning",
            text: "Something went wrong. The Dev Team has been notified.",
          });
        }
      })
      .catch((e) => {
        setSnackBarProps({
          open: true,
          severity: "warning",
          text: "Something went wrong. The Dev Team has been notified.",
        });
      })
      .finally(() => setIsSendingEmail(false));
  };

  return (
    <div>
      <Snacky snackprops={snackBarProps} setSnackBarProps={setSnackBarProps} />
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth={"md"}>
        <div>
          <AppBar position="static" style={{ background: "#217F8B" }}>
            <Toolbar variant="dense" style={{ paddingRight: 0 }}>
              <Typography
                variant="subtitle1"
                align="center"
                style={{ flexGrow: 1 }}
              >
                Notify Influencer - Content Approved
              </Typography>
              <IconButton onClick={handleClose} color="inherit">
                <Close />
              </IconButton>
            </Toolbar>
          </AppBar>
        </div>
        {isSendingEmail ? (
          <ProcessingSpinner duration={60} unitTime={"seconds"} />
        ) : (
          <div className="content-approved-dialog-body-container">
            <div className="content-approved-dialog-body-influencer-name">
              <Typography>
                {scheduleItem?.personFkNavigation?.firstName}{" "}
                {scheduleItem?.personFkNavigation?.lastName}
              </Typography>

              <Typography>
                {scheduleItem?.personFkNavigation?.agentFkNavigation
                  ?.emailAddress
                  ? "Agent's Email: " +
                  scheduleItem?.personFkNavigation?.agentFkNavigation
                    ?.emailAddress
                  : scheduleItem?.personFkNavigation?.emailAddress}
              </Typography>
            </div>
            <div>
              {emailTemplate && (
                <EmailEditor
                  emailBodyHtml={emailTemplate?.template}
                  emailSubject={emailTemplate?.name}
                  setEmailBodyHtml={(value) =>
                    setEmailTemplate({ ...emailTemplate, template: value })
                  }
                  setEmailSubject={(value) =>
                    setEmailTemplate({ ...emailTemplate, name: value })
                  }
                />
              )}
            </div>
          </div>
        )}

        <div className="outreach-dialog-footer-container">
          <Button size="small" onClick={handleClose} variant="outlined">
            {" "}
            Cancel
          </Button>
          <Button
            size="small"
            color="primary"
            variant="contained"
            onClick={handleSendEmail}
            disabled={isSendingEmail}
          >
            Send Now
          </Button>
        </div>
      </Dialog>
    </div>
  );
};

export default ContentApprovedDialog;
