import {
  AppBar,
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  Modal,
  Toolbar,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Close } from "@material-ui/icons";
import "./outreachDialog.scss";
import InfluencersOutreachTable from "./InfluencersOutreachTable";
import axios from "axios";
import { useUserContext } from "../../../../../../context/user/UserContext";
import EmailEditor from "../../../SendSchedule/EmailEditor";
import PreviewEmail from "../../../SendSchedule/PreviewEmail";
import ProcessingSpinner from "../../../../../Shared/Spinner/ProcessingSpinner";
import { useAuth } from "../../../../../../context/auth/AuthContext";
const OutreachDialog = ({ open, handleClose, scheduleItems, scheduleId, setSnackBarProps, handleSaveCallback }) => {
  const { user } = useAuth();
  const [selected, setSelected] = useState([]);
  const [emailTemplate, setEmailTemplate] = useState(null);
  const [openPreviewEmail, setOpenPreviewEmail] = useState(false);
  const [previewData, setPreviewData] = useState(null);
  const [isSendingEmail, setIsSendingEmail] = useState(false);

  useEffect(() => {
    if (scheduleId && scheduleItems?.length > 0) {
      getPeopleList();
      getEmailTemplate();
    }
  }, []);

  const getPeopleList = () => {
    axios
      .get(`Schedules/GetPeopleInScheduleItems/${scheduleId}`, {
        params: {
          willReceive: false
        }
      })
      .then(({ data }) => {
        setSelected(filterRecords(data));
      });
  };

  const filterRecords = (data) => {
    if (!data || data.length === 0) {
      return [];
    }

    return data.filter((record) => {
      const allocatedRates = record.allocatedRates;
      const clientApprovalStatus = record.clientApprovalStatus;
      const deliverables = record.scheduleItemDeliverables;

      if (
        allocatedRates > 0 &&
        clientApprovalStatus === "approved"
      ) {
        return true;
      }

      return false;
    });
  }

  const getEmailTemplate = () => {
    axios
      .get(`HtmlTemplate/GetHtmlTemplate/InfluencerOutreachFullEmail`)
      .then(({ data }) => {
        setEmailTemplate(data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const buildScheduleItemDto = (dto, isChecked) => {
    return {
      ...dto,
      willReceive: isChecked,
    };
  };

  const handleGlobalCheckboxTick = (isChecked) => {
    setSelected(
      [...selected].map((dto) => {
        if (dto.scheduleItemStatus === "Signed" ||
          (dto.scheduleItemDeliverables?.length > 0 && dto.scheduleItemDeliverables.some((deliverable) => deliverable.goLiveDate === null))) {
          isChecked = false
        }
        return buildScheduleItemDto(dto, isChecked);
      })
    );
  };

  const handleCheckboxChange = (scheduleItemId, isChecked) => {
    setSelected(
      [...selected].map((dto) => {
        if (dto.scheduleItemId === scheduleItemId) {
          return buildScheduleItemDto(dto, isChecked);
        }
        return buildScheduleItemDto(dto, dto.willReceive);
      })
    );
  };

  const handleClosePreview = () => {
    setOpenPreviewEmail(false);
    setPreviewData(null);
  };

  const handlePreview = (dto) => {
    axios
      .post(`Influencer/GetOutreachEmailPreview`, {
        people: [dto],
        htmlBody: emailTemplate.template,
        subject: emailTemplate.name,
        senderEmail: user.email,
        senderFirstName: user.firstName,
        senderFullName: user.firstName + " "+user.lastName,
      })
      .then(({ data }) => {
        setPreviewData({
          person: data.people[0],
          htmlBody: data.htmlBody,
          subject: data.subject,
        });
        setOpenPreviewEmail(true);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleSendEmail = () => {
    setIsSendingEmail(true);
    axios
      .post(`Influencer/SendInfluencerOutreachEmail`, {
        people: selected,
        htmlBody: emailTemplate.template,
        subject: emailTemplate.name,
        senderEmail: user.email,
        senderFirstName: user.firstName,
        senderFullName: user.firstName + " "+user.lastName,
      })
      .then(({ data }) => {
        console.log(data)
        console.log("success", data["success"])
        console.log("error", data["error"])
        setSelected(data["success"].concat(data["error"]))

        if(data["error"]?.length > 0){
          setSnackBarProps({
            open: true,
            severity: "warning",
            text:
              "Some influencers were not able to receive the email. Check the last sent date",
          });
        } else {
          setSnackBarProps({
            open: true,
            severity: "success",
            text:
              "Fantastic! The emails have been successfully sent to the influencers.",
          });
          handleClose();
        }
      })
      .catch((e) => {
        console.log(e);
        setSnackBarProps({
          open: true,
          severity: "warning",
          text:
            "Some influencers were not able to receive the email. Check the last sent date",
        });
      })
      .finally(() => {
        handleSaveCallback()
        setIsSendingEmail(false)
      });
  };

  console.log("selected", selected, isSendingEmail)

  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth={"lg"}>
        {openPreviewEmail && (
          <PreviewEmail
            modalState={openPreviewEmail}
            handleModal={handleClosePreview}
            previewComponents={previewData}
          />
        )}
        <div>
          <AppBar position="static" style={{ background: "#217F8B" }}>
            <Toolbar variant="dense" style={{ paddingRight: 0 }}>
              <Typography
                variant="subtitle1"
                align="center"
                style={{ flexGrow: 1 }}
              >
                Influencer Outreach
              </Typography>
              <IconButton onClick={handleClose} color="inherit">
                <Close />
              </IconButton>
            </Toolbar>
          </AppBar>
        </div>
        {isSendingEmail ? (
          <ProcessingSpinner duration={60} unitTime={"seconds"} />
        ) : (
          <div className="outreach-dialog-body-container">
            <div>
              <InfluencersOutreachTable
                scheduleItemsDto={selected}
                handleGlobalCheckboxTick={handleGlobalCheckboxTick}
                handleCheckboxChange={handleCheckboxChange}
                handlePreview={handlePreview}
              />
            </div>
            <div>
              {emailTemplate && (
                <EmailEditor
                  emailBodyHtml={emailTemplate?.template}
                  emailSubject={emailTemplate?.name}
                  setEmailBodyHtml={(value) =>
                    setEmailTemplate({ ...emailTemplate, template: value })
                  }
                  setEmailSubject={(value) =>
                    setEmailTemplate({ ...emailTemplate, name: value })
                  }
                />
              )}
            </div>
          </div>
        )}

        <div className="outreach-dialog-footer-container">
          <Button size="small" onClick={handleClose} variant="outlined" className="default-button-grey">
            {" "}
            Cancel
          </Button>
          <Button
            size="small"
            className={`${isSendingEmail || !selected.some(s => s.willReceive === true) ? "" : "default-button"} ` }
            variant="contained"
            onClick={handleSendEmail}
            disabled={isSendingEmail || !selected.some(s => s.willReceive === true)}
          >
            Send Now
          </Button>
        </div>
      </Dialog>
    </div>
  );
};

export default OutreachDialog;
