import React, { useEffect, useState } from "react";
import "./peopleOfInfluenceForm.scss";
import {
  Button,
  CircularProgress,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import moment from "moment";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
  useStaticState,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import InfluencersAutocomplete from "../../Shared/InfluencersAutocomplete/InfluencersAutocomplete";
import { format } from "date-fns";
import { isEmpty } from "../../../utils/GeneralUtils";
import axios from "axios";
import { get12MonthsAheadAnd1MonthBackArr } from "../../../utils/TimeHelpers";
import PullFromBudgetAutocomplete from "../../Shared/BudgetItem/PullFromBudgetAutocomplete";
const initialErrorState = {
  startTime: "",
  budget: "",
  allocatedRates: "",
  person: "",
};
const PeopleOfInfluenceForm = ({
  budgetItems,
  poiInfluencer,
  handleOnChange,
  handleSaveScheduleItem,
}) => {
  const [errorForm, setErrorForm] = useState(initialErrorState);
  const [influencers, setInfluencers] = useState([]);

  useEffect(() => {
    fetchInfluencers();
  }, []);

  const fetchInfluencers = async () => {
    try {
      const response = await axios
        .post(`/influencer/SearchInfluencers`, {
          industries: [],
          ageCategories: [],
          rating: [],
          hasPets: false,
          hasKids: false,
          type: ""
        }, {
          params: {
            pageSize: 250,
            organisationId: 5,
          },
        });
      setInfluencers(response.data);
    } catch (error) {
      console.error("Error fetching options:", error);
    }
  };
  const handleOnChangeInfluencerAutocomplete = (person) => {
    handleOnChange("person", person);
  };

  const handleSave = async () => {
    if (await validateForm()) {
      handleSaveScheduleItem();
    }
  };

  const findInfluencerByPartialName = (budget) => {
    // Extract the projectCategory from the budget
    const projectCategory = budget.projectCategory.toLowerCase();

    // Iterate through the influencers array
    for (const influencer of influencers) {
      // Check if the influencer's full name (firstName + lastName) is present
      // in the projectCategory, case-insensitive
      const fullName = `${influencer.firstName} ${influencer.lastName}`.toLowerCase();

      if (projectCategory.includes(fullName)) {
        return influencer; // Return the matching influencer
      }
    }

    return null; // Return null if no match is found
  }

  const validateForm = async () => {
    let isValidForm = true;
    const tempError = { ...initialErrorState };

    if (isEmpty(poiInfluencer.budgetId)) {
      tempError.budget = "Please select a budget";
      isValidForm = false;
    } else {
      const selectedBudget = await budgetItems.find(
        (bi) => bi.budgetId === poiInfluencer.budgetId
      );

      if (Number(poiInfluencer.allocatedRates) > selectedBudget.remaining) {
        tempError.budget = "Not enough budget remaining";
        isValidForm = false;
      }
    }

    if (isEmpty(poiInfluencer.startTime)) {
      tempError.startTime = "Select the Go Live Date";
      isValidForm = false;
    }

    if (!poiInfluencer.allocatedRates) {
      tempError.allocatedRates = "Enter a fee";
      isValidForm = false;
    }

    if (isEmpty(poiInfluencer.personFk)) {
      tempError.person = "Please select a talent";
      isValidForm = false;
    }

    if (!isValidForm) {
      setErrorForm(tempError);
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }

    return isValidForm;
  };
  return (
    <div className="people-of-influence-form-root">
      <Paper className="people-of-influence-form-wrapper">
        <div className="people-of-influence-form-container">
          <Autocomplete
            options={budgetItems.filter((b) => b.remaining > 0)}
            value={budgetItems.find(
              (bi) => bi.budgetId === poiInfluencer.budgetId
            )}
            getOptionLabel={(option) =>
              `${option.projectCategory}${!!option.billingDate
                ? " - " + moment(option.billingDate).format("MMM/YY")
                : ""
              } - $${option.remaining} remaining`
            }
            onChange={(event, newValue) => {
              handleOnChange("budget", newValue || null);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                label="Pull From Budget"
                variant="outlined"
                error={errorForm.budget ? true : false}
                helperText={errorForm.budget ? errorForm.budget : ""}
              />
            )}
            disableClearable
            fullWidth
          />
          <TextField
            label="Fee"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            size="small"
            variant="outlined"
            value={poiInfluencer.allocatedRates || null}
            onChange={(e) => {
              handleOnChange("allocatedRates", e.target.value);
            }}
            error={errorForm.allocatedRates ? true : false}
            helperText={
              errorForm.allocatedRates ? errorForm.allocatedRates : ""
            }
          />
          {poiInfluencer?.id ? (
            <TextField
              label="Talent"
              size="small"
              variant="outlined"
              disabled={true}
              value={poiInfluencer.providerName}
            />
          ) : (
            <Autocomplete
              options={influencers}
              getOptionLabel={(option) =>
                option.firstName + " " + option.lastName
              }
              onChange={(e, newValue) => {
                handleOnChangeInfluencerAutocomplete(newValue || null);
              }}
              value={poiInfluencer.personFk ? influencers.find(i => i.id === poiInfluencer.personFk) : null}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={"Search Talent"}
                  variant={"outlined"}
                  size="small"
                  error={errorForm.person ? true : false}
                  helperText={errorForm.person ? errorForm.budget : ""}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: <>{params.InputProps.endAdornment}</>,
                  }}
                />
              )}
            />
          )}

          <Autocomplete
            value={
              poiInfluencer.startTime
                ? {
                  display: moment(poiInfluencer.startTime).format(
                    "MMMM YYYY"
                  ),
                  value: poiInfluencer.startTime,
                }
                : null
            }
            options={get12MonthsAheadAnd1MonthBackArr()}
            onChange={(event, option) => {
              handleOnChange("startTime", option.value);
            }}
            getOptionLabel={(option) => option.display}
            renderInput={(params) => (
              <TextField
                {...params}
                label={"Billing Month"}
                size="small"
                fullWidth={true}
                variant="outlined"
              />
            )}
            disableClearable
          />
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            size="small"
            className="default-button"
            variant="contained"
            onClick={handleSave}
          >
            Save
          </Button>
        </div>
      </Paper>
    </div>
  );
};

export default PeopleOfInfluenceForm;
