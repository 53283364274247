import React, { useState } from "react";
import { withStyles, styled, makeStyles } from "@material-ui/core/styles";
import {
  TextField,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Typography,
  Tooltip,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import AccessTime from "@material-ui/icons/AccessTime";
import ContractorPopUp from "../../ProjectOutputs/PopUps/ContractorPopUp";
import ContractButton from "../ScheduleItemTable/ContractButton";
import { RightClickMenu } from "../ScheduleItemTable/CellMenus";
import { DeleteItemDialog } from "../ScheduleItemTable/Dialogs";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import { format } from "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  CheckCircle as CheckCircleIcon,
  NotInterested as NotInterestedIcon
} from "@material-ui/icons";
import { connect } from "react-redux";
import { setDialogProps } from "../../../../actions/dialog";
import InformationDialogy from "../../../Shared/InformationDialogy";
import { getFirstStartTime, getLastEndTime } from "../../../../utils/utils";
import { getTimePortion } from "../../../../utils/TimeHelpers";
import { useUserContext } from "../../../../context/user/UserContext";
import { useAuth } from "../../../../context/auth/AuthContext";
//----------------------------------------
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: 200,
  },
  alignRight: {
    marginLeft: "auto",
    marginRight: 0,
  },
  margin: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  tableHeader: {
    fontSize: 13,
    fontWeight: "bold",
    paddingLeft: "2px",
    paddingRight: "2px",
  },
  namePadding: {
    paddingLeft: "10px",
  },
  removeMarginPadding: {
    padding: 0,
    margin: 0,
  },
}));

const StyledPaper = styled(Paper)({
  width: "100%",
});
const StyledButton = styled(Button)({
  color: "#000000",
  borderRadius: "20%",
});
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: "#9E9E9E",
    textTransform: "uppercase",
    fontWeight: "bold",
  },
  body: {
    fontSize: "1em",
  },
}))(TableCell);
const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);
const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const initialState = {
  mouseX: null,
  mouseY: null,
};

const ClientTable = (props) => {
  const classes = useStyles();
  const { user } = useAuth();

  const [state, setState] = useState(initialState);
  const [openContactDetailsPopUp, setOpenContactDetailsPopUp] = useState(false);
  const [currentProvider, setCurrentProvider] = useState(null);
  const [activeScheduleItem, setActiveScheduleItem] = useState();
  const [currentScheduleItemId, setCurrentScheduleItemId] = useState();
  const [openDeleteItemDialog, setOpenDeleteItemDialog] = useState(false);
  // const [currentRightClickedItem, setCurrentRightClickedItem] = useState(null);
  const [rightClickOpen, setRightClickOpen] = useState(false);
  // const [rightClickAgentOpen, setRightClickAgentOpen] = useState(false);

  const [showTimeError, setShowTimeError] = useState(false);
  const [timeErrorText, setTimeErrorText] = useState("");

  const handleCloseContext = () => {
    setState(initialState);
    setRightClickOpen(false);
    // setRightClickAgentOpen(false);
  };

  const handleContextClick = (event, index, rowObj) => {
    event.preventDefault();
    setState({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    });
    // setCurrentRightClickedItem(rowObj);
    setRightClickOpen(true);
  };

  return (
    <StyledPaper>
      {showTimeError && (
        <InformationDialogy
          setDialogProps={setDialogProps}
          buttonProps={{
            show: showTimeError,
            titleText: "Woops!",
            contentText: timeErrorText,
            buttonColor: "primary",
            buttonText: "Ok",
          }}
          handleButton={() => {
            setTimeErrorText("")
            setShowTimeError(false)
          }}
        />
      )}
      {openContactDetailsPopUp && (
        <ContractorPopUp
          handleModal={() => {
            setOpenContactDetailsPopUp(false);
          }}
          modalState={openContactDetailsPopUp}
          personId={currentProvider}
          scheduleItemId={currentScheduleItemId}
          reloadPersonList={props.reloadPersonList}
          reloadScheduleItemsList={() => props.reloadScheduleItemsList()}
          type="EditScheduleItem"
          providerType={props.providerType}
        />
      )}
      {openDeleteItemDialog && (
        <DeleteItemDialog
          table={props.providerType.toLowerCase()}
          scheduleItem={activeScheduleItem}
          getBudget={() => props.getBudget(props.project.id)}
          openDeleteItemDialog={openDeleteItemDialog}
          setSnackBarProps={props.setSnackBarProps}
          setOpenDeleteItemDialog={setOpenDeleteItemDialog}
          deleteScheduleItem={props.deleteScheduleItem}
          setActiveScheduleItem={setActiveScheduleItem}
        />
      )}
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <StyledTableCell
                className={[classes.tableHeader, classes.namePadding]}
                style={{
                  width: "300px",
                }}
              >
                Name
              </StyledTableCell>
              <StyledTableCell
                className={classes.tableHeader}
                align="center"
              >
                Start Time
              </StyledTableCell>
              <StyledTableCell
                className={classes.tableHeader}
                align="center"
              >
                End Time
              </StyledTableCell>
              <StyledTableCell
                className={classes.tableHeader}
              ></StyledTableCell>
              <StyledTableCell
                className={classes.tableHeader}
                align="center"
              >Schedule</StyledTableCell>
              <StyledTableCell
                className={classes.tableHeader}
              ></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.scheduleItems &&
              props.scheduleItems.map((scheduleItem, index) => (
                <StyledTableRow key={scheduleItem.id}>
                  <TableCell
                    className={classes.namePadding}
                    padding="none"
                    onContextMenu={(event) => {
                      setCurrentScheduleItemId(scheduleItem.id);
                      handleContextClick(event, index, scheduleItem);
                    }}
                  >
                    <HtmlTooltip
                      title={
                        scheduleItem.personFkNavigation !== null &&
                          scheduleItem.personFkNavigation !== undefined ? (
                            <div onClick={(event) => event.stopPropagation}>
                              <Typography color="inherit">
                                {scheduleItem.providerName}
                              </Typography>
                              <b>{"Phone"}</b>
                              {": "}{" "}
                              <em>
                                {scheduleItem.personFkNavigation.phoneNumber}
                              </em>{" "}
                              <br />
                              <b>{"Email"}</b>
                              {": "}{" "}
                              <em>
                                {scheduleItem.personFkNavigation.emailAddress}
                              </em>{" "}
                            </div>
                          ) : (
                            ""
                          )
                      }
                      interactive
                      arrow
                      placement="left"
                    >
                      <Typography
                        variant="body1"
                        component="div"
                        style={{
                          width: "300px",
                          marginLeft: "2px",
                          marginRight: "2px",
                        }}
                        className={"client-name-cell-body"}
                      >
                        <span
                          className={"pointer clickable"}
                          onClick={() => {
                            setCurrentProvider(scheduleItem.personFk);
                            setCurrentScheduleItemId(scheduleItem.id);
                            setOpenContactDetailsPopUp(true);
                          }}>
                          {scheduleItem.providerName}
                        </span>
                      </Typography>
                    </HtmlTooltip>
                    {rightClickOpen &&
                      currentScheduleItemId === scheduleItem.id ? (
                        <RightClickMenu
                          state={state}
                          scheduleItem={scheduleItem}
                          handleCloseContext={handleCloseContext}
                          setSnackBarProps={props.setSnackBarProps}
                        />
                      ) : (
                        ""
                      )}
                  </TableCell>
                  <TableCell padding="none" align="center">
                    <KeyboardTimePicker
                      disabled={props.project.isArchived === 1 || scheduleItem.contractStatus != "Send"}
                      margin="normal"
                      id={`start-time-picker-client-table-${index}`}
                      value={scheduleItem.startTime}
                      onChange={(date, value) => {
                        if (date instanceof Date && !isNaN(date)) {
                          var firstStartTime = getFirstStartTime(props.currentSchedule);
                          var scheduleDateString = format(firstStartTime, "yyyy-MM-dd")
                          var newDateTime = new Date(`${scheduleDateString} ${getTimePortion(date)}`);

                          if (firstStartTime !== null && newDateTime.getTime() < firstStartTime.getTime()) {
                            setTimeErrorText(`Invalid Time. That is before the first location. We have placed it at the earliest start time: ${format(firstStartTime, "hh:mm a")}`)

                            if (getTimePortion(new Date(scheduleItem.startTime)) === getTimePortion(firstStartTime)) {
                              setTimeErrorText(`Invalid Time. This item is already at the earliest possible time: ${format(firstStartTime, "hh:mm a")}, please choose something later`)
                            }
                            else {
                              props.editScheduleItem(
                                scheduleItem,
                                "client",
                                "startTime",
                                format(firstStartTime, "HH:mm:ss"),
                                1, user?.email
                              );
                            }
                            setShowTimeError(true)
                          }
                          else {
                            props.editScheduleItem(
                              scheduleItem,
                              "client",
                              "startTime",
                              format(date, "HH:mm:ss"),
                              1, user?.email
                            );
                          }
                        }
                      }}
                      KeyboardButtonProps={{
                        "aria-label": "start time",
                        style: {
                          ...{
                            padding: "0px",
                            margin: "0px",
                          },
                        },
                      }}
                      keyboardIcon={<AccessTime size="small" />}
                      className={classes.margin}
                      style={{
                        minWidth: "100px",
                        maxWidth: "100px",
                      }}
                    />
                  </TableCell>
                  <TableCell padding="none" align="center">
                    <KeyboardTimePicker
                      disabled={props.project.isArchived === 1 || scheduleItem.contractStatus != "Send"}
                      margin="normal"
                      id="end-time-picker"
                      onChange={(date, value) => {
                        if (date instanceof Date && !isNaN(date)) {
                          var lastEndTime = getLastEndTime(props.currentSchedule);
                          var scheduleDateString = format(lastEndTime, "yyyy-MM-dd")
                          var newDateTime = new Date(`${scheduleDateString} ${getTimePortion(date)}`);

                          if (lastEndTime !== null && newDateTime.getTime() > lastEndTime.getTime()) {
                            setTimeErrorText(`Invalid Time. That is after the last location. We have placed it at the latest possible time: ${format(lastEndTime, "hh:mm a")}`)
                            if (getTimePortion(new Date(scheduleItem.endTime)) === getTimePortion(lastEndTime)) {
                              setTimeErrorText(`Invalid Time. This item is already at the latest possible time: ${format(lastEndTime, "hh:mm a")}, please choose something earlier`)
                            }
                            props.editScheduleItem(
                              scheduleItem,
                              "client",
                              "endTime",
                              format(lastEndTime, "HH:mm:ss"),
                              1, user?.email
                            );
                            setShowTimeError(true)
                          }
                          else {
                            props.editScheduleItem(
                              scheduleItem,
                              "client",
                              "endTime",
                              format(date, "HH:mm:ss"),
                              1, user?.email
                            );
                          }
                        }
                      }}
                      value={scheduleItem.endTime}
                      KeyboardButtonProps={{
                        "aria-label": "start time",
                        style: {
                          ...{
                            padding: "0px",
                            margin: "0px",
                          },
                        },
                      }}
                      style={{
                        minWidth: "100px",
                        maxWidth: "100px",
                      }}
                      keyboardIcon={<AccessTime size="small" />}
                      className={classes.margin}
                    />
                  </TableCell>
                  <TableCell padding="none" align="center">
                    <ContractButton
                      providerType={props.providerType}
                      si={scheduleItem}
                      disabled={props.project.isArchived === 1}
                    />
                  </TableCell>
                  <TableCell padding="none" align="center">
                    {scheduleItem.scheduleSentTime !== null ? (
                      <CheckCircleIcon
                        className="schedule-sent-icon"
                      />
                    ) : (
                        <NotInterestedIcon
                          className="schedule-not-sent-icon"
                        />
                      )
                    }
                  </TableCell>
                  <TableCell padding="none" align="left">
                    {/* <ScheduleButton id={scheduleItem.id} /> */}
                    <StyledButton
                      disabled={props.project.isArchived === 1}
                      onClick={() => {
                        setActiveScheduleItem(scheduleItem);
                        setOpenDeleteItemDialog(true);
                      }}
                    >
                      <DeleteIcon style={{ color: "rgba(0, 0, 0, 0.54)" }} />
                    </StyledButton>
                  </TableCell>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </MuiPickersUtilsProvider>
    </StyledPaper>
  );
};

const mapStateToProps = (state, ownProps) => ({
  currentSchedule: state.schedules.schedule,
});
export default connect(mapStateToProps, {
  setDialogProps,
})(ClientTable);
