import { Button, Typography, } from "@material-ui/core";
import React, { useState } from "react";
import LaunchIcon from "@material-ui/icons/Launch";
const MediaReconciliationSheetActions = () => {
  const handleOpenMediaReconciliationSheet = () => {
    window.open(
      `https://docs.google.com/spreadsheets/d/1C_-NENSjGdjfP6c0hMWucw8zwZnX8YvPxzTStdacGAs/edit#gid=0`,
      "_blank"
    );
  };

  return (
    <div className="media-reconciliation-sheet-actions-root">
      <div>
        <Button
          onClick={handleOpenMediaReconciliationSheet}
          startIcon={<LaunchIcon />}
          variant="contained"
          className="default-button"
        >
          Open Reconciliation Sheet
        </Button>
      </div>
    </div>
  );
};

export default MediaReconciliationSheetActions;
