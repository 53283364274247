import React from "react";
import { useHistory } from "react-router-dom";

const ClientsMediaInvoiceStatus = ({ client }) => {
  const history = useHistory();
  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    history.push(`/Client/Home/${client.companyId}/2`);
  };
  return (
    <div style={{ display: "flex", fontSize: 10 }} onClick={handleClick}>
      {Object.entries(client?.mediaInvoiceStatusSummary).map(([key, value]) => (
        <div className={`chip-short ${value}-chip roboto-medium`}>{key}</div>
      ))}
    </div>
  );
};

export default ClientsMediaInvoiceStatus;
