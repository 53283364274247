import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Axios from "axios";
import {
    Grid,
    Button,
    Snackbar,
    Switch,
    FormControl,
    FormControlLabel,
    TextField
} from "@material-ui/core";
import "../Admin.css";
import MuiAlert from "@material-ui/lab/Alert";
import Loader from "../../Loader";


const useStyles = makeStyles((theme) => ({
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        // minHeight: 390,
        // width: "25vW",
        // overflow: "hidden"
    },
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(3),
    },
    root: {
        "& > *": {
            margin: theme.spacing(1),
            width: "25ch",
        },
    },
}));

const InfluencerSettings = (props) => {
    const classes = useStyles();
    const [successCreate, setSuccessCreate] = useState(false);
    const [failedCreate, setFailedCreate] = useState(false);
    const [fetchingData, setFetchingData] = useState(true);
    const [dataLoaded, setDataLoaded] = useState(false);

    const [defaultSettings, setDefaultSettings] = useState(null);
    //const [daysBeforeGoLive, setDaysBeforeGoLive] = useState(null);

    const closeSuccessCreateSnackbar = () => {
        setSuccessCreate(false);
    };

    const closeFailedCreateSnackbar = () => {
        setFailedCreate(false);
    };

    var snackbarSuccess = (
        <Snackbar
            anchorOrigin={{
                vertical: "top",
                horizontal: "right"
            }}
            open={successCreate}
            autoHideDuration={2000}
            onClose={closeSuccessCreateSnackbar}
        >
            <MuiAlert severity="success">Successfully Saved Changes!</MuiAlert>
        </Snackbar>
    );

    var snackbarFailed = (
        <Snackbar
            anchorOrigin={{
                vertical: "top",
                horizontal: "right"
            }}
            open={failedCreate}
            autoHideDuration={2000}
            onClose={closeFailedCreateSnackbar}
        >
            <MuiAlert severity="error">Failed to Save Changes!</MuiAlert>
        </Snackbar>
    );

    const handleChange = (event, text) => {
        var newValue = event.target.value;
        var newDefaults = {
            ...defaultSettings
        }
        switch (text) {
            case "Days before go live date":
                newDefaults = {
                    ...newDefaults,
                    "daysBeforeGoLiveDate": newValue
                }
                setDefaultSettings(newDefaults)
                break;
            default:
                break;
        }
    };

    const getField = (text, value) => {
        var filteredValue = value !== undefined ? value : null;
        return (
            <div className="textfield-padding">
                <TextField
                    id={text}
                    variant="outlined"
                    label={text}
                    value={filteredValue}
                    onChange={(event) => handleChange(event, text)}
                    fullWidth
                />
            </div>
        );
    }

    const saveChanges = () => {
        Axios.post(`Influencer/PostInfluencerDefaults`, defaultSettings)
            .then(res => {
                setSuccessCreate(true)
            }).catch(e => {
                setFailedCreate(true)
            })
    }

    useEffect(() => {
        if (fetchingData) {
            setFetchingData(false);
            Axios.get(`Influencer/GetInfluencerDefaults`)
                .then(res => {
                    setDefaultSettings(res.data)
                    setDataLoaded(true)
                }).catch(e => {
                    console.log(e)
                    setDataLoaded(false)
                })
        }
    }, [fetchingData]);

    if (!dataLoaded) {
        return <Loader />;
    } else {
        return (
            <main className={classes.content}>
                <div className="main-content" />
                <Typography variant="h4">Influencer Settings</Typography>
                <div classes={{
                    paper: classes.drawerPaper,
                }}>
                    <Grid container className="table-grid">
                        <Grid item xs={12} md={4} lg={3}>
                            {getField("Days before go live date", defaultSettings.daysBeforeGoLiveDate)}
                            <div className="button-div">
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={() => saveChanges()}
                                >
                                    Save
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                </div>
                {snackbarSuccess}
                {snackbarFailed}
            </main>
        );
    }
}

export default InfluencerSettings;
