import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  redirectTo: null,
  projectFilter: null,
  projectNames: [],
};
const adminMenu = createReducer(initialState, {
  SET_REDIRECT_TO: (state, action) => {
    return {
      ...state,
      redirectTo: action.payload.redirectTo,
    };
  },
  SET_PROJECT_FILTER: (state, action) => {
    return {
      ...state,
      projectFilter: action.payload.projectFilter,
    };
  },
  GET_PROJECT_NAMES: (state, action) => {
    return {
      ...state,
      projectNames: action.payload.projectNames,
    };
  },
});

export default adminMenu;
