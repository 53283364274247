import { Typography } from '@material-ui/core';
import React from 'react';

const ImagePreview = ({ imageUrl, handleOnClick }) => {
  return (
    <div style={{ maxWidth: '100%', maxHeight: '100%', overflow: 'hidden' }}>
      <img
        src={imageUrl}
        alt="Image Preview"
        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
        onClick={handleOnClick}
      // , maxHeight: 300
      />
    </div>
  );
};

const VideoPreview = ({ 
  videoUrl, 
  showControls,
  handleOnClick
 }) => {
  return (
    <div style={{ maxWidth: '100%', maxHeight: '100%', overflow: 'hidden' }}>
      <video
        src={videoUrl}
        controls={showControls}
        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
        onClick={handleOnClick}
      />
    </div>
  );
};

const FilePreview = ({
  handleOnClick = null,
  fileUrl,
  showControls = true,
  containerClassName = ""
}) => {
  const isImage = /\.(jpeg|jpg|png|gif|bmp)$/i.test(fileUrl?.toLowerCase());
  const isVideo = /\.(mp4|mov|avi|mkv|wmv)$/i.test(fileUrl?.toLowerCase());

  return (
    <div style={containerClassName === "" ? { width: '100px' } : {}} className={containerClassName}>
      {isImage && <ImagePreview imageUrl={fileUrl} handleOnClick={handleOnClick}/>}
      {isVideo && <VideoPreview
        videoUrl={fileUrl}
        showControls={showControls}
        handleOnClick={handleOnClick}
      />}
      {!isImage && !isVideo && <Typography>Unsupported file format</Typography>}
    </div>
  );
};

export default FilePreview;
