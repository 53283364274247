import { Button } from "@material-ui/core";
import React from "react";
import "./addNewPOIInfluencerButton.scss";
const AddNewPOIInfluencerButton = ({ handleClick, handleOpenCreateNewInfluencer, handleReimbursementClick }) => {
  return (
    <div className="add-new-poi-influencer-button-root">
      <Button
        size="small"
        className="default-button"
        variant="contained"
        onClick={handleReimbursementClick}
      >
        Create Reimbursement
      </Button>
      <Button
        size="small"
        className="default-button"
        variant="contained"
        onClick={handleOpenCreateNewInfluencer}
      >
        Create New Talent
      </Button>
      <Button
        size="small"
        className="default-button"
        variant="contained"
        onClick={handleClick}
      >
        Add Talent
      </Button>
    </div>
  );
};

export default AddNewPOIInfluencerButton;
