import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { Close as CloseIcon } from "@material-ui/icons";
import moment from "moment";
import DatePicker from "react-multi-date-picker";
import { Autocomplete } from "@material-ui/lab";
import { get3monthsBeforeAndAfterCurrentDate } from "../../../../utils/TimeHelpers";
import Axios from "axios";
import Spinner from "../../../Shared/Spinner/Spinner";

const GenerateMediaInvoiceDialog = ({
  open,
  handleClose,
  isGeneratingInvoices,
  handleGenerateInvoices,
  selectedBillingMonth,
  setSelectedBillingMonth,
  loadingClientDeliveries
}) => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    await handleGenerateInvoices(selectedBillingMonth, true);
  };

  return (
    <Dialog open={open} maxWidth="xs" fullWidth id="add-budget-dialog">
      <div className="close-and-transfer-dialog-close-icon-container">
        {!isGeneratingInvoices && (
          <IconButton
            size="small"
            className="close-and-transfer-dialog-close-icon"
            onClick={handleClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        )}
      </div>
      <DialogTitle style={{ display: "grid" }}>
        <Typography
          variant="h6"
          className="roboto-bold"
        >{`Generate Media Invoices`}</Typography>
        <DialogContentText style={{ textAlign: "left" }}>
          <Typography className="roboto-regular add-budget-description">
            This action will create invoices based on media records for the
            selected month.
          </Typography>
        </DialogContentText>
      </DialogTitle>
      <DialogContent>
        <Autocomplete
          disabled={isGeneratingInvoices}
          value={
            selectedBillingMonth
              ? {
                display: selectedBillingMonth.format("MMMM YYYY"),
                value: selectedBillingMonth,
              }
              : null
          }
          options={get3monthsBeforeAndAfterCurrentDate()}
          onChange={(event, option) => {
            setSelectedBillingMonth(option.value);
          }}
          getOptionLabel={(option) => option.display}
          renderInput={(params) => (
            <TextField
              {...params}
              label={"Billing Month"}
              size="small"
              fullWidth={true}
              variant="outlined"
            />
          )}
          disableClearable
        />
      </DialogContent>
      <DialogActions>
        {loadingClientDeliveries ?
          <Typography variant="caption">
            Fetching Media Deliveries...
          </Typography> :
          (isGeneratingInvoices ? (
            <div style={{ padding: 10 }}>
              <CircularProgress />
            </div>
          ) : (
            <div className="add-budget-popup-action-container">
              <Button
                style={{ marginRight: 5 }}
                className="default-button-grey add-budget-button"
                onClick={(e) => {
                  handleClose();
                }}
              >
                Cancel
              </Button>
              <Button
                style={{ marginLeft: 5 }}
                className="default-button add-budget-button"
                onClick={handleSubmit}
                disabled={loading || loadingClientDeliveries}
              >
                Save
              </Button>
            </div>
          ))
        }
      </DialogActions>
    </Dialog>
  );
};

export default GenerateMediaInvoiceDialog;
