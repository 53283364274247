import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  allRoles: [],
  allUsers: [],
  activeUser: { loading: true },
  errorMessage: "",
  userEmail: "",
};

const roles = createReducer(initialState, {
  GET_USERS_SUCCESS: (state, action) => {
    return {
      ...state,
      allUsers: action.payload,
    };
  },
  GET_USERS_FAILURE: (state, action) => {
    return {
      ...state,
      errorMessage: action.payload,
    };
  },
  ID_ACTIVE_USER: (state, action) => {
    return {
      ...state,
      activeUser: state.allUsers.find((u) => u.email == action.payload),
    };
  },
  SET_USER_EMAIL: (state, action) => {
    return {
      ...state,
      userEmail: action.payload,
    };
  },
});

export default roles;
