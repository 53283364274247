import React, { useState, useEffect } from "react";
import axios from "axios";
import MasterStatusItemRow from "../Client/MasterStatus/MasterStatusItemRow";
import { Collapse, Table, TableBody, Typography } from "@material-ui/core";

const HomepageClientCardFooter = ({
    department,
    setProjectStatusData,
    showHidden,
    client,
    setShowConfirmation,
    projectStatus,
    project
}) => {
    const [showCampaigns, setShowCampaigns] = useState(false);
    const [clients, setClients] = useState([]);

    const getInfluencerStatus = async (projectId, newShowInfluencersValue) => {
        if (newShowInfluencersValue) {
            try {
                const response = await axios.get(`Influencer/GetInfluencerMasterStatusByProjectId/${projectId}`);
                setClients(response.data);
                setShowCampaigns(true);
            } catch (error) {
                console.log(error);
                setClients([]);
                setShowCampaigns(false);
            }
        } else {
            setShowCampaigns(false);
        }
    };

    const handleShowInfluencersClick = () => {
        getInfluencerStatus(projectStatus.projectId, !showCampaigns);
    };

    const handleHideProjectClick = () => {
        setShowConfirmation(true);
        setProjectStatusData({
            companyId: client.companyId,
            jobId: project.jobId,
            projectStatusId: projectStatus.id,
        });
    };

    const isSocialClub = department?.toLowerCase() === "the social club";

    return (
        <div>
            <div className="homepage-client-card-footer" style={isSocialClub ? { justifyContent: 'space-between' } : {justifyContent: 'flex-end'}}>
                {isSocialClub && (
                    <span className="show-influencers-text link" onClick={handleShowInfluencersClick}>
                        {showCampaigns ? 'Hide Influencer Status' : 'Show Influencer Status'}
                    </span>
                )}
                <span className="hide-project-text link" onClick={handleHideProjectClick} >
                    {showHidden ? 'Unhide Project' : 'Hide Project'}
                </span>
            </div>

            {isSocialClub && showCampaigns && (
                <Table size="small">
                    <TableBody>
                        {clients.length > 0 ? (
                            clients.map(clientsDto =>
                                clientsDto?.projects?.map(campaign => (
                                    <MasterStatusItemRow campaign={campaign} key={`${campaign.campaignId}-master-status-item-row`} />
                                ))
                            )
                        ) : (
                            <Typography variant="body2" color="textSecondary" style={{ padding: '10px' }}>
                                There are no campaigns.
                            </Typography>
                        )}
                    </TableBody>
                </Table>
            )}
        </div>
    );
};

export default HomepageClientCardFooter;
