import { Typography } from "@material-ui/core";
import React from "react";
import { currencyFormat } from "../../../../utils/GeneralUtils";

const ReconciliationTotals = ({ payable, allocatedAmount }) => {
  return (
    <div className="reconciliation-totals-root">
      <div className="reconciliation-totals-wrapper">
        <Typography variant="caption" className="reconciliation-totals-label">
          Bill Total
        </Typography>
        <div className="reconciliation-totals-amount-container">
          <Typography variant="caption">
            {currencyFormat(payable?.subtotal)}
          </Typography>
        </div>
      </div>
      <div className="reconciliation-totals-wrapper">
        <Typography variant="caption" className="reconciliation-totals-label">
          Total Allocated
        </Typography>
        <div className="reconciliation-totals-amount-container">
          <Typography variant="caption">
            {currencyFormat(allocatedAmount)}
          </Typography>
        </div>
      </div>
      <div className="reconciliation-totals-wrapper">
        <Typography variant="caption" className="reconciliation-totals-label">
          Still to Match
        </Typography>
        <div className="reconciliation-totals-amount-container">
          <Typography variant="caption">
            {currencyFormat(payable?.subtotal - allocatedAmount)}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default ReconciliationTotals;
