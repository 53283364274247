import { Typography } from "@material-ui/core";
import React from "react";
import ReconcilePurchaseOrderTable from "./ReconcilePurchaseOrderTable";
import ReconcileNewPurchaseOrder from "./ReconcileNewPurchaseOrder";

const ReconcilePurchaseOrder = ({
  payable,
  reconLines,
  handleUpdateSelectedReconLines,
  handleRemoveSelectedReconLine,
  setSnackBarProps,
  addToReconLines
}) => {
  return (
    <div>
      {payable.companyId ? (
        <div className="reconcile-purchase-order-container">
          <ReconcilePurchaseOrderTable
            reconLines={reconLines}
            payable={payable}
            setSnackBarProps={setSnackBarProps}
            handleUpdateSelectedReconLines={handleUpdateSelectedReconLines}
            handleRemoveSelectedReconLine={handleRemoveSelectedReconLine}
          />
          <ReconcileNewPurchaseOrder
            payable={payable}
            setSnackBarProps={setSnackBarProps}
            addToReconLines={addToReconLines}
          />
        </div>
      ) : (
        <div className="reconciliation-centre-no-records-container">
          <Typography>
            Please enter a client to display the Purchase Orders.
          </Typography>
        </div>
      )}
    </div>
  );
};

export default ReconcilePurchaseOrder;
