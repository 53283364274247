import React, { useState, useRef, useEffect } from 'react';
import Axios from "axios";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import {
    Grid,
    Button,
    ButtonGroup,
    ClickAwayListener,
    Grow,
    Paper,
    Popper,
    MenuItem,
    MenuList
} from '@material-ui/core';
import { contains } from "../../../utils/utils";

const CateringButton = (props) => {
    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);
    const [buttonName, setButtonName] = useState(props.buttonName);
    const [buttonColor, setButtonColor] = useState();
    const [options, setOptions] = useState([]);

    useEffect(() => {
        if (buttonName === "Catering Confirmed") {
            setOptions([
                'View Order',
                'Cancel Order',
                'Send For Invoicing'
            ])
            // setButtonName("Catering Confirmed");
            setButtonColor("#008000");
        }
        else if (buttonName === "Cancelling") {
            setOptions([]);
            // setButtonName("Order Sent");
            setButtonColor("#FFA500");
        }
        else if (buttonName === "Sent") {
            setOptions(['Cancel Order', 'View Order']);
            setButtonName("Order Sent");
            setButtonColor("#3f51BF");
        }
        else if (buttonName === "Catering Invoiced") {
            setOptions(['View Order']);
            setButtonColor("#008000");
        }
    }, [props.buttonName])

    const handleMenuItemClick = (event, index) => {
        if (options[index] === "View Order") {
            props.onViewOrder();
        }
        else if (options[index] === "Send For Invoicing") {
            Axios.get(`Catering/UpdateToInvoiced/${props.scheduleId}`)
                .then(res => {
                    console.log(res)
                })
                .catch(e => {
                    console.log(e)
                })
                .finally(() => {
                    props.reloadSchedule();
                })

            Axios.get(`CateringFinances/GetLastCateringFinance`)
                .then(res => {
                    var cateringFinance = res.data;
                    var cateringOrder = props.cateringOrder;
                    var orderJson = JSON.parse(cateringOrder.orderJson);

                    // var gstInclusivePrice = cateringOrder.cost - parseFloat(orderJson.deliveryCost);
                    // var gstAdded = (gstInclusivePrice * 3) / 23;
                    // var gstExclusivePrice = (gstInclusivePrice - gstAdded) + parseFloat(orderJson.gstExclusiveDeliveryCost);

                    // var deliveryGstAdded = orderJson.deliveryCost - orderJson.gstExclusiveDeliveryCost;

                    var deliveryGstAdded = orderJson.deliveryCost - orderJson.gstExclusiveDeliveryCost;
                    var gstInclusiveMeal = parseFloat(orderJson.mealCost) * (orderJson.mealCount - orderJson.kidsMeal);
                    var gstInclusiveKidsMeal = parseFloat(orderJson.kidsMealCost) * orderJson.kidsMeal;

                    var mealGstAdded = (gstInclusiveMeal * 3) / 23;
                    var kidsMealGstAdded = (gstInclusiveKidsMeal * 3) / 23;

                    var gstExclusivePrice = (gstInclusiveMeal - mealGstAdded) + (gstInclusiveKidsMeal - kidsMealGstAdded) + parseFloat(orderJson.gstExclusiveDeliveryCost);

                    var arr = orderJson.date.split("/");
                    var jobDate = `${arr[2]}-${arr[1]}-${arr[0]}`;

                    // console.log("gstInclusiveMeal: {0}", gstInclusiveMeal);
                    // console.log("gstInclusiveKidsMeal: {0}", gstInclusiveKidsMeal);
                    // console.log("mealGstAdded: {0}", mealGstAdded);
                    // console.log("kidsMealGstAdded: {0}", kidsMealGstAdded);
                    // console.log("deliveryGstAdded: {0}", deliveryGstAdded);
                    // console.log("gstExclusivePrice: {0}", gstExclusivePrice);
                    Axios.post(`CateringInvoices/GenerateInvoice/${props.scheduleId}`, {
                        dateCreated: new Date(),
                        dateAccepted: new Date(),
                        firstName: cateringFinance.tradingName,
                        lastName: "",
                        totalDue: cateringOrder.cost,//gstInclusivePrice,
                        bankAccount: cateringFinance.bankAccount,//"12-3107-0073828-00",
                        gstReg: 1,
                        irdNumber: cateringFinance.gstNumber,//"116-323-176",
                        category: "Catering",
                        companyName: cateringFinance.companyName,//"Eat My Lunch Ltd",
                        taxWithheld: "",//(gstAdded + deliveryGstAdded).toFixed(2),
                        gstAdded: (mealGstAdded + kidsMealGstAdded + deliveryGstAdded).toFixed(2),//gstAdded.toFixed(2),
                        subtotal: gstExclusivePrice,
                        jobDate: new Date(jobDate),
                        //jobName: props.scheduleName,
                        driveFileId: "",
                        cateringContractFk: null,
                        costAccount: "",
                        emailAddress: cateringFinance.emailAddress,//"jethrocamistry@gmail.com",//"buyone@eatmylunch.nz",
                        phoneNumber: cateringFinance.phoneNumber,//"09-886 4644",
                        jobFee: gstExclusivePrice,
                        invoiceNumber: "",
                        wtRate: null
                    });
                })
                .catch(e => {
                    console.log(e);
                })
            setOptions([]);
            setButtonName("Catering Invoiced");
            setButtonColor("#008000");
        }
        else if (options[index] === "Cancel Order") {
            setOptions([]);
            setButtonName("Cancelling");
            setButtonColor("#FFA500");
            props.cancelCatering();
        }
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        // if (anchorRef.current && anchorRef.current.contains(event.target)) {
        if (anchorRef.current && contains(anchorRef.current, event.target)) {
            return;
        }
        setOpen(false);
    };

    return (
        <Grid container direction="column" alignItems="center">
            <Grid item xs={12}>
                <ButtonGroup
                    variant="contained"
                    color="primary"
                    ref={anchorRef}
                >
                    <Button style={{ backgroundColor: buttonColor }} >{buttonName}</Button>
                    <Button
                        color="primary"
                        size="small"
                        aria-controls={open ? 'split-button-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        aria-label="select merge strategy"
                        aria-haspopup="menu"
                        onClick={handleToggle}
                        style={{ backgroundColor: buttonColor }}
                    >
                        <ArrowDropDownIcon />
                    </Button>
                </ButtonGroup>
                <Popper
                    open={open}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    transition
                    disablePortal
                    style={{
                        zIndex: 9999
                    }}
                >
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                            }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList id="split-button-menu">
                                        {options.map((option, index) => (
                                            <MenuItem
                                                key={index}
                                                onClick={(event) => handleMenuItemClick(event, index)}
                                            >
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </Grid>
        </Grid>
    );
}

export default CateringButton;