import React, { useEffect, useState } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    CircularProgress,
    Typography,
    IconButton,
    InputAdornment
} from "@material-ui/core";
import {
    CallMade as CallMadeIcon,
    Close as CloseIcon
} from '@material-ui/icons';
import { Autocomplete } from "@material-ui/lab";
import axios from "axios";
import moment from "moment";
import { currencyFormat, isEmpty, isNumeric } from "../../../utils/GeneralUtils";
import "./budgetTransfer.scss";
import Spinner from "../../Shared/Spinner/Spinner";

const BudgetTransferPopup = ({
    open,
    handleClose,
    handleSaveCallback,
    defaultClientId = null
}) => {
    const [saving, setSaving] = useState(false);
    const [client, setClient] = useState(null);
    const [fromBudget, setFromBudget] = useState(null);
    const [toBudget, setToBudget] = useState(null);
    const [amountToTransfer, setAmountToTransfer] = useState(0);

    const [clients, setClients] = useState([]);
    const [budgetsLoading, setBudgetsLoading] = useState(false);
    const [budgetItems, setBudgetItems] = useState([]);

    const [success, setSuccess] = useState(false);
    const initialErrorState = {
        client: "",
        fromBudget: "",
        toBudget: "",
        amountToTransfer: ""
    };
    const [errorForm, setErrorForm] = useState(initialErrorState);

    useEffect(() => {
        if (!success) {
            fetchCompaniesWithProjects();
        }
    }, [success]);

    useEffect(() => {
        if (client) {
            fetchAllBudget();
        }
    }, [client])

    useEffect(() => {
        if(clients && defaultClientId !== null){
            var foundClient = clients.find(c => c.id === defaultClientId);
            if(foundClient){
                setClient(foundClient)
            }
        }
    }, [clients])

    const fetchCompaniesWithProjects = () => {
        axios
            .get(`/companies/GetCompaniesWithProjects?organisationId=2`)
            .then((res) => {
                setClients(res.data);
            })
            .catch((err) => { }

            );
    };

    const fetchAllBudget = () => {
        setBudgetsLoading(true);
        axios
            .get(`/budgetitems/GetBudgetForCompanyByProject/${client?.id}`)
            .then(({ data }) => {
                setBudgetItems(data);
            }).catch((e) => {
                console.log(e)
            }).finally(() => {
                setBudgetsLoading(false);
            });
    };

    const isValid = () => {
        let isValid = true;
        const tempError = { ...initialErrorState };

        if (isEmpty(client)) {
            tempError.client = "Please select a client";
            isValid = false;
        }

        if (isEmpty(fromBudget)) {
            tempError.fromBudget = "Please select a budget";
            isValid = false;
        }

        if (isEmpty(toBudget)) {
            tempError.toBudget = "Please select a budget";
            isValid = false;
        }

        if (amountToTransfer === "0" || amountToTransfer === parseFloat(0)) {
            tempError.amountToTransfer = "The amount cannot be 0";
            isValid = false;
        }

        if (!isEmpty(fromBudget) && parseFloat(amountToTransfer) > parseFloat(fromBudget.remaining)) {
            tempError.amountToTransfer = "Not enough funds";
            isValid = false;
        }

        if(!isEmpty(fromBudget) && !isEmpty(toBudget) && fromBudget.budgetId === toBudget.budgetId){
            tempError.fromBudget = "Cannot be the same as the 'To Budget'";
            tempError.toBudget = "Cannot be the same as the 'From Budget'";
            isValid = false;
        }

        if (!isValid) {
            setErrorForm(tempError);
        }

        return isValid;
    }

    const handleTransfer = () => {
        if (isValid()) {
            setSaving(true);
            axios.post(`/budgetItems/TransferBudgetItems`, {
                budgetFrom: fromBudget?.budgetId,
                budgetTo: toBudget?.budgetId,
                amount: amountToTransfer,
            }).then(async ({ data }) => {
                setSuccess(true);
            }).catch((err) => {

            }).finally(() => {
                setSaving(false);
            });
        }
    };

    const handleEditThis = () => {
        var newFromRemaining = parseFloat(fromBudget.remaining) - parseFloat(amountToTransfer);
        var newToRemaining = parseFloat(toBudget.remaining) + parseFloat(amountToTransfer);
        setFromBudget({
            ...fromBudget,
            remaining: newFromRemaining
        })
        setToBudget({
            ...toBudget,
            remaining: newToRemaining
        })
        setBudgetItems([...budgetItems.map(b => {
            if (b.budgetId === fromBudget.budgetId) {
                return {
                    ...b,
                    remaining: newFromRemaining
                }
            } else if (b.budgetId === toBudget.budgetId) {
                return {
                    ...b,
                    remaining: newToRemaining
                }
            } else {
                return b;
            }
        })])
        setAmountToTransfer(0);
        setSuccess(false);
    }

    const transferBudgetSection = () => {
        return (
            <React.Fragment>
                <Typography variant="h5" className="roboto-medium"><strong>Move Budget Tool</strong></Typography>
                <Typography variant="caption" style={{ paddingTop: 10 }} className="roboto-medium">
                    <strong>Select budget items to transfer</strong>
                </Typography>
                <div className="budget-transfer-field-container roboto-regular">
                    {clients?.length > 0 && (
                        <Autocomplete
                            disabled={defaultClientId}
                            value={client}
                            options={clients}
                            getOptionLabel={(option) => option.name}
                            onChange={(e, newValue) => {
                                setClient(newValue);
                                setFromBudget(null);
                                setToBudget(null);
                                setAmountToTransfer(0);
                                setErrorForm(initialErrorState)
                            }}
                            selectOnFocus
                            renderOption={(option) => {
                                return (
                                    <li key={option.id} className="roboto-regular">
                                        {option.name}
                                    </li>
                                );
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    className="roboto-regular"
                                    label="Client"
                                    size="small"
                                    fullWidth={true}
                                    variant="filled"
                                />
                            )}
                        />
                    )}
                </div>
                {client && !budgetsLoading && (
                    <React.Fragment>
                        <div className="budget-transfer-field-container">
                            <Autocomplete
                                value={fromBudget}
                                options={budgetItems}
                                onChange={(event, newValue) => {
                                    setFromBudget(newValue);
                                    if (errorForm.fromBudget) {
                                        setErrorForm({
                                            ...errorForm,
                                            fromBudget: ""
                                        })
                                    }
                                }}
                                getOptionLabel={(option) =>
                                    `${option.projectCategory} ${" - "}${moment(option.billingDate).format("MMM/YY")}${" - "}${currencyFormat(
                                        option.remaining
                                    )}`
                                }
                                renderOption={(option) => {
                                    return (
                                        <li key={option.budgetId}>
                                            <Typography variant="caption" className="roboto-regular">
                                                {option.projectCategory}{" - "}{moment(option.billingDate).format("MMM/YY")}{" - "}
                                                {currencyFormat(option.remaining)}
                                            </Typography>
                                        </li>
                                    );
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={`From Budget`}
                                        fullWidth={true}
                                        variant="filled"
                                        // className={`roboto-regular ${errorForm.fromBudget && "highlight-border-red"}`}
                                        error={errorForm.fromBudget ? true : false}
                                        helperText={errorForm.fromBudget ? errorForm.fromBudget : ""}
                                    />
                                )}
                                disableClearable

                            />
                        </div>
                        <div className="budget-transfer-field-container">
                            <Autocomplete
                                value={toBudget}
                                options={budgetItems}
                                onChange={(event, newValue) => {
                                    setToBudget(newValue);
                                    if (errorForm.toBudget) {
                                        setErrorForm({
                                            ...errorForm,
                                            toBudget: ""
                                        })
                                    }
                                }}
                                getOptionLabel={(option) =>
                                    `${option.projectCategory} ${" - "}${moment(option.billingDate).format("MMM/YY")}${" - "}${currencyFormat(
                                        option.remaining
                                    )}`
                                }
                                renderOption={(option) => {
                                    return (
                                        <li key={option.budgetId}>
                                            <Typography variant="caption" className="roboto-regular">
                                                {option.projectCategory}{" - "}{moment(option.billingDate).format("MMM/YY")}{" - "}
                                                {currencyFormat(option.remaining)}
                                            </Typography>
                                        </li>
                                    );
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={`To Budget`}
                                        fullWidth={true}
                                        variant="filled"
                                        // className={`roboto-regular ${errorForm.toBudget && "highlight-border-red"}`}
                                        error={errorForm.toBudget ? true : false}
                                        helperText={errorForm.toBudget ? errorForm.toBudget : ""}
                                    />
                                )}
                                disableClearable
                            />
                        </div>
                        <div className="budget-transfer-field-container">
                            <TextField
                                id="amount-to-transfer"
                                label="Amount to Transfer"
                                fullWidth
                                variant="filled"
                                value={amountToTransfer}
                                onChange={(e) => {
                                    if (isNumeric(e.target.value) || e.target.value === "") {
                                        setAmountToTransfer(e.target.value)
                                        if (errorForm.amountToTransfer) {
                                            setErrorForm({
                                                ...errorForm,
                                                amountToTransfer: ""
                                            })
                                        }
                                    }
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">$</InputAdornment>
                                    ),
                                    // className: `roboto-regular ${errorForm.amountToTransfer && "highlight-border-red"}`
                                }}
                                error={errorForm.amountToTransfer ? true : false}
                                helperText={errorForm.amountToTransfer ? errorForm.amountToTransfer : ""}
                            />
                        </div>
                    </React.Fragment>
                )}
                {budgetsLoading && (
                    <div>
                        <Spinner />
                        <div style={{ marginTop: 15, textAlign: "center" }}>
                            <Typography variant="h6">
                                Grabbing budgets
                            </Typography>
                        </div>
                    </div>
                )}
                {saving ? (
                    <DialogActions style={{ padding: 10 }}>
                        <CircularProgress />
                    </DialogActions>
                ) : (
                    <DialogActions style={{ paddingTop: 30 }} >
                        <Button disabled={saving || budgetsLoading} className="default-button-grey" variant="contained" onClick={handleClose}>Cancel</Button>
                        <Button
                            disabled={saving || budgetsLoading}
                            className={"default-button"}
                            variant="contained"
                            onClick={() => handleTransfer()}
                            autoFocus>
                            Transfer Now <CallMadeIcon fontSize="small" />
                        </Button>
                    </DialogActions>
                )}
            </React.Fragment>
        )
    }

    const successSection = () => {
        return (
            <React.Fragment>
                <Typography variant="h4" className="roboto-medium transfer-sucess">Success 🤩</Typography>
                <div className="budget-transfer-success-container">
                    <Typography className="roboto-medium"><strong>{`$${currencyFormat(amountToTransfer)} from`}</strong></Typography>
                    <Typography className="robot-font light-text">{`${fromBudget.projectCategory} - ${moment(fromBudget.billingDate).format("MMM/YY")}`}</Typography>
                    <Typography className="roboto-regular">has been <span className="roboto-medium">moved to</span></Typography>
                    <Typography className="roboto-regular light-text">{`${toBudget.projectCategory} - ${moment(toBudget.billingDate).format("MMM/YY")}`}</Typography>
                </div>
                <div className="budget-transfer-edit-container">
                    <Typography className="roboto-regular">Need to edit this? <span className="link" onClick={() => handleEditThis()}>Edit here</span></Typography>
                </div>
            </React.Fragment>
        )
    }

    return (
        <div>
            <Dialog
                id={"budget-transfer-dialog"}
                open={open}
                onClose={(e, reason) => {
                    if (reason !== "backdropClick") {
                        handleClose();
                    }
                }}
                classes={{ paper: "budget-transfer-paper" }}
            >
                <div className="budget-transfer-dialog-close-icon-container">
                    <IconButton size="small" className="budget-transfer-dialog-close-icon"
                        onClick={() => {
                            handleClose();
                        }}>
                        <CloseIcon fontSize="small" />
                    </IconButton>
                </div>
                {!success && transferBudgetSection()}
                {success && successSection()}
            </Dialog>
        </div>
    );
};

export default BudgetTransferPopup;
