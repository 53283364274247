import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { makeStyles, styled, withStyles } from "@material-ui/core/styles";
import {
    Modal,
    AppBar,
    Toolbar,
    IconButton,
    Grid,
    TextField,
    FormControlLabel,
    Checkbox,
    Button,
    CircularProgress
} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import Typography from '@material-ui/core/Typography';
import {
    Close as CloseIcon,
    Save as SaveIcon,
    Edit as EditIcon,
    AddCircle as AddCircleIcon
} from "@material-ui/icons";
import EmployeeTable from "../EmployeeTable";

//---------Styling -----------------
const useStyles = makeStyles(theme => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflow: "hidden"
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        height: "600px",
        maxHeight: "600px",
        width: "600px",
        overflowY: "auto"
    }
}));
//-----------------------------------

const CompanyDetailsPopUp = (props) => {
    const classes = useStyles();

    console.log(props.companyObj);
    const [isEditing, setIsEditing] = React.useState(false);
    const [isSaving, setIsSaving] = React.useState(false);
    const [currentCompany, setCurrentCompany] = useState({
        name: null,
        address: null,
        website: null,
        about: null,
        gstregistered: 0,
        gstnumber: ""
    })
    const { name, address, website, about, gstregistered, gstnumber } = currentCompany;
    const [allPeople, setAllPeople] = React.useState([]);
    const [allEmployees, setAllEmployees] = React.useState([]);
    const [employeesToAdd, setEmployeesToAdd] = React.useState([]);
    const [newEmployee, setNewEmployee] = React.useState(null);

    // -----Functions-----------
    const handleCurrentCompanyChange = key => event => {
        setCurrentCompany({ ...currentCompany, [key]: event.target.value })
    }
    const handleGSTChange = (key) => event => {
        var value = event.target.checked ? 1 : 0;
        setCurrentCompany({ ...currentCompany, [key]: value });
    }
    const handleSave = () => {
        setIsSaving(true);
        Axios.put(`Companies/PutCompany/${props.companyObj.id}`, {
            id: props.companyObj.id,
            name: name,
            address: address,
            website: website,
            about: about,
            gstregistered: gstregistered,
            gstnumber: gstnumber
        })
            .then(res => {
                console.log(res);
                setIsSaving(false);
                setIsEditing(false);
            })
            .catch(e => {
                console.log(e);
                setIsSaving(false);
            })
        if(employeesToAdd.length > 0){
            employeesToAdd.forEach(employee => {
                Axios.post(`CompanyPeoples/PostCompanyPeople`, {
                    personFk: employee.id,
                    companyFk: props.companyObj.id
                })
                .then(res => {
                    console.log(res);
                })
                .catch(e => {
                    console.log(e);
                })
            })
        }
    }
    const getAllPeople = () => {
        Axios.get(`People/GetPeople`)
            .then(res => {
                setAllPeople(res.data);
            })
            .catch(e => {
                console.log(e);
            })
    }
    const addEmployee = (personObj) => {
        var newEmployees = employeesToAdd;
        var currentEmployees = allEmployees;

        newEmployees.push(personObj);
        setEmployeesToAdd(newEmployees);

        currentEmployees.push(personObj);
        setAllEmployees(currentEmployees);

        setNewEmployee(null);
    }
    //-----End Of Functions--------
    //-----Use Effects--------------
    useEffect(() => {
        getAllPeople();
        setCurrentCompany({
            name: props.companyObj.name,
            address: props.companyObj.address,
            website: props.companyObj.website,
            about: props.companyObj.about,
            gstregistered: props.companyObj.gstregistered,
            gstnumber: props.companyObj.gstnumber
        });
        var employees = [];
        props.companyObj.companyPeople.map(companyPerson => {
            employees.push(companyPerson.personFkNavigation);
        })
        setAllEmployees(employees);
    }, []);
    //-----End of Use Effects-----------

    return (
        <Modal
            aria-labelledby="contractor-modal"
            aria-describedby="contractor-modal"
            open={props.modalState}
            onClose={props.handleModal}
            className={classes.modal}
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
        >
            <div>
                <AppBar position="static" style={{ background: "#217F8B" }}>
                    <Toolbar variant="dense" style={{ paddingRight: 0, display: 'flex' }}>
                        <Typography
                            variant="subtitle1"
                            align="center"
                            style={{ flexGrow: 1 }}
                        >
                            {name} Details
                        </Typography>
                        <div style={{ display: 'flex' }}>
                            <IconButton
                                aria-haspopup="true"
                                onClick={() => setIsEditing(true)}
                                style={!isEditing ? {} : { display: 'none' }}
                            >
                                <EditIcon />
                            </IconButton>
                            <IconButton
                                color="inherit"
                                onClick={() => handleSave()}
                                style={isEditing ? {} : { display: 'none' }}
                                disabled={isSaving}
                            >
                                <SaveIcon style={{ color: "#2d2d2d" }} />
                            </IconButton>
                            {
                                isSaving ?
                                    <CircularProgress />
                                    :
                                    <IconButton
                                        onClick={props.handleModal}
                                        color="inherit"
                                    >
                                        <CloseIcon />
                                    </IconButton>
                            }
                        </div>
                    </Toolbar>
                </AppBar>
                <div className={classes.paper}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12} lg={12}>
                            <TextField
                                label="Name"
                                defaultValue={" "}
                                value={name}
                                variant="outlined"
                                style={{ width: "100%" }}
                                onChange={handleCurrentCompanyChange("name")}
                                disabled={isEditing ? false : true}
                            />
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <TextField
                                label="Address"
                                defaultValue={" "}
                                value={address}
                                variant="outlined"
                                style={{ width: "100%" }}
                                onChange={handleCurrentCompanyChange("address")}
                                disabled={isEditing ? false : true}
                            />
                            {/* {
                                isEditing ?
                                    <div>
                                        <GooglePlacesEdit
                                            disabled={isEditing ? false : true}
                                            address={address}
                                            changeAddress={handleAddressChange}
                                            setLatLngDetails={setLatLngDetails}
                                            setNewLocationAddress={setNewLocationAddress}
                                            locationObj={locationObj}
                                            setLocationObj={setLocationObj}
                                            type="LocationEdit"
                                        />
                                    </div>
                                    :
                                    ""
                            } */}
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <TextField
                                label="Website"
                                defaultValue={" "}
                                value={website}
                                variant="outlined"
                                multiline={true}
                                style={{ width: "100%" }}
                                onChange={handleCurrentCompanyChange("website")}
                                disabled={isEditing ? false : true}
                            />
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <TextField
                                label="About"
                                defaultValue={" "}
                                value={about}
                                variant="outlined"
                                multiline={true}
                                style={{ width: "100%" }}
                                onChange={handleCurrentCompanyChange("about")}
                                disabled={isEditing ? false : true}
                            />
                        </Grid>
                        <Grid item xs={6} md={6} lg={6}>
                            <FormControlLabel
                                value="top"
                                control={
                                    <Checkbox
                                        checked={gstregistered}
                                        onChange={handleGSTChange('gstregistered')}
                                        value="primary"
                                    />
                                }
                                label="GST Registered"
                                labelPlacement="left"
                                disabled={isEditing ? false : true}
                            />
                        </Grid>
                        <Grid item xs={6} md={6} lg={6}>
                            <TextField
                                label="GST Number"
                                defaultValue={" "}
                                value={gstnumber}
                                variant="outlined"
                                style={{ width: "100%" }}
                                onChange={handleCurrentCompanyChange("gstnumber")}
                                disabled={isEditing ? false : true}
                            />
                        </Grid>
                        {
                            isEditing ?
                                <Grid container spacing={2} item xs={12} md={12} lg={12}>
                                    <Grid item xs={10} md={10} lg={10}>
                                        <Autocomplete
                                            options={allPeople}
                                            getOptionLabel={option => option.fullName}
                                            value={newEmployee}
                                            onChange={(event, newValue) => {
                                                setNewEmployee(newValue);
                                            }}
                                            renderInput={params => <TextField {...params} variant="outlined" label="Add New Employee" margin="normal" />}
                                        />
                                    </Grid>
                                    <Grid item xs={2} md={2} lg={2} style={{display: 'flex', verticalAlign: 'center'}}>
                                        <IconButton
                                            color="secondary"
                                            aria-label="add employee"
                                            component="span"
                                            style={{
                                                padding: "0px"
                                            }}
                                            onClick={() => addEmployee(newEmployee)}
                                        >
                                            <AddCircleIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                                : ""
                        }
                        <Grid item xs={12} md={12} lg={12}>
                            <EmployeeTable
                                allPeople={allEmployees}
                            />
                        </Grid>
                    </Grid>
                </div>
            </div>
        </Modal>
    )
}

export default CompanyDetailsPopUp;