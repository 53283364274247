import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { connect } from "react-redux";
import {
    ButtonGroup,
    Grid,
    Grow,
    Button,
    Popper,
    Paper,
    MenuList,
    MenuItem,
    ClickAwayListener,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { contains } from "../../../utils/utils";
import { setDialogProps } from "../../../actions/dialog";
import { setSnackBarProps } from "../../../actions/snackbar";
import InformationDialogy from "../../Shared/InformationDialogy";
import Snacky from "../../Shared/Snacky";
import { useUserContext } from "../../../context/user/UserContext";
import {  useHistory } from "react-router-dom";
import { useAuth } from "../../../context/auth/AuthContext";
const changeButton = (contractStatus) => {
    switch (contractStatus) {
        case "Disabled":
        case "Send":
            return { color: "#c8c8c8", options: ["Not Sent"] };
        case "Pending":
            return {
                color: "#FFA500",
                options: ["Pending", "Resend", "Cancel Contract"],
            };
        case "Signed":
            return {
                color: "#F987C5",
                options: ["Signed", "Send for Invoicing", "Cancel Contract"],
            };
        case "Declined":
            return {
                color: "#FF0000",
                options: ["Declined", "Cancel Declined Contract"],
            };
        case "Invoiced":
            return { color: "#008000", options: ["Invoiced"] };
        case "CalendarSent":
            return { color: "#008000", options: ["Calendar Sent", "Cancel Calendar"] };
        case "CateringSent":
            return { color: "#FFA500", options: ["Catering Ordered", "Cancel Order"] };
        case "Accepted":
            return { color: "#008000", options: ["Order Accepted", "Cancel Order", "Send for Invoicing"] };
        case "Cancelling":
            return { color: "#FFA500", options: ["Cancelling"] };
        case "Cancelled":
            return { color: "#c8c8c8", options: ["Cancelled"] };
        case "InvoicePending":
            return { color: "#FFA500", options: ["Invoice Pending", "Resend", "Cancel Invoice"] };
        default:
            return { color: "#c8c8c8", options: ["Error"] };
    }
};

const SpamItemButton = (props) => {
    let { snackbar } = props;
    let history = useHistory();
    const { user, } = useAuth();
    const [checked, setChecked] = useState(true);
    const [open, setOpen] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [buttonProps, setButtonProps] = useState(
        changeButton(props.spamItem.status)
    );
    const [showCancelWarning, setShowCancelWarning] = useState(false);

    const anchorRef = useRef(null);
    let spamItem = props.spamItem;

    useEffect(() => {
        setButtonProps(changeButton(spamItem.status));
    }, [spamItem.status]);

    const handleClick = () => {
    };

    const redirectToInfluencerPage = () => {
      if (
        (spamItem?.personCategory === "Influencer" || spamItem?.personCategory === "Content Creator") &&
        spamItem?.campaignId &&
        spamItem?.companyId
      ) {
        history.push(
          `/Client/Home/${spamItem?.companyId}/1/${spamItem.campaignId}`
        );
      }
    };

    const handleMenuItemClick = (event, index) => {
        if (buttonProps.options[index] === "Cancel Contract") {
            if (spamItem.type === "Talent" || spamItem.type === "Influencer" || spamItem.type === "Content Creator" || spamItem.type === "Contractor" || spamItem.type === "Location") {
                props.handleOnClick("Send", "status", spamItem);
                handleCancelContract();
            }
            else {
                setShowCancelWarning(true)
            }
        } if (buttonProps.options[index] === "Cancel Declined Contract") {
            if (spamItem.type === "Talent" || spamItem.type === "Influencer" || spamItem.type === "Content Creator" || spamItem.type === "Contractor" || spamItem.type === "Location") {
                props.handleOnClick("Send", "status", spamItem);
                handleCancelDeclinedContract();
            }
            else {
                setShowCancelWarning(true)
            }
        } else if (buttonProps.options[index] === "Send for Invoicing") {
            if (spamItem.personCategory === "Talent" || spamItem.personCategory === "Contractor") {
                props.handleOnClick("InvoicePending", "status", spamItem);
                sendInvoice();
            }
            else if (spamItem.type === "Catering") {
                props.handleOnClick("Invoiced", "status", spamItem);
                sendCateringInvoice();
            }else if(spamItem.personCategory === "Influencer" || spamItem.personCategory === "Content Creator"){
                redirectToInfluencerPage()
            }
            else {
                props.handleOnClick("InvoicePending", "status", spamItem);
                sendLocationInvoice();
            }
        } else if (buttonProps.options[index] === "Resend") {
            if(spamItem.personCategory === "Influencer" || spamItem.personCategory === "Content Creator"){
                redirectToInfluencerPage()
            }else{
                var scheduleItemId = spamItem.scheduleItemId ? spamItem.scheduleItemId : 0;
                var scheduleId = spamItem.scheduleId ? spamItem.scheduleId : 0;
                var locationId = spamItem.locationId ? spamItem.locationId : 0;
                var cateringId = spamItem.cateringId ? spamItem.cateringId : 0;
                var url = `SpamBots/ResendEmail/${spamItem.type}/${spamItem.status}/${scheduleItemId}/${spamItem.personId}/${scheduleId}/${locationId}/${cateringId}`;
                axios.post(url, {
                    name: `${user.firstName} ${user.lastName}`,
                    email: user.email
                })
                    .then((res) => {
                        props.setSnackBarProps("success", "Successfully Resent", true);
                        console.log("Resend Email");
                        console.log(res);
                    })
                    .catch((e) => {
                        props.setSnackBarProps("warning", "Resend Error: Please try again", true);
                        console.log(e);
                    });
            }
        } else if (buttonProps.options[index] === "Cancel Invoice") {
            props.handleOnClick("Signed", "status", spamItem);
            if (props.providerType === "Location") {
                cancelLocationInvoice();
            }
            else {
                cancelInvoice();
            }
        }
        else if (buttonProps.options[index] === "Cancel Order") {
            props.handleOnClick("Cancelling", "status", spamItem);
            cancelOrder({ id: spamItem.scheduleId }, { id: spamItem.cateringId });
        }
        else if (buttonProps.options[index] === "Cancel Calendar") {
            props.handleOnClick("Cancelling", "status", spamItem);
            handleCancelCalendar();
        }
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && contains(anchorRef.current, event.target)) {
            return;
        }
        setOpen(false);
    };

    const sendInvoice = () => {
        axios
            .post(`Mail/SendInvoice/${spamItem.personId}/${spamItem.scheduleItemId}`, {
                name: `${user.firstName} ${user.lastName}`,
                email: user.email
            })
            .then((res) => {
                props.setSnackBarProps("success", "Invoice Sent", true);
            })
            .catch((e) => {
                props.setSnackBarProps("warning", "Invoice Error: Please try again", true);
                console.log(e);
            });
    };

    const sendLocationInvoice = () => {
        axios.post(`Mail/SendLocationInvoice/${spamItem.scheduleId}/${spamItem.locationId}`, {
            name: `${user.firstName} ${user.lastName}`,
            email: user.email
        })
            .then((res) => props.setSnackBarProps("success", "Invoice Sent", true))
            .catch((err) => {
                props.setSnackBarProps("warning", "Invoice Error: Please try again", true);
                console.log(err)
            });
    }

    const cancelInvoice = () => {
        axios.patch(`ScheduleItems/CancelInvoice/${spamItem.scheduleItemId}/${props.providerType}`, {
            id: spamItem.scheduleItemId
        })
            .then((res) => props.setSnackBarProps("success", "Invoice Cancelled", true))
            .catch((err) => {
                props.setSnackBarProps("warning", "Cencel Error: Please try again", true);
                console.log(err)
                props.handleOnClick("InvoicePending", "status", spamItem);
            });
    }

    const cancelLocationInvoice = () => {
        axios.patch(`LocationContracts/CancelLocationInvoice/${spamItem.scheduleId}/${spamItem.locationId}`, {
            scheduleFk: spamItem.scheduleId,
            locationFk: spamItem.locationId
        })
            .then((res) => props.setSnackBarProps("success", "Invoice Cancelled", true))
            .catch((err) => {
                props.setSnackBarProps("warning", "Cencel Error: Please try again", true);
                console.log(err)
                props.handleOnClick("InvoicePending", "status", spamItem);
            });
    }

    const sendCateringInvoice = () => {
        axios.get(`Catering/UpdateToInvoiced/${props.spamItem.scheduleId}`)
            .then(res => {
                console.log(res)
            })
            .catch(e => {
                console.log(e)
            })
        axios.get(`Catering/GetCatering/${props.spamItem.scheduleId}`)
            .then(catering => {
                axios.get(`CateringFinances/GetLastCateringFinance`)
                    .then(res => {
                        var cateringFinance = res.data;
                        var cateringOrder = catering.data;
                        console.log(cateringOrder);
                        var orderJson = JSON.parse(cateringOrder.orderJson);

                        var gstInclusivePrice = cateringOrder.cost - parseFloat(orderJson.deliveryCost);
                        var gstAdded = (gstInclusivePrice * 3) / 23;
                        var gstExclusivePrice = (gstInclusivePrice - gstAdded) + parseFloat(orderJson.gstExclusiveDeliveryCost);

                        var deliveryGstAdded = orderJson.deliveryCost - orderJson.gstExclusiveDeliveryCost;

                        var arr = orderJson.date.split("/");
                        var jobDate = `${arr[2]}-${arr[1]}-${arr[0]}`;

                        axios.post(`CateringInvoices/GenerateInvoice/${spamItem.scheduleId}`, {
                            dateCreated: new Date(),
                            dateAccepted: new Date(),
                            firstName: cateringFinance.tradingName,
                            lastName: "",
                            totalDue: cateringOrder.cost,//gstInclusivePrice,
                            bankAccount: cateringFinance.bankAccount,//"12-3107-0073828-00",
                            gstReg: 1,
                            irdNumber: cateringFinance.gstNumber,//"116-323-176",
                            category: "Catering",
                            companyName: cateringFinance.companyName,//"Eat My Lunch Ltd",
                            taxWithheld: "",//(gstAdded + deliveryGstAdded).toFixed(2),
                            gstAdded: (gstAdded + deliveryGstAdded).toFixed(2),//gstAdded.toFixed(2),
                            subtotal: gstExclusivePrice,
                            jobDate: new Date(jobDate),
                            //jobName: props.scheduleName,
                            driveFileId: "",
                            cateringContractFk: null,
                            costAccount: "",
                            emailAddress: cateringFinance.emailAddress,//"buyone@eatmylunch.nz",
                            phoneNumber: cateringFinance.phoneNumber,//"09-886 4644",
                            jobFee: gstExclusivePrice,
                            invoiceNumber: "",
                            wtRate: null
                        });
                    })
                    .catch(e => {
                        console.log(e);
                    })
            })
    }

    const handleCancelContract = () => {
        axios.put(`SpamBots/CancelFromSpamTable`, spamItem)
            .then((res) => props.setSnackBarProps("success", "Contract Cancelled", true))
            .catch((err) => {
                props.setSnackBarProps("warning", "Cancel Error: Please try again", true);
                console.log(err)
            });
    };

    const handleCancelDeclinedContract = () => {
        if (spamItem.type === "Location") {
            axios.patch(`ScheduleLocation/CancelDeclinedContract/${spamItem.scheduleId}/${spamItem.locationId}`, {
                scheduleFk: spamItem.scheduleId,
                locationFk: spamItem.locationId
            })
                .then((res) => props.setSnackBarProps("success", "Contract Cancelled", true))
                .catch((err) => {
                    props.setSnackBarProps("warning", "Cancel Error: Please try again", true);
                    console.log(err)
                });
        }
        else {
            axios.patch(`ScheduleItems/CancelDeclinedContract/${spamItem.scheduleItemId}`, {
                id: spamItem.scheduleItemId
            })
                .then((res) => props.setSnackBarProps("success", "Contract Cancelled", true))
                .catch((err) => {
                    props.setSnackBarProps("warning", "Cancel Error: Please try again", true);
                    console.log(err)
                });
        }
    }

    const cancelOrder = (schedule, catering) => {
        axios.post(`Catering/CancelCateringOrder/${schedule.id}/${catering.id}`, {
            id: catering.id
        })
            .then((res) => {
                props.setSnackBarProps("success", "Cancellation Email Sent", true);
                props.handleOnClick("Cancelled", "status", spamItem);
            })
            .catch((err) => {
                props.setSnackBarProps("warning", "Cancel Error: Please try again", true);
                console.log(err)
            });
    }

    const handleCancelCalendar = () => {
        axios.patch(`GoogleCalendar/DeleteScheduleItemEvent/${spamItem.scheduleItemId}`, {
            id: spamItem.scheduleItemId,
        }).then((res) => {
            console.log(res);
        }).catch((e) => {
            console.log(e);
        });
    }

    return (
        <Grow in={checked} mountOnEnter unmountOnExit>
            <Grid container alignItems="center">
                {showCancelWarning ? (
                    <InformationDialogy
                        setDialogProps={setDialogProps}
                        buttonProps={{
                            show: showCancelWarning,
                            titleText: "Woops!",
                            contentText:
                                `${spamItem.type} cannot be cancelled. Only Location, Talent and Contractors can be cancelled`,
                            buttonColor: "primary",
                            buttonText: "Ok",
                        }}
                        handleButton={() => setShowCancelWarning(false)}
                    />
                ) : (
                        <React.Fragment></React.Fragment>
                    )}
                {snackbar.open && (
                    <Snacky snackprops={snackbar} setSnackBarProps={setSnackBarProps} />
                )}
                <Grid item xs={12}>
                    <ButtonGroup
                        variant="contained"
                        // color="secondary"
                        ref={anchorRef}
                        aria-label="split button"
                        size="small"
                        style={{
                            width: "200px",
                            backgroundColor: buttonProps.color,
                        }}
                    >
                        <Button
                            onClick={handleClick}
                            style={{
                                padding: "0px 0px 0px 0px",
                                width: "200px",
                                backgroundColor: buttonProps.color,
                                color: "white",
                                whiteSpace: "normal",
                                wordWrap: "break-word",
                            }}
                        >
                            {buttonProps.options[selectedIndex]}
                        </Button>
                        <Button
                            size="small"
                            aria-controls={open ? "split-button-menu" : undefined}
                            aria-expanded={open ? "true" : undefined}
                            aria-label="select declined option"
                            aria-haspopup="menu"
                            onClick={handleToggle}
                            style={{
                                width: "20px",
                                backgroundColor: buttonProps.color,
                                color: "white",
                            }}
                        >
                            <ArrowDropDownIcon />
                        </Button>
                    </ButtonGroup>
                    <Popper
                        open={open}
                        anchorEl={anchorRef.current}
                        role={undefined}
                        transition
                        disablePortal
                        style={{
                            zIndex: "999",
                        }}
                    >
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{
                                    transformOrigin:
                                        placement === "bottom" ? "center top" : "center bottom",
                                }}
                            >
                                <Paper>
                                    <ClickAwayListener onClickAway={handleClose}>
                                        <MenuList id="split-button-menu">
                                            {buttonProps.options.map(
                                                (option, index) =>
                                                    index > 0 && (
                                                        <MenuItem
                                                            key={option}
                                                            disabled={index === 0}
                                                            selected={index === selectedIndex}
                                                            onClick={(event) =>
                                                                handleMenuItemClick(event, index)
                                                            } //console.log(event)}
                                                        >
                                                            {option}
                                                        </MenuItem>
                                                    )
                                            )}
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </Grid>
            </Grid>
        </Grow>
    );
};

// export default SpamItemButton;

const mapStateToProps = (state, ownProps) => ({
    snackbar: state.snackbar,
    dialog: state.dialog,
});
export default connect(mapStateToProps, {
    setDialogProps,
    setSnackBarProps,
})(SpamItemButton);