import React, { useEffect, useState } from "react";
import CurrencyOptionItem from "./CurrencyOptionItem";
import { getCurrencies } from "../../utils/GeneralUtils";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Menu, MenuItem } from "@material-ui/core";
import "./currencyDropdownMenu.scss";
const CurrencyDropdownMenu = ({
  originalCurrency = "NZD",
  handleChangeCurrency,
  size = "medium",
  selectedCurrencies,
  isReadOnly = false,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [selectedCurrency, setSelectedCurrency] = useState(null);

  useEffect(() => {
    setSelectedCurrency(
      selectedCurrencies
        ? selectedCurrencies.find((c) => c.value === originalCurrency)
        : getCurrencies().find((c) => c.value === originalCurrency)
    );
  }, [originalCurrency, selectedCurrencies]);

  return (
    <div
      className="currency-exdropdown-menu-root"
      style={{ padding: size === "small" ? "7px 10px" : "15px 10px" }}
    >
      {!!selectedCurrency && (
        <>
          <div
            onClick={(e) => {
              if (!isReadOnly) {
                setAnchorEl(e.currentTarget);
              }
            }}
            className="currency-exchange-form-currecy-dropdown"
          >
            <CurrencyOptionItem option={selectedCurrency} />
            {!isReadOnly && <ExpandMoreIcon />}
            
          </div>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={() => setAnchorEl(null)}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            {(selectedCurrencies || getCurrencies()).map((currency, index) => (
              <MenuItem
                key={index}
                onClick={(e) => {
                  setAnchorEl(null);
                  handleChangeCurrency(currency);
                }}
              >
                <CurrencyOptionItem option={currency} />
              </MenuItem>
            ))}
          </Menu>
        </>
      )}
    </div>
  );
};

export default CurrencyDropdownMenu;
