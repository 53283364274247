import {
  Button,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
} from "@material-ui/core";
import React from "react";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";

const SuppliersList = (props) => {
  return (
    <div>
      <List component="nav">
        <ListItem
          button
          onClick={(event) => props.handleSelectSupplier()}
          key="create_btn"
        >
          <ListItemIcon>
            <AddCircleOutlineIcon />
          </ListItemIcon>
          <ListItemText primary="Create new supplier" />
        </ListItem>
      </List>
      <Divider />
      <Paper className="client-list-paper">
        <List component="nav" className="client-list">
          {props.suppliers &&
            props.suppliers.map((supplier) => {
              return (
                <ListItem
                  key={supplier.id}
                  button
                  className={props.selectedSupplier?.id === supplier.id ? 
                    `default-client-settings selected-menu` : `default-client-settings`}
                  onClick={(event) => props.handleSelectSupplier(supplier.id)}
                >
                  <ListItemText primary={supplier.supplierName} />
                </ListItem>
              );
            })}
        </List>
      </Paper>
    </div>
  );
};

export default SuppliersList;
