import { Fab, Switch, TextField, Typography } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import axios from "axios";
import React, { useEffect, useState } from "react";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import TalentAgencyPopUp from "../TalentAgencyPopUp";
import AddAgentPopUp from "../../../Schedule/NewContact/AddAgentPopUp";

const AgentFinancialForm = ({
  isEditing,
  person,
  agent,
  handleSelectAgent,
  setShowAddAgentContact,
  handleAgentOnChange,
  setShowTalentAgencyPopUp,
  showTalentAgencyPopUp,
  showAddAgentContact,
  currency = "NZD"
}) => {
  const [agentList, setAgentList] = useState([]);

  useEffect(() => {
    getAgents();
  }, []);

  const getAgents = () => {
    axios
      .get(`Agents/GetAgents`, {
        params: {
          organisationId: (person?.personCategory === "Influencer" || person?.personCategory === "Content Creator") ? 2 : 1,
        },
      })
      .then((res) => {
        setAgentList(res.data);
      })
      .catch((e) => {
        alert("unable to fetch agents");
        console.log(e);
      });
  };

  const saveNewAgent = (agent) => {
    console.log(agent);
    axios
      .post(`Agents/PostAgent`, agent)
      .then(async (res) => {
        setAgentList([...agentList, res.data]);
        handleSelectAgent(null, res.data, true);
      })
      .catch((e) => {
        console.log(e);
        alert("unable to create new agent");
      });
  };

  return (
    <div className="person-financial-tab-root">
      {showTalentAgencyPopUp && !!agent?.companyFk && (
        <TalentAgencyPopUp
          modalState={showTalentAgencyPopUp}
          handleModal={() => setShowTalentAgencyPopUp(false)}
          parent="ContractorPopUp"
          companyId={agent?.companyFk}
        />
      )}
      {showAddAgentContact && (
        <AddAgentPopUp
          modalState={showAddAgentContact}
          handleModal={() => setShowAddAgentContact(false)}
          handleSave={saveNewAgent}
          parent="ContractorPopUp"
          currency={currency}
          organisationId={(person?.personCategory === "Influencer" || person?.personCategory === "Content Creator") ? 2 : 1}
        />
      )}
      <div className="person-financial-tab-agent-select-container">
        <Autocomplete
          disabled={isEditing ? false : true}
          label="Agent"
          clearOnEscape
          options={agentList}
          getOptionLabel={(option) =>
            option.companyFkNavigation !== null
              ? `${option.firstName} ${option.lastName} - ${option.companyFkNavigation.name}`
              : `${option.firstName} ${option.lastName} - No Company`
          }
          value={agent}
          style={{
            width: "auto",
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Select an Agent *"
              variant="outlined"
              fullWidth
            />
          )}
          onChange={(e, newValue) => handleSelectAgent(e,newValue)}
        />
        <div
          style={{ display: "flex", justifyContent: "center", width: "100%" }}
        >
          <Fab
            disabled={isEditing ? false : true}
            className={isEditing ? "addAgentFab" : ""}
            onClick={() => setShowAddAgentContact(true)}
          >
            <PersonAddIcon />
          </Fab>
        </div>
      </div>
      <div className="person-financial-tab-flex-container">
        <TextField
          disabled={isEditing && agent?.id ? false : true}
          variant="outlined"
          fullWidth
          label="Agent First Name"
          value={agent?.firstName || ""}
          onChange={(e) => handleAgentOnChange("firstName", e.target.value)}
        />
        <TextField
          disabled={isEditing && agent?.id ? false : true}
          variant="outlined"
          fullWidth
          label="Agent Last Name"
          value={agent?.lastName || ""}
          onChange={(e) => handleAgentOnChange("lastName", e.target.value)}
        />
      </div>
      <div className="person-financial-tab-flex-container">
        <TextField
          disabled={isEditing && agent?.id ? false : true}
          variant="outlined"
          fullWidth
          label="Agent Phone"
          value={agent?.phoneNumber || ""}
          onChange={(e) => handleAgentOnChange("phoneNumber", e.target.value)}
        />
        <TextField
          disabled={isEditing && agent?.id ? false : true}
          variant="outlined"
          fullWidth
          label="Agent Email Address"
          value={agent?.emailAddress || ""}
          onChange={(e) => handleAgentOnChange("emailAddress", e.target.value)}
        />
      </div>
      <div className="person-financial-tab-flex-container">
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            style={
              agent?.enableSms === 1
                ? { color: "grey" }
                : {
                    borderBottom: "solid",
                    borderColor: isEditing ? "#3f51b5" : "grey",
                  }
            }
          >
            Disable SMS Notifications
          </div>
          <div>
            <Switch
              color="primary"
              checked={agent?.enableSms === 1 ? true : false}
              name="enableSms"
              disabled={isEditing ? false : true}
              onChange={(e) =>
                handleAgentOnChange("enableSms", e.target.checked ? 1 : 0)
              }
              className={isEditing ? "blueSwitch" : ""}
            />
          </div>
          <div
            style={
              agent?.enableSms === 1
                ? {
                    borderColor: isEditing ? "#3f51b5" : "grey",
                  }
                : { color: "grey" }
            }
          >
            Enable SMS Notifcations
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div
          className={
            agent === null
              ? "talentAgencyCardClickError"
              : "talentAgencyCardClick"
          }
          onClick={(e) => {
            if (agent !== null) {
              //setShowTalentAgencyPopUp(true);
            }
          }}
        >
          <Typography>
            {agent === null
              ? `Choose an Agent`
              : !!agent?.companyFkNavigation
              ? `Talent Agency: ${agent?.companyFkNavigation?.name}`
              : "Select Talent Agency"}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default AgentFinancialForm;
