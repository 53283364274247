import React from "react";
import { useDataContext } from "../../../context/data/DataContext";
import { Chip, Typography } from "@material-ui/core";

const IndustryBadge = ({ industryId, size = "small" }) => {
  const { industries } = useDataContext();
  const industry = industries.find((i) => i.id === industryId);
  return industry ? (
    <div
      style={{
        backgroundColor: industry.baseColour || "#f4f4f4",
        borderRadius: 30,
        padding: "5px 10px",
        display: "flex",
        alignItems: "center",
        alignContent: "center",
        height: "max-content"
      }}
    >
      <Typography variant="caption" style={{ fontSize: 10 }}>
        {industry.name}
      </Typography>
    </div>
  ) : (
    <></>
  );
};

export default IndustryBadge;
