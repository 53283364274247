import { Typography } from "@material-ui/core";
import { Rating } from "@material-ui/lab";
import React from "react";
import { timeAgo } from "../../../utils/TimeHelpers";

const PersonRatingsTable = ({ ratings }) => {
  return (
    <div className="person-ratings-table-root">
      {ratings?.length > 0 ? (
        ratings.map((rating, index) => (
          <div className="person-ratings-table-record-container">
            <div className="person-ratings-table-record-heading">
              <Rating readOnly value={rating.rating} size="small" precision={0.5}/>
              <Typography variant="caption">
                {timeAgo(rating.ratingDate)}
              </Typography>
            </div>
            <div>
              <Typography variant="caption">{rating.comments}</Typography>
              <Typography variant="caption">{" - "}</Typography>
              <Typography variant="caption">{rating.user}</Typography>
            </div>
          </div>
        ))
      ) : (
        <div className="person-ratings-table-no-records">
          <Typography variant="body2">No Reviews</Typography>
        </div>
      )}
    </div>
  );
};

export default PersonRatingsTable;
