export const setSnackBarProps = (severity, text, open) => async (dispatch) => {
  try {
    dispatch({
      type: "SET_SNACKBAR_PROPS",
      payload: {
        severity,
        text,
        open,
      },
    });
  } catch (err) {
    dispatch({
      type: "SET_SNACKBAR_PROPS_ERROR",
      payload: err,
    });
  }
};
