import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  text: "",
  severity: "info",
  open: false,
};
const snackbar = createReducer(initialState, {
  SET_SNACKBAR_PROPS: (state, action) => {
    return {
      ...state,
      text: action.payload.text,
      severity: action.payload.severity,
      open: action.payload.open,
    };
  },
});

export default snackbar;
