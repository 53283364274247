import axios from "axios";
import { EDIT_BUDGET } from "./budgetTypes";
import { getBudget } from "./budget";
// import { useUserContext } from "../context/user/UserContext";
// import { useAuth } from "../context/auth/AuthContext";

// Get schedule items fetches the schedule items for the schedulewrapper components.
// It returns an object of arrays, sorted on the backend, one for each table.
export const getScheduleItems = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`ScheduleItems/GetSortedScheduleItems/${id}`);
    dispatch({
      type: "GET_SCHEDULE_ITEMS",
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: "GET_SCHEDULE_ITEMS_ERROR",
      payload: err,
    });
  }
};

export const sortScheduleItems = (table) => async (dispatch) => {
  try {
    dispatch({
      type: "SORT_SCHEDULE_ITEMS",
      payload: { table },
    });
  } catch (err) {
    dispatch({
      type: "SORT_SCHEDULE_ITEMS_ERROR",
      payload: err,
    });
  }
};

export const editScheduleItem = (
  scheduleItem,
  table,
  key,
  value,
  makeAxios = 1,
  user = "dev@magnesium.nz"
) => async (dispatch) => {
  //const { user } = useAuth();
  console.log("editScheduleItem")
  if (key == "startTime") {
    value = scheduleItem.endTime.substring(0, 10) + "T" + value;
  } else if (key == "endTime") {
    value = scheduleItem.startTime.substring(0, 10) + "T" + value;
  }
  try {
    if (makeAxios == 1) {
      axios.patch(`ScheduleItems/PatchScheduleItem/${scheduleItem.id}`, {
        ...scheduleItem,
        [key]: value,
      },
      {
        params: {
          user: user,
        },
      });
    }
    dispatch({
      type: "EDIT_SCHEDULE_ITEM",
      payload: { scheduleItem, table, key, value },
    });
    if (key == "startTime") {
      dispatch({
        type: "SORT_SCHEDULE_ITEMS",
        payload: { table },
      });
    }
  } catch (err) {
    dispatch({
      type: "EDIT_SCHEDULE_ITEM_ERROR",
      payload: err,
    });
  }
};

export const postScheduleItem = (scheduleItem, table, projId) => async (
  dispatch
) => {
  try {
    if (table != "talent") {
      axios
        .post(`ScheduleItems/PostScheduleItem`, {
          ...scheduleItem,
        })
        .then((res) => {
          dispatch({
            type: "POST_SCHEDULE_ITEM",
            payload: { scheduleItem: res.data, table },
          });
          dispatch(getBudget(projId));
          try {
            dispatch({
              type: "SORT_SCHEDULE_ITEMS",
              payload: { table },
            });
          } catch {
            dispatch({
              type: "SORT_SCHEDULE_ITEMS_ERROR",
            });
          }
        });
    } else {
      axios
        .post(`ScheduleItems/PostScheduleItemTalent`, {
          ...scheduleItem,
        })
        .then((res) => {
          dispatch({
            type: "POST_SCHEDULE_ITEM",
            payload: { scheduleItem: res.data, table },
          });
          try {
            dispatch({
              type: "SORT_SCHEDULE_ITEMS",
              payload: { table },
            });
          } catch {
            dispatch({
              type: "SORT_SCHEDULE_ITEMS_ERROR",
            });
          }
        });
    }
  } catch (err) {
    dispatch({
      type: "POST_SCHEDULE_ITEM_ERROR",
      payload: err,
    });
  }
};

export const deleteScheduleItem = (scheduleItem, table, projId = 0) => async (
  dispatch
) => {
  try {
    axios.delete(`ScheduleItems/DeleteScheduleItem/${scheduleItem.id}`);
    dispatch({
      type: "DELETE_SCHEDULE_ITEM",
      payload: { scheduleItem, table },
    });
    if (scheduleItem.isStaff != 1 && scheduleItem.isClient != 1) {
      try {
        dispatch({
          type: EDIT_BUDGET,
          payload: {
            category: scheduleItem.category,
            amount: Number(scheduleItem.allocatedRates) * -1,
          },
        });
      } catch {
        dispatch({
          type: "EDIT_BUDGET_ERROR",
          payload: {
            category: scheduleItem.category,
            amount: Number(scheduleItem.allocatedRates) * -1,
          },
        });
      }
    }
  } catch (err) {
    dispatch({
      type: "DELETE_SCHEDULE_ITEM_ERROR",
      payload: err,
    });
    dispatch({
      type: "SET_SNACKBAR_PROPS",
      payload: { severity: "warning", text: "Failed to delete", open: true },
    });
  }
};

export const resetScheduleItems = () => async (dispatch) => {
  try {
    dispatch({
      type: "RESET_SCHEDULE_ITEMS",
    });
  } catch (err) {
    dispatch({
      type: "RESET_SCHEDULE_ITEM_ERROR",
      payload: err,
    });
  }
};

export const updateScheduleItemDates = (date) => async (dispatch) => {
  try {
    dispatch({
      type: "UPDATE_SCHEDULE_ITEM_DATES",
      payload: { value: date },
    });
  } catch (err) {
    dispatch({
      type: "UPDATE_SCHEDULE_ITEM_DATES_ERROR",
      payload: err,
    });
  }
};

export const cancelInvoice = (
  scheduleItem,
  table,
  key,
  value,
  makeAxios = 1
) => async (dispatch) => {
  try {
    if (makeAxios == 1) {
      axios.patch(`ScheduleItems/CancelInvoice/${scheduleItem.id}/${table}`, scheduleItem);
    }
    dispatch({
      type: "EDIT_SCHEDULE_ITEM",
      payload: { scheduleItem, table, key, value },
    });
  } catch (err) {
    dispatch({
      type: "CANCEL_INVOICE_ITEM_ERROR",
      payload: err,
    });
  }
};
