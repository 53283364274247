import moment from "moment";
import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { get3monthsBeforeAndAfterCurrentDate } from "../../../../utils/TimeHelpers";
const AddMonthlySpendToSheetDialog = ({
  open,
  handleClose,
  populateMediaSpendSheet,
}) => {
  const [selectedBillingMonth, setSelectedBillingMonth] = useState(moment());
  const handleSubmit = () => {
    populateMediaSpendSheet(selectedBillingMonth);
  };
  return (
    <Dialog
      open={open}
      onClose={(e, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ paper: "confirmation-with-input-dialog" }}
    >
      <DialogTitle id="alert-dialog-title">Before we continue...</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          To prevent duplicate entries, choose the month to be added and confirm
          it is not already included in the spreadsheet.
        </DialogContentText>
        <Autocomplete
          value={
            selectedBillingMonth
              ? {
                  display: selectedBillingMonth.format("MMMM YYYY"),
                  value: selectedBillingMonth,
                }
              : null
          }
          options={get3monthsBeforeAndAfterCurrentDate()}
          onChange={(event, option) => {
            setSelectedBillingMonth(option.value);
          }}
          getOptionLabel={(option) => option.display}
          renderInput={(params) => (
            <TextField
              {...params}
              label={"Billing Month"}
              size="small"
              fullWidth={true}
              variant="outlined"
            />
          )}
          disableClearable
        />
      </DialogContent>
      <DialogActions>
        <Button
          className="default-button"
          variant="contained"
          onClick={() => handleClose()}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          disabled={!selectedBillingMonth}
          className={"default-button"}
          variant="contained"
          onClick={() => handleSubmit()}
          autoFocus
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddMonthlySpendToSheetDialog;
