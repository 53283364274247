import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { Card } from "@material-ui/core";
import NewPrePost from "./NewPrePost";
import PrePostTable from "./PrePostTable";
import BudgetItemsTable from "../BudgetItemsTable";
import { setSnackBarProps } from "../../../../actions/snackbar";
import { getBudget } from "../../../../actions/budget";
import Snacky from "../../../Shared/Snacky";
import { useUserContext } from "../../../../context/user/UserContext";
import { useAuth } from "../../../../context/auth/AuthContext";
import ClientPurchaseOrder from "../../../Client/PurchaseOrder/ClientPurchaseOrder";

const PreProduction = (props) => {
  const { user } = useAuth();
  const [items, setItems] = useState(false);
  const [itemsFetched, setItemsFetched] = useState(false);
  const [people, setPeople] = useState([]);
  const [companies, setCompanies] = useState(false);
  const [companiesFetched, setCompaniesFetched] = useState();
  const [prepostScheduleId, setPrepostScheduleId] = useState(null);
  const [isLoadingItems, setIsLoadingItems] = useState(false);
  const [budgetsForPO, setBudgetsForPO] = useState([]);
  const [budgetsForPoLoading, setBudgetsForPoLoading] = useState(false);

  const passNewScheduleUp = (newItm) => {
    setItems([...items, newItm]);
    props.getBudget(props.projectId);
  };

  const updatePersonInScheduleItems = (person) => {
    if (items?.length > 0) {
      setItems(
        items.map((i) =>
          i.personFk === person?.id
            ? { ...i, personFkNavigation: { ...person } }
            : i
        )
      );
    }
  };

  const deleteScheduleItem = (item) => {
    setItems(items.filter((sI) => sI !== item));
    axios
      .delete(`ScheduleItems/DeleteScheduleItem/${item.id}`)
      .then((res) => {
        props.setSnackBarProps(
          "success",
          "Influencer removed from the project",
          true
        );
        props.getBudget(props.projectId);
      })
      .catch((error) => {
        console.log(error);
        props.setSnackBarProps("warning", "Failed to delete item", true);
      });
  };

  useEffect(() => {
    if (!companiesFetched) {
      axios
        .get(`/Companies/GetCompanies`)
        .then((companies) => {
          setCompanies(companies.data);
          setCompaniesFetched(true);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [companiesFetched]);

  useEffect(() => {
    if (!itemsFetched && !isLoadingItems) {
      setIsLoadingItems(true);
      axios
        .get(`/PrePostProductions/GetPrePostProductions/${props.projectId}`)
        .then((response) => {
          console.log("PrePostProductions");
          console.log(response.data);
          setItems(response.data.prePostScheduleItems);
          setPrepostScheduleId(response.data.prePostScheduleId);
          setItemsFetched(true);
          setIsLoadingItems(false);
        })
        .catch((error) => {
          console.log(error);
          setIsLoadingItems(false);
        });
    }
  }, [itemsFetched]);

  const removePreviousContract = (sItem) => {
    axios
      .patch(`TalentContracts/ClearContractDriveId/${sItem.id}`)
      .then((res) => {
        console.log("Cleared Contract");
      })
      .catch((e) => {
        console.log("Error clearing contract");
      });
  };

  const handleSwitchContractType = (sItem) => {
    var contractType =
      sItem.talentContractType === "Restricted" ? "BuyOut" : "Restricted";
    var talentContractStatus = "";
    var contractStatus = "";
    if (contractType === "Restricted") {
      talentContractStatus = "Incomplete";
      contractStatus = "Disabled";
      setItems(
        items.map((sI) =>
          sI.id === sItem.id
            ? {
              ...sI,
              talentContractType: contractType,
              talentContractStatus: talentContractStatus,
              contractStatus: contractStatus,
              isAucklandStandardContract: 0,
            }
            : sI
        )
      );
      sItem.talentContractType = contractType;
      sItem.talentContractStatus = talentContractStatus;
      sItem.contractStatus = contractStatus;
      sItem.isAucklandStandardContract = 0;
      removePreviousContract(sItem);
    } else if (contractType === "BuyOut") {
      talentContractStatus = "Complete";
      contractStatus = "Send";
      setItems(
        items.map((sI) =>
          sI.id === sItem.id
            ? {
              ...sI,
              talentContractType: contractType,
              talentContractStatus: talentContractStatus,
              contractStatus: contractStatus,
            }
            : sI
        )
      );
      sItem.talentContractType = contractType;
      sItem.talentContractStatus = talentContractStatus;
      sItem.contractStatus = contractStatus;
    }
    var payload = sItem;
    axios
      .put(`ScheduleItems/UpdateScheduleItem/${sItem.id}`, payload, {
        params: {
          user: user?.email,
        },
      })
      .then((res) => {
        console.log(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleCompletePrePostNotes = (sItem, notes) => {
    setItems(
      items.map((sI) =>
        sI.id === sItem.id
          ? { ...sI, prePostNotes: notes, contractStatus: "Send" }
          : sI
      )
    );
    sItem.contractStatus = "Send";
    var payload = sItem;
    payload.PrePostNotes = notes;
    console.log("handleCompletePrePostNotes");
    axios
      .put(`ScheduleItems/UpdateScheduleItem/${sItem.id}`, payload, {
        params: {
          user: user?.email,
        },
      })
      .then((res) => {
        console.log(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleCompleteTalentContract = (sItem, outputs) => {
    setItems(
      items.map((sI) =>
        sI.id === sItem.id
          ? { ...sI, talentContractStatus: "Complete", contractStatus: "Send" }
          : sI
      )
    );
    var payload = sItem;
    payload.talentContractStatus = "Complete";
    payload.contractStatus = "Send";
    axios
      .put(`ScheduleItems/UpdateScheduleItem/${sItem.id}`, payload, {
        params: {
          user: user?.email,
        },
      })
      .then((res) => {
        console.log(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
    payload = {
      ScheduleItemFk: sItem.id,
      Outputs: JSON.stringify(outputs),
    };
    axios
      .post(`TalentContracts/PostTalentContract/${sItem.id}`, payload)
      .then((res) => console.log(res.data))
      .catch((err) => console.log(err));
  };

  const handleScheduleItemEdit = (value, name, scheduleItem) => {
    setItems(
      items.map((s) =>
        s.id === scheduleItem.id
          ? {
            ...s,
            [name]: value,
          }
          : s
      )
    );
    if (
      name === "startTime" ||
      name === "endTime" ||
      name === "isAucklandStandardContract"
    ) {
      scheduleItem[name] = value;
      axios
        .patch(
          `ScheduleItems/PatchScheduleItem/${scheduleItem.id}`,
          scheduleItem,
          {
            params: {
              user: user?.email,
            },
          }
        )
        .then((res) => console.log(res.data))
        .catch((err) => console.log(err));
    }
    props.getBudget(props.projectId);
  };

  const updateScheduleItem = (scheduleItem) => {
    axios
      .patch(
        `ScheduleItems/PatchScheduleItem/${scheduleItem.id}`,
        scheduleItem,
        {
          params: {
            user: user?.email,
          },
        }
      )
      .then(({ data }) => {
        setItems(
          items.map((si) => (si.id === scheduleItem.id ? scheduleItem : si))
        );
        props.setSnackBarProps(
          "success",
          "Your changes have been saved!",
          true
        );
        props.getBudget(props.projectId);
      })
      .catch((err) => {
        console.log(err);
        props.setSnackBarProps(
          "warning",
          "Hm, it looks like something went wrong.",
          true
        );
      });
  };

  const handleCompleteAucklandContract = (sItem, outputs) => {
    setItems(
      items.map((sI) =>
        sI.id === sItem.id
          ? {
            ...sI,
            talentContractStatus: "Draft",
            isAucklandStandardContract: 1,
          }
          : sI
      )
    );
    var payload = {
      talentContract: {
        ScheduleItemFk: sItem.id,
        Outputs: JSON.stringify(outputs),
        TalentContractOutput: outputs,
      },
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      phone: user.phone,
    };
    axios
      .post(`TalentContracts/PostAucklandTalentContract/${sItem.id}`, payload)
      .then((res) => console.log(res.data))
      .catch((err) => console.log(err));
  };

  const confirmAucklandStandard = (sItem) => {
    setItems(
      items.map((s) =>
        s.id === sItem.id
          ? {
            ...s,
            ["talentContractStatus"]: "Complete",
            ["contractStatus"]: "Send",
            ["isAucklandStandardContract"]: 1,
          }
          : s
      )
    );
    sItem["talentContractStatus"] = "Complete";
    sItem["contractStatus"] = "Send";
    sItem["isAucklandStandardContract"] = 1;
    axios
      .patch(`ScheduleItems/PatchScheduleItem/${sItem.id}`, sItem, {
        params: {
          user: user?.email,
        },
      })
      .then((res) => console.log(res.data))
      .catch((err) => console.log(err));
  };

  const fetchAllBudget = () => {
    setBudgetsForPoLoading(true)
    axios
      .get(
        `budgetitems/GetBudgetForCompanyByProject/${props.project.companyFk}`,
        {
          params: {
            isArchived: 0,
            projectId: props.project.id,
            showHidden: true,
          },
        }
      )
      .then(({ data }) => {
        setBudgetsForPO(data);
      }).finally(() => {
        setBudgetsForPoLoading(false)
      });
  };

  useEffect(() => {
    fetchAllBudget();
  }, []);

  return (
    <div>
      <Snacky
        snackprops={props.snackbar}
        setSnackBarProps={props.setSnackBarProps}
      />
      <div style={{ marginBottom: 20 }}>
        <BudgetItemsTable
          budgetItems={props.budget.budget}
          parent={"PrePost"}
        />
      </div>
      <>
        <Card style={{ marginTop: "15px" }}>
          <div style={{ margin: "0px", padding: "0px" }}>
            <>
              <div>
                <NewPrePost
                  scheduleItems={items} //scheduleItems
                  companies={companies}
                  people={people}
                  schedule={{
                    id: 1000,
                  }}
                  // providerType={"Contractor"}
                  // providerType={""}
                  passNewScheduleUp={passNewScheduleUp}
                  budgetItems={
                    !props.budgetLoading
                      ? props.budget.budget.filter(
                        (b) =>
                          b.category !== "Location Fees" &&
                          b.category !== "Catering"
                      )
                      : []
                  }
                  project={props.project}
                  snackbar={props.snackbar}
                  setSnackBarProps={props.setSnackBarProps}
                />
              </div>
              <div className="pre-post-tab-style">
                {itemsFetched && items !== undefined ? (
                  <PrePostTable
                    scheduleItems={items}
                    //scheduleItems={props.scheduleItems}
                    budgetItems={props.budget.budget}
                    snackbar={props.snackbar}
                    setSnackBarProps={props.setSnackBarProps}
                    companies={companies}
                    // people={people}
                    deleteScheduleItem={deleteScheduleItem}
                    reloadScheduleItemsList={() => setItemsFetched(false)}
                    completeTalentContract={handleCompleteTalentContract}
                    handleScheduleItemEdit={handleScheduleItemEdit}
                    completePrePostNotes={handleCompletePrePostNotes}
                    switchContractType={handleSwitchContractType}
                    projectTag={props.project.projectTag}
                    projectid={props.project.id}
                    handleCompleteAucklandContract={
                      handleCompleteAucklandContract
                    }
                    confirmAucklandStandard={confirmAucklandStandard}
                    prepostScheduleId={prepostScheduleId}
                    projectName={props.project.projectName}
                    project={props.project}
                  />
                ) : (
                  <div></div>
                )}
              </div>
            </>
          </div>
        </Card>
        <div style={{ marginTop: 10 }}>
          {!budgetsForPoLoading && (
            <ClientPurchaseOrder
              budgetItems={budgetsForPO}
              companyId={props.project.companyFk}
              fetchAllBudget={() => fetchAllBudget()}
            />
          )}
        </div>
      </>
    </div>
  );
};
const mapStateToProps = (state, ownProps) => ({
  snackbar: state.snackbar,
  budgetLoading: state.budget.budgetLoading,
});
// https://react-redux.js.org/api/connect
export default connect(mapStateToProps, {
  setSnackBarProps,
  getBudget,
})(PreProduction);
