import React, { useState, useEffect } from "react";
import Axios from "axios";
import { connect } from "react-redux";
import { getExpenseCodes } from "../../../actions/budget";
import { getValueOrEmptyString } from "../../../utils/utils";
import {
    Modal,
    AppBar,
    Toolbar,
    Typography,
    IconButton,
    Paper,
    TextField,
    Button,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    Switch
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";

const useStyles = makeStyles(theme => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflow: "hidden"
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        height: "auto",
        maxHeight: "80vH",
        width: "500px",
        overflowY: "auto"
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const AccountCodePopUp = (props) => {
    const classes = useStyles();
    const receipt = props.receipt;
    let { expenseCodes, getExpenseCodes } = props;

    const [accountCode, setAccountCode] = useState(null);
    const [expenseName, setExpenseName] = useState(null);

    const handleSave = () => {
        //updates front-end
        var newReceipt = receipt;
        newReceipt.billStatus = "Approved";
        newReceipt.accountCode = accountCode;
        props.acceptReceipt("Approved", "billStatus", receipt);
        props.handleCloseModal();
        //updates back-end
        Axios
            .post(
                `Receipts/WriteToReceiptSheetFromAdmin/${newReceipt.id}`,
                newReceipt
            )
            .then((res) => console.log(res.data))
            .catch((e) => console.log(e));
    }

    const handleExpenseCode = (obj, value) => {
        setExpenseName(value);
        setAccountCode(value.accountCode);
    };

    const getExpenseCodeCombo = () => {
        return (
            <Autocomplete
                style={{ marginRight: "5px", marginLeft: "5px" }}
                options={expenseCodes}
                getOptionLabel={(option) => option.accountName}
                disableClearable
                value={expenseName}
                size="small"
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Account Name"
                        placeholder="Account Name *"
                        fullWidth
                        size="small"
                        error={accountCode === null}
                    />
                )}
                ListboxProps
                onChange={handleExpenseCode}
            />
        );
    };

    useEffect(() => {
        setAccountCode(receipt.accountCode)
        getExpenseCodes();
    }, [])

    return (
        <div>
            <Modal
                open={props.modalState}
                onClose={() => props.handleCloseModal()}
                className={classes.modal}
            >
                <div>
                    <AppBar position="static" style={{ background: "#217F8B" }}>
                        <Toolbar
                            disableGutters
                            variant="dense"
                        >
                            <Typography
                                variant="subtitle1"
                                align="center"
                                style={{ flexGrow: 1 }}
                            >
                                Enter Account Code
                            </Typography>
                            <IconButton
                                onClick={() => props.handleCloseModal()}
                                color="inherit"
                            >
                                <Close />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <div className={classes.paper}>
                        {getExpenseCodeCombo()}
                        <div className="textfield-padding">
                            <TextField
                                id={`AccountCode-AccountCodePopUp`}
                                disabled
                                variant="outlined"
                                label={`AccountCode`}
                                value={getValueOrEmptyString(accountCode).toString()}
                                onChange={(event) => setAccountCode(event.target.value)}
                                fullWidth
                                size="small"
                                error={getValueOrEmptyString(accountCode) === null || getValueOrEmptyString(accountCode).toString().trim() === ""}
                            />
                        </div>
                        <div
                            style={{ display: 'flex', justifyContent: 'flex-end' }}
                        >
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={() => props.handleCloseModal()}
                                style={{ margin: 10 }}
                            >
                                Cancel
                            </Button>
                            <Button
                                color="primary"
                                variant="contained"
                                disabled={accountCode === null || accountCode === ""}
                                onClick={() => handleSave()}
                                style={{ margin: 10 }}
                            >
                                Proceed
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

const mapStateToProps = (state, ownProps) => ({
    expenseCodes: state.budget.expenseCodes,
});
export default connect(mapStateToProps, {
    getExpenseCodes,
})(AccountCodePopUp);