import React, { useState, useEffect } from "react";
import Axios from "axios";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { setSnackBarProps } from "../../../actions/snackbar";
import { set } from "date-fns";
import CheckInTable from "./CheckInTable";
import Snacky from "../../Shared/Snacky";
import {
    Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(3),
    },
    root: {
        "& > *": {
            margin: theme.spacing(1),
            width: "25ch",
        },
    },
}));

const CheckInPage = (props) => {
    let { setSnackBarProps, snackbar } = props;
    const classes = useStyles();
    const [checkInList, setCheckInList] = useState(null);
    const [initialFilters, setInitialFilters] = useState({});
    const [reload, setReload] = useState(false);

    const dismissItem = (checkInItem) => {
        Axios.patch(`CheckIn/DismissCheckIn`, checkInItem)
            .then((res) => {
                console.log(res)
                setSnackBarProps("success", "Item Dismissed", true);
            })
            .catch((e) => {
                console.log(e)
                setSnackBarProps("warning", "Error: Could Not Dismiss", true);
            })

        setCheckInList(checkInList.filter(item => item.id !== checkInItem.id));
    }

    const handleEditCheckInItem = (checkInItem) => {
        setCheckInList(checkInList.map((c) => {
            return (
                c.id === checkInItem.id ? {
                    ...c,
                    ["firstName"]: checkInItem.firstName,
                    ["lastName"]: checkInItem.lastName,
                    ["name"]: checkInItem.name,
                } : c
            )
        }));
    }

    useEffect(() => {
        console.log("checkInList")
        console.log(checkInList)
        setReload(true);
    }, [checkInList])

    useEffect(() => {
        if (reload) {
            setReload(false);
        }
    }, [reload])

    useEffect(() => {
        Axios.get(`CheckIn/GetAllCheckIn`)
            .then(res => {
                console.log(res);
                var arr = res.data;
                for (var i = 0; i < arr.length; i++) {
                    //Format values correctly
                    arr[i].date = arr[i].date !== null ? new Date(arr[i].date) : null;
                }
                setCheckInList(arr);
            })
            .catch(e => {
                console.log(e)
            })
    }, [])

    return (
        <main className={classes.content}>
            <div className="main-content" />
            <Typography variant="h4" align="center" gutterBottom>Check In</Typography>
            <Snacky snackprops={snackbar} setSnackBarProps={setSnackBarProps} />
            {checkInList !== undefined && checkInList !== null && !reload &&
                (<CheckInTable
                    checkInList={checkInList}
                    dismissItem={dismissItem}
                    initialFilters={initialFilters}
                    setInitialFilters={setInitialFilters}
                    handleEditCheckInItem={handleEditCheckInItem}
                />)
            }
        </main>

    )
}

const mapStateToProps = (state, ownProps) => ({
    snackbar: state.snackbar,
});
export default connect(mapStateToProps, {
    setSnackBarProps,
})(CheckInPage);