import React from "react";
import { Container, Typography } from "@material-ui/core";
import TimeTrackerAllocation from "./Allocation/TimeTrackerAllocation";
import "./timeTracker.scss";
const TimeTracker = () => {
  return (
    <Container maxWidth="lg">
      <div className="time-tracker-root">
        <Typography>Time Tracker</Typography>
        <TimeTrackerAllocation />
      </div>
    </Container>
  );
};

export default TimeTracker;
