import {
  Button,
  IconButton,
  Paper,
  TableContainer,
  TextField,
  Tooltip,
  Typography,
  withStyles,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import CustomTableHead from "../../Shared/CustomTableHead";
import LoadingTableSkeleton from "../../Shared/LoadingTableSkeleton";
import { getComparator } from "../../../utils/TableUtils";
import "./clientPeopleOfInfluenceTable.scss";
import { currencyFormat, isEmpty } from "../../../utils/GeneralUtils";
import DeleteIcon from "@material-ui/icons/Delete";
import ConfirmationDialogActions from "../../Shared/ConfirmationDialogActions";
import ProcessingSpinnerDialog from "../../Shared/Spinner/ProcessingSpinnerDialog";
import DescriptionIcon from "@material-ui/icons/Description";
import { Autocomplete } from "@material-ui/lab";
import moment from "moment";
import { get12MonthsAheadAnd1MonthBackArr } from "../../../utils/TimeHelpers";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import axios from "axios";

const CustomTableRow = ({
  si,
  renderInvoiceStatus,
  handleOpenPdf,
  handleRemoveScheduleItem,
  handleChangeInvoiceMonth,
  handleOpenSendInvoiceDialog,
}) => {
  return (
    <tr key={si?.id} className="client-people-of-influence-table-tbody-tr">
      <td>
        <Typography variant="caption">{si.providerName}</Typography>
      </td>
      <td style={{ maxWidth: 350 }}>
        <Typography variant="caption">{si.category}</Typography>
      </td>
      <td>
        <Typography variant="caption">
          {currencyFormat(si.allocatedRates)}
        </Typography>
      </td>
      <td>
        <Typography variant="caption">
          {renderInvoiceStatus(si.contractStatus)}
        </Typography>
        {(si.contractStatus === "AwaitingPayment" ||
          si.contractStatus === "Paid") && (
          <IconButton
            aria-label="pdf"
            size="small"
            onClick={(e) => handleOpenPdf(e, si.id)}
          >
            <PictureAsPdfIcon />
          </IconButton>
        )}
      </td>
      <td style={{ minWidth: 150 }}>
        <Autocomplete
          value={
            si.startTime
              ? {
                  display: moment(si.startTime).format("MMMM YYYY"),
                  value: si.startTime,
                }
              : null
          }
          options={get12MonthsAheadAnd1MonthBackArr()}
          onChange={(event, option) => {
            event.stopPropagation();
            handleChangeInvoiceMonth({
              ...si,
              startTime: option.value,
            });
          }}
          getOptionLabel={(option) => option.display}
          renderInput={(params) => (
            <TextField
              {...params}
              label={"Billing Month"}
              size="small"
              fullWidth={true}
              variant="outlined"
            />
          )}
          disableClearable
          disabled={
            si.contractStatus === "AwaitingPayment" ||
            si.contractStatus === "Paid"
          }
        />
      </td>

      <td>
        <div className="client-people-of-influence-table-flex-container">
          <Button
            disabled={
              si.contractStatus === "AwaitingPayment" ||
              si.contractStatus === "Paid"
            }
            onClick={(e) => handleOpenSendInvoiceDialog(si)}
            size="small"
            color="primary"
            variant="contained"
          >
            {si.contractStatus === "AwaitingPayment" ||
            si.contractStatus === "Paid"
              ? "Sent"
              : "Send Now"}
          </Button>
          {si.contractStatus === "AwaitingPayment" ||
          si.contractStatus === "Paid" ? (
            ""
          ) : (
            <IconButton
              aria-label="delete"
              size="small"
              onClick={(e) => handleRemoveScheduleItem(e, si)}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </div>
      </td>
    </tr>
  );
};
const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);
const tableHeader = [
  {
    field: "providerName",
    name: "Talent",
    fieldType: "string",
    alignment: "left",
  },
  {
    field: "category",
    name: "Budget",
    fieldType: "string",
    alignment: "left",
  },
  {
    field: "allocatedRates",
    name: "Fee",
    fieldType: "number",
    alignment: "left",
  },
  {
    field: "contractStatus",
    name: "Invoice Status",
    fieldType: "string",
    alignment: "left",
  },
  {
    field: "startTime",
    name: "",
    fieldType: "date",
    alignment: "left",
  },

  {
    field: "providerName",
    name: "",
    fieldType: "string",
    alignment: "center",
  },
];

const ClientPeopleOfInfluenceTable = ({
  poiInfluencers,
  isLoading,
  handleSelectInfluencer,
  removeScheduleItem,
  handleChangeInvoiceMonth,
  handleOpenPdf,
  setPoiInfluencers,
  setSnackBarProps,
  snackBarProps,
  setSearchPaidInvoices,
  searchPaidInvoices,
}) => {
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState({
    field: "providerName",
    name: "",
    fieldType: "string",
  });
  const [selectedInfluencer, setSelectedInfluencer] = useState(null);
  const [openRemoveDialog, setOpenRemoveDialog] = useState(false);
  const [isSendingInvoice, setIsSendingInvoice] = useState(false);
  const [openConfirmSendInvoiceDialog, setOpenConfirmSendInvoiceDialog] =
    useState(false);
  const [isRemovingInfluencer, setIsRemovingInfluencer] = useState(false);

  const handleOnRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleRowClick = (e, influencer) => {
    e.preventDefault();
    handleSelectInfluencer(influencer);
  };

  const handleRemoveScheduleItem = (e, influencer) => {
    e.preventDefault();
    e.stopPropagation();
    setSelectedInfluencer(influencer);
    setOpenRemoveDialog(true);
  };

  const handleCloseRemoveDialog = () => {
    setSelectedInfluencer(null);
    setOpenRemoveDialog(false);
    setIsRemovingInfluencer(false);
  };

  const handleConfirmInfluencerRemoval = async () => {
    setIsRemovingInfluencer(true);
    await removeScheduleItem(selectedInfluencer);
    handleCloseRemoveDialog();
  };

  const handleOpenSendInvoiceDialog = async (si) => {
    setSelectedInfluencer(si);
    setOpenConfirmSendInvoiceDialog(true);
  };

  const renderInvoiceStatus = (status) => {
    switch (status) {
      case "AwaitingPayment":
        return "Awaiting Payment";
      case "InvoicePending":
        return "Awaiting Billing Month";
      default:
        return status;
    }
  };

  const handleConfirmSendInvoice = () => {
    setIsSendingInvoice(true);
    axios
      .post(
        `/influencer/PostInfluencerInvoice/${selectedInfluencer.personFk}/${selectedInfluencer.id}`
      )
      .then(({ data }) => {
        setPoiInfluencers(
          poiInfluencers.map((i) => (i.id == data.id ? data : i))
        );
        setSnackBarProps({
          open: true,
          severity: "success",
          text: "The invoice has been created.",
        });
        handleCloseSendInvoiceDialog();
      })
      .catch((err) => {
        console.log(err);
        setSnackBarProps({
          open: true,
          severity: "warning",
          text: "Unable to send invoice.. The dev team has been notified.",
        });
      })
      .finally(() => {
        setIsSendingInvoice(false);
      });
  };

  const handleCloseSendInvoiceDialog = () => {
    setSelectedInfluencer(null);
    setOpenConfirmSendInvoiceDialog(false);
  };

  return (
    <div className="client-people-of-influence-table-root-container">
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "end",
        }}
      >
        <Typography
          variant="caption"
          className="reimbursement-show-hidden-link-button"
          onClick={(e) => setSearchPaidInvoices(!searchPaidInvoices)}
        >
          {searchPaidInvoices ? "Hide Paid Invoices" : "Show Paid Invoices"}
        </Typography>
      </div>
      {openRemoveDialog && !!selectedInfluencer && (
        <ConfirmationDialogActions
          title={"Are you sure?"}
          open={openRemoveDialog}
          contentText={
            isRemovingInfluencer
              ? "Processing..."
              : "This action will remove the talent"
          }
          button1Action={handleCloseRemoveDialog}
          button1Text={"Cancel"}
          button2Text={"Confirm"}
          button2Action={handleConfirmInfluencerRemoval}
        />
      )}
      {openConfirmSendInvoiceDialog &&
        !!selectedInfluencer &&
        isSendingInvoice && (
          <ProcessingSpinnerDialog loading={isSendingInvoice} />
        )}
      {openConfirmSendInvoiceDialog &&
        !!selectedInfluencer &&
        !isSendingInvoice && (
          <ConfirmationDialogActions
            title={"Are you sure?"}
            open={openConfirmSendInvoiceDialog}
            contentText={
              isSendingInvoice
                ? "Processing..."
                : `This action will send the invoice to ${selectedInfluencer.providerName}`
            }
            button1Action={handleCloseSendInvoiceDialog}
            button1Text={"Cancel"}
            button2Text={"Confirm"}
            button2Action={handleConfirmSendInvoice}
          />
        )}
      <TableContainer component={Paper}>
        <table className="custom-table custom-table-border custom-table-hover socialites-projects-table">
          <CustomTableHead
            headers={tableHeader}
            orderBy={orderBy}
            order={order}
            onRequestSort={handleOnRequestSort}
          />
          <tbody>
            {isLoading ? (
              <LoadingTableSkeleton
                loadingMessage="Loading talents..."
                colspan="6"
              />
            ) : (
              [...poiInfluencers]
                .sort(getComparator(order, orderBy))
                .map((si) => (
                  <CustomTableRow
                    key={si.id}
                    si={si}
                    handleOpenPdf={handleOpenPdf}
                    handleChangeInvoiceMonth={handleChangeInvoiceMonth}
                    handleRemoveScheduleItem={handleRemoveScheduleItem}
                    renderInvoiceStatus={renderInvoiceStatus}
                    handleOpenSendInvoiceDialog={handleOpenSendInvoiceDialog}
                  />
                ))
            )}
          </tbody>
        </table>
      </TableContainer>
      {!isLoading && poiInfluencers?.length === 0 && (
        <div className="client-people-of-influence-table-no-records">
          <Typography variant="h6">Nothing here...</Typography>
        </div>
      )}
    </div>
  );
};

export default ClientPeopleOfInfluenceTable;
