import React, { useState, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Axios from "axios";
import {
  Grid,
  Tooltip,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  Input,
  MenuItem,
  TextField,
} from "@material-ui/core";
import "../Admin.css";
import Loader from "../../Loader";
import Snacky from "../../Shared/Snacky";
import { connect } from "react-redux";
import { setSnackBarProps } from "../../../actions/snackbar";
import { setProjectFilter } from "../../../actions/adminMenu";
import ReimbursementTable from "./ReimbursementTable";
import DepositTable from "../Deposit/DepositTable";
import NewReinbursmentPersonPopUp from "./NewReinbursmentPersonPopUp";

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));

const addCommasToFloat = (float) => {
  /*
      The regex uses 2 lookahead assertions:
        - a positive one to look for any point in the string that has a multiple of 3 digits in a row after it,
        - a negative assertion to make sure that point only has exactly a multiple of 3 digits. The replacement expression puts a comma there.
        - (?<!\.\d*) is a negative lookbehind that says the match can't be preceded by a . followed by zero or more digits.
    */
  return float.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
};

const ReimbursementPage = (props) => {
  const classes = useStyles();
  let { setSnackBarProps, snackbar } = props;

  const [totalDueOnScreen, setTotalDueOnScreen] = useState(0.0);
  const [allReceipts, setAllReceipts] = useState();
  const [filterClicked, setFilterClicked] = useState(false);
  const [rowDeleted, setRowDeleted] = useState(false);
  const [updateRemaining, setUpdateRemaining] = useState(false);
  const [requestedBankAccountReceipt, setRequestedBankAccountReceipt] = useState(false);


  const [currentUser, setCurrentUser] = useState(null);
  const [openReimbursementPopUp, setOpenReimbursementPopUp] = useState(false);
  const [getReimbursementManager, setGetReimbursementManager] = useState(true);

  const [initialFilters, setInitialFilters] = useState({
    billStatus: ["Approved", "Rejected", "PAID"],
  });

  const [depositList, setDepositList] = useState();
  const [initialDepositFilters, setInitialDepositFilters] = useState({
    dismissed: [1],
  });
  const [depositFilterClicked, setDepositFilterClicked] = useState(false);

  const dismissDeposit = (depositItem) => {
    Axios.patch(
      `Deposit/UpdateDismissStatus/${depositItem.projectId}`,
      depositItem
    )
      .then((res) => {
        var message =
          depositItem.dismissed === 1 ? "Deposit Muted" : "Deposit Unmuted";
        setSnackBarProps("success", message, true);
        console.log(res);
      })
      .catch((e) => {
        var message =
          depositItem.dismissed === 1
            ? "Error: Could not Mute Deposit"
            : "Error: Could not Unmute Deposit";
        setSnackBarProps("warning", message, true);
        console.log(e);
      });

    setDepositList(
      depositList.map((item) => {
        return item.id === depositItem.id
          ? {
            ...item,
            ["dismissed"]: depositItem.dismissed,
          }
          : item;
      })
    );
  };

  const handleReceiptRejected = (receiptItem) => {
    var newReceipts = allReceipts;
    newReceipts.map((receipt) => {
      if (receiptItem.id === receipt.id) {
        receipt.remaining = receipt.remaining + parseFloat(receiptItem.amount);
        receipt.billStatus = "Rejected";
        return receipt;
      } else if (
        receiptItem !== null &&
        receiptItem.projectId === receipt.projectId
      ) {
        receipt.remaining = receipt.remaining + parseFloat(receiptItem.amount);
        return receipt;
      } else {
        return receipt;
      }
    });
    // console.log(newReceipts);
    setAllReceipts(newReceipts);
    setUpdateRemaining(true);
  };

  const handleReceiptEdit = (value, name, receiptItem) => {
    if (value === "Rejected") {
      handleReceiptRejected(receiptItem);
    } else {
      setAllReceipts(
        allReceipts.map((r) => {
          if (r.id === receiptItem.id) {
            Axios.put(
              `Receipts/UpdateReceiptFromAdmin/${receiptItem.id}`,
              receiptItem
            )
              .then((res) => {
                console.log(res);
              })
              .catch((e) => {
                console.log(e);
              });
          }
          return r.id === receiptItem.id
            ? {
              ...r,
              [name]: value,
            }
            : r;
        })
      );
    }
  };

  const handleReceiptDelete = (receiptItem) => {
    var newList = [];
    for (var i = 0; i < allReceipts.length; i++) {
      if (allReceipts[i].id !== receiptItem.id) {
        newList.push(allReceipts[i]);
      }
    }
    setAllReceipts(newList);
    setRowDeleted(true);
  };

  const handleRequestBankAccountReceipt = (receiptItem) => {
    var newList = [];
    for (var i = 0; i < allReceipts.length; i++) {
      if (allReceipts[i].id === receiptItem.id) {
        newList.push(receiptItem);
      } else {
        newList.push(allReceipts[i]);
      }
    }
    setAllReceipts(newList);
    setRowDeleted(true);
  };

  const handleEditReceiptModalSave = (receiptItem) => {
    setAllReceipts(
      allReceipts.map((r) => {
        if (r.id === receiptItem.id) {
          Axios.put(
            `Receipts/UpdateReceiptFromAdmin/${receiptItem.id}`,
            receiptItem
          )
            .then((res) => {
              console.log(res);
            })
            .catch((e) => {
              console.log(e);
            });
        }
        return r.id === receiptItem.id
          ? {
            ...r,
            ["supplier"]: receiptItem.supplier,
            ["type"]: receiptItem.type,
            ["accountCode"]: receiptItem.accountCode,
            ["amount"]: receiptItem.amount,
            ["bankAccount"]: receiptItem.bankAccount,
            ["gst"]: receiptItem.gst,
            ["camistryCard"]: receiptItem.camistryCard,
          }
          : r;
      })
    );
  };

  useEffect(() => {
    Axios.get("Receipts/GetAllReceiptsForAdmin")
      .then((res) => {
        var arr = res.data;
        var newAmount = 0.0;
        for (var i = 0; i < arr.length; i++) {
          //Format values correctly
          arr[i].date = arr[i].date !== null ? new Date(arr[i].date) : null;
          arr[i].amount =
            arr[i].amount !== null
              ? parseFloat(arr[i].amount).toFixed(2)
              : null;
          //Set up initial Total
          if (arr[i].amount !== null) {
            newAmount = newAmount + parseFloat(arr[i].amount);
          }
        }
        setAllReceipts(arr);
        setTotalDueOnScreen(newAmount);
      })
      .catch((err) => console.log(err));
  }, []);

  const [preimbursementUser, setPreimbursementUser] = useState();

  useEffect(() => {
    if (getReimbursementManager) {
      setGetReimbursementManager(false);
      const _getReimbursementManager = Axios.get(
        "Users/GetReimbursementManager"
      );
      const _getPreimbursementManager = Axios.get(
        "Users/GetPreimbursementManager"
      );

      Axios.all([_getReimbursementManager, _getPreimbursementManager])
        .then(
          Axios.spread((...res) => {
            setCurrentUser(res[0].data);
            setPreimbursementUser(res[1].data);
          })
        )
        .catch((err) => console.log(err));
    }
  }, [getReimbursementManager]);

  useEffect(() => {
    setFilterClicked(false);
  }, [initialFilters]);

  useEffect(() => {
    setDepositFilterClicked(false);
  }, [depositFilterClicked]);

  useEffect(() => {
    setRowDeleted(false);
  }, [rowDeleted]);

  useEffect(() => {
    if (allReceipts !== null && allReceipts !== undefined) {
      // console.log("allReceipts.length")
      // console.log(allReceipts.length)
      // console.log("-----------------")
    }
    if (depositList !== null && depositList !== undefined) {
      // console.log("depositList.length")
      // console.log(depositList.length)
      // console.log("-----------------")
    }
  }, [allReceipts, depositList]);

  useEffect(() => {
    if (updateRemaining) {
      setUpdateRemaining(false);
    }
  }, [updateRemaining]);

  //---------
  useEffect(() => {
    Axios.get("Deposit/GetAwaitingDeposits")
      .then((res) => {
        var arr = res.data;
        for (var i = 0; i < arr.length; i++) {
          //Format values correctly
          arr[i].dateSigned =
            arr[i].dateSigned !== null ? new Date(arr[i].dateSigned) : null;
          arr[i].dueDate =
            arr[i].dueDate !== null ? new Date(arr[i].dueDate) : null;
          arr[i].firstShoot =
            arr[i].firstShoot !== null ? new Date(arr[i].firstShoot) : null;
        }
        setDepositList(arr);
      })
      .catch((err) => console.log(err));
  }, []);
  //---------

  return (
    <main className={classes.content}>
      <div className="main-content" />
      <Snacky snackprops={snackbar} setSnackBarProps={setSnackBarProps} />
      {openReimbursementPopUp ? (
        <NewReinbursmentPersonPopUp
          modalState={openReimbursementPopUp}
          handleCloseModal={() => setOpenReimbursementPopUp(false)}
          setGetReimbursementManager={setGetReimbursementManager}
          changeCurrentUsers={(user, preimbursement) => {
            setCurrentUser(user);
            setPreimbursementUser(preimbursement);
          }}
        />
      ) : (
        <React.Fragment></React.Fragment>
      )}
      <Grid container>
        {snackbar.open && (
          <Snacky snackprops={snackbar} setSnackBarProps={setSnackBarProps} />
        )}
        <Grid item xs={2}>
          <Typography variant="h4">Reimbursement</Typography>
        </Grid>
        <Grid item xs={3}>
          {!filterClicked ? (
            <React.Fragment>
              <Button
                onClick={() => {
                  setFilterClicked(true);
                  setInitialFilters({});
                }}
                variant="contained"
                color="Primary"
                className="add-margin-left-right-5"
              >
                Show All
              </Button>
              <Button
                onClick={() => {
                  setFilterClicked(true);
                  setInitialFilters({
                    billStatus: ["Approved", "Rejected", "PAID"],
                  });
                }}
                variant="contained"
                color="Primary"
                className="add-margin-left-right-5"
              >
                Pending
              </Button>
            </React.Fragment>
          ) : (
            <CircularProgress />
          )}
        </Grid>
        {totalDueOnScreen !== null || totalDueOnScreen !== undefined ? (
          <React.Fragment>
            <Grid item xs={2}>
              <p className="totalAmountDue" style={{ fontWeight: "bold" }}>
                {`Total Due:  $${addCommasToFloat(
                  totalDueOnScreen.toFixed(2)
                )}`}
              </p>
            </Grid>
          </React.Fragment>
        ) : (
          <React.Fragment></React.Fragment>
        )}
        {currentUser != null && preimbursementUser != null ? (
          <Grid item xs={5}>
            <div style={{ marginBottom: 5 }}>
              <TextField
                variant="outlined"
                label={"Preimbursement Manager"}
                value={`${preimbursementUser.firstName} ${preimbursementUser.lastName}`}
                size="small"
                disabled
              />
              <TextField
                variant="outlined"
                label={"Reimbursement Manager"}
                value={`${currentUser.firstName} ${currentUser.lastName}`}
                size="small"
                disabled
                style={{ marginLeft: "5px" }}
              />
              <Button
                variant="contained"
                color="primary"
                style={{ marginLeft: "15px" }}
                onClick={() => setOpenReimbursementPopUp(true)}
              >
                Change Manager
              </Button>
            </div>
          </Grid>
        ) : (
          <React.Fragment></React.Fragment>
        )}
        <Grid item xs={12} style={{ height: "40vH", overflow: "auto" }}>
          {allReceipts !== undefined &&
            allReceipts !== null &&
            !filterClicked &&
            !rowDeleted &&
            !updateRemaining ? (
            <ReimbursementTable
              reimbursementList={allReceipts}
              setTotalDueOnScreen={(x) => setTotalDueOnScreen(x)}
              filterClicked={filterClicked}
              setFilterClicked={setFilterClicked}
              initialFilters={initialFilters}
              handleReceiptEdit={handleReceiptEdit}
              handleEditReceiptModalSave={handleEditReceiptModalSave}
              handleReceiptDelete={handleReceiptDelete}
              rowDeleted={rowDeleted}
              setRowDeleted={setRowDeleted}
              handleRequestBankAccountReceipt={handleRequestBankAccountReceipt}
              requestedBankAccountReceipt={requestedBankAccountReceipt}
              setRequestedBankAccountReceipt={setRequestedBankAccountReceipt}
            />
          ) : (
            <div>
              <h1
                className="loading-dots"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <span className="dot" />
                <span className="dot" />
                <span className="dot" />
              </h1>
            </div>
          )}
        </Grid>
        <Grid item xs={2} style={{ paddingTop: "10px" }}>
          <Typography variant="h4">Deposit</Typography>
        </Grid>
        <Grid item xs={6} style={{ paddingTop: "10px" }}>
          {!depositFilterClicked ? (
            <div style={{ display: "flex" }}>
              <Button
                size="small"
                variant="contained"
                color="primary"
                className="add-margin-left-right-5"
                onClick={() => {
                  setDepositFilterClicked(true);
                  setInitialDepositFilters({});
                }}
              >
                Show All
              </Button>
              <Button
                size="small"
                variant="contained"
                color="primary"
                className="add-margin-left-right-5"
                onClick={() => {
                  setDepositFilterClicked(true);
                  setInitialDepositFilters({
                    dismissed: [1],
                  });
                }}
              >
                Unmuted
              </Button>
              <Button
                size="small"
                variant="contained"
                color="primary"
                className="add-margin-left-right-5"
                onClick={() => {
                  setDepositFilterClicked(true);
                  setInitialDepositFilters({
                    dismissed: [0],
                  });
                }}
              >
                Muted
              </Button>
            </div>
          ) : (
            <CircularProgress />
          )}
        </Grid>
        <Grid item xs={12} style={{ height: "40vH", overflow: "auto" }}>
          {depositList !== undefined &&
            depositList !== null &&
            !depositFilterClicked ? (
            <DepositTable
              allDeposits={depositList}
              initialFilters={initialDepositFilters}
              dismissDeposit={dismissDeposit}
            />
          ) : (
            <div>
              <h1
                className="loading-dots"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <span className="dot" />
                <span className="dot" />
                <span className="dot" />
              </h1>
            </div>
          )}
        </Grid>
      </Grid>
    </main>
  );
};

const mapStateToProps = (state, ownProps) => ({
  snackbar: state.snackbar,
});

export default connect(mapStateToProps, {
  setSnackBarProps,
})(ReimbursementPage);
