import React, { useEffect, useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import {
  Table,
  AppBar,
  Toolbar,
  Typography,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  TableHead,
  Button,
  TableSortLabel,
  Tooltip,
} from "@material-ui/core";
import HomeTableHead from "./HomeTableHead";
import { connect } from "react-redux";
import { getProjects } from "../../actions/projects";
import HomeTableRow from "./HomeTableRow";
import Header from "../Header";
import DeliverPopup from "../Project/ProjectOutputs/PopUps/DeliverPopUp";
import "./homeTable.scss";
import Axios from "axios";
import Snacky from "../Shared/Snacky";
import ConfirmationDialogActions from "../Shared/ConfirmationDialogActions";
import { setSnackBarProps } from "../../actions/snackbar";
import {
  getAllProjectActiveItems,
  resetActiveItemStore,
  closeProjectAndSendAllInvoices,
} from "../../actions/closeProject";
import Dialogy from "../Shared/Dialogy";
import { setDialogProps } from "../../actions/dialog";
import OverdueOutputsPopup from "./OverdueOutputsPopup";
import { useUserContext } from "../../context/user/UserContext";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

const HomeTable = ({
  projects,
  snackbar,
  activeItems,
  getProjects,
  setShowHomeTable,
  setSnackBarProps,
  getAllProjectActiveItems,
  resetActiveItemStore,
  closeProjectAndSendAllInvoices,
  setShowInternalProjects,
  showInternalProjects,
}) => {
  const classes = useStyles();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("nextDeadline");
  const [selected, setSelected] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedOutput, setSelectedOutput] = useState(null);
  const [showDeliverPopUp, setShowDeliverPopUp] = useState(false);
  const [isLastOutput, setIsLastOutput] = useState(false);
  const [openAllProjects, setOpenAllProjects] = useState(false);

  const [showOverdueWarning, setShowOverdueWarning] = useState(false);
  const [
    overdueWarningAlreadyDisplayed,
    setOverdueWarningAlreadyDisplayed,
  ] = useState(true);
  const [displayAllOverdueProjects, setDisplayAllOverdueProjects] = useState(
    false
  );

  useEffect(() => {
    if (projects) {
      // if(projects.lockProductionCore && !overdueWarningAlreadyDisplayed){
      //   setShowOverdueWarning(true);
      //   setOverdueWarningAlreadyDisplayed(true);
      // }
      if (projects.lockProductionCore) {
        setDisplayAllOverdueProjects(true);
      }
    }
  }, [projects]);

  useEffect(() => {
    loadProjectsAndOutputs();
  }, []);

  const loadProjectsAndOutputs = () => {
    getProjects();
  };

  const handleCompleteDelivery = async () => {
    if (isLastOutput) {
      await archiveProject();
      await closeProjectAndSendAllInvoices(activeItems, {
        name: `Magnesium`,
        email: "dev@magnesium.nz",
      });

      resetActiveItemStore();
      await loadProjectsAndOutputs();
      setSnackBarProps("success", "The Delivery was successful", true);
    } else {
      loadProjectsAndOutputs();
      setSnackBarProps("success", "The Delivery was successful", true);
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const descendingComparator = (a, b, orderBy) => {
    if (orderBy === "nextDeadline") {
      if (b[orderBy] === null) {
        return 1;
      }
    }
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const toggleShowDeliverPopUp = () => {
    if (showDeliverPopUp) {
      setSelectedOutput(null);
      setIsLastOutput(false);
      setShowDeliverPopUp(false);
    } else {
      setShowDeliverPopUp(true);
    }
  };

  const getUniqueDestinations = (items) => {
    var arr = items.split(";");
    const uniqueValues = [...new Set(arr)];
    var uniqueItems = uniqueValues.join(", ");
    return uniqueItems;
  };

  const handleDeliverOutputDialog = (output, projectCopy) => {
    const project = projects.projects.find((p) => p.id === projectCopy.id);
    const _outputs = project?.outputs.find(
      (o) => o.delivered === 0 && o.id !== output.id
    );
    if (!_outputs) {
      setIsLastOutput(true);
    }
    setSelectedOutput(output);
    setShowDeliverPopUp(true);
  };

  const handleOpenAllProjects = () => {
    if (openAllProjects) {
      setOpenAllProjects(false);
    } else {
      setOpenAllProjects(true);
    }
    setSelectedProject(null);
    setSelectedOutput(null);
  };

  const archiveProject = async () => {
    Axios.put(`Projects/ArchiveProject/${selectedProject.id}`)
      .then((res) => {
        console.log(res.data);
        setSnackBarProps(
          "success",
          "The Delivery was successful and the project is now archived",
          true
        );
      })
      .catch((err) => {
        console.log(err);
        setSnackBarProps(
          "warning",
          "The Delivery was successful but we are not able to auto close this project.",
          true
        );
      });
  };

  const getFilteredProjects = () => {
    if (showInternalProjects) {
      return projects?.projects;
    }
    return projects?.projects.filter((p) => p.project?.isInternalProject !== 1);
  };

  return (
    <div>
      {displayAllOverdueProjects && (
        <OverdueOutputsPopup
          open={displayAllOverdueProjects}
          handleClose={() => setDisplayAllOverdueProjects(false)}
          projects={projects}
        />
      )}
      <Dialogy
        setDialogProps={setDialogProps}
        buttonProps={{
          show: showOverdueWarning,
          titleText: "Overdue Delivery",
          contentText:
            "There is an output that is overdue, other projects will be locked until this has been handled (Note: Overdue project is highlighted in red)",
          buttonOneColor: "primary",
          buttonOneText: "Cancel",
          buttonTwoColor: "primary",
          buttonTwoText: "Ok",
        }}
        handleButtonOne={() => setShowOverdueWarning(false)}
        handleButtonTwo={() => setShowOverdueWarning(false)}
      />
      <Header
        parent="HomeTable"
        setShowHomeTable={setShowHomeTable}
        openAll={openAllProjects}
        handleOpenAllProjects={handleOpenAllProjects}
        setShowInternalProjects={setShowInternalProjects}
        showInternalProjects={showInternalProjects}
      />
      <Snacky snackprops={snackbar} setSnackBarProps={setSnackBarProps} />
      {selectedProject && selectedOutput && showDeliverPopUp && (
        <DeliverPopup
          modalState={showDeliverPopUp}
          handleModal={toggleShowDeliverPopUp}
          outputDetails={selectedOutput}
          splitSemiColon={getUniqueDestinations}
          handleInputChangeDelivery={handleCompleteDelivery}
          parentTable={"allOutputs"}
          projectDetails={selectedProject}
          getProjects={getProjects}
        />
      )}
      <TableContainer
        component={Paper}
        style={{ overflow: "hidden" }}
      >
        <Table
          className={classes.table}
          size="small"
          aria-label="customized table"
          style={{ tableLayout: "fixed" }}
        >
          <HomeTableHead
            classes={classes}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />

          <TableBody>
            {stableSort(
              getFilteredProjects(),
              getComparator(order, orderBy)
            ).map((row) => (
              <HomeTableRow
                key={row.id}
                project={row}
                selectedProject={selectedProject}
                setSelectedProject={setSelectedProject}
                setSelectedOutput={setSelectedOutput}
                selectedOutput={selectedOutput}
                getAllProjectActiveItems={getAllProjectActiveItems}
                resetActiveItemStore={resetActiveItemStore}
                handleDeliverOutputDialog={handleDeliverOutputDialog}
                openAllProjects={openAllProjects}
                displayOverdueWarning={() => setShowOverdueWarning(true)}
                lockProductionCore={projects.lockProductionCore}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

const mapStateToProps = (state) => ({
  projects: state.projects,
  snackbar: state.snackbar,
  activeItems: state.closeProject.activeItemsForProject,
  dialog: state.dialog,
});
export default connect(mapStateToProps, {
  setSnackBarProps,
  getProjects,
  getAllProjectActiveItems,
  resetActiveItemStore,
  closeProjectAndSendAllInvoices,
  setDialogProps,
})(HomeTable);
