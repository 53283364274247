import { Button } from '@material-ui/core'
import React from 'react'
import "./newPurchaseOrderButton.scss"
const NewPurchaseOrderButton = ({handleClick}) => {
  return (
    <div className="new-purchase-order-button-root">
      <Button
        size="small"
        variant="contained"
        onClick={handleClick}
        className='default-button'
      >
        New Purchase Order
      </Button>
    </div>
  )
}

export default NewPurchaseOrderButton