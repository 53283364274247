import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
    Grid,
    Button,
    Paper,
    MenuItem,
    Modal,
    AppBar,
    Toolbar,
    IconButton,
    TextField,
    Typography,
    Select,
    CircularProgress
} from "@material-ui/core";
import {
    Close as CloseIcon,
    ArrowDropDown as ArrowDropDownIcon
} from "@material-ui/icons"
import {
    editScheduleItem,
    cancelInvoice,
} from "../../../../actions/scheduleItems";
import { setSnackBarProps } from "../../../../actions/snackbar";
import axios from "axios";
import {
    MuiPickersUtilsProvider,
    DatePicker
} from '@material-ui/pickers';
import DateFnsUtils from "@date-io/date-fns";
import { format } from "date-fns";
import RolloverConfirmation from "./RolloverConfirmation";
import { useUserContext } from "../../../../context/user/UserContext";
import { useAuth } from "../../../../context/auth/AuthContext";

const useStyles = makeStyles(theme => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflow: "hidden",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        height: "auto",
        maxHeight: "80vH",
        maxWidth: 800,
    },
    appbar: {
        backgroundColor: "#182d56",
        borderRadius: 5,
        color: "white",
        maxWidth: 800
    },
    modalBody: {
        margin: 10,
        minHeight: 100
    }
}));

const RolloverPopup = (props) => {
    const classes = useStyles();
    const { user } = useAuth();
    const {
        open,
        handleClose,
        projectId,
        si,
        setOpenConfirmationDialog,
        projectName
    } = props;
    const [category, setCategory] = useState(null);
    const [accountCodeOptions, setAccountCodeOptions] = useState([]);
    const [budgtetsLoaded, setBudgetsLoaded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [fee, setFee] = useState("");
    const [talentContractDetailsLoaded, setTalentContractDetailsLoaded] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [job, setJob] = useState(null);

    const [openRolloverConfirmation, setOpenRolloverConfirmation] = useState(false);

    const getDuration = () => {
        var start = format(startDate, "do LLLL yyyy");
        var end = format(endDate, "do LLLL yyyy");
        return `${start} to ${end}`;
    }

    const handleSendRollover = () => {
        setLoading(true);
        axios
            .post(`Mail/SendRolloverInvoice/${si.personFk}/${si.id}`, {
                mail: {
                    name: `${user.firstName} ${user.lastName}`,
                    email: user.email
                },
                category: category,
                fee: fee,
                duration: getDuration(),
                job: job
            })
            .then((res) => {
                setOpenConfirmationDialog(true);
                setOpenRolloverConfirmation(false);
                handleClose();
            })
            .catch((e) => {
                console.log(e);
            }).finally(() => {
                setLoading(false);
            });
    }

    const allFieldsCompleted = () => {
        if(startDate !== null && endDate !== null && job !== null && category !== null){
            return true;
        }
        return false;
    }

    useEffect(() => {
        if (!budgtetsLoaded) {
            axios.get(`budgetItems/GetBudgetItemsForProject/${projectId}`)
                .then(res => {
                    setAccountCodeOptions(res.data);
                }).catch(e => {
                    console.log(e)
                }).finally(() => {
                    setBudgetsLoaded(true);
                })
        }
    }, [budgtetsLoaded])
    return (
        <Modal
            open={open}
            onClose={(e, reason) => {
                if (reason !== "backdropClick" && reason != "escapeKeyDown") {
                    handleClose()
                }
            }}
            className={classes.modal}
        >
            <Paper className={classes.paper}>
                <AppBar position="static" className={classes.appbar}>
                    <Toolbar
                        variant="dense"
                        style={{ paddingRight: 0, backgroundColor: "#182d56" }}
                    >
                        <Typography
                            variant="subtitle1"
                            align="center"
                            style={{ flexGrow: 1 }}
                        >
                            {openRolloverConfirmation ? `Create Rollover` : `Rollover Invoice`}
                        </Typography>
                        {openRolloverConfirmation ? "" : (
                            <IconButton
                                color="inherit"
                            >
                                <CloseIcon onClick={() => handleClose()} />
                            </IconButton>
                        )}
                    </Toolbar>
                </AppBar>
                {openRolloverConfirmation ? (
                    <RolloverConfirmation
                        agent={si.personFkNavigation.agentFkNavigation}
                        rolloverData={{
                            category: category,
                            fee: parseFloat(fee),
                            duration: getDuration(),
                            job: job
                        }}
                        scheduleItem={si}
                        person={si.personFkNavigation}
                        handleSendRollover={handleSendRollover}
                        handleClose={() => setOpenRolloverConfirmation(false)}
                    />
                ) : (
                    <div className={classes.modalBody}>
                        {budgtetsLoaded ? (
                            <Grid container spacing={2}>
                                <Grid item xs={6} md={6} lg={6}>
                                    <Grid container spacing={2} style={{ paddingTop: 5 }}>
                                        <Grid item xs={12} md={12} lg={12}>
                                            <Typography variant="h6">Previous Contract</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={12} lg={12}>
                                            <Typography variant="body1" style={{ fontFamily: "Roboto, Helvetica, Arial, sans-serif" }}>
                                                <b>{`Account: `}</b><i>{`${si.category}`}</i>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={12} lg={12}>
                                            <Typography variant="body1" style={{ fontFamily: "Roboto, Helvetica, Arial, sans-serif" }}>
                                                <b>{`Project: `}</b><i>{`${projectName}`}</i>
                                            </Typography>
                                        </Grid>
                                        {/* <Grid item xs={12} md={12} lg={12}>
                                            <Typography variant="body1" style={{ fontFamily: "Roboto, Helvetica, Arial, sans-serif" }}>
                                                <b>{`Schedule: `}</b><i>{`$${si.scheduleFkNavigation.name}`}</i>
                                            </Typography>
                                        </Grid> */}
                                        <Grid item xs={12} md={12} lg={12}>
                                            <Typography variant="body1" style={{ fontFamily: "Roboto, Helvetica, Arial, sans-serif" }}>
                                                <b>{`Fee: `}</b><i>{`$${si.allocatedRates}`}</i>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={6} md={6} lg={6}>
                                    <Grid container spacing={2} style={{ paddingTop: 5 }}>
                                        <Grid item xs={12} md={12} lg={12}>
                                            <Typography variant="h6">Rollover Details</Typography>
                                        </Grid>
                                        <Grid item xs={4} md={4} lg={4} alignItems="center" justifyContent="center">
                                            <span
                                                style={{
                                                    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                                                    fontSize: "1em",

                                                }}
                                            >
                                                Select account:
                                            </span>
                                        </Grid>
                                        <Grid item xs={8} md={8} lg={8}>
                                            <Select
                                                value={category}
                                                onChange={(event) => setCategory(event.target.value)}
                                                style={{ width: "100%" }}
                                            >
                                                <MenuItem value={""} disabled>
                                                    Select an account
                                                </MenuItem>
                                                {accountCodeOptions.map(budgetItem => {
                                                    return <MenuItem value={budgetItem.category}>{budgetItem.category}</MenuItem>
                                                })}
                                            </Select>
                                        </Grid>
                                        <Grid item xs={4} md={4} lg={4} alignItems="center" justifyContent="center">
                                            <span
                                                style={{
                                                    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                                                    fontSize: "1em",
                                                }}
                                            >
                                                Enter Job:
                                            </span>
                                        </Grid>
                                        <Grid item xs={8} md={8} lg={8}>
                                            <TextField
                                                multiline
                                                fullWidth
                                                value={job}
                                                onChange={(e) => {
                                                    setJob(e.target.value);
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={4} md={4} lg={4} alignItems="center" justifyContent="center">
                                            <span
                                                style={{
                                                    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                                                    fontSize: "1em",
                                                }}
                                            >
                                                Enter Fee:
                                            </span>
                                        </Grid>
                                        <Grid item xs={8} md={8} lg={8}>
                                            <TextField
                                                fullWidth
                                                value={fee}
                                                onChange={(e) => {
                                                    if ((e.target.value.includes(".") && /^\-?[0-9]+(?:\.[0-9]{1,2})?$/.test(e.target.value)) ||
                                                        /^\-?[0-9]+(?:\.[0-9]{1,2})?$/.test(e.target.value) || e.target.value === "" || e.target.value === "." ||
                                                        /^[0-9]+.$/.test(e.target.value) || /^.[0-9]{1,2}$/.test(e.target.value)
                                                    ) {
                                                        setFee(e.target.value)
                                                    }
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={4} md={4} lg={4} alignItems="center" justifyContent="center">
                                            <span
                                                style={{
                                                    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                                                    fontSize: "1em",
                                                }}
                                            >
                                                Start Date:
                                            </span>
                                        </Grid>
                                        <Grid item xs={8} md={8} lg={8}>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <DatePicker
                                                    value={startDate}
                                                    format="do LLLL yyyy"
                                                    onChange={setStartDate}
                                                    fullWidth
                                                />
                                            </MuiPickersUtilsProvider>
                                        </Grid>
                                        <Grid item xs={4} md={4} lg={4} alignItems="center" justifyContent="center">
                                            <span
                                                style={{
                                                    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                                                    fontSize: "1em",
                                                }}
                                            >
                                                End Date:
                                            </span>
                                        </Grid>
                                        <Grid item xs={8} md={8} lg={8}>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <DatePicker
                                                    value={endDate}
                                                    format="do LLLL yyyy"
                                                    onChange={setEndDate}
                                                    fullWidth
                                                />
                                            </MuiPickersUtilsProvider>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={12} lg={12}>
                                    {loading ?
                                        <CircularProgress /> : (
                                            <Button
                                                disabled={!allFieldsCompleted()}
                                                variant="contained"
                                                color="primary"
                                                style={{
                                                    float: "right",
                                                    paddingTop: 5
                                                }}
                                                onClick={() => {
                                                    if (fee === "." || fee === "") {
                                                        setFee("0")
                                                    }
                                                    setOpenRolloverConfirmation(true)
                                                }}
                                            >
                                                View Rollover
                                            </Button>
                                        )}
                                </Grid>
                            </Grid>
                        ) : <CircularProgress />}
                    </div>
                )}

            </Paper>
        </Modal >
    )
}

const mapStateToProps = (state, ownProps) => ({
    snackbar: state.snackbar,
});
export default connect(mapStateToProps, {
    editScheduleItem,
    setSnackBarProps,
    cancelInvoice,
})(RolloverPopup);