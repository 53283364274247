import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContentText,
  Button,
  DialogActions,
  DialogContent,
} from "@material-ui/core";
import Axios from "axios";
import { useHistory } from "react-router-dom";

export const CloseProjectDialog = (props) => {
  let {
    showActiveOutputsModal,
    setShowActiveOutputsModal,
    preArchiveCheck,
    setCloseProject,
  } = props;
  return (
    <Dialog
      open={showActiveOutputsModal}
      onClose={() => setShowActiveOutputsModal(false)}
    >
      <DialogTitle style={{ display: "flex", justifyContent: "center" }}>
        Archive Project
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to archive this project? It still has active
          outputs
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ display: "flex", justifyContent: "center" }}>
        <Button
          onClick={() => {
            setCloseProject(false);
            setShowActiveOutputsModal(false);
          }}
          color="primary"
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            preArchiveCheck();
            setShowActiveOutputsModal(false);
          }}
          color="secondary"
          autoFocus
        >
          Archive
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const ClosingUnarchivedProjectDialog = (props) => {
  let {
    showUnarchivedProjectsDialog,
    setShowUnarchivedProjectsDialog,
    setCloseProject,
    archiveProject,
    project,
  } = props;
  const history = useHistory();
  return (
    <Dialog
      open={showUnarchivedProjectsDialog}
      onClose={() => setShowUnarchivedProjectsDialog(false)}
    >
      <DialogTitle style={{ display: "flex", justifyContent: "center" }}>
        Send Surveys?
      </DialogTitle>
      <DialogContent>
        {/* <DialogContentText align="center">
          This project has been previously archived. Do you want to send NPS
          surveys again on re-closing?
        </DialogContentText> */}
        <DialogContentText align="center">
          We no longer send NPS surveys, please confirm to proceed.
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ display: "flex", justifyContent: "center" }}>
        <Button
          onClick={() => {
            setShowUnarchivedProjectsDialog(false);
            Axios.put(`Projects/ArchiveProject/${project.id}`)
              .then((res) => {
                setCloseProject(false);
                history.push("/");
              })
              .catch((e) => console.log(e));
          }}
          color="primary"
        >
          Archive now
        </Button>
        {/* <Button
          onClick={() => {
            archiveProject();
            setShowUnarchivedProjectsDialog(false);
          }}
          color="secondary"
          autoFocus
        >
          Archive and send
        </Button> */}
      </DialogActions>
    </Dialog>
  );
};
