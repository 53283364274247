import { Button, TextField } from "@material-ui/core";
import { Rating } from "@material-ui/lab";
import React, { useState } from "react";
import AddIcon from "@material-ui/icons/Add";
import SaveIcon from "@material-ui/icons/Save";
import axios from "axios";
import { useAuth } from "../../../context/auth/AuthContext";
const PersonRatingsForm = ({ personId, handleSaveCallback, displayForm = true }) => {
  const { user } = useAuth();
  const [shouldAddNew, setShouldAddNew] = useState(displayForm);
  const [rating, setRating] = useState({
    personId: personId,
    rating: 0,
    ratingDate: new Date(),
    comments: "",
    user: user?.email,
  });

  const handleToggleAddNew = () => {
    setShouldAddNew(!shouldAddNew);
  };

  const handleOnChangeRating = (field, value) => {
    setRating({ ...rating, [field]: value });
  };

  const handleSaveRating = () => {
    axios
      .post(`/people/AddNewRating`, rating)
      .then(({ data }) => {
        handleSaveCallback(data);
      })
      .catch(() => {
        console.error("Failed to save ratings");
        handleSaveCallback(null);
      });
  };

  return (
    <div className="person-ratings-form-root">
      {shouldAddNew ? (
        <>
          <div className="person-ratings-form-rating-container">
            <Rating
              value={rating.rating}
              precision={0.5}
              size={"large"}
              onChange={(e, newValue) =>
                handleOnChangeRating("rating", newValue)
              }
            />
          </div>
          <div className="person-ratings-form-comment-container">
            <TextField
              size="small"
              label="Comment"
              value={rating.comments || ""}
              variant="outlined"
              onChange={(e) => handleOnChangeRating("comments", e.target.value)}
            />
            <Button
              size="small"
              variant="outlined"
              className="default-button-grey"
              startIcon={<SaveIcon />}
              onClick={handleSaveRating}
            >
              Save New Review
            </Button>
          </div>
        </>
      ) : (
        <div className="person-ratings-form-add-new-container">
          <Button
            size="small"
            variant="outlined"
            className="default-button-grey"
            startIcon={<AddIcon />}
            onClick={handleToggleAddNew}
          >
            Add New Review
          </Button>
        </div>
      )}
    </div>
  );
};

export default PersonRatingsForm;
