import { isEmpty } from "./GeneralUtils";

export const isOnboardingRequired = (person) => {
  if (!!person.agentFkNavigation) {
    if (!!person?.agentFkNavigation?.companyFkNavigation?.bankAccountNavigation?.accountNumber) {
      if (person.currency !== "NZD") {
        if((person.currency === "AUD" && person?.agentFkNavigation?.companyFkNavigation?.bankAccountNavigation.branchCode) || person.currency !== "AUD"){
          return false;
        }
      } else if (!!person?.agentFkNavigation?.companyFkNavigation?.bankAccountNavigation?.taxNumber) {
        return false;
      }
    }
  } else {
    if (!!person.bankAccountNavigation) {
      if (
        !!person?.bankAccountNavigation?.accountNumber &&
        !!person?.bankAccountNavigation?.accountName
      ) {
        if (person.currency !== "NZD") {
          if((person.currency === "AUD" && person.bankAccountNavigation.branchCode) || person.currency !== "AUD"){
            return false;
          }
        } else {
          if (person.bankAccountNavigation?.invoiceAs === "contractor") {
            if (
              (!!person.bankAccountNavigation?.isTaxRegistered &&
                !!person.bankAccountNavigation?.taxNumber &&
                !!person.bankAccountNavigation?.wtRate) ||
              !!!person.bankAccountNavigation?.isTaxRegistered
            ) {
              return false;
            }
          } else if (person.bankAccountNavigation?.invoiceAs === "company") {
            if (
              (!!person.bankAccountNavigation?.isTaxRegistered &&
                !!person.bankAccountNavigation?.taxNumber) ||
              !!!person.bankAccountNavigation?.isTaxRegistered
            ) {
              return false;
            }
          }
        }
      }
    }
  }

  return true;
};


export const getFirstName = (fullName) => {
  // Check if fullName is null or empty
  if (!fullName || fullName.trim() === "") {
    return ""; // Return an empty string if fullName is null or empty
  }
  if (fullName.includes(" ")) {
    // Split the full name into an array of its individual words
    const nameArray = fullName.split(" ");

    // Extract and return the first word (first name)
    return nameArray[0];
  }
  return fullName;
};
