import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { makeStyles, styled, withStyles } from "@material-ui/core/styles";
import {
    Modal,
    AppBar,
    Toolbar,
    IconButton,
    Grid,
    TextField,
    FormControlLabel,
    Checkbox,
    Button,
    CircularProgress
} from "@material-ui/core";
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

//---------Styling -----------------
const useStyles = makeStyles(theme => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflow: "hidden"
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        height: "300px",
        maxHeight: "350px",
        width: "500px",
        overflowY: "auto"
    }
}));
//-----------------------------------

const CreateCompanyPopUp = (props) => {
    const classes = useStyles();

    const [isSaving, setIsSaving] = useState(false);
    const [newCompany, setNewCompany] = useState({
        name: null,
        address: null,
        website: null,
        about: null,
        gstregistered: 0,
        gstnumber: null
    })
    const { name, address, website, about, gstregistered, gstnumber } = newCompany;

    // -----Functions-----------
    const handleNewCompanyChange = (key) => event => {
        setNewCompany({ ...newCompany, [key]: event.target.value })
    }
    const handleGSTChange = (key) => event => {
        var value = event.target.checked ? 1 : 0;
        setNewCompany({ ...newCompany, [key]: value });
    }
    const clearAllFields = () => {
        setNewCompany({
            name: "",
            address: "",
            website: "",
            about: "",
            gstregistered: 0,
            gstnumber: ""
        });
    }
    const handleSaveCompany = () => {
        setIsSaving(true);
        Axios.post(`Companies/PostCompany`, {
            name: name !== "" ? name : null,
            address: address !== "" ? address : null,
            website: website !== "" ? website : null,
            about: about !== "" ? about : null,
            gstregistered: gstregistered,
            gstnumber: gstnumber !== "" ? gstnumber : null
        })
            .then(res => {
                console.log(res);
                clearAllFields();
                setIsSaving(false);
            })
            .catch(e => {
                console.log(e)
                setIsSaving(false);
            })
    }
    //-----End Of Functions--------
    //-----Use Effects--------------
    useEffect(() => {
        console.log(newCompany);
        console.log(name);
    }, [newCompany])
    useEffect(() => {
        if(gstregistered === 0){
            setNewCompany({ ...newCompany, ['gstnumber']: null })
        }
    }, [gstregistered])
    //-----End of Use Effects-----------

    return (
        <Modal
            aria-labelledby="contractor-modal"
            aria-describedby="contractor-modal"
            open={props.modalState}
            onClose={props.handleModal}
            className={classes.modal}
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
        >
            <div>
                <AppBar position="static" style={{ background: "#217F8B" }}>
                    <Toolbar variant="dense" style={{ paddingRight: 0 }}>
                        <Typography
                            variant="subtitle1"
                            align="center"
                            style={{ flexGrow: 1 }}
                        >
                            Create Company
                        </Typography>
                        <IconButton
                            onClick={props.handleModal}
                            color="inherit"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <div className={classes.paper}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12} lg={12}>
                            <TextField
                                label="Name *"
                                size="small"
                                value={name}
                                variant="outlined"
                                style={{ width: "100%" }}
                                onChange={handleNewCompanyChange("name")}
                            />
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <TextField
                                label="Address"
                                size="small"
                                value={address}
                                variant="outlined"
                                style={{ width: "100%" }}
                                onChange={handleNewCompanyChange("address")}
                            />
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <TextField
                                label="Website"
                                size="small"
                                value={website}
                                variant="outlined"
                                style={{ width: "100%" }}
                                onChange={handleNewCompanyChange("website")}
                            />
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <TextField
                                label="About"
                                size="small"
                                value={about}
                                variant="outlined"
                                multiline={true}
                                style={{ width: "100%" }}
                                onChange={handleNewCompanyChange("about")}
                            />
                        </Grid>
                        <Grid item xs={6} md={6} lg={6}>
                            <FormControlLabel
                                value="top"
                                control={
                                    <Checkbox
                                        checked={gstregistered}
                                        onChange={handleGSTChange('gstregistered')}
                                        value="primary"
                                    />
                                }
                                label="GST Registered"
                                labelPlacement="left"
                            />
                        </Grid>
                        {
                            gstregistered === 1 ?
                                <Grid item xs={6} md={6} lg={6}>
                                    <TextField
                                        label="GST Number"
                                        size="small"
                                        value={gstnumber}
                                        variant="outlined"
                                        style={{ width: "100%" }}
                                        onChange={handleNewCompanyChange("gstnumber")}
                                    />
                                </Grid>
                                : ""
                        }
                        <Grid item xs={12} md={12} lg={12}>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end'
                                }}
                            >
                                {
                                    isSaving ?
                                        <CircularProgress />
                                        :
                                        <Button
                                            variant='contained'
                                            style={{
                                                backgroundColor: "#00B2E2",
                                                color: 'white',
                                                display:
                                                    (name === null || name === "") ?
                                                        'none'
                                                        : ''
                                            }}
                                            onClick={() => handleSaveCompany()}

                                        >
                                            Create
                                        </Button>
                                }
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </Modal>
    )
}

export default CreateCompanyPopUp;