import React, { useEffect, useState } from "react";
import RwtTab from "./RwtTab";
import "./rwtReport.scss";
import { Container, Divider, Typography } from "@material-ui/core";
import RwtTable from "./RwtTable";
import Snacky from "../../Shared/Snacky";
import axios from "axios";
import Header from "../../Header";
const RwtReport = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [magnesiumRecords, setMagnesiumRecords] = useState([]);
  const [tsRecords, setTsRecords] = useState([]);
  const [snackBarProps, setSnackBarProps] = useState({
    open: false,
    severity: "success",
    text: "",
  });

  useEffect(() => {
    searchRwtRecords();
  }, []);

  const searchRwtRecords = () => {
    setIsLoading(true);
    axios
      .get(`/rwtReport/SearchRwtRecords`)
      .then(({ data }) => {
        setTsRecords(data.filter((r) => r.organisationId > 1));
        setMagnesiumRecords(data.filter((r) => r.organisationId === 1));
      })
      .catch((err) => {
        console.log(err);
        setSnackBarProps({
          open: true,
          severity: "warning",
          text: "Unable to get report details.",
        });
      })
      .finally(() => setIsLoading(false));
  };

  const updateRecord = (record) => {
    if (record.organisationId > 1) {
      var copyOfTsRecords = [...tsRecords];
      setTsRecords(copyOfTsRecords.map(r => {
        if (r.id === record.id) {
          return record;
        } else {
          return r;
        }
      }));
    } else if (record.organisationId === 1) {
      var copyOfMagnesiumRecords = [...magnesiumRecords];
      setMagnesiumRecords(copyOfMagnesiumRecords.map(r => {
        if (r.id === record.id) {
          return record;
        } else {
          return r;
        }
      }));
    }
  }

  return (
    <div className="rwt-report-root">
      <Snacky snackprops={snackBarProps} setSnackBarProps={setSnackBarProps} />
      <Container maxWidth="lg">
        <div className="rwt-report-table-container">
          <div className="rwt-report-table-wrapper">
            <div className="rwt-report-title-container">
              <Typography variant="h5">Thompson Spencer</Typography>
              <Divider />
            </div>
            <RwtTable isLoading={isLoading} records={tsRecords} updateRecord={updateRecord}/>
          </div>
          <div className="rwt-report-table-wrapper">
            <div className="rwt-report-title-container">
              <Typography variant="h5">Magnesium</Typography>
              <Divider />
            </div>
            <RwtTable isLoading={isLoading} records={magnesiumRecords} updateRecord={updateRecord}/>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default RwtReport;
