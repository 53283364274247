import React, { useEffect, useState, Fragment } from "react";
import axios from "axios";
import "../../../custom.scss";
import {
  Button,
  Select,
  MenuItem,
  CircularProgress,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  FormHelperText,
} from "@material-ui/core";
import Axios from "axios";

const Script = (props) => {
  const [showLoader, setShowLoader] = useState(false);
  const [fetchScripts, setfetchScripts] = useState(true);
  const [selectedScript, setSelectedScript] = useState(null);
  const [allScripts, setAllScripts] = useState([]);

  const [localScriptName, setLocalScriptName] = useState(null);
  const [showChangeName, setShowChangeName] = useState(false);
  const [showSaveName, setShowSaveName] = useState(false);
  const [showDeleteScript, setShowDeleteScript] = useState(false);
  const [addScriptName, setAddScriptName] = useState(false);
  const [newScriptName, setNewScriptName] = useState();
  const [createdNew, setCreatedNew] = useState(false);

  const [linkToJobFolder, setLinkToJobFolder] = useState("");
  const [jobFolderLinkLoaded, setJobFolderLinkLoaded] = useState(false);

  const addScript = (name) => {
    setShowLoader(true);
    axios
      .put(`Projects/CreateExtraScript/${props.projectId}/${name}`)
      .then((res) => {
        console.log("Successfully created the script");
        setfetchScripts(true);
        setCreatedNew(true);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setShowLoader(false);
        setAddScriptName(false);
        setNewScriptName();
      });
  };

  const updateScriptName = () => {
    setShowLoader(true);
    Axios.put(`ProjectScripts/PutProjectScripts/${selectedScript.id}`, {
      id: selectedScript.id,
      name: localScriptName,
      projectId: selectedScript.projectId,
      driveId: selectedScript.driveId,
      url: selectedScript.url,
    })
      .then((res) => {
        console.log(res);
        setfetchScripts(true);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setShowLoader(false);
      });
  };

  const deleteScript = () => {
    setShowLoader(true);
    Axios.delete(`ProjectScripts/DeleteProjectScripts/${selectedScript.id}`)
      .then((res) => {
        console.log(res);
        setfetchScripts(true);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setShowLoader(false);
      });
  };

  useEffect(() => {
    if (fetchScripts) {
      setfetchScripts(false);
      axios
        .get(`Projects/GetAllProjectScripts/${props.projectId}`)
        .then((res) => {
          console.log(res);
          setAllScripts(res.data);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [fetchScripts]);

  useEffect(() => {
    if (createdNew) {
      setSelectedScript(allScripts[allScripts.length - 1]);
      setCreatedNew(false);
    } else {
      setSelectedScript(allScripts[0]);
    }
  }, [allScripts]);

  useEffect(() => {
    console.log("selectedScript");
    console.log(selectedScript);
    if (selectedScript !== null && selectedScript !== undefined) {
      if (selectedScript.name !== null) {
        //Not the main script
        setShowChangeName(true);
        setShowSaveName(true);
        setShowDeleteScript(true);
        setLocalScriptName(selectedScript.name);
      } else {
        setShowChangeName(false);
        setShowSaveName(false);
        setShowDeleteScript(false);
        setLocalScriptName("Main Script");
      }
    }
  }, [selectedScript]);

  useEffect(() => {
    axios.get(`Script/GetJobFolderLink/${props.projectId}`)
      .then(res => {
        setLinkToJobFolder(res.data);
      }).catch(e => {
        console.log(e)
      }).finally(() => {
        setJobFolderLinkLoaded(true);
      })
  }, []);

  if (
    allScripts === null ||
    allScripts.length === 0 ||
    selectedScript === undefined
  ) {
    return <CircularProgress />;
  }
  return (
    <React.Fragment>
      {props.openInNewTabButton(selectedScript.url)}
      {addScriptName && !showLoader ? (
        <Fragment>
          <TextField
            variant="outlined"
            value={newScriptName}
            placeholder={"Enter New Script Name"}
            onChange={(event) => setNewScriptName(event.target.value)}
            size="small"
            autoFocus
          />
          <Button
            variant="contained" size="small"
            className="new-script-button-cancel"
            onClick={() => setAddScriptName(false)}
            disabled={props.project.isArchived === 1}
          >
            Cancel
          </Button>
          <Button
            variant="contained" size="small"
            color="primary"
            className="new-script-button-create"
            onClick={() => addScript(newScriptName)}
            disabled={props.project.isArchived === 1}
          >
            Create
          </Button>
        </Fragment>
      ) : (
        <Button
          variant="contained"
          color="primary"
          className={`margin-sides-10 ${showLoader || props.project.isArchived === 1 ? "" : "default-button" }`}
          // onClick={() => addScript()}
          onClick={() => setAddScriptName(true)}
          disabled={showLoader || props.project.isArchived === 1}
          size="small"
        >
          Create additional script
        </Button>
      )}
      <FormControl>
        <Select
          className={addScriptName && !showLoader ? "hide" : "margin-sides-10"}
          onChange={(event) => {
            setSelectedScript(event.target.value);
          }}
          value="Change Script"
          disabled={showLoader}
        >
          <MenuItem key={100} value="Change Script" disabled>
            Change Script
          </MenuItem>
          {allScripts.map((script, index) => {
            return (
              <MenuItem key={index} value={script}>
                {script.name}
              </MenuItem>
            );
          })}
        </Select>
        <FormHelperText style={addScriptName && !showLoader ? { visibility: "hidden" } : {}}>
          Script Selector
        </FormHelperText>
      </FormControl>
      {selectedScript !== null ? (
        <TextField
          style={addScriptName && !showLoader ? { visibility: "hidden", marginRight: "10px" } : {}}
          // value={selectedScript.name === null ? "Main Script" : selectedScript.name}
          value={localScriptName}
          onChange={(event) => {
            setLocalScriptName(event.target.value);
          }}
          disabled={!showChangeName || showLoader || props.project.isArchived === 1}//|| localScriptName === "Main Script"
          helperText="Current Script"
        />
      ) : (
        <React.Fragment></React.Fragment>
      )}
      <Button
        variant="contained"
        className={`${showSaveName ? "margin-sides-10" : "hide"} ${showLoader || props.project.isArchived === 1 ? "" : "default-button" }`}
        disabled={showLoader || props.project.isArchived === 1}
        onClick={() => updateScriptName()}
        size="small"
      >
        Save Name
      </Button>
      <Button
        variant="contained"
        className={`${showDeleteScript ? "margin-sides-10" : "hide"} ${showLoader || props.project.isArchived === 1 ? "" : "default-button" }`}
        disabled={showLoader || props.project.isArchived === 1}
        // className="margin-sides-10"
        onClick={() => deleteScript()}
        size="small"
      >
        Delete Script
      </Button>
      {jobFolderLinkLoaded ? (
        <Button
          variant="contained"
          color="primary"
          disabled={showLoader || props.project.isArchived === 1}
          className={"float-right"}
          onClick={() => window.open(linkToJobFolder)}
          size="small"
          style={linkToJobFolder === "" ? { visibility: "hidden" } : {}}
        >
          Go to Job folder
        </Button>
      ) : <CircularProgress className={"float-right"} />}
      {showLoader ? <CircularProgress /> : <React.Fragment></React.Fragment>}
      <div className="responsive-container-script">
        {!showLoader && (
          <iframe
            className="responsive-iframe"
            // src={selectedScript.url}
            src={props.project.isArchived === 1 ?
              `https://docs.google.com/document/d/${selectedScript.driveId}/preview`
              : `https://docs.google.com/document/d/${selectedScript.driveId}/edit`
            }
          ></iframe>
        )}
      </div>
    </React.Fragment>
  );
};

export default Script;
