import React, { useEffect, useState, useRef } from "react";
import {
  AppBar,
  Typography,
  Collapse,
  Toolbar,
  Paper,
  Button,
  Tooltip,
  Divider,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { Droppable, Draggable } from "react-beautiful-dnd";
import { connect } from "react-redux";
import { Output } from "./Output";
import { withStyles } from "@material-ui/core/styles";
import "./Home.css";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { getFirstDeadline } from "../../utils/OutputsHelpers";
import { resetOrphans, resetProjectLoading } from "../../actions/projects";
import { resetOutputsLoading } from "../../actions/outputs";
import { format } from "date-fns";
import { enAU } from "date-fns/locale";
import daysLeft from "../../utils/workingDaysLeft";
import negDaysLeft from "../../utils/workingDaysLeftNegatives";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: 12,
    border: "1px solid #dadde9",
  },
}))(Tooltip);

export const ProjectCard = (props) => {
  let { resetOrphans, resetOutputsLoading, resetProjectLoading } = props;
  const [showOutputs, setshowOutputs] = useState(null);
  const [deadline, setDeadline] = useState();
  const [tMinus, setTMinus] = useState();
  const [deltaApproval, setDeltaApproval] = useState(null);
  const [deltaDue, setDeltaDue] = useState(null);
  const [projID, setProjID] = useState(
    typeof props.project.id === "number"
      ? props.project.id
      : props.project.id.split("-")[2]
  );

  const [url, setUrl] = useState(
    "/project/home/" +
    (props.project.id.toString().split("-").length == 1
      ? props.project.id
      : props.project.id.split("-")[2]) +
    "/0"
  );
  useEffect(() => {
    if (props.project.outputs.length > 0) {
      var firstDeadline = getFirstDeadline(props.project.outputs);
      if (firstDeadline != null) {
        setDeadline(
          format(
            new Date(getFirstDeadline(props.project.outputs)),
            "iiii, dd/MM",
            { locale: enAU }
          )
        );
        setTMinus(daysLeft(new Date(getFirstDeadline(props.project.outputs))));
      } else {
        setDeadline("N/A");
        setTMinus(firstDeadline);
      }
    }
    setDeltaApproval(negDaysLeft(new Date(props.project.approvalDate), "A"));
    setDeltaDue(negDaysLeft(new Date(props.project.originalDueDate), "D"));
  }, [props.project.outputs]);

  useEffect(() => {
    setshowOutputs(null);
  }, [props.openAll]);

  const toggleCardOutputs = () => {
    if (showOutputs === null) {
      setshowOutputs(false);
    } else if (showOutputs === false) {
      setshowOutputs(true);
    } else if (showOutputs === true) {
      setshowOutputs(false);
    }
  };

  return (
    <Draggable
      draggableId={"Proj-" + props.project.id.toString()}
      index={props.index}
    >
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <Droppable droppableId={props.project.id.toString()}>
            {(provided) => (
              <Paper
                className={
                  props.showExtraInfo ? "droppable-info-open" : "droppable"
                }
                elevation={6}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                <HtmlTooltip
                  title={
                    <React.Fragment>
                      <em>{props.project.projectName}</em>{" "}
                      <p>{props.project.projectTag}</p>{" "}
                      <u>{props.project.customerName}</u>{" "}
                    </React.Fragment>
                  }
                >
                  <AppBar
                    position="static"
                    className="project-appbar"
                    onMouseOver={() =>
                      props.setActiveProject(
                        typeof props.project.id === "number"
                          ? props.project.id
                          : props.project.id.split("-")[2]
                      )
                    }
                    onMouseOut={() => props.setActiveProject(null)}
                    style={props.lockProductionCore && props.project.daysOverdue > 0 && props.project.project.isInternalProject !== 1 ?//
                      ({ backgroundColor: "red" })
                      : (props.activeProject == projID ? { backgroundColor: "#FF9E00" } : {})
                    }
                  // style={(props.activeProject == projID ? { backgroundColor: "#FF9E00" } : {})}
                  >
                    <div
                      className={
                        props.showExtraInfo
                          ? "extra-info-wrapper-show"
                          : "extra-info-wrapper-hide"
                      }
                    >
                      <Typography
                        className="extra-info-typography-left"
                        style={{ fontSize: ".7rem" }}
                        align="center"
                      >
                        {"Approved: " +
                          format(
                            new Date(props.project.approvalDate),
                            "dd/MM",
                            { locale: enAU }
                          )}
                      </Typography>
                      <div className="deltaA">
                        <Typography style={{ fontSize: ".7rem" }}>
                          {deltaApproval}
                        </Typography>
                      </div>
                      <Divider
                        orientation="vertical"
                        className="extra-info-divider"
                      />
                      <Typography
                        className="extra-info-typography-right"
                        style={{ fontSize: ".7rem" }}
                        align="center"
                      >
                        {"Originally due: " +
                          format(
                            new Date(props.project.originalDueDate),
                            "dd/MM",
                            { locale: enAU }
                          )}
                      </Typography>
                      <div
                        className={deltaDue < 0 ? "deltaD-green" : "deltaD-red"}
                      >
                        <Typography style={{ fontSize: ".7rem" }}>
                          {deltaDue}
                        </Typography>
                      </div>
                    </div>
                    <Toolbar
                      variant="dense"
                      className="project-toolbar"
                      style={{ justifyContent: "space-between" }}
                    >
                      {showOutputs || props.openAll ? (
                        <ArrowDropDownIcon
                          onClick={(e) => { toggleCardOutputs() }}
                          style={
                            deadline != null || deadline == undefined
                              ? { cursor: "pointer" }
                              : { visibility: "hidden" }
                          }
                        />
                      ) : (
                        <ArrowRightIcon
                          onClick={toggleCardOutputs}
                          style={
                            deadline != null || deadline == undefined
                              ? { cursor: "pointer" }
                              : { visibility: "hidden" }
                          }
                        />
                      )}
                      <Typography
                        className="typography-project-appbar"
                        align="left"
                      >
                        {`${props.project.projectTag}: ${props.project.customerName} - ${props.project.projectName}`}
                      </Typography>
                      {props.lockProductionCore && props.project.daysOverdue === 0 && props.project.project.isInternalProject !== 1 ? (// 
                        <Button
                          className="link-icon-button"
                          onClick={() => props.displayOverdueWarning()}
                        >
                          <KeyboardArrowRightIcon style={{ color: "white" }} />
                        </Button>
                      ) : (
                        <Link to={{ pathname: url }}>
                          <Button
                            className="link-icon-button"
                            onClick={() => {
                              resetOutputsLoading();
                              resetProjectLoading();
                              resetOrphans();
                            }}
                          >
                            <KeyboardArrowRightIcon style={{ color: "white" }} />
                          </Button>
                        </Link>
                      )}
                    </Toolbar>
                  </AppBar>
                </HtmlTooltip>
                <Collapse
                  in={showOutputs === null ? props.openAll : showOutputs}
                >
                  {props.project.outputs.map((output, index) => {
                    return (
                      <Output
                        key={output.id}
                        output={output}
                        index={index}
                        project={props.project}
                      />
                    );
                  })}
                  {provided.placeholder}
                </Collapse>
                <div>
                  {props.project.outputs.length > 0 ? (
                    <div className="project-card-footer">
                      <div
                        className={
                          tMinus > 5 || tMinus === undefined
                            ? "deadline-green"
                            : "deadline-red"
                        }
                        style={{
                          display: `${tMinus === undefined ? "none" : ""}`,
                        }}
                      >
                        <Typography className="project-card-footer-typography">
                          {deadline}
                        </Typography>
                      </div>
                      {tMinus === undefined ? (
                        <div
                          style={
                            tMinus === undefined
                              ? {
                                display: "flex",
                                width: "-webkit-fill-available",
                              }
                              : { display: "flex" }
                          }
                        >
                          <div className="all-delivered">
                            <Typography className="project-card-footer-typography">
                              {"All Delivered"}
                            </Typography>
                          </div>
                          <CheckCircleIcon className="all-delivered-check" />
                        </div>
                      ) : (
                        <div
                          className={
                            tMinus > 5 || tMinus === undefined
                              ? "tMinus-green"
                              : "tMinus-red"
                          }
                        >
                          <Typography className="project-card-footer-typography">
                            {tMinus != null ? `T- ${tMinus}` : ""}
                          </Typography>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
                {provided.placeholder}
              </Paper>
            )}
          </Droppable>
        </div>
      )
      }
    </Draggable >
  );
};

const mapStateToProps = (state, ownProps) => ({});

export default connect(mapStateToProps, {
  resetProjectLoading,
  resetOutputsLoading,
  resetOrphans,
})(ProjectCard);
