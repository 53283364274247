import React, { useEffect, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Modal,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Grid,
  TextField,
  Button,
  Tooltip,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { format } from "date-fns";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxHeight: "600px",
    width: "1000px",
    overflowY: "auto",
  },
  customWidth: {
    maxWidth: 500,
  },
}));

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    maxWidth: 500,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

function DeliveredOutputPopup(props) {
  const classes = useStyles();
  return (
    <div>
      <Modal
        aria-labelledby="delivered-output-modal"
        aria-describedby="delivered-output-modal"
        open={props.modalState}
        onClose={props.handleModal}
        className={classes.modal}
      >
        <div>
          <AppBar position="static" style={{ background: "#217F8B" }}>
            <Toolbar variant="dense" style={{ paddingRight: 0 }}>
              <Typography
                variant="subtitle1"
                align="center"
                style={{ flexGrow: 1 }}
              >
                Delivered Output Details
              </Typography>
              <IconButton
                aria-haspopup="true"
                onClick={props.handleModal}
                color="inherit"
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <div className={classes.paper}>
            <Grid container spacing={2}>
              {/* Row 1 */}
              <Grid item xs={4} md={4} lg={4}>
                <TextField
                  label="Key Number"
                  variant="outlined"
                  style={{ width: "100%" }}
                  value={props.outputDetails.keyNumberFkNavigation !== null && props.outputDetails.keyNumberFkNavigation !== undefined
                    ? props.outputDetails.keyNumberFkNavigation.keynumber : ""}
                />
              </Grid>
              <Grid item xs={4} md={4} lg={4}>
                <TextField
                  label="Campaign Name"
                  variant="outlined"
                  style={{ width: "100%" }}
                  value={props.outputDetails.campaignName}
                />
              </Grid>
              <Grid item xs={4} md={4} lg={4}>
                <TextField
                  label="Creataive Name"
                  variant="outlined"
                  style={{ width: "100%" }}
                  value={props.outputDetails.creativeName}
                />
              </Grid>
              {/* Row 2 */}
              <Grid item xs={4} md={4} lg={4}>
                <TextField
                  label="Start Date"
                  variant="outlined"
                  style={{ width: "100%" }}
                  value={
                    props.outputDetails.startDate != null &&
                      props.outputDetails.startDate != ""
                      ? format(
                        new Date(props.outputDetails.startDate),
                        "dd/MM/yyyy"
                      )
                      : "Not available"
                  }
                />
              </Grid>
              <Grid item xs={4} md={4} lg={4}>
                <TextField
                  label="Creative Deadline"
                  variant="outlined"
                  style={{ width: "100%" }}
                  value={
                    props.outputDetails.startDate != null &&
                      props.outputDetails.startDate != ""
                      ? format(
                        new Date(props.outputDetails.startDate),
                        "dd/MM/yyyy"
                      )
                      : "Not available"
                  }
                />
              </Grid>
              <Grid item xs={4} md={4} lg={4}>
                <TextField
                  label="Status"
                  variant="outlined"
                  style={{ width: "100%" }}
                  value={props.outputDetails.status}
                />
              </Grid>
              {/* Row 3 */}
              <Grid item xs={6} md={6} lg={6}>
                <TextField
                  label="Target Audience"
                  variant="outlined"
                  style={{ width: "100%" }}
                  value={props.outputDetails.targetAudience}
                />
              </Grid>
              <Grid item xs={6} md={6} lg={6}>
                <TextField
                  label="Client Email"
                  variant="outlined"
                  style={{ width: "100%" }}
                  value={props.outputDetails.clientEmail}
                />
              </Grid>
              {/* Row 4 */}
              <Grid item xs={6} md={6} lg={6}>
                <TextField
                  label="Destination"
                  variant="outlined"
                  style={{ width: "100%" }}
                  value={
                    props.outputDetails.platform != undefined
                      ? props.formatStringifiedStringArray(props.outputDetails.platform)
                      : "Not available"
                  }
                  multiline={true}
                />
              </Grid>
              <Grid item xs={6} md={6} lg={6}>
                <TextField
                  label="Publisher"
                  variant="outlined"
                  style={{ width: "100%" }}
                  value={
                    props.outputDetails.publisher != undefined
                      ? props.formatStringifiedStringArray(props.outputDetails.publisher)
                      : "Not available"
                  }
                  multiline={true}
                />
              </Grid>
              {/* Row 5 */}
              <Grid item xs={2} md={2} lg={2}>
                <TextField
                  label="Duration"
                  variant="outlined"
                  style={{ width: "100%" }}
                  value={props.outputDetails.duration}
                />
              </Grid>
              <Grid item xs={2} md={2} lg={2}>
                <TextField
                  label="Subtitles"
                  variant="outlined"
                  style={{ width: "100%" }}
                  value={props.outputDetails.subtitles ? "Yes" : "No"}
                />
              </Grid>
              <Grid item xs={5} md={5} lg={5}>
                <TextField
                  label="Output Code"
                  variant="outlined"
                  style={{ width: "100%" }}
                  value={props.outputDetails.outputCodes}
                />
              </Grid>
              <Grid item xs={3} md={3} lg={3}>
                <TextField
                  label="Geolocation"
                  variant="outlined"
                  style={{ width: "100%" }}
                  value={props.outputDetails.geolocations}
                />
              </Grid>
              {/* Row 6 */}
              <Grid item xs={12} md={12} lg={12}>
                <TextField
                  label="Notes"
                  variant="outlined"
                  style={{ width: "100%" }}
                  value={props.outputDetails["notes"]}
                  disabled={
                    props.outputDetails.notes === undefined ? true : false
                  }
                  multiline={true}
                />
              </Grid>
              {/* Row 7 */}
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={(event) => {
                    event.stopPropagation();
                    window.open(
                      props.outputDetails.creativeLink,
                      "_blank"
                    );
                  }}
                >
                  View Video
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default DeliveredOutputPopup;
