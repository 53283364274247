import React, { useEffect } from "react";
import {
    Close as CloseIcon,
} from "@material-ui/icons";
import {
    AppBar,
    Button,
    Dialog,
    DialogTitle,
    IconButton,
    Modal,
    Toolbar,
    Typography,
    Grid
} from "@material-ui/core"
import FilePreview from "../FilePreview";
import "./GeneralDialog.scss";

const GeneralVideoView = ({
    open,
    handleClose,
    fileUrl
}) => {

    return (
        <Dialog open={open} onClose={handleClose} maxWidth={"lg"}>
            {/* <AppBar position="static" style={{ background: "#217F8B", width: "100%" }}>
                <Toolbar variant="dense" style={{ paddingRight: 0 }}>
                    <Typography
                        variant="subtitle1"
                        align="center"
                        style={{ flexGrow: 1 }}
                    >
                        Video Preview
                    </Typography>
                    <IconButton onClick={handleClose} color="inherit">
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar> */}
            <div className="general-video-container">
                <FilePreview fileUrl={fileUrl} showControls={true} containerClassName="general-video-file-container" />
            </div>
        </Dialog>
    )
}

export default GeneralVideoView;