import "./custom.scss";
import "./styles/base.scss";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import store from "./store/store";
// import registerServiceWorker from './registerServiceWorker';
// import unregister from "./registerServiceWorker";
import { unregister as unregisterServiceWorker } from "./registerServiceWorker";
import { AuthContextProvider } from "./context/auth/AuthContext";
import { UserContextProvider } from "./context/user/UserContext";
import "../src/assets/fonts/MierA-Bold.woff2";
import "../src/assets/fonts/MierA-Regular.woff";
import { ClientContextProvider } from "./context/client/ClientContext";
import { DataContextProvider } from "./context/data/DataContext";
import { ThemeContextProvider } from "./context/theme/ThemeContext";

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const rootElement = document.getElementById("root");

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter basename={baseUrl}>
      <ThemeContextProvider>
        <AuthContextProvider>
          <UserContextProvider>
            <ClientContextProvider>
              <DataContextProvider>
                <App />
              </DataContextProvider>
            </ClientContextProvider>
          </UserContextProvider>
        </AuthContextProvider>
      </ThemeContextProvider>
    </BrowserRouter>
  </Provider>,
  rootElement
);

// registerServiceWorker();
// unregister();
unregisterServiceWorker();
