import React from "react";
import { currencyFormat } from "../../../utils/GeneralUtils";
import { Button, Card, Divider, Typography } from "@material-ui/core";
import {
  Launch as LaunchIcon
} from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import GenerateAuditSpreadsheetButton from "./GenerateAuditSpreadsheetButton";

const PayableFigures = ({ payables }) => {
  const history = useHistory();
  const calculatePayableTotal = (field, _payables) => {
    return _payables.reduce((a, b) => a + b[field], 0);
  };

  return (
    <div className="reconciliation-centre-cards-container ">
      <Card className="reconciliation-centre-cards-props reconciliation-payable-row-record-type-MEDIA-default">
        <Typography variant="button">
          {currencyFormat(
            calculatePayableTotal(
              "total",
              payables.filter((p) => p.recordType === "MEDIA")
            )
          )}
        </Typography>
        <Divider />
        <Typography variant="caption">{`${payables.filter((p) => p.recordType === "MEDIA")?.length ?? 0
          } Bills To Reconcilie`}</Typography>
      </Card>
      <Card className="reconciliation-centre-cards-props reconciliation-payable-row-record-type-RECEIPT">
        <Typography variant="button">
          {currencyFormat(
            calculatePayableTotal(
              "total",
              payables.filter((p) => p.recordType === "RECEIPT")
            )
          )}
        </Typography>
        <Divider />
        <Typography variant="caption">{`${payables.filter((p) => p.recordType === "RECEIPT")?.length ?? 0
          } Receipts`}</Typography>
      </Card>
      <div className="reconciliation-payables-rwt-container" style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
        {/* <GenerateAuditSpreadsheetButton /> */}
        <Button
          onClick={(e) => {
            history.push("/Finance/RwtReport");
          }}
          startIcon={<LaunchIcon />}
          variant="contained"
          className="default-button"
        >
          Go to RWT
        </Button>
      </div>
    </div>
  );
};

export default PayableFigures;
