import React from "react";
import Axios from "axios";
import { Switch, IconButton, makeStyles } from "@material-ui/core";
import { DescriptionOutlined } from "@material-ui/icons";
import { useUserContext } from "../../../../context/user/UserContext";
import { useAuth } from "../../../../context/auth/AuthContext";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  alignRight: {
    marginLeft: "auto",
    marginRight: 0,
  },
  removeMarginPadding: {
    padding: "0px 0px 0px 0px",
    margin: "0px 0px 0px 0px",
  },
  switchBase: {
    color: "primary",
    "&$checked": {
      color: "primary",
    },
    "&$checked + $track": {
      backgroundColor: "primary",
    },
  },
  checked: {
    color: "#3f51b5",
  },
  track: {
    color: "#3f51b5",
    backgroundColor: "#3f51b5",
  },
  thumb: {
    color: "#3f51b5",
  },
  disabled: {
    color: "#dbdbdb",
  },
}));
const styles = {
  icon: {
    width: 25,
    height: 25,
  },
};
const TalentSwitchPanel = (props) => {
  const { user } = useAuth();
  const classes = useStyles();
  let { scheduleItem } = props;

  const removePreviousContract = () => {
    Axios.patch(`TalentContracts/ClearContractDriveId/${scheduleItem.id}`)
    .then(res => {
      console.log("Cleared Contract")
    })
    .catch(e => {
      console.log("Error clearing contract")
    })
  }

  const handleSwitch = () => {
    if (scheduleItem.talentContractType == "Restricted") {
      props.editScheduleItem(
        scheduleItem,
        "talent",
        "talentContractType",
        "BuyOut",
        1, user?.email
      );
      props.editScheduleItem(
        scheduleItem,
        "talent",
        "talentContractStatus",
        "Complete",
        1, user?.email
      );
      props.editScheduleItem(scheduleItem, "talent", "contractStatus", "Send");
      // props.editScheduleItem(scheduleItem, "talent", "isAucklandStandardContract", 0);
    } else {
      props.editScheduleItem(
        scheduleItem,
        "talent",
        "talentContractType",
        "Restricted",
        1, user?.email
      );
      props.editScheduleItem(
        scheduleItem,
        "talent",
        "talentContractStatus",
        "Incomplete",
        1, user?.email
      );
      props.editScheduleItem(
        scheduleItem,
        "talent",
        "contractStatus",
        "Disabled",
        1, user?.email
      );
      props.editScheduleItem(scheduleItem, "talent", "isAucklandStandardContract", 0);
      removePreviousContract();
    }
  };
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        justifyContent: "center",
        visibility:
          scheduleItem.category.toLowerCase().trim() !== "unpaid"
            ? "visible"
            : "hidden",
      }}
    >
      <div
        style={
          scheduleItem.talentContractType === "BuyOut"
            ? {
              borderBottom: "solid",
              borderColor: "#3f51b5",
              padding: "2px",
              fontSize: "10px",
              margin: "auto",
            }
            : {
              padding: "2px",
              fontSize: "10px",
              margin: "auto",
            }
        }
      >
        Buy Out
      </div>
      {scheduleItem.contractStatus !== "Send" &&
        scheduleItem.contractStatus !== "Disabled" ? (
          <Switch
            disabled
            checked={scheduleItem.talentContractType === "Restricted"}
          />
        ) : (
          <Switch
            classes={{
              root: classes.root,
              switchBase: classes.switchBase,
              thumb: classes.thumb,
              track: classes.track,
              checked: classes.checked,
              disabled: classes.disabled,
            }}
            color="primary"
            name="talentSwitch"
            checked={scheduleItem.talentContractType === "Restricted"}
            inputProps={{
              "aria-label": "primary checkbox",
            }}
            onClick={() => {
              handleSwitch();
            }}
          />
        )}

      <div
        style={
          scheduleItem.talentContractType === "Restricted"
            ? {
              borderBottom: "solid",
              borderColor: "#3f51b5",
              padding: "2px",
              fontSize: "10px",
              margin: "auto",
            }
            : {
              padding: "2px",
              fontSize: "10px",
              margin: "auto",
            }
        }
      >
        Restricted
      </div>
      {scheduleItem.contractStatus !== "Send" &&
        scheduleItem.contractStatus !== "Disabled" ? (
          <IconButton
            style={{
              width: 30,
              height: 30,
              backgroundColor:
                scheduleItem.talentContractStatus === "Complete"
                  ? "#4dfa59"
                  : "#c8c8c8",
              color: "white",
              margin: "auto",
              marginLeft: 10,
              marginRight: 10,
              padding: 0,
              visibility:
                scheduleItem.category.toLowerCase().trim() !== "unpaid"
                  ? scheduleItem.talentContractType === "Restricted" &&
                    scheduleItem.category.toLowerCase().trim() !== "unpaid"
                    ? "visible"
                    : "hidden"
                  : "hidden",
            }}
            onClick={() => {
              props.setActiveScheduleItem(scheduleItem);
              if (scheduleItem.isAucklandStandardContract == 1 && scheduleItem.talentContractStatus === "Complete") {
                props.handleAucklandStandardModal();
              }
              else {
                props.handleTalentModal();
              }
            }}
          >
            <DescriptionOutlined style={styles.icon} />
          </IconButton>
        ) : (
          <IconButton
            style={{
              width: 30,
              height: 30,
              backgroundColor:
                scheduleItem.talentContractStatus === "Complete"
                  ? "#4dfa59"
                  : "#c8c8c8",
              color: "white",
              margin: "auto",
              marginLeft: 10,
              marginRight: 10,
              padding: 0,
              visibility:
                scheduleItem.category.toLowerCase().trim() !== "unpaid"
                  ? scheduleItem.talentContractType === "Restricted" &&
                    scheduleItem.category.toLowerCase().trim() !== "unpaid"
                    ? "visible"
                    : "hidden"
                  : "hidden",
            }}
            onClick={() => {
              props.setActiveScheduleItem(scheduleItem);
              if (scheduleItem.isAucklandStandardContract == 1 && scheduleItem.talentContractStatus === "Complete") {
                props.handleAucklandStandardModal();
              }
              else {
                props.handleTalentModal();
              }
            }}
          >
            <DescriptionOutlined style={styles.icon} />
          </IconButton>
        )}
    </div>
  );
};

export default TalentSwitchPanel;
