import React, { useEffect, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import {
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    Toolbar,
    IconButton,
    AppBar
} from "@material-ui/core";
import {
    Close as CloseIcon
} from "@material-ui/icons"

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const ReorderPopUp = (props) => {
    const classes = useStyles();

    return (
        <Dialog
            open={props.modalState}
            onClose={props.handleClose}
            scroll={'paper'}
            disableBackdropClick
            style={{ zIndex: 99999999999999 }}
        >
            <AppBar position="static" style={{ background: "#217F8B" }}>
                <Toolbar variant="dense">
                    <Typography
                        variant="subtitle1"
                        align="center"
                        style={{ flexGrow: 1 }}
                    >
                        Catering has already been ordered
                    </Typography>
                    <IconButton
                        aria-haspopup="true"
                        onClick={props.handleClose}
                        color="inherit"
                        size="small"
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            {/* <DialogTitle>Catering has already been ordered</DialogTitle> */}
            <DialogContent>
                <DialogContentText>
                    <Typography variant="body1">
                        Would you like to reorder the current catering order for the new shoot date or cancel the order?
                    </Typography>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                    <Button onClick={props.cancelOrder} color="primary" size="small" variant="contained">
                        Cancel Order
                    </Button>
                    <Button
                        onClick={props.rescheduleOrder}
                        color="primary"
                        size="small"
                        variant="contained"
                    >
                        Reschedule Order
                    </Button>
                </div>
            </DialogActions>
        </Dialog>
    );
}

export default ReorderPopUp;
