import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { withStyles, styled, makeStyles } from "@material-ui/core/styles";
import { format } from "date-fns";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Collapse,
  Grid,
  Tooltip,
} from "@material-ui/core";
import {
  UnfoldMore as UnfoldMoreIcon,
  ExpandMore as ExpandMoreIcon,
  ChevronRight as ChevronRightIcon,
  Description as DescriptionIcon,
} from "@material-ui/icons";
import ContractorPopUp from "../../Project/ProjectOutputs/PopUps/ContractorPopUp";
import Loader from "../../Loader";
import DocumentViewer from "./DocumentViewer";

const StyledPaper = styled(Paper)({
  width: "100%",
});
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: "#9E9E9E",
    textTransform: "uppercase",
    fontWeight: "bold",
  },
  body: {
    fontSize: "1em",
  },
}))(TableCell);

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: 12,
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const BudgetItemsTable = (props) => {
  let history = useHistory();
  const [showBudgetTable, setShowBudgetTable] = useState(true);
  const [redirectToProject, setRedirectToProject] = useState(false);
  const [redirectIds, setRedirectIds] = useState(null);
  const [selectedInnerItem, setSelectedInnerItem] = useState(null);
  const [openContactDetailsPopUp, setOpenContactDetailsPopUp] = useState(false);
  const [prePostClicked, setPrePostClicked] = useState(false);
  const [openDocumentView, setOpenDocumentView] = useState(false);
  const [documentDriveId, setDocumentDriveId] = useState(null);
  const [documentType, setDocumentType] = useState(null);

  const toggleBudgetTable = () => {
    if (showBudgetTable) {
      setShowBudgetTable(false);
    } else {
      setShowBudgetTable(true);
    }
  };

  const nameIsClickable = (innerItem) => {
    switch (innerItem.personCategory) {
      case "Contractor":
      case "Talent":
      case "Location Contact":
        return true;
      default:
        return false;
    }
  };

  const getName = (innerItem) => {
    if (innerItem.type === "Receipt") {
      return (
        <HtmlTooltip
          placement="top-start"
          title={
            <React.Fragment>
              <p>
                <b>Receipt sunmitted by: </b>
                <br />
                <em>{innerItem.name}</em>
              </p>
            </React.Fragment>
          }
        >
          <span>{`${innerItem.supplierName} (${innerItem.name})`}</span>
        </HtmlTooltip>
      );
    } else {
      return (
        <span
          className={nameIsClickable(innerItem) ? "pointer clickable" : ""}
          onClick={() => {
            if (nameIsClickable(innerItem)) {
              setSelectedInnerItem(innerItem);
              setOpenContactDetailsPopUp(true);
            }
          }}
        >
          {innerItem.name}
        </span>
      );
    }
  };

  const getMainTreeNode = (budgetItem) => {
    return (
      <Grid container>
        <Grid item xs={6} md={6} lg={6}>
          {budgetItem.category}
        </Grid>
        <Grid item xs={2} md={2} lg={2}>
          {budgetItem.quoted}
        </Grid>
        <Grid item xs={2} md={2} lg={2}>
          {budgetItem.totalAmount.toFixed(2)}
        </Grid>
        <Grid item xs={2} md={2} lg={2}>
          <span
            style={
              budgetItem.quoted - budgetItem.totalAmount < 0
                ? { color: "red" }
                : { color: "green" }
            }
          >
            {(budgetItem.quoted - budgetItem.totalAmount).toFixed(2)}
          </span>
        </Grid>
      </Grid>
    );
  };

  const getInnerTreeNodeHeader = () => {
    return (
      <Grid container className="inner-tree-node-header">
        <Grid item xs={3} md={3} lg={3}>
          Name
        </Grid>
        <Grid item xs={3} md={3} lg={3}>
          Shoot
        </Grid>
        <Grid item xs={1} md={1} lg={1}>
          Type
        </Grid>
        <Grid item xs={1} md={1} lg={1}>
          Role
        </Grid>
        <Grid item xs={1} md={1} lg={1}>
          Amount
        </Grid>
        <Grid item xs={1} md={1} lg={1}>
          Status
        </Grid>
        <Grid item xs={1} md={1} lg={1}>
          Document
        </Grid>
        {/* <Grid item xs={1} md={1} lg={1}>Predicted Date</Grid> */}
      </Grid>
    );
  };
  const getInnerTreeNode = (innerItem) => {
    return (
      <Grid container className="budget-item-inner-tree-container">
        <Grid item xs={3} md={3} lg={3} className={"ellipsis"}>
          {getName(innerItem)}
        </Grid>
        <Grid
          item
          xs={3}
          md={3}
          lg={3}
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          <span
            className={"pointer clickable"}
            onClick={() => {
              if (innerItem.scheduleName === "Pre/Post") {
                setPrePostClicked(true);
              }
              setRedirectToProject(true);
              setRedirectIds({
                projectId: innerItem.projectId,
                scheduleId: innerItem.scheduleId,
              });
            }}
          >
            {innerItem.scheduleName}
          </span>
        </Grid>
        <Grid item xs={1} md={1} lg={1}>
          {innerItem.type}
        </Grid>
        <Grid item xs={1} md={1} lg={1}>
          {innerItem.role}
        </Grid>
        <Grid item xs={1} md={1} lg={1}>
          {innerItem.amount}
        </Grid>
        <Grid item xs={1} md={1} lg={1} className="ellipsis">
          {innerItem.status}
        </Grid>
        <Grid item xs={1} md={1} lg={1}>
          <IconButton
            style={innerItem.driveId === "" ? { display: "none" } : {}}
            size="small"
            onClick={() => {
              setOpenDocumentView(true);
              setDocumentDriveId(innerItem.driveId);
              setDocumentType(innerItem.type === "Receipt" ? "Image" : "PDF");
            }}
          >
            <DescriptionIcon />
          </IconButton>
        </Grid>
        {/* <Grid item xs={1} md={1} lg={1}>
          {
            innerItem.date !== null ?
              format(new Date(innerItem.date), "dd/MM/yyyy")
              : <React.Fragment></React.Fragment>
          }
        </Grid> */}
      </Grid>
    );
  };

  const getHeaderTreeNode = () => {
    return (
      <Grid container className="budget-item-header-grid-container">
        <Grid item xs={6} md={6} lg={6}>
          Category
        </Grid>
        <Grid item xs={2} md={2} lg={2}>
          Quoted
        </Grid>
        <Grid item xs={2} md={2} lg={2}>
          Allocated
        </Grid>
        <Grid item xs={2} md={2} lg={2}>
          Remaining
        </Grid>
      </Grid>
    );
  };

  const getFooterTreeNode = () => {
    return (
      <Grid container className="budget-item-footer-grid-container">
        <Grid item xs={6} md={6} lg={6}>
          Total
        </Grid>
        <Grid item xs={2} md={2} lg={2}>
          {totalQuoted?.toFixed(2)}
        </Grid>
        <Grid item xs={2} md={2} lg={2}>
          {totalAllocated?.toFixed(2)}
        </Grid>
        <Grid item xs={2} md={2} lg={2}>
          <span
            style={totalRemaining < 0 ? { color: "red" } : { color: "green" }}
          >
            {totalRemaining.toFixed(2)}
          </span>
        </Grid>
      </Grid>
    );
  };

  let _budgetItems = props.budgetItems;
  var totalQuoted = 0;
  var totalAllocated = 0;
  var totalRemaining = 0;

  for (var i = 0; i < _budgetItems.length; i++) {
    totalQuoted += Number(_budgetItems[i].quoted);
    totalAllocated += Number(_budgetItems[i].totalAmount);
  }
  totalRemaining = totalQuoted - totalAllocated;

  if (redirectToProject) {
    setRedirectToProject(false);
    if (prePostClicked) {
      history.push(`/Project/Home/${redirectIds.projectId}/2`);
    } else {
      history.push(
        `/Project/Home/${redirectIds.projectId}/3/${redirectIds.scheduleId}/0`
      );
    }
    if (
      (props.parent === "ScheduleWrapper" && !prePostClicked) ||
      (prePostClicked && props.parent === "PrePost")
    ) {
      window.location.reload(false);
      return <Loader />;
    }
    return <React.Fragment></React.Fragment>;
  } else {
    return (
      <StyledPaper>
        <AppBar position="static" className="default-background-container">
          <Toolbar variant="dense">
            <Typography
              variant="subtitle1"
              align="center"
              style={{ flexGrow: 1 }}
            >
              Budget
            </Typography>
            <IconButton
              aria-label="Shrink Budget Table"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={() => toggleBudgetTable()}
              color="inherit"
            >
              <UnfoldMoreIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Collapse in={!showBudgetTable}>
          {openDocumentView && (
            <DocumentViewer
              modalState={openDocumentView}
              handleCloseModal={() => {
                setDocumentDriveId(null);
                setDocumentType(null);
                setOpenDocumentView(false);
              }}
              driveId={documentDriveId}
              docType={documentType}
            />
          )}
          {openContactDetailsPopUp && (
            <ContractorPopUp
              handleModal={() => {
                setOpenContactDetailsPopUp(false);
                setSelectedInnerItem(null);
              }}
              modalState={openContactDetailsPopUp}
              personId={selectedInnerItem.personId}
              scheduleItemId={selectedInnerItem.scheduleId}
              type="EditScheduleItem"
              providerType={selectedInnerItem.personCategory}
            />
          )}
          {totalQuoted !== 0 ? (
            <TreeView
              defaultCollapseIcon={<ExpandMoreIcon />}
              defaultExpandIcon={<ChevronRightIcon />}
            >
              <TreeItem
                nodeId={`budget-items-header`}
                label={getHeaderTreeNode()}
              ></TreeItem>
              {props.budgetItems.map((budgetItem, index) => (
                <TreeItem
                  key={index}
                  nodeId={`${budgetItem.category}-head`}
                  label={getMainTreeNode(budgetItem)}
                  classes={{
                    root: "outer-root-tree",
                    expanded: "expanded-tree",
                  }}
                >
                  {budgetItem.innerBudgets.map((innerItem, index) => (
                    <React.Fragment>
                      {index === 0 ? (
                        <TreeItem
                          nodeId={`inner-budget-header-${budgetItem.category}`}
                          label={getInnerTreeNodeHeader()}
                        ></TreeItem>
                      ) : (
                        <React.Fragment></React.Fragment>
                      )}
                      <TreeItem
                        nodeId={`inner-${innerItem.name}-${innerItem.scheduleId}-${innerItem.name}-${innerItem.scheduleItemId}-${innerItem.locationId}`}
                        label={getInnerTreeNode(innerItem)}
                        classes={{
                          root: "inner-root-tree",
                        }}
                      ></TreeItem>
                    </React.Fragment>
                  ))}
                </TreeItem>
              ))}
              <TreeItem
                nodeId={`budget-items-footer`}
                label={getFooterTreeNode()}
                classes={{ root: "budget-item-footer-root" }}
              ></TreeItem>
            </TreeView>
          ) : (
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align={"center"}>
                    No Expense has been allocated to this project from Quote
                    Builder
                  </StyledTableCell>
                </TableRow>
              </TableHead>
            </Table>
          )}
        </Collapse>
      </StyledPaper>
    );
  }
};

export default BudgetItemsTable;
