import { createAction } from "@reduxjs/toolkit";
import axios from "axios";

export const getUsersSuccess = createAction("GET_USERS_SUCCESS");
export const getUsersFailure = createAction("GET_USERS_FAILURE");
export const idActiveUser = createAction("ID_ACTIVE_USER");
export const setUserEmail = createAction("SET_USER_EMAIL");

export const getAllUsers = () => async (dispatch) => {
  try {
    var res = await axios.get(`Users/GetUsersForAuth`);
    dispatch(getUsersSuccess(res.data));
  } catch (err) {
    dispatch(getUsersFailure(err));
  }
};
