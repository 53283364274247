import React from "react";
import "./scheduleItemContractStatusBadge.scss";
const ScheduleItemContractStatusBadge = ({ status }) => {
  return (
    <div className="schedule-item-contract-status-badge-root">
      <span className="schedule-item-contract-status-badge-status">{status}</span>
    </div>
  );
};

export default ScheduleItemContractStatusBadge;
