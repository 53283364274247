import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getAllUsers, idActiveUser, setUserEmail } from "../../actions/roles";

const GAuth = (props) => {
  let { getAllUsers, idActiveUser, setUserEmail, users, userEmail } = props;

  const handleSignIn = () => {
    var gEm = GoogleAuth.currentUser.get().getBasicProfile().getEmail();
    setUserEmail(gEm);
  };
  let GoogleAuth;
  let profile;

  useEffect(() => {
    getAllUsers();
  }, []);
  useEffect(() => {
    if (userEmail != "" && users.length > 0) {
      idActiveUser(userEmail, users);
    }
  }, [userEmail, users]);
  useEffect(() => {
    gapiInit();
  }, []);

  const gapiInit = () => {
    console.log("gapi Init");
    window.gapi.load("client:auth2", () => {
      window.gapi.client
        .init({
          clientId:
            "102036930339-dcqi7kvc0q0k9bu2m5qs9dde8o9o8jhs.apps.googleusercontent.com",
          scope: "profile email",
        })
        .then(() => {
          gapiConfig();
        });
    });
  };

  const gapiConfig = () => {
    GoogleAuth = window.gapi.auth2.getAuthInstance();
    if (GoogleAuth == undefined) {
      console.log("gapi config unsuccessful");
      gapiInit();
    } else {
      if (GoogleAuth != undefined) {
        console.log("gapi config successful");
        console.log(GoogleAuth);
        if (GoogleAuth.isSignedIn.get() === true) {
          GoogleAuth.isSignedIn.listen(handleSignIn);
          profile = GoogleAuth.currentUser.get().getBasicProfile();
          setUserEmail(profile.getEmail());
        } else {
          GoogleAuth.signIn();
          var gEm = GoogleAuth.currentUser.get().getBasicProfile().getEmail();
          setUserEmail(gEm);
        }
      }
    }
  };

  return <div></div>;
};
const mapStateToProps = (state, ownProps) => ({
});
export default connect(mapStateToProps, {
  getAllUsers,
  setUserEmail,
  idActiveUser,
})(GAuth);
