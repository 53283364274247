import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  Typography,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import axios from "axios";
import React, { useEffect, useState } from "react";
import XeroContactAutocomplete from "../../Shared/XeroContactAutocomplete/XeroContactAutocomplete";
import { currencyFormat } from "../../../utils/GeneralUtils";
import moment from "moment";
import DuplicatesTable from "./DuplicatesTable";

const SendPayableToXeroDialog = ({
  open,
  handleClose,
  handleSubmit,
  payable,
  isLoading,
  handleUpdateLocalPayable,
}) => {
  const [isFetchingDetails, setIsFetchingDetails] = useState(false);
  const [isCheckingForDuplicate, setIsCheckingForDuplicate] = useState(false);
  const [xeroContactId, setXeroContactId] = useState(payable.xeroContactId);
  const [xeroContact, setXeroContact] = useState(null);
  const [possibleDuplicates, setPossibleDuplicates] = useState([]);
  const [shouldSelectXeroContact, setShouldSelectXeroContact] = useState(false);

  useEffect(() => {
    checkPossibleDuplicates();
  }, []);

  useEffect(() => {
    fetchXeroDetails();
  }, [xeroContactId]);

  const checkPossibleDuplicates = () => {
    setIsCheckingForDuplicate(true);
    if (payable.recordId) {
      axios
        .get(
          `reconciliation/SearchForDuplicatePayable/${payable.recordId}/${payable.recordType}`
        )
        .then(({ data }) => {
          setPossibleDuplicates(data);
        })
        .catch((err) => {
          console.error("Failed to complete duplicate check");
        })
        .finally(() => setIsCheckingForDuplicate(false));
    }
  };

  const fetchXeroDetails = () => {
    if (xeroContactId) {
      setIsFetchingDetails(true);
      axios
        .get(`Accounting/GetContactsById/${xeroContactId}`)
        .then(({ data }) => {
          setXeroContact(data);
        })
        .catch((err) => {
          console.error("Failed to fetch Xero contact details");
        })
        .finally(() => setIsFetchingDetails(false));
    }
  };

  const handleChangeXeroContact = (xeroContact) => {
    handleUpdateLocalPayable("xeroContactId", xeroContact);
  };

  return (
    <Dialog
      open={open}
      onClose={(e, reason) => {
        handleClose();
      }}
      onClick={(e) => {
        //Prevents triggering accordian expand
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <DialogTitle
        style={{ textAlign: "center" }}
      >{`Are you sure?`}</DialogTitle>
      <DialogContent>
        <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
          <Typography variant="caption" style={{ textAlign: "center" }}>
            {`Please confirm details before sending bill for payment.`}
          </Typography>
          <Typography variant="caption">
            Total: {currencyFormat(payable.total)}
          </Typography>
          <Typography variant="caption">
            Subtotal: {currencyFormat(payable.subtotal)}
          </Typography>
          {isFetchingDetails ? (
            <Skeleton height={20} />
          ) : xeroContact ? (
            <div style={{ display: "flex", gap: 5, alignItems: "center" }}>
              <Typography variant="caption">{`${xeroContact.Name}`}</Typography>
              <Typography
                variant="caption"
                style={{
                  fontSize: 8,
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={() => setShouldSelectXeroContact(true)}
              >
                change
              </Typography>
            </div>
          ) : (
            <div style={{ display: "flex", gap: 5, alignItems: "center" }}>
              <Typography variant="caption">{`A new contact will be created.`}</Typography>
              <Typography
                variant="caption"
                style={{
                  fontSize: 8,
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={() => setShouldSelectXeroContact(true)}
              >
                change
              </Typography>
            </div>
          )}
          {shouldSelectXeroContact && (
            <XeroContactAutocomplete
              organisationId={2}
              handleOnChange={handleChangeXeroContact}
              xeroContactId={payable.xeroContactId}
              xeroContactName={payable.xeroContactName}
              variant={"filled"}
            />
          )}
        <div>
          {isCheckingForDuplicate && (
            <div style={{ display: "grid", marginTop: 20 }}>
              <Typography variant="caption">
                Wait while the system is checking for duplicates...
              </Typography>
              <Skeleton />
            </div>
          )}
          <DuplicatesTable
            possibleDuplicates={possibleDuplicates}
            isCheckingForDuplicate={isCheckingForDuplicate}
          />
        </div>
        </div>
      </DialogContent>
      {!isFetchingDetails && (
        <DialogActions style={{ textAlign: "center", padding: 20 }}>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <React.Fragment>
              <Button
                className={"default-button"}
                onClick={handleClose}
                variant="contained"
              >
                Cancel
              </Button>
              <Button
                className={"default-button"}
                onClick={handleSubmit}
                variant="contained"
                autoFocus
              >
                Confirm
              </Button>
            </React.Fragment>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
};

export default SendPayableToXeroDialog;
