import {
  Checkbox,
  InputAdornment,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  currencyFormat,
  filterNumbersOnlyInput,
} from "../../../../utils/GeneralUtils";

const ReconcilePurchaseOrderTableRow = ({
  reconLine,
  payable,
  handleUpdateSelectedReconLines,
  handleRemoveSelectedReconLine,
}) => {
  const [usageType, setUsageType] = useState(null);
  const [selectedAmount, setSelectedAmount] = useState(reconLine.totalInNzd);

  useEffect(() => {
    if (
      payable.reference &&
      reconLine.purchaseOrderNumber &&
      payable.reference?.toLowerCase() ===
        reconLine.purchaseOrderNumber?.toLowerCase()
    ) {
      setUsageType("PARTIAL");
      handleUpdateAmount(reconLine.totalInNzd);
    }
  }, [payable]);

  const handleUpdateAmount = (amount) => {
    if (reconLine.totalInNzd >= amount) {
      setSelectedAmount(amount);
      handleUpdateSelectedReconLines(reconLine.id, amount);
    } else {
      setSelectedAmount(reconLine.totalInNzd);
      handleUpdateSelectedReconLines(reconLine.id, reconLine.totalInNzd);
    }
  };

  const handleChangeUsePartialCheckbox = (isChecked) => {
    if (isChecked) {
      handleUpdateSelectedReconLines(reconLine.id, reconLine.totalInNzd);
      setUsageType("PARTIAL");
    } else {
      handleRemoveSelectedReconLine(reconLine.id);
      setUsageType(null);
      setSelectedAmount(reconLine.totalInNzd);
    }
  };

  return (
    <TableRow>
      <TableCell className="reconcile-table-cell-root">
        <Typography variant="caption">
          {moment(reconLine.date).format("MMM YY")}
        </Typography>
      </TableCell>
      <TableCell className="reconcile-table-cell-root">
        <Typography variant="caption">
          {reconLine.purchaseOrderNumber}
        </Typography>
      </TableCell>
      <TableCell className="reconcile-table-cell-root">
        <Typography variant="caption">{reconLine.xeroContactName}</Typography>
      </TableCell>
      <TableCell className="reconcile-table-cell-root">
        <Typography variant="caption">{reconLine?.serviceName}</Typography>
      </TableCell>
      <TableCell className="reconcile-table-cell-root">
        <Typography variant="caption">
          {currencyFormat(reconLine?.totalInNzd)}
        </Typography>
      </TableCell>
      <TableCell className="reconcile-table-cell-root">
        <div className="reconcile-table-single-checkbox-container">
          <Checkbox
            checked={usageType === "PARTIAL"}
            onChange={(e) => handleChangeUsePartialCheckbox(e.target.checked)}
          />
        </div>
      </TableCell>
      <TableCell className="reconcile-table-cell-root">
        <TextField
          fullWidth
          value={selectedAmount}
          disabled={usageType !== "PARTIAL"}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          onChange={(e) =>
            handleUpdateAmount(filterNumbersOnlyInput(e.target.value))
          }
          size="small"
        />
      </TableCell>
    </TableRow>
  );
};

export default ReconcilePurchaseOrderTableRow;
