import React, { useState } from "react";
import { Button, Card } from "@material-ui/core";
import { getComparator } from "../../utils/TableUtils";
import CustomTableHead from "../Shared/CustomTableHead";
import LoadingTableSkeleton from "../Shared/LoadingTableSkeleton";
import InfluencerBasicDetails from "./InfluencerBasicDetails";
import { Skeleton } from "@material-ui/lab";

const InfluencersLookUpTable = ({
  influencers,
  isLoading,
  handleEditInfluencer,
  displayAddToProject,
  addInfluencerToProject,
  handleSaveInfluencer,
}) => {
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState({
    field: "firstName",
    name: "Name",
    fieldType: "string",
  });

  const handleOnRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <div className="influencers-lookup-table-root">
      {[...influencers]
        .sort(getComparator(order, orderBy))
        .map((influencer, index) => (
          // key={index}
          <Card key={influencer.id} className="influencers-lookup-card-container" style={influencer.organisationFk === 5 ? { backgroundColor: "#d4edff" } : {}}>
            <div className="influencers-lookup-card-inner-container">
              <InfluencerBasicDetails
                influencer={influencer}
                handleEditInfluencer={handleEditInfluencer}
                handleSaveInfluencer={handleSaveInfluencer}
              />
              {displayAddToProject && (
                <div className={"influencers-lookup-card-action-container"}>
                  {/* <Button
                    size="small"
                    color="primary"
                    variant="outlined"
                    onClick={() => addInfluencerToProject(influencer)}
                    className={influencer.organisationFk === 5 ? "poi-add-to-campaign-button poi-add-to-campaign-button-padding" : ""}
                  >
                    Add To Campaign
                  </Button> */}
                  <div
                    className={influencer.organisationFk === 5 ? "poi-add-to-campaign-button poi-add-to-campaign-button-padding action-button"
                      : "action-button"}
                    onClick={() => addInfluencerToProject(influencer)}
                  >
                    Add to campaign
                  </div>
                </div>
              )}
            </div>
          </Card>
        ))}
      {isLoading && (
        <>
          <Skeleton height={150} />
          <Skeleton height={150} />
          <Skeleton height={150} />
        </>
      )}
    </div>
  );
};

export default InfluencersLookUpTable;
