import React, { useState, useEffect } from "react";
import Axios from "axios";

import { Button, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import SendSchedulePopUp from "./SendSchedule/SendSchedulePopUp";
import "./Schedule.css";

const useStyles = makeStyles((theme) => ({
  linearProgress: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

const ScheduleTab = (props) => {
  const classes = useStyles();

  const [updatingDocument, setUpdatingDocument] = useState(false);
  const [documentUrl, setDocumentUrl] = useState("");
  const [documentFetched, setDocumentFetched] = useState(false);
  const [peopleList, setPeopleList] = useState();
  const [openSendSchedule, setOpenSendSchedule] = useState(false);
  const [emailTemplate, setEmailTemplate] = useState();
  const [scheduleDriveFileId, setScheduleDriveFileId] = useState(null);
  const [fetching, setFetching] = useState(true);

  useEffect(() => {
    if (!documentFetched) {
      setDocumentFetched(true);
      Axios.get(`Schedules/GetBasicSchedule/${props.schedule.id}`)
        .then((res) => {
          setDocumentUrl(props.project.isArchived !== 1 ? 
            `https://docs.google.com/document/d/${res.data.scheduleDriveFileId}/edit?pli=1` :
            `https://docs.google.com/document/d/${res.data.scheduleDriveFileId}/preview`);
          setScheduleDriveFileId(res.data.scheduleDriveFileId);
          setFetching(false)
        })
        .catch((error) => {
          setDocumentFetched(false);
          setFetching(false)
        });
    }
  });

  const generateDocument = () => {
    setUpdatingDocument(true);
    Axios.get(`Schedules/GenerateSchedule/${props.schedule.id}`)
      .then(res => {
        console.log(res);
        if (res.data) {
          setDocumentUrl(`https://docs.google.com/document/d/${res.data}/edit?pli=1`);
          setScheduleDriveFileId(res.data);
          setUpdatingDocument(false);
        }
      })
      .catch(e => {

      })
  }

  const getPeopleListAndEmailTemplate = () => {
    let url1 = Axios.get(`Schedules/GetPeopleList/${props.scheduleId}`);
    let url2 = Axios.get(`ScheduleEmailTemplates/GetLastScheduleEmailTemplate`);
    Axios.all([url1, url2])
      .then(
        Axios.spread((...res) => {
          let peopleList = res[0].data;
          let emailTemplate = res[1].data;
          console.log(peopleList);
          setPeopleList(peopleList);
          console.log(emailTemplate);
          setEmailTemplate(emailTemplate);
          setOpenSendSchedule(true);
        })
      )
      .catch((err) => console.log(err));
  };

  const refreshPeopleList = () => {
    setPeopleList(null);
    Axios.get(`Schedules/GetPeopleList/${props.scheduleId}`)
      .then((res) => {
        let peopleList = res.data;
        console.log(peopleList);
        setPeopleList(peopleList);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (peopleList) {
      console.log(peopleList);
      setOpenSendSchedule(true);
    }
  }, [peopleList]);

  return (
    <div height="1000px" width="100%">
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          margin: "10px 10px 10px 10px",
        }}
      >
        {updatingDocument ? (
          <React.Fragment></React.Fragment>
        ) : (
            <React.Fragment>
              {
                openSendSchedule ?
                  <SendSchedulePopUp
                    modalState={openSendSchedule}
                    handleModal={() => setOpenSendSchedule(false)}
                    peopleList={peopleList}
                    emailTemplate={emailTemplate}
                    scheduleId={props.schedule.id}
                    refreshPeopleList={refreshPeopleList}
                  /> : <React.Fragment></React.Fragment>
              }
              <Button
                variant="contained"
                color="primary"
                onClick={() => getPeopleListAndEmailTemplate()}//getPeopleListAndEmailTemplate
                className="margin-sides-10"
                disabled={!documentFetched || props.project.isArchived === 1}
                style={scheduleDriveFileId !== null ? {} : { display: 'none' }}
              >
                Send
              </Button>
              <Button
                variant="contained"
                className={`margin-sides-10 ${!documentFetched ? "" : "default-button" }`}
                onClick={() => documentFetched ? window.open(documentUrl) : ""}
                disabled={!documentFetched}
                style={scheduleDriveFileId !== null ? {} : { display: 'none' }}
              >
                Open In New Tab
              </Button>
              <Button
                size={scheduleDriveFileId !== null ? "small" : "medium"}
                color="primary"
                variant="contained"
                onClick={generateDocument}
                style={documentFetched ? {} : { display: 'none' }}
                disabled={props.project.isArchived === 1}
              >
                {scheduleDriveFileId !== null ? `Regenerate Schedule` : `Generate Schedule`}
              </Button>
            </React.Fragment>
          )}
      </div>
      {documentFetched && !updatingDocument && scheduleDriveFileId !== null ? (
        <div
          style={{
            overflow: "hidden",
            // Calculated from the aspect ration of the content (in case of 16:9 it is 9/16= 0.5625)
            paddingTop: "56.25%",
            position: "relative",
          }}
        >
          <iframe
            src={`${documentUrl}`}
            width="100%"
            height="100%"
            style={{
              border: "ridge",
              height: "100%",
              left: 0,
              position: "absolute",
              top: 0,
              width: "99%",
            }}
          />
        </div>
      ) : (
          <div className="generate-schedule-text-container">
            {
              updatingDocument ?
                <React.Fragment>
                  <h1>Updating Document</h1>
                  <h1 className="loading-dots"><span>.</span><span>.</span><span>.</span></h1>
                  <p>This may take a few seconds</p>
                </React.Fragment>
                :
                scheduleDriveFileId === null && !fetching ?
                  <React.Fragment>
                    <h1>No Schedule.</h1>
                    <p>Please click the "Generate Schedule" button to create one.</p>
                  </React.Fragment>
                  :
                  <div className="generate-schedule-text-container" style={documentFetched ? { display: 'none' } : {}}>
                    <React.Fragment>
                      <h1>Fetching Document</h1>
                      <h1 className="loading-dots"><span>.</span><span>.</span><span>.</span></h1>
                    </React.Fragment>
                  </div>
            }
          </div>
        )}
    </div>
  );
};
export default ScheduleTab;
