import {
  Paper,
  TableContainer,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import CustomTableHead from "../../Shared/CustomTableHead";
import axios from "axios";
import LoadingTableSkeleton from "../../Shared/LoadingTableSkeleton";
import { getComparator } from "../../../utils/TableUtils";
import "./campaignsTable.scss";
import ConfirmationDialogActions from "../../Shared/ConfirmationDialogActions";
import Snacky from "../../Shared/Snacky";
const tableHeader = [
  {
    field: "name",
    name: "Campaigns",
    fieldType: "string",
    alignment: "left",
  },
  {
    field: "name",
    name: "",
    fieldType: "string",
    alignment: "left",
  },
];

const CampaignsTable = ({
  companyId,
  handleSelectCampaign,
  preLoadCampaignById,
}) => {
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState({
    field: "name",
    name: "Campaigns",
    fieldType: "string",
  });
  const [keyword, setKeyword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [campaigns, setCampaigns] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [showArchived, setShowArchived] = useState(false);
  const [openArchiveModal, setOpenArchiveModal] = useState(false);
  const [snackBarProps, setSnackBarProps] = useState({
    open: false,
    severity: "success",
    text: "",
  });

  useEffect(() => {
    searchCampaigns();
  }, []);

  useEffect(() => {
    if (campaigns?.length > 0 && preLoadCampaignById) {
      const campaign = campaigns.find((c) => c.id === preLoadCampaignById);
      if (campaign) {
        handleSelectCampaign(campaign);
      }
    }
  }, [preLoadCampaignById]);

  const filterCampaigns = () => {
    if (showArchived) {
      // If showArchived is true, return all campaigns
      return campaigns;
    } else {
      // If showArchived is false, filter out campaigns where project.isArchived is 1
      return campaigns.filter((campaign) => {
        return campaign.projects[0].isArchived !== 1;
      });
    }
  }

  const searchCampaigns = () => {
    setIsLoading(true);
    axios
      .get(`Campaign/GetCampaignsByCompanyId/${companyId}`)
      .then(({ data }) => {
        setCampaigns(data);
        if (preLoadCampaignById) {
          const campaign = data.find((c) => c.id === preLoadCampaignById);
          if (campaign) {
            handleSelectCampaign(campaign);
          }
        }
      })
      .finally(() => setIsLoading(false));
  };

  const handleOnRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleRowClick = (e, campaign) => {
    e.preventDefault();
    handleSelectCampaign(campaign);
  };

  const handleAchiveCampaign = (e, campaign) => {
    e.preventDefault();
    e.stopPropagation();
    setSelectedCampaign(campaign);
    setOpenArchiveModal(true);
  };

  const handleCloseArchiveModal = () => {
    setSelectedCampaign(null);
    setOpenArchiveModal(false);
  };

  const handleConfirmArchiveProject = () => {
    axios
      .put(
        `/projects/ArchiveProject/${selectedCampaign.projectFk}`
      )
      .then(({ data }) => {
        setCampaigns(
          [...campaigns].map((c) =>
            c.id === selectedCampaign.id ? { ...c, projects: [data] } : c
          )
        );
        handleCloseArchiveModal();
        setSnackBarProps({
          open: true,
          severity: "success",
          text: "Voilà! The campaign has been updated.",
        });
      })
      .catch((err) => {
        console.log(err);
        setSnackBarProps({
          open: true,
          severity: "warning",
          text: "Unable to archive campaign.",
        });
      });
  };

  return (
    <div className="campaigns-table-root-container">
      <Snacky snackprops={snackBarProps} setSnackBarProps={setSnackBarProps} />
      {openArchiveModal && selectedCampaign && (
        <ConfirmationDialogActions
          title={"Are you sure?"}
          open={openArchiveModal}
          contentText={"This action will modify the campaign's status."}
          button1Action={handleCloseArchiveModal}
          button1Text={"Cancel"}
          button2Text={"Confirm"}
          button2Action={handleConfirmArchiveProject}
        />
      )}
      <div style={{ display: "flex", justifyContent: "end" }}>
        <Typography
          variant="caption"
          className="clients-home-link-button"
          onClick={(e) => setShowArchived(!showArchived)}
        >
          {showArchived ? "Collapse Hidden" : "Show Hidden"}
        </Typography>
      </div>
      <div className="campaigns-table-filter-container">
        <TextField
          label="Global Search"
          variant="filled"
          fullWidth={true}
          value={keyword}
          size="small"
          onChange={(e) => {
            setKeyword(e.target.value);
          }}
        />
      </div>

      <TableContainer component={Paper}>
        <table className="custom-table custom-table-border custom-table-hover socialites-projects-table">
          <CustomTableHead
            headers={tableHeader}
            orderBy={orderBy}
            order={order}
            onRequestSort={handleOnRequestSort}
          />
          <tbody>
            {isLoading ? (
              <LoadingTableSkeleton
                loadingMessage="Loading campaigns..."
                colspan="3"
              />
            ) : (
              filterCampaigns()
                .filter((c) =>
                  c.name.toLowerCase().includes(keyword.toLowerCase())
                )
                .sort(getComparator(order, orderBy))
                .map((campaign) => (
                  <tr key={campaign?.id}>
                    <td
                      className="campaigns-table-tbody-tr"
                      onClick={(e) => handleRowClick(e, campaign)}
                    >
                      <Typography variant="subtitle2" className="roboto-regular">
                        {campaign.name}
                      </Typography>
                    </td>
                    <td>
                      <div style={{ display: "flex", justifyContent: "end" }}>
                        {campaign.projects[0].isArchived !== 1 ? (
                          <Typography
                            variant="caption"
                            className="clients-home-link-button"
                            onClick={(e) => handleAchiveCampaign(e, campaign)}
                          >
                            Hide
                          </Typography>
                        ) : (
                          <Typography
                            variant="caption"
                            className="clients-home-link-button"
                            onClick={(e) => handleAchiveCampaign(e, campaign)}
                          >
                            Restore
                          </Typography>
                        )}
                      </div>
                    </td>
                  </tr>
                ))
            )}
          </tbody>
        </table>
      </TableContainer>
    </div>
  );
};

export default CampaignsTable;
