import React, { useState, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import Axios from "axios";
import {
  Grid,
  TextField,
  Button,
  Snackbar,
  Checkbox,
} from "@material-ui/core";
import "./Admin.css";
import MuiAlert from "@material-ui/lab/Alert";
import Loader from "../Loader";

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));

export default function ExpressPayment() {
  const classes = useStyles();
  const [adminMenu, setAdminMenu] = useState("");
  const [values, setValues] = useState();
  const [companyName, setCompanyName] = useState();
  const [tradingName, setTradingName] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [bankAccount, setBankAccount] = useState();
  const [mealPrice, setMealPrice] = useState();
  const [gst, setGst] = useState();
  const [successCreate, setSuccessCreate] = useState(false);
  const [failedCreate, setFailedCreate] = useState(false);
  const [gstInclusive, setGstInclusive] = useState();
  const [gstBox, setGstBox] = useState();
  const [deliveryCost, setDeliveryCost] = useState();
  const [deliveryGstInclusive, setDeliveryGstInclusive] = useState();
  const [kidsMealPrice, setKidsMealPrice] = useState();
  const [kidsMealGstInc, setKidsMealGstInc] = useState();

  const handleChange = (event, text) => {
    var newValue = event.target.value;
    switch (text) {
      case "Company Name:":
        setCompanyName(newValue);
        break;
      case "Trading Name:":
        setTradingName(newValue);
        break;
      case "Email Address:":
        setEmail(newValue.trim());
        break;
      case "Phone Number:":
        setPhone(newValue.trim());
        break;
      case "Bank Account:":
        setBankAccount(newValue.trim());
        break;
      case "Meal Price:":
        setMealPrice(newValue.trim());
        break;
      case "GST Number:":
        setGst(newValue.trim());
        break;
      case "Delivery Cost:":
        setDeliveryCost(newValue.trim());
        break;
      case "Kids Meal Price:":
        setKidsMealPrice(newValue.trim());
        break;
      default:
        break;
    }
  };

  const closeSuccessCreateSnackbar = () => {
    setSuccessCreate(false);
  };

  const closeFailedCreateSnackbar = () => {
    setFailedCreate(false);
  };

  var snackbarSuccess = (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={successCreate}
      autoHideDuration={2000}
      onClose={closeSuccessCreateSnackbar}
    >
      <MuiAlert severity="success">Successfully Saved Changes!</MuiAlert>
    </Snackbar>
  );

  var snackbarFailed = (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={failedCreate}
      autoHideDuration={2000}
      onClose={closeFailedCreateSnackbar}
    >
      <MuiAlert severity="error">Failed to Save Changes!</MuiAlert>
    </Snackbar>
  );

  const saveChanges = () => {
    Axios.post(`CateringFinances/PostCateringFinance`, {
      companyName: companyName,
      tradingName: tradingName,
      emailAddress: email,
      phoneNumber: phone,
      bankAccount: bankAccount,
      mealPrice: mealPrice,
      gstNumber: gst,
      gstInc: gstInclusive,
      deliveryCost: parseFloat(deliveryCost),
      deliveryGstInc: deliveryGstInclusive,
      dateStamp: new Date(),
      kidsMealPrice: kidsMealPrice,
      kidsMealGstInc: kidsMealGstInc
    })
      .then((res) => {
        console.log(res);
        setSuccessCreate(true);
      })
      .catch((e) => {
        console.log(e);
        setFailedCreate(true);
      });
  };

  const getField = (text, value) => (
    <div className="textfield-padding">
      <TextField
        id={text}
        variant="outlined"
        label={text}
        value={value.toString()}
        onChange={(event) => handleChange(event, text)}
        fullWidth
      />
    </div>
  );

  useEffect(() => {
    Axios.get(`CateringFinances/GetLastCateringFinance`)
      .then((res) => {
        console.log("catering");
        console.log(res.data);
        var response = res.data;
        setCompanyName(response.companyName);
        setTradingName(response.tradingName);
        setEmail(response.emailAddress);
        setPhone(response.phoneNumber);
        setBankAccount(response.bankAccount);
        setMealPrice(response.mealPrice);
        setGst(response.gstNumber);
        setGstInclusive(response.gstInc);
        setDeliveryCost(response.deliveryCost);
        setDeliveryGstInclusive(response.deliveryGstInc);
        setKidsMealGstInc(response.kidsMealGstInc);
        setKidsMealPrice(response.kidsMealPrice);
        setValues(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const handleGstIncChecked = () => {
    if (gstInclusive == 1) {
      setGstInclusive(0);
    } else {
      setGstInclusive(1);
    }
  }

  const handleDeliveryGstIncChecked = () => {
    if (deliveryGstInclusive == 1) {
      setDeliveryGstInclusive(0);
    } else {
      setDeliveryGstInclusive(1);
    }
  }

  const handleKidsMealGstIncChecked = () => {
    if (kidsMealGstInc == 1) {
      setKidsMealGstInc(0);
    } else {
      setKidsMealGstInc(1);
    }
  }

  if (values) {
    return (
      <main className={classes.content}>
        <div className="main-content" />
        <Typography variant="h4">Catering</Typography>
        <Grid container className="table-grid">
          <Grid item xs={12} md={6} lg={4}>
            {getField("Company Name:", companyName)}
            {getField("Trading Name:", tradingName)}
            {getField("Email Address:", email)}
            {getField("Phone Number:", phone)}
            {getField("Bank Account:", bankAccount)}
            <div style={{ display: "flex" }}>
              {getField("Delivery Cost:", deliveryCost)}
              <div className="catering-gst">
                <Checkbox
                  checked={deliveryGstInclusive === 1 ? true : false}
                  onChange={handleDeliveryGstIncChecked}
                  color="primary"
                  inputProps={{ "aria-label": "secondary checkbox" }}
                />
                GST Inclusive
              </div>
            </div>
            <div style={{ display: "flex" }}>
              {getField("Meal Price:", mealPrice)}
              <div className="catering-gst">
                <Checkbox
                  checked={gstInclusive === 1 ? true : false}
                  onChange={handleGstIncChecked}
                  color="primary"
                  inputProps={{ "aria-label": "secondary checkbox" }}
                />
                GST Inclusive
              </div>
            </div>
            <div style={{ display: "flex" }}>
              {getField("Kids Meal Price:", kidsMealPrice)}
              <div className="catering-gst">
                <Checkbox
                  checked={kidsMealGstInc === 1 ? true : false}
                  onChange={handleKidsMealGstIncChecked}
                  color="primary"
                  inputProps={{ "aria-label": "secondary checkbox" }}
                />
                GST Inclusive
              </div>
            </div>
            {getField("GST Number:", gst)}
            <div className="button-div">
              <Button color="primary" variant="contained" onClick={saveChanges}>
                Save
              </Button>
            </div>
          </Grid>
        </Grid>
        {snackbarSuccess}
        {snackbarFailed}
      </main>
    );
  } else {
    return <Loader />;
  }
}
