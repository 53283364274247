import React, { useState } from "react";
import { connect } from "react-redux";
import { editScheduleItem } from "../../../../actions/scheduleItems";
import { withStyles, styled, makeStyles } from "@material-ui/core/styles";
import { setSnackBarProps } from "../../../../actions/snackbar";
import {
  TextField,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Slide,
  Grid,
  InputAdornment,
  Tooltip,
  Typography,
  Chip,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import { format } from "date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import AccessTime from "@material-ui/icons/AccessTime";
import SaveIcon from "@material-ui/icons/Save";
import DeleteIcon from "@material-ui/icons/Delete";
import OfflineBoltIcon from "@material-ui/icons/OfflineBolt";
import ContractorPopUp from "../../../Project/ProjectOutputs/PopUps/ContractorPopUp";
import ContractButton from "./ContractButton";
import TalentContract from "../TalentContract/TalentContract";
import TalentNotes from "./TalentNotes";
import "../TalentContract/talentContractButtonPanelStyles.css";
import "../Schedule.css";
import TalentSwitchPanel from "./TalentSwitchPanel";
import DocumentViewer from "../DocumentViewer";
import { ProviderTableCell } from "./ProviderTableCell";
import { RightClickMenu, RightClickAgentMenu } from "./CellMenus";
import Snacky from "../../../Shared/Snacky";
import {
  DeleteItemDialog,
  InvoicesDialog,
  CancelContractDialog,
} from "../ScheduleItemTable/Dialogs";
import {
  CheckCircle as CheckCircleIcon,
  NotInterested as NotInterestedIcon,
} from "@material-ui/icons";
import { setDialogProps } from "../../../../actions/dialog";
import InformationDialogy from "../../../Shared/InformationDialogy";
import { getFirstStartTime, getLastEndTime } from "../../../../utils/utils";
import { getTimePortion } from "../../../../utils/TimeHelpers";
import AucklandStandardContract from "../TalentContract/AucklandStandardContract";
import AddEquipmentAndTravel from "../../../Shared/Contractors/AddEquipmentAndTravel";
import AddTravel from "../../../Shared/Contractors/AddTravel";
import TravelReimbursmentOnlyButton from "./PopUps/TravelReimbursmentOnlyButton";
import RolloverButton from "./RolloverButton";
import { useUserContext } from "../../../../context/user/UserContext";
import { useAuth } from "../../../../context/auth/AuthContext";
//----------------------------------------
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: 200,
  },
  margin: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  alignRight: {
    marginLeft: "auto",
    marginRight: 0,
  },
  margin: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  tableHeader: {
    fontSize: 13,
    fontWeight: "bold",
    paddingLeft: "10px",
    paddingRight: "2px",
  },
  removeMarginPadding: {
    padding: "0px 0px 0px 0px",
    margin: "0px 0px 0px 0px",
  },
  checked: {
    color: "#3f51b5",
  },
}));

const StyledPaper = styled(Paper)({
  width: "100%",
});
const StyledButton = styled(Button)({
  color: "#000000",
  borderRadius: "20%",
});
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: "#9E9E9E",
    textTransform: "uppercase",
    fontWeight: "bold",
  },
  body: {
    fontSize: "1em",
  },
}))(TableCell);
const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);
const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const initialState = {
  mouseX: null,
  mouseY: null,
};

const ScheduleItemsTable = (props) => {
  const classes = useStyles();
  const { user } = useAuth();

  const [state, setState] = useState(initialState);
  const [openContactDetailsPopUp, setOpenContactDetailsPopUp] = useState(false);
  const [openDeleteItemDialog, setOpenDeleteItemDialog] = useState(false);
  const [currentProvider, setCurrentProvider] = useState(null);
  const [currentScheduleItemId, setCurrentScheduleItemId] = useState(null);
  const [showInvoicesModal, setShowInvoicesModal] = useState(false);
  const [showContractsModal, setShowContractsModal] = useState(false);
  const [talentModalState, setTalentModalState] = useState(false);
  const [activeScheduleItem, setActiveScheduleItem] = useState();
  const [rightClickOpen, setRightClickOpen] = useState(false);
  const [currentRightClickedItem, setCurrentRightClickedItem] = useState(null);
  const [currentRightClickedAgent, setCurrentRightClickedAgent] =
    useState(null);
  const [rightClickAgentOpen, setRightClickAgentOpen] = useState(false);
  const [openTalentNotes, setOpenTalentNotes] = useState(false);

  const [showTimeError, setShowTimeError] = useState(false);
  const [timeErrorText, setTimeErrorText] = useState("");

  const [aucklandStandardModalState, setAucklandStandardModalState] =
    useState(false);

  const [lineIndex, setlineIndex] = useState(null);

  const handleCloseContext = () => {
    setState(initialState);
    setRightClickOpen(false);
    setRightClickAgentOpen(false);
  };

  const handleCloseAgentContext = () => {
    setState(initialState);
    setRightClickAgentOpen(false);
    setRightClickOpen(false);
  };

  const handleContextClick = (event, rowObj) => {
    if (rightClickOpen == true) {
      event.preventDefault();
      setState(initialState);
      setCurrentRightClickedItem();
      setRightClickOpen(false);
    } else {
      event.preventDefault();
      setState({
        mouseX: event.clientX - 2,
        mouseY: event.clientY - 4,
      });
      setCurrentRightClickedItem(rowObj);
      setRightClickOpen(true);
    }
  };

  const handleAgentContextClick = (event, rowObj) => {
    event.preventDefault();
    setState({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    });
    setCurrentRightClickedAgent(rowObj);
    setRightClickAgentOpen(true);
  };

  const handleTalentModal = () => {
    if (talentModalState) {
      setTalentModalState(false);
    } else {
      setTalentModalState(true);
    }
  };

  const handleAucklandStandardModal = () => {
    if (aucklandStandardModalState) {
      setAucklandStandardModalState(false);
    } else {
      setAucklandStandardModalState(true);
    }
  };

  const handleCompleteTalentContract = (outputs, isAucklandStandard) => {
    if (isAucklandStandard) {
      props.handleCompleteAucklandContract(activeScheduleItem, outputs);
    } else {
      props.completeTalentContract(activeScheduleItem, outputs);
      setActiveScheduleItem();
      setTalentModalState(false);
    }
  };

  const confirmAucklandStandard = () => {
    props.editScheduleItem(
      activeScheduleItem,
      "talent",
      "talentContractStatus",
      "Complete",
      1, user?.email
    );
    props.editScheduleItem(
      activeScheduleItem,
      "talent",
      "contractStatus",
      "Send",
      1, user?.email
    );
    props.editScheduleItem(
      activeScheduleItem,
      "talent",
      "isAucklandStandardContract",
      1,
      1, user?.email
    );
    setActiveScheduleItem();
    setTalentModalState(false);
  };

  const handleDeletePathway = (si) => {
    if (
      si.contractStatus == "Send" ||
      si.contractStatus == "Disabled" ||
      si.contractStatus == "Sent"
    ) {
      setCurrentScheduleItemId(si.id);
      setOpenDeleteItemDialog(true);
    } else if (
      si.contractStatus != "Send" &&
      si.contractStatus != "Disabled" &&
      si.contractStatus != "Invoiced"
    ) {
      setShowContractsModal(true);
      setActiveScheduleItem();
    } else {
      setShowInvoicesModal(true);
      setActiveScheduleItem();
    }
  };

  if (props && props.scheduleItems) {
    return (
      <StyledPaper>
        {showTimeError && (
          <InformationDialogy
            setDialogProps={setDialogProps}
            buttonProps={{
              show: showTimeError,
              titleText: "Woops!",
              contentText: timeErrorText,
              buttonColor: "primary",
              buttonText: "Ok",
            }}
            handleButton={() => {
              setTimeErrorText("");
              setShowTimeError(false);
            }}
          />
        )}
        {openDeleteItemDialog && (
          <DeleteItemDialog
            scheduleItem={activeScheduleItem}
            project={props.project}
            openDeleteItemDialog={openDeleteItemDialog}
            setOpenDeleteItemDialog={setOpenDeleteItemDialog}
            table={props.providerType.toLowerCase()}
            setSnackBarProps={props.setSnackBarProps}
            deleteScheduleItem={props.deleteScheduleItem}
            setActiveScheduleItem={setActiveScheduleItem}
          />
        )}
        {showInvoicesModal && (
          <InvoicesDialog
            setShowInvoicesModal={setShowInvoicesModal}
            showInvoicesModal={showInvoicesModal}
          />
        )}
        {showContractsModal && (
          <CancelContractDialog
            setShowContractsModal={setShowContractsModal}
            showContractsModal={showContractsModal}
          />
        )}
        {openContactDetailsPopUp && (
          <ContractorPopUp
            handleModal={() => {
              // props.reloadScheduleItemsList();
              setOpenContactDetailsPopUp(false);
            }}
            modalState={openContactDetailsPopUp}
            personId={currentProvider.id}
            scheduleItemId={currentScheduleItemId}
            type="EditScheduleItem"
            providerType={props.providerType}
          />
        )}
        {openTalentNotes && (
          <TalentNotes
            modalState={openTalentNotes}
            handleModal={() => {
              setActiveScheduleItem();
              setOpenTalentNotes(false);
            }}
            activeScheduleItem={activeScheduleItem}
            handleCompleteTalentNotes={props.handleCompleteTalentNotes}
            setSnackBarProps={props.setSnackBarProps}
          />
        )}
        {talentModalState && activeScheduleItem && (
          <TalentContract
            modalState={talentModalState}
            handleModal={handleTalentModal}
            projectTag={props.projectTag}
            projectid={props.projectid}
            scheduleItem={activeScheduleItem}
            setActiveScheduleItem={setActiveScheduleItem}
            handleComplete={handleCompleteTalentContract}
            editScheduleItem={props.editScheduleItem}
            parent="scheduleItemTable"
            confirmAucklandStandard={confirmAucklandStandard}
          />
        )}
        {aucklandStandardModalState && (
          <AucklandStandardContract
            scheduleItem={activeScheduleItem}
            modalState={aucklandStandardModalState}
            handleModal={handleAucklandStandardModal}
          />
        )}
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <StyledTableCell className={`responsive-schedule-item-header`}>
                  Budget Account
                </StyledTableCell>
                <StyledTableCell
                  className={`responsive-schedule-item-header`}
                  style={{
                    width: 130,
                  }}
                >
                  Role
                </StyledTableCell>
                <StyledTableCell className={`responsive-schedule-item-header`}>
                  Who
                </StyledTableCell>
                {props.providerType === "Contractor" && (
                  <StyledTableCell></StyledTableCell>
                )}
                <StyledTableCell
                  className={`responsive-schedule-item-header`}
                  align="left"
                // style={{
                //   width: 60,
                // }}
                >
                  {props.providerType === "Contractor" ||
                    props.providerType === "Talent"
                    ? "Fee"
                    : ""}
                </StyledTableCell>
                <StyledTableCell
                  className={`responsive-schedule-item-header`}
                  align="center"
                >
                  Start Time
                </StyledTableCell>
                <StyledTableCell
                  className={`responsive-schedule-item-header`}
                  align="left"
                >
                  End Time
                </StyledTableCell>
                <StyledTableCell
                  className={`responsive-schedule-item-header`}
                  align="left"
                ></StyledTableCell>
                <StyledTableCell
                  className={`responsive-schedule-item-header`}
                  align="left"
                ></StyledTableCell>
                <StyledTableCell
                  className={`responsive-schedule-item-header`}
                  align="left"
                ></StyledTableCell>
                <StyledTableCell
                  className={`responsive-schedule-item-header`}
                  align="right"
                >
                  Schedule
                </StyledTableCell>
                <StyledTableCell
                  className={`responsive-schedule-item-header`}
                  align="left"
                ></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.scheduleItems.map((scheduleItem, index) => (
                <Slide
                  key={scheduleItem.id}
                  direction={
                    index % 4 === 0
                      ? "up"
                      : index % 3 === 0
                        ? "right"
                        : index % 2 === 0
                          ? "down"
                          : "left"
                  }
                  in={true}
                  mountOnEnter
                  unmountOnExit
                >
                  <StyledTableRow key={scheduleItem.id} hover role="checkbox">
                    <TableCell padding="none">
                      <HtmlTooltip
                        title={
                          scheduleItem.category !== null && (
                            <div>
                              <Typography color="inherit">
                                {scheduleItem.category}
                              </Typography>
                            </div>
                          )
                        }
                        interactive
                        arrow
                        placement="left"
                      >
                        <Typography
                          variant="body1"
                          style={{
                            // width: "99%",
                            maxWidth: 200,
                            margin: 10,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                          className={"budget-account-cell-body"}
                        >
                          {scheduleItem.category}
                        </Typography>
                      </HtmlTooltip>
                    </TableCell>
                    <TableCell padding="none">
                      <Typography
                        variant="body1"
                        style={{
                          width: "99%",
                          margin: 10,
                        }}
                        className={"role-cell-body"}
                      >
                        {scheduleItem.roleFkNavigation !== null
                          ? scheduleItem.roleFkNavigation.fieldRole
                          : null}
                      </Typography>
                    </TableCell>
                    <TableCell
                      padding="none"
                      onContextMenu={(event) => {
                        setCurrentScheduleItemId(scheduleItem.id);
                        handleContextClick(event, index, scheduleItem);
                      }}
                    >
                      <ProviderTableCell
                        scheduleItem={scheduleItem}
                        providerType={props.providerType}
                        setCurrentProvider={setCurrentProvider}
                        setCurrentScheduleItemId={setCurrentScheduleItemId}
                        setOpenContactDetailsPopUp={setOpenContactDetailsPopUp}
                        handleAgentContextClick={handleAgentContextClick}
                      />
                      {rightClickOpen &&
                        currentScheduleItemId === scheduleItem.id ? (
                        <RightClickMenu
                          state={state}
                          scheduleItem={scheduleItem}
                          handleCloseContext={handleCloseContext}
                          setSnackBarProps={props.setSnackBarProps}
                        />
                      ) : (
                        ""
                      )}
                      {rightClickAgentOpen &&
                        currentScheduleItemId === scheduleItem.id ? (
                        <RightClickAgentMenu
                          state={state}
                          scheduleItem={scheduleItem}
                          handleCloseContext={handleCloseContext}
                          handleCloseAgentContext={handleCloseAgentContext}
                          setSnackBarProps={props.setSnackBarProps}
                        />
                      ) : (
                        ""
                      )}
                    </TableCell>
                    {props.providerType === "Contractor" && scheduleItem.category !== "Unpaid" && (
                      <TableCell>
                        <AddEquipmentAndTravel
                          scheduleItem={scheduleItem}
                          projectId={props.project.id}
                          scheduleId={props.currentSchedule.id}
                          lineIndex={lineIndex}
                          compIndex={index}
                          parent={"schedule"}
                        />
                      </TableCell>
                    )}
                    {props.providerType === "Contractor" && scheduleItem.category === "Unpaid" && (
                      <TableCell>
                        <TravelReimbursmentOnlyButton
                          scheduleItem={scheduleItem}
                          projectId={props.project.id}
                          scheduleId={props.currentSchedule.id}
                        />
                      </TableCell>
                    )}
                    <TableCell
                      padding="none"
                      align="center"
                      onClick={
                        scheduleItem.equipmentHire != null &&
                          scheduleItem.category.toLowerCase().trim() !==
                          "unpaid" &&
                          (scheduleItem.contractStatus == "Send" ||
                            scheduleItem.contractStatus == "Disabled")
                          ? () => setlineIndex(index)
                          : ""
                      }
                    >
                      {props.providerType === "Contractor" ||
                        props.providerType === "Talent" ? (
                        <div
                          style={{ display: "flex" }}
                          className={"fee-responsive"}
                        >
                          {scheduleItem.equipmentHire != null ? (
                            <HtmlTooltip
                              title={
                                <Typography color="inherit">
                                  Fee locked due to Equipment Hire. Click here
                                  to open popup
                                </Typography>
                              }
                              interactive
                              arrow
                              placement="left"
                            >
                              <TextField
                                padding="none"
                                id={`standard-basic-sit ${scheduleItem.id}`}
                                disabled={
                                  !(
                                    scheduleItem.contractStatus == "Send" ||
                                    scheduleItem.contractStatus == "Disabled"
                                  ) ||
                                    scheduleItem.category.toLowerCase().trim() ==
                                    "unpaid" ||
                                    scheduleItem.equipmentHire != null
                                    ? true
                                    : false
                                }
                                value={
                                  scheduleItem.equipmentHire
                                    ? scheduleItem.allocatedRates +
                                    Number(scheduleItem.equipmentHire.rate)
                                    : scheduleItem.allocatedRates
                                }
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      $
                                    </InputAdornment>
                                  ),
                                }}
                                style={{
                                  width: 60,
                                  marginLeft: "5px",
                                  marginRight: "5px",
                                }}
                                onChange={(e) =>
                                  props.editScheduleItem(
                                    scheduleItem,
                                    props.providerType === "Contractor"
                                      ? "contractor"
                                      : "talent",
                                    "allocatedRates",
                                    e.target.value,
                                    1, user?.email
                                  )
                                }
                                onBlur={(e) => {
                                  props.getBudget(props.project.id);
                                }}
                              />
                            </HtmlTooltip>
                          ) : (
                            <TextField
                              padding="none"
                              id={`standard-basic-sit ${scheduleItem.id}`}
                              disabled={
                                !(
                                  scheduleItem.contractStatus == "Send" ||
                                  scheduleItem.contractStatus == "Disabled"
                                ) ||
                                  scheduleItem.category.toLowerCase().trim() ==
                                  "unpaid" ||
                                  scheduleItem.equipmentHire != null
                                  ? true
                                  : false
                              }
                              value={
                                scheduleItem.equipmentHire
                                  ? scheduleItem.allocatedRates +
                                  Number(scheduleItem.equipmentHire.rate)
                                  : scheduleItem.allocatedRates
                              }
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    $
                                  </InputAdornment>
                                ),
                              }}
                              style={{
                                width: 60,
                                marginLeft: "5px",
                                marginRight: "5px",
                              }}
                              onChange={(e) =>
                                props.editScheduleItem(
                                  scheduleItem,
                                  props.providerType === "Contractor"
                                    ? "contractor"
                                    : "talent",
                                  "allocatedRates",
                                  e.target.value,
                                  1, user?.email
                                )
                              }
                              onBlur={(e) => {
                                props.getBudget(props.project.id);
                              }}
                            />
                          )}

                          {props.epTalent &&
                            scheduleItem.allocatedRates <= props.epTalent &&
                            scheduleItem.category.toLowerCase().trim() !=
                            "unpaid" ? (
                            <OfflineBoltIcon className="OfflineBoltIcon-icon" />
                          ) : (
                            ""
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                    </TableCell>
                    <TableCell
                      padding="none"
                      align="center"
                      style={{ width: "auto" }}
                    >
                      <div
                        className={
                          props.providerType === "Talent"
                            ? "talent-start-time-picker"
                            : "start-time-picker"
                        }
                      >
                        <KeyboardTimePicker
                          // id="start-time-picker"
                          value={scheduleItem.startTime}
                          onChange={(date, value) => {
                            if (date instanceof Date && !isNaN(date)) {
                              var firstStartTime = getFirstStartTime(
                                props.currentSchedule
                              );
                              var scheduleDateString = format(
                                firstStartTime,
                                "yyyy-MM-dd"
                              );
                              var newDateTime = new Date(
                                `${scheduleDateString} ${getTimePortion(date)}`
                              );

                              if (
                                firstStartTime !== null &&
                                newDateTime.getTime() < firstStartTime.getTime()
                              ) {
                                setTimeErrorText(
                                  `Invalid Time. That is before the first location. We have placed it at the earliest start time: ${format(
                                    firstStartTime,
                                    "hh:mm a"
                                  )}`
                                );

                                if (
                                  getTimePortion(
                                    new Date(scheduleItem.startTime)
                                  ) === getTimePortion(firstStartTime)
                                ) {
                                  setTimeErrorText(
                                    `Invalid Time. This item is already at the earliest possible time: ${format(
                                      firstStartTime,
                                      "hh:mm a"
                                    )}, please choose something later`
                                  );
                                } else {
                                  props.editScheduleItem(
                                    scheduleItem,
                                    props.providerType === "Contractor"
                                      ? "contractor"
                                      : "talent",
                                    "startTime",
                                    format(firstStartTime, "HH:mm:ss"),
                                    1, user?.email
                                  );
                                }
                                setShowTimeError(true);
                              } else {
                                props.editScheduleItem(
                                  scheduleItem,
                                  props.providerType === "Contractor"
                                    ? "contractor"
                                    : "talent",
                                  "startTime",
                                  format(date, "HH:mm:ss"),
                                  1, user?.email
                                );
                              }
                            }
                          }}
                          KeyboardButtonProps={{
                            "aria-label": "start time",
                            style: {
                              ...{
                                padding: "0px",
                                margin: "0px",
                              },
                            },
                          }}
                          className={`${classes.margin} time-picker-width ${props.providerType === "Talent"
                            ? "talent-time-picker-width"
                            : ""
                            }`}
                          keyboardIcon={<AccessTime size="small" />}
                          disabled={
                            !(
                              scheduleItem.contractStatus == "Send" ||
                              scheduleItem.contractStatus == "Disabled"
                            )
                          }
                        />
                      </div>
                    </TableCell>
                    <TableCell padding="none" align="left">
                      <div
                        className={
                          props.providerType === "Talent"
                            ? "talent-end-time-picker"
                            : "end-time-picker"
                        }
                      >
                        <KeyboardTimePicker
                          // id="end-time-picker"
                          onChange={(date, value) => {
                            if (date instanceof Date && !isNaN(date)) {
                              var lastEndTime = getLastEndTime(
                                props.currentSchedule
                              );
                              var scheduleDateString = format(
                                lastEndTime,
                                "yyyy-MM-dd"
                              );
                              var newDateTime = new Date(
                                `${scheduleDateString} ${getTimePortion(date)}`
                              );

                              if (
                                lastEndTime !== null &&
                                newDateTime.getTime() > lastEndTime.getTime()
                              ) {
                                setTimeErrorText(
                                  `Invalid Time. That is after the last location. We have placed it at the latest possible time: ${format(
                                    lastEndTime,
                                    "hh:mm a"
                                  )}`
                                );
                                if (
                                  getTimePortion(
                                    new Date(scheduleItem.endTime)
                                  ) === getTimePortion(lastEndTime)
                                ) {
                                  setTimeErrorText(
                                    `Invalid Time. This item is already at the latest possible time: ${format(
                                      lastEndTime,
                                      "hh:mm a"
                                    )}, please choose something earlier`
                                  );
                                }
                                props.editScheduleItem(
                                  scheduleItem,
                                  props.providerType === "Contractor"
                                    ? "contractor"
                                    : "talent",
                                  "endTime",
                                  format(lastEndTime, "HH:mm:ss"),
                                  1, user?.email
                                );
                                setShowTimeError(true);
                              } else {
                                props.editScheduleItem(
                                  scheduleItem,
                                  props.providerType === "Contractor"
                                    ? "contractor"
                                    : "talent",
                                  "endTime",
                                  format(date, "HH:mm:ss"),
                                  1, user?.email
                                );
                              }
                            }
                          }}
                          value={scheduleItem.endTime}
                          KeyboardButtonProps={{
                            "aria-label": "end time",
                            style: {
                              ...{
                                padding: "0px",
                                margin: "0px",
                              },
                            },
                          }}
                          keyboardIcon={<AccessTime size="small" />}
                          className={`${classes.margin} time-picker-width ${props.providerType === "Talent"
                            ? "talent-time-picker-width"
                            : ""
                            }`}
                          disabled={
                            !(
                              scheduleItem.contractStatus == "Send" ||
                              scheduleItem.contractStatus == "Disabled"
                            )
                          }
                        />
                      </div>
                    </TableCell>
                    <TableCell padding="none" align="left">
                      {props.providerType === "Talent" && (
                        <TalentSwitchPanel
                          editScheduleItem={props.editScheduleItem}
                          scheduleItem={scheduleItem}
                          handleTalentModal={handleTalentModal}
                          setActiveScheduleItem={setActiveScheduleItem}
                          handleAucklandStandardModal={() =>
                            handleAucklandStandardModal()
                          }
                        />
                      )}
                    </TableCell>
                    <TableCell padding="none" align="left">
                      {props.providerType === "Talent" && (
                        <Button
                          variant="contained"
                          size="small"
                          color="primary"
                          className={
                            scheduleItem.talentNotes === null
                              ? "talent-notes-incomplete"
                              : "talent-notes-completed"
                          }
                          onClick={() => {
                            setActiveScheduleItem(scheduleItem);
                            setOpenTalentNotes(true);
                          }}
                        >
                          Notes
                        </Button>
                      )}
                    </TableCell>
                    <TableCell padding="none" align="center">
                      {scheduleItem.contractStatus === "Invoiced" && scheduleItem.talentContractType === "Restricted" &&
                        scheduleItem.personFkNavigation !== null && scheduleItem.personFkNavigation.agentFk !== null ? (
                        <RolloverButton
                          providerType={props.providerType}
                          si={scheduleItem}
                          color="primary"
                          disabled={props.project.isArchived === 1}
                          projectId={props.project.id}
                          projectName={props.project.projectName}
                        />
                      ) : (
                        <ContractButton
                          providerType={props.providerType}
                          si={scheduleItem}
                          color="primary"
                          disabled={props.project.isArchived === 1}
                        />
                      )}
                    </TableCell>
                    <TableCell padding="none" align="center">
                      <div className="schedule-sent-column">
                        {scheduleItem.scheduleSentTime !== null ? (
                          <CheckCircleIcon className="schedule-sent-icon" />
                        ) : (
                          <NotInterestedIcon className="schedule-not-sent-icon" />
                        )}
                      </div>
                    </TableCell>
                    <TableCell padding="none" align="left">
                      <StyledButton disabled={props.project.isArchived === 1}>
                        <DeleteIcon
                          onClick={() => {
                            handleDeletePathway(scheduleItem);
                            setActiveScheduleItem(scheduleItem);
                          }}
                          style={{ color: "rgba(0, 0, 0, 0.54)" }}
                        />
                      </StyledButton>
                    </TableCell>
                  </StyledTableRow>
                </Slide>
              ))}
            </TableBody>
          </Table>
          {props.snackbar.open && (
            <Snacky
              snackprops={props.snackbar}
              setSnackBarProps={setSnackBarProps}
            />
          )}
        </MuiPickersUtilsProvider>
      </StyledPaper>
    );
  } else {
    return <div></div>;
  }
};

const mapStateToProps = (state, ownProps) => ({
  snackbar: state.snackbar,
  currentSchedule: state.schedules.schedule,
});
export default connect(mapStateToProps, {
  setSnackBarProps,
  editScheduleItem,
  setDialogProps,
})(ScheduleItemsTable);
