import React, { useState } from "react";
import "./customPayableTableRowDetails.scss";
import {
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";
import { isNumeric } from "../../../../utils/GeneralUtils";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DeleteIcon from '@material-ui/icons/Delete';
import { format } from "date-fns";
import { Autocomplete } from "@material-ui/lab";
import XeroContactAutocomplete from "../../../Shared/XeroContactAutocomplete/XeroContactAutocomplete";
import CloseIcon from "@material-ui/icons/Close";
const CustomPayableTableRowDetails = ({
  payable,
  handleOnChange,
  handleSavePayable,
  companies,
  hasChanges,
  accountCodes,
  handleRemovePayable,
}) => {
  const [loadingIframe, setLoadingIframe] = useState(true);
  const [isEditingSupplier, setIsEditingSupplier] = useState(false);

  const renderCompaniesByOrganisation = () => {
    if (companies?.length > 0) {
      if (payable?.organisationId !== 1) {
        return companies.filter((c) => c.organisationFk !== 1);
      } else {
        return companies.filter((c) => c.organisationFk === 1);
      }
    }
  };

  const handleChangeXeroContact = (xeroContact) => {
    handleOnChange("platform", xeroContact);
    setIsEditingSupplier(false);
  };

  const GstRateCodeCheckbox = () => {
    return (
      <div className="textfield-padding" style={{ justifyContent: "center" }}>
        <FormControlLabel
          control={
            <Checkbox
              name="gst-checkbox"
              color="primary"
              checked={payable?.gstRateCode === "INPUT2"}
              onChange={(e) => {
                handleOnChange("gstRateCode", "INPUT2");
              }}
            />
          }
          label="GST Inc"
        />
        <FormControlLabel
          control={
            <Checkbox
              name="no-gst-checkbox"
              color="primary"
              checked={payable?.gstRateCode === "NONE"}
              onChange={(e) => {
                handleOnChange("gstRateCode", "NONE");
              }}
            />
          }
          label="No GST"
        />
      </div>
    );
  };

  return (
    <div className="custom-payable-table-row-details-root">
      <div className="custom-payable-table-row-details-remove-container">
        <IconButton
          aria-label="delete"
          onClick={handleRemovePayable}
          className="custom-payable-table-row-details-remove-txt"
        >
          <DeleteIcon />
        </IconButton>
      </div>
      <div className="custom-payable-table-row-details-fields-wrapper">
        <div className="custom-payable-table-row-details-fields-container">
          {isEditingSupplier ? (
            <XeroContactAutocomplete
              organisationId={payable?.organisationId}
              handleOnChange={handleChangeXeroContact}
              value={payable.plafform}
            />
          ) : (
            <TextField
              label="Supplier"
              size="small"
              placeholder="Supplier"
              variant="filled"
              value={payable?.platform || ""}
              onClick={(e) => setIsEditingSupplier(true)}
            />
          )}

          <TextField
            disabled={!payable.xeroId}
            label="Supplier Bank Account"
            size="small"
            placeholder="Bank Account"
            variant="filled"
            value={payable?.supplierBankAcc || ""}
            onChange={(e) => {
              handleOnChange("supplierBankAcc", e.target.value);
            }}
          />
          <TextField
            label="Amount"
            size="small"
            placeholder="Amount"
            variant="filled"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            value={payable?.amount}
            onChange={(e) =>
              isNumeric(e.target.value) || e.target.value !== ""
                ? handleOnChange("amount", e.target.value)
                : 0
            }
          />
          <Autocomplete
            options={renderCompaniesByOrganisation()}
            getOptionLabel={(option) => option.name}
            value={companies.find((c) => c.id === payable.companyId)}
            onChange={(e, newValue) => {
              handleOnChange("companyId", newValue || null);
            }}
            renderOption={(option) => {
              return (
                <li key={option.id}>
                  <Typography variant="caption">{option.name}</Typography>
                </li>
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Client"
                size="small"
                placeholder="Client"
                variant="filled"
              />
            )}
          />
          <TextField
            label="Reference"
            size="small"
            placeholder="Reference"
            variant="filled"
            value={payable?.transactionId || ""}
            onChange={(e) => {
              handleOnChange("transactionId", e.target.value);
            }}
          />
          {/* <Autocomplete
            options={accountCodes.filter(
              (a) => a.organisationFk === payable.organisationId
            )}
            getOptionLabel={(option) =>
              `${option.accountName} - ${option.accountCode}`
            }
            value={accountCodes.find(
              (c) => c.accountCode === payable.accountCode
            )}
            onChange={(e, newValue) => {
              handleOnChange("accountCode", newValue || null);
            }}
            renderOption={(option) => {
              return (
                <li key={option.id}>
                  <Typography variant="caption">
                    {`${option.accountName} - ${option.accountCode}`}
                  </Typography>
                </li>
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Account Code"
                size="small"
                placeholder="Account Code"
                variant="filled"
              />
            )}
          />

          <TextField
            disabled
            label="Tax Type"
            size="small"
            placeholder="Tax Type"
            variant="filled"
            value={payable?.gstRateCode || ""}
          /> */}

          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              format="dd/MM/yyyy"
              label={"Invoice Date"}
              inputVariant={"filled"}
              size="small"
              value={payable.transactionDate}
              onChange={(date, value) => {
                if (date instanceof Date && !isNaN(date)) {
                  handleOnChange(
                    "transactionDate",
                    format(date, "yyyy-MM-dd HH:mm:ss")
                  );
                }
              }}
              KeyboardButtonProps={{
                style: {
                  ...{
                    padding: "0px",
                    margin: "0px",
                  },
                },
              }}
            />
          </MuiPickersUtilsProvider>
          {GstRateCodeCheckbox()}
        </div>

        {hasChanges && (
          <Button
            variant="contained"
            color="primary"
            onClick={handleSavePayable}
          >
            Save
          </Button>
        )}
      </div>
      <div className="custom-payable-table-row-details-file-container">
        {payable.driveId ? (
          <iframe
            src={`https://drive.google.com/file/d/${payable.driveId}/preview`}
            className={
              "custom-payable-table-row-details-file-container-pdf-iframe "
            }
            width={"100%"}
            height={"100%"}
            onLoad={() => setLoadingIframe(false)}
            title={`document-image-${payable.driveId}`}
            allow="clipboard-read; clipboard-write"
          ></iframe>
        ) : (
          <Typography>"No PDF Attached"</Typography>
        )}
      </div>
    </div>
  );
};

export default CustomPayableTableRowDetails;
