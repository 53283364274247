import axios from "axios";
import {
  GET_PROJECTS,
  RESET_ORPHANS,
  MERGE_ORPHAN_SIBLINGS,
  MERGE_ORPHANS_TO_REAL_PROJECT_ON_DROP,
  COPY_PROJECT,
  SPLICE_PROJECT,
  UPDATE_REAL_PROJECT_PROGRESS,
  UPDATE_SHELL_PROJECT_PROGRESS,
  RETURN_OUTPUT,
  RETURN_SHELL_OUTPUTS_TO_OWNER,
  SWAP_PROJECTS,
  MERGE_OUTPUTS_TO_SHELL_PROJECT_ONSHELL_PROJECT_DROP,
  RENDER_ORPHAN_SHELLS,
  SPLICE_ONE_FROM_SHELL,
  SPLICE_ONE_FROM_REAL,
  RESET_PROJECTS_LOADING,
  RESORT_PROJECTS,
  RENDER_ORPHAN_OUTPUTS,
} from "./types";
//Get outputs
export const getProjects = () => async (dispatch) => {
  try {
    const res = await axios.get("Projects/GetProjectsForBoard");

    dispatch({
      type: GET_PROJECTS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: "PROJECTS_ERROR",
      payload: err,
    });
  }
};
export const renderOrphanShells = (outputs) => async (dispatch) => {
  try {
    dispatch({
      type: RENDER_ORPHAN_SHELLS,
      payload: { outputs },
    });
  } catch (err) {
    console.log(err);
  }
};

export const renderOrphanOutputs = (outputs) => async (dispatch) => {
  try {
    dispatch({
      type: RENDER_ORPHAN_OUTPUTS,
      payload: { outputs },
    });
  } catch (err) {
    console.log(err);
  }
};

export const mergeOrphanSiblings = (parentProject, output) => async (
  dispatch
) => {
  try {
    dispatch({
      type: MERGE_ORPHAN_SIBLINGS,
      payload: { parentProject, output },
    });
  } catch (err) {
    console.log(err);
  }
};

export const copyProject = (
  ownerProject,
  parentProject,
  output,
  source,
  destination
) => async (dispatch) => {
  try {
    dispatch({
      type: COPY_PROJECT,
      payload: {
        ownerProject,
        parentProject,
        output,
        source,
        destination,
      },
    });
  } catch (err) {
    console.log(err);
  }
};
export const spliceProject = (parentProject) => async (dispatch) => {
  try {
    dispatch({
      type: SPLICE_PROJECT,
      payload: { parentProject },
    });
  } catch (err) {
    console.log(err);
  }
};
export const returnOutput = (ownerProject, parentProject, output) => async (
  dispatch
) => {
  try {
    dispatch({
      type: RETURN_OUTPUT,
      payload: { ownerProject, parentProject, output },
    });
  } catch (err) {
    console.log(err);
  }
};

export const updateRealProjectProgress = (
  destination,
  source,
  draggableId
) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_REAL_PROJECT_PROGRESS,
      payload: {
        destination,
        source,
        draggableId,
      },
    });
  } catch (err) {
    console.log(err);
  }
};

export const updateShellProjectProgress = (
  destination,
  source,
  draggableId,
  shellToSplice
) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_SHELL_PROJECT_PROGRESS,
      payload: {
        destination,
        source,
        draggableId,
        shellToSplice,
      },
    });
  } catch (err) {
    console.log(err);
  }
};

export const returnShellOutputsToOwner = (ownerProject, source) => async (
  dispatch
) => {
  try {
    dispatch({
      type: RETURN_SHELL_OUTPUTS_TO_OWNER,
      payload: { ownerProject, source },
    });
  } catch (err) {
    console.log(err);
  }
};

export const swapProjects = (
  parentShell,
  ownerId,
  source,
  ownerProgressCode
) => async (dispatch) => {
  try {
    dispatch({
      type: SWAP_PROJECTS,
      payload: { parentShell, ownerId, source, ownerProgressCode },
    });
  } catch (err) {
    console.log(err);
  }
};

export const mergeOrphansToRealProjectOnDrop = (
  destination,
  source,
  draggableId,
  children
) => async (dispatch) => {
  try {
    dispatch({
      type: MERGE_ORPHANS_TO_REAL_PROJECT_ON_DROP,
      payload: { destination, source, draggableId, children },
    });
  } catch (err) {
    console.log(err);
  }
};

export const mergeOutputsToShellProjectOnShellProjectDrop = (
  destination,
  source,
  draggableId,
  children
) => async (dispatch) => {
  try {
    dispatch({
      type: MERGE_OUTPUTS_TO_SHELL_PROJECT_ONSHELL_PROJECT_DROP,
      payload: { destination, source, draggableId, children },
    });
  } catch (err) {
    console.log(err);
  }
};

export const spliceOneFromShell = (shellProject, output, shellIndex) => async (
  dispatch
) => {
  try {
    dispatch({
      type: SPLICE_ONE_FROM_SHELL,
      payload: { shellProject, output, shellIndex },
    });
  } catch (err) {
    console.log(err);
  }
};

export const spliceOneFromReal = (project, output) => async (dispatch) => {
  try {
    dispatch({
      type: SPLICE_ONE_FROM_REAL,
      payload: { project, output },
    });
  } catch (err) {
    console.log(err);
  }
};

export const resetProjectLoading = () => async (dispatch) => {
  try {
    dispatch({
      type: RESET_PROJECTS_LOADING,
      payload: {},
    });
  } catch (err) {
    console.log(err);
  }
};

export const resortProjects = (destinationColumn) => async (dispatch) => {
  try {
    dispatch({
      type: RESORT_PROJECTS,
      payload: { destinationColumn },
    });
  } catch (err) {
    console.log(err);
  }
};

export const resetOrphans = () => async (dispatch) => {
  try {
    dispatch({
      type: RESET_ORPHANS,
    });
  } catch (err) {
    console.log(err);
  }
};
