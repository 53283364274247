import React, { useState, useRef, useEffect } from "react";
import { TextField, Grid, Typography } from "@material-ui/core";

import Autocomplete from "@material-ui/lab/Autocomplete";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { makeStyles } from "@material-ui/core/styles";
import parse from "autosuggest-highlight/parse";
import throttle from "lodash/throttle";

import { geocodeByAddress, getLatLng } from "react-places-autocomplete";

// function loadScript(src, position, id) {
//   if (!position) {
//     return;
//   }

//   const script = document.createElement("script");
//   script.setAttribute("async", "");
//   script.setAttribute("id", id);
//   script.src = src;
//   position.appendChild(script);
// }

const autocompleteService = { current: null };

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}));

function GooglePlaces(props) {
  const classes = useStyles();
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState();
  const [location, setLocation] = useState(
    props.stepperLocation != null ? props.stepperLocation : []
  );
  const [characters, setCharacters] = useState(0);
  const loaded = useRef(false);

  // if (typeof window !== "undefined" && !loaded.current) {
  //   if (!document.querySelector("#google-maps")) {
  //     console.log("google maps script loaded");
  //     loadScript(
  //       "https://maps.googleapis.com/maps/api/js?key=AIzaSyCLFxyCAH7WvG0NmYibctDA_dUx4stGM_s&libraries=places",
  //       document.querySelector("head"),
  //       "google-maps"
  //     );
  //   }

  //   loaded.current = true;
  // }

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  const changeLocation = async (obj, value) => {
    if (value !== null) {
      const results = await geocodeByAddress(value.description);
      const latLng = await getLatLng(results[0]);
      props.handleLocationChange({
        address: value.description,
        latlng: latLng,
      });
      props.setStepperLocation(value.description);
    }
  };

  const fetch = React.useMemo(
    () =>
      throttle((input, callback) => {
        autocompleteService.current.getPlacePredictions(input, callback);
      }, 200),
    []
  );

  useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions([]);
      return undefined;
    }

    // {inputValue.length === 1 || options.length > 4 ?
    //   fetch({ input: inputValue, componentRestrictions: {country: 'nz'} }, results => {
    //     if (active) {
    //       setOptions(results || []);
    //     }
    //   }) :
    //   inputValue.length > characters ?
    //   fetch({ input: inputValue }, results => {
    //     setCharacters(inputValue.length);
    //     if (active) {
    //       setOptions(results || []);
    //     }
    //   })
    //   :
    fetch(
      { input: inputValue, componentRestrictions: { country: "nz" } },
      (results) => {
        if (active) {
          setOptions(results || []);
        }
      }
    );
    // }

    return () => {
      active = false;
    };
  }, [inputValue, fetch]);

  return (
    <Autocomplete
      id="google-map-demo"
      style={{ width: "100%" }}
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option.description
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      value={location}
      onChange={changeLocation}
      includeInputInList
      freeSolo
      disableOpenOnFocus
      renderInput={(params) => (
        <TextField
          {...params}
          label="Add a location"
          variant="outlined"
          fullWidth
          onChange={handleChange}
        />
      )}
      renderOption={(option) => {
        const matches =
          option.structured_formatting.main_text_matched_substrings;
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match) => [match.offset, match.offset + match.length])
        );

        return (
          <Grid container alignItems="center">
            <Grid item>
              <LocationOnIcon className={classes.icon} />
            </Grid>
            <Grid item xs>
              {parts.map((part, index) => (
                <span
                  key={index}
                  style={{ fontWeight: part.highlight ? 700 : 400 }}
                >
                  {part.text}
                </span>
              ))}

              <Typography variant="body2" color="textSecondary">
                {option.structured_formatting.secondary_text}
              </Typography>
            </Grid>
          </Grid>
        );
      }}
    />
  );
}

export default GooglePlaces;
