import React, { Fragment } from "react";
import { Tooltip, Switch } from "@material-ui/core";
import { connect } from "react-redux";
import { getBudget } from "../../../../actions/budget";
import "./scheduleLocationStyles.css";
import { useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: 12,
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const LocationSwitch = (props) => {
  const handleSwitch = (e) => {
    if (!e.target.checked) {
      if (props.sl.contractStatus !== "Send") {
        props.setDialogProps({
          titleText: "Switch to free?",
          contentText: "This will cancel any outstanding contracts",
          buttonOneText: "Switch",
          buttonTwoText: "Cancel",
          show: true,
        });
      } else {
        props.editScheduleLocation(props.schedule, props.sl, "fee", null);
      }
    } else {
      props.editScheduleLocation(props.schedule, props.sl, "fee", 0);
    }
  };

  return (
    <HtmlTooltip
      placement="top"
      disableHoverListener={props.sl.contractStatus !== "Return"}
      title={
        "This is a return location - please send all contracts etc via the first."
      }
    >
      <div
        style={{
          display: "flex",
          marginLeft: "0",
          marginRight: "0",
          // border: "1px solid #c8c8c8",
          // borderRadius: "5px",
          padding: "0px 5px 5px 5px",
          width: "100%",
          justifyContent: "center",
          height: "max-content"
        }}
      >
        <div
          style={
            props.sl.fee === null
              ? {
                  borderBottom: "solid",
                  borderColor: "#3f51b5",
                  padding: "4px",
                  fontSize: "14px",
                  marginTop: "4px",
                }
              : {
                  padding: "4px",
                  fontSize: "14px",
                  marginTop: "4px",
                }
          }
        >
          Free Location
        </div>

        <Switch
          disabled={
            (props.sl.locationFkNavigation.primaryContactNavigation === null ||
            props.sl.locationFkNavigation.primaryContactNavigation.id ===
              null ||
            props.sl.contractStatus === "Return"
              ? true
              : !props.budgetLoading
              ? props.sl.contractStatus === "Invoiced"
                ? true
                : false
              : false)
              || (props.project.isArchived === 1)
          }
          color="primary"
          className={"blueSwitch"}
          name="feeSwitch"
          checked={props.sl.fee !== null}
          inputProps={{ "aria-label": "primary checkbox" }}
          onClick={(e) => {
            if( props.budget.filter((bi) => bi.category === "Location Fees").length === 0 ){
              props.setShowNoLocationFeeWarning(true)
            } else {
              handleSwitch(e);
            }
          }}
          // margintop="4px"
        />
        <div
          style={
            props.sl.fee !== null
              ? {
                  borderBottom: "solid",
                  borderColor: "#3f51b5",
                  padding: "4px",
                  fontSize: "14px",
                  marginTop: "4px",
                }
              : {
                  padding: "4px",
                  fontSize: "14px",
                  marginTop: "4px",
                }
          }
        >
          Paid Location
        </div>
        {props.locationBudget === 0 ? (
          <div
            style={{
              padding: "4px",
              fontSize: "14px",
              marginTop: "4px",
            }}
          >
            Location Budget is Zero.
          </div>
        ) : (
          <div style={{ display: "none" }}></div>
        )}
      </div>
    </HtmlTooltip>
  );
};

const mapStateToProps = (state, ownProps) => ({
  project: state.schedules.project,
  budget: state.budget.budget,
  budgetLoading: state.budget.budgetLoading,
});
// https://react-redux.js.org/api/connect
export default connect(mapStateToProps, {
  getBudget,
})(LocationSwitch);
