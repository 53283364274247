import React from "react";
import { Redirect, Route, useLocation } from "react-router-dom";
import { useAuth } from "../../context/auth/AuthContext";

const AuthenticatedRoute = (props) => {
  const { isAuthenticated, isLoadingUser } = useAuth();

  const { path, component: Component } = props;

  return (
    <Route
      exact
      path={path}
      render={(props) =>
        isAuthenticated && !isLoadingUser ? (
          <Component {...props} />
        ) : isLoadingUser ? (
          <></>
        ) : (
          window.open(
            `${process.env.REACT_APP_IDENTITY_HUB_URL}/login?redirect=${window.location.origin}${window.location.pathname}`,
            "_self"
          )
        )
      }
    />
  );
};

export default AuthenticatedRoute;
