import React, { useEffect, useState } from "react";
import "./campaignInfluencersCard.scss";
import InfluencerBasicDetails from "../../InfluencersNetwork/InfluencerBasicDetails";
import { Button, Paper, Typography } from "@material-ui/core";
import InfluencerOutreachDetails from "./InfluencerOutreachDetails";
import { calculateRemainingBudget } from "../../../utils/BudgetItemsUtils";
import AddToCampaignButton from "./AddToCampaignButton";
const CampaignInfluencersCard = ({
  scheduleItems,
  handleEditInfluencer,
  handleSaveInfluencer,
  budgetItems,
  handleSaveScheduleItem,
  setSnackBarProps,
  deleteScheduleItem,
  handleAddToCampaign,
  project,
  handleRefreshScheduleItemList,
}) => {
  const [selectedInfluencer, setSelectedInfluencer] = useState(null);
  const [loadLastInfluencer, setLoadLastInfluencer] = useState(false);

  useEffect(() => {
    if (scheduleItems?.length > 0) {
      setSelectedInfluencer(scheduleItems[0]);
    }
  }, []);

  useEffect(() => {
    if (scheduleItems?.length > 0 && loadLastInfluencer) {
      setSelectedInfluencer(scheduleItems[scheduleItems.length - 1]);
    }
  }, [scheduleItems]);

  const handleSelectInfluencer = (e, influencer) => {
    e.preventDefault();
    e.stopPropagation();
    setSelectedInfluencer(influencer);
  };

  const calculateRemainigInfluencerSpend = async () => {
    return await calculateRemainingBudget(
      budgetItems || [],
      scheduleItems,
      "Influencer Spend"
    );
  };

  return (
    <div className="campaign-influencers-card-root">
      <Paper>
        <div className="campaign-influencers-card-list-container ">
          {scheduleItems.map((influencer, index) => (
            <div
              key={index}
              className={`campaign-influencers-card-list-item ${
                selectedInfluencer?.id === influencer.id
                  ? "campaign-influencer-card-selected-record"
                  : ""
              }`}
              onClick={(e) => handleSelectInfluencer(e, influencer)}
            >
              <InfluencerBasicDetails
                influencer={influencer?.personFkNavigation}
                handleEditInfluencer={handleEditInfluencer}
                handleSaveInfluencer={handleSaveInfluencer}
                displayIndustry={false}
              />
            </div>
          ))}
          <div className="campaign-influencers-card-add-new-container">
            <AddToCampaignButton handleAddToCampaign={handleAddToCampaign} />
          </div>
        </div>
      </Paper>

      <Paper>
        {selectedInfluencer ? (
          <div className="campaing-influencers-card-influencer-details">
            <InfluencerOutreachDetails
              influencer={selectedInfluencer}
              budgetItems={budgetItems}
              handleEditInfluencer={handleEditInfluencer}
              handleSaveInfluencer={handleSaveInfluencer}
              handleSaveScheduleItem={handleSaveScheduleItem}
              setSnackBarProps={setSnackBarProps}
              deleteScheduleItem={deleteScheduleItem}
              calculateRemainigInfluencerSpend={
                calculateRemainigInfluencerSpend
              }
              project={project}
              handleRefreshScheduleItemList={handleRefreshScheduleItemList}
            />
          </div>
        ) : (
          <div className="campaing-influencers-card-no-record">
            <Typography variant="h6">
              {" "}
              Select the influencer to see the details...
            </Typography>
          </div>
        )}
      </Paper>
    </div>
  );
};

export default CampaignInfluencersCard;
