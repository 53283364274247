import React, { useEffect, useState } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    TextField,
    Button,
    IconButton,
    Typography,
    AppBar,
    Toolbar,
    InputAdornment,
    FormControlLabel,
    Checkbox,
    CircularProgress
} from "@material-ui/core";
import {
    Close as CloseIcon
} from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import axios from "axios";
import moment from "moment";
import { currencyFormat, isEmpty } from "../../../utils/GeneralUtils";
import PullFromBudgetAutocomplete from "../../Shared/BudgetItem/PullFromBudgetAutocomplete";
import { useUserContext } from "../../../context/user/UserContext";
import { useAuth } from "../../../context/auth/AuthContext";
const initialErrorState = {
    reimbursementValue: "",
    description: "",
    person: "",
    budget: ""
};

const PeopleOfInfluenceReimbursementPoup = ({
    open,
    handleClose,
    budgetItems,
    handleAferSave
}) => {
    const { user } = useAuth();
    const [influencers, setInfluencers] = useState([]);
    const [selectedInfluencer, setSelectedInfluencer] = useState(null);
    const [selectedBudget, setSelectedBudget] = useState(null);
    const [reimbursementValue, setReimbursementValue] = useState(0);
    const [description, setDescription] = useState("");
    const [gstInc, setGstInc] = useState(true);
    const [saving, setSaving] = useState(false);
    const [errorForm, setErrorForm] = useState(initialErrorState);

    const fetchInfluencers = async () => {
        try {
            const response = await axios
                .post(`/influencer/SearchInfluencers`, {
                    industries: [],
                    ageCategories: [],
                    rating: [],
                    hasPets: false,
                    hasKids: false,
                    type: ""
                }, {
                    params: {
                        pageSize: 250,
                        organisationId: 5,
                    },
                });
            setInfluencers(response.data);
        } catch (error) {
            console.error("Error fetching options:", error);
        }
    };

    const handleFeeChange = (event) => {
        var newValue = event.target.value;
        // const inputValue = newValue.replace(/\D/g, '');
        const inputValue = newValue.replace(/[^0-9.]/g, '');
        setReimbursementValue(inputValue);
    };

    const validateForm = async () => {
        let isValidForm = true;
        const tempError = { ...initialErrorState };

        if (isEmpty(selectedBudget) || isEmpty(selectedBudget?.budgetId)) {
            tempError.budget = "Please select a budget";
            isValidForm = false;
        } else {
            if (Number(reimbursementValue) > selectedBudget?.remaining) {
                tempError.budget = "Not enough budget remaining";
                isValidForm = false;
            }
        }

        if (reimbursementValue === 0) {
            tempError.reimbursementValue = "Enter an amount";
            isValidForm = false;
        }

        if (isEmpty(selectedInfluencer)) {
            tempError.person = "Please select a talent";
            isValidForm = false;
        }

        if (isEmpty(description)) {
            tempError.description = "Please type what this is for";
            isValidForm = false;
        }

        if (!isValidForm) {
            setErrorForm(tempError);
        }

        return isValidForm;
    };

    const createReimbursement = () => {
        setSaving(true);
        setErrorForm(initialErrorState);
        axios.post(`Receipts/PostPoiReimbursement`, {
            firstName: selectedInfluencer.firstName,
            lastName: selectedInfluencer.lastName,
            email: selectedInfluencer.emailAddress,
            type: "Influencer Reimbursement",
            budgetId: selectedBudget.budgetId,
            personId: selectedInfluencer.id,
            total: reimbursementValue,
            gst: gstInc ? 1 : 0,
            projectId: selectedBudget.projectId,
            accountCode: selectedBudget.accountCode,
            description: description,
            submittedByEmail: user?.email
        }).then(() => {
            handleAferSave();
            handleClose();
        }).catch(() => {

        }).finally(() => { setSaving(false) })
    }


    const handleSave = async () => {
        if (await validateForm()) {
            createReimbursement();
        }
    };

    useEffect(() => {
        fetchInfluencers();
    }, []);

    useEffect(() => {
        console.log(selectedInfluencer)
    }, [selectedInfluencer]);

    useEffect(() => {
        console.log(selectedBudget)
    }, [selectedBudget]);

    return (
        <Dialog
            open={open}
            onClose={handleClose}
        >
            <AppBar position="static" style={{ background: "#217F8B" }}>
                <Toolbar variant="dense" style={{ paddingRight: 0 }}>
                    <Typography
                        variant="subtitle1"
                        align="center"
                        style={{ flexGrow: 1 }}
                    >
                        Reimbursement
                    </Typography>
                    <span style={{ display: "flex" }}>
                        <IconButton onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </span>
                </Toolbar>
            </AppBar>
            <DialogContent>
                <Autocomplete
                    options={influencers}
                    getOptionLabel={(option) =>
                        option.firstName + " " + option.lastName
                    }
                    onChange={(e, newValue) => {
                        setSelectedInfluencer(newValue)
                    }}
                    value={selectedInfluencer}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={"Select Talent *"}
                            variant={"outlined"}
                            size="small"
                            error={errorForm.person ? true : false}
                            helperText={errorForm.person ? errorForm.person : ""}
                        />
                    )}
                    style={{ paddingBottom: 10 }}
                />
                <PullFromBudgetAutocomplete
                    options={budgetItems.filter(b => b.isHidden !== 1 && b.remaining > 0)}
                    value={selectedBudget}
                    handleOnChange={(event, newValue) => {
                        setSelectedBudget(newValue)
                    }}
                    style={{ paddingBottom: 10 }}
                />
                <div>
                    <TextField
                        label="Amount *"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">$</InputAdornment>
                            ),
                        }}
                        size="small"
                        variant="outlined"
                        value={reimbursementValue}
                        onChange={handleFeeChange}
                        style={{ paddingBottom: 10 }}
                        error={errorForm.reimbursementValue ? true : false}
                        helperText={errorForm.reimbursementValue ? errorForm.reimbursementValue : ""}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="gst-checkbox"
                                color="primary"
                                checked={gstInc}
                                onChange={(e) => { setGstInc(e.target.checked) }}
                            />
                        }
                        label="GST Inc"
                        style={{ paddingLeft: 5 }}
                    />
                </div>
                <TextField
                    label="What is this for? *"
                    size="small"
                    variant="outlined"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    fullWidth
                    multiline
                    style={{ paddingBottom: 10 }}
                    error={errorForm.description ? true : false}
                    helperText={errorForm.description ? errorForm.description : ""}
                />
            </DialogContent>
            <DialogActions style={{ display: "flex" }}>
                {saving ? <div style={{ padding: 5 }}>
                    <CircularProgress />
                </div> : (
                    <React.Fragment>
                        <Button
                            onClick={handleClose}
                            className={saving ? "" : "default-button-grey"}
                            variant="contained"
                            disabled={saving}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={handleSave}
                            className={saving ? "" : "default-button"}
                            variant="contained"
                            disabled={saving}
                        >
                            Process Reimbursement
                        </Button>
                    </React.Fragment>
                )}

            </DialogActions>
        </Dialog>
    );
}

export default PeopleOfInfluenceReimbursementPoup;