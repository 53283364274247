import React, { Fragment, useState } from "react";
import "./budgetTable.scss";
import {
  Button,
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { currencyFormat } from "../../../../utils/GeneralUtils";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
const CustomTableRow = ({
  budget,
  selectedPayable,
  handleAllocatePayableToBudget,
}) => {
  const [openAllocated, setOpenAllocated] = useState(false);

  return (
    <Fragment>
      <TableRow>
        <TableCell className="client-budget-table-icon-column">
          {budget.records?.length > 0 && (
            <IconButton aria-label="expand row" size="small">
              {openAllocated ? (
                <KeyboardArrowUpIcon
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpenAllocated(!openAllocated);
                  }}
                />
              ) : (
                <KeyboardArrowDownIcon
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpenAllocated(!openAllocated);
                  }}
                />
              )}
            </IconButton>
          )}
        </TableCell>
        <TableCell className="payable-table-budget-table-category-column">
          <Typography variant="caption">{budget.projectCategory}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="caption">
            {currencyFormat(Number(budget.remaining))}
          </Typography>
        </TableCell>
        <TableCell>
          {selectedPayable?.amount <= budget.remaining && (
            <Button
              size="small"
              variant="outlined"
              onClick={(e) => handleAllocatePayableToBudget(budget)}
            >
              <Typography variant="caption">Allocate</Typography>
            </Button>
          )}
        </TableCell>
      </TableRow>
      {budget.records?.length > 0 && openAllocated && (
        <TableRow>
          <TableCell colSpan={4}>
            <Collapse in={true} timeout="auto" unmountOnExit>
              <div className="payable-table-budget-table-sub-table">
                <div>
                  <Typography variant="caption">
                    <strong>Name</strong>
                  </Typography>
                </div>
                <div>
                  <Typography variant="caption">
                    <strong>Allocated</strong>
                  </Typography>
                </div>
              </div>
              {budget.records.map((record, index) => (
                <div
                  key={index}
                  className="payable-table-budget-table-sub-table"
                >
                  <div>
                    <Typography variant="caption">
                      {record.name} {record.status ? " - "+record.status : ""}
                    </Typography>
                  </div>
                  <div>
                    <Typography variant="caption">
                      {currencyFormat(Number(record.total))}
                    </Typography>
                  </div>
                </div>
              ))}
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </Fragment>
  );
};

const BudgetTable = ({
  budget,
  selectedPayable,
  handleAllocatePayableToBudget,
}) => {
  return (
    <div>
      <TableContainer component={Paper}>
        <Table size="small" className="payable-table-budget-table-category">
          <TableHead>
            <TableRow>
              <TableCell className="client-budget-table-icon-column"></TableCell>
              <TableCell>Project</TableCell>
              <TableCell>Remaining</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {budget.map((b, index) => (
              <CustomTableRow
                key={index}
                budget={b}
                selectedPayable={selectedPayable}
                handleAllocatePayableToBudget={handleAllocatePayableToBudget}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default BudgetTable;
