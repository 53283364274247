import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import { setSnackBarProps } from "../../../actions/snackbar";
import {
  getSchedulesForTable,
  archiveSchedule,
} from "../../../actions/schedules";

import Snacky from "../../Shared/Snacky";
import { format } from "date-fns";
import { withStyles, styled } from "@material-ui/core/styles";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContentText,
  DialogContent,
} from "@material-ui/core";
import { Delete } from "@material-ui/icons";

import Loader from "../../Loader";

const StyledPaper = styled(Paper)({
  width: "100%",
});

const StyledTableContainer = styled(TableContainer)({
  height: "calc(100vh - 142px);",
  backgroundColor: "#e6f5ff",
});
//------------------------------------------------
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: "#9E9E9E",
    textTransform: "uppercase",
    fontWeight: "bold",
  },
  body: {
    fontSize: "1em",
  },
}))(TableCell);
const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);

const StyledButton = styled(Button)({
  color: "#000000",
  borderRadius: "20%",
});

const ScheduleTable = ({
  getSchedulesForTable,
  archiveSchedule,
  schedulesForTable,
  setSnackBarProps,
  loading,
  snackbar,
  project
}) => {
  const initialDialogProps = {
    titleText: "",
    contentText: "",
    buttonOneText: "",
    buttonTwoText: "",
    show: false,
    scheduleId: null,
  };
  const [dialogProps, setDialogProps] = useState(initialDialogProps);

  const history = useHistory();
  const location = useLocation();

  const handleDeleteSchedule = (id, hasInvoices, index) => {
    if (hasInvoices) {
      setDialogProps({
        titleText: "This schedule cannot be archived",
        contentText: "It contains invoiced items",
        buttonOneText: "",
        buttonTwoText: "OK",
        show: true,
        scheduleId: null,
      });
    } else {
      setDialogProps({
        titleText: "Do you want to archive this schedule?",
        contentText: "",
        buttonOneText: "Archive",
        buttonTwoText: "Cancel",
        show: true,
        scheduleId: id,
        index: index,
      });
    }
  };

  const dialogButtonOne = () => {
    return (
      <Button
        onClick={() => {
          archiveSchedule(dialogProps.scheduleId, dialogProps.index);
          setDialogProps(initialDialogProps);
        }}
        color="primary"
      >
        {dialogProps.buttonOneText}
      </Button>
    );
  };
  const dialogButtonTwo = () => {
    return (
      <Button
        onClick={() => {
          setDialogProps(initialDialogProps);
        }}
        color="primary"
      >
        {dialogProps.buttonTwoText}
      </Button>
    );
  };

  const dialog = () => {
    return (
      <Dialog
        open={dialogProps.show}
        onClose={() => setDialogProps(initialDialogProps)}
      >
        <DialogTitle style={{ textAlign: "center" }}>
          {dialogProps.titleText}
        </DialogTitle>
        <DialogContent>
          <DialogContentText style={{ textAlign: "center" }}>
            {dialogProps.contentText}
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ textAlign: "center" }}>
          {dialogProps.buttonOneText !== "" && dialogButtonOne()}
          {dialogButtonTwo()}
        </DialogActions>
      </Dialog>
    );
  };

  const returnAddressText = (addresses) => {
    if (addresses.length === 1) {
      return addresses[0];
    } else {
      return addresses.length + " locations";
    }
  };

  const navigateToSchedule = (id) => {
    history.push(location.pathname + "/" + id + "/0");
  };

  useEffect(() => {
    getSchedulesForTable(location.pathname.split("/")[3]);
  }, []);

  if (loading) {
    return <Loader />;
  } else {
    return (
      <StyledPaper>
        {snackbar.open && (
          <Snacky snackprops={snackbar} setSnackBarProps={setSnackBarProps} />
        )}
        {dialogProps.show && dialog()}
        {schedulesForTable.length === 0 ? (
          <h3 style={{ textAlign: "center" }}>
            No schedules have been created for this project yet
          </h3>
        ) : (
          <StyledTableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Name</StyledTableCell>
                  <StyledTableCell>Address</StyledTableCell>
                  <StyledTableCell>Date</StyledTableCell>
                  <StyledTableCell>Start Time</StyledTableCell>
                  <StyledTableCell>End Time</StyledTableCell>
                  <StyledTableCell></StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {schedulesForTable.map((schedule, index) => (
                  <StyledTableRow key={schedule.scheduleID}>
                    <TableCell
                      onClick={() => navigateToSchedule(schedule.scheduleID)}
                    >
                      {schedule.scheduleName}
                    </TableCell>
                    <TableCell
                      onClick={() => navigateToSchedule(schedule.scheduleID)}
                    >
                      {returnAddressText(schedule.scheduleAddresses)}
                    </TableCell>
                    <TableCell
                      onClick={() => navigateToSchedule(schedule.scheduleID)}
                    >
                      {format(
                        new Date(schedule.scheduleDate),
                        "iiii, dd/MM/yyyy"
                      )}
                    </TableCell>
                    <TableCell
                      onClick={() => navigateToSchedule(schedule.scheduleID)}
                    >
                      {new Date(schedule.startTime).getFullYear() != "1970" &&
                        format(new Date(schedule.startTime), "hh:mm a")}
                    </TableCell>
                    <TableCell
                      onClick={() => navigateToSchedule(schedule.scheduleID)}
                    >
                      {new Date(schedule.startTime).getFullYear() != "1970" &&
                        format(new Date(schedule.finishTime), "hh:mm a")}
                    </TableCell>
                    <TableCell>
                      {project.isArchived !== 1 && (
                        <StyledButton
                          onClick={() =>
                            handleDeleteSchedule(
                              schedule.scheduleID,
                              schedule.hasInvoices,
                              index
                            )
                          }
                        >
                          <Delete style={{ color: "rgba(0, 0, 0, 0.54)" }} />
                        </StyledButton>
                      )}
                    </TableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </StyledTableContainer>
        )}
      </StyledPaper>
    );
  }
};

const mapStateToProps = (state) => ({
  schedulesForTable: state.schedules.schedulesForTable,
  loading: state.schedules.loadingSchedulesForTable,
  snackbar: state.snackbar,
  project: state.schedules.project
});
// https://react-redux.js.org/api/connect
export default connect(mapStateToProps, {
  getSchedulesForTable,
  archiveSchedule,
  setSnackBarProps,
})(ScheduleTable);
