import { combineReducers } from "redux";
import outputs from "./outputs";
import projects from "./projects";
import schedules from "./schedules";
import snackbar from "./snackbar";
import dialog from "./dialog";
import budget from "./budget";
import loader from "./loader";
import people from "./people";
import closeProject from "./closeProject";
import adminMenu from "./adminMenu";
import scheduleItems from "./scheduleItems";
import calendarOutputs from "./calendarOutputs";
import roles from "./roles";
import thompsonSpencerProject from "./thompsonSpencerProject";

export default combineReducers({
  outputs,
  loader,
  projects,
  schedules,
  snackbar,
  dialog,
  budget,
  people,
  scheduleItems,
  adminMenu,
  calendarOutputs,
  closeProject,
  thompsonSpencerProject
});
