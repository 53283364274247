import React, { useEffect, useState } from "react";
import Axios from "axios";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Tooltip,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  medium: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  large: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
}));

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    //backgroundColor: '#f5f5f9',
    //color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const providerTableCell = (contact) => {
  return (
    <HtmlTooltip
      title={
        <div onClick={(event) => event.stopPropagation}>
          <Typography color="inherit">Contact Details</Typography>
          <b>{"Phone"}</b>
          {": "} <em>{contact.phoneNumber}</em> <br />
          <b>{"Email"}</b>
          {": "} <em>{contact.emailAddress}</em>{" "}
        </div>
      }
      interactive
      arrow
      placement="left"
    >
      <Typography>
        <TextField
          value={contact.fullName}
          variant="outlined"
          fullWidth
          disabled
          size="small"
          style={{
            width: "200px", //160%
            marginLeft: "2px",
            marginRight: "2px",
          }}
          // onClick={() => {
          //   setCurrentProvider(scheduleItem.personFk);
          //   setCurrentScheduleItemId(scheduleItem.id);
          //   setOpenContactDetailsPopUp(true);
          // }}
        />
      </Typography>
    </HtmlTooltip>
  );
};

const ContactCard = (props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [contact, setContact] = React.useState({});
  const [contactId, setContactId] = useState(props.contactId);
  const [mouseX, setMouseX] = React.useState(null);
  const [mouseY, setMouseY] = React.useState(null);
  const [rightClickOpen, setRightClickOpen] = React.useState(false);

  const handleEmailClick = () => {
    setOpen(true);
    navigator.clipboard.writeText(
      !props.locationChanged ? contact.emailAddress : "No Email"
    );
  };

  const handlePhoneClick = () => {
    setOpen(true);
    navigator.clipboard.writeText(
      !props.locationChanged ? contact.phoneNumber : "No Phone"
    );
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleCloseContext = () => {
    setMouseX(null);
    setMouseY(null);
    setRightClickOpen(false);
  };
  const handleContextClick = (event) => {
    event.preventDefault();
    setMouseX(event.clientX - 2);
    setMouseY(event.clientY - 4);
    setRightClickOpen(true);
  };

  const rightClickMenu = () => {
    return (
      <Menu
        keepMounted
        open={mouseY !== null}
        onClose={handleCloseContext}
        anchorReference="anchorPosition"
        anchorPosition={
          mouseY !== null && mouseX !== null
            ? { top: mouseY, left: mouseX }
            : undefined
        }
      >
        <MenuItem
          onClick={() => {
            handlePhoneClick();
            handleCloseContext();
          }}
        >
          Copy Phone
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleEmailClick();
            handleCloseContext();
          }}
        >
          Copy Email Address
        </MenuItem>
      </Menu>
    );
  };

  useEffect(() => {
    if (contactId !== 0 && contactId !== undefined) {
      Axios.get(`People/GetPerson/${contactId}`)
        .then((res) => {
          setContact(res.data);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [props.contactId]);

  return <div>{providerTableCell(contact)}</div>;
};

export default ContactCard;
