import React, { useState } from "react";
import "./rwtTable.scss";
import { Chip, Paper, TableContainer, Typography, Button } from "@material-ui/core";
import CustomTableHead from "../../Shared/CustomTableHead";
import moment from "moment";
import { getComparator } from "../../../utils/TableUtils";
import RwtActionButton from "./RwtActionButton";
import Snacky from "../../Shared/Snacky";
import axios from 'axios';
import ConfirmationDialogWithInput from '../../Shared/ConfirmationDialogWithInput';
import { useUserContext } from '../../../context/user/UserContext';
import { useAuth } from "../../../context/auth/AuthContext";

const tableHeader = [
  {
    field: "date",
    name: "Period",
    fieldType: "date",
    alignment: "left",
  },
  {
    field: "status",
    name: "Status",
    fieldType: "string",
    alignment: "left",
  },
  {
    field: "",
    name: "",
    fieldType: "string",
    alignment: "right",
  },
];
const RwtTable = ({ records, isLoading, updateRecord }) => {
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState({
    field: "date",
    name: "Period",
    fieldType: "date",
  });
  const [snackBarProps, setSnackBarProps] = useState({
    open: false,
    severity: "success",
    text: "",
  });
  const [showConfirmSubmit, setShowConfirmSubmit] = useState(false);
  const [selectedRwt, setSelectedRwt] = useState(null);
  const { user } = useAuth();

  const handleOnRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const getStatusText = (rwt) => {
    if (rwt.status === "available") {
      return "Available";
    } else if (rwt.status === "pending") {
      var month = moment(rwt.date).add(1, 'M').format("MMMM")
      return `Pending - will be available on ${month} 7th`;
    } else if (rwt.status === "submitted") {
      return `Submitted on ${moment(rwt.submittedOn).format("DD/MM/YYYY")} by ${rwt.submittedBy}`;
    }
  }

  const handleSubmit = (input) => {
    axios.post(`RwtReport/MarkAsSubmitted/${selectedRwt.id}`, {
      submittedBy: input,
      rwtReportId: selectedRwt.id,
      user: user?.email
    })
      .then(res => {
        updateRecord(res.data);
        setSnackBarProps({
          open: true,
          severity: "success",
          text: "Marked as submitted",
        });
        setShowConfirmSubmit(false);
      }).catch(e => {
        setSnackBarProps({
          open: true,
          severity: "warning",
          text: "Unable to mark as submitted",
        });
      })
  }

  return (
    <div className="rwt-table-root-container">
      <Snacky
        snackprops={snackBarProps}
        setSnackBarProps={setSnackBarProps}
      />
      {showConfirmSubmit && (
        <ConfirmationDialogWithInput
          open={showConfirmSubmit}
          handleClose={() => {
            setShowConfirmSubmit(false);
            setSelectedRwt(null);
          }}
          handleSubmit={handleSubmit}
          submitLabel={"Confirm"}
          description={"Please type your name to confirm you have uploaded this"}
          textFieldLabel={"Name"}
          title={"Confirm RWT Report Submission"}
        />
      )}
      <TableContainer component={Paper}>
        <table className="custom-table custom-table-border custom-table-hover socialites-projects-table">
          <CustomTableHead
            headers={tableHeader}
            orderBy={orderBy}
            order={order}
            onRequestSort={handleOnRequestSort}
          />
          <tbody>
            {[...records].sort(getComparator(order, orderBy)).map((rwt) => (
              <tr key={rwt.id}>
                <td>
                  <Typography variant="caption">
                    {moment(rwt.date).format("MMMM YYYY")}
                  </Typography>
                </td>
                <td>
                  <Typography variant="caption">
                    {getStatusText(rwt)}
                  </Typography>
                </td>
                <td>
                  <div style={{ display: "flex", gap: 20}}>
                    {rwt.status !== "pending" && (
                      <Button
                      variant="contained"
                      size="small"
                      color="primary"
                      onClick={() => {
                        window.open(`https://drive.google.com/uc?id=${rwt.fileId}&export=download`)
                      }}
                    >
                      Download
                    </Button>
                      // <RwtActionButton rwt={rwt} />)
                    )}
                    {rwt.status === "available" && (
                      <Button
                        variant="contained"
                        size="small"
                        color="primary"
                        onClick={() => {
                          setShowConfirmSubmit(true);
                          setSelectedRwt(rwt);
                        }}
                      >
                        Submit
                      </Button>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </TableContainer>
      {!isLoading && records?.length === 0 && (
        <div className="rwt-table-no-records">
          <Typography variant="h6">Nothing here...</Typography>
        </div>
      )}
    </div>
  );
};

export default RwtTable;
