import React, { useState, useEffect } from "react";
import {
    Dialog,
    Typography,
    DialogContent,
    DialogTitle,
    Grid,
    Button,
    DialogActions,
    CircularProgress,
    Divider,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import SplitBillRow from "./SplitBillRow";
import { v1 as uuidv1 } from "uuid";
import ConfirmationDialog from "../../../Shared/ConfirmationDialog";
import axios from "axios";
import { currencyFormat } from "../../../../utils/GeneralUtils";
import PayableFileViewer from "../PayableFileViewer";
import ProcessingSpinnerDialog from "../../../Shared/Spinner/ProcessingSpinnerDialog";

const SplitPayableDialog = ({
    payable,
    open,
    handleClose,
    handleSuccess = () => { },
    handleError = () => { },
    companies = []
}) => {

    const [newPayables, setNewPayables] = useState([
        { ...payable, total: payable.total / 2, recordId: uuidv1() },
        { ...payable, total: payable.total / 2, recordId: uuidv1() }
    ]);
    const [remaining, setRemaining] = useState(payable.total);
    const [showError, setShowError] = useState(false);
    const [saving, setSaving] = useState(false);
    const [loadingPdf, setLoadingPdf] = useState(false);

    const addNewSplitPayable = () => {
        var payablesToAdd = [...newPayables];
        payablesToAdd.push({
            ...payable,
            total: 0,
            reference: "",
            recordId: uuidv1()
        })

        setNewPayables(payablesToAdd);
    }

    const removeNewSplitPayable = (recordId) => {
        setNewPayables([...newPayables].filter(p => p.recordId != recordId));
    }

    const updateNewSplitPayable = (payable) => {
        var payablesToAdd = [...newPayables];
        for (var i = 0; i < payablesToAdd.length; i++) {
            if (payablesToAdd[i].recordId == payable.recordId) {
                payablesToAdd[i] = payable;
            }
        }

        setNewPayables(payablesToAdd);
    }

    const handleSplitBill = () => {
        if (remaining != 0) {
            setShowError(true)
        } else {
            setSaving(true)
            var filteredPayables = [...newPayables];
            filteredPayables.forEach(p => p.recordId = 0);
            axios.post(`MediaAdDocuments/SplitBill/${payable.recordId}`, {
                payables: filteredPayables
            }).then(res => {
                handleSuccess();
            }).catch(e => {
                handleError();
            }).finally(() => {
                setSaving(false);
            })
        }
    }

    useEffect(() => {
        var total = 0;
        for (var i = 0; i < newPayables.length; i++) {
            if (typeof newPayables[i].total == "string") {
                total += parseFloat(newPayables[i].total)
            } else {
                total += newPayables[i].total;
            }
        }
        console.log(`${total.toFixed(2)}`)
        setRemaining(payable.total - parseFloat(total.toFixed(2)));
    }, [newPayables])

    return (
        <div>
            {showError && (
                <ConfirmationDialog
                    show={showError}
                    titleText="Oops!"
                    contentText="It looks like the remaining is not 0. Please make sure the remaining is 0 before proceeding"
                    handleClose={() => setShowError(false)}
                    buttonText="close"
                    buttonClass="default-button"
                    fullWidth={true}
                    maxWidth="xs"
                />
            )}
            {saving && (
                <ProcessingSpinnerDialog loading={saving} />
            )}
            <Dialog
                open={open}
                onClose={(e, reason) => {
                    if (!saving && reason != "backdropClick") {
                        handleClose()
                    }
                }}
                fullWidth
                maxWidth="lg"
                className="split-payable-dialog default-dialog"
            >
                <DialogTitle className="default-title default-dialog-header-container">
                    Split Bill
                </DialogTitle>
                <DialogContent>
                    <div style={{ display: "flex" }}>
                        <div style={{ width: "50%" }}>
                            <Grid container spacing={2}>
                                <Grid item xs={4} md={4} lg={4}></Grid>
                                <Grid item xs={4} md={4} lg={4}>
                                    <Typography variant="caption">Original Reference: {payable.reference}</Typography>
                                </Grid>
                                <Grid item xs={3} md={3} lg={3}>
                                    <div>
                                        <Typography variant="caption">Original: {currencyFormat(payable.total)}</Typography>
                                    </div>
                                    <div>
                                        {(remaining == null || isNaN(remaining)) ? <Typography variant="caption">
                                            Remaining: <span className={"text-red"}>Bill has invalid amount</span>
                                        </Typography> :
                                            <Typography variant="caption">
                                                Remaining: <span className={remaining == 0 ? "text-green" : "text-red"}>{currencyFormat(remaining)}</span>
                                            </Typography>}
                                    </div>
                                </Grid>
                                <Grid item xs={1} md={1} lg={1} className="default-text-field-container">

                                </Grid>
                                {newPayables.map(payable =>
                                    <SplitBillRow
                                        key={payable.recordId}
                                        payable={payable}
                                        removePayable={removeNewSplitPayable}
                                        updatePayable={updateNewSplitPayable}
                                        companies={companies}
                                    />)
                                }
                            </Grid>
                            <div style={{
                                display: "flex",
                                justifyContent: "center",
                                paddingTop: 10
                            }}>
                                <Button
                                    className="default-button-grey"
                                    onClick={() => addNewSplitPayable()}
                                    disabled={saving}
                                >
                                    Click to add bill
                                </Button>
                            </div>
                        </div>
                        <div style={{ width: "50%", maxHeight: "80%" }}>
                            <PayableFileViewer
                                payable={payable}
                                loadingPdf={loadingPdf}
                                setLoadingPdf={setLoadingPdf}
                            />
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button
                        className="default-button-grey"
                        onClick={handleClose}
                        disabled={saving}
                    >
                        Close
                    </Button>
                    {/* {saving ? <div style={{ padding: 10 }}>
                        <CircularProgress />
                    </div> :
                        <Button
                            className="default-button"
                            onClick={handleSplitBill}
                            disabled={saving}
                        >
                            Split Bill
                        </Button>
                    } */}
                    <Button
                        className="default-button"
                        onClick={handleSplitBill}
                        disabled={saving}
                    >
                        Split Bill
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default SplitPayableDialog;