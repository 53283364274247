import { createReducer } from "@reduxjs/toolkit";
const initialState = {
  calendarOutputs: [],
  calendarOutputsMonthView: [],
};

const calendarOutputs = createReducer(initialState, {
  GET_OUTPUTS_FOR_CALENDAR: (state, action) => {
    return {
      ...state,
      calendarOutputs: action.payload,
    };
  },
  GET_OUTPUTS_FOR_CALENDAR_MONTH_VIEW: (state, action) => {
    return {
      ...state,
      calendarOutputsMonthView: action.payload,
    };
  },
});

export default calendarOutputs;
