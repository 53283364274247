import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import "./Reschedule.scss";
import {
  AccessTime,
  LocationOn as LocationOnIcon
} from "@material-ui/icons";
import {
  TableRow,
  TableCell,
} from "@material-ui/core";
import "date-fns";
import { format, isValid } from "date-fns";
import {
  KeyboardTimePicker,
} from "@material-ui/pickers";
import {
    getValueOrEmptyString,
} from "../../../../utils/utils";
import {
  changeLocation,
  createNewSchedule,
  deleteScheduleLocation,
  editScheduleLocation,
  promoteScheduleLocation,
  noLocationAlert,
} from "../../../../actions/schedules";
import { editScheduleItem } from "../../../../actions/scheduleItems";
import LocationContractButton from "../ScheduleLocation/LocationContractButton";

const useStyles = makeStyles((theme) => ({
    margin: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

const RescheduleLocationRow = (props) => {
    let classes = useStyles();
    let {
        category,
        row,
        schedule,
    } = props;

    return (
        <TableRow key={row.id} className="reschedule-location-row">
            <TableCell>{row.locationFkNavigation.address}</TableCell>
            <TableCell>
                {getValueOrEmptyString(row.fee) !== "" ? `$${row.fee}` : `No Fee`}
            </TableCell>
            <TableCell align="center">
                <div>
                    <KeyboardTimePicker
                        value={row.startTime}
                        onChange={(date, value) => {
                            if (date instanceof Date && !isNaN(date)) {
                                props.editScheduleLocation(
                                    schedule,
                                    row,
                                    "startTime",
                                    format(date, "HH:mm:ss"),
                                    1
                                );
                            }
                        }}
                        KeyboardButtonProps={{
                            "aria-label": "start time",
                            style: {
                                ...{
                                    padding: "0px",
                                    margin: "0px",
                                },
                            },
                        }}
                        className={`${classes.margin} time-picker-width ${props.providerType === "Talent"
                            ? "talent-time-picker-width"
                            : ""
                            }`}
                        keyboardIcon={<AccessTime size="small" />}
                    />
                </div>
            </TableCell>
            <TableCell>
                <div>
                    <KeyboardTimePicker
                        // id="end-time-picker"
                        onChange={(date, value) => {
                            if (date instanceof Date && !isNaN(date)) {
                                props.editScheduleLocation(
                                    schedule,
                                    row,
                                    "endTime",
                                    format(date, "HH:mm:ss"),
                                    1
                                );
                            }
                        }}
                        value={row.endTime}
                        KeyboardButtonProps={{
                            "aria-label": "end time",
                            style: {
                                ...{
                                    padding: "0px",
                                    margin: "0px",
                                },
                            },
                        }}
                        keyboardIcon={<AccessTime size="small" />}
                        className={`${classes.margin} time-picker-width ${props.providerType === "Talent"
                            ? "talent-time-picker-width"
                            : ""
                            }`}
                    />
                </div>
            </TableCell>
            <TableCell align="left">
                <div className="location-contract-button">
                    <LocationContractButton
                        sl={row}
                        l={row.locationFkNavigation}
                        s={schedule}
                        editScheduleLocation={props.editScheduleLocation}
                        rescheduleButton="Location"
                    />
                </div>
            </TableCell>
        </TableRow>
    );
};

const mapStateToProps = (state, ownProps) => ({
    allScheduleLocations: state.schedules.schedule.scheduleLocations,
    noLocation: state.schedules.noLocation,
    scheduleItems: state.scheduleItems,
    allScheduleItems: state.schedules.schedule.scheduleItem,
    schedule: state.schedules.schedule,
});
export default connect(mapStateToProps, {
    changeLocation,
    createNewSchedule,
    deleteScheduleLocation,
    editScheduleLocation,
    noLocationAlert,
    promoteScheduleLocation,
    editScheduleItem,
})(RescheduleLocationRow);