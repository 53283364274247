import { Skeleton } from "@material-ui/lab";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { currencyFormat } from "../../../../utils/GeneralUtils";
import { Divider, Typography } from "@material-ui/core";
import "./totalCards.scss";
import moment from "moment";
import { useHistory } from "react-router-dom";
const TotalCards = () => {
  const history = useHistory();
  const [isLoadingAccruals, setIsLoadingAccruals] = useState(false);
  const [isLoadingPayables, setIsLoadingPayables] = useState(false);
  const [accrualsFigure, setAccrualsFigure] = useState(null);
  const [payablesFigure, setPayablesFigure] = useState(null);
  useEffect(() => {
    getAccrualsRobotFigure();
    getUnmatchedPayablesFigure();
  }, []);

  const getAccrualsRobotFigure = () => {
    setIsLoadingAccruals(true);
    axios
      .get(`/billsdashboard/GetAccrualsRobotTotalFromBalanceSheet`)
      .then(({ data }) => {
        setAccrualsFigure(data);
      })
      .catch((err) => console.error(err))
      .finally(() => setIsLoadingAccruals(false));
  };

  const getUnmatchedPayablesFigure = () => {
    setIsLoadingPayables(true);
    axios
      .get(`/billsdashboard/GetUnmatchedPayablesFigure`)
      .then(({ data }) => {
        setPayablesFigure(data);
      })
      .catch((err) => console.error(err))
      .finally(() => setIsLoadingPayables(false));
  };

  const handleClickUnmatchedPayableAmount = (e) => {
    e.preventDefault();
    history.push("/Finance/Payables");
  };

  return (
    <div className="bills-dashboard-total-cards-root">
      {isLoadingAccruals ? (
        <Skeleton height={250} />
      ) : (
        <div className="bills-dashboard-total-cards-container">
          <div className="bills-dashboard-total-cards-amount">
            {currencyFormat(accrualsFigure?.total || 0)}
          </div>
          <Divider />
          <div className="bills-dashboard-total-cards-description">
            <Typography variant="caption">Accruals Robot</Typography>
            <Typography variant="caption">{`As at ${moment()
              .endOf("month")
              .format("DD MMMM YYYY")}`}</Typography>
          </div>
        </div>
      )}
      {isLoadingPayables ? (
        <Skeleton height={250} />
      ) : (
        <div className="bills-dashboard-total-cards-container">
          <div className="bills-dashboard-total-cards-amount">
            {currencyFormat(payablesFigure?.total || 0)}
          </div>
          <Divider />
          <div className="bills-dashboard-total-cards-description">
            <Typography variant="caption">Payable Invoices</Typography>
            <Typography
              variant="caption"
              className="bills-dashboard-total-cards-description-link"
              onClick={handleClickUnmatchedPayableAmount}
            >{`Unmatched Amount`}</Typography>
          </div>
        </div>
      )}
    </div>
  );
};

export default TotalCards;
