import React from "react";
import { Switch, IconButton, makeStyles } from "@material-ui/core";
import { DescriptionOutlined } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: 200,
  },
  margin: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  alignRight: {
    marginLeft: "auto",
    marginRight: 0,
  },
  margin: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  tableHeader: {
    fontSize: 13,
    fontWeight: "bold",
    paddingLeft: "10px",
    paddingRight: "2px",
  },
  removeMarginPadding: {
    padding: "0px 0px 0px 0px",
    margin: "0px 0px 0px 0px",
  },
  switchBase: {
    color: "primary",
    "&$checked": {
      color: "primary",
    },
    "&$checked + $track": {
      backgroundColor: "primary",
    },
  },
  checked: {
    color: "#3f51b5",
  },
  track: {
    color: "#3f51b5",
    backgroundColor: "#3f51b5",
  },
  thumb: {
    color: "#3f51b5",
  },
  disabled: {
    color: "#dbdbdb",
  },
}));

const styles = {
  icon: {
    width: 25,
    height: 25,
  },
};

const PrePostTalentSwitchPanel = (props) => {
  const classes = useStyles();
  let { scheduleItem } = props;

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        justifyContent: "center",
        visibility:
          scheduleItem.category.toLowerCase().trim() !== "unpaid"
            ? "visible"
            : "hidden",
      }}
    >
      <div
        style={
          scheduleItem.talentContractType === "BuyOut"
            ? {
                borderBottom: "solid",
                borderColor: "#3f51b5",
                padding: "2px",
                fontSize: "10px",
                margin: "auto",
              }
            : {
                padding: "2px",
                fontSize: "10px",
                margin: "auto",
              }
        }
      >
        Buy Out
      </div>
      {scheduleItem.contractStatus !== "Send" &&
      scheduleItem.contractStatus !== "Disabled" ? (
        <Switch
          disabled
          checked={scheduleItem.talentContractType === "Restricted"}
        />
      ) : (
        <Switch
          classes={{
            root: classes.root,
            switchBase: classes.switchBase,
            thumb: classes.thumb,
            track: classes.track,
            checked: classes.checked,
            disabled: classes.disabled,
          }}
          color="primary"
          name="talentSwitch"
          checked={scheduleItem.talentContractType === "Restricted"}
          inputProps={{
            "aria-label": "primary checkbox",
          }}
          marginTop="4px"
          onClick={() => {
            props.switchContractType(scheduleItem);
          }}
        />
      )}

      <div
        style={
          scheduleItem.talentContractType === "Restricted"
            ? {
                borderBottom: "solid",
                borderColor: "#3f51b5",
                padding: "2px",
                fontSize: "10px",
                margin: "auto",
              }
            : {
                padding: "2px",
                fontSize: "10px",
                margin: "auto",
              }
        }
      >
        Restricted
      </div>
      {scheduleItem.contractStatus !== "Send" &&
      scheduleItem.contractStatus !== "Disabled" ? (
        <IconButton
          style={{
            width: 30,
            height: 30,
            backgroundColor:
              scheduleItem.talentContractStatus === "Complete"
                ? "#4dfa59"
                : "#c8c8c8",
            color: "white",
            margin: "auto",
            marginLeft: 10,
            marginRight: 10,
            padding: 0,
            visibility:
              scheduleItem.category.toLowerCase().trim() !== "unpaid"
                ? scheduleItem.talentContractType === "Restricted" &&
                  scheduleItem.category.toLowerCase().trim() !== "unpaid"
                  ? "visible"
                  : "hidden"
                : "hidden",
          }}
          onClick={() => {
            // props.handleTalentModal();
            props.setActiveScheduleItem(scheduleItem);
            if (scheduleItem.isAucklandStandardContract == 1 && scheduleItem.talentContractStatus === "Complete") {
              props.handleAucklandStandardModal();
            }
            else {
              props.handleTalentModal();
            }
          }}
        >
          <DescriptionOutlined style={styles.icon} />
        </IconButton>
      ) : (
        <IconButton
          style={{
            width: 30,
            height: 30,
            backgroundColor:
              scheduleItem.talentContractStatus === "Complete"
                ? "#4dfa59"
                : "#c8c8c8",
            color: "white",
            margin: "auto",
            marginLeft: 10,
            marginRight: 10,
            padding: 0,
            visibility:
              scheduleItem.category.toLowerCase().trim() !== "unpaid"
                ? scheduleItem.talentContractType === "Restricted" &&
                  scheduleItem.category.toLowerCase().trim() !== "unpaid"
                  ? "visible"
                  : "hidden"
                : "hidden",
          }}
          onClick={() => {
            // props.handleTalentModal();
            props.setActiveScheduleItem(scheduleItem);
            if (scheduleItem.isAucklandStandardContract == 1 && scheduleItem.talentContractStatus === "Complete") {
              props.handleAucklandStandardModal();
            }
            else {
              props.handleTalentModal();
            }
          }}
        >
          <DescriptionOutlined style={styles.icon} />
        </IconButton>
      )}
    </div>
  );
};

export default PrePostTalentSwitchPanel;
