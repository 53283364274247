import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { currencyFormat, isNumeric } from "../../../../utils/GeneralUtils";
import axios from "axios";
import Snacky from "../../../Shared/Snacky";
import moment from "moment";

const TransferBudgetButton = ({
  budgetItems,
  setBudgetItems,
  handleSaveCallback,
  source,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [budgetToTransfer, setBudgetToTransfer] = useState({
    budgetFrom: null,
    budgetTo: null,
    amount: 0,
  });

  const [snackBarProps, setSnackBarProps] = useState({
    open: false,
    severity: "success",
    text: "",
  });
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);

  useEffect(() => {
    if (source === "bill-dashboard") {
      fetchCompaniesWithProjects();
    }
  }, []);

  useEffect(() => {
    if (companies.length > 0 && selectedCompany) {
      fetchAllBudget();
    }
  }, [selectedCompany]);

  const fetchCompaniesWithProjects = () => {
    axios
      .get(`/companies/GetCompaniesWithProjects`)
      .then(({ data }) => {
        setCompanies(data);
      })
      .catch((err) =>
        setSnackBarProps({
          open: true,
          severity: "warning",
          text: "Unable to load companies",
        })
      );
  };

  const handleClick = () => {
    setOpenModal(true);
  };
  const handleClose = () => {
    setBudgetToTransfer({
      budgetFrom: null,
      budgetTo: null,
      amount: 0,
    });
    setOpenModal(false);
  };

  const handleSave = () => {
    if (isAmountValid()) {
      axios
        .post(`/budgetItems/TransferBudgetItems`, budgetToTransfer)
        .then(async ({ data }) => {
          setSnackBarProps({
            open: true,
            severity: "success",
            text: "The budget has been transfered.",
          });
          await handleSaveCallback();
          handleClose();
        })
        .catch((err) => {
          setSnackBarProps({
            open: true,
            severity: "warning",
            text: "Unable to complete action.",
          });
        });
    }
  };

  const isAmountValid = () => {
    if (
      budgetToTransfer.budgetFrom &&
      budgetToTransfer.budgetTo &&
      budgetToTransfer.amount > 0
    ) {
      const budgetItem = budgetItems.find(
        (b) => b.budgetId === budgetToTransfer.budgetFrom
      );
      if (budgetItem?.remaining >= budgetToTransfer.amount) {
        return true;
      }
    }

    return false;
  };

  const fetchAllBudget = () => {
    axios
      .get(`/budgetitems/GetBudgetForCompanyByProject/${selectedCompany?.id}`)
      .then(({ data }) => {
        setBudgetItems(data);
      });
  };

  return (
    <div>
      <Snacky snackprops={snackBarProps} setSnackBarProps={setSnackBarProps} />
      {openModal && (
        <Dialog
          open={openModal}
          onClose={(e, reason) => {
            if (reason !== "backdropClick") {
              handleClose();
            }
          }}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle style={{ display: "flex", justifyContent: "center" }}>
            Move Budget
          </DialogTitle>
          <DialogContent
            style={{ display: "flex", flexDirection: "column", gap: 10 }}
          >
            <DialogContentText align="center">
              Select budget items to transfer
            </DialogContentText>
            {companies?.length > 0 && (
              <Autocomplete
                options={companies}
                getOptionLabel={(option) => option.name}
                onChange={(e, newValue) => {
                  setSelectedCompany(newValue);
                }}
                selectOnFocus
                renderOption={(option) => {
                  return (
                    <li key={option.id}>
                      {option.name}
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Client"
                    size="small"
                    fullWidth={true}
                    variant="filled"
                  />
                )}
              />
            )}
            {budgetItems?.length > 0 && (
              <>
                <Autocomplete
                  options={budgetItems.filter((b) => b.remaining > 0)}
                  getOptionLabel={(option) =>
                    `${option.projectCategory} ${" - "}${moment(option.billingDate).format("MMM/YY")}${" - "}${currencyFormat(
                      option.remaining
                    )}`
                  }
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setBudgetToTransfer({
                        ...budgetToTransfer,
                        budgetFrom: newValue.budgetId,
                      });
                    } else {
                      setBudgetToTransfer({
                        ...budgetToTransfer,
                        budgetFrom: null,
                      });
                    }
                  }}
                  renderOption={(option) => {
                    return (
                      <li key={option.budgetId}>
                        <Typography variant="caption">
                          {option.projectCategory}{" - "}{moment(option.billingDate).format("MMM/YY")}{" - "}
                          {currencyFormat(option.remaining)}
                        </Typography>
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="From Budget"
                      size="small"
                      placeholder="From Budget"
                      variant="filled"
                    />
                  )}
                />
                <Autocomplete
                  options={budgetItems}
                  getOptionLabel={(option) =>
                    `${option.projectCategory}${" - "}${moment(option.billingDate).format("MMM/YY")}${" - "}${currencyFormat(
                      option.remaining
                    )}`
                  }
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setBudgetToTransfer({
                        ...budgetToTransfer,
                        budgetTo: newValue.budgetId,
                      });
                    } else {
                      setBudgetToTransfer({
                        ...budgetToTransfer,
                        budgetTo: null,
                      });
                    }
                  }}
                  renderOption={(option) => {
                    return (
                      <li key={option.budgetId}>
                        <Typography variant="caption">
                          {option.projectCategory}{" - "}{moment(option.billingDate).format("MMM/YY")}{" - "}
                          {currencyFormat(option.remaining)}
                        </Typography>
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="To"
                      size="small"
                      placeholder="To"
                      variant="filled"
                    />
                  )}
                />
                <TextField
                  label="Amount To Transfer"
                  size="small"
                  placeholder="Amount To Transfer"
                  variant="filled"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  value={budgetToTransfer?.amount}
                  onChange={(e) =>
                    isNumeric(e.target.value) || e.target.value !== ""
                      ? setBudgetToTransfer({
                          ...budgetToTransfer,
                          amount: e.target.value,
                        })
                      : setBudgetToTransfer({ ...budgetToTransfer, amount: 0 })
                  }
                />
              </>
            )}
          </DialogContent>
          <DialogActions
            style={{ display: "flex", justifyContent: "end", gap: 20 }}
          >
            <Button
              onClick={() => {
                handleClose();
              }}
              variant="outlined"
            >
              Cancel
            </Button>
            <Button
              disabled={!isAmountValid()}
              onClick={() => {
                handleSave();
              }}
              color="primary"
              variant="contained"
              autoFocus
            >
              Transfer
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {(budgetItems?.length > 1 || source === "bill-dashboard") && (
        <Button variant="outlined" size="small" onClick={handleClick}>
          Transfer Budget
        </Button>
      )}
    </div>
  );
};

export default TransferBudgetButton;
