import React, { Fragment, useState } from "react";
import "./expensesTable.scss";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import ContractorPopUp from "../../../Project/ProjectOutputs/PopUps/ContractorPopUp";
import { currencyFormat } from "../../../../utils/GeneralUtils";
import CustomTableRowCompany from "./CustomTableRowCompany";

const CustomTableRow = ({ bill, setSnackBarProps, handlePreLoadCampaign }) => {
  const [openAllocated, setOpenAllocated] = useState(false);
  const [openUpdatePerson, setOpenUpdatePerson] = useState(false);
  const [selectedPerson, setSelectedPerson] = useState(null);
  const handleSavePerson = (selectedPerson) => {
    if (selectedPerson) {
      setSnackBarProps({
        open: true,
        severity: "success",
        text: "Your changes have been saved!",
      });
    } else {
      setSnackBarProps({
        open: true,
        severity: "warning",
        text: "Hm, it looks like something went wrong.",
      });
    }
  };
  return (
    <Fragment>
      {openUpdatePerson && selectedPerson?.id && (
        <ContractorPopUp
          handleModal={() => {
            setSelectedPerson(null);
            setOpenUpdatePerson(false);
          }}
          modalState={openUpdatePerson}
          personId={selectedPerson?.id}
          scheduleItemId={null}
          reloadPersonList={() => console.log("No reload")}
          reloadScheduleItemsList={() => {}}
          type="Influencer"
          providerType={selectedPerson.personCategory}
          handleEditPerson={handleSavePerson}
          selectedContact={selectedPerson}
          rowUpdated={() => {}}
        />
      )}
      <TableRow>
        <TableCell className="client-budget-table-icon-column">
          {bill.records?.length > 0 && (
            <IconButton aria-label="expand row" size="small">
              {openAllocated ? (
                <KeyboardArrowUpIcon
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpenAllocated(!openAllocated);
                  }}
                />
              ) : (
                <KeyboardArrowDownIcon
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpenAllocated(!openAllocated);
                  }}
                />
              )}
            </IconButton>
          )}
        </TableCell>
        <TableCell className="client-budget-table-category-column">
          {bill.projectName} {" - "} {bill.category}
        </TableCell>
        <TableCell>{currencyFormat(Number(bill.quoted))}</TableCell>
        <TableCell>{currencyFormat(Number(bill.allocated))}</TableCell>
        <TableCell>{currencyFormat(Number(bill.remaining))}</TableCell>
      </TableRow>
      {bill.records?.length > 0 && openAllocated && (
        <TableRow>
          <TableCell colSpan={5}>
            <Collapse in={true} timeout="auto" unmountOnExit>
              <div className="client-budget-table-sub-table">
                <div>
                  <strong>{"Name"}</strong>
                </div>
                <div>
                  <strong>Campaign</strong>
                </div>
                <div></div>
                <div>
                  <strong>Total</strong>
                </div>
                <div></div>
              </div>
              {bill.records.map((record, index) => (
                <div key={index} className="client-budget-table-sub-table">
                  <div>
                    {record.person ? (
                      <span
                        className={`client-budget-table-link`}
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          setSelectedPerson(record.person);
                          setOpenUpdatePerson(true);
                        }}
                      >
                        {record.name}
                      </span>
                    ) : (
                      `${record.name}`
                    )}
                  </div>
                  <div>
                    {record.campaignName && (
                      <span
                        className={`${
                          record.campaignId ? "client-budget-table-link" : ""
                        }`}
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          if (record.campaignId) {
                            handlePreLoadCampaign(record.campaignId);
                          }
                        }}
                      >
                        {record.campaignName}
                      </span>
                    )}{" "}
                  </div>
                  <div>{record.status}</div>
                  <div>{currencyFormat(Number(record.total))}</div>
                  <div></div>
                </div>
              ))}
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </Fragment>
  );
};
const ExpensesTable = ({
  expenseBills,
  setSnackBarProps,
  handlePreLoadCampaign,
  grandTotals,
}) => {
  return (
    <div>
      {expenseBills?.length > 0 ? (
        <div className="expenses-table-records-container">
          <TableContainer component={Paper}>
            <Table size="small" className="expense-table-root-container">
              <TableHead>
                <TableRow>
                  <TableCell className="client-budget-table-icon-column"></TableCell>
                  <TableCell>Client</TableCell>
                  <TableCell>Quoted</TableCell>
                  <TableCell>Allocated</TableCell>
                  <TableCell>Remaining</TableCell>
                </TableRow>
              </TableHead>
              <TableHead>
                <TableRow>
                  <TableCell className="client-budget-table-icon-column"></TableCell>
                  <TableCell>Total</TableCell>
                  <TableCell>{currencyFormat(Number(grandTotals.totalQuoted))}</TableCell>
                  <TableCell>{currencyFormat(Number(grandTotals.totalAllocated))}</TableCell>
                  <TableCell>{currencyFormat(Number(grandTotals.totalRemaining))}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {expenseBills.map((companyBills, indexBill) => (
                  <CustomTableRowCompany
                    key={indexBill}
                    companyBills={companyBills}
                    setSnackBarProps={setSnackBarProps}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      ) : (
        <div className="expenses-table-no-records">
          <Typography variant="h6">Nothing here...</Typography>
        </div>
      )}
    </div>
  );
};

export default ExpensesTable;
