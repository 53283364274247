import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { connect } from "react-redux";
import {
  ButtonGroup,
  Grid,
  Grow,
  Button,
  Popper,
  Paper,
  MenuList,
  MenuItem,
  ClickAwayListener,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { contains } from "../../../utils/utils";
import { setDialogProps } from "../../../actions/dialog";
import InformationDialogy from "../../Shared/InformationDialogy";

const changeButton = (contractStatus) => {
  switch (contractStatus) {
    case "Draft":
    case "Pending":
      return {
        color: "#FFA500",
        options: ["Pending", "Accept Receipt", "Reject Receipt"],
      };
    case "Approved":
      return {
        color: "#33AFFF",
        options: ["Approved", "View Bill"],
      };
    case "PAID":
      return {
        color: "#008000",
        options: ["Paid"],
      };
    case "Rejected":
      return {
        color: "#FF0000",
        options: ["Rejected"],
      };
    case "InfoRequested":
      return {
        color: "#FFA500",
        options: ["Info Requested"],
      };
    case "MissingInfo":
      return {
        color: "#FFA500",
        options: ["Missing Info"],
      };
    default:
      return { color: "#c8c8c8", options: ["Error"] };
  }
};

const ReimbursementButton = (props) => {
  const [checked, setChecked] = useState(true);
  const [open, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [buttonProps, setButtonProps] = useState(
    changeButton(props.receipt.billStatus)
  );
  const [showCancelWarning, setShowCancelWarning] = useState(false);

  const anchorRef = useRef(null);
  let receipt = props.receipt;

  useEffect(() => {
    if (receipt.billStatus !== "InfoRequested" && (receipt.bankAccount === null || receipt.bankAccount === "")) {
      setButtonProps(changeButton("MissingInfo"));
    } else {
      setButtonProps(changeButton(receipt.billStatus));
    }
  }, [receipt.billStatus]);

  const handleClick = () => { };

  const handleMenuItemClick = (event, index) => {
    if (buttonProps.options[index] === "Accept Receipt") {
      if (props.accountCode === null) {
        props.handleAccountCodeError();
      } else {
        var newReceipt = receipt;
        newReceipt.billStatus = "Approved";
        props.handleOnClick("Approved", "billStatus", receipt);
        axios
          .post(
            `Receipts/WriteToReceiptSheetFromAdmin/${newReceipt.id}`,
            newReceipt
          )
          .then((res) => console.log(res.data))
          .catch((e) => console.log(e));
      }
    } else if (buttonProps.options[index] === "Reject Receipt") {
      var newReceipt = receipt;
      newReceipt.billStatus = "Rejected";

      props.handleOnClick("Rejected", "billStatus", receipt);
      axios
        .post(`Receipts/RejectAdminReceipt/${newReceipt.id}`, newReceipt)
        .then((res) => console.log(res.data))
        .catch((e) => console.log(e));
    }
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && contains(anchorRef.current, event.target)) {
      return;
    }
    setOpen(false);
  };

  return (
    <Grow in={checked} mountOnEnter unmountOnExit>
      <Grid container alignItems="center">
        {showCancelWarning ? (
          <InformationDialogy
            setDialogProps={setDialogProps}
            buttonProps={{
              show: showCancelWarning,
              titleText: "Woops!",
              contentText: `Cannot be cancelled.`,
              buttonColor: "primary",
              buttonText: "Ok",
            }}
            handleButton={() => setShowCancelWarning(false)}
          />
        ) : (
          <React.Fragment></React.Fragment>
        )}
        <Grid item xs={12}>
          <ButtonGroup
            variant="contained"
            ref={anchorRef}
            aria-label="split button"
            size="small"
            style={{
              width: "120px",
              backgroundColor: buttonProps.color,
            }}
          >
            <Button
              onClick={handleClick}
              style={{
                padding: "0px 2px 0px 2px",
                width: "120px",
                backgroundColor: buttonProps.color,
                color: "white",
                whiteSpace: "normal",
                wordWrap: "break-word",
              }}
            >
              {buttonProps.options[selectedIndex]}
            </Button>
            <Button
              size="small"
              aria-controls={open ? "split-button-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-label="select declined option"
              aria-haspopup="menu"
              onClick={handleToggle}
              style={{
                width: "15px",
                backgroundColor: buttonProps.color,
                color: "white",
              }}
            >
              <ArrowDropDownIcon />
            </Button>
          </ButtonGroup>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
            style={{
              zIndex: "999",
            }}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom",
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList id="split-button-menu">
                      {buttonProps.options.map(
                        (option, index) =>
                          index > 0 && (
                            <MenuItem
                              key={option}
                              disabled={index === 0}
                              selected={index === selectedIndex}
                              onClick={(event) =>
                                handleMenuItemClick(event, index)
                              }
                            >
                              {option}
                            </MenuItem>
                          )
                      )}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Grid>
      </Grid>
    </Grow>
  );
};

const mapStateToProps = (state, ownProps) => ({
  dialog: state.dialog,
});
export default connect(mapStateToProps, {
  setDialogProps,
})(ReimbursementButton);
