import React, { useState, useEffect } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import DeliverPopup from "./PopUps/DeliverPopUp";

import {
  Table,
  AppBar,
  Toolbar,
  Typography,
  TableBody,
  TableCell,
  TableSortLabel,
  TableContainer,
  TableRow,
  Paper,
  Input,
  TableHead,
  Menu,
  MenuItem,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import {
  Close as CloseIcon,
  Edit as EditIcon,
  Save as SaveIcon,
} from "@material-ui/icons";
import { format } from "date-fns";
import daysLeft from "../../../utils/workingDaysLeft";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontWeight: "bold",
  },
  body: {
    fontSize: 14,
  },
  root: {
    padding: "10px 15px 10px 0px",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);

const StyledInput = withStyles((theme) => ({
  root: {
    fontSize: "14px",
  },
}))(Input);

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
});

//----------------------Start of Sorting---------------------------
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  console.log(array);
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "Creative Name",
    align: "left",
    disablePadding: false,
    label: "Creative Name",
  },
  {
    id: "Duration",
    align: "left",
    disablePadding: false,
    label: "Duration",
  },
  {
    id: "Working Days",
    align: "left",
    disablePadding: false,
    label: "Working Days",
  },
  {
    id: "Format",
    align: "left",
    disablePadding: false,
    label: "Format",
  },
  {
    id: "Creative Deadline",
    align: "left",
    disablePadding: false,
    label: "Creative Deadline",
  },
  { id: "Platform", align: "left", disablePadding: false, label: "Platform" },
  {
    id: "Publisher",
    align: "left",
    disablePadding: false,
    label: "Publisher",
  },
  // {
  //   id: "audience",
  //   align: "left",
  //   disablePadding: false,
  //   label: "Target Audience",
  // },
  // {
  //   id: "goal",
  //   align: "left",
  //   disablePadding: false,
  //   label: "Strategic Goal",
  // },
  {
    id: "Key Number",
    align: "left",
    disablePadding: false,
    label: "Key # (TV Only)",
  },
  {
    id: "Subtitles",
    align: "left",
    disablePadding: false,
    label: "Subtitles",
  },
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => (
          <StyledTableCell
            key={headCell.id}
            align={headCell.align}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ paddingLeft: index === 0 ? "15px" : 0, width: index === 0 ? '15%' : "auto" }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
const initialState = {
  mouseX: null,
  mouseY: null,
};

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

//-----------------End of Sorting--------------------------

export default function MediaPlanOutputs(props) {
  const classes = useStyles();
  const [showMediaOutputsTable, setShowMediaOutputsTable] = useState(true);
  const [rows, setRows] = useState();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("StartDate");
  const [state, setState] = React.useState(initialState);
  const [outputIndex, setOutputIndex] = React.useState();
  const [selectedRow, setSelectedRow] = useState([]);
  const [showDeliverPopUp, setShowDeliverPopUp] = useState(false);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const getUniqueDestinations = (items) => {
    const uniqueValues = [...new Set(JSON.parse(items))];
    var uniqueItems = uniqueValues.join(", ");
    return uniqueItems;
  };

  const removeUndefined = (items) => {
    const uniqueValues = [...new Set(JSON.parse(items))];
    if (uniqueValues.includes("undefined")) {
      let index = uniqueValues.indexOf("undefined");
      uniqueValues.splice(index, 1);
    }
    var uniqueItems = uniqueValues.join(", ");
    return uniqueItems;
  };

  const handleClick = (event, index, row) => {
    event.preventDefault();
    setState({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    });
    console.log(index);
    setOutputIndex(index);
    if (row.Publisher) {
      row.Publisher = removeUndefined(row.Publisher);
    }
    setSelectedRow(row);
  };

  const handleCloseContext = () => {
    setState(initialState);
    console.log(outputIndex);
  };

  const handleShowDeliverModal = () => {
    setState(initialState);
    toggleShowDeliverPopUp();
  };

  const toggleShowDeliverPopUp = () => {
    if (showDeliverPopUp) {
      setShowDeliverPopUp(false);
    } else {
      setShowDeliverPopUp(true);
    }
  };

  const handleInputChange = (event, rowObj) => {
    props.handleInputChange(event, rowObj);
  };

  // useEffect(() => {
  //   console.log("props.outputs_________");
  //   console.log(props.outputs);
  // });

  return (
    <TableContainer component={Paper}>
      <AppBar
        position="static"
        className="output-header-container"
        style={{
          background: props.editMode ? "#ff9e00" : "",
        }}
      >
        <Toolbar style={{ minHeight: "30px" }}>
          <Typography variant="h6" align="center" style={{ flexGrow: 1 }}>
            From Media Planner
          </Typography>
          {props.editMode ? (
            <IconButton
              color="inherit"
              onClick={() => {
                props.toggleEditMode();
                props.handleSave();
              }}
            >
              <SaveIcon style={{ color: "#2d2d2d" }} />
            </IconButton>
          ) : (
            ""
          )}
          {!props.editMode && props.project.isArchived !== 1 ? (
            <IconButton
              aria-label="Toggle Edit Mode"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={() => props.toggleEditMode()}
              color="inherit"
            >
              <EditIcon style={{ color: "#2d2d2d" }} />
            </IconButton>
          ) : (
            ""
          )}
        </Toolbar>
      </AppBar>
      {props.outputs && showDeliverPopUp && (
        <DeliverPopup
          modalState={showDeliverPopUp}
          handleModal={toggleShowDeliverPopUp}
          outputDetails={selectedRow}
          splitSemiColon={getUniqueDestinations}
          handleEdit={() => props.handleEdit(selectedRow)}
          handleInputChangeDelivery={props.handleInputChangeDelivery}
          parentTable={"mediaOutputs"}
        />
      )}
      {showMediaOutputsTable && (
        <Table
          className={classes.table}
          size="small"
          aria-label="customized table"
        >
          <EnhancedTableHead
            classes={classes}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={props.outputs ? props.outputs.length : 0}
          />
          <TableBody>
            {props.outputs
              ? stableSort(props.outputs, getComparator(order, orderBy)) //props.outputs.map((row, index) => (
                  .map((row, index) => (
                    <StyledTableRow
                      key={row.rowID}
                      hover={true}
                      onContextMenu={(event) => handleClick(event, index, row)}
                      style={{ cursor: "context-menu" }}
                    >
                      <StyledTableCell style={{ paddingLeft: "15px" }}>
                      <Tooltip title={row.creativeName ? row.creativeName : row["Creative Name"] }>
                        <StyledInput
                          style={{width: "100%"}}
                          disabled={
                            props.project.isArchived === 1 ||
                            (props.editMode === true && row.rowID != null)
                          }
                          name="creativeName"
                          disableUnderline={true}
                          value={
                            row.creativeName
                              ? row.creativeName
                              : row["Creative Name"]
                          }
                          onChange={(event) => handleInputChange(event, row)}
                        />
                      </Tooltip>
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <StyledInput
                          disabled={
                            props.project.isArchived === 1 ||
                            props.editMode === true
                          }
                          name="Duration"
                          disableUnderline={true}
                          value={row.duration}
                        />
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <StyledInput
                          disabled={
                            props.project.isArchived === 1 ||
                            props.editMode === true
                          }
                          name="Working Days"
                          disableUnderline={true}
                          value={
                            row.startDate
                              ? daysLeft(new Date(row.startDate))
                              : "Not available"
                          }
                        />
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <StyledInput
                          disabled={
                            props.project.isArchived === 1 ||
                            props.editMode === true
                          }
                          name="Format"
                          disableUnderline={true}
                          value={row.videoFormat ? row.videoFormat : "N/A"}
                        />
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <StyledInput
                          disabled={
                            props.project.isArchived === 1 ||
                            props.editMode === true
                          }
                          name="Creative Deadline"
                          disableUnderline={true}
                          value={
                            row.startDate
                              ? format(
                                  new Date(row.startDate),
                                  "dd/MM/yyyy"
                                )
                              : ""
                          }
                        />
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <StyledInput
                          disabled={
                            props.project.isArchived === 1 ||
                            props.editMode === true
                          }
                          name="Platform"
                          disableUnderline={true}
                          value={getUniqueDestinations(row.platform)}
                        />
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <StyledInput
                          disabled={
                            props.project.isArchived === 1 ||
                            props.editMode === true
                          }
                          name="Publisher"
                          disableUnderline={true}
                          value={
                            getUniqueDestinations(row.publisher) !== "undefined"
                              ? getUniqueDestinations(row.publisher)
                              : ""
                          }
                        />
                      </StyledTableCell>
                      {/* <StyledTableCell align="left">
                        <StyledInput
                          disabled={
                            props.project.isArchived === 1 ||
                            props.editMode === true
                          }
                          name="Audience"
                          disableUnderline={true}
                          value={row.targetAudience ? row.targetAudience : ""}
                        />
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <StyledInput
                          disabled={
                            props.project.isArchived === 1 ||
                            props.editMode === true
                          }
                          name="Goal"
                          disableUnderline={true}
                          value={row.strategicGoal ? row.strategicGoal : ""}
                        />
                      </StyledTableCell> */}
                      <StyledTableCell align="left">
                        <StyledInput
                          disabled={
                            props.project.isArchived === 1 ||
                            props.editMode === true
                          }
                          name="Key Number"
                          disableUnderline={true}
                          value={row.keyNumberFkNavigation === null ? "" : row.keyNumberFkNavigation.keynumber}
                        />
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <StyledInput
                          disabled={
                            props.project.isArchived === 1 ||
                            props.editMode === true
                          }
                          name="Subtitles"
                          disableUnderline={true}
                          value={
                            row.subtitles == null
                              ? ""
                              : row.subtitles === true
                              ? "Yes"
                              : "No"
                          }
                        />
                      </StyledTableCell>
                    </StyledTableRow>
                  ))
              : ""}
            <Menu
              keepMounted
              open={state.mouseY !== null}
              onClose={handleCloseContext}
              anchorReference="anchorPosition"
              anchorPosition={
                state.mouseY !== null && state.mouseX !== null
                  ? { top: state.mouseY, left: state.mouseX }
                  : undefined
              }
            >
              {props.project.isArchived !== 1 && (
                <MenuItem onClick={() => handleShowDeliverModal()}>
                  Deliver
                </MenuItem>
              )}
              {props.project.isArchived === 1 && (
                <MenuItem>Cannot deliver from an archived project</MenuItem>
              )}
            </Menu>
          </TableBody>
        </Table>
      )}
    </TableContainer>
  );
}
