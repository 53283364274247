import React, { useState, setState } from "react";
import styles from "./Invoice.module.scss";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useEffect } from "react";
import axios from "axios"
import { LinearProgress, Button, CircularProgress } from "@material-ui/core";
import { getTodaysDate } from "../../../../utils/TimeHelpers";

const RolloverConfirmation = (props) => {
    const {
        agent,
        rolloverData,
        scheduleItem,
        person,
        handleSendRollover,
        handleClose
    } = props;

    const [contract, setcontract] = useState();//pull from DB?
    const [company, setcompany] = useState();
    const [talentFee, settalentFee] = useState();
    const [bookingFee, setbookingFee] = useState();
    const [loading, setLoading] = useState(false);

    const returnDate = () => {
        var date = new Date(scheduleItem.startTime);
        var dd = String(date.getDate()).padStart(2, "0");
        var mm = String(date.getMonth() + 1).padStart(2, "0");
        var yyyy = date.getFullYear();
        return dd + "/" + mm + "/" + yyyy;
    };

    useEffect(() => {
        var contractRequest = axios.get(`TalentContracts/GetTalentContractWithTalentInvoice/${scheduleItem.id}`);
        var companyRequest = axios.get(`Companies/GetCompany/${agent.companyFk}`);

        axios.all([contractRequest, companyRequest])
            .then(axios.spread((...responses) => {
                const contract = responses[0].data;
                const company = responses[1].data;
                setcontract(contract);
                setcompany(company);
            })
            ).catch(e => {
                console.log(e)
            })

    }, []);

    const returnGST = () => {
        if (contract.talentGst == 0) {
            return (bookingFee * 0.15).toFixed(2);
        } else {
            return (rolloverData.fee * 0.15).toFixed(2);
        }
    }

    useEffect(() => {
        if (contract) {
            if (contract.bookingFeeType === "Percentage") {
                settalentFee(
                    rolloverData.fee -
                    (rolloverData.fee * contract.bookingFee) / 100
                );
                setbookingFee(
                    rolloverData.fee * (contract.bookingFee / 100)
                );
            } else {
                settalentFee(rolloverData.fee - contract.bookingFee);
                setbookingFee(contract.bookingFee);
            }
        }
    }, [contract]);


    if (contract && company) {
        return (
            <div>
                <div className={styles.invoice}>
                    <div
                        id="InvoiceComponant"
                    >
                        <div className={styles.addresses}>
                            <div className={styles.from}>
                                <div>
                                    <strong>From: </strong>
                                    <br />
                                    <strong>{`${company.name}`}</strong>
                                    <br />
                                    {`${agent.firstName} ${agent.lastName}`}
                                    <br />
                                    {`${agent.emailAddress}`}
                                    <br />
                                    {`${agent.phoneNumber}`}
                                </div>
                            </div>
                            <div className={styles.fromTo}>
                                <strong>To: </strong>
                                <br />
                                <strong>Magnesium Limited</strong>
                                <br />
                                5A Owens Road
                                <br />
                                Epsom, Auckland &nbsp;1023
                                <br />
                                accounts@magnesium.nz
                            </div>
                            <div>
                                <div className={`${styles.valueTable} ${styles.to}`}>
                                    <div className={styles.row}>
                                        <div className={styles.label}>Date</div>
                                        <div className={`${styles.value} ${styles.date}`}>
                                            {`${getTodaysDate()}`}
                                        </div>
                                    </div>
                                    <div className={styles.row}>
                                        <div className={styles.label}>GST#</div>
                                        <div className={styles.value}>
                                            {company.gstnumber}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h2 className={styles.invoiceTitle}>
                            {"Tax Invoice"}
                        </h2>
                        <table className={styles.invoiceTable}>
                            <thead className={styles.invoiceTableHeader}>
                                <tr className={styles.invoiceTableRow}>
                                    <td>Date</td>
                                    <td>Item</td>
                                    <td>Quantity</td>
                                    <td className={styles.columnMinWidth}>Fee</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className={styles.invoiceTableRow}>
                                    <td>{returnDate()}</td>
                                    <td>
                                        {/* .. */}
                                        <p>{`Rollover for ${person.firstName} ${person.lastName}`}</p>
                                        <p>{`Job: ${rolloverData.job}`}</p>
                                        <p>{`Duration: ${rolloverData.duration}`}</p>
                                        <br />
                                    </td>
                                    <td>1</td>
                                    {agent != null && (contract.talentGst == "1") ? (
                                        <td>${rolloverData.fee.toFixed(2)}</td>
                                    ) : (
                                        <td>{`$${talentFee.toFixed(2)}`}</td>
                                    )}
                                </tr>
                                {agent != null && contract.talentGst == "0" && (
                                    <tr className={styles.invoiceTableRow}>
                                        <td></td>
                                        <td>Booking Fee (+ GST)</td>
                                        <td>1</td>
                                        <td>{`$${bookingFee.toFixed(2)}`}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        <table className={styles.invoiceFooterTable}>
                            <tr>
                                <td colSpan="3">
                                    {/* Payment to be made to Bank Account # {`${agent.companyFkNavigation.bankAccount}`} */}
                                    <br />
                                    <br />
                                </td>
                            </tr>
                        </table>
                        <div className={styles.totalContainer}>
                            <form></form>
                            <form>
                                <div className={styles.valueTable}>
                                    <div className={styles.row}>
                                        <div className={styles.label}>Sub Total</div>
                                        <div className={`${styles.value} ${styles.currency}`}>
                                            ${rolloverData.fee.toFixed(2)}
                                        </div>
                                    </div>
                                    {(person.bcorIc === "ic" && person.gstRegistered === 1) ||
                                        (person.bcorIc === "bc" && person.bcisGstreg === 1) ||
                                        agent != null ? (
                                        <div className={styles.row}>
                                            <div className={styles.label}>GST (15%)</div>
                                            <div className={`${styles.value} ${styles.currency}`}>
                                                ${returnGST()}
                                            </div>
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                    <div className={styles.row}>
                                        <div className={styles.label}>Total Due</div>
                                        <div className={`${styles.value} ${styles.currency}`}>
                                            ${(Number(rolloverData.fee.toFixed(2)) + Number(returnGST())).toFixed(2)}
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className={styles.invoice}>
                    {loading ? (
                        <CircularProgress
                            style={{float: "right"}}
                        />
                    ) : (
                        <React.Fragment>
                            <Button
                                style={{ float: "left" }}
                                variant="contained"
                                onClick={handleClose}
                            >
                                Go Back
                            </Button>
                            <Button
                                style={{ float: "right" }}
                                color="primary"
                                variant="contained"
                                onClick={() => {
                                    setLoading(true);
                                    handleSendRollover();
                                }}
                            >
                                Send Rollover
                            </Button>
                        </React.Fragment>
                    )}
                </div>
            </div>
        )
    } else {
        return (<div className={styles.invoice}>
            <LinearProgress />
        </div>)
    }

}

export default RolloverConfirmation;