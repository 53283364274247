import {
  Box,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  IconButton
} from "@material-ui/core";
import {
  ArrowRight as ArrowRightIcon,
  ArrowLeft as ArrowLeftIcon
} from "@material-ui/icons"
import React, { useEffect } from "react";
import "./customTableHead.scss";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));
const CustomTableHead = ({
  headers,
  orderBy,
  order,
  onRequestSort,
  hasHiddenColumns = false,
  hiddenColHeaderName,
  handleHiddenColumnIconClick,
  showHidden
}) => {
  const classes = useStyles();
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };


  return (
    <TableHead className="thead-custom-table-head">
      <TableRow>
        {headers.map((header, index) => {
          if(header.canBeHidden && !showHidden){
            return;
          }
          return (
            <TableCell
              key={index}
              align={`${header?.alignment ? header.alignment : "center"}`}
              sortDirection={orderBy === header.field ? order : false}
              style={{ paddingRight: 0 }}
            >
              <TableSortLabel
                active={orderBy === header.field}
                direction={orderBy === header.field ? order : "asc"}
                onClick={createSortHandler(header)}
                className="thead-sorting-header"
                hideSortIcon={false}
              >
                {header.name}
                {orderBy === header.field ? (
                  <span className={classes.visuallyHidden}>
                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                  </span>
                ) : null}
                {hasHiddenColumns && hiddenColHeaderName === header.name && (
                  <IconButton size="small" onClick={(e) => { e.stopPropagation(); handleHiddenColumnIconClick(); }}>
                    {showHidden ? <ArrowLeftIcon fontSize="small"/> : <ArrowRightIcon fontSize="small"/>}
                  </IconButton>
                )}
              </TableSortLabel>
  
            </TableCell>
          )

        })}
      </TableRow>
    </TableHead>
  );
};

export default CustomTableHead;
