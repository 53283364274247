import React, { useEffect, useState } from "react";
import Axios from "axios";
import { connect } from "react-redux";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Slide } from "react-slideshow-image";
import SlideShow from "./SlideShow";
import { Previews } from "./DragAndDrop";
import "./LocationDetailsPopUp.css";
import { useHistory, useLocation } from "react-router-dom";
import ContactTable from "./Contact/ContactTable";
import PropTypes from "prop-types";
import Gallery from "react-grid-gallery";
import GooglePlacesEdit from "./GooglePlacesEdit";
import AddNewContact from "./AddNewContact";
import HistoryTable from "../../Contract/HistoryTable";
import {
  Button,
  Grid,
  Modal,
  AppBar,
  Toolbar,
  Typography,
  TextField,
  Tab,
  Tabs,
  Paper,
  Box,
  InputAdornment,
  IconButton,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  Tooltip,
  Switch,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import Loader from "./../../../Loader";
import NotificationImportantIcon from "@material-ui/icons/NotificationImportant";
import SimpleAlerts from "../../ProjectOutputs/PopUps/CompleteInfoNotification";
import { setDialogProps } from "../../../../actions/dialog";
import Dialogy from "../../../Shared/Dialogy";
import { checkEmail } from "../../../../utils/Validation";
import { getSchedule } from "../../../../actions/schedules";
import { useUserContext } from "../../../../context/user/UserContext";
import { useAuth } from "../../../../context/auth/AuthContext";

const properties = {
  duration: 5000,
  transitionDuration: 500,
  autoplay: false,
  infinite: true,
  indicators: false,
  scale: 0.4,
  arrows: true,
};

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: false,
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    // height: '80vH',
    // width: '80vW',
    height: "500px",
    width: "1000px",
    overflowY: "auto",
  },
  grid: {
    marginBottom: "10px",
    marginLeft: "2px",
    marginLeft: "2px",
  },
}));

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
  position: "relative",
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
      {/* {value === index ? 
        <Box p={3}>{children}</Box>
        : <React.Fragment></React.Fragment>
      } */}
    </Typography>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `location-details-tab-${index}`,
    "aria-controls": `location-details-tabpanel-${index}`,
  };
}

function LocationsDetailsPopUp(props) {
  const classes = useStyles();
  const location = useLocation();
  const [showWarning, setShowWarning] = useState(false);
  const [value, setValue] = useState(
    props.initialTab !== null ? props.initialTab : 0
  );
  const [imgGallery, setImgGallery] = useState([]);
  const [savedImgGallery, setSavedImgGallery] = useState(false);

  const [allPeople, setAllPeople] = useState([]);
  const [fetchContact, setFetchContact] = useState(true);

  const [googleAddress, setGoogleAddress] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [locationNotes, setLocationNotes] = useState({
    floorType: " ",
    roofHeight: " ",
    recceStatus: " ",
    videosLink: " ",
    floorPlanLink: " ",
    windowsNotes: " ",
    parkingNotes: " ",
    furnitureNotes: " ",
    sunNotes: " ",
    lightNotes: " ",
    specialNotes: " ",
    evacuationProcedure: " ",
    accessDetails: " ",
  });
  const {
    floorType,
    roofHeight,
    recceStatus,
    videosLink,
    floorPlanLink,
    windowsNotes,
    parkingNotes,
    furnitureNotes,
    sunNotes,
    lightNotes,
    specialNotes,
    evacuationProcedure,
    accessDetails,
  } = locationNotes;

  const [locationDetails, setLocationDetails] = React.useState({
    pricing: " ",
    indoor: false,
    outdoor: false,
    address: "",
    locationFinanceFk: null,
    personFk: null,
  });
  const {
    pricing,
    indoor,
    outdoor,
    address,
    locationFinanceFk,
    personFk,
  } = locationDetails;

  const [latLngDetails, setLatLngDetails] = React.useState({
    longitude: "",
    latitude: "",
  });
  const { longitude, latitude } = latLngDetails;

  const [locationFinance, setLocationFinance] = React.useState({
    firstName: " ",
    lastName: " ",
    paymentMethod: " ",
    companyName: " ",
    gstRegistered: " ",
    gstNumber: " ",
    bankAccount: " ",
  });
  const {
    firstName,
    lastName,
    paymentMethod,
    companyName,
    gstRegistered,
    gstNumber,
    bankAccount,
  } = locationFinance;

  const [locationContact, setLocationContact] = React.useState({
    contactId: null,
    contactFirstName: "",
    contactLastName: "",
    contactEmail: "",
    contactPhone: "",
    enableSms: null,
  });
  const {
    contactId,
    contactFirstName,
    contactLastName,
    contactEmail,
    contactPhone,
    enableSms,
  } = locationContact;

  const { user } = useAuth();
  const [currentLocation, setCurrentLocation] = React.useState({});
  const [fetchedLocation, setFetchedLocation] = React.useState(false);
  const [updatingLocation, setUpdatingLocation] = React.useState(false);
  const [hasTempPhoto, setHasTempPhoto] = React.useState(false);

  const [imgsToDelete, setImgsToDelete] = useState([]);
  const [cloudFilesToDelete, setCloudFilesToDelete] = useState([]);
  const [updatingImages, setUpdatingImages] = useState(false);
  const [newImages, setNewImages] = useState([]);

  const [temporaryImages, setTemporaryImages] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const [newLocationAddress, setNewLocationAddress] = React.useState("");
  const [locationObj, setLocationObj] = React.useState(null);

  const [peopleFetched, setPeopleFetched] = useState(false);

  const [contactFocus, setContactFocus] = useState({ fullName: "" });

  const [addNewContact, setAddNewContact] = useState(false);

  const [contractList, setContractList] = useState();
  const [fetchContractList, setFetchContractList] = useState(true);

  const [locationContactCheckbox, setLocationContactCheckbox] = useState(false);

  const StyledDelete = withStyles((theme) => ({
    root: {
      position: "absolute",
      right: "2px",
      color: "rgba(0, 0, 0, 0.54)",
      cursor: "pointer",
    },
  }))(DeleteIcon);

  const StyledButton = withStyles((theme) => ({
    root: {
      color: "#000000",
      borderRadius: "20%",
    },
  }))(Button);

  const StyledButtonCreateContact = withStyles((theme) => ({
    root: {
      background: "#00b2e2",
      color: "white",
      marginBottom: "1%",
      marginTop: "1%",
      marginLeft: "1%",
      marginRight: "1%",
      "&:hover": {
        background: "#008cb3",
      },
    },
  }))(Button);

  useEffect(() => {
    console.log(locationObj);
  }, [locationObj]);

  useEffect(() => {
    if (!peopleFetched) {
      Axios.get("/People/GetLocationContacts")
        .then((response) => {
          console.log("GetLocationContactsdetailspopup");
          console.log(response.data);
          setPeopleFetched(true);
          setAllPeople(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [peopleFetched]);

  const fetchLocationContact = () => {
    console.log("canceled");
    setFetchContact(true);
  };

  // useEffect(() => {
  //   if (imgGallery.length === 0 && !savedImgGallery) {
  //     console.log("No images in state");
  //     setSavedImgGallery(true);
  //     var images = [];
  //     props.location.locationImage.map((image) => {
  //       images.push({
  //         src: image.locationUrl,
  //         thumbnail: image.locationUrl,
  //         thumbnailWidth: "auto",
  //         id: image.id,
  //         isSelected: false,
  //         cloudId: image.imageRoom,
  //       });
  //     });
  //     console.log(images);
  //     setImgGallery(images);
  //   }
  // });

  useEffect(() => {
    if (!fetchedLocation && props.location.id !== "blank") {
      setFetchedLocation(true);
      Axios.get(`Locations/GetLocation/${props.location.id}`)
        .then((res) => {
          var location = res.data;
          console.log("==================");
          console.log(location);
          setCurrentLocation(location);
          setLocationNotes({
            floorType: location.floorType,
            roofHeight: location.roofHeight,
            recceStatus: location.recceStatus,
            videosLink: location.videosLink,
            floorPlanLink: location.floorPlanLink,
            windowsNotes: location.windowsNotes,
            parkingNotes: location.parkingNotes,
            furnitureNotes: location.furnitureNotes,
            sunNotes: location.sunNotes,
            lightNotes: location.lightNotes,
            specialNotes: location.specialNotes,
            evacuationProcedure: location.evacuationProcedure,
            accessDetails: location.accessDetails,
          });
          setLocationDetails({
            pricing: location.pricing,
            indoor: location.isIndoor,
            outdoor: location.isOutdoor,
            address: location.address,
            locationFinanceFk: location.locationFinanceFk,
            personFk: location.primaryContact,
          });
          setLatLngDetails({
            longitude: location.longitude,
            latitude: location.latitude,
          });
          try {
            var images = [];
            location.locationImage.map((image) => {
              images.push({
                src: image.locationUrl,
                thumbnail: image.locationUrl,
                thumbnailWidth: "auto",
                id: image.id,
                isSelected: false,
                cloudId: image.imageRoom,
              });
            });
            console.log(images);
            setImgGallery(images);
          } catch (e) {
            console.log(e);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  });

  useEffect(() => {
    if (locationFinanceFk !== null) {
      Axios.get(`LocationFinances/GetLocationFinance/${locationFinanceFk}`)
        .then((res) => {
          setLocationFinance({
            firstName: res.data.firstName,
            lastName: res.data.lastName,
            paymentMethod: res.data.paymentMethod,
            companyName: res.data.companyName,
            gstRegistered: res.data.gstRegistered,
            gstNumber: res.data.gstNumber,
            bankAccount: res.data.bankAccount,
          });
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [locationFinanceFk]);

  useEffect(() => {
    if (personFk !== null && fetchContact) {
      Axios.get(`People/GetPerson/${personFk}`)
        .then((res) => {
          console.log("Person!!!");
          console.log(res);
          setLocationContact({
            contactId: res.data.id,
            contactFirstName: res.data.firstName,
            contactLastName: res.data.lastName,
            contactEmail: res.data.emailAddress,
            contactPhone: res.data.phoneNumber,
            enableSms: res.data.enableSms,
          });
          setFetchContact(false);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [personFk, fetchContact]);

  useEffect(() => {
    //console.log(`delete img: `);
    //console.log(imgsToDelete);
  }, [imgsToDelete]);

  useEffect(() => {
    setUpdatingImages(false);
    console.log("imgGallery");
    console.log(imgGallery);
  }, [imgGallery]);

  useEffect(() => {
    //console.log(newImages);
    setTemporaryImages(newImages);
  }, [newImages]);

  useEffect(() => {
    //console.log(cloudFilesToDelete);
  }, [cloudFilesToDelete]);

  useEffect(() => {
    //console.log('temporaryImages');
    //console.log(temporaryImages);
  }, [temporaryImages]);

  useEffect(() => {
    if (address !== null || address !== "" || address !== " ") {
      console.log(address);
      let rawAddress = address;
      let res = rawAddress.replace(/ /g, "%20");
      res = res.replace(/,/g, "%2C");
      res = res.replace(/|/, "%7C");
      setGoogleAddress(res);
    }
  }, [address]);

  useEffect(() => {
    if (fetchContractList) {
      Axios.get(`People/GetLocationHistory/${props.location.id}`)
        .then((res) => {
          console.log("GetLocationHistory");
          console.log(res);
          var contracts = res.data;
          contracts.map((c) => {
            if (c.dateSigned !== null) {
              c.dateSigned = new Date(c.dateSigned);
            }
          });
          setContractList(contracts);
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          setFetchContractList(false);
        });
    }
  }, [fetchContractList]);

  const toggleAddNewContact = () => {
    if (addNewContact == false) {
      setAddNewContact(true);
    } else if (addNewContact == true) {
      setAddNewContact(false);
    }
  };

  const closeAddNewContact = () => {
    setAddNewContact(false);
  };

  const onSelectLocation = () => {
    props.handleModal();
    if (props.setLocation) {
      props.setLocation(props.location);
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleEditChange = () => {
    if (isEditing) {
      setIsEditing(false);
    } else {
      setIsEditing(true);
    }
  };

  const handleSetLocationContact = (value) => {
    setLocationContact({
      contactId: value.id,
      contactFirstName: value.firstName,
      contactLastName: value.lastName,
      contactEmail: value.emailAddress,
      contactPhone: value.phoneNumber,
      enableSms: value.enableSms,
    });
  };

  const handleLocationNotesChange = (note) => (event) => {
    setLocationNotes({ ...locationNotes, [note]: event.target.value });
  };

  const handleLocationContactChange = (detail) => (event) => {
    if (detail === "enableSms") {
      setLocationContact({
        ...locationContact,
        [detail]: event.target.checked ? 1 : 0,
      });
    } else {
      setLocationContact({ ...locationContact, [detail]: event.target.value });
    }
  };

  const handleEnvironmentChange = (detail) => (event) => {
    setLocationDetails({ ...locationDetails, [detail]: event.target.checked });
  };

  const handlePricingChange = (detail) => (event) => {
    setLocationDetails({ ...locationDetails, ["pricing"]: event.target.value });
  };

  const handleLocationFinanceChange = (key) => (event) => {
    setLocationFinance({ ...locationFinance, [key]: event.target.value });
  };

  const handleGSTChange = (key) => (event) => {
    var value = event.target.checked;
    setLocationFinance({ ...locationFinance, [key]: value });
  };

  const handleAddressChange = (address) => {
    console.log(address);
    setLocationDetails({ ...locationDetails, ["address"]: address });
    console.log(address);
  };

  const handleLatLngChange = (name) => (event) => {
    setLocationDetails({ ...locationDetails, [name]: event.target.value });
  };

  const checkPaymentMethod = () => {
    switch (paymentMethod) {
      case undefined:
        return false;
        break;
      case null:
        return false;
        break;
      default:
        return true;
        break;
    }
  };

  const updateLocation = () => {
    if (props.parent === "scheduleLocation") {
      props.updateLocationContact({
        locationFk: currentLocation.id,
        contact: {
          id: contactId,
          firstName: contactFirstName,
          lastName: contactLastName,
          fullName: `${contactFirstName} ${contactLastName}`,
          phoneNumber: contactPhone,
          emailAddress: contactEmail,
          personCategory: "Location Contact",
        },
      });
    }
    setUpdatingLocation(true);
    setLoading(true);

    var count = 0; //used to wait for all calls to finish

    var contactUpdateId;
    if (contactId == null) {
      contactUpdateId = 0;
    } else {
      contactUpdateId = contactId;
    }

    Axios.put(
      `Locations/UpdateContactFromLocation/${props.location.id}/${contactUpdateId}`
    )
      .then((res) => {
        console.log(res);
      })
      .catch((error) => {
        console.log(error);
      });

    if (contactId) {
      Axios.put(`People/UpdateContact/${contactUpdateId}?user=${user?.email}`, {
        id: contactId,
        firstName: contactFirstName,
        lastName: contactLastName,
        fullName: `${contactFirstName} ${contactLastName}`,
        phoneNumber: contactPhone,
        emailAddress: contactEmail,
        personCategory: "Location Contact",
      })
        .then((res) => {
          console.log(res);
        })
        .catch((e) => {
          console.log(e);
        });
    }

    if (cloudFilesToDelete.length > 0) {
      console.log("There are files to delete: ");
      console.log(cloudFilesToDelete);

      var files = newImages;
      cloudFilesToDelete.forEach((id, index) => {
        // console.log(id);
        // console.log(imgsToDelete[index]);
        Axios.post(`LocationImage/DeleteImage`, {
          cloudId: id,
          dbId: imgsToDelete[index],
        })
          .then((res) => {
            count++;
            console.log("Finished Delete");
            console.log(count);
            if (count >= cloudFilesToDelete.length) {
              console.log("HIT!!!!");
              handleSaveImages(files);
            } else {
              console.log("count++");
            }
          })
          .catch((e) => {
            console.log("Error on: " + id);
            console.log(e);
            count++;
          });
      });
    } else {
      console.log("There are No files to delete");
      handleSaveImages(newImages);
    }

    var price, roof, isIndoor, isOutdoor, locationAddress;
    try {
      //Check Price
      price = parseFloat(pricing);
    } catch (e) {
      console.log(e);
      price = null;
    }
    try {
      //Check Roof
      roof = parseFloat(roofHeight);
    } catch (e) {
      console.log(e);
      roof = null;
    }
    //Check Environment
    if (outdoor) {
      isOutdoor = 1;
    } else {
      isOutdoor = 0;
    }
    if (indoor) {
      isIndoor = 1;
    } else {
      isIndoor = 0;
    }
    //Check Address
    if (newLocationAddress !== "") {
      locationAddress = newLocationAddress;
    } else {
      locationAddress = props.location.address;
    }
    //Check gstRegistered
    var gstReg;
    if (gstRegistered === false) {
      gstReg = 0;
    } else {
      gstReg = 1;
    }
    Axios.put(`LocationFinances/UpdateLocationFinance/${locationFinanceFk}`, {
      id: locationFinanceFk,
      firstName: firstName,
      lastName: lastName,
      paymentMethod: paymentMethod,
      companyName: companyName,
      gstRegistered: paymentMethod == "individual" ? 0 : gstReg,
      gstNumber: gstNumber,
      bankAccount: bankAccount,
    })
      .then((res) => {
        console.log("YEP");
        console.log(res);
      })
      .catch((e) => {
        console.log(e);
        console.log("NOPE");
      });
    Axios.put(`Locations/UpdateLocationDetails/${currentLocation.id}`, {
      id: currentLocation.id,
      floorType: floorType,
      roofHeight: roof,
      recceStatus: recceStatus,
      videosLink: videosLink,
      floorPlanLink: floorPlanLink,
      windowsNotes: windowsNotes,
      parkingNotes: parkingNotes,
      furnitureNotes: furnitureNotes,
      sunNotes: sunNotes,
      lightNotes: lightNotes,
      specialNotes: specialNotes,
      evacuationProcedure: evacuationProcedure,
      accessDetails: accessDetails,
      pricing: price,
      isIndoor: isIndoor,
      isOutdoor: isOutdoor,
      address: locationAddress,
      latitude: latitude,
      longitude: longitude,
    })
      .then((res) => {
        props.setLoadingImagesTrue();
        var images = imgGallery;
        if (temporaryImages.length > 0) {
          setHasTempPhoto(true);
          temporaryImages.map((img) => {
            images.push({
              src: img.preview,
              thumbnail: img.preview,
              thumbnailWidth: "auto",
            });
          });
          setImgGallery(images);
        }
        setUpdatingLocation(false);
        setFetchedLocation(false);
        setIsEditing(false);
        setNewImages([]);
        setCloudFilesToDelete([]);
        setImgsToDelete([]);
        setHasTempPhoto(false);
      })
      .catch((error) => {
        console.log(error);
        setUpdatingLocation(false);
        setFetchedLocation(false);
        setNewImages([]);
        setCloudFilesToDelete([]);
        setImgsToDelete([]);
      });
  };

  const onSelectImage = (index, image) => {
    // console.log("onSelectImage");
    // console.log("index: "+index);
    // console.log("image: ");
    // console.log(image);
    try {
      if (image.isSelected === false) {
        image.isSelected = true;
      } else {
        image.isSelected = false;
      }
    } catch (e) {
      console.log(e);
    }
  };

  const deleteSelectedImages = () => {
    setUpdatingImages(true);
    var newImgsToDelete = imgsToDelete;
    var newCloudFilesToDelete = cloudFilesToDelete;
    var remainingImgs = [];

    imgGallery.map((img) => {
      if (img.isSelected) {
        newImgsToDelete.push(img.id);
        newCloudFilesToDelete.push(img.cloudId);
      } else {
        remainingImgs.push(img);
      }
    });

    setImgsToDelete(newImgsToDelete);
    setCloudFilesToDelete(newCloudFilesToDelete);
    setImgGallery(remainingImgs);
  };

  const handleImageSelectChange = (images) => {
    //console.log(images);
    var arr = Array.from(images);
    arr.map((img) => {
      Object.assign(img, {
        preview: URL.createObjectURL(img),
      });
    });
    setNewImages(arr);
  };

  const setNewContact = (value) => {
    setLocationContact({
      contactId: value.id,
      contactFirstName: value.firstName,
      contactLastName: value.lastName,
      contactEmail: value.emailAddress,
      contactPhone: value.phoneNumber,
      enableSms: value.enableSms,
    });
    setAddNewContact(false);
  };

  const handleSaveImages = (files) => {
    // var files = newImages;
    console.log("Handle Save Images");
    console.log("Images To Save: ");
    console.log(files);
    var formData = new FormData();

    files.map((file) => {
      formData.append("file", file);
    });

    //console.log(formData);

    formData.append("locationId", props.location.id);
    // for (var key of formData.entries()) {
    //     console.log(key[0] + ", " + key[1]);
    // }

    Axios.post("/CloudStorage/UploadImages", formData, {
      headers: { "content-type": "multipart/form-data" },
    })
      .then((res) => {
        console.log(res);
        props.setLoadingImagesFalse();
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        props.setLoadingImagesFalse();
        setLoading(false);
      });
  };

  const handleRemoveFile = (file) => {
    setNewImages(newImages.filter((item) => item !== file));
    URL.revokeObjectURL(file.preview);
  };

  let thumbs = newImages.map((file) => (
    <div style={thumb} key={file.name}>
      {/* {console.log(" !!!!!!!!!!!!!!!!!!! ")}
            {console.log(file)} */}
      <div style={thumbInner}>
        <StyledDelete
          onClick={() => {
            handleRemoveFile(file);
          }}
        />
        <img src={file.preview} style={img} />
      </div>
    </div>
  ));

  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }))(Tooltip);

  const handleDelete = (id) => {
    console.log(id);
    setLocationContact({
      contactId: null,
      contactFirstName: "",
      contactLastName: "",
      contactEmail: "",
      contactPhone: "",
      enableSms: null,
    });
  };

  return (
    <div>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={props.modalState}
        onClose={props.handleModal}
        className={classes.modal}
        disableBackdropClick={true}
      >
        <div>
          {showWarning ? (
            <Dialogy
              setDialogProps={setDialogProps}
              buttonProps={{
                show: showWarning,
                titleText: "Invalid data",
                contentText:
                  "One of the fields is incorrect. Please fix this before continuing",
                buttonOneColor: "primary",
                buttonOneText: "Cancel",
                buttonTwoColor: "primary",
                buttonTwoText: "Ok",
              }}
              handleButtonOne={() => setShowWarning(false)}
              handleButtonTwo={() => setShowWarning(false)}
            />
          ) : (
            <React.Fragment></React.Fragment>
          )}
          <AppBar position="static" style={{ background: "#217F8B" }}>
            <Toolbar variant="dense">
              <Typography
                variant="subtitle1"
                align="center"
                style={{ flexGrow: 1 }}
              >
                Location Details
              </Typography>
              <IconButton
                aria-haspopup="true"
                onClick={props.handleModal}
                color="inherit"
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <div className={classes.paper}>
            {loading ? <Loader /> : ""}
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              {!isEditing && props.parent !== "SpamBotTable" ? (
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  onClick={() => onSelectLocation()}
                  style={{ margin: "0px 5px 5px 5px" }}
                >
                  Choose This Location
                </Button>
              ) : (
                <React.Fragment></React.Fragment>
              )}
              {isEditing ? (
                !updatingLocation ? (
                  <div style={{ display: "flex" }}>
                    <Button
                      variant="contained"
                      size="small"
                      style={{
                        margin: "0px 5px 5px 5px",
                        backgroundColor: "#d3d3d3",
                        color: "black",
                      }}
                      onClick={() => {
                        console.log("Cleared");
                        setFetchedLocation(false);
                        setSavedImgGallery(false);
                        setNewLocationAddress("");
                        setImgGallery([]);
                        setImgsToDelete([]);
                        setIsEditing(false);
                        fetchLocationContact();
                        closeAddNewContact();
                      }}
                    >
                      <span style={{ marginRight: "5px" }}>Cancel</span>
                      <HighlightOffIcon />
                    </Button>
                    <Button
                      variant="contained"
                      size="small"
                      style={{
                        margin: "0px 5px 5px 5px",
                        backgroundColor: "green",
                        color: "white",
                      }}
                      onClick={() => updateLocation()} //checkEmail(contactEmail) ? setShowWarning(true) :
                    >
                      <span style={{ marginRight: "5px" }}>Save Changes</span>
                      <SaveIcon size="small" />
                    </Button>
                  </div>
                ) : (
                  <CircularProgress size={30} />
                )
              ) : (
                <IconButton
                  variant="contained"
                  size="small"
                  style={{ margin: "0px 5px 5px 5px", color: "black" }}
                  onClick={() => handleEditChange()}
                >
                  <EditIcon size="small" />
                </IconButton>
              )}
            </div>
            <AppBar position="static">
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="Location Details AppBar"
              >
                <Tab label="Images" {...a11yProps(0)} />
                {locationFinance.paymentMethod.length > 2 ? (
                  <Tab label="Financial" {...a11yProps(1)} />
                ) : (
                  <HtmlTooltip
                    title={"Please click to enter details"}
                    interactive
                    arrow
                    placement="top"
                  >
                    <Tab
                      label={
                        <div>
                          <NotificationImportantIcon
                            style={{ verticalAlign: "middle" }}
                          />{" "}
                          Financial
                        </div>
                      }
                      {...a11yProps(1)}
                      style={{ background: "#ff0059" }}
                    />
                  </HtmlTooltip>
                )}
                <Tab label="Contact" {...a11yProps(2)} />
                <Tab label="Details" {...a11yProps(3)} />
                <Tab label="Notes" {...a11yProps(4)} />
                <Tab label="History" {...a11yProps(5)} />
              </Tabs>
            </AppBar>
            {/* Images Panel */}
            <TabPanel value={value} index={0}>
              <Grid container>
                <Grid item xs={6} md={6} lg={6}>
                  {isEditing ? (
                    <Button
                      color="primary"
                      variant="contained"
                      size="small"
                      onClick={() =>
                        document.getElementById("file-input").click()
                      }
                    >
                      <span style={{ marginRight: "5px" }}>Upload Images</span>
                      <input
                        id="file-input"
                        style={{ display: "none" }}
                        type="file"
                        onChange={(e) =>
                          handleImageSelectChange(e.target.files)
                        }
                        multiple={true}
                      />
                      <CloudUploadIcon />
                    </Button>
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={6}
                  lg={6}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  {isEditing ? (
                    <Button
                      variant="contained"
                      size="small"
                      style={{
                        margin: "0px 5px 5px 5px",
                        color: "white",
                        backgroundColor: "red",
                      }}
                      onClick={deleteSelectedImages}
                    >
                      <span style={{ marginRight: "5px" }}>
                        Delete Selected Images
                      </span>
                      <DeleteForeverIcon size="small" />
                    </Button>
                  ) : (
                    ""
                  )}
                </Grid>
              </Grid>
              <div>
                {isEditing ? thumbs : ""}
                {imgGallery.length !== 0 && !updatingImages && !hasTempPhoto ? (
                  <div
                    style={{
                      display: "block",
                      minHeight: "1px",
                      width: "100%",
                      border: "1px solid #ddd",
                      overflow: "auto",
                    }}
                  >
                    <Gallery
                      images={imgGallery}
                      showLightboxThumbnails={true}
                      onSelectImage={
                        isEditing
                          ? (index, image) => onSelectImage(index, image)
                          : () =>
                              alert(
                                "Click the edit button to start editing images"
                              )
                      }
                    />
                    {/* <SlideShow 
                                        images={props.location.locationImage} 
                                        location={props.location}
                                        height='60vH'
                                    /> */}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </TabPanel>
            {/* Finance Panel */}
            <TabPanel value={value} index={1}>
              <FormControl
                component="fieldset"
                className={classes.formControl}
                style={{ width: "100%", marginBottom: "30px" }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={2} md={2} lg={2}>
                    <FormLabel
                      component="legend"
                      style={{
                        color: "#2d2d2d",
                        fontSize: "1.3em",
                        lineHeight: 1.8,
                      }}
                    >
                      Invoice as:{" "}
                    </FormLabel>
                  </Grid>
                  <Grid item xs={4} md={4} lg={4}>
                    <RadioGroup
                      value={paymentMethod}
                      onChange={handleLocationFinanceChange("paymentMethod")}
                      row={true}
                    >
                      <FormControlLabel
                        value="individual"
                        control={<Radio disabled={isEditing ? false : true} />}
                        label="Individual"
                      />
                      <FormControlLabel
                        value="company"
                        control={<Radio disabled={isEditing ? false : true} />}
                        label="Company"
                      />
                    </RadioGroup>
                  </Grid>
                  <Grid item xs={6} md={6} lg={6}>
                    {console.log("paymentMethod")}
                    {console.log(paymentMethod)}
                    {paymentMethod !== " " ? (
                      ""
                    ) : isEditing ? (
                      <SimpleAlerts text={"Select an option to start"} />
                    ) : (
                      <SimpleAlerts
                        text={
                          "Please go to edit mode and select an option to start"
                        }
                      />
                    )}
                  </Grid>
                </Grid>
              </FormControl>
              <div
                style={
                  checkPaymentMethod()
                    ? paymentMethod === "individual"
                      ? {}
                      : { display: "none" }
                    : { display: "none" }
                }
              >
                <Grid container spacing={2}>
                  {/* Row 1 */}
                  <Grid item xs={6} md={6} lg={6}>
                    <TextField
                      label="First Name"
                      value={firstName}
                      defaultValue={" "}
                      disabled={!isEditing}
                      multiline={true}
                      variant="outlined"
                      style={{ width: "100%" }}
                      onChange={handleLocationFinanceChange("firstName")}
                    />
                  </Grid>
                  <Grid item xs={6} md={6} lg={6}>
                    <TextField
                      label="Last Name"
                      value={lastName}
                      defaultValue={" "}
                      disabled={!isEditing}
                      multiline={true}
                      variant="outlined"
                      style={{ width: "100%" }}
                      onChange={handleLocationFinanceChange("lastName")}
                    />
                  </Grid>
                  {/* Row 2 */}
                  <Grid item xs={12} md={12} lg={12}>
                    <TextField
                      label="Bank Account"
                      value={bankAccount}
                      defaultValue={" "}
                      disabled={!isEditing}
                      multiline={true}
                      variant="outlined"
                      style={{ width: "100%" }}
                      onChange={handleLocationFinanceChange("bankAccount")}
                    />
                  </Grid>
                </Grid>
              </div>
              <div
                style={
                  checkPaymentMethod()
                    ? paymentMethod === "company"
                      ? {}
                      : { display: "none" }
                    : { display: "none" }
                }
              >
                <Grid container spacing={2}>
                  {/* Row 1 */}
                  <Grid item xs={12} md={12} lg={12}>
                    <TextField
                      label="Company Name"
                      value={companyName}
                      defaultValue={" "}
                      disabled={!isEditing}
                      multiline={true}
                      variant="outlined"
                      style={{ width: "100%" }}
                      onChange={handleLocationFinanceChange("companyName")}
                    />
                  </Grid>
                  {/* Row 2 */}
                  <Grid item xs={12} md={12} lg={12}>
                    <TextField
                      label="Bank Account"
                      value={bankAccount}
                      defaultValue={" "}
                      disabled={!isEditing}
                      multiline={true}
                      variant="outlined"
                      style={{ width: "100%" }}
                      onChange={handleLocationFinanceChange("bankAccount")}
                    />
                  </Grid>
                  {/* Row 3 */}
                  <Grid item xs={6} md={6} lg={6}>
                    {isEditing ? (
                      <FormControlLabel
                        value="top"
                        control={
                          <Checkbox
                            checked={gstRegistered}
                            onChange={handleGSTChange("gstRegistered")}
                            value="primary"
                          />
                        }
                        label="GST Registered"
                        labelPlacement="top"
                      />
                    ) : (
                      <TextField
                        label="GST Registered"
                        multiline={true}
                        variant="outlined"
                        style={{ width: "100%" }}
                        value={gstRegistered ? "Yes" : "No"}
                        disabled={isEditing ? false : true}
                      />
                    )}
                  </Grid>
                  <Grid item xs={6} md={6} lg={6}>
                    <TextField
                      label="GST Number"
                      value={gstNumber}
                      defaultValue={" "}
                      disabled={!isEditing}
                      multiline={true}
                      variant="outlined"
                      style={{ width: "100%" }}
                      onChange={handleLocationFinanceChange("gstNumber")}
                    />
                  </Grid>
                </Grid>
              </div>
            </TabPanel>
            {/*Contacts Panel */}
            <TabPanel value={value} index={2}>
              {/* <ContactTable location={props.location} isEditing={isEditing} /> */}
              {contactFirstName !== "" ? (
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={6}
                    md={6}
                    lg={6}
                    style={{ marginBottom: "10px", minWidth: "40%" }}
                  >
                    <TextField
                      label="First Name"
                      multiline={true}
                      variant="outlined"
                      style={{ width: "100%" }}
                      value={contactFirstName}
                      disabled={isEditing ? false : true}
                      onChange={handleLocationContactChange("contactFirstName")}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    md={6}
                    lg={6}
                    style={{ marginBottom: "10px", minWidth: "40%" }}
                  >
                    <TextField
                      label="Last Name"
                      multiline={true}
                      variant="outlined"
                      style={{ width: "100%" }}
                      value={contactLastName}
                      disabled={isEditing ? false : true}
                      onChange={handleLocationContactChange("contactLastName")}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    style={{ marginBottom: "10px", minWidth: "40%" }}
                  >
                    <TextField
                      label="Phone Number"
                      multiline={true}
                      variant="outlined"
                      style={{ width: "100%" }}
                      value={contactPhone}
                      disabled={isEditing ? false : true}
                      onChange={handleLocationContactChange("contactPhone")}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    style={{ marginBottom: "10px", minWidth: "40%" }}
                  >
                    <TextField
                      error={checkEmail(contactEmail)}
                      helperText={
                        checkEmail(contactEmail)
                          ? "Not a valid email address"
                          : null
                      }
                      label="Email Address"
                      multiline={true}
                      variant="outlined"
                      style={{ width: "100%" }}
                      value={contactEmail}
                      disabled={isEditing ? false : true}
                      onChange={handleLocationContactChange("contactEmail")}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <Grid
                      component="label"
                      container
                      alignItems="center"
                      spacing={1}
                    >
                      <Grid
                        item
                        style={
                          enableSms === 1
                            ? { color: "grey" }
                            : {
                                borderBottom: "solid",
                                borderColor: isEditing ? "#3f51b5" : "grey",
                              }
                        }
                      >
                        Disable SMS Notifications
                      </Grid>
                      <Grid item>
                        <Switch
                          color="primary"
                          checked={enableSms === 1 ? true : false}
                          name="enableSms"
                          disabled={isEditing ? false : true}
                          onChange={handleLocationContactChange("enableSms")}
                          className={isEditing ? "blueSwitch" : ""}
                        />
                      </Grid>
                      <Grid
                        item
                        style={
                          enableSms === 1
                            ? {
                                borderBottom: "solid",
                                borderColor: isEditing ? "#3f51b5" : "grey",
                              }
                            : { color: "grey" }
                        }
                      >
                        Enable SMS Notifcations
                      </Grid>
                    </Grid>
                  </Grid>
                  {isEditing ? (
                    <React.Fragment>
                      <Grid
                        item
                        xs={6}
                        md={6}
                        lg={6}
                        style={{ minWidth: "40%" }}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              // name="no-location-contact-checkbox-edit"
                              checked={locationContactCheckbox}
                              onClick={(event) => {
                                if (event.target.checked) {
                                  setLocationContactCheckbox(true);
                                  handleDelete(props.location.id);
                                  // setContact(null);
                                  // setCheckboxClicked(true);
                                } else {
                                  setLocationContactCheckbox(true);
                                }
                              }}
                            />
                          }
                          label="Location is a public place"
                        />
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        md={6}
                        lg={6}
                        style={{ minWidth: "40%" }}
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          style={{ float: "right" }}
                          onClick={() => handleDelete(props.location.id)}
                        >
                          {/* <DeleteIcon
                            style={{ color: "rgba(0, 0, 0, 0.54)" }}
                          /> */}
                          Change Contact
                        </Button>
                      </Grid>
                    </React.Fragment>
                  ) : (
                    ""
                  )}
                </Grid>
              ) : (
                <div>
                  {addNewContact ? (
                    <AddNewContact
                      setNewContact={setNewContact}
                      closeAddNewContact={closeAddNewContact}
                    />
                  ) : (
                    <div>
                      {isEditing ? (
                        <div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={locationContactCheckbox}
                                  onClick={(event) => {
                                    if (event.target.checked) {
                                      setLocationContactCheckbox(true);
                                      handleDelete(props.location.id);
                                      // setContact(null);
                                      // setCheckboxClicked(true);
                                    } else {
                                      setLocationContactCheckbox(false);
                                    }
                                  }}
                                />
                              }
                              label="Location is a public place"
                            />
                          </div>
                          <div
                            style={
                              locationContactCheckbox
                                ? { display: "none" }
                                : { display: "flex", justifyContent: "center" }
                            }
                          >
                            <Autocomplete
                              id="add-location-contact-autocomplete"
                              options={allPeople}
                              value={contactFocus}
                              getOptionLabel={(option) => option.fullName}
                              style={{ width: "50%", marginRight: "15px" }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Select an existing location contact"
                                  variant="outlined"
                                  fullWidth
                                />
                              )}
                              // onChange={(e, value) => focusContact(e, value)}
                              onChange={(e, value) =>
                                value !== null
                                  ? handleSetLocationContact(value)
                                  : ""
                              }
                            />
                            <div
                              style={{
                                display: "flex",
                                marginTop: "auto",
                                marginBottom: "auto",
                              }}
                            >
                              <StyledButtonCreateContact
                                style={{ width: "210px" }}
                                id="add-new-contact-button"
                                aria-label="create a new contact"
                                onClick={() => toggleAddNewContact()}
                              >
                                Create New Contact
                              </StyledButtonCreateContact>
                            </div>
                          </div>
                        </div>
                      ) : (
                        "No contact currently added, please go to edit mode and add a contact"
                      )}
                    </div>
                  )}
                </div>
              )}
            </TabPanel>
            {/* Details Panel */}
            <TabPanel value={value} index={3}>
              <Grid container style={{ display: "flex" }}>
                <Grid item xs={6} md={6} lg={6}>
                  <div>
                    <iframe
                      style={{ border: "none", height: "50vH" }}
                      src={
                        googleAddress
                          ? `https://maps.google.com/maps?q='${googleAddress}'&t=y&z=16&output=embed`
                          : ""
                      }
                      width="100%"
                      height="100%"
                    ></iframe>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={6}
                  lg={6}
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <Grid container style={{ marginLeft: "5px" }}>
                    <Grid item xs={12} md={12} lg={12} className={classes.grid}>
                      <TextField
                        label="Original Address"
                        multiline={true}
                        variant="outlined"
                        style={{ width: "100%" }}
                        value={fetchedLocation ? props.location.address : ""}
                        disabled
                      />
                      {/* <TextField
                                                label="New Address"
                                                multiline={true}
                                                variant="outlined"
                                                //style={isEditing ? { width: "100%", marginTop: '10px' } : { display: 'none' }}//isEditing ? { display: 'none' } : 
                                                style={{ display: 'none' }}
                                                value={newLocationAddress}//newLocationAddress
                                                disabled
                                            /> */}
                      {isEditing ? (
                        <div>
                          <GooglePlacesEdit
                            disabled={isEditing ? false : true}
                            address={address}
                            changeAddress={handleAddressChange}
                            setLatLngDetails={setLatLngDetails}
                            setNewLocationAddress={setNewLocationAddress}
                            locationObj={locationObj}
                            setLocationObj={setLocationObj}
                            type="LocationEdit"
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} className={classes.grid}>
                      <TextField
                        label="Pricing"
                        multiline={true}
                        variant="outlined"
                        style={{ width: "100%" }}
                        value={fetchedLocation ? pricing : ""}
                        onChange={handlePricingChange("pricing")}
                        disabled={isEditing ? false : true}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={6} md={6} lg={6}>
                        {isEditing ? (
                          <FormControlLabel
                            value="top"
                            control={
                              <Checkbox
                                checked={indoor}
                                onChange={handleEnvironmentChange("indoor")}
                                value="primary"
                              />
                            }
                            label="Indoor"
                            labelPlacement="top"
                          />
                        ) : (
                          <TextField
                            label="Indoor"
                            multiline={true}
                            variant="outlined"
                            style={{ width: "100%" }}
                            value={
                              fetchedLocation
                                ? indoor === 1
                                  ? "Yes"
                                  : "No"
                                : ""
                            }
                            disabled={isEditing ? false : true}
                          />
                        )}
                      </Grid>
                      <Grid item xs={6} md={6} lg={6}>
                        {isEditing ? (
                          <FormControlLabel
                            value="top"
                            control={
                              <Checkbox
                                checked={outdoor}
                                onChange={handleEnvironmentChange("outdoor")}
                                value="primary"
                              />
                            }
                            label="Outdoor"
                            labelPlacement="top"
                          />
                        ) : (
                          <TextField
                            label="Outdoor"
                            multiline={true}
                            variant="outlined"
                            style={{ width: "100%" }}
                            value={
                              fetchedLocation
                                ? outdoor === 1
                                  ? "Yes"
                                  : "No"
                                : ""
                            }
                            disabled={isEditing ? false : true}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </TabPanel>
            {/* Notes Panel */}
            <TabPanel value={value} index={4}>
              <Grid container>
                <Grid
                  item
                  xs={6}
                  md={6}
                  lg={6}
                  style={{ marginBottom: "10px" }}
                >
                  <TextField
                    size="small"
                    label="Floor Type"
                    multiline={true}
                    variant="outlined"
                    style={{ width: "90%" }}
                    value={floorType}
                    disabled={isEditing ? false : true}
                    onChange={handleLocationNotesChange("floorType")}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={6}
                  lg={6}
                  style={{ marginBottom: "10px" }}
                >
                  <TextField
                    size="small"
                    label="Roof Height"
                    multiline={true}
                    variant="outlined"
                    style={{ width: "90%" }}
                    value={roofHeight}
                    disabled={isEditing ? false : true}
                    onChange={handleLocationNotesChange("roofHeight")}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={6}
                  lg={6}
                  style={{ marginBottom: "10px" }}
                >
                  <TextField
                    size="small"
                    label="Window Notes"
                    multiline={true}
                    variant="outlined"
                    style={{ width: "90%" }}
                    value={windowsNotes}
                    disabled={isEditing ? false : true}
                    onChange={handleLocationNotesChange("windowsNotes")}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={6}
                  lg={6}
                  style={{ marginBottom: "10px" }}
                >
                  <TextField
                    size="small"
                    label="Parking Notes"
                    multiline={true}
                    variant="outlined"
                    style={{ width: "90%" }}
                    value={parkingNotes}
                    disabled={isEditing ? false : true}
                    onChange={handleLocationNotesChange("parkingNotes")}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={6}
                  lg={6}
                  style={{ marginBottom: "10px" }}
                >
                  <TextField
                    size="small"
                    label="Furniture Notes"
                    multiline={true}
                    variant="outlined"
                    style={{ width: "90%" }}
                    value={furnitureNotes}
                    disabled={isEditing ? false : true}
                    onChange={handleLocationNotesChange("furnitureNotes")}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={6}
                  lg={6}
                  style={{ marginBottom: "10px" }}
                >
                  <TextField
                    size="small"
                    label="Sun Notes"
                    multiline={true}
                    variant="outlined"
                    style={{ width: "90%" }}
                    value={sunNotes}
                    disabled={isEditing ? false : true}
                    onChange={handleLocationNotesChange("sunNotes")}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={6}
                  lg={6}
                  style={{ marginBottom: "10px" }}
                >
                  <TextField
                    size="small"
                    label="Light Notes"
                    multiline={true}
                    variant="outlined"
                    style={{ width: "90%" }}
                    value={lightNotes}
                    disabled={isEditing ? false : true}
                    onChange={handleLocationNotesChange("lightNotes")}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={6}
                  lg={6}
                  style={{ marginBottom: "10px" }}
                >
                  <TextField
                    size="small"
                    label="Special Notes"
                    multiline={true}
                    variant="outlined"
                    style={{ width: "90%" }}
                    value={specialNotes}
                    disabled={isEditing ? false : true}
                    onChange={handleLocationNotesChange("specialNotes")}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={6}
                  lg={6}
                  style={{ marginBottom: "10px" }}
                >
                  <TextField
                    size="small"
                    label="Evacuation Procedure"
                    multiline={true}
                    variant="outlined"
                    style={{ width: "90%" }}
                    value={evacuationProcedure}
                    disabled={isEditing ? false : true}
                    onChange={handleLocationNotesChange("evacuationProcedure")}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={6}
                  lg={6}
                  style={{ marginBottom: "10px" }}
                >
                  <TextField
                    size="small"
                    label="Access Details"
                    multiline={true}
                    variant="outlined"
                    style={{ width: "90%" }}
                    value={accessDetails}
                    disabled={isEditing ? false : true}
                    onChange={handleLocationNotesChange("accessDetails")}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={6}
                  lg={6}
                  style={{ marginBottom: "10px" }}
                >
                  <TextField
                    size="small"
                    label="Recce Status"
                    multiline={true}
                    variant="outlined"
                    style={{ width: "90%" }}
                    value={recceStatus}
                    disabled={isEditing ? false : true}
                    onChange={handleLocationNotesChange("recceStatus")}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={6}
                  lg={6}
                  style={{ marginBottom: "10px" }}
                >
                  <TextField
                    size="small"
                    label="Video Links"
                    multiline={true}
                    variant="outlined"
                    style={{ width: "90%" }}
                    value={videosLink}
                    disabled={isEditing ? false : true}
                    onChange={handleLocationNotesChange("videosLink")}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={6}
                  lg={6}
                  style={{ marginBottom: "10px" }}
                >
                  <TextField
                    size="small"
                    label="Floor Plan Links"
                    multiline={true}
                    variant="outlined"
                    style={{ width: "90%" }}
                    value={props.location.floorPlanLink}
                    disabled={isEditing ? false : true}
                    onChange={handleLocationNotesChange("floorPlanLink")}
                  />
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={5}>
              {contractList !== undefined && contractList !== null ? (
                <HistoryTable contractList={contractList}/>
              ) : (
                <CircularProgress />
              )}
            </TabPanel>
          </div>
        </div>
      </Modal>
    </div>
  );
}

// export default LocationsDetailsPopUp;

const mapStateToProps = (state, ownProps) => ({
  dialog: state.dialog,
});
export default connect(mapStateToProps, {
  setDialogProps,
  getSchedule,
})(LocationsDetailsPopUp);
