import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import "./Reschedule.scss";
import {
    AccessTime,
    LocationOn as LocationOnIcon
} from "@material-ui/icons";
import {
    TableRow,
    TableCell,
} from "@material-ui/core";
import "date-fns";
import { format, isValid } from "date-fns";
import {
    KeyboardTimePicker,
} from "@material-ui/pickers";
import {
    changeLocation,
    createNewSchedule,
    deleteScheduleLocation,
    editScheduleLocation,
    promoteScheduleLocation,
    noLocationAlert,
} from "../../../../actions/schedules";
import {
    getFirstStartTime,
    getLastEndTime,
    getValueOrEmptyString,
    getLocationAddress,
    addressErrorText
} from "../../../../utils/utils";
import { getTimePortion } from "../../../../utils/TimeHelpers";
import { editScheduleItem } from "../../../../actions/scheduleItems";
import ContractButton from "../ScheduleItemTable/ContractButton";
import { makeStyles } from "@material-ui/core/styles";
import { useAuth } from "../../../../context/auth/AuthContext";
import { useUserContext } from "../../../../context/user/UserContext";

const useStyles = makeStyles((theme) => ({
    margin: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  }));

const RescheduleContractorRow = (props) => {
    const { user } = useAuth();
    let classes = useStyles();
    let {
        category,
        row,
        schedule,
        allScheduleLocations
    } = props;

    const [shakeStartTime, setShakeStartTime] = useState(false);
    const [shakeEndTime, setShakeEndTime] = useState(false);
    const [shakeRow, setShakeRow] = useState(false);
    const [shakeTable, setShakeTable] = useState(false);
    const [timeOutOfBounds, setTimeOutOfBounds] = useState(false);
    const [address, setAddress] = useState();

    useEffect(() => {
        if(shakeStartTime){
            setTimeout(() => {
                setShakeStartTime(false);
            }, 1000)//Match timeout with the reschedule-row-shake animation in Reschedule.scss
        }
    }, [shakeStartTime])

    useEffect(() => {
        if(shakeEndTime){
            setTimeout(() => {
                setShakeEndTime(false);
            }, 1000)//Match timeout with the reschedule-row-shake animation in Reschedule.scss
        }
    }, [shakeEndTime])

    useEffect(() => {
        if(shakeRow){
            setTimeout(() => {
                setShakeRow(false);
            }, 1000)//Match timeout with the reschedule-row-shake animation in Reschedule.scss
        }
    }, [shakeRow])

    useEffect(() => {
        var address = getLocationAddress(schedule, row);
        if(address === addressErrorText) {
            setTimeOutOfBounds(true);
        }
        else {
            setTimeOutOfBounds(false);
        }
        setAddress(address)
    }, [row.startTime, row.endTime, allScheduleLocations, schedule.endTime])

    return (
        <TableRow 
            key={row.id} 
            className={`reschedule-contractor-row ${timeOutOfBounds && `reschedule-row-time-out-of-bounds`} ${shakeRow && `reschedule-row-shake`}`}>
            <TableCell className="reschedule-who-cell">{`${row.personFkNavigation.firstName} ${row.personFkNavigation.lastName}`}</TableCell>
            <TableCell className="reschedule-category-cell">{category}</TableCell>
            <TableCell className="reschedule-role-cell">
                {getValueOrEmptyString(row.roleFkNavigation) !== "" ? row.roleFkNavigation.fieldRole : "No Role"}
            </TableCell>
            <TableCell className="reschedule-fee-cell">
                {getValueOrEmptyString(row.allocatedRates) !== "" ? `$${row.allocatedRates}` : `No Fee`}
            </TableCell>
            <TableCell className={`reschedule-start-time-cell ${shakeStartTime && `reschedule-row-shake`}`}>
                <div>
                    <KeyboardTimePicker
                        value={row.startTime}
                        onChange={(date, value) => {
                            if (date instanceof Date && !isNaN(date)) {
                                var firstStartTime = getFirstStartTime(schedule);
                                var scheduleDateString = format(
                                    firstStartTime,
                                    "yyyy-MM-dd"
                                );
                                var newDateTime = new Date(
                                    `${scheduleDateString} ${getTimePortion(date)}`
                                );

                                if (
                                    firstStartTime !== null &&
                                    newDateTime.getTime() < firstStartTime.getTime()
                                ) {
                                    props.setTimeErrorText(
                                        `Invalid Time. That is before the first location. We have placed it at the earliest start time: ${format(firstStartTime, "HH:mm a")}`
                                    );

                                    if (
                                        getTimePortion(new Date(row.startTime)) === getTimePortion(firstStartTime)
                                    ) {
                                        props.setTimeErrorText(
                                            `Invalid Time. This item is already at the earliest possible time: ${format(
                                                firstStartTime,
                                                "hh:mm a"
                                            )}, please choose something later`
                                        );
                                    } else {
                                        props.editScheduleItem(
                                            row,
                                            getValueOrEmptyString(category).toLowerCase(),
                                            "startTime",
                                            format(firstStartTime, "HH:mm:ss"),
                                            1, user?.email
                                        );
                                    }
                                    setShakeStartTime(true);
                                    setShakeRow(true);
                                    props.setShowTimeError(true);
                                } else {
                                    props.editScheduleItem(
                                        row,
                                        getValueOrEmptyString(category).toLowerCase(),
                                        "startTime",
                                        format(date, "HH:mm:ss"),
                                        1,  user?.email
                                    );
                                }
                            }
                        }}
                        KeyboardButtonProps={{
                            "aria-label": "start time",
                            style: {
                                ...{
                                    padding: "0px",
                                    margin: "0px",
                                },
                            },
                        }}
                        className={`${classes.margin} time-picker-width ${props.providerType === "Talent"
                            ? "talent-time-picker-width"
                            : ""
                            }`}
                        keyboardIcon={<AccessTime size="small" />}
                    />
                </div>
            </TableCell>
            <TableCell className={`reschedule-end-time-cell ${shakeEndTime && `reschedule-row-shake`}`}>
                <div>
                    <KeyboardTimePicker
                        // id="end-time-picker"
                        onChange={(date, value) => {
                            if (date instanceof Date && !isNaN(date)) {
                                var lastEndTime = getLastEndTime(schedule);
                                var scheduleDateString = format(lastEndTime, "yyyy-MM-dd");
                                var newDateTime = new Date(
                                    `${scheduleDateString} ${getTimePortion(date)}`
                                );

                                if (
                                    lastEndTime !== null &&
                                    newDateTime.getTime() > lastEndTime.getTime()
                                ) {
                                    props.setTimeErrorText(
                                        `Invalid Time. That is after the last location. We have placed it at the latest possible time: ${format(
                                            lastEndTime,
                                            "hh:mm a"
                                        )}`
                                    );
                                    if (
                                        getTimePortion(new Date(row.endTime)) ===
                                        getTimePortion(lastEndTime)
                                    ) {
                                        props.setTimeErrorText(
                                            `Invalid Time. This item is already at the latest possible time: ${format(
                                                lastEndTime,
                                                "hh:mm a"
                                            )}, please choose something earlier`
                                        );
                                    }
                                    props.editScheduleItem(
                                        row,
                                        getValueOrEmptyString(category).toLowerCase(),
                                        "endTime",
                                        format(lastEndTime, "HH:mm:ss"),
                                        1, user?.email
                                    );
                                    setShakeEndTime(true);
                                    setShakeRow(true);
                                    props.setShowTimeError(true);
                                } else {
                                    props.editScheduleItem(
                                        row,
                                        getValueOrEmptyString(category).toLowerCase(),
                                        "endTime",
                                        format(date, "HH:mm:ss"),
                                        1, user?.email
                                    );
                                }
                            }
                        }}
                        value={row.endTime}
                        KeyboardButtonProps={{
                            "aria-label": "end time",
                            style: {
                                ...{
                                    padding: "0px",
                                    margin: "0px",
                                },
                            },
                        }}
                        keyboardIcon={<AccessTime size="small" />}
                        className={`${classes.margin} time-picker-width 
                                    ${props.providerType === "Talent" && "talent-time-picker-width"}`}
                    />
                </div>
            </TableCell>
            <TableCell>
                <ContractButton
                    providerType={category}
                    si={row}
                    color="primary"
                    rescheduleButton="ContractorTalent"
                />
            </TableCell>
            <TableCell>
                {address}
                {/* {getLocationAddress(schedule, row)} */}
            </TableCell>
        </TableRow>
    );
};

const mapStateToProps = (state, ownProps) => ({
    allScheduleLocations: state.schedules.schedule.scheduleLocations,
    noLocation: state.schedules.noLocation,
    scheduleItems: state.scheduleItems,
    allScheduleItems: state.schedules.schedule.scheduleItem,
    schedule: state.schedules.schedule,
});
export default connect(mapStateToProps, {
    changeLocation,
    createNewSchedule,
    deleteScheduleLocation,
    editScheduleLocation,
    noLocationAlert,
    promoteScheduleLocation,
    editScheduleItem,
})(RescheduleContractorRow);