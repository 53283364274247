import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
    EditorState,
    ContentState,
    convertToRaw,
    convertFromHTML,
    compositeDecorator,
    Modifier,
} from "draft-js";
import "./SendSchedule.scss";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import Typography from "@material-ui/core/Typography";
import Axios from "axios";
import {
    Button,
    Snackbar,
    CircularProgress,
    TextField,
    FormControl,
    InputLabel,
} from "@material-ui/core";
import {
    SendScheduleMergeTagOption as MergeTagOptions,
    UploadImageButton
} from "../../../../utils/ToolbarCustomOptions";
import { CustomImageUpload } from "./CustomImageUpload";

const useStyles = makeStyles((theme) => ({
    content: {
        flexGrow: 1,
    },
    root: {
        "& > *": {
            width: "25ch",
        },
    },
}));

const EmailEditor = (props) => {
    const classes = useStyles();
    const [emailHtml, setEmailHtml] = useState(null);

    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [render, setRender] = useState(false);
    const [images, setImages] = useState([])

    useEffect(() => {
        console.log(props);
        console.log(props.emailBodyHtml);
        setEmailHtml(props.emailBodyHtml);
    }, [])

    //   const myDecorator = () =>
    //     new compositeDecorator([
    //       {
    //         strategy: findImageEntities,
    //         component: Image,
    //       },
    //     ]);

    //     const Image = props => {
    //       const { height, src, width } = props.contentState
    //         .getEntity(props.entityKey)
    //         .getData();
    //       return <img src={src} height={height} width={width} />;
    //     };
    //     const findImageEntities = (contentBlock, callback, contentState) => {
    //         contentBlock.findEntityRanges(character => {
    //           const entityKey = character.getEntity();
    //           return (
    //             entityKey !== null &&
    //             contentState.getEntity(entityKey).getType() === "IMAGE"
    //           );
    //         }, callback);
    //       }


    const clearContract = () => {
        console.log("current state");
        console.log(editorState);
        setEditorState(EditorState.createEmpty());
    };

    const onEditorStateChange = (editorState) => {
        console.log(draftToHtml(convertToRaw(editorState.getCurrentContent())));
        props.setEmailBodyHtml(
            draftToHtml(convertToRaw(editorState.getCurrentContent()))
        );
        setEditorState(editorState);
    };

    const subjectToolbarOption = () => {
        return (
            <div
                className="subject-container"
            >
                <InputLabel className="center-subject-item">Subject: </InputLabel>
                <TextField
                    variant="outlined"
                    size="small"
                    value={props.emailSubject}
                    onChange={handleSubjectChange}
                    fullWidth
                    className="center-subject-item"
                />
            </div>
        )
    }


    const handleSubjectChange = (event) => {
        props.setEmailSubject(event.target.value);
    };

    useEffect(() => {
        console.log(emailHtml);
        if (emailHtml !== null) {
            const blocksFromHTML = convertFromHTML(emailHtml);
            const state = ContentState.createFromBlockArray(
                blocksFromHTML.contentBlocks,
                blocksFromHTML.entityMap
            );
            setEditorState(EditorState.createWithContent(state, compositeDecorator));
            setRender(true);
        }
    }, [emailHtml]);

    const handleImageSelectChange = (images) => {
        var arr = Array.from(images);
        arr.map((img) => {
            Object.assign(img, {
                preview: URL.createObjectURL(img),
            });
        });
        setImages(arr);
    }

    const uploadImageCallBack = (img) => {
        return new Promise(
            (resolve, reject) => {
                try {
                    console.log("img");
                    console.log(img);
                    var blob = img;

                    console.log('Blob - ', blob);

                    var reader = new FileReader();
                    reader.readAsDataURL(blob);
                    reader.onloadend = function () {
                        var base64String = reader.result;
                        console.log(reader)
                        console.log('Base64 String - ', base64String);

                        console.log('Base64 String without Tags- ',
                            base64String.substr(base64String.indexOf(',') + 1));

                        var response = {
                            data: {
                                link: base64String,
                                type: img.type
                            }
                        }
                        resolve(response);
                    }

                }
                catch (error) {
                    reject(error);
                };
            }
        );
    }

    useEffect(() => {
        console.log(emailHtml);
        if (emailHtml !== null) {
            const blocksFromHTML = convertFromHTML(emailHtml);
            const state = ContentState.createFromBlockArray(
                blocksFromHTML.contentBlocks,
                blocksFromHTML.entityMap
            );
            setEditorState(EditorState.createWithContent(state, compositeDecorator));
            setRender(true);
        }
    }, [emailHtml]);

    useEffect(() => {
        console.log("editorState useEffect");
        console.log(editorState);
    }, [editorState]);

    useEffect(() => {
        console.log("images");
        console.log(images);
    }, [images]);

    useEffect(() => {
        console.log("editorState useEffect");
        console.log(editorState);
    }, [editorState]);

    if (render) {
        return (
            <main className={classes.content}>
                <div className="text-editor-container">
                    <Editor
                        toolbar={
                            {
                                options: ['inline', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'emoji', 'image', 'history'],
                                inline: {
                                    options: ['bold', 'italic', 'underline'], //'strikethrough'
                                },
                                fontSize: {
                                    options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
                                },
                                fontFamily: {
                                    options: ['Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana'],
                                },
                                list: {
                                    inDropdown: false,
                                    options: ['unordered', 'ordered', 'indent', 'outdent'],
                                },
                                textAlign: {
                                    inDropdown: false,
                                    options: ['left', 'center', 'right', 'justify'],
                                },
                                colorPicker: {
                                    colors: ['rgb(97,189,109)', 'rgb(26,188,156)', 'rgb(84,172,210)', 'rgb(44,130,201)',
                                        'rgb(147,101,184)', 'rgb(71,85,119)', 'rgb(204,204,204)', 'rgb(65,168,95)', 'rgb(0,168,133)',
                                        'rgb(61,142,185)', 'rgb(41,105,176)', 'rgb(85,57,130)', 'rgb(40,50,78)', 'rgb(0,0,0)',
                                        'rgb(247,218,100)', 'rgb(251,160,38)', 'rgb(235,107,86)', 'rgb(226,80,65)', 'rgb(163,143,132)',
                                        'rgb(239,239,239)', 'rgb(255,255,255)', 'rgb(250,197,28)', 'rgb(243,121,52)', 'rgb(209,72,65)',
                                        'rgb(184,49,47)', 'rgb(124,112,107)', 'rgb(209,213,216)'],
                                },
                                emoji: {
                                    emojis: [
                                        '😀', '😁', '😂', '😃', '😉', '😋', '😎', '😍', '😗', '🤗', '🤔', '😣', '😫', '😴', '😌', '🤓',
                                        '😛', '😜', '😠', '😇', '😷', '😈', '👻', '😺', '😸', '😹', '😻', '😼', '😽', '🙀', '🙈',
                                        '🙉', '🙊', '👼', '👮', '🕵', '💂', '👳', '🎅', '👸', '👰', '👲', '🙍', '🙇', '🚶', '🏃', '💃',
                                        '⛷', '🏂', '🏌', '🏄', '🚣', '🏊', '⛹', '🏋', '🚴', '👫', '💪', '👈', '👉', '👉', '👆', '🖕',
                                        '👇', '🖖', '🤘', '🖐', '👌', '👍', '👎', '✊', '👊', '👏', '🙌', '🙏', '🐵', '🐶', '🐇', '🐥',
                                        '🐸', '🐌', '🐛', '🐜', '🐝', '🍉', '🍄', '🍔', '🍤', '🍨', '🍪', '🎂', '🍰', '🍾', '🍷', '🍸',
                                        '🍺', '🌍', '🚑', '⏰', '🌙', '🌝', '🌞', '⭐', '🌟', '🌠', '🌨', '🌩', '⛄', '🔥', '🎄', '🎈',
                                        '🎉', '🎊', '🎁', '🎗', '🏀', '🏈', '🎲', '🔇', '🔈', '📣', '🔔', '🎵', '🎷', '💰', '🖊', '📅',
                                        '✅', '❎', '💯',
                                    ],
                                },
                                image: {
                                    uploadCallback: uploadImageCallBack,
                                    // urlEnabled: true,
                                    // uploadEnabled: true,
                                    // alignmentEnabled: true,
                                    // previewImage: true,
                                    // inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                                    // alt: { present: true, mandatory: false },
                                    // defaultSize: {
                                    //     height: 'auto',
                                    //     width: '250',
                                    // },
                                    className: 'email-editor-image-componant',
                                    test: "test",
                                    component: CustomImageUpload
                                },
                                history: {
                                    inDropdown: false,
                                    options: ['undo', 'redo'],
                                },
                            }
                        }
                        editorState={editorState}
                        wrapperClassName="send-schedule-text-editor-wrapper"
                        editorClassName="send-schedule-text-editor"
                        onEditorStateChange={onEditorStateChange}
                        handlePastedText={() => false}
                        toolbarCustomButtons={[
                            <MergeTagOptions onChange={onEditorStateChange} editorState={editorState} />,
                            subjectToolbarOption(),
                            // <CustomImageToolbar uploadImageCallBack={uploadImageCallBack}/>
                        ]}
                    />
                </div>
            </main>
        );
    } else {
        return <CircularProgress />;
    }
}

export default EmailEditor;
