import React from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogContentText,
    Button,
} from "@material-ui/core";

const ConfirmationDialog = ({
    show,
    titleText,
    contentText,
    handleClose,
    buttonText,
    buttonClass = "",
    maxWidth = "md",
    fullWidth = false
}) => {

    return (
        <Dialog
            open={show}
            onClose={() => handleClose()}
            maxWidth={maxWidth}
            fullWidth={fullWidth}
        >
            <DialogTitle style={{ textAlign: "center" }}>
                {titleText}
            </DialogTitle>
            <DialogContent>
                <DialogContentText style={{ textAlign: "center" }}>
                    {contentText}
                </DialogContentText>
            </DialogContent>
            <DialogActions style={{ textAlign: "center" }}>
                <Button
                    onClick={() => handleClose()}
                    color="primary"
                    className={buttonClass}
                >
                    {buttonText}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmationDialog;
