import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Close, FormatListBulleted } from "@material-ui/icons";
import Snacky from "../../../Shared/Snacky";
import Dialogy from "../../../Shared/Dialogy";
import { setSnackBarProps } from "../../../../actions/snackbar";
import { setDialogProps } from "../../../../actions/dialog";
import { greetings } from "../../../../utils/Greetings";
import {
    Paper,
    AppBar,
    Table,
    Typography,
    Toolbar,
    IconButton,
    Modal,
    Input,
    OutlinedInput,
    TextField,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogContentText,
    Card,
    CardActions
} from "@material-ui/core";
import DocumentViewer from "../DocumentViewer";

const useStyles = makeStyles((theme) => ({
    root: {},
    icon: {
        color: "rgba(255, 255, 255, 0.54)",
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(2, 4, 3),
        height: "auto",
        maxHeight: "80vH",
        width: "80vH",
        overflow: "auto",
    },
    modal2: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflow: "hidden",
    },
    paper2: {
        backgroundColor: "#fefefe",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 2, 3),
        height: "80vH",
        maxHeight: "80vH",
        width: "1000px",
        overflowY: "auto",
        position: "relative"
    },
    feeBox: {
        width: 80,
    },
}));

const TalentNotes = (props) => {
    const classes = useStyles();
    const [notes, setNotes] = useState(null);
    const [showGreetingWarning, setShowGreetingWarning] = useState(false);
    const [showDocumentViewer, setShowDocumentViewer] = useState(false);
    const [loading, setLoading] = useState(true);

    const imageLoaded = () => {
        setLoading(false);
    };

    const containsGreeting = (message) => {
        var greetingsList = greetings;
        if (message !== null) {
            for (var i = 0; i < greetingsList.length; i++) {
                if(new RegExp("\\b" + greetingsList[i].toLowerCase() + "\\b").test(message.toLowerCase())) {
                    console.log(greetingsList[i].toLowerCase());
                    console.log("Match with regx");
                    return true;
                }
            }
        }
        return false;
    }

    useEffect(() => {
        setNotes(props.activeScheduleItem.talentNotes)
    }, [])

    return (
        <div>
            <Modal
                open={props.modalState}
                onClose={props.handleModal}
                className={classes.modal}
            >
                <div>
                    <AppBar position="static" style={{ background: "#217F8B" }}>
                        <Toolbar variant="dense" style={{ paddingRight: 0 }}>
                            <Typography
                                variant="subtitle1"
                                align="center"
                                style={{ flexGrow: 1 }}
                            >
                                Talent Notes
                            </Typography>
                            <IconButton
                                aria-haspopup="true"
                                onClick={props.handleModal}
                                color="inherit"
                            >
                                <Close />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <div className={classes.paper}>
                        {showDocumentViewer && (
                            <DocumentViewer
                                modalState={showDocumentViewer}
                                handleCloseModal={() => {
                                    setShowDocumentViewer(false);
                                }}
                                driveId={"1Yz1-gIsK3_eBSRFd9Q_vgw__0s8Ny8PW"}
                                docType={"PDF"}
                            />
                        )}
                        {showGreetingWarning && (
                            <Modal
                                open={showGreetingWarning}
                                onClose={() => setShowGreetingWarning(false)}
                                className={classes.modal2}
                                disableBackdropClick
                            >
                                <div>
                                    <AppBar position="static" style={{ background: "#217F8B" }}>
                                        <Toolbar variant="dense" style={{ paddingRight: 0 }}>
                                            <Typography
                                                variant="subtitle1"
                                                align="center"
                                                style={{ flexGrow: 1 }}
                                            >
                                                Warning
                                            </Typography>
                                        </Toolbar>
                                    </AppBar>
                                    <div className={classes.paper2}>
                                        <DialogContentText style={{ textAlign: "center" }}>
                                            This contains a form of greeting. It is reccommened that you don't place any greeting as these notes will be placed as content in an email.
                                         </DialogContentText>
                                        <div style={{ display: !loading && "none" }}>
                                            <h1 className="loading-dots" style={{ display: "flex", justifyContent: "center" }}><span>.</span><span>.</span><span>.</span></h1>
                                        </div>
                                        <Card
                                            elevation={9}
                                            style={{
                                                display: loading && "none",
                                                width: "100%",
                                                display: "flex",
                                                maxHeight: "80vH"
                                            }}
                                        >
                                            <div>
                                                <img
                                                    src={`https://drive.google.com/uc?id=1wFuUfmepRlCruYpHq-b6UEXZh7Fcr0Py&export=view`}
                                                    className={"document-image"}
                                                    onLoad={imageLoaded}
                                                />
                                            </div>
                                        </Card>
                                        <div style={{ position: "absolute", bottom: 0, right: "0%", margin: 10 }}>
                                            <Button
                                                onClick={() => setShowGreetingWarning(false)}
                                                size="small"
                                                variant="contained"
                                                //color="primary"
                                                style={{margin: 5}}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                onClick={() => {
                                                    props.handleModal();
                                                    props.handleCompleteTalentNotes(props.activeScheduleItem, notes);
                                                }}
                                                size="small"
                                                variant="contained"
                                                //color="primary"
                                                style={{margin: 5}}
                                            >
                                                Proceed Anyway
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </Modal>
                        )}
                        <TextField
                            value={notes}
                            variant={"outlined"}
                            multiline={true}
                            fullWidth={true}
                            label={"Talent Notes"}
                            onChange={(event) => {
                                setNotes(event.target.value);
                            }}
                            rows={16}
                            placeholder={
                                "Place any notes for this talent in here\n\n "
                            }
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <div
                            style={{
                                marginTop: 10,
                                display: "flex",
                                justifyContent: "flex-end",
                            }}
                        >
                            <Button
                                variant={"contained"}
                                onClick={() => {
                                    if (containsGreeting(notes)) {
                                        setShowGreetingWarning(true);
                                    }
                                    else {
                                        props.handleModal();
                                        props.handleCompleteTalentNotes(props.activeScheduleItem, notes);
                                    }
                                }}
                                color="primary"
                            >
                                Save
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

//export default TalentNotes;

const mapStateToProps = (state, ownProps) => ({
    snackbar: state.snackbar,
    dialog: state.dialog,
});
export default connect(mapStateToProps, {
    setSnackBarProps,
    setDialogProps,
})(TalentNotes);