import React, { useEffect } from "react";
import { useAuth } from "../../../context/auth/AuthContext";
import { TextField } from "@material-ui/core";
import WeekNavigator from "../../Shared/Inputs/WeekNavigator";
import moment from "moment";

const TimeTrackerAllocationFilters = ({
  setDatesToDisplay,
  keyword,
  handleKeywordChange,
}) => {
  useEffect(() => {
    populateDatesToDisplay();
  }, []);

  const populateDatesToDisplay = (week) => {
    let _datesToDisplay = [];
    const startOfWeek = week ? week : moment().startOf("week");
    for (let i = 0; i < 7; i++) {
      const day = startOfWeek.clone().add(i, "days");
      _datesToDisplay.push(day);
    }
    setDatesToDisplay(_datesToDisplay);
  };

  return (
    <div className="time-tracker-allocation-filters-root">
      <TextField
        className="time-tracker-filter-input default-text-field-container "
        label="Global Search"
        variant="filled"
        fullWidth={true}
        value={keyword}
        size="small"
        onChange={(e) => {
          handleKeywordChange(e.target.value);
        }}
      />
      <WeekNavigator handleChangeWeek={populateDatesToDisplay} allowFutureWeeks={false}/>
    </div>
  );
};

export default TimeTrackerAllocationFilters;
