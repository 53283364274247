/* eslint-disable no-use-before-define */
import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { makeStyles, styled, withStyles } from "@material-ui/core/styles";
import {
    TextField,
    Paper,
    TableRow,
    Table,
    TableBody,
    TableCell,
    Button,
    Fab,
    IconButton,
    InputAdornment,
} from "@material-ui/core";
import { PersonAdd } from "@material-ui/icons";
import AccessTime from "@material-ui/icons/AccessTime";
import SearchIcon from "@material-ui/icons/Search";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { format, parse } from "date-fns";
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
} from "@material-ui/pickers";
import AddContactPopUp from "./NewContact/AddContactPopUp";
import ExistingContactsPopup from "./NewContact/ExistingContactsPopup";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import ContractorPopUp from "../ProjectOutputs/PopUps/ContractorPopUp";
import { createFilterOptions } from "@material-ui/lab/Autocomplete";
import { setSnackBarProps } from "../../../actions/snackbar";
import { postScheduleItem } from "../../../actions/scheduleItems";
import { setDialogProps } from "../../../actions/dialog";

import "./Schedule.css";
import Snacky from "../../Shared/Snacky";
import Dialogy from "../../Shared/Dialogy";
import { intervalToDuration } from "date-fns/esm";
import InformationDialogy from "../../Shared/InformationDialogy";
import { getFirstStartTime, getLastEndTime } from "../../../utils/utils";
import { getTimePortion } from "../../../utils/TimeHelpers";
import { useUserContext } from "../../../context/user/UserContext";
import { useAuth } from "../../../context/auth/AuthContext";

const StyledPaper = styled(Paper)({
    width: "100%",
});
const StyledFab = withStyles((theme) => ({
    root: {
        background: "#00b2e2",
        color: "white",
        "&:hover": {
            background: "#008cb3",
        },
    },
}))(Fab);
const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.white,
        color: "#9E9E9E",
    },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
        width: 200,
    },
    margin: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    alignRight: {
        marginLeft: "auto",
        marginRight: 0,
    },
}));

const filter = createFilterOptions();

const NewScheduleItem = (props) => {
    const { user } = useAuth();

    const classes = useStyles();
    const [fieldsInvalid, setFieldsInvalid] = useState(false);
    const [invalidFields, setInvalidFields] = useState([]);
    const [addContactModalState, setAddContactModalState] = useState(false);
    const [category, setCategory] = useState(null);
    const [startTime, setStartTime] = useState(props.schedule.startTime);
    const [endTime, setEndTime] = useState(props.schedule.endTime);
    const [allocatedRates, setAllocatedRates] = useState(null);
    const [providerName, setProviderName] = useState(null);
    const [companyFk, setCompanyId] = useState();
    const [personFk, setPersonId] = useState();
    const [duplicates, setDuplicates] = useState([]);
    const [existingContactsModalState, setExistingContactsModalState] = useState(
        false
    );
    const [
        addContactModalHasBeenOpened,
        setAddContactModalHasBeenOpened,
    ] = useState(false);
    const [openContractorPopup, setOpenContractorPopUp] = useState(false);
    const [categoryObj, setCategoryObj] = useState(null);
    const [roleObj, setRoleObj] = useState(null);
    const [role, setRole] = useState(null);
    const [goAhead, setGoAhead] = useState(false);
    const [newContact, setNewContact] = useState([]);
    const [providerObj, setProviderObj] = useState(null);
    const [roleList, setRoleList] = useState([]);
    const [getRoles, setGetRoles] = useState(true);
    const [feeFieldLocked, setFeeFieldLocked] = useState(false);
    const [showWarning, setShowWarning] = useState(false);
    const [changedToUnpaid, setChangedToUnpaid] = useState(false);

    const [showTimeError, setShowTimeError] = useState(false);
    const [timeErrorText, setTimeErrorText] = useState("");

    useEffect(() => {
        if (startTime !== props.schedule.startTime) {
            setStartTime(props.schedule.startTime);
        }
    }, [props.schedule.startTime]);

    useEffect(() => {
        if (endTime !== props.schedule.endTime) {
            setEndTime(props.schedule.endTime);
        }
    }, [props.schedule.endTime]);

    const handleAddContactModal = () => {
        if (addContactModalState) {
            setAddContactModalState(false);
        } else {
            setAddContactModalState(true);
            setAddContactModalHasBeenOpened(true);
        }
    };

    const handleExistingContactModal = () => {
        console.log("Toggle");
        if (existingContactsModalState) {
            setExistingContactsModalState(false);
        } else {
            setExistingContactsModalState(true);
        }
    };

    const setFeeToZero = () => {
        setAllocatedRates(0);
        setFeeFieldLocked(true);
    };

    const changeCategory = (obj, value) => {
        setFieldsInvalid(false);
        setInvalidFields([]);
        setCategoryObj(value);
        if (value !== null) {
            setCategory(value.category);
            if (value.category === "Unpaid") {
                setFeeToZero();
            } else {
                if (feeFieldLocked) {
                    console.log("feeFieldLocked");
                    setFeeFieldLocked(false);
                }
            }
        }
    };

    const changeRole = (object, value) => {
        setFieldsInvalid(false);
        setInvalidFields([]);
        if (typeof object !== "string" && value === undefined) {
            //Company Selected From Dropdown
            setRoleObj(object);
        }
    };

    const createNewRole = (role) => {
        //roleList.push(role);
        axios
            .post(`Roles/PostRole`, role)
            .then((res) => {
                console.log(res);
                changeRole(res.data);
                setGetRoles(true);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const changeAllocatedRates = (value) => {
        setFieldsInvalid(false);
        setInvalidFields([]);
        //setAllocatedRates(value);
        if (categoryObj?.quoted && value <= categoryObj.quoted) {
            setAllocatedRates(value);
        } else if (categoryObj?.category == "Unpaid") {
            setAllocatedRates(value);
        }
    };

    const changeProvider = (e, value) => {
        console.log(value);
        setFieldsInvalid(false);
        setInvalidFields([]);
        setProviderObj(value);
        if (value === null) {
            setProviderName(undefined);
            setCompanyId(undefined);
            setPersonId(undefined);
            return;
        }
        setProviderName(value.firstName + " " + value.lastName);
        setPersonId(value.id);
    };

    useEffect(() => {
        if (getRoles) {
            setGetRoles(false);
            axios
                .get(`Roles/getRoles`)
                .then((res) => {
                    var unfilteredRoles = res.data;
                    var filteredRoles = [];
                    unfilteredRoles.map((role) => {
                        if (role.category === props.providerType) {
                            filteredRoles.push(role);
                        }
                    });
                    setRoleList(filteredRoles);
                })
                .catch((e) => {
                    console.log(e);
                });
        }
    });

    const checkAllFieldsValid = (providerType) => {
        console.log("checking valid fields");
        switch (providerType) {
            case "Contractor":
            case "Talent":
                if (
                    allocatedRates === null ||
                    (categoryObj.category !== "Unpaid" && allocatedRates === 0) ||
                    providerObj === null ||
                    roleObj === null ||
                    categoryObj === null
                ) {
                    let invalidFieldsArr = [];
                    if (
                        allocatedRates === null ||
                        allocatedRates === "" ||
                        allocatedRates === 0
                    ) {
                        invalidFieldsArr.push("AllocatedRates");
                    }
                    if (providerObj === null) {
                        invalidFieldsArr.push("ProviderName");
                    }
                    if (roleObj === null) {
                        invalidFieldsArr.push("Role");
                    }
                    if (categoryObj === null) {
                        invalidFieldsArr.push("Category");
                    }
                    setFieldsInvalid(true);
                    setInvalidFields(invalidFieldsArr);
                    props.setSnackBarProps("warning", "Missing fields", true);
                    return false;
                } else {
                    return true;
                }
            default:
                return true;
        }
    };

    const submitNewScheduleItem = (providerType) => {
        if (checkAllFieldsValid(providerType)) {
            if (categoryObj === null && providerType != "Staff" && providerType != "Client" && categoryObj?.category != "Unpaid" && !categoryObj?.budgetId) {
                setCategory(null)
                setCategoryObj(null)
                props.setSnackBarProps("warning", "Please select a budget account", true);
            } else {
                var siObj = {
                    category: providerType === "Client" ? "ClientItem" : category,
                    budgetId: categoryObj?.budgetId || null,
                    startTime: startTime,
                    endTime: endTime,
                    allocatedRates: allocatedRates === null ? 0 : allocatedRates,
                    providerName: providerName,
                    isStaff:
                        providerType === "Staff" ? 1 : providerType === "Talent" ? 2 : 0,
                    isClient: providerType === "Client" ? 1 : 0,
                    scheduleFk: props.schedule.id,
                    companyFk: companyFk,
                    personFk: personFk,
                    contractStatus:
                        providerType === "Talent"
                            ? category.toLowerCase().trim() !== "unpaid"
                                ? "Disabled"
                                : "Send"
                            : "Send",
                    talentContractType:
                        providerType === "Talent"
                            ? "Restricted"
                            : providerType === "Contractor"
                                ? "BuyOut"
                                : null,
                    talentContractStatus: providerType === "Talent" ? "Incomplete" : null,
                    roleFk: roleObj !== null ? roleObj.id : null,
                    createdBy: user?.email,
                };
                props.postScheduleItem(
                    siObj,
                    providerType.toLowerCase(),
                    props.project.id
                );
                setStartTime(props.schedule.startTime);
                setEndTime(props.schedule.endTime);
                setAllocatedRates(0);
                setProviderName(null);
                setProviderObj(null);
                setCategoryObj(null);
                setRoleObj(null);
                setFeeFieldLocked(false);
            }
        }
    };

    useEffect(() => {
        if (duplicates.length > 0) {
            setAddContactModalState(false);
            setExistingContactsModalState(true);
        }
    }, [duplicates]);

    const selectExisting = (contact) => {
        selectNewPerson(contact);
        handleExistingContactModal();
    };
    const closePopUps = () => {
        setOpenContractorPopUp(false);
    };
    const selectNewPerson = (value) => {
        changeProvider("", value);
    };
    const handleEditExisting = (contact) => {
        setPersonId(contact.id);
        setOpenContractorPopUp(true);
    };
    const handleContinue = () => {
        setGoAhead(true);
        setExistingContactsModalState(false);
        setAddContactModalState(true);
    };

    const checkIfUnpaid = () => {
        if (categoryObj.category !== "Unpaid") {
            if (allocatedRates === "" || allocatedRates === null) {
                return true;
            } else {
                try {
                    var fee = parseInt(allocatedRates);
                    if (fee === 0) {
                        return true;
                    }
                } catch (e) {

                }
            }
        }
        return false;
    };

    const handleSubmitButton = () => {
        console.log(props.providerType);
        props.providerType === "Talent" || props.providerType === "Contractor"
            ? checkIfUnpaid()
                ? setShowWarning(true)
                : submitNewScheduleItem(props.providerType)
            : submitNewScheduleItem(props.providerType);
    };

    const handleChangeToUnpaid = () => {
        setCategory("Unpaid");
        setCategoryObj({ category: "Unpaid" });
        changeAllocatedRates("0");
        setChangedToUnpaid(true);
    };
    useEffect(() => {
        if (changedToUnpaid) {
            setChangedToUnpaid(false);
            submitNewScheduleItem(props.providerType);
            setShowWarning(false);
        }
    }, [changedToUnpaid]);

    return (
        <div className={classes.root}>
            <StyledFab
                size="small"
                style={{ position: "absolute", zIndex: "10", marginTop: "-20px" }}
                onClick={() => handleAddContactModal()}
            >
                <PersonAdd />
            </StyledFab>
            <Snacky
                snackprops={props.snackbar}
                setSnackBarProps={props.setSnackBarProps}
            />
            {showTimeError && (
                <InformationDialogy
                    setDialogProps={setDialogProps}
                    buttonProps={{
                        show: showTimeError,
                        titleText: "Woops!",
                        contentText: timeErrorText,
                        buttonColor: "primary",
                        buttonText: "Ok",
                    }}
                    handleButton={() => {
                        setTimeErrorText("")
                        setShowTimeError(false)
                    }}
                />
            )}
            {showWarning ? (
                <Dialogy
                    setDialogProps={setDialogProps}
                    buttonProps={{
                        show: showWarning,
                        titleText: "Warning",
                        contentText: "Budget Account must be unpaid if the fee is $0",
                        buttonOneColor: "primary",
                        buttonOneText: "Cancel",
                        buttonTwoColor: "primary",
                        buttonTwoText: "Change to Unpaid & Continue",
                    }}
                    handleButtonOne={() => setShowWarning(false)}
                    handleButtonTwo={() => handleChangeToUnpaid()}
                />
            ) : (
                <React.Fragment></React.Fragment>
            )}
            {existingContactsModalState && (
                <ExistingContactsPopup
                    handleModal={() => handleExistingContactModal()}
                    duplicates={duplicates}
                    handleEdit={handleEditExisting}
                    modalState={existingContactsModalState}
                    setNewContact={setNewContact}
                    handleContinue={handleContinue}
                    selectExisting={selectExisting}
                />
            )}
            {addContactModalState && (
                <AddContactPopUp
                    parent={"NewScheduleItem"}
                    handleModal={() => handleAddContactModal()}
                    handleAddContactModal={() => handleAddContactModal()}
                    modalState={addContactModalState}
                    category={props.providerType}
                    company={props.projectCFk}
                    selectNewPerson={selectNewPerson}
                    people={props.people}
                    setDuplicates={setDuplicates}
                    goAhead={goAhead}
                    setGoAhead={setGoAhead}
                    goAheadContact={newContact}
                    setNewContact={setNewContact}
                />
            )}
            {openContractorPopup && (
                <ContractorPopUp
                    handleModal={() => setOpenContractorPopUp(false)}
                    modalState={openContractorPopup}
                    personId={personFk}
                    reloadPersonList={props.reloadPersonList}
                    type="NewScheduleItem"
                    providerType={props.providerType}
                    closePopUps={() => closePopUps()}
                    selectExisting={selectExisting}
                />
            )}
            <StyledPaper>
                <Table size="small" aria-label="a dense table">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <TableBody>
                            <TableRow>
                                {props.providerType === "Contractor" ||
                                    props.providerType === "Staff" ||
                                    props.providerType === "Talent" ? (
                                    <StyledTableCell
                                        style={
                                            props.providerType === "Talent" ||
                                                props.providerType === "Contractor"
                                                ? { width: 150, padding: 3 }
                                                : {}
                                        }
                                    >
                                        <div className={"schedule-item-category"}>
                                            <Autocomplete
                                                options={
                                                    props.providerType == "Staff"
                                                        ? props.tasks
                                                        : [...props.budgetItems.filter(b => b.totalAmount <= b.quoted), { category: "Unpaid" }]
                                                }
                                                getOptionLabel={(option) => option.category}
                                                value={categoryObj}
                                                style={{
                                                    width: "auto",
                                                }}
                                                size="small"
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        placeholder="Account *"
                                                        // variant="outlined"
                                                        fullWidth
                                                        size="small"
                                                        error={
                                                            fieldsInvalid &&
                                                            invalidFields.indexOf("Category") !== -1
                                                        }
                                                    />
                                                )}
                                                className={classes.margin}
                                                onChange={changeCategory}
                                            />
                                        </div>
                                    </StyledTableCell>
                                ) : (
                                    ""
                                )}
                                {props.providerType === "Contractor" ||
                                    props.providerType === "Staff" ||
                                    props.providerType === "Talent" ? (
                                    <StyledTableCell
                                        style={
                                            props.providerType === "Talent" ||
                                                props.providerType === "Contractor"
                                                ? { width: 150, padding: 6 }
                                                : {}
                                        }
                                    >
                                        <div className={"schedule-item-provider"}>
                                            <Autocomplete
                                                options={roleList}
                                                //getOptionLabel={(option) => option.fieldRole}
                                                value={roleObj}
                                                style={{
                                                    width: "auto",
                                                }}
                                                size="small"
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        placeholder="Role *"
                                                        fullWidth
                                                        size="small"
                                                        error={
                                                            fieldsInvalid &&
                                                            invalidFields.indexOf("Role") !== -1
                                                        }
                                                    />
                                                )}
                                                className={classes.margin}
                                                // onChange={changeRole}
                                                onChange={(event, newValue) => {
                                                    // Create a new value from the user input
                                                    if (newValue && newValue.inputValue) {
                                                        var role = {
                                                            fieldRole: newValue.inputValue,
                                                            category: props.providerType,
                                                        };
                                                        changeRole(role);
                                                        createNewRole(role);
                                                        return;
                                                    } else {
                                                        changeRole(newValue);
                                                    }
                                                }}
                                                getOptionLabel={(option) => {
                                                    // Value selected with enter, right from the input
                                                    if (typeof option === "string") {
                                                        return option;
                                                    }
                                                    // Add "xxx" option created dynamically
                                                    if (option.fieldRole) {
                                                        return option.fieldRole;
                                                    }
                                                    // Regular option
                                                    return option.fieldRole;
                                                }}
                                                filterOptions={(options, params) => {
                                                    const filtered = filter(options, params);
                                                    const createOption = [
                                                        {
                                                            inputValue: params.inputValue,
                                                            fieldRole: `Create "${params.inputValue}"`,
                                                        },
                                                    ];
                                                    let newOptions = [];
                                                    // Suggest the creation of a new value
                                                    if (params.inputValue !== "") {
                                                        newOptions = createOption.concat(filtered);
                                                    } else {
                                                        newOptions = filtered;
                                                    }
                                                    return newOptions;
                                                }}
                                                renderOption={(option, { selected }) => {
                                                    const checkOption = 'Create "';
                                                    if (option.fieldRole.includes(checkOption)) {
                                                        return (
                                                            <Fragment>
                                                                <span className={"blueText"}>
                                                                    {option.fieldRole}
                                                                </span>
                                                            </Fragment>
                                                        );
                                                    } else {
                                                        return option.fieldRole;
                                                    }
                                                }}
                                            />
                                        </div>
                                    </StyledTableCell>
                                ) : null}
                                <StyledTableCell
                                    style={
                                        props.providerType === "Talent" ||
                                            props.providerType === "Contractor"
                                            ? { width: 200, padding: 6 }
                                            : {}
                                    }
                                >
                                    <div
                                        style={
                                            props.providerType === "Contractor" ||
                                                props.providerType === "Talent"
                                                ? { display: "flex", alignItems: "flex-end" }
                                                : {}
                                        }
                                        className={"schedule-item-provider"}
                                    >
                                        <Autocomplete
                                            //id="schedule-item-provider"
                                            options={props.people[props.providerType.toLowerCase()]}
                                            getOptionLabel={(option) =>
                                                option.firstName + " " + option.lastName
                                            }
                                            style={{ width: "100%" }}
                                            value={providerObj}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    placeholder="Who? *"
                                                    // variant="outlined"
                                                    fullWidth
                                                    size="small"
                                                    error={
                                                        fieldsInvalid &&
                                                        invalidFields.indexOf("ProviderName") !== -1
                                                    }
                                                />
                                            )}
                                            className={classes.margin}
                                            onChange={(e, value) => changeProvider(e, value)}
                                        />
                                        {props.providerType === "Contractor" ||
                                            props.providerType === "Talent" ? (
                                            <IconButton
                                                component="span"
                                                size="small"
                                                onClick={
                                                    personFk !== undefined
                                                        ? () => setOpenContractorPopUp(true)
                                                        : ""
                                                }
                                            >
                                                <SearchIcon />
                                            </IconButton>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                </StyledTableCell>
                                {props.providerType === "Contractor" ||
                                    props.providerType === "Talent" ? (
                                    <StyledTableCell
                                        style={
                                            props.providerType === "Talent" ||
                                                props.providerType === "Contractor"
                                                ? { width: 7, padding: "6px 5px 10px 18px" }
                                                : { paddingBottom: "10px" }
                                        }
                                    >
                                        {props.epContractor ? (
                                            <div className="express-payment-new-item">
                                                Invoices under ${props.epContractor} will be paid within
                                                7 days of the shoot
                                            </div>
                                        ) : (
                                            <div className="express-payment-new-item">
                                                Invoices under ${props.epTalent} will be paid within 7
                                                days of the shoot
                                            </div>
                                        )}
                                        <TextField
                                            placeholder="Fee"
                                            //label="Allocated *"
                                            style={{
                                                minWidth: "100px",
                                                maxWidth: "100px",
                                                padding: "0px 0px 0px 0px",
                                                marginTop: "20px",
                                            }}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">$</InputAdornment>
                                                ),
                                                error:
                                                    fieldsInvalid &&
                                                    invalidFields.indexOf("AllocatedRates") !== -1,
                                            }}
                                            id="standard-basic-si"
                                            value={allocatedRates}
                                            disabled={feeFieldLocked ? true : false}
                                            onChange={(e) => changeAllocatedRates(e.target.value)}
                                        />
                                    </StyledTableCell>
                                ) : (
                                    ""
                                )}
                                <StyledTableCell>
                                    <div className={"start-time-picker"}>
                                        <KeyboardTimePicker
                                            margin="normal"
                                            // id="start-time-picker"
                                            label="Start Time *"
                                            value={startTime}
                                            onChange={(date, value) => {
                                                if (date instanceof Date && !isNaN(date)) {
                                                    var firstStartTime = getFirstStartTime(props.currentSchedule);
                                                    var scheduleDateString = format(firstStartTime, "yyyy-MM-dd")
                                                    var newDateTime = new Date(`${scheduleDateString} ${getTimePortion(date)}`);

                                                    if (firstStartTime !== null && newDateTime.getTime() < firstStartTime.getTime()) {
                                                        setTimeErrorText(`Invalid Time. That is before the first location. We have placed it at the earliest start time: ${format(firstStartTime, "hh:mm a")}`)
                                                        setShowTimeError(true)
                                                        setStartTime(format(firstStartTime, "yyyy-MM-dd HH:mm:ss"));
                                                    }
                                                    else {
                                                        setStartTime(format(newDateTime, "yyyy-MM-dd HH:mm:ss"));
                                                    }
                                                }
                                            }}
                                            style={{
                                                minWidth: "100px",
                                                maxWidth: "100px",
                                            }}
                                            KeyboardButtonProps={{
                                                "aria-label": "start time",
                                                style: {
                                                    ...{
                                                        padding: "0px",
                                                        margin: "0px",
                                                    },
                                                },
                                            }}
                                            keyboardIcon={<AccessTime />}
                                            className={classes.margin}
                                        />
                                    </div>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <div className={"end-time-picker"}>
                                        <KeyboardTimePicker
                                            margin="normal"
                                            // id="end-time-picker"
                                            label="End Time *"
                                            value={endTime}
                                            onChange={(date, value) => {
                                                if (date instanceof Date && !isNaN(date)) {
                                                    var lastEndTime = getLastEndTime(props.currentSchedule);
                                                    var scheduleDateString = format(lastEndTime, "yyyy-MM-dd")
                                                    var newDateTime = new Date(`${scheduleDateString} ${getTimePortion(date)}`);

                                                    if (lastEndTime !== null && newDateTime.getTime() > lastEndTime.getTime()) {
                                                        setTimeErrorText(`Invalid Time. That is after the last location. We have placed it at the latest possible time: ${format(lastEndTime, "hh:mm a")}`)
                                                        setShowTimeError(true)
                                                        setEndTime(format(lastEndTime, "yyyy-MM-dd HH:mm:ss"));
                                                    }
                                                    else {
                                                        setEndTime(format(newDateTime, "yyyy-MM-dd HH:mm:ss"));
                                                    }
                                                }
                                            }}
                                            style={{
                                                minWidth: "100px",
                                                maxWidth: "100px",
                                            }}
                                            KeyboardButtonProps={{
                                                "aria-label": "end time",
                                                style: {
                                                    ...{
                                                        padding: "0px",
                                                        margin: "0px",
                                                    },
                                                },
                                            }}
                                            keyboardIcon={<AccessTime />}
                                            className={classes.margin}
                                        />
                                    </div>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <div style={{ display: "flex", height: "100%" }}>
                                        <Button
                                            onClick={() => handleSubmitButton()}
                                            style={{ alignSelf: "flex-end" }}
                                            disabled={props.project.isArchived === 1}
                                        >
                                            Submit
                                        </Button>
                                    </div>
                                </StyledTableCell>
                            </TableRow>
                        </TableBody>
                    </MuiPickersUtilsProvider>
                </Table>
            </StyledPaper>
        </div>
    );
};

const mapStateToProps = (state, ownProps) => ({
    snackbar: state.snackbar,
    people: state.people,
    dialog: state.dialog,
    currentSchedule: state.schedules.schedule,
});
export default connect(mapStateToProps, {
    setSnackBarProps,
    postScheduleItem,
    setDialogProps,
})(NewScheduleItem);