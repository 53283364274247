import React, { useEffect, useState } from "react";
import "./clientMediaSpend.scss";
import Snacky from "../../Shared/Snacky";
import MediaCampaigns from "./MediaCampaigns";
const ClientMediaSpend = ({ budgetItems, companyId, fetchAllBudget, organisationId, company }) => {

  const [snackBarProps, setSnackBarProps] = useState({
    open: false,
    severity: "success",
    text: "",
  });
  return (
    <div className="client-media-spend-root">
      <Snacky snackprops={snackBarProps} setSnackBarProps={setSnackBarProps} />
      <MediaCampaigns
        setSnackBarProps={setSnackBarProps}
        budgetItems={budgetItems}
        companyId={companyId}
        company={company}
        fetchAllBudget={fetchAllBudget}
        organisationId={organisationId}
      />

    </div>
  );
};

export default ClientMediaSpend;
