import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { getMonthsArray } from "../../../../utils/utils";

const OutreachFormInfluencerFields = ({
  record,
  handleOnChange,
  isReadOnly,
}) => {
  const [showExclusivity, setShowExclusivity] = useState(
    record?.usageInMonths !== record.exclusivityInMonths
  );

  return (
    <>
      <div className="influencer-outreach-details-2columns-grid">
        <FormControl fullWidth variant="outlined" size="small">
          <InputLabel>Type</InputLabel>
          <Select
            labelId={`paid-organic-dropdown-${record?.id}`}
            label="Type"
            variant="outlined"
            size="small"
            id={`paid-organic-dropdown-${record?.id}`}
            value={record?.type || ""}
            onChange={(event) => handleOnChange("type", event.target.value)}
            disabled={isReadOnly()}
          >
            <MenuItem value={"paid"}>{"Paid"}</MenuItem>
            <MenuItem value={"organic"}>{"Organic"}</MenuItem>
            <MenuItem value={"ugc"}>{"UGC"}</MenuItem>
          </Select>
        </FormControl>
        <div className="influencer-deliverable-item-field-grid influencer-deliverable-item-field-fullwidth">
          <Autocomplete
            // className="schedule-item-deliverables-line-usage"
            options={[{ id: 0, value: "No Usage" }].concat(getMonthsArray(12))}
            getOptionLabel={(option) => option.value}
            value={
              record.usageInMonths
                ? {
                    id: record.usageInMonths,
                    value: `${record.usageInMonths} Month${
                      record.usageInMonths > 1 ? "s" : ""
                    }`,
                  }
                : { id: 0, value: "No Usage" }
            }
            onChange={(event, newValue) => {
              setShowExclusivity(newValue.id !== record.exclusivityInMonths);
              handleOnChange("usageInMonths", newValue.id);
            }}
            disabled={isReadOnly()}
            disableClearable
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                label={`Usage`}
                fullWidth={true}
                variant="outlined"
              />
            )}
            fullWidth={true}
          />
          {!showExclusivity && !isReadOnly() && (
            <div className="influencer-deliverable-item-link-wrapper">
              <Typography variant="caption">{`Exclusivity period same as usage`}</Typography>
              <div className="influencer-deliverable-item-link-field">
                <Typography
                  variant="caption"
                  onClick={(e) => setShowExclusivity(true)}
                >{`edit`}</Typography>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="influencer-outreach-details-2columns-grid">
        <div lassName="influencer-deliverable-item-field-grid"></div>
        <div className="influencer-deliverable-item-field-fullwidth">
          {showExclusivity || isReadOnly() ? (
            <Autocomplete
              // className="schedule-item-deliverables-line-usage"
              options={[{ id: 0, value: "No Exclusivity" }].concat(
                getMonthsArray(12)
              )}
              getOptionLabel={(option) => option.value}
              value={
                record.exclusivityInMonths
                  ? {
                      id: record.exclusivityInMonths,
                      value: `${record.exclusivityInMonths} Month${
                        record.exclusivityInMonths > 1 ? "s" : ""
                      }`,
                    }
                  : { id: 0, value: "No Exclusivity" }
              }
              disabled={isReadOnly()}
              onChange={(event, newValue) => {
                handleOnChange("exclusivityInMonths", newValue?.id || null);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  label={`Exclusivity`}
                  fullWidth={true}
                  variant="outlined"
                />
              )}
              fullWidth={true}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default OutreachFormInfluencerFields;
