import React, { useState } from "react";
import { format } from "date-fns";
import {
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
} from "@material-ui/core";
import "../Outputs.scss";

export const InvoicesModalTable = (props) => {
  let {
    activeItems,
    handleShowPersonDetails,
    ignoredItems,
    handleIgnoreItem,
  } = props;

  return (
    <div
      style={{ display: activeItems.length === 0 && "none" }}
      className="table-green"
    >
      <div
        className="title-container"
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "5px",
        }}
      >
        <Typography className="active-items-modal-text" align="center">
          This project has some items that have not been invoiced.
        </Typography>
      </div>
      <Table className="active-items-modal-table" size="small">
        <TableHead>
          <TableRow>
            <TableCell>Send</TableCell>
            <TableCell>Person</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Due</TableCell>
            <TableCell>Fee</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {activeItems.length > 0 &&
            activeItems.map((item, index) => {
              return (
                <TableRow key={item.id}>
                  <TableCell>
                    <Checkbox
                      checked={ignoredItems.indexOf(item.id) === -1}
                      onChange={() => handleIgnoreItem(item.id)}
                    />
                  </TableCell>
                  <TableCell
                    className={item.category != "Catering" && "clickable"}
                    onClick={() => handleShowPersonDetails(item)}
                  >
                    {item.providerName}
                  </TableCell>
                  <TableCell>{item.category}</TableCell>
                  <TableCell>
                    {format(new Date(item.due), "dd/MM/yyyy")}
                  </TableCell>
                  <TableCell>${item.allocatedRates}</TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </div>
  );
};
