import {
  Checkbox,
  InputAdornment,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import {
  currencyFormat,
  filterNumbersOnlyInput,
} from "../../../../utils/GeneralUtils";
import ReconciledItemsDialog from "./ReconciledItemsDialog";

const TableCellWithTypography = ({ children, style }) => (
  <TableCell className="reconcile-table-cell-root">
    <Typography variant="caption" style={style}>
      {children}
    </Typography>
  </TableCell>
);

const getTextColor = (amount) => {
  if (amount < 0) {
    return "red";
  } else if (amount === 0) {
    return "green";
  } else {
    return "blue";
  }
};

const ReconcileMediaUndersAndOversTableRow = ({
  reconLine,
  handleUpdateSelectedReconLines,
  handleRemoveSelectedReconLine,
  payable,
}) => {
  const [selectedAmount, setSelectedAmount] = useState(
    reconLine?.totalWithoutCommission
  );
  const [isChecked, setIsChecked] = useState(false);
  const [openAllocatedItems, setOpenAllocatedItems] = useState(false);

  const {
    mediaCampaign,
    code,
    supplierCost,
    clientCost,
    commissionRate,
    unmatchedAmount,
  } = reconLine;

  useEffect(() => {
    setSelectedAmount(
      payable.subtotal <= reconLine.unmatchedAmount
        ? payable.subtotal
        : reconLine.unmatchedAmount?.toFixed(2)
    );
  }, [payable.subtotal, reconLine.unmatchedAmount]);

  useEffect(() => {
    const reconciledRecord = reconLine.reconciledMediaBills.find(
      (r) =>
        r.mediaSpendId === reconLine.mediaSpendId &&
        r.billId === payable.recordId
    );
    if (reconciledRecord) {
      setIsChecked(true);
      setSelectedAmount(reconciledRecord.amountReconciled);
      handleUpdateSelectedReconLines(
        reconLine.mediaSpendId,
        reconciledRecord.amountReconciled
      );
    }
  }, [payable]);

  const calculateClientActualAmount = (subtotal, commissionRate) => {
    return subtotal + subtotal * (commissionRate / (100 - commissionRate));
  };

  const payableSubtotal = payable?.subtotal || 0;

  const actualClientCost = calculateClientActualAmount(
    payableSubtotal,
    commissionRate
  );
  const clientCostDifference = clientCost - actualClientCost;

  const clientCostDifferenceColor = getTextColor(clientCostDifference);

  const handleUpdateAmount = (amount) => {
    if (amount > reconLine?.unmatchedAmount) {
      return;
    } else {
      setSelectedAmount(amount);
      handleUpdateSelectedReconLines(reconLine.mediaSpendId, amount);
    }
  };

  const handleChangeCheckbox = (isCheckboxChecked) => {
    if (isCheckboxChecked) {
      handleUpdateSelectedReconLines(reconLine.mediaSpendId, selectedAmount);
    } else {
      handleUpdateSelectedReconLines(reconLine.mediaSpendId, 0);
    }

    setIsChecked(isCheckboxChecked);
  };

  return (
    <>
      {openAllocatedItems && (
        <ReconciledItemsDialog
          reconciledItems={reconLine.reconciledMediaBills}
          open={openAllocatedItems}
          handleClose={() => setOpenAllocatedItems(false)}
        />
      )}
      <TableRow>
        <TableCellWithTypography>
          <Typography variant="caption">
            {mediaCampaign?.companyName}
          </Typography>
        </TableCellWithTypography>
        <TableCellWithTypography>
          {code || mediaCampaign?.jobId + " " + mediaCampaign?.name}{" "}
          {mediaCampaign?.mediaSupplier?.supplierName}
        </TableCellWithTypography>
        <TableCellWithTypography>
          {currencyFormat(supplierCost)}
        </TableCellWithTypography>
        <TableCellWithTypography>
          {currencyFormat(payableSubtotal)}
        </TableCellWithTypography>
        {/* <TableCellWithTypography>
          {currencyFormat((supplierCost || 0) - payableSubtotal)}
        </TableCellWithTypography>
        <TableCellWithTypography>
          {currencyFormat(clientCost)}
        </TableCellWithTypography>
        <TableCellWithTypography>
          {currencyFormat(actualClientCost) + ` ${commissionRate}%`}
        </TableCellWithTypography> */}
        <TableCellWithTypography style={{ color: clientCostDifferenceColor }}>
          {currencyFormat(clientCostDifference)}
        </TableCellWithTypography>
        <TableCellWithTypography>
          {currencyFormat(unmatchedAmount)}
        </TableCellWithTypography>
        <TableCell className="reconcile-table-cell-root">
          <div className="reconcile-table-single-checkbox-container">
            <Checkbox
              checked={isChecked}
              onChange={(e) => handleChangeCheckbox(e.target.checked)}
            />
          </div>
        </TableCell>
        <TableCell className="reconcile-table-cell-root">
          <div className="reconcile-allocation-cell-container">
            <TextField
              fullWidth
              value={selectedAmount}
              onChange={(e) =>
                handleUpdateAmount(filterNumbersOnlyInput(e.target.value))
              }
              size="small"
              InputProps={{
                style: { fontSize: "0.75rem" },
                startAdornment: (
                  <InputAdornment
                    position="start"
                    
                  >
                    <span style={{ fontSize: "0.75rem" }}>$</span>
                  </InputAdornment>
                ),
              }}
            />
            {reconLine.reconciledMediaBills?.length > 0 && (
              <div
                className="reconcile-allocation-cell-allocation-link"
                onClick={(e) => setOpenAllocatedItems(true)}
              >
                <Typography variant="caption">{`${
                  reconLine.reconciledMediaBills?.length
                } allocation${
                  reconLine.reconciledMediaBills?.length > 1 ? "s" : ""
                }`}</Typography>
              </div>
            )}
          </div>
        </TableCell>
      </TableRow>
    </>
  );
};

export default ReconcileMediaUndersAndOversTableRow;
