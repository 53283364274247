import React, { useState } from "react";
import { IconButton, Typography, Box } from "@material-ui/core";
import { ArrowBackIos, ArrowForwardIos } from "@material-ui/icons";
import moment from "moment";

const WeekNavigator = ({ handleChangeWeek, allowFutureWeeks = true }) => {
  const [currentWeek, setCurrentWeek] = useState(moment().startOf("week"));

  const handlePreviousWeek = () => {
    const previousWeek = currentWeek.clone().subtract(1, "week");
    setCurrentWeek(previousWeek);
    handleChangeWeek(previousWeek);
  };

  const handleNextWeek = () => {
    const nextWeek = currentWeek.clone().add(1, "week");
    if (nextWeek.isSameOrBefore(moment().startOf("week"))) {
      setCurrentWeek(nextWeek);
      handleChangeWeek(nextWeek);
    } else if (allowFutureWeeks) {
      setCurrentWeek(nextWeek);
      handleChangeWeek(nextWeek);
    }
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      style={{ backgroundColor: "white", minHeight: 45 }}
    >
      <IconButton
        onClick={handlePreviousWeek}
        aria-label="previous week"
        size="small"
      >
        <ArrowBackIos />
      </IconButton>

      <Typography
        variant="caption"
        style={{ margin: "0 16px", textAlign: "center", color: "#757575" }}
      >
        {currentWeek.format("MMMM DD")}{" "}
        {currentWeek.clone().endOf("week").format("MMMM DD")}
      </Typography>

      <IconButton
        onClick={handleNextWeek}
        aria-label="next week"
        size="small"
        disabled={
          !allowFutureWeeks && currentWeek.isSame(moment().startOf("week"))
        }
      >
        <ArrowForwardIos />
      </IconButton>
    </Box>
  );
};

export default WeekNavigator;
