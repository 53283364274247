import React, { useEffect, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Axios from "axios";
import {
  Modal,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  FormControl,
  FormLabel,
  Switch,
  Grid,
  TextField,
  Button,
  Snackbar,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import MuiAlert from "@material-ui/lab/Alert";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
    width: "130%",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    minHeight: 390,
    width: "25vW",
    overflow: "hidden",
  },
  paperMatchContractorPopUp: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    minHeight: 550,
    width: "25vW",
    overflow: "hidden",
  },
}));

function TalentAgencyPopUp(props) {
  const classes = useStyles();

  const [getCompany, setGetCompany] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [successCreate, setSuccessCreate] = useState(false);
  const [failedCreate, setFailedCreate] = useState(false);
  const [talentCompany, setTalentCompany] = useState({
    name: null,
    bankAccount: null,
    gstregistered: null,
    gstnumber: null,
  });
  const { name, bankAccount, gstregistered, gstnumber } = talentCompany;

  const handleTalentCompanyChange = (key) => (event) => {
    var value = null;
    if (key === "gstregistered") {
      value = event.target.checked ? 1 : 0;
    } else {
      value = event.target.value;
    }
    setTalentCompany({ ...talentCompany, [key]: value });
  };

  const closeSuccessCreateSnackbar = () => {
    setSuccessCreate(false);
  };

  const closeFailedCreateSnackbar = () => {
    setFailedCreate(false);
  };

  const handleSave = () => {
    Axios.put(`Companies/UpdateTalentAgencyFinanceDetails/${props.companyId}`, {
      id: props.companyId,
      name: name,
      bankAccount: bankAccount,
      gstregistered: 1,
      gstnumber: gstnumber,
    })
      .then((res) => {
        console.log(res);
        setSuccessCreate(true);
      })
      .catch((e) => {
        console.log(e);
        setFailedCreate(true);
      });
  };

  var snackbarSuccess = (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={successCreate}
      autoHideDuration={2000}
      onClose={closeSuccessCreateSnackbar}
    >
      <MuiAlert severity="success">Successfully Saved Changes!</MuiAlert>
    </Snackbar>
  );

  var snackbarFailed = (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={failedCreate}
      autoHideDuration={2000}
      onClose={closeFailedCreateSnackbar}
    >
      <MuiAlert severity="error">Failed to Save Changes!</MuiAlert>
    </Snackbar>
  );

  useEffect(() => {
    if (getCompany) {
      console.log("getCompany");
      setGetCompany(false);
      Axios.get(`Companies/GetCompany/${props.companyId}`)
        .then((res) => {
          console.log(res);
          var talentCompany = res.data;
          setTalentCompany({
            name: talentCompany.name,
            bankAccount: talentCompany.bankAccount,
            gstregistered: 1,
            gstnumber: talentCompany.gstnumber,
          });
        })
        .catch((e) => {
          console.log(e);
        });
    }
  });

  return (
    <div>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={props.modalState}
        onClose={props.handleModal}
        // className={classes.modal}
        className={"popUpRightAlignedEditTalentContact"}
      >
        <div>
          <AppBar position="static" style={{ background: "#217F8B" }}>
            <Toolbar variant="dense" style={{ paddingRight: 0 }}>
              <Typography
                variant="subtitle1"
                align="center"
                style={{ flexGrow: 1 }}
              >
                Talent Agency Finance Details
              </Typography>
              <span style={{ display: "flex" }}>
                <IconButton
                  variant="contained"
                  style={
                    isEditing
                      ? {}
                      : {
                          display: "none",
                          color: "black",
                        }
                  }
                  onClick={() => {
                    handleSave();
                    setIsEditing(false);
                  }}
                  //onClick={() => setIsEditing(false)}
                >
                  <SaveIcon />
                </IconButton>
                <IconButton
                  variant="contained"
                  style={
                    isEditing
                      ? {
                          display: "none",
                          color: "black",
                        }
                      : {}
                  }
                  onClick={() => setIsEditing(true)}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  aria-label="Close Talent Agency Finance Details"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={props.handleModal}
                >
                  <CloseIcon />
                </IconButton>
              </span>
            </Toolbar>
          </AppBar>
          <div className={classes.paperMatchContractorPopUp}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={12}>
                <TextField
                  label="Agency Name"
                  defaultValue={" "}
                  value={name}
                  disabled={!isEditing}
                  multiline={true}
                  variant="outlined"
                  style={{ width: "100%" }}
                  onChange={handleTalentCompanyChange("name")}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <TextField
                  label="Bank Account"
                  defaultValue={" "}
                  value={bankAccount}
                  disabled={!isEditing}
                  multiline={true}
                  variant="outlined"
                  style={{ width: "100%" }}
                  onChange={handleTalentCompanyChange("bankAccount")}
                />
              </Grid>
              {/* <Grid item xs={12} md={12} lg={12}>
                                <Grid container>
                                    <Grid item xs={6} md={6} lg={6} style={{ marginTop: "auto", marginBottom: "auto" }}>
                                        <FormLabel
                                            component="legend"
                                            style={{
                                                color: "#2d2d2d",
                                                fontSize: "1.2em",
                                            }}
                                        >
                                            <Typography>
                                                GST Registered?
                                            </Typography>
                                        </FormLabel>
                                    </Grid>
                                    <Grid item xs={6} md={6} lg={6}>
                                        <Grid component="label" container alignItems="center" spacing={1} style={{ marginTop: "auto", marginBottom: "auto" }}>
                                            <Grid
                                                item
                                                style={gstregistered ? { color: "grey" } : { borderBottom: "solid", borderColor: (isEditing ? "#3f51b5" : "grey") }}
                                            >
                                                <Typography>No</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Switch
                                                    color="primary"
                                                    checked={gstregistered === 1 ? true : false}
                                                    name="bcOrIc"
                                                    disabled={isEditing ? false : true}
                                                    onChange={handleTalentCompanyChange("gstregistered")}
                                                    className={isEditing ? "blueSwitch" : ""}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                style={gstregistered ? { borderBottom: "solid", borderColor: (isEditing ? "#3f51b5" : "grey") } : { color: "grey" }}
                                            >
                                                <Typography>Yes</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid> */}
              <Grid item xs={12} md={12} lg={12}>
                <TextField
                  label="GST Number"
                  multiline={true}
                  variant="outlined"
                  style={{ width: "100%" }}
                  defaultValue={null}
                  value={gstnumber}
                  disabled={isEditing ? false : true}
                  onChange={handleTalentCompanyChange("gstnumber")}
                />
              </Grid>
            </Grid>
          </div>
          {snackbarSuccess}
          {snackbarFailed}
        </div>
      </Modal>
    </div>
  );
}

export default TalentAgencyPopUp;
