import React from "react";
import {
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Checkbox,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button,
    InputAdornment
} from "@material-ui/core";
import {
    ChildCare as ChildCareIcon
} from '@material-ui/icons';
import {
    makeStyles,
} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 150,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const CateringTable = (props) => {
    const classes = useStyles();

    return (
        <div>
            <Table>
                <TableBody>
                    {
                        props.people.map((person, index) => {
                            console.log(person)
                            return (
                                <TableRow key={index}>
                                    {/* Checkbox */}
                                    <TableCell className="catering-table-cell">
                                        <Checkbox
                                            color="primary"
                                            //defaultChecked
                                            checked={person.willOrder}
                                            onChange={(event) => {
                                                var p = person;
                                                p.willOrder = event.target.checked;
                                                props.updatePerson(person, "willOrder", event.target.checked);
                                            }}
                                        />
                                    </TableCell>
                                    {/* Name */}
                                    <TableCell className="catering-table-cell" >
                                        <TextField
                                            value={`${person.firstName} ${person.lastName}`}
                                            label={"Name"}
                                            variant="outlined"
                                            fullWidth
                                            disabled
                                        />
                                    </TableCell>
                                    {/* Role */}
                                    <TableCell className="catering-table-cell">
                                        <TextField
                                            label={`Role`}
                                            value={person.role}
                                            variant="outlined"
                                            fullWidth
                                            disabled
                                            InputProps={person.isChild ? {
                                                endAdornment: <InputAdornment position="end"><ChildCareIcon/></InputAdornment>,
                                            } : {}}
                                        />
                                    </TableCell>
                                    {/* Phone */}
                                    <TableCell className="catering-table-cell">
                                        <TextField
                                            label={"Phone"}
                                            value={person.phoneNumber}
                                            variant="outlined"
                                            fullWidth
                                            disabled
                                        />
                                    </TableCell>
                                    {/* Meal Preference */}
                                    <TableCell className="catering-table-cell">
                                        <FormControl >
                                            <InputLabel>Meal Preference</InputLabel>
                                            <Select
                                                value={person.mealPreference}
                                                onChange={(event) => {
                                                    props.updatePerson(person, "mealPreference", event.target.value);
                                                }}
                                                label="Meal Preference"
                                                fullWidth
                                                className={"catering-table-meal-dropdown"}
                                            >
                                                <MenuItem value={"Classic"}>Classic</MenuItem>
                                                <MenuItem value={"Vegetarian"}>Vegetarian</MenuItem>
                                                <MenuItem value={"Vegan"}>Vegan</MenuItem>
                                                <MenuItem value={"Gluten Free"}>Gluten Free</MenuItem>
                                                <MenuItem value={"Dairy Free"}>Dairy Free</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </TableCell>
                                </TableRow>
                            );
                        })
                    }
                </TableBody>
            </Table>
        </div>
    )
}

export default CateringTable;