/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from "react";
import axios from "axios";
import { makeStyles, styled, withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import {
  getLowerCasePeople,
  getContractors,
  getTalent,
  getAllPeople,
} from "../../../../actions/people";
import { setSnackBarProps } from "../../../../actions/snackbar";
import {
  TextField,
  Paper,
  TableRow,
  Table,
  TableBody,
  TableCell,
  Button,
  Fab,
  IconButton,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import { PersonAdd } from "@material-ui/icons";
import SearchIcon from "@material-ui/icons/Search";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { format } from "date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import AddContactPopUp from "../NewContact/AddContactPopUp";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import ContractorPopUp from "../../ProjectOutputs/PopUps/ContractorPopUp"; //./ProjectOutputs/PopUps/ContractorPopUp
import { createFilterOptions } from "@material-ui/lab/Autocomplete";
import ExistingContactsPopup from "../NewContact/ExistingContactsPopup";
import Snacky from "../../../Shared/Snacky";
import Dialogy from "../../../Shared/Dialogy";
import { setDialogProps } from "../../../../actions/dialog";
import { postScheduleItem } from "../../../../actions/scheduleItems";
import { useUserContext } from "../../../../context/user/UserContext";
import { useAuth } from "../../../../context/auth/AuthContext";

const StyledPaper = styled(Paper)({
  width: "100%",
});

const StyledFab = withStyles((theme) => ({
  root: {
    background: "#00b2e2",
    color: "white",
    "&:hover": {
      background: "#008cb3",
    },
  },
}))(Fab);

//---------------------------------------
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: "#9E9E9E",
  },
  body: {
    //fontSize: "1em"
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: 200,
  },
  margin: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  alignRight: {
    marginLeft: "auto",
    marginRight: 0,
  },
}));

const filter = createFilterOptions();

const NewPrePost = (props) => {
  const { user } = useAuth();
  const { project } = useUserContext();

  const classes = useStyles();
  const [fieldsInvalid, setFieldsInvalid] = useState(false);
  const [invalidFields, setInvalidFields] = useState([]);
  const [deltaStartTime, setDeltaStartTime] = useState(false);
  const [addContactModalState, setAddContactModalState] = useState(false);
  const [category, setCategory] = useState(null);
  const [startTime, setStartTime] = useState();
  const [allocatedRates, setAllocatedRates] = useState(null);
  const [providerName, setProviderName] = useState(null);
  const [companyFk, setCompanyId] = useState();
  const [personFk, setPersonId] = useState();
  const [openContractorPopup, setOpenContractorPopUp] = useState(false);
  const [showCategoryError, setShowCategoryError] = useState(false);
  const [categoryObj, setCategoryObj] = useState(null);
  const [roleObj, setRoleObj] = useState(null);
  const [providerObj, setProviderObj] = useState(null);
  const [allRoles, setAllRoles] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [getRoles, setGetRoles] = useState(true);
  const [providerType, setProviderType] = useState(null);

  //----------------------
  const [existingContactsModalState, setExistingContactsModalState] = useState(
    false
  );
  const [duplicates, setDuplicates] = useState([]);
  const [newContact, setNewContact] = useState([]);
  const [goAhead, setGoAhead] = useState(false);
  const [feeFieldLocked, setFeeFieldLocked] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [changedToUnpaid, setChangedToUnpaid] = useState(false);

  console.log("props", props, project)

  const handleExistingContactModal = () => {
    console.log("Toggle");
    if (existingContactsModalState) {
      setExistingContactsModalState(false);
    } else {
      setExistingContactsModalState(true);
    }
  };

  useEffect(() => {
    props.getAllPeople();
  }, []);

  const handleEditExisting = (contact) => {
    setPersonId(contact.id);
    setOpenContractorPopUp(true);
  };

  const handleContinue = () => {
    setGoAhead(true);
    setExistingContactsModalState(false);
    setAddContactModalState(true);
  };

  const selectExisting = (contact) => {
    let contactToAdd = {
      firstName: contact.firstName,
      lastName: contact.lastName,
      id: contact.id,
    };
    selectNewPerson(contactToAdd);
    handleExistingContactModal();
  };

  const closePopUps = () => {
    setOpenContractorPopUp(false);
  };

  useEffect(() => {
    if (duplicates.length > 0) {
      setAddContactModalState(false);
      setExistingContactsModalState(true);
    }
  }, [duplicates]);

  const handleAddContactModal = () => {
    if (addContactModalState) {
      setAddContactModalState(false);
    } else {
      setAddContactModalState(true);
    }
  };
  useEffect(() => {
    var todayMidnight = new Date();
    todayMidnight.setHours(0, 0, 0, 0);
    setStartTime(todayMidnight);
  }, []);
  const setFeeToZero = () => {
    setAllocatedRates(0);
    setFeeFieldLocked(true);
  };
  const changeCategory = (obj, value) => {
    setFieldsInvalid(false);
    setInvalidFields([]);
    setCategoryObj(value);
    if (value !== null) {
      setCategory(value.category);
      if (value.category === "Unpaid") {
        setFeeToZero();
      } else {
        if (feeFieldLocked) {
          console.log("feeFieldLocked");
          setFeeFieldLocked(false);
        }
      }
    }
  };

  const handleChangeProviderType = (obj, value) => {
    props.getLowerCasePeople(value);
    if (value == "Contractor") {
      props.getContractors();
    } else {
      props.getTalent();
    }
    setFieldsInvalid(false);
    setInvalidFields([]);
    setProviderType(value);
  };

  const selectNewPerson = (value) => {
    changeProvider("", value);
  };

  const changeRole = (object, value) => {
    setFieldsInvalid(false);
    setInvalidFields([]);
    if (typeof object !== "string" && value === undefined) {
      setRoleObj(object);
    }
  };
  const createNewRole = (role) => {
    axios
      .post(`Roles/PostRole`, role)
      .then((res) => {
        console.log(res);
        changeRole(res.data);
        setGetRoles(true);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const changeStartTime = (value) => {
    setStartTime(value);
    setDeltaStartTime(true);
  };

  const changeAllocatedRates = (value) => {
    setFieldsInvalid(false);
    setInvalidFields([]);
    if(categoryObj?.quoted && value <= categoryObj.quoted){
      setAllocatedRates(value);
    } else if(categoryObj?.category == "Unpaid"){
      setAllocatedRates(value);
    }
  };

  const changeProvider = (e, value) => {
    if (value === null) {
      setProviderName(undefined);
      setCompanyId(undefined);
      setPersonId(undefined);
      return;
    } else {
      if (value.firstName + " " + value.lastName) {
        setPersonId(value.id);
        setProviderName(value.firstName + " " + value.lastName);
      } else {
        setPersonId(value.id);
        setProviderName(value.firstName + " " + value.lastName);
      }
      setFieldsInvalid(false);
      setInvalidFields([]);
    }
    setProviderObj(value);
  };

  useEffect(() => {
    if (getRoles) {
      setGetRoles(false);
      axios
        .get(`Roles/getRoles`)
        .then((res) => {
          console.log(res.data);
          setAllRoles(res.data);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [getRoles]);

  useEffect(() => {
    console.log("filteringRoles");
    var filteredRoles = [];
    allRoles.forEach((role) => {
      if (role.category === providerType) {
        filteredRoles.push(role);
      }
    });
    setRoleList(filteredRoles);
  }, [providerType]);

  const checkAllFieldsValid = () => {
    if (
      allocatedRates === null ||
      (categoryObj.category !== "Unpaid" && allocatedRates === 0) ||
      providerObj === null ||
      roleObj === null ||
      categoryObj === null ||
      providerType === null
    ) {
      let invalidFieldsArr = [];
      if (
        allocatedRates === null ||
        allocatedRates === "" ||
        allocatedRates === 0
      ) {
        invalidFieldsArr.push("AllocatedRates");
      }
      if (providerObj === null) {
        invalidFieldsArr.push("ProviderName");
      }
      if (roleObj === null) {
        invalidFieldsArr.push("Role");
      }
      if (categoryObj === null) {
        invalidFieldsArr.push("Category");
      }
      if (providerType === null) {
        invalidFieldsArr.push("Type");
      }
      props.setSnackBarProps("warning", "Missing fields", true);
      setFieldsInvalid(true);
      setInvalidFields(invalidFieldsArr);
      return false;
    } else {
      return true;
    }
  };

  const submitNewScheduleItem = () => {
    if (checkAllFieldsValid()) {
      var url = `/ScheduleItems/PostScheduleItemFromPrePost/${props.project.id}`;
      if (providerType === "Talent") {
        url = `/ScheduleItems/PostTalentScheduleItemFromPrePost/${props.project.id}`;
      }
      axios
        .post(url, {
          category: providerType === "Client" ? "ClientItem" : category,
          budgetId: categoryObj?.budgetId || null,
          startTime:
            deltaStartTime === false
              ? format(startTime, "yyyy-MM-dd")
              : format(startTime, "yyyy-MM-dd"),
          allocatedRates: allocatedRates === null ? 0 : allocatedRates,
          invoiceStatus: null,
          scheduleSentStatus: null,
          providerName: providerName,
          isStaff:
            providerType === "Staff" ? 1 : providerType === "Talent" ? 2 : 0,
          isClient: 0,
          prePostNotes: "-",
          companyFk: project?.companyFk ? project?.companyFk : null,
          personFk: personFk,
          contractStatus: providerType === "Talent" ? "Disabled" : "Send",
          talentContractType:
            providerType === "Talent"
              ? "Restricted"
              : providerType === "Contractor"
              ? "BuyOut"
              : null,
          talentContractStatus: providerType === "Talent" ? "Incomplete" : null,
          roleFk: roleObj !== null ? roleObj.id : null,
          createdBy: user?.email,
        })
        .then((response) => {
          props.passNewScheduleUp(response.data);
          setCategory(null);
          setStartTime(null);
          setAllocatedRates(0);
          setProviderName(null);
          setProviderObj(null);
          setCategoryObj(null);
          setRoleObj(null);
          setFeeFieldLocked(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const checkIfUnpaid = () => {
    if (categoryObj.category !== "Unpaid") {
      if (allocatedRates === "" || allocatedRates === null) {
        return true;
      } else {
        try {
          var fee = parseInt(allocatedRates);
          if (fee === 0) {
            return true;
          }
        } catch (e) {
          // alert("No");
        }
      }
    }
    return false;
  };

  const handleSubmitButton = () => {
    props.providerType === "Talent" || props.providerType === "Contractor"
      ? checkIfUnpaid()
        ? setShowWarning(true)
        : submitNewScheduleItem()
      : submitNewScheduleItem();
  };

  const handleChangeToUnpaid = () => {
    setCategory("Unpaid");
    setCategoryObj({ category: "Unpaid" });
    changeAllocatedRates("0");
    setChangedToUnpaid(true);
  };
  useEffect(() => {
    if (changedToUnpaid) {
      setChangedToUnpaid(false);
      submitNewScheduleItem(); //props.rerenderFn
      setShowWarning(false);
    }
  }, [changedToUnpaid]);

  var providerError = (
    <div>
      <Dialog
        open={showCategoryError}
        onClose={() => setShowCategoryError(false)}
      >
        <DialogContent>
          <DialogContentText>
            Please select either "Contractor" or "Talent" before adding a
            person.
          </DialogContentText>
        </DialogContent>
        <DialogContent>
          <Autocomplete
            options={["Contractor", "Talent"]}
            getOptionLabel={(option) => option}
            disableClearable={true}
            value={providerType}
            style={{
              width: "auto",
            }}
            size="small"
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Talent/Contractor *"
                fullWidth
                size="small"
                error={fieldsInvalid && invalidFields.indexOf("Type") !== -1}
              />
            )}
            className={classes.margin}
            onChange={handleChangeProviderType}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleAddContactModal();
              setShowCategoryError(false);
            }}
            color="primary"
            autoFocus
            style={providerType === null ? { display: "none" } : {}}
          >
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );

  return (
    <div className={classes.root}>
      <Snacky
        snackprops={props.snackbar}
        setSnackBarProps={props.setSnackBarProps}
      />
      {providerError}
      {props.project.isArchived !== 1 && (
        <StyledFab
          size="small"
          style={{ position: "absolute", zIndex: "10", marginTop: "-20px" }}
          // onClick={() =>
          //   providerType !== null
          //     ? handleAddContactModal()
          //     : setShowCategoryError(true)
          // }
          onClick={() => setShowCategoryError(true)}
        >
          <PersonAdd />
        </StyledFab>
      )}
      {existingContactsModalState && (
        <ExistingContactsPopup
          handleModal={() => handleExistingContactModal()}
          duplicates={duplicates}
          handleEdit={handleEditExisting}
          modalState={existingContactsModalState}
          setNewContact={setNewContact}
          handleContinue={handleContinue}
          selectExisting={selectExisting}
        />
      )}
      {addContactModalState && providerType != "" ? (
        <AddContactPopUp
          handleModal={() => handleAddContactModal()}
          handleAddContactModal={() => handleAddContactModal()}
          modalState={addContactModalState}
          category={providerType}
          selectNewPerson={selectNewPerson}
          people={props.people}
          setDuplicates={setDuplicates}
          goAhead={goAhead}
          setGoAhead={setGoAhead}
          goAheadContact={newContact}
          setNewContact={setNewContact}
          parent={"NewScheduleItem"}
        />
      ) : (
        ""
      )}
      {openContractorPopup && (
        <ContractorPopUp
          handleModal={() => setOpenContractorPopUp(false)}
          modalState={openContractorPopup}
          personId={personFk}
          reloadPersonList={props.reloadPersonList}
          type="NewScheduleItem"
          providerType={props.providerType}
          closePopUps={() => closePopUps()}
          selectExisting={selectExisting}
        />
      )}
      {showWarning ? (
        <Dialogy
          setDialogProps={setDialogProps}
          buttonProps={{
            show: showWarning,
            titleText: "Warning",
            contentText: "Budget Account must be unpaid if the fee is $0",
            buttonOneColor: "primary",
            buttonOneText: "Cancel",
            buttonTwoColor: "primary",
            buttonTwoText: "Change to Unpaid & Continue",
          }}
          handleButtonOne={() => setShowWarning(false)}
          handleButtonTwo={() => handleChangeToUnpaid()}
        />
      ) : (
        <React.Fragment></React.Fragment>
      )}

      {console.log(props.people)}
      <StyledPaper>
        <Table size="small" aria-label="a dense table">
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <TableBody>
              <TableRow>
                <StyledTableCell
                  style={{ width: 220, padding: "6px 10px 5px 50px" }}
                >
                  <div className={"schedule-item-category"}>
                    <Autocomplete
                      options={["Contractor", "Talent"]}
                      getOptionLabel={(option) => option}
                      disableClearable={true}
                      value={providerType}
                      style={{
                        width: "auto",
                      }}
                      size="small"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Talent/Contractor *"
                          fullWidth
                          size="small"
                          error={
                            fieldsInvalid &&
                            invalidFields.indexOf("Type") !== -1
                          }
                        />
                      )}
                      className={classes.margin}
                      onChange={handleChangeProviderType}
                    />
                  </div>
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: 220, padding: "6px 10px 5px 50px" }}
                >
                  <div className={"schedule-item-category"}>
                    <Autocomplete
                      options={props.budgetItems.filter(b => b.totalAmount <= b.quoted)}
                      // options={
                      //   Object.keys(props.budgetItems).length === 0 && props.budgetItems.constructor === Object
                      //     ? props.budgetItems
                      //     : [...props.budgetItems, { category: "Unpaid" }]
                      // }
                      getOptionLabel={(option) => option.category}
                      value={categoryObj}
                      style={{
                        width: "auto",
                      }}
                      size="small"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Account *"
                          fullWidth
                          size="small"
                          error={
                            fieldsInvalid &&
                            invalidFields.indexOf("Category") !== -1
                          }
                        />
                      )}
                      className={classes.margin}
                      onChange={changeCategory}
                    />
                  </div>
                </StyledTableCell>
                <StyledTableCell
                  style={{ padding: "6px 10px 5px 50px" }} //width: 220,
                >
                  <div className={"schedule-item-provider"}>
                    <Autocomplete
                      options={roleList}
                      //getOptionLabel={(option) => option.fieldRole}
                      value={roleObj}
                      style={{
                        width: "auto",
                      }}
                      size="small"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Role *"
                          fullWidth
                          size="small"
                          error={
                            fieldsInvalid &&
                            invalidFields.indexOf("Role") !== -1
                          }
                        />
                      )}
                      className={classes.margin}
                      onChange={(event, newValue) => {
                        // Create a new value from the user input
                        if (newValue && newValue.inputValue) {
                          var role = {
                            fieldRole: newValue.inputValue,
                            category: providerType,
                          };
                          changeRole(role);
                          createNewRole(role);
                          return;
                        } else {
                          changeRole(newValue);
                        }
                      }}
                      getOptionLabel={(option) => {
                        // Value selected with enter, right from the input
                        if (typeof option === "string") {
                          return option;
                        }
                        // Add "xxx" option created dynamically
                        if (option.fieldRole) {
                          return option.fieldRole;
                        }
                        // Regular option
                        return option.fieldRole;
                      }}
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);
                        console.log("options");
                        console.log(options);
                        const createOption = [
                          {
                            inputValue: params.inputValue,
                            fieldRole: `Create "${params.inputValue}"`,
                          },
                        ];
                        let newOptions = [];
                        // Suggest the creation of a new value
                        if (params.inputValue !== "") {
                          newOptions = createOption.concat(filtered);
                          console.log(newOptions);
                          console.log(filtered);
                        } else {
                          newOptions = filtered;
                        }
                        return newOptions;
                      }}
                      renderOption={(option, { selected }) => {
                        const checkOption = 'Create "';
                        if (option.fieldRole.includes(checkOption)) {
                          return (
                            <React.Fragment>
                              <span className={"blueText"}>
                                {option.fieldRole}
                              </span>
                            </React.Fragment>
                          );
                        } else {
                          return option.fieldRole;
                        }
                      }}
                    />
                  </div>
                </StyledTableCell>

                <StyledTableCell
                  style={{ width: 220, padding: "6px 10px 5px 50px" }}
                >
                  <div
                    style={{ display: "flex", alignItems: "flex-end" }}
                    className={"schedule-item-provider"}
                  >
                    <Autocomplete
                      disabled={providerType == null}
                      options={
                        providerType != null
                          ? props.people[providerType.toLowerCase()]
                          : null
                      }
                      getOptionLabel={(option) =>
                        option.firstName + " " + option.lastName + `${option.isInternal ? " (internal)": ""}`
                      }
                      style={{ width: "100%" }}
                      value={providerObj}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Who? *"
                          // variant="outlined"
                          fullWidth
                          size="small"
                          error={
                            fieldsInvalid &&
                            invalidFields.indexOf("ProviderName") !== -1
                          }
                        />
                      )}
                      className={classes.margin}
                      onChange={(e, value) => changeProvider(e, value)}
                    />

                    <IconButton
                      component="span"
                      size="small"
                      onClick={
                        personFk !== undefined
                          ? () => setOpenContractorPopUp(true)
                          : ""
                      }
                    >
                      <SearchIcon />
                    </IconButton>
                  </div>
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: 7, padding: "6px 10px 5px 50px" }}
                >
                  <TextField
                    placeholder="Fee"
                    //label="Allocated *"
                    style={{
                      minWidth: "100px",
                      maxWidth: "100px",
                      padding: "6px 10px 5px 50px",
                      marginTop: "20px",
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                      error:
                        fieldsInvalid &&
                        invalidFields.indexOf("AllocatedRates") !== -1,
                    }}
                    id="standard-basic-npp"
                    value={allocatedRates}
                    disabled={feeFieldLocked ? true : false}
                    onChange={(e) => changeAllocatedRates(e.target.value)}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <div
                    className={"start-time-picker"}
                    style={{ display: "flex", padding: "6px 10px 5px 80px" }}
                  >
                    <KeyboardDatePicker
                      format="dd/MM/yyyy"
                      minDate={new Date()}
                      margin="normal"
                      label="Due Date"
                      value={startTime}
                      onChange={(value) => changeStartTime(value)}
                      style={{
                        minWidth: "120px",
                        maxWidth: "120px",
                      }}
                      KeyboardButtonProps={{
                        "aria-label": "start time",
                        style: {
                          ...{
                            padding: "0px",
                            margin: "0px",
                          },
                        },
                      }}
                      className={classes.margin}
                    />
                  </div>
                </StyledTableCell>
                <StyledTableCell>
                  <div style={{ display: "flex", height: "100%" }}>
                    <Button
                      onClick={() => handleSubmitButton()} //submitNewScheduleItem(props.rerenderFn)
                      style={{ alignSelf: "flex-end" }}
                      disabled={props.project.isArchived === 1}
                    >
                      Submit
                    </Button>
                  </div>
                </StyledTableCell>
              </TableRow>
            </TableBody>
          </MuiPickersUtilsProvider>
        </Table>
      </StyledPaper>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  snackbar: state.snackbar,
  people: state.people,
  dialog: state.dialog,
});
// https://react-redux.js.org/api/connect
export default connect(mapStateToProps, {
  setSnackBarProps,
  getLowerCasePeople,
  getContractors,
  getTalent,
  getAllPeople,
  setDialogProps,
  postScheduleItem,
})(NewPrePost);
