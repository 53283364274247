import { Typography } from "@material-ui/core";
import React from "react";

const MediaForeignCurrencyNote = ({
  currency,
  fontSize = 16,
  fontWeight = 400,
}) => {
  return (
    <div>
      <Typography style={{ fontSize: fontSize, fontWeight: fontWeight }}>
        NOTE: This client is set to be invoiced in{" "}
        <span style={{ color: "red" }}>{currency}</span>. Please enter all amounts in this currency.
      </Typography>
    </div>
  );
};

export default MediaForeignCurrencyNote;
