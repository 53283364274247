import React, { useEffect, useState } from "react";
import {
    Typography,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    Table,
    TableContainer,
    TableHead,
    TableCell,
    TableBody,
    Paper,
    TableRow,
    IconButton,
    CircularProgress
} from "@material-ui/core";
import {
    Delete as DeleteIcon,
    LinkOff as LinkOffIcon
} from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import "./DeleteOutputErrorPopup.css";
import axios from "axios";

const DeleteOutputErrorPopup = (props) => {
    let history = useHistory();

    const {
        modalState,
        handleModal,
        scheduleItemsForDeleteOutput,
        outputToDelete,
        removeOutput
    } = props;

    const [loading, setLoading] = useState(false);

    return (
        <Dialog
            id="overdue-outputs-popup-dialog"
            open={modalState}
            onClose={(e, reason) => {
                if (reason !== "backdropClick" && reason !== "escapeKeyDown" && !loading) {
                    handleModal();
                }
            }}
        >
            <DialogTitle id="delete-output-error-title">{`${outputToDelete.creativeName} is linked to NZ Standard Contract.`}</DialogTitle>
            <DialogContent id="delete-output-error-subtitle">
                <Typography variant='body1'>
                    {`(Note: Unlink contracts to continue with delete)`}
                </Typography>
            </DialogContent>
            <DialogContent>
                <TableContainer
                    component={Paper}
                >
                    <Table
                        size="small"
                        aria-label="customized table"
                        className='delete-output-error-table'
                    >
                        <TableHead>
                            <TableCell>Schedule Name</TableCell>
                            <TableCell>Full Name</TableCell>
                            <TableCell>Role</TableCell>
                            <TableCell>Contract Status</TableCell>
                            <TableCell>Contract</TableCell>
                        </TableHead>
                        <TableBody>
                            {scheduleItemsForDeleteOutput.map(si => {
                                return (
                                    <TableRow>
                                        <TableCell>
                                            <span
                                                onClick={() => {
                                                    history.push(`${si.schedulePath}`)
                                                }}
                                                className="link"
                                            >
                                                {si.scheduleName}
                                            </span>
                                        </TableCell>
                                        <TableCell>{si.fullName}</TableCell>
                                        <TableCell>{si.role}</TableCell>
                                        <TableCell>{si.contractStatus}</TableCell>
                                        <TableCell>
                                            {si.talentContractDriveId ? <a target={"_blank"} href={`https://drive.google.com/file/d/${si.talentContractDriveId}/view`}>View</a> : ""}
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    onClick={() => handleModal()}
                    disabled={loading}
                >
                    Cancel
                </Button>
                {loading ? <CircularProgress />
                    : <Button
                        disabeld={loading}
                        variant="contained"
                        color='primary'
                        onClick={() => {
                            setLoading(true);
                            axios.delete(`OutputsV2/UnlinkAndDeleteOutput/${outputToDelete.id}`)
                                .then(() => {
                                    removeOutput();
                                    handleModal();
                                }).catch((e) => {
                                    console.log(e)
                                }).finally(() => {
                                    setLoading(false);
                                })
                        }}
                    >
                        Unlink & Delete Output
                    </Button>}
            </DialogActions>
        </Dialog>
    )
};

export default DeleteOutputErrorPopup;
