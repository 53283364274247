import React, { useEffect, useState } from "react";
import DatePicker from "react-multi-date-picker";
import "./monthRangePicker.scss"
const MonthRangePicker = ({ value, handleOnChangeRange, height = 33 }) => {
  const [selectedRange, setSelectedRange] = useState(value);

  const onChange = (newRange) => {
    setSelectedRange(newRange)
    if(newRange && newRange.length > 1){
      handleOnChangeRange(newRange[0].toDate(), newRange[1].toDate())
    }
  }
  
  return (
    <>
      <DatePicker
        value={selectedRange}
        onChange={onChange}
        onlyMonthPicker
        range
        dateSeparator=" to "
        className="month-range-picker-container"
        style={{height: height}}
      />
    </>
  );
};

export default MonthRangePicker;
