import React, { useState, useEffect } from "react";
import {
    Checkbox,
    InputAdornment,
    TableCell,
    TableRow,
    TextField,
    Typography
} from "@material-ui/core";
import {
    currencyFormat,
    filterNumbersOnlyInput
} from "../../../../utils/GeneralUtils";

const ReconcileReceiptBudgetRow = ({
    payable,
    reconLine,
    handleUpdateSelectedReconLines,
    handleRemoveSelectedReconLine
}) => {
    const [usageType, setUsageType] = useState(null);
    const [selectedAmount, setSelectedAmount] = useState(0);

    const handleChangeUseAllCheckbox = (isChecked) => {
        if (isChecked) {
            handleUpdateSelectedReconLines(
                reconLine.budgetId,
                reconLine.remaining
            );
            setUsageType("ALL")
        } else {
            handleRemoveSelectedReconLine(reconLine.budgetId);
            setUsageType(null)
        }
    };

    const handleChangeUsePartialCheckbox = (isChecked) => {
        if (isChecked) {
            handleUpdateSelectedReconLines(
                reconLine.budgetId,
                selectedAmount
            );
            setUsageType("PARTIAL")
        } else {
            handleRemoveSelectedReconLine(reconLine.budgetId);
            setUsageType(null)
        }
    };

    const handleUpdateAmount = (amount) => {
        if (amount > reconLine?.remaining) {
            return;
        } else {
            setSelectedAmount(amount);
            handleUpdateSelectedReconLines(
                reconLine.budgetId,
                amount
            );
        }
    };

    useEffect(() => {
        setSelectedAmount(payable.subtotal <= reconLine.remaining ? payable.subtotal : reconLine.remaining.toFixed(2));
    }, [payable.subtotal, reconLine.remaining]);

    useEffect(() => {
        if (payable.budgetId != null && payable.budgetId === reconLine.budgetId) {
            handleUpdateSelectedReconLines(
                reconLine.budgetId,
                payable.subtotal <= reconLine.remaining ? payable.subtotal : reconLine.remaining.toFixed(2)
            );
            setUsageType("PARTIAL")
        }
    }, [])

    return (
        <TableRow>
            <TableCell colSpan={2} className="reconcile-table-cell-root">
                <Typography variant="caption">
                    {reconLine.clientName}
                </Typography>
            </TableCell>
            <TableCell className="reconcile-table-cell-root">
                <Typography variant="caption">
                    {reconLine.projectName}
                </Typography>
            </TableCell>
            <TableCell className="reconcile-table-cell-root">
                <Typography variant="caption">
                    {reconLine.category}
                </Typography>
            </TableCell>
            <TableCell className="reconcile-table-cell-root">
                <Typography variant="caption">
                    {currencyFormat(reconLine.remaining)}
                </Typography>
            </TableCell>
            {/* <TableCell className="reconcile-table-cell-root">
                <div className="reconcile-table-single-checkbox-container">
                    <Checkbox
                        checked={usageType === "ALL"}
                        onChange={(e) => handleChangeUseAllCheckbox(e.target.checked)}
                    />
                </div>
            </TableCell> */}
            <TableCell className="reconcile-table-cell-root">
                <div className="reconcile-table-single-checkbox-container">
                    <Checkbox
                        checked={usageType === "PARTIAL"}
                        onChange={(e) => handleChangeUsePartialCheckbox(e.target.checked)}
                    />
                </div>
            </TableCell>
            <TableCell className="reconcile-table-cell-root">
                <TextField
                    fullWidth
                    value={selectedAmount}
                    onChange={(e) => handleUpdateAmount(filterNumbersOnlyInput(e.target.value))}
                    size="small"
                    disabled={usageType !== "PARTIAL"}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                    }}
                />
            </TableCell>
        </TableRow>
    )
}

export default ReconcileReceiptBudgetRow;