import React from "react";
import { Menu, MenuItem } from "@material-ui/core";

export const RightClickMenu = (props) => {
  let { handleCloseContext, setSnackBarProps, state, scheduleItem } = props;
  return (
    <Menu
      keepMounted
      open={state.mouseY !== null}
      onClose={handleCloseContext}
      anchorReference="anchorPosition"
      anchorPosition={
        state.mouseY !== null && state.mouseX !== null
          ? { top: state.mouseY, left: state.mouseX }
          : undefined
      }
    >
      <MenuItem disabled>
        {scheduleItem.personFkNavigation.firstName !== null &&
        scheduleItem.personFkNavigation.lastName !== null
          ? `${scheduleItem.personFkNavigation.firstName} ${scheduleItem.personFkNavigation.lastName}`
          : `Person`}
      </MenuItem>
      <MenuItem
        onClick={() => {
          if (
            scheduleItem.personFkNavigation !== null &&
            scheduleItem.personFkNavigation !== undefined
          ) {
            setSnackBarProps("info", "Copied to clipboard", true);
            navigator.clipboard.writeText(
              scheduleItem.personFkNavigation.phoneNumber
                ? scheduleItem.personFkNavigation.phoneNumber
                : "No Phone Number"
            );
            handleCloseContext();
          }
        }}
      >
        Copy Phone
      </MenuItem>
      <MenuItem
        onClick={() => {
          if (
            scheduleItem.personFkNavigation !== null &&
            scheduleItem.personFkNavigation !== undefined
          ) {
            setSnackBarProps("info", "Copied to clipboard", true);
            navigator.clipboard.writeText(
              scheduleItem.personFkNavigation.emailAddress
                ? scheduleItem.personFkNavigation.emailAddress
                : "No Email Address"
            );
            handleCloseContext();
          }
        }}
      >
        Copy Email Address
      </MenuItem>
    </Menu>
  );
};

export const RightClickAgentMenu = (props) => {
  let {
    setSnackBarProps,
    handleCloseAgentContext,
    state,
    handleCloseContext,
    scheduleItem,
  } = props;
  return (
    <Menu
      keepMounted
      open={state.mouseY !== null}
      onClose={handleCloseContext}
      anchorReference="anchorPosition"
      anchorPosition={
        state.mouseY !== null && state.mouseX !== null
          ? { top: state.mouseY, left: state.mouseX }
          : undefined
      }
    >
      <MenuItem disabled>Agent Details</MenuItem>
      <MenuItem
        onClick={() => {
          if (
            scheduleItem.personFkNavigation.agentFkNavigation !== null &&
            scheduleItem.personFkNavigation.agentFkNavigation !== undefined
          ) {
            setSnackBarProps("info", "Copied to clipboard", true);
            navigator.clipboard.writeText(
              scheduleItem.personFkNavigation.agentFkNavigation.phoneNumber
                ? scheduleItem.personFkNavigation.agentFkNavigation.phoneNumber
                : "No Phone Number"
            );
            handleCloseAgentContext();
          }
        }}
      >
        Copy Agent Phone
      </MenuItem>
      <MenuItem
        onClick={() => {
          if (
            scheduleItem.personFkNavigation.agentFkNavigation !== null &&
            scheduleItem.personFkNavigation.agentFkNavigation !== undefined
          ) {
            setSnackBarProps("info", "Copied to clipboard", true);
            navigator.clipboard.writeText(
              scheduleItem.personFkNavigation.agentFkNavigation.emailAddress
                ? scheduleItem.personFkNavigation.agentFkNavigation.emailAddress
                : "No Email Address"
            );
            handleCloseAgentContext();
          }
        }}
      >
        Copy Agent Email Address
      </MenuItem>
    </Menu>
  );
};
