import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Typography,
} from "@material-ui/core";
import axios from "axios";
import React, { useState, useEffect } from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import BudgetAccordionHead from "./BudgetAccordionHead";
import BudgetItemDetails from "./BudgetItemDetails";
import AddBudgetPopup from "./AddBudgetPopup";

const BudgetAccordion = ({
  budgetByProject,
  filterBudget,
  setSnackBarProps,
  handleCloseProject,
  handleCloseAndTransfer,
  groupedBudgetsByProjectId,
  handleCloseProjectWithLiability
}) => {
  const [expanded, setExpanded] = useState(false);

  const [fetchingRecords, setFetchingRecords] = useState(false);
  const [getRecords, setGetRecords] = useState(false);
  const [records, setRecords] = useState([]);

  useEffect(() => {
    if (getRecords) {
      setGetRecords(false);
      setFetchingRecords(true);
      var ids = [];
      for (var i = 0; i < budgetByProject.length; i++) {
        ids.push(budgetByProject[i].budgetId)
      }
      axios.post(`BudgetItems/GetRecordsForBudgets`, ids)
        .then(res => {
          setRecords(res.data)
        })
        .catch(e => console.log(e))
        .finally(() => setFetchingRecords(false))
    }
  }, [getRecords]);

  useEffect(() => {
    if (expanded) {
      setGetRecords(true)
    }
  }, [expanded])

  return (
    <Accordion
      expanded={expanded}
      onChange={(e) => setExpanded(!expanded)}
      className="budget-table-accordion-container"
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <BudgetAccordionHead
          groupedBudgetsByProjectId={groupedBudgetsByProjectId}
          budgetByProject={budgetByProject}
          expanded={expanded}
          handleCloseProject={handleCloseProject}
          handleCloseAndTransfer={handleCloseAndTransfer}
          handleCloseProjectWithLiability={handleCloseProjectWithLiability} />
      </AccordionSummary>
      <AccordionDetails>
        <div style={{ display: "flex", flexDirection: "column", gap: 20 }}>
          {budgetByProject?.length > 0 &&
            filterBudget(budgetByProject).map((b, index) => {
              var recordsForThisBudget = records.filter(r => r.id == b.budgetId);
              return (
                <BudgetItemDetails
                  key={index}
                  budget={b}
                  setSnackBarProps={setSnackBarProps}
                  expanded={expanded}
                  records={recordsForThisBudget[0] ? recordsForThisBudget[0]?.records : []}
                  fetchingRecords={fetchingRecords}
                />
              )
            })}
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default BudgetAccordion;
