import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";

const EditInvoiceReferenceModal = ({
  open,
  handleClose,
  invoice,
  saveReference,
  saving,
}) => {
  const [reference, setReference] = useState("");

  useEffect(() => {
    if (invoice) {
      setReference(invoice?.reference);
    }
  }, [invoice]);

  const onChangeReference = (e) => {
    if (e.target?.value) {
      setReference(e.target.value);
    } else {
      setReference("");
    }
  };

  return (
    <div>
      <Dialog
        onClose={(e, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        open={open}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Update Invoice Reference
        </DialogTitle>
        <DialogContent>
          <Grid container item justify="flex-start" direction="column">
            <Tooltip title="Invoice Reference/PO Number" placement="left">
              <TextField
                disabled={saving}
                id="ponumber"
                label="Reference"
                variant="outlined"
                value={reference}
                size="small"
                onChange={(e) => onChangeReference(e)}
              />
            </Tooltip>
          </Grid>
        </DialogContent>
        <DialogActions>
          <div style={{ padding: 10, display: "flex", justifyContent: "end", gap: 10 }}>
            {saving ? (
              <CircularProgress />
            ) : (
              <>
                <Button onClick={handleClose} className="default-button-grey">
                  Cancel
                </Button>
                <Button
                  className="default-button"
                  onClick={(e) => saveReference(reference)}
                >
                  Save
                </Button>
              </>
            )}
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EditInvoiceReferenceModal;
