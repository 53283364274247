import React, { useState, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
// import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import { ProjectCard } from "./ProjectCard";
import { OrphanProjectCard } from "./OrphanProject";
import "./Home.css";

export const DnDBoard = (props) => {
  let { resetOutputsLoading, resetOrphans } = props;
  const [activeProject, setActiveProject] = useState();
  const [showCardContents, setShowCardContents] = useState([]);
  const onDragFin = (result) => {
    console.log(result);
    const { destination, source, draggableId } = result;
    if (draggableId.split("-")[1] == "NoProject") {
      return;
    } else {
      if (draggableId.slice(0, 5) == "Proj-") {
        handleDropProject(destination, source, draggableId);
      }
      if (
        source.droppableId !== destination.droppableId &&
        draggableId.slice(0, 5) !== "Proj-"
      ) {
        handleDropOutput(destination, source, draggableId);
      }
    }
  };

  const handleDropProject = (destination, source, draggableId) => {
    console.log("handle drop project");
    var thisProject = props.projects[source.droppableId][source.index];
    if (thisProject.outputs.length === 0) {
      console.log(
        "Attempted to move real project without outputs. Not permitted."
      );
      return;
    }
    if (isNaN(draggableId.split("-")[1])) {
      if (isNaN(destination.droppableId)) {
        if (destination.droppableId.split("-").length > 1) {
          if (destination.droppableId.split("-")[3] == source.droppableId) {
            console.log(
              "dropped shell project on own source stage - do nothing"
            );
            return;
          } else {
            var ownerProjectId = draggableId.split("-")[3];
            var ownerProject = props.projects.projects.find(
              (proj) => proj.id == ownerProjectId
            );
            if (
              ownerProject.progressCode == destination.droppableId.split("-")[3]
            ) {
              props.returnShellOutputsToOwner(ownerProject, source);
              console.log(
                "shell project dropped on shell project in owner column"
              );
              return;
            } else {
              var orphansInDestination =
                props.outputs[destination.droppableId.split("-")[3]];
              var siblings = orphansInDestination.filter(
                (output) => output.projectFk == draggableId.split("-")[2]
              );
              if (siblings.length == 0) {
                props.updateShellProjectProgress(
                  destination,
                  source,
                  draggableId
                );
                console.log("shell project dropped on column without siblings");
                return;
              } else {
                console.log("shell project dropped on column with siblings");
                var shellProject = props.projects[
                  draggableId.split("-")[3]
                ].find((proj) => proj.id == draggableId);
                props.mergeOutputsToShellProjectOnShellProjectDrop(
                  destination,
                  source,
                  draggableId,
                  siblings
                );
                props.spliceProject(shellProject);
                return;
              }
            }
          }
        } else {
          if (destination.droppableId == source.droppableId) {
            console.log("shell project dropped on grid, grid is origin");
            return;
          } else {
            var ownerProjectId = draggableId.split("-")[3];
            var ownerProject = props.projects.projects.find(
              (proj) => proj.id == ownerProjectId
            );

            if (ownerProject.progressCode == destination.droppableId) {
              console.log(
                "Shell project in owner column, return outputs to owner."
              );
              console.log(props.projects[source.droppableId]);
              props.spliceOutputsFromColumn(
                source.droppableId,
                ownerProject.id
              );
              props.returnShellOutputsToOwner(ownerProject, source);

              console.log("shell project dropped on owner column");
              return;
            } else {
              var orphansInDestination = props.outputs[destination.droppableId];
              var siblings = orphansInDestination.filter(
                (output) => output.projectFk == draggableId.split("-")[3]
              );
              console.log(siblings);
              if (siblings.length == 0) {
                console.log("shell project dropped on column without siblings");
                var outputsToSplice =
                  props.projects[source.droppableId][source.index].outputs;
                props.updateShellProjectProgress(
                  destination,
                  source,
                  draggableId
                );
                props.spliceOutputsFromColumn(
                  source.droppableId,
                  ownerProject.id
                );
                props.spliceOutputsToColumn(
                  destination.droppableId,
                  outputsToSplice
                );
                return;
              } else {
                var outputsToSplice =
                  props.projects[source.droppableId][source.index].outputs;
                var shellArr = props.projects[
                  destination.droppableId
                ].filter((proj) => isNaN(proj.id));
                var shellToSplice = shellArr.find(
                  (proj) => proj.id.split("-")[2] == draggableId.split("-")[2]
                );
                props.mergeOutputsToShellProjectOnShellProjectDrop(
                  destination,
                  source,
                  draggableId,
                  siblings,
                  shellToSplice
                );
                props.spliceOutputsToColumn(
                  destination.droppableId,
                  outputsToSplice
                );
                props.spliceOutputsFromColumn(
                  source.droppableId,
                  ownerProject.id
                );
                console.log("shell project dropped on column with siblings");
                return;
              }
            }
          }
        }
      }
    } else {
      if (isNaN(destination.droppableId)) {
        if (destination.droppableId.split("-").length > 1) {
          if (destination.droppableId.split("-")[3] == source.droppableId) {
            console.log(
              "dropped real project on own source stage - do nothing"
            );
            return;
          } else {
            var orphansInDestination =
              props.outputs[destination.droppableId.split("-")[3]];
            var children = orphansInDestination.filter(
              (output) => output.projectFk == draggableId.split("-")[1]
            );
            if (children.length == 0) {
              props.updateRealProjectProgress(destination, source, draggableId);
              console.log(
                "real project dropped on shell project in column without belonging orphans"
              );
              return;
            } else {
              console.log(
                "real project dropped on shell project in column with belonging orphans"
              );
              props.mergeOrphansToRealProjectOnDrop(
                destination,
                source,
                draggableId,
                children
              );
              props.updateRealProjectProgress(destination, source, draggableId);
              props.spliceOutputsFromColumn(
                destination.droppableId,
                draggableId.split("-")[1]
              );
              return;
            }
          }
        } else {
          if (destination.droppableId == source.droppableId) {
            console.log("real project dropped on own grid - do nothing");
            return;
          } else {
            var orphansInDestination = props.outputs[destination.droppableId];
            var children = orphansInDestination.filter(
              (output) => output.projectFk == draggableId.split("-")[1]
            );
            if (children.length == 0) {
              console.log(
                "real project dropped on column without belonging orphans"
              );
              props.updateRealProjectProgress(destination, source, draggableId);
              console.log(destination.droppableId);
              props.resortProjects(destination.droppableId);

              return;
            } else {
              console.log(
                "real project dropped on column with belonging orphans"
              );
              props.mergeOrphansToRealProjectOnDrop(
                destination,
                source,
                draggableId,
                children
              );
              props.updateRealProjectProgress(destination, source, draggableId);
              props.spliceOutputsFromColumn(
                destination.droppableId,
                draggableId.split("-")[1]
              );
              return;
            }
          }
        }
      } else {
        return;
        //Destination is real project. Pretty sure not possible, but keeping this code here just in case.
        // Check if is in source column
        var destinationProject = props.projects.projects.find(
          (proj) => proj.id == destination.droppableId.split("-")[1]
        );
        if (destinationProject.progressCode == destination.droppableId) {
          console.log(
            "real project dropped on real project in own column. do nothing."
          );
          return;
        } else {
          // Check for orphans
          var orphansInDestination =
            props.outputs[destinationProject.progressCode];
          var children = orphansInDestination.filter(
            (output) => output.projectFk == draggableId.split("-")[1]
          );
          if (children.length == 0) {
            console.log(
              "real project dropped on real project in column without belonging orphans"
            );
            props.updateRealProjectProgress(destination, source, draggableId);
            return;
          } else {
            console.log(
              "real project dropped on real project in column with belonging orphans"
            );
            props.mergeOrphansToRealProjectOnDrop(
              destination,
              source,
              draggableId,
              children
            );
            props.updateRealProjectProgress(destination, source, draggableId);

            return;
          }
        }
      }
    }
  };

  const handleDropOutput = (destination, source, draggableId) => {
    var parentProject;
    console.log(destination);
    console.log(source);
    console.log(draggableId);
    if (source.droppableId.split("-").length == 1) {
      parentProject = props.projects.projects.find(
        (p) => p.id == source.droppableId
      );
    } else {
      parentProject = props.projects[source.droppableId.split("-")[3]].find(
        (p) => p.id == source.droppableId
      );
    }
    console.log(parentProject);
    var output = props.outputs.outputs.find((o) => o.id == draggableId);
    var ownerProject = props.projects.projects.find(
      (p) => p.id == output.projectFk
    );
    var destinationProject;
    if (isNaN(destination.droppableId)) {
      if (destination.droppableId.slice(0, 2) == "O-") {
        console.log("output dropped on shell, shell contains siblings");
        if (destination.droppableId.split("-")[2] == output.projectFk) {
          var thisProject = props.projects[
            destination.droppableId.split("-")[3]
          ].find((p) => p.id == destination.droppableId);
          props.spliceOutputsToColumn(
            destination.droppableId.split("-")[3],
            output
          );
          props.mergeOrphanSiblings(thisProject, output);
          props.spliceOneOutputFromColumn(parentProject.progressCode, output);
          props.spliceOneFromReal(parentProject, output);

          return;
        } else {
          console.log(
            "Output dropped on shell, shell does not contain siblings. do nothing"
          );
          return;
        }
      } else {
        console.log("output dropped on grid");
        console.log(parentProject);
        if (destination.droppableId == parentProject.progressCode) {
          console.log(
            "output dropped on grid, same grid as parent. do nothing"
          );
          return;
        } else if (
          destination.droppableId != parentProject.progressCode &&
          destination.droppableId == ownerProject.progressCode
        ) {
          console.log("output dropped on grid - owner's grid");
          if (parentProject.outputs.length == 1) {
            console.log(
              "output dropped on grid, owner's grid, only child of parent - testing"
            );
            console.log(ownerProject);
            console.log(parentProject);
            console.log(output);
            console.log(source);
            console.log(source.droppableId.split("-")[3]);
            props.spliceOutputsFromColumn(
              destination.droppableId,
              ownerProject.id
            );
            props.spliceOutputsFromColumn(
              source.droppableId.split("-")[3],
              ownerProject.id
            );
            props.returnOutput(ownerProject, parentProject, output);
            props.spliceProject(parentProject);
            //props.spliceOutputsFromColumn(source.droppableId, parentProject.id);

            return;
          } else {
            console.log(
              "output dropped on grid, owner's grid, not only child of parent - testing"
              //Parent must be shell
            );
            props.returnOutput(ownerProject, parentProject, output);
            props.spliceOneFromShell(parentProject, output, source.index);
            props.spliceOneOutputFromColumn(
              source.droppableId.split("-")[3],
              output
            );
          }
        } else if (
          destination.droppableId != parentProject.progressCode &&
          destination.droppableId != ownerProject.progressCode
        ) {
          console.log("output dropped on grid, not owner's not parent's");
          var siblings = props.outputs[destination.droppableId].filter(
            (orphan) => orphan.projectFk == output.projectFk
          );
          if (siblings.length == 0) {
            console.log(
              "output dropped on grid, not owner's not parent's, no siblings"
            );
            if (parentProject.outputs.length == 1) {
              console.log(
                "output dropped on grid, not owner's not parent's, no siblings, only child of parent - testing"
              );
              if (!isNaN(parentProject.id)) {
                console.log(
                  "output dropped on grid, not owner's not parent's, no siblings, only child of parent, parent is real - testing"
                );
                // To simulate project source
                var dummySource = {
                  droppableId: parentProject.progressCode,
                  index: props.projects[parentProject.progressCode].indexOf(
                    props.projects[parentProject.progressCode].find(
                      (proj) => proj.id == source.droppableId
                    )
                  ),
                };
                // To simulate project source
                var dummyDraggableId = "Proj-" + output.projectFk;
                props.updateRealProjectProgress(
                  destination,
                  dummySource,
                  dummyDraggableId
                );
                return;
              } else {
                var dummySource = {
                  droppableId: source.droppableId.split("-")[3],
                  index: props.projects[
                    source.droppableId.split("-")[3]
                  ].indexOf(
                    props.projects[source.droppableId.split("-")[3]].find(
                      (proj) => proj.id == source.droppableId
                    )
                  ),
                };
                props.updateShellProjectProgress(
                  destination,
                  dummySource,
                  source.droppableId
                );
                var dest = {
                  destination: destination.droppableId,
                  code: destination.droppableId,
                };
                props.spliceOutputsToColumn(destination.droppableId, [output]);
                props.spliceOneOutputFromColumn(
                  source.droppableId.split("-")[3],
                  output
                );
                console.log(
                  "output dropped on grid, not owner's not parent's, no siblings, only child of parent, parent is shell - testing"
                );
                return;
              }
            } else {
              console.log(
                "output dropped on grid, not owner's not parent's, no siblings, not only child of parent"
              );
              if (!isNaN(parentProject.id)) {
                console.log(
                  "output dropped on grid, not owner's not parent's, no siblings, not only child of parent, parent is real - testing"
                );
                console.log(destination.droppableId);
                console.log(output);
                props.spliceOutputsToColumn(destination.droppableId, [output]);
                var dest = {
                  destination: destination.droppableId,
                  code: destination.droppableId,
                };
                props.copyProject(
                  ownerProject,
                  parentProject,
                  output,
                  source,
                  dest
                );
                return;
              } else {
                console.log(
                  "output dropped on grid, not owner's not parent's, no siblings, not only child of parent, parent is shell - testing"
                );
                var dummySource = {
                  droppableId: source.droppableId.split("-")[3],
                  index: props.projects[
                    source.droppableId.split("-")[3]
                  ].indexOf(
                    props.projects[source.droppableId.split("-")[3]].find(
                      (proj) => proj.id == source.droppableId
                    )
                  ),
                };
                var dest = {
                  destination: destination.droppableId,
                  code: destination.droppableId,
                };
                props.copyProject(
                  ownerProject,
                  parentProject,
                  output,
                  source,
                  dest
                );
                props.spliceOneOutputFromColumn(
                  source.droppableId.split("-")[3],
                  output
                );
                props.spliceOutputsToColumn(destination.droppableId, [output]);
                return;
              }
            }
          } else {
            console.log(
              "output dropped on grid, not owner's not parent's, some siblings"
            );
            if (parentProject.outputs.length == 1) {
              console.log(
                "output dropped on grid, not owner's not parent's, some siblings, only child of parent"
              );
              if (isNaN(parentProject.id)) {
                console.log(
                  "output dropped on grid, not owner's not parent's, some siblings, only child of parent, parent is shell - testing"
                );
                var shellArr = props.projects[
                  destination.droppableId
                ].filter((proj) => isNaN(proj.id));
                var thisProject = shellArr.find(
                  (p) => p.id.split("-")[2] == output.projectFk
                );
                console.log(thisProject);
                props.mergeOrphanSiblings(thisProject, output);
                props.spliceProject(parentProject);
                props.spliceOutputsToColumn(destination.droppableId, [output]);
                props.spliceOneOutputFromColumn(
                  source.droppableId.split("-")[3],
                  output
                );
                return;
              } else {
                console.log(
                  "output dropped on grid, not owner's not parent's, some siblings, only child of parent, parent is real - testing"
                );
                // update Real progress
                var dummySource = {
                  droppableId: parentProject.progressCode,
                  index: props.projects[parentProject.progressCode].indexOf(
                    props.projects[parentProject.progressCode].find(
                      (proj) => proj.id == source.droppableId
                    )
                  ),
                };
                // To simulate project source
                var dummyDraggableId = "Proj-" + output.projectFk;
                props.updateRealProjectProgress(
                  destination,
                  dummySource,
                  dummyDraggableId
                );
                // Need To merge here
                var orphansInDestination =
                  props.outputs[destination.droppableId];
                var siblings = orphansInDestination.filter(
                  (op) => op.projectFk == output.projectFk
                );
                var dummySource = {
                  droppableId: destination.droppableId,
                  index: 0,
                };
                props.mergeOrphansToRealProjectOnDrop(
                  destination,
                  dummySource,
                  parentProject.id,
                  siblings
                );
                var dest = {
                  destination: destination.droppableId,
                  code: null,
                };
                props.spliceOutputsFromColumn(
                  destination.droppableId,
                  ownerProject.id
                );
                return;
              }
            } else {
              console.log(
                "output dropped on grid, not owner's not parent's, some siblings, not only child of parent - testing"
              );
              if (!isNaN(parentProject.id)) {
                console.log(
                  "output dropped on grid, not owner's not parent's, some siblings, not only child of parent, parent is real - testing"
                );
                console.log(parentProject);
                props.spliceOneOutputFromColumn(
                  parentProject.progressCode,
                  output
                );
                props.spliceOutputsToColumn(destination.droppableId, [output]);
                var dest = {
                  destination: destination.droppableId,
                  code: destination.droppableId,
                };
                var shellArr = props.projects[
                  destination.droppableId
                ].filter((proj) => isNaN(proj.id));
                var thisProject = shellArr.find(
                  (p) => p.id.split("-")[2] == output.projectFk
                );
                props.mergeOrphanSiblings(thisProject, output);
                props.spliceOneFromReal(parentProject, output);

                return;
              } else {
                console.log(
                  "output dropped on grid, not owner's not parent's, some siblings, not only child of parent, parent is shell - testing"
                );
                var dest = {
                  destination: destination.droppableId,
                  code: destination.droppableId,
                };
                var shellArr = props.projects[
                  destination.droppableId
                ].filter((proj) => isNaN(proj.id));
                console.log(shellArr);
                var thisProject = shellArr.find(
                  (p) => p.id.split("-")[2] == output.projectFk
                );
                console.log(thisProject);
                console.log(parentProject);
                props.mergeOrphanSiblings(thisProject, output);
                props.spliceOneFromShell(parentProject, output, source.index);
                props.spliceOneOutputFromColumn(
                  source.droppableId.split("-")[3],
                  output
                );
                props.spliceOutputsToColumn(destination.droppableId, [output]);
                return;
              }
            }
          }
        }
      }
    }
  };

  return (
    <DragDropContext onDragEnd={onDragFin}>
      <div className="paper">
        <Grid
        spacing={2}
          container
          className="root-s-o root"
          style={{
            display: "flex",
            flexFlow: "column",
            alignItems: "center",
          }}
          //style={{ display: "flex", flexFlow: "column" }}
        >
          <Droppable droppableId={"O"}>
            {(provided) => (
              <Grid
                item
                ref={provided.innerRef}
                {...provided.droppableProps}
                style={{
                  backgroundColor: "#c8c8c8",
                  overflowY: "auto",
                  borderRadius: 5,
                  minHeight: "402px",
                  display: "flex",
                  flexFlow: "column",
                  alignItems: "center",
                  width: "100%",
                  marginBottom: "10px",
                }}
              >
                <Typography className="typography-font">
                  Awaiting Outputs
                </Typography>
                {props.getFilteredProjects(props.projects.O).map((project, index) => {
                  return (
                    <ProjectCard
                      resortProjects={props.resortProjects}
                      sortProjectsByDelivery={props.sortProjectsByDelivery}
                      resetProjectLoading={props.resetProjectLoading}
                      resetOutputsLoading={resetOutputsLoading}
                      resetOrphans={resetOrphans}
                      setActiveProject={setActiveProject}
                      activeProject={activeProject}
                      openAll={props.openAll}
                      showExtraInfo={props.showExtraInfo}
                      key={project.id}
                      index={index}
                      project={project}
                      showCardContents={showCardContents}
                      setShowCardContents={setShowCardContents}
                      // setActiveProject={setActiveProject}
                      lockProductionCore={props.lockProductionCore}
                      displayOverdueWarning={props.displayOverdueWarning}
                    />
                  );
                })}
                {provided.placeholder}
              </Grid>
            )}
          </Droppable>
          <Grid
            item
            style={{
              backgroundColor: "#c8c8c8",
              overflowY: "auto",
              borderRadius: 5,
              minHeight: "calc(100% - 412px)",
              display: "flex",
              flexFlow: "column",
              alignItems: "center",
              marginTop: "1px",
              width: "100%",
            }}
          >
            <Typography className="typography-font">Awaiting Sale</Typography>
            {props.getFilteredProjects(props.projects.S).map((project, index) => {
              return (
                <OrphanProjectCard
                  key={project.id}
                  project={project}
                  openAll={props.openAll}
                  index={index}
                  setActiveProject={setActiveProject}
                />
              );
            })}
          </Grid>
        </Grid>
        <Droppable droppableId={"Sc"}>
          {(provided) => (
            <Grid
              container
              className="root"
              spacing={2}
              ref={provided.innerRef}
              {...provided.droppableProps}
              style={{
                backgroundColor: "",
                display: "flex",
                flexFlow: "column",
                alignItems: "center",
              }}
            >
              <Typography className="typography-font">Scripting</Typography>
              {props.getFilteredProjects(props.projects.Sc).map((project, index) => {
                return (
                  <ProjectCard
                    resortProjects={props.resortProjects}
                    sortProjectsByDelivery={props.sortProjectsByDelivery}
                    resetProjectLoading={props.resetProjectLoading}
                    resetOutputsLoading={resetOutputsLoading}
                    resetOrphans={resetOrphans}
                    setActiveProject={setActiveProject}
                    activeProject={activeProject}
                    openAll={props.openAll}
                    showExtraInfo={props.showExtraInfo}
                    key={project.id}
                    index={index}
                    project={project}
                    showCardContents={showCardContents}
                    setShowCardContents={setShowCardContents}
                    lockProductionCore={props.lockProductionCore}
                    displayOverdueWarning={props.displayOverdueWarning}
                  />
                );
              })}
              {provided.placeholder}
            </Grid>
          )}
        </Droppable>
        <Droppable droppableId={"Sch"}>
          {(provided) => (
            <Grid
              container
              className="root"
              spacing={2}
              ref={provided.innerRef}
              {...provided.droppableProps}
              style={{
                backgroundColor: "",
                display: "flex",
                flexFlow: "column",
                alignItems: "center",
              }}
            >
              <Typography className="typography-font">Scheduling</Typography>
              {props.getFilteredProjects(props.projects.Sch).map((project, index) => {
                return (
                  <ProjectCard
                    resortProjects={props.resortProjects}
                    sortProjectsByDelivery={props.sortProjectsByDelivery}
                    resetProjectLoading={props.resetProjectLoading}
                    resetOutputsLoading={resetOutputsLoading}
                    resetOrphans={resetOrphans}
                    setActiveProject={setActiveProject}
                    activeProject={activeProject}
                    openAll={props.openAll}
                    showExtraInfo={props.showExtraInfo}
                    key={project.id}
                    index={index}
                    project={project}
                    showCardContents={showCardContents}
                    setShowCardContents={setShowCardContents}
                    lockProductionCore={props.lockProductionCore}
                    displayOverdueWarning={props.displayOverdueWarning}
                  />
                );
              })}
              {provided.placeholder}
            </Grid>
          )}
        </Droppable>
        <Droppable droppableId={"Sh"}>
          {(provided) => (
            <Grid
              container
              className="root"
              spacing={2}
              ref={provided.innerRef}
              {...provided.droppableProps}
              style={{
                backgroundColor: "",
                display: "flex",
                flexFlow: "column",
                alignItems: "center",
              }}
            >
              <Typography className="typography-font">Shooting</Typography>
              {props.getFilteredProjects(props.projects.Sh).map((project, index) => {
                return (
                  <ProjectCard
                    resortProjects={props.resortProjects}
                    sortProjectsByDelivery={props.sortProjectsByDelivery}
                    resetProjectLoading={props.resetProjectLoading}
                    resetOutputsLoading={resetOutputsLoading}
                    resetOrphans={resetOrphans}
                    setActiveProject={setActiveProject}
                    activeProject={activeProject}
                    openAll={props.openAll}
                    showExtraInfo={props.showExtraInfo}
                    key={project.id}
                    index={index}
                    project={project}
                    showCardContents={showCardContents}
                    setShowCardContents={setShowCardContents}
                    lockProductionCore={props.lockProductionCore}
                    displayOverdueWarning={props.displayOverdueWarning}
                  />
                );
              })}
              {provided.placeholder}
            </Grid>
          )}
        </Droppable>
        <Droppable droppableId={"E"}>
          {(provided) => (
            <Grid
              container
              className="root"
              spacing={2}
              ref={provided.innerRef}
              {...provided.droppableProps}
              style={{
                backgroundColor: "",
                display: "flex",
                flexFlow: "column",
                alignItems: "center",
              }}
            >
              <Typography className="typography-font">Editing</Typography>
              {props.getFilteredProjects(props.projects.E).map((project, index) => {
                return (
                  <ProjectCard
                    resortProjects={props.resortProjects}
                    sortProjectsByDelivery={props.sortProjectsByDelivery}
                    resetProjectLoading={props.resetProjectLoading}
                    resetOutputsLoading={resetOutputsLoading}
                    resetOrphans={resetOrphans}
                    setActiveProject={setActiveProject}
                    activeProject={activeProject}
                    openAll={props.openAll}
                    showExtraInfo={props.showExtraInfo}
                    key={project.id}
                    project={project}
                    index={index}
                    showCardContents={showCardContents}
                    setShowCardContents={setShowCardContents}
                    lockProductionCore={props.lockProductionCore}
                    displayOverdueWarning={props.displayOverdueWarning}
                  />
                );
              })}
              {provided.placeholder}
            </Grid>
          )}
        </Droppable>
        <Droppable droppableId={"C"}>
          {(provided) => (
            <Grid
              container
              className="root"
              spacing={2}
              ref={provided.innerRef}
              {...provided.droppableProps}
              style={{
                backgroundColor: "",
                display: "flex",
                flexFlow: "column",
                alignItems: "center",
              }}
            >
              <Typography className="typography-font">Closing</Typography>
              {props.getFilteredProjects(props.projects.C).map((project, index) => {
                return (
                  <ProjectCard
                    resortProjects={props.resortProjects}
                    sortProjectsByDelivery={props.sortProjectsByDelivery}
                    resetProjectLoading={props.resetProjectLoading}
                    resetOutputsLoading={resetOutputsLoading}
                    resetOrphans={resetOrphans}
                    setActiveProject={setActiveProject}
                    activeProject={activeProject}
                    openAll={props.openAll}
                    showExtraInfo={props.showExtraInfo}
                    key={project.id}
                    openArray={props.openArray}
                    project={project}
                    showCardContents={showCardContents}
                    index={index}
                    setShowCardContents={setShowCardContents}
                    lockProductionCore={props.lockProductionCore}
                    displayOverdueWarning={props.displayOverdueWarning}
                  />
                );
              })}
              {provided.placeholder}
            </Grid>
          )}
        </Droppable>
      </div>
    </DragDropContext>
  );
};

export default DnDBoard;
