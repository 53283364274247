export default function negDaysLeft(deadline, type) {
  // This returns calendar days, not working days
  var d0 = new Date();
  //   var startDate = parseDate(d0);
  var startDate = d0;
  var endDate = deadline;
  // Calculate days between dates
  var millisecondsPerDay = 86400 * 1000; // Day in milliseconds
  startDate.setHours(0, 0, 0, 1); // Start just after midnight
  endDate.setHours(23, 59, 59, 999); // End just before midnight
  var diff = endDate - startDate; // Milliseconds between datetime objects
  var days = Math.ceil(diff / millisecondsPerDay);

  if (days && type === "A") {
    return Math.abs(days);
  } else if (days && type === "D") {
    return days * -1 >= 0 ? "+" + days * -1 : days * -1;
  } else {
    return "+1";
  }
}
