import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Axios from "axios";
import {
  Grid,
  TextField,
  Button,
  Snackbar,
} from "@material-ui/core";
import "./Admin.css";
import MuiAlert from "@material-ui/lab/Alert";
import Loader from "../Loader";


const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));

export default function ExpressPayment() {
  const classes = useStyles();
  const [adminMenu, setAdminMenu] = useState("");
  const [values, setValues] = useState();
  const [location, setLocation] = useState();
  const [contractor, setContractor] = useState();
  const [talent, setTalent] = useState();
  const [successCreate, setSuccessCreate] = useState(false);
  const [failedCreate, setFailedCreate] = useState(false);

  const handleChange = (event, text) => {
    var newValue = event.target.value;
    switch (text) {
      case "Location:":
        setLocation(newValue.trim());
        break;
      case "Contract:":
        setContractor(newValue.trim());
        break;
      case "Talent:":
        setTalent(newValue.trim());
        break;
      default:
        break;
    }
  };

  const closeSuccessCreateSnackbar = () => {
    setSuccessCreate(false);
  };

  const closeFailedCreateSnackbar = () => {
    setFailedCreate(false);
  };

  var snackbarSuccess = (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "right"
      }}
      open={successCreate}
      autoHideDuration={2000}
      onClose={closeSuccessCreateSnackbar}
    >
      <MuiAlert severity="success">Successfully Saved Changes!</MuiAlert>
    </Snackbar>
  );

  var snackbarFailed = (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "right"
      }}
      open={failedCreate}
      autoHideDuration={2000}
      onClose={closeFailedCreateSnackbar}
    >
      <MuiAlert severity="error">Failed to Save Changes!</MuiAlert>
    </Snackbar>
  );

  const saveChanges = () => {
    Axios.post(`ExpressPayments/PostExpressPayment`, {
      locationThreshold: location,
      contractorThreshold: contractor,
      talentThreshold: talent,
      dateStamp: new Date()
    })
      .then((res) => {
        console.log(res);
        setSuccessCreate(true);

      })
      .catch((e) => {
        console.log(e);
        setFailedCreate(true);
      });
  }

  const getField = (text, value) => (
    <div className="textfield-padding">
      <TextField
        id={text}
        label="Outlined"
        variant="outlined"
        label={text}
        value={value.toString()}
        onChange={(event) => handleChange(event, text)}
        fullWidth
      />
    </div>
  );

  useEffect(() => {
    Axios.get(`ExpressPayments/GetLastExpressPayment`)
      .then((res) => {
        console.log(res.data);
        console.log(typeof res.data.locationThreshold.toString());
        var response = res.data;
        setLocation(response.locationThreshold);
        setContractor(response.contractorThreshold);
        setTalent(response.talentThreshold);
        setValues(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  if (values) {
    return (
      <main className={classes.content}>
        <div className="main-content" />
        <Typography variant="h4">Express Payment</Typography>
        <Grid container className="table-grid">
          <Grid item xs={12} md={4} lg={3}>
            {getField("Location:", location)}
            {getField("Contract:", contractor)}
            {getField("Talent:", talent)}
            <div className="button-div">
              <Button
                color="primary"
                variant="contained"
                onClick={saveChanges}
              >
                Save
            </Button>
            </div>
          </Grid>
        </Grid>
        {snackbarSuccess}
        {snackbarFailed}
      </main>
    );
  } else {
    return <Loader />;
  }
}
