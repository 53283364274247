import React, { useEffect, useState } from "react";
import {

} from "@material-ui/icons";
import {
  TextField,
  Button,
  makeStyles,
  Fab,
  Grid,
  Typography,
  Checkbox,
  FormControlLabel
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { withStyles } from "@material-ui/core/styles";
import AddNewContact from "./AddNewContact";
import Axios from "axios";

export const AddLocationContact = props => {
  const [allPeople, setAllPeople] = useState([]);
  const [contact, setContact] = useState(props.contactFromStepper);
  const [peopleFetched, setPeopleFetched] = useState(false);
  const [addMultipleContacts, setAddMultipleContacts] = useState(true);
  const [type, setType] = useState(
    props.typesFromStepper != null ? props.typesFromStepper : [0, 0, 0]
  );
  const [focusType, setFocusType] = useState("everything");
  const [contactFocus, setContactFocus] = useState({ fullName: "" });
  const [addNewContact, setAddNewContact] = useState(false);
  const [checkboxClicked, setCheckboxClicked] = useState(false);

  const StyledButton = withStyles(theme => ({
    root: {
      background: "#00b2e2",
      color: "white",
      marginBottom: "1%",
      marginTop: "1%",
      marginLeft: "1%",
      marginRight: "1%",
      "&:hover": {
        background: "#008cb3"
      }
    }
  }))(Button);

  const StyledButtonDelete = withStyles((theme) => ({
    root: {
      color: "#000000",
      borderRadius: "20%",
    },
  }))(Button);

  useEffect(() => {
    if (!peopleFetched) {
      Axios.get("/People/GetLocationContacts")
        .then(response => {
          console.log("GetLocationContacts");
          console.log(response.data);
          setPeopleFetched(true);
          setAllPeople(response.data);
        })
        .catch(error => {
          console.log(error);
        });
    }
  });

  useEffect(() => {
    console.log("contact changed")
    setPeopleFetched(false);
  }, [contact]);

  useEffect(() => {
    props.passContactToStepper(contact);
    // setCheckboxClicked(contact === null)
  }, [contact]);

  const handleRemoveContact = () => {
    setContact();
  };

  const toggleAddNewContact = () => {
    if (addNewContact == false) {
      setAddNewContact(true);
    } else if (addNewContact == true) {
      setAddNewContact(false);
    }
  };

  const addContactToList = contact => {
    console.log("contact");
    console.log(contact);
    setContact(contact);
    //setContactFocus({ fullName: "" });
    setContactFocus(contact);
  };

  const toggleAddMultipleContacts = () => {
    addContactToList();
  };

  const setNewContact = newContact => {
    addContactToList(newContact);
    setAddMultipleContacts(true);
    setAddNewContact(false);
  };

  const handleTypeChange = event => {
    if (event.target.value == "everything") {
      let accountsId = type[2];
      let accessId = type[1];
      setType([contactFocus.id, accessId, accountsId]);
      setFocusType(event.target.value);
    } else if (event.target.value == "access") {
      let accountsId = type[2];
      let everythingId = type[0];
      setType([everythingId, contactFocus.id, accountsId]);
      setFocusType(event.target.value);
    } else if (event.target.value == "accounts") {
      let accessId = type[1];
      let everythingId = type[0];
      setFocusType(event.target.value);
      setType([everythingId, accessId, contactFocus.id]);
    }
  };

  const closeAddNewContact = () => {
    setAddNewContact(false);
  };

  return (
    <div>
      {addNewContact ? (
        <AddNewContact
          setNewContact={setNewContact}
          closeAddNewContact={closeAddNewContact}
        />
      ) : (
          <div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="no-location-contact-checkbox"
                    defaultChecked={false}
                    onClick={(event) => {
                      if (event.target.checked) {
                        setContact(null);
                        setCheckboxClicked(true);
                      }
                      else {
                        setCheckboxClicked(false);
                      }
                    }}
                  />
                }
                label="Location is a public place"
              />
            </div>
            <div style={checkboxClicked ? { display: "none" } : { display: "flex", justifyContent: "center" }}>
              <Autocomplete
                id="add-location-contact-autocomplete"
                options={allPeople}
                disableClearable
                //value={contactFocus}
                value={props.contactFromStepper}
                getOptionLabel={option => `${option.firstName} ${option.lastName}`}
                style={{ width: "30%", marginRight: "15px" }}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Select an existing location contact"
                    variant="outlined"
                    fullWidth
                  />
                )}
                // onChange={(e, value) => focusContact(e, value)}
                onChange={(e, value) => value !== null ? addContactToList(value) : ""}
              />
              <div
                style={{
                  display: "flex",
                  marginTop: "auto",
                  marginBottom: "auto"
                }}
              >
                <StyledButton
                  style={{ width: "210px" }}
                  id="add-new-contact-button"
                  aria-label="create a new contact"
                  onClick={() => toggleAddNewContact()}
                >
                  Create New Contact
                </StyledButton>
                {/* <StyledButton
                  size="small"
                  disabled={props.contactFromStepper === null}
                  onClick={() => setContact(null)}
                >
                  Location has no contact
                </StyledButton> */}
              </div>
            </div>
            <div style={{ marginTop: '45px' }}>
              {contact ? (
                <Grid
                  container
                  direction="column"
                  alignItems="center"
                  justify="center"
                >
                  <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    style={{ marginBottom: "10px", minWidth: "40%" }}
                  >
                    <Typography variant={"h4"} >Location Contact</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    style={{ marginBottom: "10px", minWidth: "40%" }}
                  >
                    <TextField
                      label="First Name"
                      multiline={true}
                      variant="outlined"
                      style={{ width: "100%" }}
                      value={contact.firstName}
                      disabled={true}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    style={{ marginBottom: "10px", minWidth: "40%" }}
                  >
                    <TextField
                      label="Last Name"
                      multiline={true}
                      variant="outlined"
                      style={{ width: "100%" }}
                      value={contact.lastName}
                      disabled={true}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    style={{ marginBottom: "10px", minWidth: "40%" }}
                  >
                    <TextField
                      label="Email Address"
                      multiline={true}
                      variant="outlined"
                      style={{ width: "100%" }}
                      value={contact.emailAddress}
                      disabled={true}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    style={{ marginBottom: "10px", minWidth: "40%" }}
                  >
                    <TextField
                      label="Phone Number"
                      multiline={true}
                      variant="outlined"
                      style={{ width: "100%" }}
                      value={contact.phoneNumber}
                      disabled={true}
                    />
                  </Grid>
                  {/* <Grid
                           item
                           xs={12}
                           md={12}
                           lg={12}
                           style={{ minWidth: "40%" }}
                         >
                           <StyledButtonDelete style={{ float: "right" }}>
                             <Delete
                               onClick={() => handleRemoveContact(contact)}
                               style={{ color: "rgb(255, 77, 77)" }}
                             />
                           </StyledButtonDelete>
                         </Grid> */}
                </Grid>
              )
                :
                <div>
                  {
                    checkboxClicked ?
                      <Typography variant={"h2"}>Location will have no contact</Typography>
                      :
                      <Typography variant={"h2"}>Select or Create a contact</Typography>
                  }
                </div>
              }
            </div>
          </div>
        )}
    </div>
  );
};
