import React, { useEffect, useState } from "react";
import Header from "../../Header";
import "./reconciliationCentre.scss";
import {
  Card,
  CardContent,
  Container,
  Divider,
  Typography,
} from "@material-ui/core";
import Snacky from "../../Shared/Snacky";
import moment from "moment";
import axios from "axios";
import { Skeleton } from "@material-ui/lab";
import PayablesList from "./PayablesList";
import ReconciliationContainer from "./ReconRecords/ReconciliationContainer";
import ProcessingSpinnerDialog from "../../Shared/Spinner/ProcessingSpinnerDialog";
import PayableFigures from "./PayableFigures";

const initialFilter = {
  dateFrom: moment().add(-6, "months").startOf("month").format("YYYY-MM-DD"),
  dateTo: moment().endOf("month").format("YYYY-MM-DD"),
  company: null,
  supplier: null,
  keyword: null,
  billStatus: ["Pending","Submitted"],
};
const ReconciliationCentre = () => {
  const [filter, setFilter] = useState(initialFilter);
  const [isLoadingPayables, setIsLoadingPayables] = useState(false);
  const [payables, setPayables] = useState([]);
  const [selectedPayable, setSelectedPayable] = useState(null);
  const [companies, setCompanies] = useState([]);
  const [staff, setStaff] = useState([]);
  const [projects, setProjects] = useState([]);
  const [mediaSuppliers, setMediaSuppliers] = useState([]);
  const [snackBarProps, setSnackBarProps] = useState({
    open: false,
    severity: "success",
    text: "",
  });
  const [isSearchingRelatedRecords, setIsSearchingRelatedRecords] =
    useState(false);
  const [reconLines, setReconLines] = useState([]);
  const [selectedReconLines, setSelectedReconLines] = useState([]);
  const [isClosingPayable, setIsClosingPayable] = useState(false);

  console.log("selectedReconLines", selectedReconLines)

  useEffect(() => {
    getMediaSuppliers();
    searchPayables();
    getCompanies();
    getStaff();
    getProjects();
  }, []);

  const getStaff = (search) => {
    if (search == null || search.trim() == "") {
      search = null;
    }
    axios
      .get(
        `/people/GetPeopleForReconiliation${
          search != null ? `?search=${search}` : ""
        }`
      )
      .then(({ data }) => {
        setStaff(data);
      })
      .catch((err) => console.log(err));
  };

  const getMediaSuppliers = () => {
    axios
      .get(`/mediasuppliers/GetMediaSuppliers`)
      .then(({ data }) => {
        setMediaSuppliers(data);
      })
      .catch((err) => console.log(err));
  };

  const getCompanies = () => {
    axios
      .get(`companies/GetCompaniesInvoiceSettings`, {
        params: {
          isSalesClient: 1,
        },
      })
      .then(({ data }) => {
        setCompanies(data);
      })
      .catch((err) => console.log(err));
  };

  const getProjects = () => {
    axios
      .get(`projects/GetProjectsForReconciliation`)
      .then(({ data }) => {
        setProjects(data);
      })
      .catch((err) => console.log(err));
  };

  const searchPayables = () => {
    setIsLoadingPayables(true);
    axios
      .post(
        `Reconciliation/SearchPayablesForReconciliation`,
        filter.billStatus,
        {
          params: {
            dateFrom: filter.dateFrom,
            dateTo: filter.dateTo,
            companyId: filter.companyId,
            supplierId: filter.supplierId,
            keyword: filter.keyword,
          },
        }
      )
      .then(({ data }) => {
        setPayables(data);
      })
      .catch((err) => {
        console.log(err);
        setSnackBarProps({
          open: true,
          severity: "warning",
          text: "Sorry, something went wrong.",
        });
      })
      .finally(() => {
        setIsLoadingPayables(false);
      });
  };

  const searchRelatedMediaSpend = (payable) => {
    setIsSearchingRelatedRecords(true);
    axios
      .get(`Reconciliation/SearchMediaSpendUndersAndOvers`, {
        params: {
          companyId: payable.companyId,
          supplierId: payable.mediaSupplierId,
          date: payable.date,
        },
      })
      .then(({ data }) => {
        setReconLines(data);
      })
      .catch((e) => {
        console.log(e);
        setSnackBarProps({
          open: true,
          severity: "warning",
          text: "Unable to fetch records.",
        });
      })
      .finally(() => setIsSearchingRelatedRecords(false));
  };

  const searchRelatedPurchaseOrder = (payable) => {
    if (payable.companyId) {
      setIsSearchingRelatedRecords(true);
      axios
        .get(`Reconciliation/SearchMatchingPurchaseOrder`, {
          params: {
            companyId: payable.companyId,
            date: payable.date,
          },
        })
        .then(({ data }) => {
          setReconLines(data);
        })
        .catch((e) => {
          console.log(e);
          setSnackBarProps({
            open: true,
            severity: "warning",
            text: "Unable to fetch records.",
          });
        })
        .finally(() => setIsSearchingRelatedRecords(false));
    } else {
      setReconLines([]);
    }
  };

  const resetRelatedRecords = () => {
    setReconLines([]);
    setSelectedReconLines([]);
  };

  const handleUpdatePayablesList = (localPayable) => {
    setPayables(
      payables.map((p) =>
        p.recordId === localPayable.recordId ? localPayable : p
      )
    );
    if (localPayable.recordType === "PO")
      searchRelatedPurchaseOrder(localPayable);
    if (localPayable.recordType === "MEDIA")
      searchRelatedMediaSpend(localPayable);
    //
  };

  const saveMediaReconciliationLines = (payable) => {
    setIsClosingPayable(true);
    setIsLoadingPayables(true);
    axios
      .post(
        `Reconciliation/SaveMediaReconciliationLines/${payable.recordId}`,
        selectedReconLines
      )
      .then(({ data }) => {
        searchRelatedMediaSpend(payable)
        setSnackBarProps({
          open: true,
          severity: "success",
          text: "Fantastic! The Records have been saved.",
        });
      })
      .catch((err) => {
        console.log(err);
        setSnackBarProps({
          open: true,
          severity: "warning",
          text: "Unable to save records.",
        });
      })
      .finally(() => {
        setIsClosingPayable(false);
        setIsLoadingPayables(false);
      });
  };

  const matchAndCloseMediaBill = (payable) => {
    setIsClosingPayable(true);
    setIsLoadingPayables(true);
    axios
      .post(
        `Reconciliation/MatchAndCloseMediaBill/${payable.recordId}`,
        selectedReconLines
      )
      .then(({ data }) => {
        resetRelatedRecords();
        removePayablefromList(data);
        setSelectedPayable(null);
        setSnackBarProps({
          open: true,
          severity: "success",
          text: "Fantastic! This bill is closed.",
        });
      })
      .catch((err) => {
        console.log(err);
        setSnackBarProps({
          open: true,
          severity: "warning",
          text: "Unable to fetch records.",
        });
      })
      .finally(() => {
        setIsClosingPayable(false);
        setIsLoadingPayables(false);
      });
  };

  const matchAndCloseReceipt = (payable) => {
    setIsClosingPayable(true);
    setIsLoadingPayables(true);

    axios
      .post(
        `Reconciliation/MatchAndCloseReceipt/${payable.recordId}`,
        selectedReconLines
      )
      .then(({ data }) => {
        resetRelatedRecords();
        removePayablefromList(data);
        setSelectedPayable(null);
        setSnackBarProps({
          open: true,
          severity: "success",
          text: "Fantastic! This bill is closed.",
        });
      })
      .catch((err) => {
        console.log(err);
        setSnackBarProps({
          open: true,
          severity: "warning",
          text: "Unable to fetch records.",
        });
      })
      .finally(() => {
        setIsClosingPayable(false);
        setIsLoadingPayables(false);
      });
  };

  const removePayablefromList = (payable) => {
    setPayables(payables.filter((p) => p.recordId !== payable.recordId));
  };
  const addNewPayableToList = (payable) => {
    setPayables([...payables, payable]);
  };

  const matchAndClosePo = (payable) => {
    setIsClosingPayable(true);
    setIsLoadingPayables(true);
    axios
      .post(
        `Reconciliation/MatchAndClosePurchaseOrder/${payable.recordId}`,
        selectedReconLines
      )
      .then(({ data }) => {
        resetRelatedRecords();
        removePayablefromList(data);
        setSelectedPayable(null);
        setSnackBarProps({
          open: true,
          severity: "success",
          text: "Fantastic! This bill is closed.",
        });
      })
      .catch((err) => {
        console.log(err);
        setSnackBarProps({
          open: true,
          severity: "warning",
          text: "Unable to fetch records.",
        });
      })
      .finally(() => {
        setIsClosingPayable(false);
        setIsLoadingPayables(false);
      });
  };

  const addToReconLines = (reconLine) => {
    setReconLines([reconLine, ...reconLines]);
  };

  return (
    <div>
      <Container maxWidth="xl">
        <Snacky
          snackprops={snackBarProps}
          setSnackBarProps={setSnackBarProps}
        />
        {isClosingPayable && (
          <ProcessingSpinnerDialog loading={isClosingPayable} />
        )}
        <div className="reconciliation-centre-root">
          <div className="reconciliation-centre-main-container">
            <div className="reconciliation-centre-bills-wrapper">
              <div className="reconciliation-centre-bills-container">
                {isLoadingPayables ? (
                  <div className="reconciliation-centre-skeleton-container">
                    <Skeleton height={50} />
                    <Skeleton height={50} />
                    <Skeleton height={50} />
                  </div>
                ) : payables.length === 0 ? (
                  <div className="reconciliation-centre-no-records-container">
                    <Typography>Nothing to reconcile</Typography>
                  </div>
                ) : (
                  <div className="reconciliation-centre-payables-wrapper">
                    <PayableFigures payables={payables} />

                    <PayablesList
                      payables={payables}
                      mediaSuppliers={mediaSuppliers}
                      companies={companies}
                      setSnackBarProps={setSnackBarProps}
                      searchRelatedMediaSpend={searchRelatedMediaSpend}
                      searchRelatedPurchaseOrder={searchRelatedPurchaseOrder}
                      resetRelatedRecords={resetRelatedRecords}
                      setSelectedPayable={setSelectedPayable}
                      handleUpdatePayablesList={handleUpdatePayablesList}
                      selectedReconLines={selectedReconLines}
                      setSelectedReconLines={setSelectedReconLines}
                      removePayablefromList={removePayablefromList}
                      addNewPayableToList={addNewPayableToList}
                      setIsClosingPayable={setIsClosingPayable}
                      setIsLoadingPayables={setIsLoadingPayables}
                      selectedPayable={selectedPayable}
                      staff={staff}
                      projects={projects}
                      resetPayables={searchPayables}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="reconciliation-centre-records-wrapper">
              {isSearchingRelatedRecords ? (
                <div className="reconciliation-centre-skeleton-container">
                  <Skeleton height={50} />
                  <Skeleton height={50} />
                  <Skeleton height={50} />
                </div>
              ) : reconLines.length === 0 &&
                selectedPayable?.recordType === "UNRECOGNISED_MEDIA" ? (
                <div className="reconciliation-centre-no-records-container">
                  <Typography>Nothing to reconcile</Typography>
                </div>
              ) : (
                <ReconciliationContainer
                  reconLines={reconLines}
                  mediaSuppliers={mediaSuppliers}
                  companies={companies}
                  setSnackBarProps={setSnackBarProps}
                  payable={selectedPayable}
                  selectedReconLines={selectedReconLines}
                  setSelectedReconLines={setSelectedReconLines}
                  matchAndCloseMediaBill={matchAndCloseMediaBill}
                  matchAndCloseReceipt={matchAndCloseReceipt}
                  matchAndClosePo={matchAndClosePo}
                  addToReconLines={addToReconLines}
                  saveMediaReconciliationLines={saveMediaReconciliationLines}
                />
              )}
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ReconciliationCentre;
