import React, { useState, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Axios from "axios";
import { Grid, Tooltip, Button, CircularProgress } from "@material-ui/core";
import "./Admin.css";
import Loader from "../Loader";
import Snacky from "../Shared/Snacky";
import { connect } from "react-redux";

import { setSnackBarProps } from "../../actions/snackbar";
import { setProjectFilter } from "../../actions/adminMenu";
import SpamBotTable from "./SpamBotComponents/SpamBotTable";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: 12,
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));

const addCommasToFloat = (float) => {
  /*
    The regex uses 2 lookahead assertions:
      - a positive one to look for any point in the string that has a multiple of 3 digits in a row after it,
      - a negative assertion to make sure that point only has exactly a multiple of 3 digits. The replacement expression puts a comma there.
      - (?<!\.\d*) is a negative lookbehind that says the match can't be preceded by a . followed by zero or more digits.
  */
  return float.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
};

const SpamBot = (props) => {
  let { setSnackBarProps, snackbar, setProjectFilter, projectFilter } = props;
  const classes = useStyles();
  const [spamBot, setSpamBot] = useState();
  const [totalDueOnScreen, setTotalDueOnScreen] = useState(0.0);

  const [initialFilters, setInitialFilters] = useState({
    status: [
      "Calendar Sent",
      "Contract Not Sent",
      "Contract Signed",
      "Invoiced",
      "Order Accepted",
      "null",
      "Order Cancelled",
      "Can't find type"
    ],
    projectName: ["Xero Code Testing - May 2020"],
  });

  const [filterClicked, setFilterClicked] = useState(false);

  const setTotal = (x) => {
    setTotalDueOnScreen(parseFloat(x));
  };

  useEffect(() => {
    Axios.get("SpamBots/GetAllSpamBot")
      .then((res) => {
        var arr = res.data;
        var newAmount = 0.0;
        for (var i = 0; i < arr.length; i++) {
          //Format values correctly
          arr[i].dueDate =
            arr[i].dueDate !== null ? new Date(arr[i].dueDate) : null;
          arr[i].nextSend =
            arr[i].nextSend !== null ? new Date(arr[i].nextSend) : null;
          arr[i].lastSent =
            arr[i].lastSent !== null ? new Date(arr[i].lastSent) : null;
          arr[i].amountDue =
            arr[i].amountDue !== null
              ? parseFloat(arr[i].amountDue).toFixed(2)
              : null;
          //Set up initial Total
          if (arr[i].amountDue !== null) {
            newAmount = newAmount + parseFloat(arr[i].amountDue);
          }
          //
          if (arr[i].status === "Sent") {
            if (arr[i].type === "Catering") {
              arr[i].status = "CateringSent";
            } else {
              arr[i].status = "CalendarSent";
            }
          }
        }
        setSpamBot(arr);
        setTotalDueOnScreen(newAmount);
      })
      .catch((err) => console.log(err));
  }, [filterClicked]);

  useEffect(() => {
    if (projectFilter !== null) {
      setInitialFilters({
        ...initialFilters,
        projectName: projectFilter,
      });
      setProjectFilter(null);
    }
  }, [projectFilter]);

  useEffect(() => {
    setFilterClicked(false);
  }, [initialFilters]);

  if (spamBot) {
    return (
      <main className={classes.content}>
        <div className="main-content" />
        <Grid container>
          {snackbar.open && (
            <Snacky snackprops={snackbar} setSnackBarProps={setSnackBarProps} />
          )}
          <Grid item xs={2}>
            <Typography variant="h4">Central Lookup</Typography>
          </Grid>
          <Grid item xs={3}>
            {!filterClicked ? (
              <React.Fragment>
                <Button
                  onClick={() => {
                    setFilterClicked(true);
                    setInitialFilters({
                      status: [
                        "Calendar Sent",
                        "Contract Not Sent",
                        "Invoiced",
                        "Order Sent",
                        "null",
                        "Contract Pending",
                        "Invoice Pending",
                        "Order Cancelled",
                        "Can't find type"
                      ],
                      projectName: ["Xero Code Testing - May 2020", "null"],
                    });
                  }}
                  variant="contained"
                  color="Primary"
                  className="add-margin-left-right-5"
                >
                  In Progress
                </Button>
                <Button
                  onClick={() => {
                    setFilterClicked(true);
                    setInitialFilters({
                      status: [
                        "Calendar Sent",
                        "Contract Not Sent",
                        "Contract Signed",
                        "Invoiced",
                        "Order Accepted",
                        "null",
                        "Order Cancelled",
                        "Can't find type"
                      ],
                      projectName: ["Xero Code Testing - May 2020"],
                    });
                  }}
                  variant="contained"
                  color="Primary"
                  className="add-margin-left-right-5"
                >
                  Pending
                </Button>
              </React.Fragment>
            ) : (
              <CircularProgress />
            )}
          </Grid>
          {totalDueOnScreen !== null || totalDueOnScreen !== undefined ? (
            <React.Fragment>
              <Grid item xs={7}>
                <p className="totalAmountDue" style={{ fontWeight: "bold" }}>
                  {`Total Due:  $${addCommasToFloat(
                    totalDueOnScreen.toFixed(2)
                  )}`}
                </p>
              </Grid>
            </React.Fragment>
          ) : (
            <React.Fragment></React.Fragment>
          )}
          <Grid item xs={12}>
            {!filterClicked ? (
              <SpamBotTable
                spamList={spamBot}
                // setTotalDueOnScreen={setTotalDueOnScreen}
                setTotalDueOnScreen={(x) => setTotal(x)}
                initialFilters={initialFilters}
                setProjectFilter={setProjectFilter}
                setInitialFilters={setInitialFilters}
                filterClicked={filterClicked}
                setFilterClicked={setFilterClicked}
              />
            ) : (
              <React.Fragment></React.Fragment>
            )}
          </Grid>
        </Grid>
      </main>
    );
  } else {
    return <Loader />;
  }
};

const mapStateToProps = (state, ownProps) => ({
  snackbar: state.snackbar,
  projectFilter: state.adminMenu.projectFilter,
});
// https://react-redux.js.org/api/connect
export default connect(mapStateToProps, {
  setSnackBarProps,
  setProjectFilter,
})(SpamBot);
