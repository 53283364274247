import React, { useEffect, useState, useRef } from "react"
import {
    ArrowDropDown as ArrowDropDownIcon,
    StarBorder as StarBorderIcon
} from "@material-ui/icons";
import {
    Menu,
    MenuItem,
    Checkbox,
    ListItemText
} from "@material-ui/core";
import { Rating } from "@material-ui/lab";

const FilterButtonCustom = ({
    title,
    options,
    filterType,
    selectedOptions,
    setSelectedOptions
}) => {
    const [contextMenu, setContextMenu] = useState(null);
    const [selectAllClicked, setSelectAllClicked] = useState(false)
    const optionRef = useRef(null);

    const handleContextMenu = (event) => {
        event.preventDefault();
        setContextMenu(
            contextMenu === null
                ? {
                    mouseX: event.clientX,// - 2,
                    mouseY: event.clientY,// - 4,
                }
                : null,
        );
    };

    const handleClose = () => {
        setContextMenu(null);
    };

    const getLabel = (value) => {
        if (filterType == "industry") {
            return value.name;
        } else if (filterType == "ageCategory") {
            return value.generation;
        } else {
            return value
        }
    }

    const getMenuItemKey = (option) => {
        return (filterType == "industry" || filterType == "ageCategory") ? `menu-item-${option.id}` : option
    }

    const handleSelectOption = (e, option) => {
        var newSelected = [...selectedOptions];
        var foundOptionIndex = newSelected.indexOf(option);
        if (foundOptionIndex > -1) {
            if (newSelected[foundOptionIndex]?.id) {
                newSelected = newSelected.filter(o => o.id != newSelected[foundOptionIndex].id);
            } else {
                newSelected = newSelected.filter(o => o != newSelected[foundOptionIndex]);
            }
            setSelectAllClicked(false)
        } else {
            newSelected = [...newSelected, option];
        }

        if (newSelected.length == options.length) {
            setSelectAllClicked(true)
        }

        setSelectedOptions(newSelected);
    }

    const checkAllOptions = () => {
        var newSelected = [];
        for (var i = 0; i < options.length; i++) {
            newSelected.push(options[i])
        }
        setSelectedOptions(newSelected);
    }

    const uncheckAllOptions = () => {
        setSelectedOptions([]);
    }

    const getListItem = (option) => {
        if (filterType == "rating") {
            return <Rating
                name="simple-controlled"
                value={option}
                precision={0.5}
                disabled
                emptyIcon={<StarBorderIcon fontSize="inherit" />}
            />
        } else {
            return <ListItemText primary={getLabel(option)} />
        }
    }

    const handleSelectAllClick = (checked) => {
        if (checked) {
            checkAllOptions();
        } else {
            uncheckAllOptions();
        }
    }

    useEffect(() => {
        if (selectedOptions.length == 0 && selectAllClicked) {
            setSelectAllClicked(false);
        }
    }, [selectedOptions])

    return (
        <div>
            <div className="filter-button">
                <div style={{ display: "flex" }} onClick={handleContextMenu}>
                    <span style={{ alignSelf: "center" }}>
                        {selectedOptions.length > 0 ? `${title}: ${selectedOptions.length} selected` : `${title}`}
                    </span>
                    <ArrowDropDownIcon />
                </div>
            </div>
            <div
                ref={optionRef}
            >
                <Menu
                    open={contextMenu !== null}
                    onClose={handleClose}
                    anchorReference="anchorPosition"
                    anchorPosition={
                        contextMenu !== null
                            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                            : undefined
                    }
                >
                    <MenuItem
                        key={"select-all"}
                        value={""}
                        onClick={e => {
                            setSelectAllClicked(!selectAllClicked)
                            handleSelectAllClick(!selectAllClicked)
                        }}
                    >
                        <Checkbox checked={selectAllClicked} className="filter-checkbox" />
                        Select All
                    </MenuItem>
                    {options.map((option) => (
                        <MenuItem
                            key={getMenuItemKey(option)}
                            value={option}
                            onClick={e => handleSelectOption(e, option)}
                        >
                            <Checkbox checked={selectedOptions.indexOf(option) > -1} className="filter-checkbox" />
                            {getListItem(option)}
                        </MenuItem>
                    ))}
                </Menu>
            </div>
        </div>
    )
}

export default FilterButtonCustom;