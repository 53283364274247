import React, { useEffect, useState } from "react";
import "./billsDashboard.scss";
import { Container, Divider, Typography } from "@material-ui/core";
import Header from "../../Header";
import axios from "axios";
import ExpensesTable from "./ExpensesTable/ExpensesTable";
import { Skeleton } from "@material-ui/lab";
import Snacky from "../../Shared/Snacky";
import TotalCards from "./TotalCards/TotalCards";
import TransferBudgetButton from "./TransferBudgetButton/TransferBudgetButton";
const BillsDashboard = () => {
  const [expenseBills, setExpenseBills] = useState([]);
  const [budgetItems, setBudgetItems] = useState([]);
  const [grandTotals, setGrandTotals] = useState({
    totalQuoted: 0,
    totalAllocated: 0,
    totalRemaining: 0,
  });
  const [isLoadingBills, setIsLoadingBills] = useState(false);
  const [snackBarProps, setSnackBarProps] = useState({
    open: false,
    severity: "success",
    text: "",
  });

  useEffect(() => {
    searchExpenseBills();
  }, []);

  const searchExpenseBills = () => {
    setIsLoadingBills(true);
    axios
      .get(`billsDashboard/GetExpenseTable`)
      .then(({ data }) => {
        setGrandTotals(calculateTotals(data));
        setExpenseBills(data);
      })
      .catch((err) => {
        setSnackBarProps({
          open: true,
          severity: "warning",
          text: "Unable to search bills.",
        });
      })
      .finally(() => setIsLoadingBills(false));
  };

  const calculateTotals = (data) => {
    return data.reduce(
      (totals, item) => {
        totals.totalQuoted += item.quotedByCompany;
        totals.totalAllocated += item.allocatedByCompany;
        totals.totalRemaining += item.remainingByCompany;
        return totals;
      },
      {
        totalQuoted: 0,
        totalAllocated: 0,
        totalRemaining: 0,
      }
    );
  };

  const handleTransferBudgetCallback = async () => {
    await searchExpenseBills();
    setBudgetItems([]);
  };

  return (
    <div>
      <div className="bills-dashboard-root">
        <Snacky
          snackprops={snackBarProps}
          setSnackBarProps={setSnackBarProps}
        />

        <Container maxWidth="lg">
          <div className="bill-dashboard-expense-table-container">
            <TotalCards />
            <TransferBudgetButton
              budgetItems={budgetItems}
              setBudgetItems={setBudgetItems}
              handleSaveCallback={handleTransferBudgetCallback}
              source={"bill-dashboard"}
            />
            <div>
              <div>
                <Typography variant="h6">Expense Table</Typography>
              </div>
              <Divider />
            </div>
            <div>
              {isLoadingBills ? (
                <>
                  <Skeleton height={100} />
                  <Skeleton height={100} />
                  <Skeleton height={100} />
                </>
              ) : (
                <ExpensesTable
                  expenseBills={expenseBills}
                  setSnackBarProps={setSnackBarProps}
                  handlePreLoadCampaign={() => {}}
                  grandTotals={grandTotals}
                />
              )}
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default BillsDashboard;
