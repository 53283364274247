import React, { useState, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Modal,
  AppBar,
  Toolbar,
  IconButton,
  Button,
  FormControl,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  Tooltip,
  Divider,
  Switch,
  Collapse,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Typography from "@material-ui/core/Typography";
import { Close } from "@material-ui/icons";
import axios from "axios";
import InformationDialogy from "../../../../Shared/InformationDialogy";
import CircularProgress from "@material-ui/core/CircularProgress";
import "./equipmentAndTravelPopup.scss";
import { getScheduleItems } from "../../../../../actions/scheduleItems";
import { connect } from "react-redux";

//---------Styling -----------------
const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    height: "auto",
    width: "600px",
    overflowY: "auto",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  container: {
    maxHeight: 350,
  },
}));

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const EquipmentAndTravelPopup = (props) => {
  const classes = useStyles();
  const {
    equipmentModal,
    closePopup,
    scheduleItem,
    projectId,
    getScheduleItems,
    scheduleId,
    getBudget,
    equipmentCheckbox,
    accounts,
    equipmentFee,
    fee,
    handleEquipmentCheckbox,
    handleFeeChange,
    handleEquipmentFeeChange,
    handleloading,
    travelDistance,
    travelContribution,
    handleSnacky,
    disableFields,
    reloadPrepostScheduleItemsList,
    parent
  } = props;
  const [showInformationDialogy, setShowInformationDialogy] = useState(false);
  const [loading, setloading] = useState(false);
  const [travelContributionFee, settravelContributionFee] = useState({
    account: null,
    fee: null,
  });

  const [equipmentSwitch, setequipmentSwitch] = useState(false);

  useEffect(() => {
    if (accounts) {
      var copyOfAccounts = [...accounts];
      var foundAccount = copyOfAccounts.findIndex(a => a.costAccount === scheduleItem.travelReimbursment?.accountCode);

      if (parent === "prepost") {
        foundAccount = copyOfAccounts.findIndex(a => a.costAccount === scheduleItem.travelReimbursmentFkNavigation?.accountCode);
      }
      console.log("copyOfAccounts[foundAccount]");
      console.log(copyOfAccounts[foundAccount]);
      console.log(parent === "prepost" ?
        (scheduleItem.travelReimbursmentFkNavigation === null ? null : scheduleItem.travelReimbursmentFkNavigation.amount)
        : (scheduleItem.travelReimbursment === null ? null : scheduleItem.travelReimbursment.amount))
      settravelContributionFee({
        ...travelContributionFee,
        ["account"]: foundAccount >= 0 ? copyOfAccounts[foundAccount] : null,
        ["fee"]: parent === "prepost" ?
          (scheduleItem.travelReimbursmentFkNavigation === null ? null : `${scheduleItem.travelReimbursmentFkNavigation.amount}`)
          : (scheduleItem.travelReimbursment === null ? null : `${scheduleItem.travelReimbursment.amount}`)
      })
      if (foundAccount >= 0) {
        setequipmentSwitch(true)
      }
    }
  }, [accounts]);

  const saveEquipmentHire = () => {
    setloading(true);
    console.log(scheduleItem);
    axios
      .post(`/EquipmentsHire/PostEquipmentHire/${scheduleItem.id}`, {
        id: scheduleItem.equipmentHire ? scheduleItem.equipmentHire.id : 0,
        rate: equipmentFee.fee,
        accountName: equipmentFee.account.category,
        accountCode: equipmentFee.account.costAccount,
        equipmentOnly: equipmentCheckbox ? 1 : 0,
        scheduleItemFee: fee.fee,
        scheduleItemAccountName: fee.account.category,
        travelContributionFee: travelContributionFee.fee == null ? null : travelContributionFee.fee,
        travelContributionAccount: travelContributionFee.account !== null ? travelContributionFee.account.costAccount : null,
        travelContributionAccountName: travelContributionFee.account !== null ? travelContributionFee.account.category : null,
      })
      .then((res) => {
        getScheduleItems(scheduleId);
        getBudget(projectId);
        setequipmentSwitch(false);
        closePopup();
        setloading(false);
        handleSnacky(true, "Success");
        if (parent === "prepost") {
          reloadPrepostScheduleItemsList();
        }
      })
      .catch((err) => {
        console.log(err);
        setequipmentSwitch(false);
        setloading(false);
        handleSnacky(true, "Failed");
      });
  };

  const handleTravelContribution = (field, value) => {
    settravelContributionFee({
      ...travelContributionFee,
      [field]: value,
    });
  };

  const handleClosePopup = () => {
    setequipmentSwitch(false);
    closePopup();
  };

  const toggleEquipmentSwitch = () => {
    setequipmentSwitch(!equipmentSwitch);
    settravelContributionFee({
      account: null,
      fee: null,
    });
  };

  if (
    (fee.fee || fee.fee == 0) &&
    fee.account &&
    accounts &&
    travelContribution
  ) {
    handleloading(false);
    return (
      <Modal
        aria-labelledby="equipment-modal"
        aria-describedby="equipment-modal"
        open={equipmentModal}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        className={"equipment-popup-modal"}
      >
        <div>
          <InformationDialogy
            buttonProps={{
              show: showInformationDialogy,
              titleText: "Missing fields!",
              contentText:
                "Please complete all fields before submiting pre-imbursement",
              buttonColor: "primary",
              buttonText: "Ok",
            }}
            handleButton={() => setShowInformationDialogy(false)}
          />
          <AppBar position="static" style={{ background: "#217F8B" }}>
            <Toolbar variant="dense" style={{ paddingRight: 0 }}>
              <Typography
                variant="subtitle1"
                align="center"
                style={{ flexGrow: 1 }}
              >
                {`Equipment Fee for ${scheduleItem.personFkNavigation.firstName} ${scheduleItem.personFkNavigation.lastName}`}
              </Typography>
              <span style={{ display: "flex" }}>
                <IconButton
                  onClick={handleClosePopup}
                  color="inherit"
                  style={{ color: "black" }}
                >
                  <Close />
                </IconButton>
              </span>
            </Toolbar>
          </AppBar>
          <div className={classes.paper}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: "5px",
                width: "100%",
              }}
            >
              <FormControl
                component="fieldset"
                className={classes.formControl}
                style={{
                  width: "100%",
                }}
              >
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography
                      variant="h5"
                      className="equipment-fee-section-title"
                    >
                      Fee
                    </Typography>
                  </Grid>
                  {/* Fee Section */}
                  <Grid item xs={9}>
                    <HtmlTooltip
                      title={
                        <Typography color="inherit">
                          Change this if you want to pay for the fee out of
                          another account
                        </Typography>
                      }
                      interactive
                      arrow
                      placement="left"
                    >
                      <Autocomplete
                        id="fee-accounts"
                        options={accounts}
                        selectOnFocus
                        fullWidth={true}
                        autoHighlight={true}
                        autoSelect={true}
                        disableClearable={true}
                        disabled={equipmentCheckbox || disableFields}
                        onChange={(event, newValue) => {
                          handleFeeChange("acount", newValue);
                        }}
                        size="small"
                        getOptionLabel={(option) => `${option.category}`}
                        value={fee.account}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Budget account"
                            variant="outlined"
                          />
                        )}
                      />
                    </HtmlTooltip>
                  </Grid>
                  <Grid item xs={3}>
                    <HtmlTooltip
                      title={
                        <Typography color="inherit">
                          Rate for fee only
                        </Typography>
                      }
                      interactive
                      arrow
                      placement="left"
                    >
                      <TextField
                        fullWidth
                        label="Fee"
                        size="small"
                        variant="outlined"
                        value={fee.fee}
                        onChange={(e) => handleFeeChange("fee", e.target.value)}
                        disabled={equipmentCheckbox || disableFields}
                      ></TextField>
                    </HtmlTooltip>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={7}>
                    <Typography
                      variant="h5"
                      className="equipment-fee-section-title"
                    >
                      Equipment
                    </Typography>
                  </Grid>
                  <Grid item xs={5} style={{ textAlignLast: "end" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={equipmentCheckbox}
                          onChange={handleEquipmentCheckbox}
                          name="equipment-cb"
                          color="primary"
                          disabled={disableFields}
                        />
                      }
                      label="Equipment only"
                      style={{
                        backgroundColor: "#eee",
                        padding: "0px 10px 0px 0px",
                        margin: "0px",
                      }}
                    />
                  </Grid>
                  <Grid item xs={9}>
                    <HtmlTooltip
                      title={
                        <Typography color="inherit">
                          Change this if you want to pay for the gear out of
                          another account
                        </Typography>
                      }
                      interactive
                      arrow
                      placement="left"
                    >
                      <Autocomplete
                        id="charge-accounts"
                        options={accounts}
                        selectOnFocus
                        fullWidth={true}
                        autoHighlight={true}
                        autoSelect={true}
                        disableClearable={true}
                        disabled={disableFields}
                        onChange={(event, newValue) => {
                          handleEquipmentFeeChange("account", newValue);
                        }}
                        size="small"
                        getOptionLabel={(option) => `${option.category}`}
                        value={equipmentFee.account}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Budget account"
                            variant="outlined"
                          />
                        )}
                      />
                    </HtmlTooltip>
                  </Grid>
                  <Grid item xs={3}>
                    <HtmlTooltip
                      title={
                        <Typography color="inherit">
                          Rate for equipment only | Independent contractors do
                          not pay tax on this
                        </Typography>
                      }
                      interactive
                      arrow
                      placement="left"
                    >
                      <TextField
                        fullWidth
                        label="Gear Fee"
                        size="small"
                        variant="outlined"
                        disabled={disableFields}
                        onChange={(e) => {
                          console.log("handleEquipmentFeeChange");
                          handleEquipmentFeeChange("fee", e.target.value)
                        }}
                        value={equipmentFee.fee}
                      ></TextField>
                    </HtmlTooltip>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={9}>
                    <div style={{ textAlign: "right" }}>
                      <Typography variant="h6">Total Rate</Typography>
                    </div>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      label="Total rate"
                      size="small"
                      variant="outlined"
                      value={`$${(
                        Number(fee.fee) + Number(equipmentFee.fee)
                      ).toString()}`}
                      disabled={true}
                      inputProps={{
                        style: { fontWeight: "600", color: "black" },
                      }}
                    ></TextField>
                  </Grid>
                  <Grid items xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={5}>
                    <Typography
                      variant="h5"
                      className="equipment-fee-section-title"
                    >
                      Travel Contribution
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Switch
                      checked={equipmentSwitch}
                      onChange={toggleEquipmentSwitch}
                      color="primary"
                      name="equipmentSwitchChecked"
                      inputProps={{ "aria-label": "primary checkbox" }}
                      disabled={disableFields}
                    />
                  </Grid>
                  <Grid item xs={5}></Grid>
                  {equipmentSwitch && (
                    <Collapse in={equipmentSwitch} style={{ padding: "12px" }}>
                      <Grid container spacing={3}>
                        <Grid item xs={9}>
                          <HtmlTooltip
                            title={
                              <Typography color="inherit">
                                Change this if you want to pay for the travel
                                contribution out of another account
                              </Typography>
                            }
                            interactive
                            arrow
                            placement="left"
                          >
                            <Autocomplete
                              id="charge-accounts"
                              options={accounts}
                              selectOnFocus
                              fullWidth={true}
                              autoHighlight={true}
                              autoSelect={true}
                              onChange={(event, newValue) => {
                                handleTravelContribution("account", newValue);
                              }}
                              size="small"
                              getOptionLabel={(option) => `${option.category}`}
                              value={travelContributionFee.account}
                              disabled={
                                Number(travelDistance) <
                                travelContribution.travelDistance || disableFields
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Budget account"
                                  variant="outlined"
                                />
                              )}
                            />
                          </HtmlTooltip>
                        </Grid>
                        <Grid item xs={3}>
                          <HtmlTooltip
                            title={
                              <Typography color="inherit">
                                Enter the travel contribution here
                              </Typography>
                            }
                            interactive
                            arrow
                            placement="left"
                          >
                            <TextField
                              fullWidth
                              label="Contribution"
                              size="small"
                              variant="outlined"
                              onChange={(e) =>
                                handleTravelContribution("fee", e.target.value)
                              }
                              value={travelContributionFee.fee}
                              disabled={
                                Number(travelDistance) <
                                travelContribution.travelDistance || disableFields
                              }
                            ></TextField>
                          </HtmlTooltip>
                        </Grid>
                        {Number(travelDistance) >= travelContribution.travelDistance && parent !== "prepost" && (
                          <Grid item xs={12}>
                            <div style={{ textAlign: "right" }}>
                              <Typography color="secondary">
                                The recommended contribution is $
                                {(
                                  Number(travelDistance) * travelContribution.rate
                                ).toFixed(2)}
                              </Typography>
                            </div>
                          </Grid>
                        )}
                        {Number(travelDistance) < travelContribution.travelDistance && parent !== "prepost" && (
                          <Grid item xs={12}>
                            <Typography
                              color="secondary"
                              align="right"
                              style={{ textAlign: "right" }}
                            >
                              {`* This distance is below Magnesiums travel reimbursement
                      distance. Only distances greater than ${travelContribution.travelDistance}km should be
                      contributed to.`}
                            </Typography>
                          </Grid>
                        )}
                        {parent === "prepost" && (
                          <Grid item xs={12}>
                            {/* spacing for contribution prepost */}
                            <div style={{ textAlign: "right" }}>
                              <Typography color="secondary">
                                {travelContributionFee.fee == null || travelContributionFee.fee == "" || travelContributionFee.account == null ?
                                  `Please enter the account and contribution above` : ``}
                              </Typography>
                            </div>
                          </Grid>
                        )}
                      </Grid>
                    </Collapse>
                  )}
                  <Grid item xs={12} className="equipment-form-button">
                    {!loading ? (
                      <Button
                        variant="contained"
                        size="medium"
                        color="primary"
                        onClick={saveEquipmentHire}
                      >
                        Save
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        size="medium"
                        color="primary"
                        disabled={true}
                      >
                        Save
                        <span>
                          <CircularProgress color="primary" size={25} />
                        </span>
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </FormControl>
            </div>
          </div>
        </div>
      </Modal>
    );
  } else {
    return <div></div>;
  }
};

const mapStateToProps = (state, ownProps) => ({});
export default connect(mapStateToProps, {
  getScheduleItems,
})(EquipmentAndTravelPopup);
