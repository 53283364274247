import { Collapse, IconButton, TableCell, TableRow } from "@material-ui/core";
import React, { Fragment, useState } from "react";
import { currencyFormat } from "../../../../utils/GeneralUtils";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import CustomTableRowBudgetItem from "./CustomTableRowBudgetItem";
import ScheduleItemContractStatusBadge from "../../../Shared/Badge/ScheduleItemContractStatusBadge";

const CustomTableRowScheduleItem = ({ scheduleItem, setSnackBarProps }) => {
  return (
    <Fragment>
      <TableRow>
        <TableCell className="client-budget-table-icon-column"></TableCell>
        <TableCell className="expense-table-schedule-item-column">
          <div className="expense-table-schedule-item-column-wrapper">
            <strong>{scheduleItem.providerName}</strong>
            <ScheduleItemContractStatusBadge
              status={scheduleItem.contractStatus}
            />
          </div>
        </TableCell>
        <TableCell>{scheduleItem.personCategory}</TableCell>
        <TableCell>
          {scheduleItem.talentInvoiceDriveId ? (
            <a
              href={`https://docs.google.com/document/d/${scheduleItem.talentInvoiceDriveId}/preview`}
              target="_blank"
            >
              {currencyFormat(Number(scheduleItem.allocatedRates))}
            </a>
          ) : (
            currencyFormat(Number(scheduleItem.allocatedRates))
          )}
        </TableCell>
        <TableCell>-</TableCell>
      </TableRow>
    </Fragment>
  );
};

export default CustomTableRowScheduleItem;
