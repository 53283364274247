import React, { useEffect, useState } from "react";
import TableFilter from "react-table-filter";
import { format } from "date-fns";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import {
    Table,
    TableBody,
    TableCell,
    TableRow,
    Tooltip,
    Button,
} from "@material-ui/core";
import {
    withStyles,
    makeStyles,
    useTheme,
    styled,
} from "@material-ui/core/styles";
import Dialogy from "../../Shared/Dialogy";
import { setDialogProps } from "../../../actions/dialog";
import "./Deposit.css";

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
        fontWeight: "bold",
    },
    body: {
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
    },
    root: {
        padding: "10px 10px 10px 10px",
        maxHeight: "50px",
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        "&:nth-of-type(odd)": {
            backgroundColor: "#efefef",
        },
    },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    table: {
        minWidth: 750,
    },
}));

const headCells = [
    {
        id: "client",
        align: "center",
        label: "Client",
    },
    {
        id: "projectName",
        align: "center",
        label: "Project",
    },
    {
        id: "dateSigned",
        align: "center",
        label: "Date Signed",
    },
    {
        id: "dueDate",
        align: "center",
        label: "Due Date",
    },
    {
        id: "accountsContact",
        align: "center",
        label: "Accounts Contact",
    },
    {
        id: "firstShoot",
        align: "center",
        label: "First Shoot",
    },
    {
        id: "daysBeforeShoot",
        align: "center",
        label: "Days Before First Shoot",
    },
    {
        id: "dismissed",
        align: "center",
        label: "",
    },
];


const DepositTable = (props) => {
    let history = useHistory();
    var classes = useStyles();

    const [depositList, setDepositList] = useState(props.allDeposits);

    const [initialFilters, setInitialFilters] = useState(props.initialFilters);

    const [redirectToProject, setRedirectToProject] = useState(false);
    const [selectedDepositItem, setSelectedDepositItem] = useState(null);
    const [showWarning, setShowWarning] = useState(false);

    const getHeaderClass = (cellId) => {
        switch (cellId) {
            default:
                return "deposit-table-header-cell";
        }
    };

    const handleHeadCell = (cell) => {
        if (cell.id === "dateSigned" ||
            cell.id === "dueDate" ||
            cell.id === "firstShoot") {
            return (
                <th
                    filterkey={cell.id}
                    className={getHeaderClass(cell.id)}
                    itemDisplayValueFunc={(itemValue) => {
                        if (itemValue !== null) {
                            return itemValue.toDateString();
                        }
                    }}
                    itemSortValueFunc={(itemValue) => {
                        if (itemValue !== "(blank)") {
                            return itemValue.toDateString();
                        }
                    }}
                    align={cell.align}
                >
                    {cell.label}
                </th>
            );
        }
        else {
            return (
                <th
                    filterkey={cell.id}
                    className={getHeaderClass(cell.id)}
                    align={cell.align}
                >
                    {cell.label}
                </th>
            );
        }
    }

    const handleCloseWarning = () => {
        setShowWarning(false)
        setSelectedDepositItem(null);
    }

    const handleButtonClick = () => {
        var newDepositItem = selectedDepositItem;
        newDepositItem["dismissed"] = selectedDepositItem.dismissed === 1 ? 0 : 1;
        props.dismissDeposit(newDepositItem);
        handleCloseWarning();
    }

    const handleWarningMessage = () => {
        var currentDepositItem = selectedDepositItem;
        if (currentDepositItem.dismissed === 0) {
            return "Note: This will remove all warnings for his deposit in the system";
        }
        else {
            return "Note: This will add all approriate warnings in the system";
        }
    }

    // useEffect(() => {
    //     setDepositList(props.allDeposits);
    //     setInitialFilters(props.initialFilters);
    // }, [props.allDeposits])

    // useEffect(() => {
    //     setInitialFilters(props.initialFilters);
    // }, [props.initialFilters])

    if (redirectToProject) {
        var projectId = selectedDepositItem.projectId;
        history.push(`/Project/Home/${projectId}/0`);
        return <React.Fragment></React.Fragment>;
    }
    else {
        return (
            <React.Fragment>
                <Table
                    id="deposit-table"
                    className={classes.table}
                    size="small"
                >
                    <TableFilter
                        rows={depositList}
                        onFilterUpdate={(updatedData, filterConfiguration) => {
                            console.log(filterConfiguration);
                            setDepositList(updatedData);
                        }}
                        initialFilters={props.initialFilters}
                    >
                        {headCells.map((cell) => {
                            return handleHeadCell(cell);
                        })}
                    </TableFilter>
                    <TableBody>
                        {showWarning ? (
                            <Dialogy
                                setDialogProps={setDialogProps}
                                buttonProps={{
                                    show: showWarning,
                                    titleText: "Warning",
                                    contentText: handleWarningMessage(),
                                    buttonOneColor: "primary",
                                    buttonOneText: "Cancel",
                                    buttonTwoColor: "primary",
                                    buttonTwoText: "Ok",
                                }}
                                handleButtonOne={() => handleCloseWarning()}
                                handleButtonTwo={() => {
                                    handleButtonClick();
                                }}
                            />
                        ) : (
                                <React.Fragment></React.Fragment>
                            )}
                        {depositList.length > 0
                            ? depositList.map((deposit, index) => {
                                return (
                                    <StyledTableRow
                                        key={`${deposit.id}-deposit-table-row`}
                                        hover={true}
                                        className={deposit.daysBeforeShoot <= 0 && "deposit-table-row-overdue"}
                                    >
                                        <StyledTableCell className="deposit-table-body-cell">
                                            {deposit.client}
                                        </StyledTableCell>
                                        <StyledTableCell className="deposit-table-body-cell">
                                            <span
                                                className="clickable"
                                                onClick={() => {
                                                    setRedirectToProject(true);
                                                    setSelectedDepositItem(deposit)
                                                }}
                                            >
                                                {deposit.projectName}
                                            </span>
                                        </StyledTableCell>
                                        <StyledTableCell className="deposit-table-body-cell">
                                            {deposit.dateSigned !== null
                                                ? format(deposit.dateSigned, "dd/MM/yyyy")
                                                : null}
                                        </StyledTableCell>
                                        <StyledTableCell align="center" className="deposit-table-body-cell">
                                            {deposit.dueDate !== null
                                                ? format(deposit.dueDate, "dd/MM/yyyy")
                                                : null}
                                        </StyledTableCell>
                                        <StyledTableCell className="deposit-table-body-cell">
                                            {deposit.accountsContact}
                                        </StyledTableCell>
                                        <StyledTableCell className="deposit-table-body-cell">
                                            {deposit.firstShoot !== null
                                                ? format(deposit.firstShoot, "dd/MM/yyyy")
                                                : null}
                                        </StyledTableCell>
                                        <StyledTableCell
                                            align="center"
                                            className={deposit.daysBeforeShoot <= 0 ? "deposit-table-overdue-text deposit-table-body-cell" : "deposit-table-body-cell"}
                                        >
                                            {deposit.daysBeforeShoot}
                                        </StyledTableCell>
                                        <StyledTableCell align="center" className="deposit-table-body-cell">
                                            <Button
                                                variant="contained"
                                                size="small"
                                                color={deposit.dismissed ? `primary` : `primary`}
                                                onClick={() => {
                                                    setShowWarning(true);
                                                    setSelectedDepositItem(deposit)
                                                }}
                                            >
                                                {deposit.dismissed ? `Unmute` : `Mute`}
                                            </Button>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                )
                            })
                            : <React.Fragment></React.Fragment>
                        }
                    </TableBody>
                </Table>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    dialog: state.dialog,
});
export default connect(mapStateToProps, {
    setDialogProps,
})(DepositTable);