import { Button, Menu, MenuItem, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import moment from "moment";
import axios from "axios";
import { Skeleton } from "@material-ui/lab";
import { getDeliverablesFormat } from "../../../utils/ScheduleItemUtils";
import Snacky from "../../Shared/Snacky";
import InfluencerDeliverableItem from "./InfluencerDeliverableItem";
import "./influencerDeliverables.scss";
import { isEmpty } from "../../../utils/GeneralUtils";
import InfluencerAutoChaseBtn from "./InfluencerAutoChaseBtn";
import { useUserContext } from "../../../context/user/UserContext";
import { v1 as uuidv1 } from "uuid";
import ProcessingSpinnerDialog from "../../Shared/Spinner/ProcessingSpinnerDialog";
import ConfirmationDialog from "../../Shared/ConfirmationDialog";
import { useAuth } from "../../../context/auth/AuthContext";

const InfluencerDeliverables = ({ influencer, isReadOnly, deliverables, setDeliverables, isLoadingDeliverables, setIsLoadingDeliverables }) => {
  const { user } = useAuth();
  const [dueDateDays, setDueDateDays] = useState(1);
  const [hasChanges, setHasChanges] = useState(false);
  const [snackBarProps, setSnackBarProps] = useState({
    open: false,
    severity: "success",
    text: "",
  });
  const [contextMenu, setContextMenu] = React.useState(null);
  const [showProcessingSpinnerDialog, setShowProcessingSpinnerDialog] =
    useState(false);
  const [openInvalidDeliverableDialog, setOpenInvalidDeliverableDialog] =
    useState(false);
  const [invalidDeliverableMessage, setInvalidDeliverableMessage] =
    useState(null);

  useEffect(() => {
    getDeliverables();
    getDefaultDueDateDays();
    setHasChanges(false)
    setDueDateDays(1)
  }, [influencer?.id]);

  const handleSave = () => {
    if (isDeliverablesValid()) {
      setIsLoadingDeliverables(true);
      axios
        .post(`ScheduleItems/UpdateScheduleItemDeliverables`, deliverables)
        .then(({ data }) => {
          setSnackBarProps({
            open: true,
            severity: "success",
            text: "Success! The details have been saved.",
          });
          setDeliverables(data);
          setHasChanges(false);
        })
        .catch((err) => {
          console.log(err);
          setOpenInvalidDeliverableDialog(true);
          setInvalidDeliverableMessage("Unable to save your changes.");
        })
        .finally(() => setIsLoadingDeliverables(false));
    }
  };

  const isDeliverablesValid = () => {
    const invalidGoLiveDate = deliverables.find((d) => isEmpty(d.goLiveDate) && influencer.scheduleItemType !== "Content Creator");
    const invalidDueDate = deliverables.find((d) => isEmpty(d.dueDate));
    const deliverableWithBoost = deliverables.find((d) => d.boostBudget > 0);

    if (invalidGoLiveDate) {
      setOpenInvalidDeliverableDialog(true);
      setInvalidDeliverableMessage(
        "The GO LIVE DATE is missing for some deliverables."
      );
      return false;
    }
    if (invalidDueDate) {
      setOpenInvalidDeliverableDialog(true);
      setInvalidDeliverableMessage(
        "The DUE DATE DATE is missing for some deliverables."
      );
      return false;
    }
    if (!!!deliverableWithBoost && influencer?.type === "paid") {
      setOpenInvalidDeliverableDialog(true);
      setInvalidDeliverableMessage(
        "Unable to save! This is a promoted campaign with no Boost Budget for any deliverable. Add some boost budget to proceed."
      );
      return false;
    }

    return true;
  };

  const getDefaultDueDateDays = () => {
    axios.get(`Influencer/GetInfluencerDefaults`).then(({ data }) => {
      if (data?.daysBeforeGoLiveDate) {
        setDueDateDays(data?.daysBeforeGoLiveDate);
      }
    });
  };

  const handleChangeDeliverable = (deliverableId, field, value) => {
    if (field === "goLiveDate" && value) {
      setDeliverables(
        deliverables.map((d) =>
          d.id === deliverableId
            ? {
                ...d,
                [field]: value,
                dueDate: moment(value).add(-dueDateDays, "days"),
              }
            : d
        )
      );
    } else if (field === "shouldSendProduct") {
      setDeliverables(
        deliverables.map((d) =>
          d.id === deliverableId
            ? { ...d, [field]: value, productProvided: null }
            : d
        )
      );
    } else {
      setDeliverables(
        deliverables.map((d) =>
          d.id === deliverableId ? { ...d, [field]: value } : d
        )
      );
    }
    setHasChanges(true);
  };

  const getDeliverables = () => {
    setIsLoadingDeliverables(true);
    axios
      .get(`ScheduleItems/GetScheduleItemDeliverables/${influencer.id}`)
      .then(({ data }) => {
        setDeliverables(data);
      })
      .catch((err) => {
        console.log(err);
        setDeliverables([]);
      })
      .finally(() => {
        setIsLoadingDeliverables(false);
      });
  };
  const handleCreateNew = (selectedFormat) => {
    setIsLoadingDeliverables(true);
    handleCloseAddDeliverable();
    axios
      .post(`ScheduleItems/CreateUpdateScheduleItemDeliverable`, {
        ScheduleItemfk: influencer.id,
        personFk: influencer.personFk,
        usageInMonths: influencer.scheduleItemType !== "Content Creator" ? 3 : null,
        exclusivityInMonths: influencer.scheduleItemType !== "Content Creator" ? 3 : null,
        format: selectedFormat,
        quantity: 1,
      })
      .then(({ data }) => {
        setSnackBarProps({
          open: true,
          severity: "success",
          text: "All done! You can enter the details now.",
        });

        setDeliverables([...deliverables, data]);
      })
      .catch((err) => {
        console.log(err);
        setSnackBarProps({
          open: true,
          severity: "warning",
          text: "Oh snap! Something went wrong.",
        });
      })
      .finally(() => setIsLoadingDeliverables(false));
  };

  const handleDeleteItem = (deliverable) => {
    setIsLoadingDeliverables(true);
    if (deliverable?.id) {
      axios
        .delete(`ScheduleItems/DeleteDeliverable/${deliverable?.id}`)
        .then(({ data }) => {
          setSnackBarProps({
            open: true,
            severity: "success",
            text: "All done! The Deliverable has been deleted.",
          });

          setDeliverables(
            [...deliverables].filter((d) => d.id !== deliverable.id)
          );
        })
        .catch((err) => {
          console.log(err);
          setSnackBarProps({
            open: true,
            severity: "warning",
            text: "Oh snap! Something went wrong.",
          });
        })
        .finally(() => setIsLoadingDeliverables(false));
    }
  };

  const handleDeleteFile = async (deliverable, file) => {
    if (deliverable?.id && file?.id) {
      await axios
        .delete(
          `ScheduleItems/DeleteDeliverableFile/${deliverable.id}/${file.id}/${deliverable.scheduleItemFk}?user=${user?.email}`
        )
        .then(({ data }) => {
          setSnackBarProps({
            open: true,
            severity: "success",
            text: "All done! The file has been deleted.",
          });
          var copyOfDeliverables = [...deliverables];
          var newDeliverable = copyOfDeliverables.find(
            (d) => d.id === deliverable.id
          );
          var newDeliverableFiles = [];

          if (newDeliverable?.scheduleItemDeliverableFiles?.length > 0) {
            var files = newDeliverable.scheduleItemDeliverableFiles;
            for (var i = 0; i < files.length; i++) {
              if (files[i].id != file.id) {
                newDeliverableFiles.push(files[i]);
              }
            }
          }

          if (newDeliverable?.scheduleItemDeliverableFiles) {
            newDeliverable.scheduleItemDeliverableFiles = newDeliverableFiles;
          }

          setDeliverables(
            deliverables.map((d) =>
              d.id === newDeliverable.id ? newDeliverable : d
            )
          );
        })
        .catch((err) => {
          console.log(err);
          setSnackBarProps({
            open: true,
            severity: "warning",
            text: "Oh snap! Something went wrong.",
          });
        });
    }
  };

  const handleFileUpload = async (
    selectedFile,
    selectedDeliverable,
    isStats = 0
  ) => {
    setShowProcessingSpinnerDialog(true);
    const bucketName = "ts-influencer-content";
    const fileName = `${selectedFile.name}`;
    const guid = uuidv1();
    const url = `https://storage.googleapis.com/${bucketName}/${guid}-${fileName}`;
    const response = await fetch(url, {
      method: "PUT",
      body: selectedFile,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });

    if (response.ok) {
      await axios
        .post("Services/UploadDeliverableFile", {
          deliverableId: selectedDeliverable.id,
          fileUrl: `https://storage.googleapis.com/${bucketName}/${guid}-${fileName}`,
          fileName: fileName,
          isStats: isStats,
        })
        .then((res) => {
          setSnackBarProps({
            open: true,
            severity: "success",
            text: "All done! The file has been uploaded.",
          });
        })
        .catch((e) => {
          setSnackBarProps({
            open: true,
            severity: "warning",
            text: "Error. Could not upload file.",
          });
        })
        .finally(() => {
          getDeliverables();
          setShowProcessingSpinnerDialog(false);
        });
    } else {
      setShowProcessingSpinnerDialog(false);
    }
  };

  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
          }
        : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
          // Other native context menus might behave different.
          // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
          null
    );
  };

  const handleCloseAddDeliverable = () => {
    setContextMenu(null);
  };

  const handleUpdateDeliverableAutoChase = (deliverableIds, value) => {
    axios
      .post(
        `/scheduleitems/UpdateAutoChaseForDeliverables/${value}`,
        deliverableIds
      )
      .then(({ data }) => {
        setDeliverables(
          deliverables.map((d) => {
            if (deliverableIds.includes(d.id)) {
              return { ...d, autoChase: value ? 1 : 0 };
            }
            return d;
          })
        );
        setSnackBarProps({
          open: true,
          severity: "success",
          text: "Beauty! The data has been saved.",
        });
      })
      .catch((err) => {
        setSnackBarProps({
          open: true,
          severity: "warning",
          text: "Ops, something went wrong",
        });
      });
  };

  const handleDeliverableAutoChase = (value) => {
    handleUpdateDeliverableAutoChase(
      deliverables.map((d) => d.id),
      value
    );
  };

  return (
    <div>
      <Snacky snackprops={snackBarProps} setSnackBarProps={setSnackBarProps} />
      {showProcessingSpinnerDialog && (
        <ProcessingSpinnerDialog loading={showProcessingSpinnerDialog} />
      )}
      {openInvalidDeliverableDialog && invalidDeliverableMessage && (
        <ConfirmationDialog
          show={openInvalidDeliverableDialog}
          titleText="Oh snap! Something went wrong."
          contentText={invalidDeliverableMessage}
          handleClose={() => {
            setOpenInvalidDeliverableDialog(false);
            setInvalidDeliverableMessage(null);
          }}
          buttonText="Ok, got it!"
        />
      )}
      {isLoadingDeliverables && <Skeleton />}
      <div className="influencer-deliverables-content-container">
        <Typography variant="h6">{`${influencer.scheduleItemType === "Content Creator" ? "Royalty Free Content": "Content"}`}</Typography>
        {/* {deliverables?.length > 0 && (
          <InfluencerAutoChaseBtn
            title="Chase All Content"
            isChecked={!(!!deliverables.find(d => d.autoChase === 0))}
            handleChange={handleDeliverableAutoChase}
          />
        )} */}
      </div>
      {!(deliverables.length > 0) && (
        <div className="influencer-outreach-details-divider"></div>
      )}

      <div className="influencer-deliverables-itemwrapper">
        {deliverables.length > 0 &&
          !isLoadingDeliverables &&
          deliverables.map((item, index) => (
            <InfluencerDeliverableItem
              key={index}
              deliverableCounter={index + 1}
              deliverable={item}
              handleChangeDeliverable={handleChangeDeliverable}
              allDeliverables={deliverables}
              handleDeleteItem={handleDeleteItem}
              isReadOnly={isReadOnly}
              influencer={influencer}
              handleUpdateDeliverableAutoChase={
                handleUpdateDeliverableAutoChase
              }
              handleDeleteFile={handleDeleteFile}
              handleFileUpload={handleFileUpload}
            />
          ))}
      </div>
      {!isReadOnly() && (
        <div className="influencer-deliverables-add-button">
          <div>
            <Button
              size="small"
              onClick={handleContextMenu}
              color="primary"
              variant="contained"
            >
              Add new deliverable
            </Button>
            <Menu
              open={contextMenu !== null}
              onClose={handleCloseAddDeliverable}
              anchorReference="anchorPosition"
              anchorPosition={
                contextMenu !== null
                  ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                  : undefined
              }
            >
              {getDeliverablesFormat().map((item) => (
                <MenuItem
                  onClick={(e) => {
                    handleCreateNew(item);
                  }}
                >
                  {item}
                </MenuItem>
              ))}
            </Menu>
          </div>

          <Button
            className={
              hasChanges ? "influencer-schedule-table-row-btn-wiggle" : ""
            }
            size="small"
            color="primary"
            disabled={!(deliverables.length > 0 && hasChanges)}
            variant="contained"
            onClick={handleSave}
          >
            {`Save ${
              deliverables?.length > 1 ? "Deliverables" : "Deliverable"
            }`}
          </Button>
        </div>
      )}
    </div>
  );
};

export default InfluencerDeliverables;
