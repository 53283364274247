import { Typography } from "@material-ui/core";
import React from "react";
import TimeTrackerCaptionLabel from "../TimeTrackerCaptionLabel";

const TimeTrackerAllocationHeader = ({ datesToDisplay }) => {
  return (
    <div className="time-tracker-hour-allocation-row no-hover-state">
      <div className="time-tracker-project-name-container">
        <Typography></Typography>
      </div>
      <div className="time-tracker-hour-allocation-column-container time-tracker-allocation-container">
        {datesToDisplay.map((date, index) => (
          <TimeTrackerCaptionLabel
            key={index}
            value={date.format("ddd, MMM Do")}
          />
        ))}
      </div>
      <div style={{ minWidth: 50 }}></div>
    </div>
  );
};

export default TimeTrackerAllocationHeader;
