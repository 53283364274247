import { Skeleton } from "@material-ui/lab";
import axios from "axios";
import React, { useEffect, useState } from "react";
import PersonRatingsTable from "./PersonRatingsTable";
import "./personNotes.scss";
import { useAuth } from "../../../context/auth/AuthContext";
import Snacky from "../Snacky";
import PersonNotesForm from "./PersonNotesForm";
import PersonNotesTable from "./PersonNotesTable";

const PersonNotes = ({ personId }) => {
  
  const [notes, setNotes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [snackBarProps, setSnackBarProps] = useState({
    open: false,
    severity: "success",
    text: "",
  });

  useEffect(() => {
    getNotes();
  }, [personId]);

  const getNotes = () => {
    if (personId) {
      setIsLoading(true);
      axios
        .get(`/PersonNotes/GetNotes/${personId}`)
        .then(({ data }) => {
          setNotes(data);
        })
        .catch(() => {
          console.error("Failed to fetch notes");
          setSnackBarProps({
            open: true,
            severity: "warning",
            text: "Failed to fetch notes",
          });
        })
        .finally(() => setIsLoading(false));
    }
  };

  const handleSaveCallback = (note) => {
    if (note) {
      setNotes([note, ...notes]);
      setSnackBarProps({
        open: true,
        severity: "success",
        text: "Fantastic! The note was saved successfully",
      });
    } else {
      setSnackBarProps({
        open: true,
        severity: "warning",
        text: "Failed to save the note",
      });
    }
  };

  
  return (
    <div>
      <Snacky snackprops={snackBarProps} setSnackBarProps={setSnackBarProps} />
      {isLoading ? (
        <div style={{ display: "grid" }}>
          <Skeleton height={20} />
          <Skeleton height={20} />
          <Skeleton height={20} />
        </div>
      ) : (
        <div className="person-ratings-table-wrapper">
          <PersonNotesForm
            handleSaveCallback={handleSaveCallback}
            personId={personId}
            displayForm={false}
          />
          <PersonNotesTable notes={notes} />
        </div>
      )}
    </div>
  );
};

export default PersonNotes;
