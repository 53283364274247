import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import {
  Paper,
  Button,
  Grid,
  Grow,
  ButtonGroup,
  Popper,
  MenuItem,
  MenuList,
  ClickAwayListener,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Axios from "axios";
import { getCurrentTime } from "../../../../utils/TimeHelpers";
import { contains } from "../../../../utils/utils";
import { useUserContext } from "../../../../context/user/UserContext";
import { useAuth } from "../../../../context/auth/AuthContext";

const changeButton = (contractStatus, category) => {
  switch (contractStatus) {
    case "Return":
      return { color: "#3f51BF", options: ["Return"] };
      break;
    case "Send":
      return { color: "#3f51BF", options: ["Send"] };
      break;
    case "Pending":
      if(category === "Location") {
        return {
          color: "#FFA500",
          options: ["Pending", "Cancel Contract"],
        };
      } else {
        return {
          color: "#FFA500",
          options: ["Pending", "Cancel Contract"],
        };
      }
      break;
    case "Signed":
      if(category === "Location") {
        return {
          color: "#F987C5",
          options: ["Signed", "Cancel Contract"],
        };
      } else {
        return {
          color: "#F987C5",
          options: ["Signed", "Cancel Contract", "Send for Invoicing"],
        };
      }
      break;
    case "Declined":
      return {
        color: "#FF0000",
        options: ["Declined", "Cancel Declined Contract"],
      };
      break;
    case "InvoicePending":
      return { color: "#FFA500", options: ["Invoice Pending", "Cancel Invoice"] };
      break;
    case "Invoiced":
      return { color: "#008000", options: ["Invoiced"] };
      break;
  }
};

const LocationContractButton = (props) => {
  const { user, } = useAuth();
  const [buttonProps, setButtonProps] = useState(
    changeButton(props.sl.contractStatus, props.rescheduleButton)
  );
  const [open, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [checked, setChecked] = useState(true);
  const anchorRef = useRef(null);

  const handleClick = () => {
    if (buttonProps.options[selectedIndex] === "Send" && props.rescheduleButton !== "Location") {
      handleSendLocationContract();
    }
  };

  useEffect(() => {
    setButtonProps(changeButton(props.sl.contractStatus, props.rescheduleButton));
  }, [props.sl.contractStatus]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    // if (anchorRef.current && anchorRef.current.contains(event.target)) {
    if (anchorRef.current && contains(anchorRef.current, event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleMenuItemClick = (event, index) => {
    if (props.parentIsClosingProject) {
      if (buttonProps.options[index] === "Cancel Contract") {
        props.editProjectActiveItem(props.item, "contractStatus", "Send");
        Axios.post(
          `LocationContracts/CancelLocationContract/${props.item.locationId}/${props.item.scheduleFk}`
        )
          .then((res) => {
            console.log("Splicing project active item inside button");
            props.spliceProjectActiveItem(props.item.id);
          })
          .catch((err) => console.log(err));
      } else if (buttonProps.options[index] === "Send for Invoicing") {
        // props.editScheduleLocation(props.sl, "contractStatus", "Invoiced");
        props.spliceProjectActiveItem(props.item.id);
        Axios.post(
          `Mail/SendLocationInvoice/${props.item.scheduleFk}/${props.item.locationId}`,
          {
            name: `${user.firstName} ${user.lastName}`,
            email: user.email,
          }
        )
          .then((res) => console.log(res))
          .catch((err) => console.log(err));
      } else if (buttonProps.options[index] === "Resend") {
        Axios.post(
          `ResendMail/ResendLocationContract/${props.item.scheduleFk}/${props.item.locationId}`,
          {
            name: `${user.firstName} ${user.lastName}`,
            email: user.email,
          }
        )
          .then((res) => {
            console.log(res);
          })
          .catch((err) => console.log(err));
      }
      setOpen(false);
    } else {
      if (buttonProps.options[index] === "Cancel Contract") {
        props.editScheduleLocation(props.s, props.sl, "contractStatus", "Send");
        Axios.post(
          `LocationContracts/CancelLocationContract/${props.l.id}/${props.s.id}`
        )
          .then((res) => console.log(res))
          .catch((err) => console.log(err));
      } else if (buttonProps.options[index] === "Send for Invoicing") {
        // props.editScheduleLocation(props.sl, "contractStatus", "Invoiced");
        props.editScheduleLocation(
          props.s,
          props.sl,
          "contractStatus",
          "InvoicePending"
        );
        Axios.post(`Mail/SendLocationInvoice/${props.s.id}/${props.l.id}`, {
          name: `${user.firstName} ${user.lastName}`,
          email: user.email,
        })
          .then((res) => console.log(res))
          .catch((err) => console.log(err));
      } else if (buttonProps.options[index] === "Resend") {
        Axios.post(
          `ResendMail/ResendLocationContract/${props.s.id}/${props.l.id}`,
          {
            name: `${user.firstName} ${user.lastName}`,
            email: user.email,
          }
        )
          .then((res) => {
            console.log(res);
          })
          .catch((err) => console.log(err));
      } else if (buttonProps.options[index] === "Cancel Invoice") {
        handleCancelLocationInvoice();
      } else if (buttonProps.options[index] === "Cancel Declined Contract") {
        handleCancelDeclinedContract();
      }
      setOpen(false);
    }
  };

  const handleCancelLocationInvoice = () => {
    props.editScheduleLocation(props.s, props.sl, "contractStatus", "Signed");
    Axios.patch(`LocationContracts/CancelLocationInvoice/${props.s.id}/${props.l.id}`, {
      scheduleFk: props.s.id,
      locationFk: props.l.id,
    })
  }

  const handleSendLocationContract = () => {
    props.editScheduleLocation(props.s, props.sl, "contractStatus", "Pending");
    Axios.post(
      `Mail/SendLocationContractEmail/${props.l.primaryContact}/${props.l.id}/${props.s.id}`,
      {
        name: `${user.firstName} ${user.lastName}`,
        email: user.email,
      }
    )
      .then(
        Axios.post("LocationContracts/PostLocationContract", {
          dateSent: getCurrentTime(),
          status: "Sent",
          schedulefk: props.sl.scheduleFk,
          locationFk: props.sl.locationFk,
        })
          .then((res) => {
            console.log(res);
          })
          .catch((e) => {
            console.log(e);
          })
      )
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCancelDeclinedContract = () => {
    props.editScheduleLocation(props.s, props.sl, "contractStatus", "Signed");
    Axios.patch(`ScheduleLocation/CancelDeclinedContract/${props.s.id}/${props.l.id}`, {
      scheduleFk: props.s.id,
      locationFk: props.l.id
    })
      .then((res) => console.log(res))
      .catch((err) => {
        console.log(err)
      });
  }

  return (
    <Grow in={checked} mountOnEnter unmountOnExit>
      <Grid
        container
        alignItems="center"
        style={
          !props.parentIsClosingProject && { width: "auto", marginLeft: "10px" }
        }
      >
        <Grid item xs={12}>
          <ButtonGroup
            disabled={props.disabled !== undefined ? props.disabled : false}
            variant="contained"
            ref={anchorRef}
            aria-label="split button"
            size="small"
            style={
              !props.parentIsClosingProject
                ? {
                  width: "160px",
                  backgroundColor: buttonProps.color,
                  float: "right",
                }
                : {
                  width: "120px",
                  backgroundColor: buttonProps.color,
                }
            }
          >
            <Button
              onClick={handleClick}
              style={
                !props.parentIsClosingProject
                  ? {
                    padding: "0px 0px 0px 0px",
                    width: "140px",
                    backgroundColor: buttonProps.color,
                    color: "white",
                  }
                  : {
                    padding: "0px 0px 0px 0px",
                    width: "100px",
                    backgroundColor: buttonProps.color,
                    color: "white",
                  }
              }
            >
              {buttonProps.options[selectedIndex]}
            </Button>
            <Button
              size="small"
              aria-controls={open ? "split-button-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-label="select declined option"
              aria-haspopup="menu"
              onClick={handleToggle}
              style={{
                width: "20px",
                backgroundColor: buttonProps.color,
                color: "white",
              }}
            >
              <ArrowDropDownIcon />
            </Button>
          </ButtonGroup>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
            style={{
              zIndex: "999",
            }}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom",
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList id="split-button-menu">
                      {buttonProps.options.map(
                        (option, index) =>
                          index > 0 && (
                            <MenuItem
                              key={option}
                              disabled={index === 0}
                              selected={index === selectedIndex}
                              onClick={(event) =>
                                handleMenuItemClick(event, index)
                              }
                            >
                              {option}
                            </MenuItem>
                          )
                      )}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Grid>
      </Grid>
    </Grow>
  );
};

const mapStateToProps = (state, ownProps) => ({
});
export default connect(mapStateToProps, {})(LocationContractButton);
