import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  open: false,
};
const dialog = createReducer(initialState, {
  SET_DIALOG_PROPS: (state, action) => {
    return {
      ...state,
      open: action.payload.open,
    };
  },
});

export default dialog;
