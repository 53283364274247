import React, { useEffect } from "react";
import { Backdrop, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import "./authLoader.scss";
import { useState } from "react";
import FirebaseGAuth from "./FirebaseGAuth";
import { useAuth } from "../../context/auth/AuthContext";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: "99999",
    color: "white",
    background: "#f8f8f8 !important",
  },
}));
const AuthLoader = ({ endpoint, location }) => {
  const classes = useStyles();
  const { user } = useAuth();
  const [hasLoaded, setHasLoaded] = useState(false);

  useEffect(() => {
    if (!hasLoaded) {
      setTimeout(() => setHasLoaded(true), 1500);
    }
  }, [hasLoaded, user]);

  return (
    <div>
      <Backdrop className={classes.backdrop} open={true}>
        {hasLoaded && (
          <FirebaseGAuth
            endpoint={
              location.state !== undefined
                ? location.state.endpoint === "/index.html"
                  ? "/"
                  : location.state.endpoint
                : "/"
            }
          />
        )}

        <div
          className="auth-loader-container"
          style={{ width: "250px", height: "auto" }}
        >
          <Typography component={"h2"} className="loader-text">
            Loading...
          </Typography>
        </div>
        {/* <div
              className="d-flex align-items-center justify-content-center"
              style={{ width: '90px', height: '80px' }}>
              <GridLoader color={"white"} loading={true} />
            </div> */}
      </Backdrop>
    </div>
  );
};

export default AuthLoader;
