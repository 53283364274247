import {
  Button,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import "./purchaseOrderForm.scss";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { Autocomplete } from "@material-ui/lab";
import {
  currencyFormat,
  generateUniqueHash,
  isEmpty,
} from "../../../utils/GeneralUtils";
import { format } from "date-fns";
import XeroContactAutocomplete from "../../Shared/XeroContactAutocomplete/XeroContactAutocomplete";
import moment from "moment";
import { get12MonthsAheadAnd1MonthBackArr } from "../../../utils/TimeHelpers";
import CurrencyExchangeDialog from "../../CurrencyExchange/CurrencyExchangeDialog";
import CurrencyDropdownMenu from "../../CurrencyExchange/CurrencyDropdownMenu";
import MultiCurrencyInputField from "../../CurrencyExchange/MultiCurrencyInputField";
import ConfirmationDialogActions from "../../Shared/ConfirmationDialogActions";
import { internalOrganisationIds } from "../../../utils/OrganisationUtils";
import PullFromBudgetAutocomplete from "../../Shared/BudgetItem/PullFromBudgetAutocomplete";

const PurchaseOrderForm = ({
  purchaseOrder,
  budgetItems,
  handlePurchaseOrderOnChange,
  handleCreatePurchaseOrder,
  handleChangeTotal,
  showHiddenBudget = false
}) => {
  const [showConfirmInternal, setShowConfirmInternal] = useState(false);
  const handleChangeXeroContact = (xeroContact) => {
    //xeroContact.ContactID
    handlePurchaseOrderOnChange("xeroContactId", xeroContact);
  };

  return (
    <div className="purchase-order-form-root">
      <Paper>
        {showConfirmInternal && (
          <ConfirmationDialogActions
            title={"Are you sure?"}
            open={showConfirmInternal}
            contentText={"This action will create an internal company quote."}
            button1Action={() => setShowConfirmInternal(false)}
            button1Text={"Cancel"}
            button2Text={"Proceed"}
            button2Action={() => {
              handleCreatePurchaseOrder();
              setShowConfirmInternal(false);
            }}
          />
        )}
        <div className="purchase-order-form-container">
          <XeroContactAutocomplete
            organisationId={2}
            handleOnChange={handleChangeXeroContact}
            value={purchaseOrder.xeroContactId}
            variant={"outlined"}
          />
          <PullFromBudgetAutocomplete
            options={budgetItems.filter((b) => b.remaining > 0)}//b.isHidden !== 1 && 
            handleOnChange={(event, newValue) => {
              handlePurchaseOrderOnChange("budget", newValue || null);
            }}
            value={budgetItems.find(
              (bi) => bi.projectCategory === purchaseOrder.budgetCategory
            )}
          />
          <TextField
            className="purchase-order-form-container-campaign-input"
            label="What is this for?"
            placeholder="Service Name"
            variant="outlined"
            size="small"
            value={purchaseOrder.serviceName}
            onChange={(e) => {
              handlePurchaseOrderOnChange("serviceName", e.target.value);
            }}
          />

          <div>
            <MultiCurrencyInputField
              totalAmount={purchaseOrder.total}
              nzdTotalAmount={purchaseOrder.totalInNzd}
              size="small"
              currency={purchaseOrder.currency}
              handleChange={handleChangeTotal}
              partOfCurrencyExchangeForm={false}
            />
          </div>

          <Button
            size="small"
            className="default-button"
            variant="contained"
            onClick={() => {
              if (internalOrganisationIds.includes(purchaseOrder.xeroContactId)) {
                setShowConfirmInternal(true);
              } else {
                handleCreatePurchaseOrder();
              }
            }}>
            Save
          </Button>
        </div>
      </Paper>
    </div>
  );
};

export default PurchaseOrderForm;
