import React, { useState, useEffect } from "react";
import {
    Box,
    Collapse,
    IconButton,
    Table,
    TableHead,
    TableBody,
    TableCell,
    Typography,
    TableRow,
    CircularProgress,
} from "@material-ui/core";
import {
    KeyboardArrowDown as KeyboardArrowDownIcon,
    KeyboardArrowUp as KeyboardArrowUpIcon
} from "@material-ui/icons";

const ClientDeliverablesRow = ({
    delivery
}) => {
    const [openDestination, setOpenDestination] = useState(false);
    const searchDestinations = (keyNumber) => {
        setOpenDestination(!openDestination);
    };
    const renderStatusBadge = (status) => {
        return status !== "completed"
            ? "badge badge-warning"
            : "badge badge-success";
    };
    return (
        <React.Fragment>
            <tr key={delivery.id}>
                <td>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => searchDestinations(delivery.keyNumber)}
                    >
                        {openDestination ? (
                            <KeyboardArrowUpIcon />
                        ) : (
                            <KeyboardArrowDownIcon />
                        )}
                    </IconButton>
                </td>
                <td>
                    <Typography variant="caption">
                        {new Date(delivery.created).toLocaleDateString("en-NZ")}
                    </Typography>
                </td>
                <td>
                    <Typography variant="caption">{delivery.title}</Typography>
                </td>
                <td>
                    <Typography variant="caption">{delivery.keyNumber}</Typography>
                </td>
                <td>
                    <Typography variant="caption">{delivery.duration}</Typography>
                </td>
                <td>
                    <div className={renderStatusBadge(delivery.status)}>
                        {delivery.status}
                    </div>
                </td>
                <td>
                    <Typography variant="caption">
                        {new Date(delivery.firstAirDate).toLocaleDateString("en-NZ")}
                    </Typography>
                </td>
                <td className="text-center">
                    <Typography variant="caption">{delivery.quantity}</Typography>
                </td>
                <td className="text-center">
                    <Typography variant="caption">${delivery.rate}</Typography>
                </td>
                <td className="text-center">
                    <Typography variant="caption">${delivery.cab}</Typography>
                </td>
                <td className="text-center">
                    <Typography variant="caption">${delivery.total}</Typography>
                </td>
            </tr>
            <TableRow style={{ backgroundColor: "white" }}>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={11}>
                    <Collapse in={openDestination} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Typography variant="h5" gutterBottom component="div">
                                Destinations
                            </Typography>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Network</TableCell>
                                        <TableCell>Submitted</TableCell>
                                        <TableCell>AdStream Status</TableCell>
                                        <TableCell>View Status</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {delivery.destinations ? (
                                        delivery.destinations.map((destination) => (
                                            <TableRow key={destination.destinationId}>
                                                <TableCell component="th" scope="row">
                                                    <Typography variant="caption">
                                                        {destination.destinationName}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    <Typography variant="caption">
                                                        {new Date(destination.submitted).toLocaleDateString(
                                                            "en-NZ"
                                                        )}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    <Typography variant="caption">
                                                        {destination.a4Status}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    <Typography variant="caption">
                                                        {destination.viewStatus}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    ) : (
                                        <CircularProgress />
                                    )}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    )
}

export default ClientDeliverablesRow;