import React, { useEffect, useState } from "react";
import "./influencerDeliverableItem.scss";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Badge,
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  Grid,
  CircularProgress,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import CheckIcon from "@material-ui/icons/Check";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { Autocomplete } from "@material-ui/lab";
import { getDeliverablesFormat, getDeliverablesQuantities } from "../../../utils/ScheduleItemUtils";
import { format } from "date-fns";
import { calculateDateDifference, getMonthsArray } from "../../../utils/utils";
import { isNumeric } from "../../../utils/GeneralUtils";
import DriveVideoPreview from "../../Shared/DriveVideoPreview";
import FilePreview from "../../Shared/FilePreview";
import ConfirmationDialogActions from "../../Shared/ConfirmationDialogActions";
import InfluencerDeliverableItemCaption from "./InfluencerDeliverableItemCaption";
import InfluencerAutoChaseBtn from "./InfluencerAutoChaseBtn";
import axios from "axios";
import {
  Delete,
  HourglassEmpty as HourglassEmptyIcon,
  CloudUpload as CloudUploadIcon,
} from "@material-ui/icons";
import ProcessingSpinner from "../../Shared/Spinner/ProcessingSpinner";
import ContentApprovedDialog from "../../Project/Schedule/PrePost/InfluencerNetwork/ContentApproved/ContentApprovedDialog";
import StatisticsPopup from "./StatisticsPopup";
import GeneralVideoView from "../../Shared/Dialog/GeneralVideoView";

const InfluencerDeliverableItem = ({
  deliverableCounter,
  deliverable,
  handleChangeDeliverable,
  allDeliverables,
  handleDeleteItem,
  isReadOnly,
  influencer,
  handleUpdateDeliverableAutoChase,
  handleDeleteFile,
  handleFileUpload,
}) => {
  const [openDeleteItemDialog, setOpenDeleteItemDialog] = useState(false);
  const [openDeleteFileDialog, setOpenDeleteFileDialog] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(null);
  const [expanded, setExpanded] = useState(true);
  const [isUploading, setIsUploading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showDueDate, setShowDueDate] = useState(false);
  const [
    openContentApprovedOnConfirmation,
    setOpenContentApprovedOnConfirmation,
  ] = useState(false);
  const [deliverableId, setDeliverableId] = useState(null);
  const [showStatsPopup, setShowStatsPopup] = useState(false);
  const [showFilePreview, setShowFilePreview] = useState(false);
  const [filePreviewUrl, setFilePreviewUrl] = useState(null);

  const handleDeleteItemDialogClose = () => {
    setOpenDeleteItemDialog(false);
  };
  const handleConfirmDeleteItem = async () => {
    await handleDeleteItem(deliverable);
    handleDeleteItemDialogClose();
  };
  const handleDeliverableAutoChase = (value) => {
    handleUpdateDeliverableAutoChase([deliverable.id], value);
  };
  const handleConfirmDeleteFile = async () => {
    setIsDeleting(true);
    await handleDeleteFile(deliverable, fileToDelete);
    setOpenDeleteFileDialog(false);
    setFileToDelete(null);
    setIsDeleting(false);
  };
  const handleFileChange = async (e, isStats = 0) => {
    e.preventDefault();
    setIsUploading(true);
    await handleFileUpload(e.target.files[0], deliverable, isStats);
    setIsUploading(false);
  };
  const handleCloseConfirmationModal = () => {
    setOpenContentApprovedOnConfirmation(false);
    setDeliverableId(null);
  };
  const handleConfirmContentApproval = () => {
    //update deliverable
    handleChangeDeliverable(deliverable.id, "status", "approved");
    handleCloseConfirmationModal();
  };
  const handleMarkAsCompleteCallback = () => {
    //update deliverable
    handleChangeDeliverable(deliverable.id, "statsUploaded", 1);
    setShowStatsPopup(false);
    setDeliverableId(null);
  };
  const getStatisticsButton = (deliverable) => {
    if (deliverable.statsUploaded === 1) {
      return (
        <Button
          variant="contained"
          style={{ backgroundColor: "rgb(0, 153, 0)", color: "white" }}
          size="small"
          fullWidth
          onClick={() => {
            setShowStatsPopup(true);
          }}
        >
          Statistics
        </Button>
      );
    } else {
      return (
        <Button
          variant="contained"
          color="primary"
          size="small"
          fullWidth
          onClick={() => {
            setShowStatsPopup(true);
          }}
        >
          Statistics
        </Button>
      );
    }
  };

  return (
    <Accordion expanded={expanded} style={{ borderRadius: 4 }}>
      {showFilePreview && (
        <GeneralVideoView
          open={showFilePreview}
          handleClose={() => {
            setShowFilePreview(false);
            setFilePreviewUrl(null);
            setDeliverableId(null);
          }}
          fileUrl={filePreviewUrl}
        />
      )}
      {showStatsPopup && (
        <StatisticsPopup
          open={showStatsPopup}
          handleClose={() => {
            setShowStatsPopup(false);
            setDeliverableId(null);
          }}
          deliverableFiles={deliverable.scheduleItemDeliverableFiles}
          deliverableId={deliverable.id}
          handleMarkAsCompleteCallback={handleMarkAsCompleteCallback}
          isReadOnly={deliverable.statsUploaded === 1}
        />
      )}
      {openContentApprovedOnConfirmation && (
        <ContentApprovedDialog
          open={openContentApprovedOnConfirmation}
          scheduleItemId={deliverable?.scheduleItemFk}
          handleClose={handleCloseConfirmationModal}
          handleUpdateCallback={handleConfirmContentApproval}
          deliverableId={deliverableId}
        />
      )}
      {openDeleteItemDialog && (
        <ConfirmationDialogActions
          title={"Are you sure?"}
          open={openDeleteItemDialog}
          contentText={
            "This action will delete the deliverable and any uploaded file"
          }
          button1Action={handleDeleteItemDialogClose}
          button1Text={"Cancel"}
          button2Text={"Delete"}
          button2Action={handleConfirmDeleteItem}
          isLoading={isDeleting}
        />
      )}
      {openDeleteFileDialog && (
        <ConfirmationDialogActions
          title={"Delete File?"}
          open={openDeleteFileDialog}
          contentText={
            "This action will delete the selected file from the system. (This cannot be undone)"
          }
          button1Action={() => {
            setOpenDeleteFileDialog(false);
            setFileToDelete(null);
          }}
          button1Text={"Cancel"}
          button2Text={"Delete"}
          button2Action={() => {
            handleConfirmDeleteFile();
          }}
          isLoading={isDeleting}
        />
      )}
      <AccordionSummary
        expandIcon={<ExpandMoreIcon onClick={(e) => setExpanded(!expanded)} />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <div className="influencer-deliverable-item-accordion-title">
          <Typography
            onClick={(e) => setExpanded(!expanded)}
          >{`Deliverable #${deliverableCounter} - ${deliverable?.quantity || 1} x ${deliverable?.format}`}</Typography>
          <InfluencerAutoChaseBtn
            title="Chase Content"
            isChecked={!!deliverable.autoChase}
            handleChange={handleDeliverableAutoChase}
          />
        </div>
      </AccordionSummary>
      <AccordionDetails className="influencer-deliverable-item-accordion-details">
        <div className="influencer-deliverable-flex-container">
          <Autocomplete
            className={"influencer-deliverable-flex-container-item"}
            options={getDeliverablesQuantities()}
            value={deliverable.quantity}
            getOptionLabel={(option) => option?.toString()}
            onChange={(event, newValue) => {
              handleChangeDeliverable(deliverable.id, "quantity", newValue);
            }}
            disabled={isReadOnly()}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                label="Quantity"
                variant="outlined"
                fullWidth={true}
              />
            )}
            disableClearable
            fullWidth
            size="small"
          />
          <Autocomplete
            className={"influencer-deliverable-flex-container-item"}
            options={getDeliverablesFormat()}
            value={deliverable.format}
            getOptionLabel={(option) => option}
            renderOption={(option) => <Chip label={option} />}
            renderTags={(tagValue, getTagProps) => {}}
            onChange={(event, newValue) => {
              handleChangeDeliverable(deliverable.id, "format", newValue);
            }}
            disabled={isReadOnly()}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                label="Format"
                variant="outlined"
                fullWidth={true}
              />
            )}
            disableClearable
            fullWidth
            size="small"
          />
          {influencer?.type?.toLowerCase() === "paid" && (
            <div className={"influencer-deliverable-flex-container-item"}>
              <TextField
                label="Boost Budget"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                fullWidth
                variant="outlined"
                size="small"
                value={deliverable.boostBudget}
                disabled={isReadOnly()}
                onChange={(e) => {
                  if (isNumeric(e.target.value) || e.target.value === "") {
                    handleChangeDeliverable(
                      deliverable.id,
                      "boostBudget",
                      e.target.value
                    );
                  }
                }}
              />
            </div>
          )}
        </div>
        <div className="influencer-deliverable-flex-container">
          {influencer.scheduleItemType !== "Content Creator" && (
            <div
              className="influencer-deliverable-flex-container-item-half-width"
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  // className="schedule-item-deliverables-line-date"
                  format="dd/MM/yyyy"
                  disablePast
                  disabled={isReadOnly()}
                  minDateMessage={null}
                  inputVariant={"outlined"}
                  size="small"
                  fullWidth
                  autoOk={true}
                  label={"Go Live Date"}
                  value={deliverable.goLiveDate}
                  onChange={(date, value) => {
                    if (date instanceof Date && !isNaN(date)) {
                      handleChangeDeliverable(
                        deliverable.id,
                        "goLiveDate",
                        format(date, "yyyy-MM-dd HH:mm:ss")
                      );
                    }
                  }}
                  KeyboardButtonProps={{
                    style: {
                      ...{
                        padding: "0px",
                        margin: "0px",
                      },
                    },
                  }}
                  renderDay={(
                    day,
                    selectedDate,
                    isInCurrentMonth,
                    dayComponent
                  ) => {
                    var render = false;
                    for (var i = 0; i < allDeliverables.length; i++) {
                      var dDate = new Date(allDeliverables[i].goLiveDate);
                      var calendarDate = new Date(day);
                      if (dDate.toISOString() === calendarDate.toISOString()) {
                        render = true;
                      }
                    }

                    return (
                      <Badge
                        className="delivereable-calendar-badge"
                        badgeContent={render ? "!" : undefined}
                        color="secondary"
                      >
                        {dayComponent}
                      </Badge>
                    );
                  }}
                />
              </MuiPickersUtilsProvider>
              {!showDueDate && !isReadOnly() && (
                <div className="influencer-deliverable-item-link-wrapper">
                  <Typography variant="caption">{`Due - ${calculateDateDifference(
                    deliverable.dueDate,
                    deliverable.goLiveDate
                  )} days`}</Typography>
                  <div className="influencer-deliverable-item-link-field">
                    <Typography
                      variant="caption"
                      onClick={(e) => setShowDueDate(true)}
                    >{`edit`}</Typography>
                  </div>
                </div>
              )}
            </div>
          )}

          {(influencer.scheduleItemType === "Content Creator" ||
            showDueDate ||
            isReadOnly()) && (
            <div
              className="influencer-deliverable-flex-container-item-half-width"
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  // className="schedule-item-deliverables-line-date"
                  format="dd/MM/yyyy"
                  label={"Due Date"}
                  size="small"
                  inputVariant={"outlined"}
                  value={deliverable.dueDate}
                  fullWidth
                  disabled={isReadOnly()}
                  onChange={(date, value) => {
                    if (date instanceof Date && !isNaN(date)) {
                      handleChangeDeliverable(
                        deliverable.id,
                        "dueDate",
                        format(date, "yyyy-MM-dd HH:mm:ss")
                      );
                    } else {
                      handleChangeDeliverable(deliverable.id, "dueDate", null);
                    }
                  }}
                  KeyboardButtonProps={{
                    style: {
                      ...{
                        padding: "0px",
                        margin: "0px",
                      },
                    },
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
          )}
        </div>
        <div>
          {deliverable.scheduleItemDeliverableFiles?.length === 0 && (
            <div className="deliverable-action-button-contaner-new">
              <input
                accept="video/*, image/*"
                className={"upload-deliverables-upload-button"}
                id={`contained-button-file-upload-${deliverable.id}`}
                type="file"
                onChange={(e) => handleFileChange(e)}
                disabled={isUploading}
              />
              <label htmlFor={`contained-button-file-upload-${deliverable.id}`}>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  component="span"
                  startIcon={<CloudUploadIcon />}
                >
                  Upload New Content
                </Button>
              </label>
            </div>
          )}
          {deliverable.scheduleItemDeliverableFiles?.length > 0 ? (
            <div>
              <Grid container spacing={1}>
                <Grid item xs={9} md={9} lg={9}>
                  <div
                    style={{
                      display: "flex",
                      overflowX: "auto",
                      maxWidth: 500,
                    }}
                  >
                    {deliverable.scheduleItemDeliverableFiles?.map(
                      (file, index) => {
                        if (file.isStats == 1) {
                          return "";
                        } else {
                          return (
                            <div style={{ padding: "0px 5px" }}>
                              <FilePreview
                                fileUrl={file.driveId}
                                showControls={false}
                                containerClassName="deliverableItem-filePreview-container"
                                handleOnClick={() => {
                                  setFilePreviewUrl(file.driveId);
                                  setShowFilePreview(true);
                                }}
                              />
                            </div>
                          );
                        }
                      }
                    )}
                  </div>
                </Grid>
                <Grid item xs={3} md={3} lg={3}>
                  {deliverable.status !== "approved" ? (
                    <React.Fragment>
                      <div className="deliverable-action-button-contaner-existing">
                        <input
                          accept="video/*, image/*"
                          className={"upload-deliverables-upload-button"}
                          id={`contained-button-file-upload-${deliverable.id}`}
                          type="file"
                          onChange={(e) => handleFileChange(e)}
                          disabled={isUploading}
                        />
                        <label
                          htmlFor={`contained-button-file-upload-${deliverable.id}`}
                        >
                          <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            component="span"
                            fullWidth
                            startIcon={<CloudUploadIcon />}
                          >
                            Upload
                          </Button>
                        </label>
                      </div>
                      <div className="deliverable-action-button-contaner-existing">
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          fullWidth
                          onClick={() => {
                            setOpenContentApprovedOnConfirmation(true);
                            setDeliverableId(deliverable.id);
                          }}
                        >
                          Approve
                        </Button>
                      </div>
                      <div className="deliverable-action-button-contaner-existing">
                        {getStatisticsButton(deliverable)}
                      </div>
                    </React.Fragment>
                  ) : (
                    <div>
                      <div className="deliverable-action-button-contaner-existing">
                        <Button
                          variant="contained"
                          style={{
                            backgroundColor: "rgb(0, 153, 0)",
                            color: "white",
                          }}
                          size="small"
                          fullWidth
                          disabled
                        >
                          Approved
                        </Button>
                      </div>
                      <div className="deliverable-action-button-contaner-existing">
                        {getStatisticsButton(deliverable)}
                      </div>
                    </div>
                  )}
                </Grid>
              </Grid>
            </div>
          ) : (
            <Typography variant="caption">Awaiting upload</Typography>
          )}
        </div>
        {influencer.scheduleItemType !== "Content Creator" && (
          <div>
            <InfluencerDeliverableItemCaption
              deliverable={deliverable}
              handleSaveCopy={() => {}}
            />
          </div>
        )}

        {!isReadOnly() && (
          <div className="influencer-deliverable-item-action-container">
            <Button
              size="small"
              color="primary"
              variant="contained"
              startIcon={<DeleteIcon />}
              onClick={() => setOpenDeleteItemDialog(true)}
            >
              Delete
            </Button>
          </div>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default InfluencerDeliverableItem;
