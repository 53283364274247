import React, { Fragment } from "react";
import {
  Grid,
  withStyles,
  Tooltip,
  Typography,
  TextField,
  IconButton,
} from "@material-ui/core";
import ContactMailIcon from "@material-ui/icons/ContactMail";
import ChildCareIcon from "@material-ui/icons/ChildCare";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const StyledTypography = withStyles((theme) => ({
  root: {
    fontSize: "14px",
  },
}))(Typography);

export const ProviderTableCell = (props) => {
  let {
    scheduleItem,
    setOpenContactDetailsPopUp,
    setCurrentProvider,
    setCurrentScheduleItemId,
    handleAgentContextClick,
  } = props;
  return (
    <React.Fragment>
      <Grid container spacing={1}>
        <Grid item xs={8} md={8} lg={8} className="provider-cell pointer">
          <HtmlTooltip
            title={
              scheduleItem.personFkNavigation !== null &&
                scheduleItem.personFkNavigation !== undefined ? (
                  <div onClick={(event) => event.stopPropagation}>
                    <Typography color="inherit">
                      {scheduleItem.personFkNavigation.firstName +
                        " " +
                        scheduleItem.personFkNavigation.lastName}
                    </Typography>
                    <b>{"Phone"}</b>
                    {": "} <em>{scheduleItem.personFkNavigation.phoneNumber}</em>{" "}
                    <br />
                    <b>{"Email"}</b>
                    {": "} <em>{scheduleItem.personFkNavigation.emailAddress}</em>{" "}
                    {scheduleItem.personFkNavigation.parentName != null && (
                      <div>
                        <b>Parent: </b>
                        <em>{scheduleItem.personFkNavigation.parentName}</em>
                      </div>
                    )}
                  </div>
                ) : (
                  ""
                )
            }
            interactive
            arrow
            placement="left"
          >
            <Typography
              variant="body1"
              onClick={() => {
                setCurrentProvider(
                  scheduleItem.personFkNavigation
                    ? scheduleItem.personFkNavigation
                    : scheduleItem.personFk
                );
                setCurrentScheduleItemId(scheduleItem.id);
                setOpenContactDetailsPopUp(true);
              }}
              className={`clickable provider-cell-responsive`}
            >
              {scheduleItem.personFkNavigation.firstName + " " + scheduleItem.personFkNavigation.lastName}
            </Typography>
            {/* <StyledTypography
              component={"div"}
              style={{ fontFamily: "Roboto" }}
            >
              <TextField
                value={
                  scheduleItem.personFkNavigation.firstName +
                  " " +
                  scheduleItem.personFkNavigation.lastName
                }
                variant="outlined"
                fullWidth
                disabled
                size="small"
                inputProps={{
                  className: "inputProps",
                }}
                className={`provider-cell ${
                  props.providerType === "Talent"
                    ? "talent-provider-cell-responsive"
                    : ""
                }`}
                onClick={() => {
                  setCurrentProvider(
                    scheduleItem.personFkNavigation
                      ? scheduleItem.personFkNavigation
                      : scheduleItem.personFk
                  );
                  setCurrentScheduleItemId(scheduleItem.id);
                  setOpenContactDetailsPopUp(true);
                }}
              />
            </StyledTypography> */}
          </HtmlTooltip>
        </Grid>
        {scheduleItem.personFkNavigation !== null &&
          scheduleItem.personFkNavigation.agentFk !== null ? (
            <Grid item xs={3} md={3} lg={3}>
              <HtmlTooltip
                title={
                  scheduleItem.personFkNavigation.agentFkNavigation !== null &&
                    scheduleItem.personFkNavigation.agentFkNavigation !==
                    undefined ? (
                      <div onClick={(event) => event.stopPropagation()}>
                        <Typography color="inherit">
                          Agent Contact Details
                    </Typography>
                        <b>{"Phone"}</b>
                        {": "}{" "}
                        <em>
                          {
                            scheduleItem.personFkNavigation.agentFkNavigation
                              .phoneNumber
                          }
                        </em>{" "}
                        <br />
                        <b>{"Email"}</b>
                        {": "}{" "}
                        <em>
                          {
                            scheduleItem.personFkNavigation.agentFkNavigation
                              .emailAddress
                          }
                        </em>{" "}
                      </div>
                    ) : (
                      ""
                    )
                }
                interactive
                arrow
                placement="right"
              >
                <IconButton
                  onClick={(event) => event.stopPropagation()}
                  onContextMenu={(event) =>
                    handleAgentContextClick(event, scheduleItem)
                  }
                  size="small"
                  style={{
                    padding: 0,
                    margin: 0,
                  }}
                >
                  <ContactMailIcon
                    style={{
                      width: 30,
                      height: 30,
                    }}
                  // className="agent-icon-responsive"
                  />
                </IconButton>
              </HtmlTooltip>
            </Grid>
          ) : (
            ""
          )}
        {props.providerType === "Talent" &&
          scheduleItem.personFkNavigation !== null &&
          scheduleItem.personFkNavigation.hasParent === 1 ? (
            <IconButton
              size="small"
              disabled
              style={{
                padding: 0,
                margin: 0,
              }}
            >
              <ChildCareIcon />
            </IconButton>
          ) : (
            ""
          )}
      </Grid>
    </React.Fragment>
  );
};
