import { Typography } from "@material-ui/core";
import { Rating } from "@material-ui/lab";
import React, { useEffect, useState } from "react";

const PersonRatingsIcon = ({ ratings, size = "small", styles }) => {
  const [value, setValue] = useState(0);

  useEffect(() => {
    if (ratings?.length > 0) {
      setValue(
        ratings?.reduce((acc, curr) => acc + (curr.rating || 0), 0) /
          (ratings?.length || 1)
      );
    }
  }, [ratings]);

  return (
    <div>
      {ratings?.length > 0 && (
        <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
          <Rating value={value} precision={0.5} size={size} readOnly />
          <Typography style={styles} variant="caption">
            ({ratings.length})
          </Typography>
        </div>
      )}
    </div>
  );
};

export default PersonRatingsIcon;
