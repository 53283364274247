import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Button,
} from "@material-ui/core";

const InformationDialogy = (props) => {
  const initialDialogProps = {
    open: false,
  };
  return (
    <Dialog
      open={props.buttonProps.show}
      onClose={() => props.setDialogProps(initialDialogProps)}
    >
      <DialogTitle style={{ textAlign: "center" }}>
        {props.buttonProps.titleText}
      </DialogTitle>
      <DialogContent>
        <DialogContentText style={{ textAlign: "center" }}>
          {props.buttonProps.contentText}
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ textAlign: "center" }}>
        <Button
          onClick={() => {
            props.handleButton();
          }}
          color={props.buttonProps.buttonColor}
        >
          {props.buttonProps.buttonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InformationDialogy;
