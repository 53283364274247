import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import PayableFileViewer from "../PayableFileViewer";
import moment from "moment";

const ReconciledItemsDialog = ({ reconciledItems, open, handleClose }) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [loadingPdf, setLoadingPdf] = useState(false);
  useEffect(() => {
    if (reconciledItems?.length > 0) {
      setSelectedItem(reconciledItems[0]?.bill);
    }
  }, []);

  return (
    <Dialog
      open={open}
      onClose={(e, reason) => {
        handleClose();
      }}
      fullWidth
      maxWidth="lg"
      className="split-payable-dialog default-dialog"
    >
      <DialogTitle className="default-title default-dialog-header-container">
        Bills allocated to this campaign
      </DialogTitle>
      <DialogContent>
        <div style={{ display: "flex" }}>
          <div style={{ width: "50%" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Reference</TableCell>
                  <TableCell>Total</TableCell>
                  <TableCell>Subtotal</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {reconciledItems.map(({ bill }, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      {bill.transactionDate
                        ? moment(bill.transactionDate)?.format("DD/MM/YYYY")
                        : " - "}
                    </TableCell>
                    <TableCell>{bill.transactionId}</TableCell>
                    <TableCell>{bill.amount}</TableCell>
                    <TableCell>{bill.subtotal}</TableCell>
                    <TableCell>
                      <Typography
                        style={{ cursor: "pointer" }}
                        variant="caption"
                        onClick={(e) => setSelectedItem(bill)}
                      >
                        view
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
          <div style={{ width: "50%", maxHeight: "80%" }}>
            {selectedItem && (
              <PayableFileViewer
                payable={selectedItem}
                loadingPdf={loadingPdf}
                setLoadingPdf={setLoadingPdf}
              />
            )}
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button className="default-button-grey" onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReconciledItemsDialog;
