import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
} from "@material-ui/core";
import { format } from "date-fns";
import Axios from "axios";

const DeliveryConfirmation = (props) => {
  const [hungryClassic, setHungryClassic] = useState(0);
  const [hungrySimpleSalad, setHungrySimpleSalad] = useState(0);
  const [vegetarian, setVegetarian] = useState(0);
  const [vegan, setVegan] = useState(0);
  const [glutenFree, setGlutenFree] = useState(0);
  const [dairyFree, setDairyFree] = useState(0);
  const [totalCost, setTotalCost] = useState(0.0);
  const [mealCount, setMealCount] = useState(0);
  const [gstIncCost, setGstIncCost] = useState(null);
  const [deliveryCost, setDeliveryCost] = useState(0.0);
  const [gstExclusiveDeliveryPrice, setGstExclusiveDeliveryPrice] = useState(0.0);
  const [kidsGstIncCost, setKidsGstIncCost] = useState(null);
  const [kidsMeal, setKidsMeal] = useState(0);
  const deliveryContact = props.deliveryContact;
  const [showPlaceOrder, setShowPlaceOrder] = useState(false);

  const placeOrder = () => {
    axios.post(`Schedules/SendCateringOrder`, {
      address: props.deliveryAddress,
      startTime: format(props.deliveryTime, "HH:mm aa"),
      date: format(new Date(props.scheduleDate), "dd/MM/yyyy"),//props.scheduleDate,
      classic: hungryClassic,
      simpleSalad: hungrySimpleSalad,
      vegetarian: vegetarian,
      vegan: vegan,
      glutenFree: glutenFree,
      dairyFree: dairyFree,
      kidsMeal: kidsMeal,
      contactName: deliveryContact.firstName + " " + deliveryContact.lastName,
      contactPhone: deliveryContact.phoneNumber,
      scheduleId: props.scheduleId,
      cost: (totalCost + deliveryCost).toFixed(2),
      mealCost: gstIncCost,
      kidsMealCost: kidsGstIncCost,
      deliveryCost: deliveryCost.toFixed(2),
      mealCount: mealCount,
    });
    axios.post(`Catering/PostCatering`, {
      orderJson: JSON.stringify({
        address: props.deliveryAddress,
        startTime: format(props.deliveryTime, "HH:mm aa"),
        date: format(new Date(props.scheduleDate), "dd/MM/yyyy"),//props.scheduleDate
        classic: hungryClassic,
        simpleSalad: hungrySimpleSalad,
        vegetarian: vegetarian,
        vegan: vegan,
        glutenFree: glutenFree,
        dairyFree: dairyFree,
        kidsMeal: kidsMeal,
        contactName: deliveryContact.firstName + " " + deliveryContact.lastName,
        contactPhone: deliveryContact.phoneNumber,
        contactEmail: deliveryContact.emailAddress,
        deliveryCost: deliveryCost.toFixed(2),
        gstExclusiveDeliveryCost: gstExclusiveDeliveryPrice.toFixed(2),
        mealCost: gstIncCost,
        kidsMealCost: kidsGstIncCost,
        mealCount: mealCount,
      }),
      peopleJson: JSON.stringify(props.allPeople),
      scheduleFk: props.scheduleId,
      status: "Sent",
      type: "Deliver",
      cost: (totalCost + deliveryCost).toFixed(2),
      mealCount: mealCount,
    });
    props.editSchedule(
      "cateringOrdered",
      1,
      props.schedule,
      1
    );
    props.handleClose();
    props.closeCaterPopup();
  };

  useEffect(() => {
    Axios.get(`CateringFinances/GetLastCateringFinance`)
      .then((res) => {
        var cateringFinance = res.data;
        if (cateringFinance.gstInc === 1) {
          setGstIncCost(cateringFinance.mealPrice);
        } else {
          //It is exclusive of GST
          var inclusivePrice = cateringFinance.mealPrice * 1.15;
          setGstIncCost(inclusivePrice.toFixed(2));
        }
        if (cateringFinance.kidsMealGstInc == 1) {
          setKidsGstIncCost(cateringFinance.kidsMealPrice);
        }
        else {
          var inclusiveKidsPrice = cateringFinance.kidsmealPrice * 1.15;
          setKidsGstIncCost(inclusiveKidsPrice.toFixed(2));
        }
        if (cateringFinance.deliveryGstInc === 1) {
          setDeliveryCost(cateringFinance.deliveryCost);
          var gstAdded = (cateringFinance.deliveryCost * 3) / 23;
          var gstExclusiveDeliveryPrice = cateringFinance.deliveryCost - gstAdded;
          setGstExclusiveDeliveryPrice(gstExclusiveDeliveryPrice);
        } else {
          //It is exclusive of GST
          var deliveryInclusivePrice = cateringFinance.deliveryCost * 1.15;
          setDeliveryCost(deliveryInclusivePrice);
          setGstExclusiveDeliveryPrice(cateringFinance.deliveryCost);
        }
        var classic = 0;
        var vegetarian = 0;
        var vegan = 0;
        var glutenFree = 0;
        var dairyFree = 0;
        var kidsMeal = 0;
        let people = props.checkedPeople;

        console.log("Catering People")
        console.log(people)

        //Creates the Order
        people.map((person) => {
          switch (person.mealPreference) {
            case "Classic":
              if (person.isChild) {
                kidsMeal++;
              }
              else {
                classic++;
              }
              break;
            case "Vegetarian":
              vegetarian++;
              break;
            case "Vegan":
              vegan++;
              break;
            case "Gluten Free":
              glutenFree++;
              break;
            case "Dairy Free":
              dairyFree++;
              break;
            default:
              break;
          }
        });
        //----Original----
        if (classic % 2 === 1) {
          setHungryClassic((Math.round(classic / 2)));
          setHungrySimpleSalad(Math.floor(classic / 2));
        } else {
          setHungryClassic((classic / 2));
          setHungrySimpleSalad(classic / 2);
        }
        //----Winter----
        //80:20 to salad
        // setHungryClassic((Math.round(classic * 0.8)));
        // setHungrySimpleSalad(Math.round(classic * 0.2));
        //-----------------


        setVegetarian(vegetarian);
        setVegan(vegan);
        setGlutenFree(glutenFree);
        setDairyFree(dairyFree);
        setKidsMeal(kidsMeal);
        setShowPlaceOrder(true);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  useEffect(() => {
    //16.95
    var cost = (gstIncCost * (
      hungryClassic +
      hungrySimpleSalad +
      vegetarian +
      vegan +
      glutenFree +
      dairyFree
    )) + (kidsGstIncCost * kidsMeal);
    var count =
      hungryClassic +
      hungrySimpleSalad +
      vegetarian +
      vegan +
      glutenFree +
      dairyFree +
      kidsMeal;

    setTotalCost(cost);
    setMealCount(count);
    console.log("gstIncCost");
    console.log(gstIncCost);
    console.log("Cost");
    console.log(cost);
  }, [
    hungryClassic,
    hungrySimpleSalad,
    vegetarian,
    vegan,
    dairyFree,
    glutenFree,
    kidsMeal
  ]);

  return (
    <Dialog open={props.modalState} onClose={props.handleClose} scroll={"paper"}>
      <DialogTitle>Eat My Lunch Delivery Order</DialogTitle>
      <DialogContent dividers={true}>
        <DialogContentText>
          {
            <div>
              <b>{`Delivery Address:`}</b>
              <br />
              {props.deliveryAddress}
              <br />
              <br />
              <b>{`Delivery Contact:`}</b>
              <br />
              {`${deliveryContact.firstName} ${deliveryContact.lastName}`}
              <br />
              <br />
              <b>{`Orders:`}</b>
              {
                showPlaceOrder ?
                  <React.Fragment>
                    <br />
                    {hungryClassic === 0 ? (
                      ""
                    ) : (
                      <div>
                        {`Hungry Classic: ${hungryClassic}`}
                        <br />
                      </div>
                    )}
                    {hungrySimpleSalad === 0 ? (
                      ""
                    ) : (
                      <div>
                        {`Hungry Simple Salad: ${hungrySimpleSalad}`}
                        <br />
                      </div>
                    )}
                    {vegetarian === 0 ? (
                      ""
                    ) : (
                      <div>
                        {`Classic Vegetarian: ${vegetarian}`}
                        <br />
                      </div>
                    )}
                    {glutenFree === 0 ? (
                      ""
                    ) : (
                      <div>
                        {`Classic Gluten Friendly: ${glutenFree}`}
                        <br />
                      </div>
                    )}
                    {vegan === 0 ? (
                      ""
                    ) : (
                      <div>
                        {`Classic Vegan: ${vegan}`}
                        <br />
                      </div>
                    )}
                    {dairyFree === 0 ? (
                      ""
                    ) : (
                      <div>
                        {`Classic Dairy Friendly: ${dairyFree}`}
                        <br />
                      </div>
                    )}
                    {kidsMeal === 0 ? (
                      ""
                    ) : (
                      <div>
                        {`Kids Meal: ${kidsMeal}`}
                        <br />
                      </div>
                    )}
                  </React.Fragment>
                  : <CircularProgress />
              }
              {/* <br />
                            <b>{`Cost: $`}</b>{totalCost.toFixed(2)}
                            <br />
                            <b>{`Inc delivery: $`}</b>{(totalCost + deliveryCost).toFixed(2)} */}
            </div>
          }
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} color="primary">
          Cancel
        </Button>
        <Button
          disabled={!showPlaceOrder}
          onClick={() => {
            placeOrder();
            props.handleClose();
          }}
          color="primary"
        >
          Place Order
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeliveryConfirmation;
