// -------------------------------------- Kanban Board -----------------------------------------//

export const GET_OUTPUTS = "GET_OUTPUTS";
export const GET_PROJECTS = "GET_PROJECTS";
export const RENDER_ORPHAN_SHELLS = "RENDER_ORPHAN_SHELLS";

export const COPY_PROJECT = "COPY_PROJECT";
export const SWAP_PROJECTS = "SWAP_PROJECTS";
export const SPLICE_PROJECT = "SPLICE_PROJECT";

export const UPDATE_REAL_PROJECT_PROGRESS = "UPDATE_REAL_PROJECT_PROGRESS";
export const UPDATE_SHELL_PROJECT_PROGRESS = "UPDATE_SHELL_PROJECT_PROGRESS";

export const MERGE_OUTPUTS_TO_SHELL_PROJECT_ONSHELL_PROJECT_DROP =
  "MERGE_OUTPUTS_TO_SHELL_PROJECT_ONSHELL_PROJECT_DROP";

export const SPLICE_OUTPUTS_TO_COLUMN = "SPLICE_OUTPUTS_TO_COLUMN ";
export const SPLICE_OUTPUTS_FROM_COLUMN = "SPLICE_OUTPUTS_FROM_COLUMN ";
export const SPLICE_ONE_OUTPUT_FROM_COLUMN = "SPLICE_ONE_OUTPUT_FROM_COLUMN ";

export const MERGE_ORPHANS_TO_REAL_PROJECT_ON_DROP =
  "MERGE_ORPHANS_TO_REAL_PROJECT_ON_DROP";
export const MERGE_ORPHAN_SIBLINGS = "MERGE_ORPHAN_SIBLINGS";
export const RETURN_OUTPUT = "RETURN_OUTPUT";
export const RETURN_SHELL_OUTPUTS_TO_OWNER = "RETURN_SHELL_OUTPUTS_TO_OWNER";
export const SPLICE_ONE_FROM_SHELL = "SPLICE_ONE_FROM_SHELL";
export const SPLICE_ONE_FROM_REAL = "SPLICE_ONE_FROM_REAL";
export const SORT_PROJECTS = "SORT_PROJECTS";
export const RESET_PROJECTS_LOADING = "RESET_PROJECTS_LOADING";
export const RESET_OUTPUTS_LOADING = "RESET_OUTPUTS_LOADING";
export const RESORT_PROJECTS = "RESORT_PROJECTS";

export const RENDER_ORPHAN_OUTPUTS = "RENDER_ORPHAN_OUTPUTS";
export const RESET_ORPHANS = "RESET_ORPHANS";
