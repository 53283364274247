import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Box, Typography, Tab, Tabs, AppBar } from "@material-ui/core";
import axios from "axios";
import "../../custom.scss";
import Script from "./ScriptWrapperComponents/Script";
import Slides from "./ScriptWrapperComponents/Slides";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scriptwrapper-tabpanel-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} style={{ height: "calc(100vh - 230px)" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

const ScriptWrapper = ({ projectId, project, url, slidesFromParent }) => {
  const classes = useStyles();
  const [tabValue, setTabValue] = React.useState(0);
  const [showLoader, setShowLoader] = useState(false);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const openInNewTabButton = (url) => {
    return (
      <Button
        variant="contained"
        className={`margin-sides-10 ${showLoader ? "" : "default-button" }`}
        onClick={() => window.open(url)}
        disabled={showLoader}
        size="small"
      >
        Open In New Tab
      </Button>
    );
  };

  return (
    <div className={classes.root}>
      {project?.organisationFk === 1 ? (
        <>
          <AppBar position="static" className="default-background-container">
            <Tabs value={tabValue} onChange={handleTabChange} centered>
              <Tab label={`Script & Storyboard`} />
              <Tab label={`Casting`} />
              <Tab label={`Direction`} />
            </Tabs>
          </AppBar>
          <TabPanel value={tabValue} index={0}>
            <Script
              url={url}
              projectId={projectId}
              project={project}
              openInNewTabButton={openInNewTabButton}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
              <Slides
                slideType="Casting"
                project={project}
                openInNewTabButton={openInNewTabButton}
              />
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
              <Slides
                slideType="Direction"
                project={project}
                openInNewTabButton={openInNewTabButton}
              />
          </TabPanel>
        </>
      ) : (
        <Slides
          slideType="Influencer"
          project={project}
          openInNewTabButton={openInNewTabButton}
          slidesFromParent={slidesFromParent}
        />
      )}
    </div>
  );
};

export default ScriptWrapper;
