import React, { useEffect, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import axios from "axios";
import DeliveryConfirmation from "./DeliveryConfirmation";
import PickupConfirmation from "./PickupConfirmation";
import classifyPoint from "robust-point-in-polygon";
import { cateringPolygon, wellingtonPolygon } from "../../../utils/CateringPolygonCoordinates";
import {
    Modal,
    AppBar,
    Toolbar,
    Typography,
    IconButton,
    Grid,
    Card,
    Button,
    Tabs,
    Tab,
    Box,
    Tooltip,
    FormControl,
    Input,
    InputLabel,
    Select,
    MenuItem,
    Snackbar
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import CateringTable from "./CateringTable";
import PropTypes from 'prop-types';
import "./Catering.css";
import MuiAlert from "@material-ui/lab/Alert";
import {
    differenceInCalendarDays,
    format
} from "date-fns";
import {
    geocodeByAddress
} from "react-places-autocomplete";

const useStyles = makeStyles(theme => ({
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        minHeight: "70Vh",
        maxHeight: "70Vh",
        width: 1000,
        overflow: "auto"
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },
}));

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        maxWidth: 300,
        fontSize: theme.typography.pxToRem(12),
        border: "1px solid #dadde9",
    },
}))(Tooltip);

function CateringPopUp(props) {
    const classes = useStyles();

    const [getPeople, setGetPeople] = useState(true);
    const [people, setPeople] = useState([]);

    const [completedPeople, setCompletedPeople] = useState([]);
    const [uncompletePeople, setUnCompletePeople] = useState([]);
    const [checkedPeople, setCheckedPeople] = useState([]);
    const [cateringBudget, setCateringBudget] = useState(null);
    const [scheduleDate, setScheduleDate] = useState(null);
    const [peopleFetched, setPeopleFetched] = useState(false);

    const [showPickup, setShowPickup] = useState(false);
    const [showDelivery, setShowDelivery] = useState(false);
    const [show20, setShow20] = useState(false);
    const [tabMessage, setTabMessage] = useState("");

    const [tabValue, setTabValue] = useState(null);

    const [deliverableAddresses, setDeliverableAddresses] = useState([]);
    const [deliveryAddress, setDeliveryAddress] = useState(null);
    const [pickupContact, setPickupContact] = useState(null);
    const [pickupTime, setPickupTime] = useState(null);
    const [deliveryContact, setDeliveryContact] = useState(null);
    const [showDeliveryConfirmation, setShowDeliveryConfirmation] = useState(false);
    const [showPickupConfirmation, setShowPickupConfirmation] = useState(false);

    const [orderInfo, setOrderInfo] = useState(false);
    const [orderType, setOrderType] = useState(null);

    const [shakeTable, setShakeTable] = useState(false);
    const [showOrderError, setShowOrderError] = useState(false);

    const [donationTotal, setDonationTotal] = useState(0);

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                {...other}
            >
                {value === index && (
                    <Box p={3}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.any.isRequired,
        value: PropTypes.any.isRequired,
    };

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const checkMealPreferences = () => {
        var people = checkedPeople;
        for (var i = 0; i < people.length; i++) {
            if (people[i].mealPreference === null) {
                return false;
            }
        }

        return true;
    }

    const handleError = () => {
        setShowOrderError(true);
        setShakeTable(true)
    }

    const mealCount = () => {
        return (
            <div style={{ display: "flex", justifyContent: "flex-end", width: "40%" }}>
                <div className="catering-meals-left">
                    {`Meals Left: ${Math.round((cateringBudget / 20) - checkedPeople.length)}`}
                </div>
                <div className="catering-divider">|</div>
                <div className="catering-tally">
                    {`Order Tally: ${checkedPeople.length}`}
                </div>
            </div>
        )
    }

    const deliverySection = () => {
        return (
            <div>
                <div className="output-delivery-section">
                    <FormControl className={classes.formControl}>
                        <InputLabel>Delivery Address</InputLabel>
                        <Select
                            value={deliveryAddress}
                            onChange={(event) => setDeliveryAddress(event.target.value)}
                        >
                            {
                                deliverableAddresses.map((address, index) => {
                                    return <MenuItem key={index} value={address}>{address.address}</MenuItem>
                                })
                            }
                        </Select>
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <InputLabel>Delivery Contact</InputLabel>
                        <Select
                            value={deliveryContact}
                            onChange={(event) => setDeliveryContact(event.target.value)}
                        >
                            {
                                people.map((person, index) => {
                                    if (person.personCategory === "Staff" || person.personCategory === "Contractor") {
                                        return <MenuItem key={index} value={person}>{`${person.firstName} ${person.lastName}`}</MenuItem>
                                    }
                                })
                            }
                        </Select>
                    </FormControl>
                    <div>
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            disabled={(deliveryAddress === null || deliveryContact === null) ? true : false}
                            onClick={() => checkMealPreferences() ? setShowDeliveryConfirmation(true) : handleError()}
                        >
                            Send Delivery Order
                        </Button>
                    </div>
                    {props.cateringOrdered === 0 && mealCount()}
                </div>
                <Typography className="catering-notes">
                    <div>
                        <i><b>Note:</b> Addresses shown are only those within delivery zone</i>
                    </div>
                    <div>
                        <i><b>Note:</b> Lunch will be delivered between 10am - 12:30pm throughout out the morning...order will be displayed on Camistry Go</i>
                    </div>
                </Typography>
            </div>
        )
    }

    const pickupSection = () => {
        return (
            <div>
                <div className="output-delivery-section">
                    <FormControl className={classes.formControl}>
                        <InputLabel>Pickup Time</InputLabel>
                        <Select
                            value={pickupTime}
                            onChange={(event) => setPickupTime(event.target.value)}
                        >
                            <MenuItem value={'December 17, 1995 07:00:00'}>{`7am`}</MenuItem>
                            <MenuItem value={'December 17, 1995 08:00:00'}>{`8am`}</MenuItem>
                            <MenuItem value={'December 17, 1995 09:00:00'}>{`9am`}</MenuItem>
                            <MenuItem value={'December 17, 1995 10:00:00'}>{`10am`}</MenuItem>
                            <MenuItem value={'December 17, 1995 11:00:00'}>{`11am`}</MenuItem>
                            <MenuItem value={'December 17, 1995 12:00:00'}>{`12pm`}</MenuItem>
                            <MenuItem value={'December 17, 1995 13:00:00'}>{`1pm`}</MenuItem>
                            <MenuItem value={'December 17, 1995 14:00:00'}>{`2pm`}</MenuItem>
                            <MenuItem value={'December 17, 1995 15:00:00'}>{`3pm`}</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <InputLabel>Delivery Contact</InputLabel>
                        <Select
                            value={pickupContact}
                            onChange={(event) => setPickupContact(event.target.value)}
                        >
                            {
                                people.map((person, index) => {
                                    if (person.personCategory === "Staff" || person.personCategory === "Contractor") {
                                        return <MenuItem key={index} value={person}>{`${person.firstName} ${person.lastName}`}</MenuItem>
                                    }
                                })
                            }
                        </Select>
                    </FormControl>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() => checkMealPreferences() ? setShowPickupConfirmation(true) : handleError()}
                        disabled={(pickupTime === null || pickupContact === null) ? true : false}
                    >
                        Send Pickup Order
                    </Button>
                    {props.cateringOrdered === 0 && mealCount()}
                </div>
                <div>
                    <Typography className="catering-notes">
                        <div>
                            <i><b>Note:</b> Pickup will be at 5 Galatos Street, Auckland CBD, Auckland 1010</i>
                        </div>
                    </Typography>
                </div>
            </div>
        )
    }

    const updateMealPreferenceDB = (personId, mealPreference) => {
        axios.put(`People/UpdateMealPreference`, {
            id: personId,
            mealPreference: mealPreference
        })
    }

    const updatePerson = (p, fieldName, objValue) => {
        setPeople(
            people.map((person) =>
                person === p
                    ? { ...person, [fieldName]: objValue }
                    : person
            )
        );
        console.log(fieldName)
        if (fieldName === "mealPreference") {
            updateMealPreferenceDB(p.id, objValue);
        }
    }

    const hasRedBorder = () => {
        var count = 0;
        for (var i = 0; i < uncompletePeople.length; i++) {
            if (uncompletePeople[i].willOrder === false) {
                count++;
            }
        }
        if (uncompletePeople.length === count) {
            return false;
        }
        return true;
    }

    const mainTables = () => {
        return (
            <Grid container spacing={2}>
                {/* Non specified meals */}
                <Grid item sm={12} md={12} lg={12}>
                    <Card elevation={5}>
                        {
                            uncompletePeople.length > 0 ?
                                <div className={hasRedBorder() ?
                                    shakeTable ? "output-catering-table-red shake" : "output-catering-table-red"
                                    : ""}>
                                    <CateringTable
                                        people={uncompletePeople}
                                        updatePerson={updatePerson}
                                    />
                                </div>
                                : ""
                        }
                    </Card>
                </Grid>
                {/* Specified meals */}
                <Grid item sm={12} md={12} lg={12}>
                    <Card elevation={5}>
                        <div className="output-catering-table-green">
                            <CateringTable
                                people={completedPeople}
                                updatePerson={updatePerson}
                            />
                        </div>
                    </Card>
                </Grid>
            </Grid>
        )
    }

    const tabHeader = (label, disabled) => {
        if (disabled) {
            return (
                <Tab
                    label={label}
                    disabled={true}
                    className={"output-tab-disabled"}
                />
            )
        }
        return (
            <Tab label={label} />
        )
    }

    const existingOrder = () => {
        return (
            <div style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column"
            }}>
                {
                    orderInfo.type === "Pickup" ?
                        <b>{`Pickup Address:`}</b>
                        :
                        <b>{`Delivery Address:`}</b>
                }
                <br />
                {orderInfo.address}
                <br />
                <br />
                <b>{orderInfo.type === "Pickup" ? `Pickup Contact:` : `Delivery Contact`}</b>
                <br />
                {`${orderInfo.contactName}`}
                <br />
                <br />
                <b>{`Location start time:`}</b>
                <br />
                {orderInfo.startTime}
                <br />
                <br />
                <b>{`Orders:`}</b>
                <br />
                {`Hungry Classic: ${orderInfo.classic}`}
                <br />
                {`Hungry Simple Salad: ${orderInfo.simpleSalad}`}
                <br />
                {`Classic Vegetarian: ${orderInfo.vegetarian}`}
                <br />
                {`Classic Gluten Friendly: ${orderInfo.glutenFree}`}
                <br />
                {`Classic Vegan: ${orderInfo.vegan}`}
                <br />
                {`Classic Dairy Friendly: ${orderInfo.dairyFree}`}
                <br />
                {`Kids Meal: ${orderInfo.kidsMeal}`}
                <br />
                <br />
                <p style={{fontSize: "1.5em"}}>We have donated <b>{donationTotal}</b> lunches to kids in need</p>
                <hr/>
                {/* <br />
                <b>{`Cost:`}</b>{` $${props.cateringOrder.cost.toFixed(2)}`} */}
            </div>
        )
    }

    useEffect(() => {
        var requestOne = axios.get(`BudgetItems/GetCateringBudget/${props.projectId}`);
        var requestTwo = axios.get(`Schedules/GetScheduleDate/${props.scheduleId}`);
        var requestThree = axios.get(`Catering/GetTotalCateringForProject/${props.projectId}`);
        var requestFour = axios.get(`Catering/GetDonationTotal`)

        axios.all([requestOne, requestTwo, requestThree, requestFour])
            .then(axios.spread((...responses) => {
                const cateringBudget = responses[0].data;
                const scheduleDate = responses[1].data;
                const totalCatering = responses[2].data;
                const donations = responses[3].data;

                setCateringBudget(cateringBudget - totalCatering);
                setScheduleDate(scheduleDate);
                // setScheduleDate(date);
                setDonationTotal(donations);
            })).catch(errors => {
                // react on errors.
                console.log(errors);
            })
    }, [])

    useEffect(() => {
        if (props.cateringOrdered === 0) {
            if (getPeople) {
                setGetPeople(false);
                axios.get(`People/GetPeopleForCatering/${props.scheduleId}`)
                    .then(res => {
                        setPeople(res.data);
                        setPeopleFetched(true);
                    })
                    .catch(e => {
                        console.log(e);
                        setPeopleFetched(true);
                    })
            }
        }
        else {
            setPeople(JSON.parse(props.cateringOrder.peopleJson));
            setPeopleFetched(true);
            setOrderInfo(JSON.parse(props.cateringOrder.orderJson))
            setOrderType(props.cateringOrder.type);
        }
    }, [getPeople])

    //Seperates people with no meal preference
    useEffect(() => {
        if (people !== null && people.length > 0) {
            var complete = [];
            var uncomplete = [];
            var checked = [];

            people.forEach(person => {
                if (person.mealPreference !== null) {
                    complete.push(person);
                }
                else {
                    uncomplete.push(person);
                }
                if (person.willOrder) {
                    checked.push(person);
                }
            })
            setCompletedPeople(complete);
            setUnCompletePeople(uncomplete);
            setCheckedPeople(checked);
        }
    }, [people])

    //Sees what available options there are
    useEffect(() => {
        if (peopleFetched) {
            var isInzone = false;
            var deliveryAddress = [];

            if (false) {
                setShowDelivery(false);
                setShowPickup(false);
                setShow20(true);
                setTabMessage("Only Pay on the Day available (Less than 2 days until the schedule)");
            }
            else if(false) {
                setShowDelivery(false);
                setShowPickup(false);
                setShow20(true);
                setTabMessage("Only Pay on the Day available (Less than 4 people)");
            }
            else {
                //Check inzone
                var scheduleLocations = props.scheduleLocations;
                for (var i = 0; i < scheduleLocations.length; i++) {
                    var address = scheduleLocations[i].locationFkNavigation.address.split(",")
                    var suburb = "";
                    if (address.length === 5) {
                        suburb = address[2];
                    }
                    else {
                        suburb = address[1];
                    }
                    var isInzone = false;
                    if (classifyPoint(cateringPolygon, [scheduleLocations[i].locationFkNavigation.latitude, scheduleLocations[i].locationFkNavigation.longitude]) === -1
                        || classifyPoint(wellingtonPolygon, [scheduleLocations[i].locationFkNavigation.latitude, scheduleLocations[i].locationFkNavigation.longitude]) === -1) {
                        var d1 = new Date("December 17, 1995 07:00:00");//min 7am
                        var d2 = new Date("December 17, 1995 15:00:00");//max 3pm
                        var sDate = new Date("December 17, 1995");

                        var scheduleLocationDate = format(new Date(scheduleLocations[i].startTime), "hh:mm aa");
                        var s = scheduleLocationDate;
                        var parts = s.match(/(\d+)\:(\d+) (\w+)/);
                        var hours = /AM/i.test(parts[3])
                            ?
                            parseInt(parts[1], 10)
                            : parseInt(parts[1], 10) !== 12
                                ? parseInt(parts[1], 10) + 12
                                : parseInt(parts[1], 10);

                        var minutes = parseInt(parts[2], 10);
                        sDate = new Date(1995, 11, 17, hours, minutes, 0);

                        if (d1 <= sDate && d2 >= sDate) {
                            console.log("success")
                            isInzone = true;
                            var scheduleLocation = {
                                address: scheduleLocations[i].locationFkNavigation.address,
                                startTime: scheduleLocations[i].startTime
                            }
                            deliveryAddress.push(scheduleLocation);
                        }
                    }
                }
                setShow20(true);
                if (deliveryAddress.length > 0) {
                    setShowDelivery(true);
                    setShow20(false)
                }
                if (deliveryAddress.length !== scheduleLocations.length) {
                    setShowPickup(true);
                }
                setDeliverableAddresses(deliveryAddress);
            }
        }
    }, [peopleFetched, scheduleDate])

    useEffect(() => {
        if (showDelivery && !showPickup && !show20) {
            setTabMessage("Eat My Lunch Delivery is available for all locations")
        }
        else if (showDelivery && showPickup && !show20) {
            setTabMessage("Eat My Lunch does not deliver to all locations")
        }
        else if (!showDelivery && showPickup && show20) {
            setTabMessage("Schedule locations cannot be delivered to")
        }
    }, [deliverableAddresses])

    useEffect(() => {
        if (shakeTable) {
            setTimeout(() => {
                setShakeTable(false)
            }, 500);
        }
    }, [shakeTable])

    return (
        <div>
            {showOrderError && (
                <Snackbar
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "center",
                    }}
                    open={showOrderError}
                    autoHideDuration={5000}
                    onClose={() => setShowOrderError(false)}
                >
                    <MuiAlert severity="warning">Set Meal Preference or Untick from Order</MuiAlert>
                </Snackbar>
            )}
            {
                showDeliveryConfirmation ?
                    <DeliveryConfirmation
                        modalState={showDeliveryConfirmation}
                        handleClose={() => setShowDeliveryConfirmation(false)}
                        deliveryAddress={deliveryAddress.address}
                        deliveryTime={new Date(deliveryAddress.startTime)}
                        deliveryContact={deliveryContact}
                        checkedPeople={checkedPeople}
                        allPeople={people}
                        scheduleId={props.scheduleId}
                        scheduleDate={props.scheduleDate}
                        closeCaterPopup={() => {
                            props.handleModal()
                            props.reloadSchedule()
                        }}
                        editSchedule={props.editSchedule}
                        schedule={props.schedule}
                    />
                    : ""
            }
            {
                showPickupConfirmation ?
                    <PickupConfirmation
                        modalState={showPickupConfirmation}
                        handleClose={() => setShowPickupConfirmation(false)}
                        checkedPeople={checkedPeople}
                        pickupContact={pickupContact}
                        pickupTime={new Date(pickupTime)}
                        allPeople={people}
                        scheduleId={props.scheduleId}
                        scheduleDate={props.scheduleDate}
                        closeCaterPopup={() => {
                            props.handleModal()
                            props.reloadSchedule()
                        }}
                        editSchedule={props.editSchedule}
                        schedule={props.schedule}
                    />
                    : ""
            }
            <Modal
                open={props.modalState}
                onClose={props.handleModal}
                className={"catering-popup-modal"}
            >
                <div>
                    <AppBar position="static" style={{ background: "#217F8B" }}>
                        <Toolbar variant="dense" style={{ paddingRight: 0 }}>
                            <Typography
                                variant="subtitle1"
                                align="center"
                                style={{ flexGrow: 1 }}
                            >
                                Catering Order
                            </Typography>
                            <span style={{ display: "flex" }}>
                                <IconButton
                                    onClick={props.handleModal}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </span>
                        </Toolbar>
                    </AppBar>
                    <div className={classes.paper}>
                        <Typography>
                            <Grid container spacing={2}>
                                {
                                    props.cateringOrdered === 0 ?
                                        <React.Fragment>
                                            <AppBar position="static">
                                                <HtmlTooltip
                                                    title={
                                                        <div>
                                                            {tabMessage}
                                                        </div>
                                                    }
                                                    placement={"top"}

                                                >
                                                    <Tabs
                                                        value={tabValue}
                                                        onChange={handleTabChange}
                                                        centered
                                                    >
                                                        {tabHeader("Eat My Lunch (Delivery)", !showDelivery)}
                                                        {tabHeader("Eat My Lunch (Pickup)", !showPickup)}
                                                        {tabHeader("Pay on the Day", !show20)}
                                                        {/* {tabHeader("Donations", false)} */}
                                                    </Tabs>
                                                </HtmlTooltip>
                                            </AppBar>
                                            <TabPanel value={tabValue} index={0}>
                                                {deliverySection()}
                                                <br />
                                                {mainTables()}
                                            </TabPanel>
                                            <TabPanel value={tabValue} index={1}>
                                                {pickupSection()}
                                                <br />
                                                {mainTables()}
                                            </TabPanel>
                                            <TabPanel value={tabValue} index={2}>
                                                {/* {mainTables()} */}
                                            </TabPanel>
                                            {/* <TabPanel value={tabValue} index={3}>
                                                <Typography
                                                    variant="h3"
                                                >
                                                    {`Kids Lunches Donated: ${donationTotal.toFixed(2)}`}
                                                </Typography>
                                            </TabPanel> */}
                                        </React.Fragment>
                                        : existingOrder()
                                }
                            </Grid>
                        </Typography>
                    </div>
                </div>
            </Modal>
        </div >
    );
}

export default CateringPopUp;