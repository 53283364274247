import React, { useState } from "react";
import Snacky from "../../Shared/Snacky";
import { Skeleton } from "@material-ui/lab";
import {
  getMockBudget,
  groupByProjectId,
} from "../../../utils/BudgetItemsUtils";
import BudgetAccordion from "./BudgetAccordion";
import "./budgets.scss";
import BudgetTotalTable from "./BudgetTotalTable";
import ContractorPopUp from "../../Project/ProjectOutputs/PopUps/ContractorPopUp";

const LoadingBudgetSkeleton = () => {
  return (
    <div>
      <Skeleton />
      <Skeleton />
      <Skeleton />
    </div>
  );
};
const Budgets = ({ budget, isLoadingBudget, handleCloseProject, handleCloseAndTransfer, handleCloseProjectWithLiability }) => {
  const [snackBarProps, setSnackBarProps] = useState({
    open: false,
    severity: "success",
    text: "",
  });
  const [showHidden, setShowHidden] = useState(false);

  const filterBudget = (budgetByProject = null) => {
    if (showHidden) {
      // If showHidden is true, return all budgets
      return budgetByProject || budget;
    } else {
      return budgetByProject
        ? budgetByProject.filter((campaign) => {
            return campaign.isHidden !== 1;
          })
        : budget.filter((campaign) => {
            return campaign.isHidden !== 1;
          });
    }
  };

  let groupedBudgetsByProjectId = groupByProjectId(budget)

  return (
    <div>
      <Snacky snackprops={snackBarProps} setSnackBarProps={setSnackBarProps} />
      {isLoadingBudget ? (
        <LoadingBudgetSkeleton />
      ) : (
        <>
          {groupedBudgetsByProjectId.map((budgetByProject, index) => (
            <BudgetAccordion
              key={index}
              groupedBudgetsByProjectId={groupedBudgetsByProjectId}
              budgetByProject={budgetByProject}
              filterBudget={filterBudget}
              setSnackBarProps={setSnackBarProps}
              handleCloseProject={handleCloseProject}
              handleCloseAndTransfer={handleCloseAndTransfer}
              handleCloseProjectWithLiability={handleCloseProjectWithLiability}
            />
          ))}
          <BudgetTotalTable budget={budget}/>
        </>
      )}
    </div>
  );
};

export default Budgets;
