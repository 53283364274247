import React, { useEffect, useState } from "react";
import { Card, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Delete } from "@material-ui/icons";

import { useDropzone } from "react-dropzone";
const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box",
};
const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
  position: "relative",
};
const img = {
  display: "block",
  width: "auto",
  height: "100%",
};
const dropZoneZone = {
  padding: "3%",
  backgroundColor: "aliceblue",
  marginTop: "2%",
  fontFamily: "Roboto , Helvetica , Arial, sans-serif",
  borderColor: "#eeeeee",
  borderWidth: "2px",
  borderStyle: "dashed",
  textAlign: "center",
  padding: "10%",
  cursor: "pointer",
};

export function Previews(props) {
  const [files, setFiles] = useState(
    props.stepperImages != undefined ? props.stepperImages : []
  );
  const [jaggedFiles, setJaggedFiles] = useState([]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      setJaggedFiles([
        files,
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        ),
      ]);
    },
  });
  useEffect(() => {
    let fileArr = [];
    for (let i = 0; i < jaggedFiles.length; i++) {
      for (let j = 0; j < jaggedFiles[i].length; j++) {
        fileArr.push(jaggedFiles[i][j]);
      }
    }
    setFiles(fileArr);
  }, [jaggedFiles]);

  const StyledDelete = withStyles((theme) => ({
    root: {
      position: "absolute",
      right: "2px",
      color: "#c8c8c8",
      cursor: "pointer",
    },
  }))(Delete);

  const handleRemoveFile = (file) => {
    setFiles(files.filter((item) => item !== file));
  };

  const thumbs = files.map((file) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <StyledDelete
          onClick={() => {
            handleRemoveFile(file);
          }}
        />
        <img src={file.preview} style={img} />
      </div>
    </div>
  ));

  useEffect(
    () => () => {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  useEffect(() => {
    if (files.length > 0) {
      props.passFilesToStepper(files);
    }
  }, [files]);

  return (
    <section className="container">
      <Card {...getRootProps({ className: "dropzone" })} style={dropZoneZone}>
        <input {...getInputProps()} />
        <Typography style={{ textAlign: "center" }}>
          Drag image files here, or click to open file dialog
        </Typography>
      </Card>
    </section>
  );
}
