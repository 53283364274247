import React, { useState, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import Axios from "axios";
import {
  Grid,
  TextField,
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  DialogTitle,
  DialogActions,
  Dialog,
  Card,
  Switch,
  Tooltip,
} from "@material-ui/core";
import "./Admin.css";
import Loader from "../Loader";
import { Delete } from "@material-ui/icons";
import { format } from "date-fns";
import Snacky from "../Shared/Snacky";
import { connect } from "react-redux";

import { setSnackBarProps } from "../../actions/snackbar";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: 12,
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));

const Surveys = ({ setSnackBarProps, snackbar }) => {
  const classes = useStyles();
  const [values, setValues] = useState();
  const [surveyParams, setSurveyParams] = useState({
    projectCloseDelay: null,
    shootCloseDelay: null,
    projectClosePeriod: null,
    shootClosePeriod: null,
    queueProjectNps: null,
    queueShootNps: null,
  });
  const [scheduledSurveys, setScheduledSurveys] = useState([]);
  const [deleteTask, setDeleteTask] = useState({
    taskArray: [],
    delete: false,
  });
  const [deleteDialog, setDeleteDialog] = useState({
    id: null,
    showModal: false,
  });

  const handleChange = (event, text) => {
    if (typeof event === "boolean") {
      setSurveyParams({ ...surveyParams, [text]: event === true ? 1 : 0 });
    } else {
      setSurveyParams({ ...surveyParams, [text]: event.target.value.trim() });
    }
  };

  const saveChanges = () => {
    Axios.post(`SurveysAdmins/PostSurveysAdmin`, {
      projectCloseDelay: surveyParams.projectCloseDelay,
      shootCloseDelay: surveyParams.shootCloseDelay,
      projectClosePeriod: surveyParams.projectClosePeriod,
      shootClosePeriod: surveyParams.shootClosePeriod,
      queueProjectNps: surveyParams.queueProjectNps,
      queueShootNps: surveyParams.queueShootNps,
      dateStamp: new Date(),
    })
      .then((res) => {
        console.log(res);
        setSnackBarProps("success", "Changes saved", true);
      })
      .catch((e) => {
        console.log(e);
        setSnackBarProps("warning", "Failed to save changes", true);
      });
  };

  useEffect(() => {
    Axios.get("Nps/GetNps")
      .then((res) => {
        setScheduledSurveys(
          res.data.filter(
            (survey) => survey.taskId != null && survey.sentTime === null
          )
        );
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (deleteTask.delete) {
      deleteTask.taskArray.forEach((taskId) => {
        Axios.post(`Nps/DeleteTask/${taskId}`)
          .then((res) => {
            setSnackBarProps("success", "Scheduled survey deleted", true);
            setScheduledSurveys(
              scheduledSurveys.filter((survey) => survey.id != taskId)
            );
            setDeleteTask({ delete: false, taskArray: [] });
          })
          .catch((err) => {
            setSnackBarProps("warning", "Survey deletion failed", true);
            setDeleteTask({ delete: false, taskArray: [] });
          });
      });
    }
  }, [deleteTask.delete]);

  useEffect(() => {
    Axios.get(`SurveysAdmins/GetLastSurveysAdmin`)
      .then((res) => {
        setSurveyParams(res.data);
        setValues(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const deleteItemDialog = (survey) => {
    return (
      <Dialog
        open={deleteDialog.id === survey.id && deleteDialog.showModal === true}
        onClose={() => setDeleteDialog({})}
      >
        <DialogTitle>{"Do you want to cancel this survey?"}</DialogTitle>
        <DialogActions>
          <Button onClick={() => setDeleteDialog({})} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              setDeleteTask({ delete: true, taskArray: [survey.id] });
              setDeleteDialog({});
            }}
            color="secondary"
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const queueSwitch = (parent, switched) => (
    <div className="switch-box">
      <div className={!switched ? "text-switched" : "text-unswitched"}>
        Don't queue surveys
      </div>
      <HtmlTooltip
        title={
          <React.Fragment>
            <p>
              {!switched
                ? "If a survey falls within the period, it will not be re-scheduled"
                : "If a survey falls within the period, it will be re-scheduled"}
            </p>{" "}
          </React.Fragment>
        }
      >
        <Switch
          color="primary"
          className={"blueSwitch"}
          name="queue"
          checked={switched}
          onClick={(event) => {
            handleChange(event.target.checked, parent);
          }}
        />
      </HtmlTooltip>
      <div className={switched ? "text-switched" : "text-unswitched"}>
        Queue surveys
      </div>
    </div>
  );

  const getField = (text, value, unit, name) => (
    <div className="textfield-padding">
      <TextField
        id={text}
        variant="outlined"
        label={text}
        value={value === null ? 0 : value.toString()}
        onChange={(event) => handleChange(event, name)}
        size="small"
      />
      <Typography className="units-label">{unit}</Typography>
    </div>
  );

  const returnNZT = (utcTime) => {
    var nztTime = new Date(utcTime).setHours(new Date(utcTime).getHours() + 12);
    var timeString = format(new Date(nztTime), "dd/MM/yyyy HH:mm");
    return timeString;
  };

  if (values) {
    return (
      <main className={classes.content}>
        <div className="main-content" />
        <Typography variant="h4">Surveys</Typography>
        <Grid container>
          {snackbar.open && (
            <Snacky snackprops={snackbar} setSnackBarProps={setSnackBarProps} />
          )}
          <Grid item xs={6}>
            <Card className="surveys-card">
              <Typography align="center" className="card-title" variant="h5">
                Projects
              </Typography>
              <div className="fields-container">
                {getField(
                  "Delay by:",
                  surveyParams.projectCloseDelay,
                  "hours",
                  "projectCloseDelay"
                )}
                {getField(
                  "Limit to every: ",
                  surveyParams.projectClosePeriod,
                  "days",
                  "projectClosePeriod"
                )}
              </div>
              {queueSwitch(
                "queueProjectNps",
                surveyParams.queueProjectNps === 1
              )}
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card className="surveys-card">
              <Typography variant="h5" className="card-title" align="center">
                Shoots
              </Typography>
              <div className="fields-container">
                {getField(
                  "Delay by:",
                  surveyParams.shootCloseDelay,
                  "hours",
                  "shootCloseDelay"
                )}
                {getField(
                  "Limit to every: ",
                  surveyParams.shootClosePeriod,
                  "days",
                  "shootClosePeriod"
                )}
              </div>
              {queueSwitch("queueShootNps", surveyParams.queueShootNps === 1)}
            </Card>
            <div className="button-div">
              <Button
                color="primary"
                variant="contained"
                onClick={saveChanges}
                className="survey-save-button"
              >
                Save
              </Button>
            </div>
          </Grid>
          <Grid item xs={12}>
            <Table className="extant-surveys-table">
              <TableHead>
                <TableRow className="extant-surveys-table-head">
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Company</TableCell>
                  <TableCell>Scheduled</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Project Name</TableCell>
                  <TableCell>Delete</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {scheduledSurveys.length > 0 ? (
                  scheduledSurveys.map((survey) => {
                    return (
                      <TableRow key={survey.id}>
                        <TableCell>
                          {survey.firstName + " " + survey.lastName}
                        </TableCell>
                        <TableCell>{survey.email}</TableCell>
                        <TableCell>{survey.company}</TableCell>
                        <TableCell>{returnNZT(survey.taskRunTime)}</TableCell>
                        <TableCell>
                          {survey.type === 1 ? "Project" : "Shoot"}
                        </TableCell>
                        <TableCell>{survey.projectName}</TableCell>
                        <TableCell>
                          <IconButton>
                            <Delete
                              onClick={() => {
                                setDeleteDialog({
                                  id: survey.id,
                                  showModal: true,
                                });
                              }}
                              style={{ color: "rgba(0, 0, 0, 0.54)" }}
                            />
                          </IconButton>
                          {deleteDialog.id === survey.id &&
                            deleteDialog.showModal === true &&
                            deleteItemDialog(survey)}
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow></TableRow>
                )}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </main>
    );
  } else {
    return <Loader />;
  }
};

const mapStateToProps = (state) => ({
  snackbar: state.snackbar,
});
// https://react-redux.js.org/api/connect
export default connect(mapStateToProps, {
  setSnackBarProps,
})(Surveys);
