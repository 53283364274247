import React, { useState, useEffect } from "react";
import {
  Modal,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Paper,
  TextField,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Switch,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import Axios from "axios";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    height: "auto",
    maxHeight: "80vH",
    width: "500px",
    overflowY: "auto",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const UsersPopUp = (props) => {
  const classes = useStyles();
  const [originalReimbursementUser, setOriginalReimbursementUser] = useState();
  const [originalPreimbursementUser, setOriginalPreimbursementUser] =
    useState();
  const [reimbursementUser, setreimbursementUser] = useState();
  const [preimbursementUser, setpreimbursementUser] = useState();
  const [userList, setUserList] = useState();

  const getUserDropdown = (field) => {
    if (userList && preimbursementUser && reimbursementUser) {
      return (
        <div className="textfield-padding">
          <FormControl fullWidth>
            <InputLabel htmlFor="">{field}</InputLabel>
            <Select
              key={field}
              value={
                field === "Preimbursement"
                  ? preimbursementUser
                  : reimbursementUser
              }
              onChange={(event) => {
                if (field === "Preimbursement") {
                  setpreimbursementUser(event.target.value);
                }
                if (field === "Reimbursement") {
                  setreimbursementUser(event.target.value);
                }
              }}
              inputProps={{
                name: `User-${field}`,
                id: `user-role-popup${field}`,
              }}
            >
              {userList.map((u) => (
                <MenuItem
                  key={u.id}
                  value={u}
                >{`${u.firstName} ${u.lastName}`}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      );
    }
  };

  const handleSave = () => {
    props.handleCloseModal();
    props.changeCurrentUsers(preimbursementUser, reimbursementUser);
    Axios.put("Users/UpdateManagers", {
      preimbursementManager: preimbursementUser.id,
      reimbursementManager: reimbursementUser.id,
      originalPreimbursementManager: originalPreimbursementUser.id,
      originalReimbursementManager: originalReimbursementUser.id,
    })
      .then((res) => props.setGetReimbursementManager(false))
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    Axios.get("Users/GetUsers")
      .then(({ data }) => {
        setUserList(data);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (userList) {
      userList.map((u) => {
        if (u.canReimburse) {
          setOriginalReimbursementUser(u);
          setreimbursementUser(u);
        }
        if (u.canPreimburse) {
          setOriginalPreimbursementUser(u);
          setpreimbursementUser(u);
        }
      });
    }
  }, [userList]);

  if (userList === null) {
    return <React.Fragment></React.Fragment>;
  }
  return (
    <div>
      <Modal
        open={props.modalState}
        onClose={() => props.handleCloseModal()}
        className={classes.modal}
      >
        <div>
          <AppBar position="static" style={{ background: "#217F8B" }}>
            <Toolbar disableGutters variant="dense">
              <Typography
                variant="subtitle1"
                align="center"
                style={{ flexGrow: 1 }}
              >
                {`Change Manager`}
              </Typography>
              <IconButton
                onClick={() => props.handleCloseModal()}
                color="inherit"
              >
                <Close />
              </IconButton>
            </Toolbar>
          </AppBar>
          <div className={classes.paper}>
            {getUserDropdown("Reimbursement")}
            {getUserDropdown("Preimbursement")}
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                color="primary"
                variant="contained"
                onClick={() => handleSave()}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default UsersPopUp;
