import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Button,
} from "@material-ui/core";

const Dialogy = (props) => {
  const initialDialogProps = {
    open: false,
  };
  return (
    <Dialog
      open={props.buttonProps.show}
      onClose={() => props.setDialogProps(initialDialogProps)}
    >
      <DialogTitle style={{ textAlign: "center" }}>
        {props.buttonProps.titleText}
      </DialogTitle>
      <DialogContent>
        <DialogContentText style={{ textAlign: "center" }}>
          {props.buttonProps.contentText}
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ textAlign: "center" }}>
        <Button
          onClick={() => {
            props.handleButtonOne();
          }}
          color={props.buttonProps.buttonOneColor}
        >
          {props.buttonProps.buttonOneText}
        </Button>
        <Button
          onClick={() => {
            props.handleButtonTwo();
          }}
          color={props.buttonProps.buttonTwoColor}
        >
          {props.buttonProps.buttonTwoText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Dialogy;
