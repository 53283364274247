import React from "react";

const TikTokIcon = () => {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="34" height="34" rx="8" fill="black" />
      <path
        d="M14.7845 14.6629V13.8065C14.4824 13.7673 14.1753 13.7428 13.858 13.7428C10.0766 13.7379 7 16.7283 7 20.4089C7 22.6651 8.15812 24.662 9.93053 25.8709C8.78752 24.6816 8.08762 23.0811 8.08762 21.329C8.08762 17.7023 11.0786 14.7461 14.7845 14.6629Z"
        fill="#25F4EE"
      />
      <path
        d="M14.9507 24.3733C16.6375 24.3733 18.0172 23.0665 18.0776 21.4416L18.0827 6.92013H20.8118C20.7564 6.62158 20.7262 6.31324 20.7262 6H17.0001L16.995 20.5215C16.9346 22.1464 15.5549 23.4531 13.8681 23.4531C13.3445 23.4531 12.851 23.3259 12.4129 23.1007C12.9769 23.8691 13.9034 24.3733 14.9507 24.3733Z"
        fill="#25F4EE"
      />
      <path
        d="M25.9126 11.8487V11.0412C24.8704 11.0412 23.9035 10.7426 23.0878 10.2238C23.8129 11.0314 24.7948 11.6187 25.9126 11.8487Z"
        fill="#25F4EE"
      />
      <path
        d="M23.0878 10.2238C22.2922 9.33793 21.8138 8.18287 21.8138 6.92013H20.8169C21.0736 8.30034 21.9197 9.48477 23.0878 10.2238Z"
        fill="#FE2C55"
      />
      <path
        d="M13.8631 17.3597C12.136 17.3597 10.7311 18.7252 10.7311 20.4039C10.7311 21.5737 11.416 22.5917 12.4129 23.1007C12.0403 22.6015 11.8188 21.9897 11.8188 21.3241C11.8188 19.6453 13.2236 18.2798 14.9507 18.2798C15.273 18.2798 15.5851 18.3336 15.8772 18.4217V14.7217C15.575 14.6825 15.2679 14.658 14.9507 14.658C14.8953 14.658 14.845 14.6629 14.7896 14.6629V17.5016C14.4925 17.4135 14.1853 17.3597 13.8631 17.3597Z"
        fill="#FE2C55"
      />
      <path
        d="M25.9126 11.8487L25.9124 14.6629C23.9788 14.6629 22.1863 14.061 20.726 13.0429V20.4089C20.726 24.0845 17.6495 27.0799 13.863 27.0799C12.4028 27.0799 11.0433 26.6295 9.93053 25.8709C11.1843 27.1777 12.9717 28 14.9506 28C18.7321 28 21.8137 25.0095 21.8137 21.329V13.963C23.2739 14.9811 25.0664 15.5831 27 15.5831V11.9613C26.6223 11.9613 26.26 11.9221 25.9126 11.8487Z"
        fill="#FE2C55"
      />
      <path
        d="M20.726 20.4089V13.0429C22.1863 14.061 23.9788 14.6629 25.9124 14.6629L25.9126 11.8487C24.7948 11.6187 23.8129 11.0314 23.0878 10.2238C21.9196 9.48477 21.0787 8.30033 20.8118 6.92013H18.0827L18.0776 21.4416C18.0172 23.0665 16.6375 24.3733 14.9507 24.3733C13.9033 24.3733 12.9818 23.8692 12.4078 23.1057C11.4108 22.5917 10.726 21.5787 10.726 20.4089C10.726 18.7301 12.1309 17.3646 13.858 17.3646C14.1802 17.3646 14.4924 17.4184 14.7845 17.5065V14.6678C11.0785 14.7461 8.08762 17.7023 8.08762 21.329C8.08762 23.0811 8.78753 24.6767 9.93053 25.8709C11.0433 26.6295 12.4028 27.0799 13.863 27.0799C17.6445 27.0799 20.726 24.0845 20.726 20.4089Z"
        fill="white"
      />
    </svg>
  );
};

export default TikTokIcon;
