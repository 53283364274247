import { Collapse, IconButton, TableCell, TableRow, Typography } from "@material-ui/core";
import React, { Fragment, useState } from "react";
import { currencyFormat } from "../../../../utils/GeneralUtils";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import CustomTableRowProject from "./CustomTableRowProject";

const CustomTableRowCompany = ({ companyBills, setSnackBarProps }) => {
  const [openAllocated, setOpenAllocated] = useState(false);
  return (
    <Fragment>
      <TableRow>
        <TableCell className="client-budget-table-icon-column">
          {companyBills.projects?.length > 0 && (
            <>
              <IconButton aria-label="expand row" size="small">
                {openAllocated ? (
                  <KeyboardArrowDownIcon
                    onClick={(e) => {
                      e.stopPropagation();
                      setOpenAllocated(!openAllocated);
                    }}
                  />
                ) : (
                  <KeyboardArrowUpIcon
                    onClick={(e) => {
                      e.stopPropagation();
                      setOpenAllocated(!openAllocated);
                    }}
                  />
                )}
              </IconButton>
            </>
          )}
        </TableCell>
        <TableCell className="client-budget-table-category-column">
          <strong>{companyBills.companyName}</strong>
        </TableCell>
        <TableCell>
          {currencyFormat(Number(companyBills.quotedByCompany))}
        </TableCell>
        <TableCell>
          {currencyFormat(Number(companyBills.allocatedByCompany))}
        </TableCell>
        <TableCell>
          {currencyFormat(Number(companyBills.remainingByCompany))}
        </TableCell>
      </TableRow>
      {companyBills.projects?.length > 0 &&
        openAllocated &&
        companyBills.projects.map((projectBills, indexProj) => (
          <CustomTableRowProject
            key={indexProj}
            projectBills={projectBills}
            setSnackBarProps={setSnackBarProps}
            companyId={companyBills.companyId}
          />
        ))}
    </Fragment>
  );
};

export default CustomTableRowCompany;
