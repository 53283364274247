"use strict";

import moment, { months } from "moment";

export const getCurrentTime = () => {
  let today = new Date();
  let date =
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
  let time =
    today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();

  return date + " " + time;
};

export const convertDatetimeToMysqlFormat = (date) => {
  // console.log("date");
  // console.log(date);
  let dateToUpdate = new Date(date);
  let date_ =
    dateToUpdate.getFullYear() +
    "-" +
    (dateToUpdate.getMonth() + 1) +
    "-" +
    dateToUpdate.getDate();
  let time =
    dateToUpdate.getHours() +
    ":" +
    dateToUpdate.getMinutes() +
    ":" +
    dateToUpdate.getSeconds();

  return date_ + " " + time;
};

export const convertDatetime = (date) => {
  // console.log("date");
  // console.log(date);
  let dateToUpdate = new Date(date);
  let date_ =
    dateToUpdate.getDate() +
    "-" +
    (dateToUpdate.getMonth() + 1) +
    "-" +
    dateToUpdate.getFullYear();
  let time =
    dateToUpdate.getHours() +
    ":" +
    dateToUpdate.getMinutes() +
    ":" +
    dateToUpdate.getSeconds();

  return date_ + " " + time;
};

export const getTimePortion = (dateTime) => {
  return (
    dateTime.getHours() +
    ":" +
    dateTime.getMinutes() +
    ":" +
    dateTime.getSeconds()
  );
};

export const getDatePortion = (dateString) => {
  let date = new Date(dateString);
  let date_ =
    ("0" + date.getDate()).slice(-2) +
    "/" +
    ("0" + (date.getMonth() + 1)).slice(-2) +
    "/" +
    date.getFullYear().toString().slice(2);

  return date_;
};

export const getTodaysDate = () => {
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0");
  var yyyy = today.getFullYear();
  return dd + "/" + mm + "/" + yyyy;
};

export const get12MonthsAheadAnd1MonthBackArr = () => {
  var now = moment();
  var firstDayOfMonth = moment(`${now.format("YYYY")}-${now.format("MM")}-01`)
  var lastDay = firstDayOfMonth.add(-1, 'day');
  var startDate = moment(`${lastDay.format("YYYY")}-${lastDay.format("MM")}-${lastDay.daysInMonth()}`)
  var dateArr = [];
  for (var i = 0; i < 13; i++) {
    if (i !== 0) {
      var tempDate = moment(`${startDate.format("YYYY")}-${startDate.format("MM")}-01`);
      tempDate = tempDate.add(1, 'month');
      lastDay = tempDate.daysInMonth();
      startDate = moment(`${tempDate.format("YYYY")}-${tempDate.format("MM")}-${lastDay}`)
    }
    dateArr.push({
      display: `${startDate.format("MMMM")} ${startDate.format("YYYY")}`,
      value: startDate.format("YYYY-MM-DD")
    })
  }

  return dateArr;
}
export const getLastDayCurrentMonth = () => {
  return moment().endOf('month');
}

export const get12MonthsBackAnd1MonthForwardArr = () => {
  var now = moment();
  var firstDayOfMonth = moment(`${now.format("YYYY")}-${now.format("MM")}-01`)
  var lastDay = firstDayOfMonth.add(-1, 'day');
  var startDate = moment(`${lastDay.format("YYYY")}-${lastDay.format("MM")}-${lastDay.daysInMonth()}`)
  var dateArr = [];
  for (var i = 0; i < 13; i++) {
    if (i !== 0) {
      var tempDate = moment(`${startDate.format("YYYY")}-${startDate.format("MM")}-01`);
      tempDate = tempDate.add(-1, 'month');
      lastDay = tempDate.daysInMonth();
      startDate = moment(`${tempDate.format("YYYY")}-${tempDate.format("MM")}-${lastDay}`)
    }
    dateArr.push({
      display: `${startDate.format("MMMM")} ${startDate.format("YYYY")}`,
      value: startDate.format("YYYY-MM-DD")
    })
  }

  return dateArr;
}

export const getLastDayOfPreviousMonthForDropdown = () => {
  var now = moment();
  var firstDayOfMonth = moment(`${now.format("YYYY")}-${now.format("MM")}-01`)
  var lastDay = firstDayOfMonth.add(-1, 'day');

  return {
    display: `${lastDay.format("MMMM")} ${lastDay.format("YYYY")}`,
    value: lastDay.format("YYYY-MM-DD")
  }
}

export const get3monthsBeforeAndAfterCurrentDate = () => {
  var dateArr = [];
  for (var i = -3; i < 3; i++) {
    const month = moment().clone().add(i, "months");
    dateArr.push({
      display: `${month.format("MMMM")} ${month.format("YYYY")}`,
      value: month
    })
  }

  return dateArr;
}

export const getXmonthsBeforeAndAfterCurrentDate = (numberOfMonths) => {
  var dateArr = [];
  for (var i = -numberOfMonths; i < numberOfMonths; i++) {
    const month = moment().clone().add(i, "months");
    dateArr.push({
      display: `${month.format("MMMM")} ${month.format("YYYY")}`,
      value: month
    })
  }

  return dateArr;
}

export const getXMonthsBackAnd1MonthForwardArr = (numberOfMonths) => {
  var now = moment().add(2, "month");
  var firstDayOfMonth = moment(`${now.format("YYYY")}-${now.format("MM")}-01`)
  var lastDay = firstDayOfMonth.add(-1, 'day');
  var startDate = moment(`${lastDay.format("YYYY")}-${lastDay.format("MM")}-${lastDay.daysInMonth()}`)
  var dateArr = [];
  for (var i = 0; i < numberOfMonths+2; i++) {
    if (i !== 0) {
      var tempDate = moment(`${startDate.format("YYYY")}-${startDate.format("MM")}-01`);
      tempDate = tempDate.add(-1, 'month');
      lastDay = tempDate.daysInMonth();
      startDate = moment(`${tempDate.format("YYYY")}-${tempDate.format("MM")}-${lastDay}`)
    }
    dateArr.push({
      display: `${startDate.format("MMMM")} ${startDate.format("YYYY")}`,
      value: startDate.format("YYYY-MM-DD")
    })
  }

  var forwardDate = firstDayOfMonth.add(2, "month").add(-1, "day");
  console.log(forwardDate.format("YYYY-MM-DD"));

  return dateArr;
}

export const timeAgo = (date) => {
  const now = new Date();
  const givenDate = new Date(date);
  const diffTime = Math.abs(now - givenDate);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  if (diffDays <= 28) {
    return `${diffDays} day${diffDays > 1 ? 's' : ''} ago`;
  } else {
    const diffMonths = Math.ceil(diffDays / 30);
    return `${diffMonths} month${diffMonths > 1 ? 's' : ''} ago`;
  }
}