import axios from "axios";
import { setLoaderProps } from "./loader";
import { setSnackBarProps } from "./snackbar";

export const resetActiveItemStore = (id) => async (dispatch) => {
  try {
    var res = await axios.get(`Projects/FindAnyActiveItems/${id}`);
    dispatch({
      type: "RESET_ACTIVE_ITEM_STORE",
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: "GET_ALL_PROJECT_ACTIVE_ITEMS_ERROR",
      payload: err,
    });
  }
};

export const getAllProjectActiveItems = (id) => async (dispatch) => {
  try {
    var res = await axios.get(`Projects/FindAnyActiveItems/${id}`);
    dispatch({
      type: "GET_ALL_PROJECT_ACTIVE_ITEMS",
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: "GET_ALL_PROJECT_ACTIVE_ITEMS_ERROR",
      payload: err,
    });
  }
};

export const editProjectActiveItem = (item, key, value) => async (dispatch) => {
  try {
    if (item.category === "Location Contract") {
      console.log("Location Contract");
      axios.patch(
        `ScheduleLocation/PatchScheduleLocationStatus/${item.id}`,
        {
          contractStatus: value,
        }
      );
    } else {
      axios.put(
        `ScheduleItems/UpdateContractStatusProjectClose/${item.scheduleItemId}`,
        {
          contractStatus: value,
        }
      );
    }
    dispatch({
      type: "EDIT_ACTIVE_ITEM",
      payload: { item, key, value },
    });
  } catch (err) {
    dispatch({
      type: "EDIT_PROJECT_ACTIVE_ITEM_ERROR",
      payload: err,
    });
  }
};

export const spliceProjectActiveItem = (id) => async (dispatch) => {
  console.log("Reached the action");
  try {
    dispatch({
      type: "SPLICE_ACTIVE_ITEM",
      payload: { id },
    });
  } catch (err) {
    dispatch({
      type: "SPLICE_PROJECT_ACTIVE_ITEM_ERROR",
      payload: err,
    });
  }
};

export const closeProjectAndSendAllInvoices = (items, sender) => async (dispatch) => {
  var reqArray = [];
  for (let i = 0; i < items.length; i++) {
    if (items[i].contractStatus === "Signed") {
      if (items[i].scheduleItemId !== null) {
        reqArray.push(
          axios.post(
            `Mail/SendInvoice/${items[i].personFk}/${items[i].scheduleItemId}`, 
            sender
          )
        );
      } else if (
        items[i].scheduleItemId === null &&
        items[i].contractStatus !== "Accepted"
      ) {
        reqArray.push(
          axios.post(
            `Mail/SendLocationInvoice/${items[i].scheduleFk}/${items[i].locationId}`,
            sender
          )
        );
      }
    } else if (items[i].contractStatus === "Accepted") {
      reqArray.push(
        axios.get(`Catering/UpdateToInvoiced/${items[i].scheduleFk}`)
      );
    }
  }
  axios
    .all(reqArray)
    .then((res) => {
      dispatch(setLoaderProps(false));
      dispatch(setSnackBarProps("success", "Invoices Sent", true));
    })
    .catch((res) => {
      dispatch(setLoaderProps(false));
      dispatch(setSnackBarProps("warning", "Failed to send Invoices", true));
    });
};
