import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import {
  getOutputsForCalendar,
  getOutputsForCalendarMonthView,
} from "../../../actions/outputs";
import format from "date-fns/format";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import getDay from "date-fns/getDay";
import "./calendarScss/styles.scss";
import { Button } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";

const locales = {
  enNZ: require("date-fns/locale/en-NZ"),
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const CalendarView = (props) => {
  let {
    getOutputsForCalendar,
    calendarOutputs,
    getOutputsForCalendarMonthView,
    calendarOutputsMonthView,
  } = props;
  const [viewVar, setViewVar] = useState("month");
  const [showOutputs, setShowOutputs] = useState(false);
  const [eventSource, setEventSource] = useState(calendarOutputsMonthView);
  const history = useHistory();
  const location = useLocation();
  useEffect(() => {
    getOutputsForCalendar();
    getOutputsForCalendarMonthView();
  }, []);

  useEffect(() => {
    if (viewVar === "month") {
      setEventSource(calendarOutputsMonthView);
    } else if (viewVar === "week") {
      setEventSource(calendarOutputs);
    } else if (viewVar === "day") {
      setEventSource(calendarOutputs);
    }
    console.log(viewVar);
  }, [viewVar, calendarOutputs]);

  // useEffect(() => {
  //   setViewVar(location.pathname.split("/")[1]);
  // }, [location.pathname]);
  // Use value of viewVar to drive switching eventSource
  const onViewChange = (newView) => {
    setViewVar(newView);
    //history.push(viewVar);
  };

  const returnRandomColor = (event) => {};

  // This event handler can drive changes to the event object - for example showing / hiding the outputs.
  const handleSelectEvent = (eV, seV) => {
    if (viewVar === "month") {
      history.push(`/project/home/${eV.id}/0`);
    } else if (viewVar !== "month" && eV.projectId !== null) {
      history.push(`/project/home/${eV.projectId}/0`);
    }
  };

  const MonthViewEvent = (event) => {
    return (
      <span>
        <strong className="event-title">{event.title}</strong>
        <ul className={showOutputs ? "show-outputs" : "hide-outputs"}>
          {event.event.outputs.map((projOP, index) => {
            return <li key={projOP.id}>{projOP}</li>;
          })}
        </ul>
      </span>
    );
  };

  const WeekViewEvent = (event) => {
    return (
      <span>
        <strong>{event.title}</strong>
      </span>
    );
  };

  const eventStyleGetter = (e, s, en, i) => {
    // var randomColor = "#000000".replace(/0/g, function () {
    //   return (~~(Math.random() * 16)).toString(16);
    // });
    var style = {
      backgroundColor: e?.hex,
      borderRadius: "5px",
      opacity: 0.8,
      color: "white",
      border: "0px",
      display: "block",
    };
    return {
      style: style,
    };
  };
  if (calendarOutputsMonthView.length > 0) {
    return (
      <div>
        <Button onClick={() => setShowOutputs(!showOutputs)}>
          Show Outputs
        </Button>
        <Calendar
          localizer={localizer}
          events={eventSource}
          startAccessor="start"
          endAccessor="end"
          view={viewVar}
          onView={onViewChange}
          onSelectEvent={handleSelectEvent}
          eventPropGetter={eventStyleGetter}
          style={{ height: "100vh", margin: 10 }}
          components={{
            event: viewVar === "month" ? MonthViewEvent : WeekViewEvent,
          }}
        />
      </div>
    );
  } else {
    return <div>Hello from calendar</div>;
  }
};

const mapStateToProps = (state) => ({
  calendarOutputs: state.calendarOutputs.calendarOutputs,
  calendarOutputsMonthView: state.calendarOutputs.calendarOutputsMonthView,
});
// https://react-redux.js.org/api/connect
export default connect(mapStateToProps, {
  getOutputsForCalendar,
  getOutputsForCalendarMonthView,
})(CalendarView);
