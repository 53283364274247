import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { Close as CloseIcon } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import ProjectsAutocomplete from "../../Shared/Projects/ProjectsAutocomplete";

const TimeTrackerAddProjectDialog = ({ open, handleClose, handleSubmit }) => {
  const [project, setProject] = useState(null);

  const handleChangeProject = (project) => {
    setProject(project);
  };
  return (
    <Dialog
      open={open}
      maxWidth="sm"
      fullWidth
      className="default-dialog"
      onClose={(e, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
    >
      <div className="close-icon-container">
        <IconButton
          size="small"
          onClick={() => {
            handleClose();
          }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </div>
      <DialogTitle>
        <Typography className="default-description">
          Select a project to be added.
        </Typography>
      </DialogTitle>
      <DialogContent>
        <ProjectsAutocomplete
          handleOnChange={handleChangeProject}
          value={project?.id}
        />
      </DialogContent>
      <DialogActions style={{ textAlign: "center" }}>
        <Button onClick={handleClose} className="default-button-grey">
          Cancel
        </Button>
        <Button
          className="default-button"
          disabled={!project}
          onClick={() => {
            handleSubmit(project);
          }}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TimeTrackerAddProjectDialog;
