import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import {
    ButtonGroup,
    Grid,
    Grow,
    Button,
    Popper,
    Paper,
    MenuList,
    MenuItem,
    ClickAwayListener,
} from "@material-ui/core";
import {
    Close as CloseIcon,
    ArrowDropDown as ArrowDropDownIcon
} from "@material-ui/icons"
import {
    editScheduleItem,
    cancelInvoice,
} from "../../../../actions/scheduleItems";
import { setSnackBarProps } from "../../../../actions/snackbar";
import { contains } from "../../../../utils/utils";
import ConfirmationDialog from "../../../Shared/ConfirmationDialog";
import RolloverPopup from "./RolloverPopup";
import { useUserContext } from "../../../../context/user/UserContext";
import { useAuth } from "../../../../context/auth/AuthContext";

const changeButton = (contractStatus) => {
    switch (contractStatus) {
        case "Invoiced":
            return { color: "#008000", options: ["Invoiced", "Roll Over"] };
            break;
    }
};

const RolloverButton = (props) => {
    const { user, } = useAuth();
    const { projectId, si, projectName } = props;
    const [checked, setChecked] = useState(true);
    const [open, setOpen] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [buttonProps, setButtonProps] = useState(
        changeButton(si.contractStatus)
    );
    const [openRolloverPopup, setOpenRolloverPopup] = useState(false);
    const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

    const anchorRef = useRef(null);

    useEffect(() => {
        setButtonProps(changeButton(si.contractStatus));
    }, [si.contractStatus]);

    const handleMenuItemClick = (event, index) => {
        if (buttonProps.options[index] === "Roll Over") {
            setOpenRolloverPopup(true);
        }
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        // if (anchorRef.current && anchorRef.current.contains(event.target)) {
        if (anchorRef.current && contains(anchorRef.current, event.target)) {
            return;
        }
        setOpen(false);
    };

    return (
        <React.Fragment>
            {openConfirmationDialog && (
                <ConfirmationDialog
                    show={openConfirmationDialog}
                    titleText="Success"
                    contentText="An invoice has been sent to the agent. This invoice will be visable in the Pre/Post section."
                    handleClose={() => setOpenConfirmationDialog(false)}
                    buttonText="Ok"
                />
            )}
            {openRolloverPopup && (
                <RolloverPopup
                    open={openRolloverPopup}
                    handleClose={() => { setOpenRolloverPopup(false) }}
                    projectId={projectId}
                    si={si}
                    setOpenConfirmationDialog={setOpenConfirmationDialog}
                    projectName={projectName}
                />
            )}
            <Grow in={checked} mountOnEnter unmountOnExit>
                <Grid container alignItems="center">
                    <Grid item xs={12}>
                        <ButtonGroup
                            disabled={props.disabled !== undefined ? props.disabled : false}
                            variant="contained"
                            // color="secondary"
                            ref={anchorRef}
                            aria-label="split button"
                            size="small"
                            style={{
                                // width: "100px",
                                backgroundColor: buttonProps.color,
                            }}
                            className="contract-button-group"
                        >
                            <Button
                                //onClick={handleClick}
                                style={{
                                    padding: "0px 0px 0px 0px",
                                    width: "80%",
                                    backgroundColor: buttonProps.color,
                                    color: "white",
                                }}
                            >
                                <span className="contract-button-text">{buttonProps.options[selectedIndex]}</span>
                            </Button>
                            <Button
                                //color="secondary"
                                size="small"
                                aria-controls={open ? "split-button-menu" : undefined}
                                aria-expanded={open ? "true" : undefined}
                                aria-label="select declined option"
                                aria-haspopup="menu"
                                onClick={handleToggle}
                                style={{
                                    width: "20%",
                                    backgroundColor: buttonProps.color,
                                    color: "white",
                                }}
                            >
                                <ArrowDropDownIcon />
                            </Button>
                        </ButtonGroup>
                        <Popper
                            open={open}
                            anchorEl={anchorRef.current}
                            role={undefined}
                            transition
                            disablePortal
                            style={{
                                zIndex: "999",
                            }}
                        >
                            {({ TransitionProps, placement }) => (
                                <Grow
                                    {...TransitionProps}
                                    style={{
                                        transformOrigin:
                                            placement === "bottom" ? "center top" : "center bottom",
                                    }}
                                >
                                    <Paper>
                                        <ClickAwayListener onClickAway={handleClose}>
                                            <MenuList id="split-button-menu">
                                                {buttonProps.options.map(
                                                    (option, index) =>
                                                        index > 0 && (
                                                            <MenuItem
                                                                key={option}
                                                                disabled={index === 0}
                                                                selected={index === selectedIndex}
                                                                onClick={(event) =>
                                                                    handleMenuItemClick(event, index)
                                                                } //console.log(event)}
                                                            >
                                                                {option}
                                                            </MenuItem>
                                                        )
                                                )}
                                            </MenuList>
                                        </ClickAwayListener>
                                    </Paper>
                                </Grow>
                            )}
                        </Popper>
                    </Grid>
                </Grid>
            </Grow>
        </React.Fragment>
    );
}

const mapStateToProps = (state, ownProps) => ({
    snackbar: state.snackbar,
});
export default connect(mapStateToProps, {
    editScheduleItem,
    setSnackBarProps,
    cancelInvoice,
})(RolloverButton);