import moment from "moment";

// Utility function for data processing
export const processTimeEntries = (timeEntriesByProjects, datesToDisplay) => {
  return timeEntriesByProjects.reduce((acc, project) => {
    acc[project.projectId] = datesToDisplay.reduce((dateAcc, date) => {
      const formattedDate = moment(date).format("YYYY-MM-DD");
      const timeEntry = project.timeEntries.find(
        (entry) => moment(entry.date).format("YYYY-MM-DD") === formattedDate
      );
      dateAcc[formattedDate] = timeEntry
        ? {
            timeEntryId: timeEntry.id,
            timeSpentInMinutes: timeEntry.timeSpentInMinutes,
            description: timeEntry.description,
          }
        : { timeSpentInMinutes: "", description: "" };
      return dateAcc;
    }, {});
    return acc;
  }, {});
};

export const minutesToHHMM = (totalMinutes) => {
  if (
    typeof totalMinutes !== "number" ||
    isNaN(totalMinutes) ||
    totalMinutes < 0
  ) {
    return "";
  }
  const flooredMinutes = Math.floor(totalMinutes);
  const hours = Math.floor(flooredMinutes / 60);
  const minutes = flooredMinutes % 60;
  return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
    2,
    "0"
  )}`;
};

export const HHmmToMinutes = (hhmm) => {
  if (!hhmm || typeof hhmm !== "string" || !validateTimeFormat(hhmm)) {
    return 0;
  }
  const [hours, minutes] = hhmm.split(":").map(Number);
  return hours * 60 + minutes;
};

export const validateTimeFormat = (value) => {
  const timePattern = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;
  return timePattern.test(value);
};
export const handleInputRestriction = (e) => {
  const allowedKeys = /[0-9:]/;
  if (!allowedKeys.test(e.key)) {
    e.preventDefault();
  }
};

export const calculateTotalTimeSpentInMinutes = (timeEntries) => {
  return timeEntries.reduce((projectTotal, entry) => {
    // Add the entry's timeSpentInMinutes to the projectTotal if it's not null
    return projectTotal + (entry.timeSpentInMinutes || 0);
  }, 0);
};

export const calculateTimeSpentByDate = (data) => {
  return data.reduce((dateMap, project) => {
    project.timeEntries.forEach((entry) => {
      if (entry.date && entry.timeSpentInMinutes) {
        const formattedDate = entry.date.split("T")[0]; // Extract date in YYYY-MM-DD format

        if (!dateMap[formattedDate]) {
          dateMap[formattedDate] = 0; // Initialize the date entry in the map
        }

        dateMap[formattedDate] += entry.timeSpentInMinutes; // Accumulate the minutes for this date
      }
    });

    return dateMap;
  }, {});
};
