import React, { useState, useEffect } from "react";
import { Typography, TextField, Button, Container } from "@material-ui/core";
import { Autocomplete, Skeleton } from "@material-ui/lab";
import Header from "../../../Header";
import axios from "axios";
import moment from "moment";
import { getXMonthsBackAnd1MonthForwardArr } from "../../../../utils/TimeHelpers";
import "./mediaMasterPage.scss";
import MediaMasterPageClientRow from "./MediaMasterPageClientRow";
import AddMonthlySpendToSheetDialog from "../../../Admin/ReconCentre/ReconRecords/AddMonthlySpendToSheetDialog";
import Snacky from "../../../Shared/Snacky";

const MediaMasterPage = (props) => {
  const [selectedBillingMonth, setSelectedBillingMonth] = useState(moment());
  const [clientsWithMediaInvoices, setClientsWithMediaInvoices] = useState([]);
  const [fetchingMediaInvoices, setFetchingMediaInvoices] = useState(false);
  const [openGenerateMediaSpendSheet, setOpenGenerateMediaSpendSheet] =
    useState(false);
  const [snackBarProps, setSnackBarProps] = useState({
    open: false,
    severity: "success",
    text: "",
  });

  const fetchMediaInvoices = () => {
    setFetchingMediaInvoices(true);
    axios
      .get(
        `MediaClients/GetDataForMasterMediaPage/${selectedBillingMonth.format(
          "M"
        )}/${selectedBillingMonth.format("YYYY")}`
      )
      .then(({ data }) => {
        setClientsWithMediaInvoices(data);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setFetchingMediaInvoices(false);
      });
  };

  useEffect(() => {
    fetchMediaInvoices();
  }, [selectedBillingMonth]);

  const populateMediaSpendSheet = (selectedBillingMonth) => {
    axios.post(
      `Reconciliation/PopulateMediaSpendReconciliationSheet/${
        selectedBillingMonth.month() + 1
      }/${selectedBillingMonth.year()}`
    );
    displayConfirmation();
  };
  const displayConfirmation = () => {
    setSnackBarProps({
      open: true,
      severity: "info",
      text: "You request has been done, please check the sheet in a few minutes.",
    });
    handleCloseOpenGenerateMediaSpendDialog();
  };

  const handleCloseOpenGenerateMediaSpendDialog = () => {
    setOpenGenerateMediaSpendSheet(false);
  };

  return (
    <div id="master-media-page-root">
      <Container style={{ display: "grid", gap: 20 }} >
        <Snacky
          snackprops={snackBarProps}
          setSnackBarProps={setSnackBarProps}
        />
        {openGenerateMediaSpendSheet && (
          <AddMonthlySpendToSheetDialog
            open={openGenerateMediaSpendSheet}
            handleClose={handleCloseOpenGenerateMediaSpendDialog}
            populateMediaSpendSheet={populateMediaSpendSheet}
          />
        )}
        <div className="master-media-filter-container">
          <Autocomplete
            value={
              selectedBillingMonth
                ? {
                    display: selectedBillingMonth.format("MMMM YYYY"),
                    value: selectedBillingMonth,
                  }
                : null
            }
            options={getXMonthsBackAnd1MonthForwardArr(11)}
            onChange={(event, option) => {
              setSelectedBillingMonth(moment(option.value));
            }}
            getOptionLabel={(option) => option.display}
            renderInput={(params) => (
              <TextField
                {...params}
                label={"Month"}
                size="small"
                variant="outlined"
              />
            )}
            disableClearable
          />
          <Button
            onClick={(e) => setOpenGenerateMediaSpendSheet(true)}
            className="default-button"
          >
            Add Monthly Spend to Sheet
          </Button>
        </div>
        <div>
          {fetchingMediaInvoices && (
            <>
              <Skeleton height={50} />
              <Skeleton height={50} />
              <Skeleton height={50} />
            </>
          )}
          {clientsWithMediaInvoices.map((c) => {
            return (
              <div style={{ paddingTop: 10 }}>
                <MediaMasterPageClientRow client={c} />
              </div>
            );
          })}
        </div>
      </Container>
    </div>
  );
};

export default MediaMasterPage;
