import React, { Fragment } from "react";
import { Grid, IconButton, TextField } from "@material-ui/core";
import { filterNumbersOnlyInput } from "../../../../utils/GeneralUtils";
import {
    Delete as DeleteIcon
} from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";

const SplitBillRow = ({
    payable,
    updatePayable,
    removePayable,
    companies
}) => {
    return (
        <Fragment>
            <Grid item xs={4} md={4} lg={4} className="default-text-field-container">
                <Autocomplete
                    options={companies}
                    value={companies.find((c) => c.id === payable?.companyId)}
                    getOptionLabel={(option) => option.name}
                    onChange={(e, newValue) => {
                        updatePayable({
                            ...payable,
                            companyId: newValue ? newValue.id : null
                        })
                    }}
                    selectOnFocus
                    renderOption={(option) => {
                        return <li key={option.id}>{option.name}</li>;
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Client"
                            variant="filled"
                            size="small"
                            fullWidth={true}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={4} md={4} lg={4} className="default-text-field-container">
                <TextField
                    label="Bill Reference"
                    variant="filled"
                    fullWidth
                    value={payable.transactionId}
                    onChange={(e) => updatePayable({
                        ...payable,
                        transactionId: e.target.value
                    })}
                    size="small"
                />
            </Grid>
            <Grid item xs={3} md={3} lg={3} className="default-text-field-container">
                <TextField
                    label="Bill Amount"
                    variant="filled"
                    fullWidth
                    value={payable.total}
                    onChange={(e) => {
                        updatePayable({
                            ...payable,
                            total: filterNumbersOnlyInput(e.target.value)
                        })
                    }}
                    size="small"
                />
            </Grid>
            <Grid item xs={1} md={1} lg={1} className="default-text-field-container">
                <IconButton size="small" onClick={() => removePayable(payable.recordId)}>
                    <DeleteIcon />
                </IconButton>
            </Grid>
        </Fragment>
    )
}

export default SplitBillRow;