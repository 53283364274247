import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import HomeTable from "./HomeTable";

const MainHome = () => {
  let history = useHistory();
  const [showHomeTable, setShowHomeTable] = useState(true);
  const [showInternalProjects, setShowInternalProjects] = useState(false);
  const urlPathName = window.location.hostname;
  const tsUrl = "hub.thompsonspencer.com";

  useEffect(() => {
    // Check if the URL contains "hub.thompsonspencer.com"
    if (urlPathName?.toLowerCase().includes(tsUrl)) {
      // If it does, redirect to "/v2/clients"
      history.push("/v2/clients");
    }
  }, [urlPathName, history]);

  if (urlPathName?.toLowerCase().includes(tsUrl)) {
    return <></>;
  } else {
    // Otherwise, render the HomeBoard component
    return (
      <HomeTable
        setShowHomeTable={setShowHomeTable}
        setShowInternalProjects={setShowInternalProjects}
        showInternalProjects={showInternalProjects}
      />
    );
  }
};

export default MainHome;
