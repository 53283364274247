import {
  AppBar,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Toolbar,
  Typography,
} from "@material-ui/core";
import React from "react";
import CurrencyExchangeForm from "./CurrencyExchangeForm";
import CloseIcon from "@material-ui/icons/Close";
const CurrencyExchangeDialog = ({
  open,
  handleClose,
  handleSubmit,
  currency,
  foreignAmount,
  nzdAmount,
  lockDropdown
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="xs"
      PaperProps={{
        style: {
          backgroundColor: "#ededed",
        },
      }}
    >
      <AppBar position="static" style={{ background: "#217F8B" }}>
        <Toolbar variant="dense" style={{ paddingRight: 0 }}>
          <Typography
            variant="subtitle1"
            align="center"
            style={{ flexGrow: 1 }}
          >
            Currency Exchange
          </Typography>
          <span style={{ display: "flex" }}>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </span>
        </Toolbar>
      </AppBar>
      <DialogContent style={{ padding: "24px 24px" }}>
        <CurrencyExchangeForm
          handleSubmit={handleSubmit}
          currency={currency}
          foreignTotal={foreignAmount}
          nzdTotal={nzdAmount}
          lockDropdown={lockDropdown}
        />
      </DialogContent>
    </Dialog>
  );
};

export default CurrencyExchangeDialog;
