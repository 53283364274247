import React from "react";
import { Grid, TextField, Fab, makeStyles } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import PersonAddIcon from "@material-ui/icons/PersonAdd";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    //marginTop: "30px"
  },
  button: {
    marginRight: theme.spacing(1),
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  completed: {
    display: "inline-block",
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  addAgentFab: {
    background: "#00b2e2",
    color: "white",
    marginBottom: "auto",
    marginLeft: "auto",
    marginRight: "0px",
    marginTop: "auto",
    minWidth: "40px",
    maxWidth: "40px",
    "&:hover": {
      background: "#008cb3",
    },
  },
}));

export const AgentAutoComplete = (props) => {
  const classes = useStyles();
  let {
    agentList,
    agent,
    changeAgent,
    setShowAddAgentContact,
    addAgentPopUpOpened,
  } = props;

  return (
    <Grid container spacing={1}>
      <Grid item xs={11} md={11} lg={11}>
        <Autocomplete
          clearOnEscape
          options={agentList}
          getOptionLabel={(option) =>
            option.companyFkNavigation !== null
              ? `${option.firstName} ${option.lastName} - ${option.companyFkNavigation.name}`
              : `${option.firstName} ${option.lastName} - No Company`
          }
          value={agent}
          style={{
            width: "auto",
          }}
          size="small"
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Agent *"
              variant="outlined"
              fullWidth
              size="small"
            />
          )}
          onChange={changeAgent}
        />
      </Grid>
      <Grid item xs={1} md={1} lg={1}>
        <Fab
          size="small"
          className={classes.addAgentFab}
          onClick={() => {
            setShowAddAgentContact(true);
            addAgentPopUpOpened();
          }}
        >
          <PersonAddIcon />
        </Fab>
      </Grid>
    </Grid>
  );
};
