import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
    Modal,
    AppBar,
    Toolbar,
    Typography,
    IconButton,
    CircularProgress,
    Card,
    Button
} from "@material-ui/core";
import { Close as CloseIcon, OpenInBrowser as OpenInBrowserIcon } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { setDialogProps } from "../../../actions/dialog";

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflow: "hidden",
    },
    paper: {
        backgroundColor: "#fefefe", //theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 2, 3),
        height: "80vH",
        maxHeight: "80vH",
        width: "1000px",
        overflowY: "auto",
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const DocumentViewer = (props) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(true);
    const [docType, setDocType] = useState(props.docType);

    const imageLoaded = () => {
        setLoading(false);
    };

    return (
        <Modal
            open={props.modalState}
            onClose={() => props.handleCloseModal()}
            className={classes.modal}
        >
            <div>
                <AppBar position="static" style={{ background: "#217F8B" }}>
                    <Toolbar variant="dense" style={{ paddingRight: 0 }}>
                        <Typography
                            variant="subtitle1"
                            align="center"
                            style={{ flexGrow: 1 }}
                        >
                            Document
                        </Typography>
                        <span style={{ display: "flex" }}>
                            <IconButton
                                variant="contained"
                                onClick={() => {window.open(`https://drive.google.com/uc?id=${props.driveId}&export=view`)}}
                            >
                                <OpenInBrowserIcon />
                            </IconButton>
                            <IconButton
                                onClick={() => props.handleCloseModal()}
                                color="inherit"
                                style={{ color: "black" }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </span>
                    </Toolbar>
                </AppBar>
                <div className={classes.paper}>
                    <div style={{ display: !loading && "none" }}>
                        <h1 className="loading-dots" style={{ display: "flex", justifyContent: "center" }}><span>.</span><span>.</span><span>.</span></h1>
                    </div>
                    {docType === "Image" && (
                        <Card
                            elevation={9}
                            style={{
                                display: loading && "none",
                                width: "100%",
                                display: "flex",
                                maxHeight: "80vH"
                            }}
                        >
                            <div>
                                <img
                                    src={`https://drive.google.com/file/d/${props.driveId}/preview`}
                                    className={"document-image"}
                                    onLoad={imageLoaded}
                                    onError={() => setDocType("PDF")}
                                />
                            </div>
                        </Card>
                    )}
                    {docType === "PDF" && (
                        <div style={{ display: loading && "none", width: "100%", height: "100%" }}>
                            <iframe
                                src={`https://drive.google.com/file/d/${props.driveId}/preview`}
                                className={"document-image"}
                                width={"100%"}
                                height={"100%"}
                                onLoad={imageLoaded}
                                allow="clipboard-read; clipboard-write"
                            ></iframe>
                        </div>
                    )}
                </div>
            </div>
        </Modal >
    );
};

const mapStateToProps = (state, ownProps) => ({
    dialog: state.dialog,
});
export default connect(mapStateToProps, {
    setDialogProps,
})(DocumentViewer);
