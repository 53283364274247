import React, { useEffect, useState } from "react";
import ReconciliationActionButton from "./ReconciliationActionButton";
import ReconciliationTotals from "./ReconciliationTotals";
import ReconcileReceiptNoProject from "../ReconcileReceipt/ReconcileReceiptNoProject";
import ReconcileReceiptBudgetTable from "../ReconcileReceipt/ReconcileReceiptBudgetTable";
import ReconcilePurchaseOrder from "../PurchaseOrder/ReconcilePurchaseOrder";
import ReconcileMediaUndersAndOvers from "../ReconcileMedia/ReconcileMediaUndersAndOvers";

const ReconciliationContainer = ({
  reconLines,
  mediaSuppliers,
  companies,
  setSnackBarProps,
  payable,
  selectedReconLines,
  setSelectedReconLines,
  matchAndCloseMediaBill,
  matchAndCloseReceipt,
  matchAndClosePo,
  addToReconLines,
  saveMediaReconciliationLines
}) => {
  const [allocatedAmount, setAllocatedAmount] = useState(0);

  useEffect(() => {
    calculateAllocatedAmount();
  }, [selectedReconLines]);

  const calculateAllocatedAmount = () => {
    if (selectedReconLines && selectedReconLines.length > 0) {
      return setAllocatedAmount(
        selectedReconLines.reduce((a, b) => a + (b.amount || 0), 0)
      );
    } else {
      return setAllocatedAmount(0);
    }
  };
  const handleMatchAndCloseButton = () => {
    if (payable.recordType === "MEDIA") {
      saveMediaReconciliationLines(payable)
      // matchAndCloseMediaBill(payable);
    } else if (payable.recordType === "RECEIPT") {
      matchAndCloseReceipt(payable);
    } else if (payable.recordType === "PO") {
      matchAndClosePo(payable);
    }
  };

  const handleUpdateSelectedReconLines = (
    recordId,
    amount,
    accountCode = null
  ) => {
    const index = selectedReconLines.findIndex((s) => s.id === recordId);
    const updatedRecord = {
      id: recordId,
      amount: Number(amount),
      accountCode: accountCode,
    };

    if (index !== -1) {
      const updatedReconLines = [...selectedReconLines];
      updatedReconLines[index] = updatedRecord;
      setSelectedReconLines(updatedReconLines);
    } else {
      setSelectedReconLines([...selectedReconLines, updatedRecord]);
    }
  };

  const handleRemoveSelectedReconLine = (recordId) => {
    setSelectedReconLines(selectedReconLines.filter((s) => s.id !== recordId));
  };

  return (
    <div className="reconciliation-container-root">
      {/* {payable?.recordType === "MEDIA" && (
        <ReconcileMediaTable
          reconLines={reconLines}
          mediaSuppliers={mediaSuppliers}
          companies={companies}
          setSnackBarProps={setSnackBarProps}
          handleUpdateSelectedReconLines={handleUpdateSelectedReconLines}
          handleRemoveSelectedReconLine={handleRemoveSelectedReconLine}
        />
        
      )} */}
      {payable?.recordType === "MEDIA" && (
        <ReconcileMediaUndersAndOvers
          reconLines={reconLines}
          payable={payable}
          handleUpdateSelectedReconLines={handleUpdateSelectedReconLines}
          handleRemoveSelectedReconLine={handleRemoveSelectedReconLine}
        />
      )}
      {payable?.recordType === "RECEIPT" && (
        <ReconcileReceiptBudgetTable
          payable={payable}
          setSnackBarProps={setSnackBarProps}
          handleUpdateSelectedReconLines={handleUpdateSelectedReconLines}
          handleRemoveSelectedReconLine={handleRemoveSelectedReconLine}
        />
      )}
      {payable?.recordType === "PO" && (
        <ReconcilePurchaseOrder
          payable={payable}
          reconLines={reconLines}
          setSnackBarProps={setSnackBarProps}
          handleUpdateSelectedReconLines={handleUpdateSelectedReconLines}
          handleRemoveSelectedReconLine={handleRemoveSelectedReconLine}
          addToReconLines={addToReconLines}
        />
      )}
      {(payable?.recordType === "RECEIPT" ||
        (payable?.recordType === "PO" && payable?.companyId == null)) && (
        <ReconcileReceiptNoProject
          payable={payable}
          setSnackBarProps={setSnackBarProps}
          handleUpdateSelectedReconLines={handleUpdateSelectedReconLines}
          handleRemoveSelectedReconLine={handleRemoveSelectedReconLine}
        />
      )}
      <ReconciliationTotals
        payable={payable}
        allocatedAmount={allocatedAmount}
      />
      <ReconciliationActionButton
        recordType={payable?.recordType}
        handleSubmit={handleMatchAndCloseButton}
        payableAmount={Number(payable?.subtotal)}
        allocatedAmount={allocatedAmount}
      />
    </div>
  );
};

export default ReconciliationContainer;
