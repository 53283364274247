import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Paper,
  IconButton,
  TextField,
  InputAdornment,
  Button,
  Grid,
  Menu,
  MenuItem,
  GridListTile,
  GridListTileBar,
  GridList,
  Slider,
  CircularProgress
} from "@material-ui/core";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import LastPageIcon from "@material-ui/icons/LastPage";
import FilterListIcon from "@material-ui/icons/FilterList";
import InfoIcon from "@material-ui/icons/Info";
import AddIcon from "@material-ui/icons/Add";
import {
  Clear
} from "@material-ui/icons";
import { set } from "date-fns/esm";
import Slideshow from "./SlideShow";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
    height: "100%"
  },
  gridList: {
    width: "100%",
    height: "100%",
    maxHeight: 380
  },
  icon: {
    color: "rgba(255, 255, 255, 0.54)"
  }
}));

const LocationsTable = props => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [searchText, setSearchText] = React.useState("");

  const [addressFilter, setAddressFilter] = React.useState("");

  const [filterDisplays, setFilterDisplays] = React.useState({
    showAddressFilter: false,
    showPriceFilter: false
  });
  const { showAddressFilter, showPriceFilter } = filterDisplays;

  const [allLocations, setAllLocations] = React.useState([]);
  const [filteredLocations, setFilteredLocations] = React.useState([]);
  const [updatedLocations, setUpdatedLocations] = React.useState(false);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const maxPrice = 1500;
  const minPrice = 0;
  const [priceRange, setPriceRange] = React.useState([0, 1500]);

  const [loadingImages, setLoadingImages] = React.useState(false);

  const handleAnchorClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleAnchorClose = () => {
    setAnchorEl(null);
  };

  //const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = event => {
    setSearchText(event.target.value);
    searchTable(event.target.value);
    setPage(0);
  };

  const handleLocationFiltersChange = filter => event => {
    //setLocationFilters({ ...locationFilters, [filter]: event.target.value });
    setAddressFilter(event.target.value);
    setPage(0);
  };

  const handlePriceRangeChange = (event, newValue) => {
    setPriceRange(newValue);
  };

  const toogleAddressFilter = () => {
    if (showAddressFilter) {
      setFilterDisplays({ ...filterDisplays, ["showAddressFilter"]: false });
      setAddressFilter("");
    } else {
      setFilterDisplays({ ...filterDisplays, ["showAddressFilter"]: true });
    }
    handleAnchorClose();
  };

  const tooglePriceFilter = () => {
    if (showPriceFilter) {
      setFilterDisplays({ ...filterDisplays, ["showPriceFilter"]: false });
      setPriceRange([minPrice, maxPrice]);
    } else {
      setFilterDisplays({ ...filterDisplays, ["showPriceFilter"]: true });
    }
    handleAnchorClose();
  };

  const clearSearch = () => {
    setSearchText("");
    searchTable("");
  };

  const filter = () => {
    var options = props.allLocations;
    var newOptions = [];
    var filteredOptions = [];

    if (!showAddressFilter && !showPriceFilter) {//Only Global search
      newOptions = options;
    }
    else if (//Only address filter with no value inside it
      showAddressFilter &&
      (addressFilter === "" || addressFilter === null) &&
      !showPriceFilter
    ) {
      newOptions = options;

      newOptions.map(option => {
        var price = option.pricing !== null ? option.pricing.toString() : "";
        var arr = [option.address, price];

        for (var j = 0; j < arr.length; j++) {
          if (arr[j].toUpperCase().includes(searchText.toUpperCase())) {
            filteredOptions.push(option);
            break;
          }
        }
      });
    }
    else {
      options.map(option => {
        var address = option.address;
        if (showAddressFilter && showPriceFilter) { //both address and price
          var price = option.pricing !== null ? option.pricing : "";
          if (
            address.toUpperCase().includes(addressFilter.toUpperCase()) &&
            price !== "" &&
            price > priceRange[0] &&
            price < priceRange[1]
          ) {
            newOptions.push(option);
          }
        } else if (showAddressFilter) { //only address
          if (showAddressFilter !== "" && showAddressFilter !== null) {
            var address = option.address;
            if (address.toUpperCase().includes(addressFilter.toUpperCase())) {
              newOptions.push(option);
            }
          }
        } else if (showPriceFilter) { //only price
          var price = option.pricing !== null ? option.pricing : "";
          if (price !== "" && price > priceRange[0] && price < priceRange[1]) {
            newOptions.push(option);
          }
        }
      });

      //Global search filters through the options obtained from the code above
      newOptions.map(option => {
        var price = option.pricing !== null ? option.pricing.toString() : "";
        var arr = [option.address, price];

        for (var j = 0; j < arr.length; j++) {
          if (arr[j].toUpperCase().includes(searchText.toUpperCase())) {
            filteredOptions.push(option);
            break;
          }
        }
      });
    }

    if (filteredOptions.length > 0) {
      setFilteredLocations(filteredOptions);
    } else if (filteredOptions.length === 0 && searchText !== "") {
      setFilteredLocations(filteredOptions);
    } else {
      setFilteredLocations(newOptions);
    }
  };

  const searchTable = text => {
    var options = props.allLocations;
    var newOptions = [];

    if (text === "" || text === null) {
      newOptions = options;
    } else {
      if (showAddressFilter || showPriceFilter) {
        options = filteredLocations;
      }
      options.map(option => {
        var price = option.pricing !== null ? option.pricing.toString() : "";
        var arr = [option.address, price];

        for (var j = 0; j < arr.length; j++) {
          if (arr[j].toUpperCase().includes(searchText.toUpperCase())) {
            newOptions.push(option);
            break;
          }
        }
      });
    }
    if ((showAddressFilter || showPriceFilter) && text !== "") {
      if (newOptions.length > 0) {
        setFilteredLocations(newOptions);
      }
    } else if ((showAddressFilter || showPriceFilter) && text === "") {
      filter();
    } else {
      props.setCurrentOptions(newOptions);
      setUpdatedLocations(false);
      // setFilteredLocations(newOptions);
    }
  };

  const clearFilters = () => {
    setAddressFilter("");
    setPriceRange([minPrice, maxPrice]);
    setSearchText("");
  };

  useEffect(() => {
    filter();
  }, [addressFilter, priceRange, searchText]);

  useEffect(() => {
    if (props.locations.length !== 0 && updatedLocations === false) {
      setAllLocations(props.locations);
      setFilteredLocations(props.locations);
      console.log("update locations");
      setUpdatedLocations(true);
    }
  });

  const setLoadingImagesFalse = () => {
    console.log("setLoadingImagesFalse");
    setLoadingImages(false);
    props.refreshLocations();
    setFilterDisplays({
      showAddressFilter: false,
      showPriceFilter: false
    })
    setAddressFilter("");
    setSearchText("");
  }

  const setLoadingImagesTrue = () => {
    console.log("setLoadingImagesTrue");
    setLoadingImages(true);
  }
  
  return (
    <div>
      <Grid
        container
        style={{
          display: "flex",
          justifyContent: "flex-start",
          verticalAlign: "center",
          marginBottom: "10px"
        }}
      >
        <Grid item xs={9} md={9} lg={9} style={{ display: "flex" }}>
          <Grid item xs={3} md={3} lg={3}>
            {/* <TextField
              label="Search All Locations"
              value={searchText}
              onChange={handleSearchChange}
              variant="outlined"
              placeholder="Search Locations"
              size="small"
              InputProps={{
                endAdornment: !(searchText === null || searchText === "") ? (
                  <InputAdornment>
                    <IconButton
                      aria-label="clear"
                      onClick={clearSearch}
                      size="small"
                    >
                      <Clear size="small" />
                    </IconButton>
                  </InputAdornment>
                ) : (
                    ""
                  )
              }}
            /> */}
          </Grid>
          <Grid
            item
            xs={3}
            md={3}
            lg={3}
            style={showAddressFilter ? {} : { display: "none" }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <TextField
                label="Filter Address"
                variant="outlined"
                size="small"
                value={addressFilter}
                onChange={handleLocationFiltersChange("addressFilter")}
              />
              <IconButton
                aria-label="clear"
                onClick={toogleAddressFilter}
                size="small"
              >
                <Clear size="small" />
              </IconButton>
            </div>
          </Grid>
          <Grid
            item
            xs={3}
            md={3}
            lg={3}
            style={
              showPriceFilter
                ? { display: "flex", justifyContent: "center" }
                : { display: "none" }
            }
          >
            <div style={{ minWidth: "30px" }}>${priceRange[0]}</div>
            <Slider
              value={priceRange}
              max={maxPrice}
              min={minPrice}
              onChange={handlePriceRangeChange}
              onChangeCommitted={handlePriceRangeChange}
              valueLabelDisplay="auto"
              aria-labelledby="range-slider"
              style={{ marginLeft: "10px", marginRight: "10px" }}
            />
            <div style={{ minWidth: "30px" }}>${priceRange[1]}</div>
            <IconButton aria-label="clear" onClick={tooglePriceFilter}>
              <Clear size="small" />
            </IconButton>
          </Grid>
        </Grid>
        <Grid item xs={3} md={3} lg={3}>
          {/* <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <div style={{ display: 'block', marginTop: 'auto', marginBottom: 'auto', marginRight: '5px' }}>
              <Button
                onClick={clearFilters}
                variant="contained"
                color="default"
                size="small"
                style={
                  showAddressFilter || showPriceFilter ? { paddingTop: "0px", paddingBottom: "0px" } : { display: "none" }
                }
              >
                Clear Filters
            </Button>
            </div>
            <IconButton
              aria-controls="location-filter-menu"
              aria-haspopup="true"
              onClick={handleAnchorClick}
            >
              <FilterListIcon size="small" />
            </IconButton>
            <Menu
              id="location-filter-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleAnchorClose}
            >
              <MenuItem onClick={toogleAddressFilter}>Address</MenuItem>
              <MenuItem onClick={tooglePriceFilter}>Price</MenuItem>
            </Menu>
          </div> */}
        </Grid>
      </Grid>
      <Paper className={classes.root}>
        <GridList cellHeight='auto' className={classes.gridList} cols={5}>
          {updatedLocations
            ? filteredLocations.map(location => (
              <GridListTile
                key={location.id}
                style={!loadingImages ? { minHeight: "180px", maxHeight: "180px" } : {}}
              >
                <Slideshow
                  images={location.locationImage}
                  location={location}
                  setLocation={props.setLocation}
                  height='180px'
                  refreshLocations={() => props.refreshLocations()}
                  //toggleLoadingImages={() => toggleLoadingImages()}
                  setLoadingImagesFalse={() => setLoadingImagesFalse()}
                  setLoadingImagesTrue={() => setLoadingImagesTrue()}
                //setUpdatingImageFalse={() => setUpdatedLocations(false)}
                />
                <GridListTileBar
                  title={location.address}
                  subtitle={<span>Pricing: ${location.pricing}</span>}
                  actionIcon={
                    <IconButton
                      aria-label={`info about ${location.address}`}
                      className={classes.icon}
                      onClick={() => props.setLocation(location)}
                    >
                      <AddIcon />
                    </IconButton>
                  }
                />
              </GridListTile>
            ))
            :
            ""
          }
        </GridList>
        {
          loadingImages ?
            <CircularProgress />
            :
            ""
        }
      </Paper>
    </div>
  );
};

export default LocationsTable;
