import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { format as formatDfns } from "date-fns";
import {
    Modal,
    AppBar,
    Toolbar,
    Typography,
    IconButton,
    Grid,
    TextField,
    Button,
    Checkbox,
    List,
    ListItem,
    ListItemText,
    ListSubheader,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    DialogContentText,
    Snackbar,
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import MuiAlert from "@material-ui/lab/Alert";
import CloseIcon from "@material-ui/icons/Close";
import Axios from "axios";
import Loader from "../../../Loader";

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflow: "hidden",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        height: "auto",
        width: "600px",
        overflow: "auto",
    },
    greenCheckbox: {
        color: green[400],
        "&$checked": {
            color: green[600],
        },
    },
}));

function DeliverPopup(props) {
    const classes = useStyles();
    const driveShareRegex = /https:\/\/drive\.google\.com\/file\/d\/(.*?)\/view\?usp=sharing/g
    const driveFolderRegex = /https:\/\/drive\.google\.com\/drive\/folders\/.*/g
    const [creativeLink, setCreativeLink] = useState("");
    const [appsScriptResponse, setAppsScriptResponse] = useState("");
    const [readyToDeliver, setReadyToDeliver] = useState(false);
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [containsTV, setContainsTV] = useState(false);
    const [deliveryInProcess, setDeliveryInProcess] = useState(false);

    const [successDelete, setSuccessDelete] = useState(false);
    let checkBoxInitialState = {
        customerNameChecked: false,
        creativeNameChecked: false,
        durationChecked: false,
        platformPublisherChecked: false,
        format: false
    };
    const [deliveryCheckboxes, setDeliveryCheckBoxes] = useState(
        checkBoxInitialState
    );
    const {
        customerNameChecked,
        creativeNameChecked,
        durationChecked,
        platformPublisherChecked,
        format
    } = deliveryCheckboxes;

    const closeSuccessDeleteSnackbar = () => {
        setSuccessDelete(false);
    };

    const handleCheckbox = (name) => (event) => {
        setDeliveryCheckBoxes({
            ...deliveryCheckboxes,
            [name]: event.target.checked,
        });
    };

    const handleCreativeLinkChange = (event) => {
        setCreativeLink(event.target.value);
    };

    const splitSemiColon = (array) => {
        // var arr = array.split(";");
        // console.log(array)
        var arr = [];
        if (array !== null && array !== undefined) {
            array = array.replace("[", "");
            array = array.replace("]", "");
            array = array.replaceAll('"', "");
            arr = array.split(",")
        }
        // console.log(arr)
        const uniqueValues = [...new Set(arr)];
        // console.log(uniqueValues)
        if (uniqueValues.includes("TV") && !containsTV) {
            setContainsTV(true);
        }
        return uniqueValues;
    };

    const closeAndReset = () => {
        console.log("reached close and reset ---------------------");
        setDeliveryCheckBoxes(checkBoxInitialState);
        setContainsTV(false);
        setCreativeLink("");
        props.handleModal();
    };

    const handleDialogClose = () => {
        setOpenConfirmation(false);
    };

    const handleOpenConfirmation = () => {
        setOpenConfirmation(true);
    };

    const processDelivery = () => {
        setDeliveryInProcess(true);
        var budget = 0;
        if (props.outputDetails["Budget"]) {
            budget = parseInt(props.outputDetails["Budget"]);
        }
        var accountsNameVar = "";
        if (props.component === "MediaPlanOutputs") {
            accountsNameVar = "";
        } else if (props.component === "OtherOutputs") {
            accountsNameVar = props.projectDetails.accountsName;
        }

        var accountsEmailVar = "";
        if (props.component === "MediaPlanOutputs") {
            accountsEmailVar = "";
        } else if (props.component === "OtherOutputs") {
            accountsEmailVar = props.projectDetails.accountsEmail;
        }

        console.log("userEmail");
        console.log(props);

        Axios.post("Script/HandleDeliveryOrderMediaCore", {
            id: props.outputDetails.id,
            keyNumber: props.outputDetails.keyNumberFkNavigation !== null ? props.outputDetails.keyNumberFkNavigation.keynumber : null,//props.outputDetails.keyNumber,
            sendToCabColumnID: props.outputDetails.sendToCabColumnID,
            rowId: props.outputDetails.rowID,
            sheetId: props.outputDetails.sheetID,
            date: new Date(props.outputDetails.invoiceDate),
            cabApprovalStatus: props.outputDetails.cABApprovalStatus,
            creativeLink: creativeLink,
            creativeLinkColumnID: props.outputDetails.creativeLinkColumnID,
            checkedByColumnID: props.outputDetails.checkedByColumnID,
            checkedAndUploadedColumnID:
                props.outputDetails.checkedAndUploadedColumnID,
            sheetRowNumber: props.outputDetails.rowNumber,
            sendToAdstreamColumnID: props.outputDetails.sentToAdstreamColumnID,
            duration: props.outputDetails.duration,
            campaignName: props.outputDetails.campaignName,
            customerName:
                (props.outputDetails.customerName === undefined || props.outputDetails.customerName === null)
                    ? props.outputDetails.client : props.outputDetails.customerName,
            publisher: props.outputDetails.publisher,
            clientEmail: props.outputDetails.clientEmail,
            accountsEmail: accountsEmailVar,
            accountsName: accountsNameVar,
            invoiceDate: props.outputDetails.invoiceDate,
            invoicePO: props.outputDetails.invoicePO,
            startDate: new Date(props.outputDetails.startDate),
            endDate: new Date(props.outputDetails.endDate),
            budget: budget,
            invoiceLinkColumnID: props.outputDetails.invoiceLinkColumnID,
            platform: props.outputDetails.platform,
            creativeName: props.outputDetails.creativeName,
            targetAudience: props.outputDetails.targetAudience,
            partner: "dev@magnesium.nz",
            companyId: props.projectDetails.companyFk || props.outputDetails.projectsFkNavigation.companyFk

        })
            .then((res) => {
                setAppsScriptResponse(res.data);
                // console.log("Processed");
                // console.log(res.data);
                // console.log(res.data === "Delivered to Adstream");
                setCreativeLink("");
                setSuccessDelete(true);
                // //res.data = Delivered to Adstream
                // if (res.data === "Delivered to Adstream") {
                //   // console.log(creativeLink.slice(32).split("/")[0]);//originally 33
                //   console.log(creativeLink.split("/")[5]);
                //   Axios.post("FileTransfer/DownloadDriveFile/", {
                //     fileURL: creativeLink.split("/")[5],
                //   })
                //     .then((res) => {
                //       console.log(res);
                //     })
                //     .catch(function (error) {
                //       console.log(error);
                //     });
                // }
                props.getProjects();
            })
            .catch((error) => {
                setDeliveryInProcess(false);
                setOpenConfirmation(false);
                props.handleModal();
                console.log(error);
            });
    };

    //---------- Use Effect -------------------------------

    useEffect(() => {
        console.log("appsScriptResponse___________");
        console.log(props);
        console.log(appsScriptResponse);
        if (appsScriptResponse) {
            console.log("appsScriptResponse after if " + appsScriptResponse);
            console.log(props.outputDetails);
            props.handleInputChangeDelivery(
                props.outputDetails,
                creativeLink,
                props.parentTable,
                appsScriptResponse
            );
            setDeliveryInProcess(false);
            setOpenConfirmation(false);
            props.handleModal();
            setAppsScriptResponse("");
            setSuccessDelete(true);
        } else {
            console.log("appsScriptResponse after else " + appsScriptResponse);
        }
    }, [appsScriptResponse]);

    useEffect(() => {
        var platformArr = ["TV", "VOD"];
        var isForTvVod = false;
        if (platformArr.some(p => props.outputDetails.platform.includes(p))) {
            isForTvVod = true;
        }
        console.log(`Is for TV/VOD: ${isForTvVod}`)
        if (
            customerNameChecked &&
                creativeNameChecked &&
                durationChecked &&
                platformPublisherChecked &&
                creativeLink.length > 0 &&
                creativeLink != null &&
                format &&
                isForTvVod ? (creativeLink.match(driveShareRegex) != null) :
                (creativeLink.match(driveFolderRegex) != null || creativeLink.match(driveShareRegex) != null)
        ) {
            setReadyToDeliver(true);
        } else if (readyToDeliver) {
            setReadyToDeliver(false);
        }
    }, [
        customerNameChecked,
        creativeNameChecked,
        durationChecked,
        platformPublisherChecked,
        creativeLink,
        format
    ]);
    //---------- End of Use Effect -------------------------------

    return (
        <div>
            {
                <Snackbar
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    open={successDelete}
                    autoHideDuration={3000}
                    onClose={closeSuccessDeleteSnackbar}
                >
                    <MuiAlert severity="success">Successfully Delivered!</MuiAlert>
                </Snackbar>
            }
            {deliveryInProcess ? (
                <Loader />
            ) : (
                <Modal
                    aria-labelledby="delivered-output-modal"
                    aria-describedby="delivered-output-modal"
                    open={props.modalState}
                    onClose={closeAndReset}
                    className={classes.modal}
                >
                    <div>
                        <AppBar position="static" style={{ background: "#217F8B" }}>
                            <Toolbar variant="dense" style={{ paddingRight: 0 }}>
                                <Typography
                                    variant="subtitle1"
                                    align="center"
                                    style={{ flexGrow: 1 }}
                                >
                                    New - Delivery Confirmation
                                </Typography>
                                <IconButton
                                    aria-haspopup="true"
                                    onClick={closeAndReset}
                                    color="inherit"
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Toolbar>
                        </AppBar>
                        <Dialog
                            open={openConfirmation}
                            onClose={handleDialogClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">
                                {"Confirm Delivery"}
                            </DialogTitle>
                            <DialogContent>
                                {containsTV ? (
                                    <div>
                                        <DialogContentText id="alert-dialog-description">
                                            Are you sure you want to deliver?
                                        </DialogContentText>
                                        <DialogContentText id="alert-dialog-description">
                                            <span style={{ color: "red" }}>WARNING:</span> Customer
                                            will be charged for Adstream and CAB fees
                                        </DialogContentText>
                                    </div>
                                ) : (
                                    <DialogContentText id="alert-dialog-description">
                                        Are you sure that you want to deliver?
                                    </DialogContentText>
                                )}
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleDialogClose} color="primary">
                                    Cancel
                                </Button>
                                <Button onClick={processDelivery} color="primary" autoFocus>
                                    Send
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <div className={classes.paper}>
                            <Grid
                                container
                                spacing={2}
                                style={{ display: "flex", justifyContent: "start" }}
                            >
                                {/* {console.log("TEST")}
                                {console.log(props.outputDetails)} */}
                                <Grid item xs={11} md={11} lg={11}>
                                    <TextField
                                        label="Creative Deadline"
                                        variant="outlined"
                                        style={{ width: "100%" }}
                                        value={
                                            props.outputDetails.startDate != null &&
                                                props.outputDetails.startDate != ""
                                                ? formatDfns(
                                                    new Date(props.outputDetails.startDate),
                                                    "dd/MM/yyyy"
                                                )
                                                : "Not available"
                                        }
                                        size="small"
                                    />
                                </Grid>
                                <Grid item xs={11} md={11} lg={11}>
                                    <TextField
                                        label="Customer Name"
                                        variant="outlined"
                                        style={{ width: "100%" }}
                                        value={(props.outputDetails.client === undefined || props.outputDetails.client === null) ?
                                            props.outputDetails.customerName : props.outputDetails.client}
                                        disabled={!customerNameChecked}
                                        size="small"
                                    />
                                </Grid>
                                <Grid item xs={1} md={1} lg={1}>
                                    <Checkbox
                                        className={customerNameChecked ? classes.greenCheckbox : ""}
                                        //checked={customerNameChecked}
                                        onChange={handleCheckbox("customerNameChecked")}
                                        value={customerNameChecked}
                                        color="default"
                                    />
                                </Grid>
                                <Grid item xs={11} md={11} lg={11}>
                                    <TextField
                                        label="Creative Name"
                                        variant="outlined"
                                        style={{ width: "100%" }}
                                        value={props.outputDetails.creativeName}
                                        disabled={!creativeNameChecked}
                                        size="small"
                                    />
                                </Grid>
                                <Grid item xs={1} md={1} lg={1}>
                                    <Checkbox
                                        className={creativeNameChecked ? classes.greenCheckbox : ""}
                                        onChange={handleCheckbox("creativeNameChecked")}
                                        value={creativeNameChecked}
                                        color="default"
                                    />
                                </Grid>
                                <Grid item xs={11} md={11} lg={11}>
                                    <TextField
                                        label="Duration"
                                        variant="outlined"
                                        style={{ width: "100%" }}
                                        value={props.outputDetails.duration}
                                        disabled={!durationChecked}
                                        size="small"
                                    />
                                </Grid>
                                <Grid item xs={1} md={1} lg={1}>
                                    <Checkbox
                                        className={durationChecked ? classes.greenCheckbox : ""}
                                        onChange={handleCheckbox("durationChecked")}
                                        value={durationChecked}
                                        color="default"
                                    />
                                </Grid>
                                <Grid item xs={11} md={11} lg={11}>
                                    <TextField
                                        label="Format"
                                        variant="outlined"
                                        style={{ width: "100%" }}
                                        value={props.outputDetails.format}
                                        disabled={!format}
                                        size="small"
                                    />
                                </Grid>
                                <Grid item xs={1} md={1} lg={1}>
                                    <Checkbox
                                        className={format ? classes.greenCheckbox : ""}
                                        onChange={handleCheckbox("format")}
                                        value={format}
                                        color="default"
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={11}
                                    md={11}
                                    lg={11}
                                    container
                                    spacing={0}
                                    style={{
                                        display: "flex",
                                        alignItems: "stretch",
                                        justifyContent: "center",
                                    }}
                                >
                                    <Grid item xs={6} md={6} lg={6}>
                                        {/* <TextField
                                label="Destination"
                                variant="outlined"
                                style={{ width: "100%" }}
                                value={
                                    props.outputDetails["Platform"] != undefined
                                        ? props.splitSemiColon(props.outputDetails["Platform"])
                                        : "Not available"
                                }
                                multiline={true}
                                disabled={!platformPublisherChecked}
                                size="small"
                            /> */}
                                        <div
                                            style={{
                                                paddingTop: "0px",
                                                paddingBottom: "0px",
                                                marginTop: "0px",
                                                marginBottom: "0px",
                                            }}
                                        >
                                            <List
                                                dense={true}
                                                subheader={
                                                    <ListSubheader
                                                        component="div"
                                                        id="Platform-list-subheader"
                                                        style={
                                                            !platformPublisherChecked
                                                                ? {
                                                                    color: "rgba(0, 0, 0, 0.38)",
                                                                    fontWeight: "bold",
                                                                }
                                                                : {
                                                                    color: "rgba(0, 0, 0, 0.87)",
                                                                    fontWeight: "bold",
                                                                }
                                                        }
                                                    >
                                                        Platforms
                                                    </ListSubheader>
                                                }
                                                style={
                                                    !platformPublisherChecked
                                                        ? { color: "rgba(0, 0, 0, 0.38)" }
                                                        : { color: "rgba(0, 0, 0, 0.87)" }
                                                }
                                            >
                                                {props.outputDetails.platform != undefined ? (
                                                    splitSemiColon(props.outputDetails.platform).map(
                                                        (item, index) => {
                                                            return (
                                                                <ListItem
                                                                    key={index}
                                                                    style={{
                                                                        paddingTop: "0px",
                                                                        paddingBottom: "0px",
                                                                        marginTop: "0px",
                                                                        marginBottom: "0px",
                                                                    }}
                                                                >
                                                                    <ListItemText primary={`- ${item}`} />
                                                                </ListItem>
                                                            );
                                                        }
                                                    )
                                                ) : (
                                                    <ListItem
                                                        style={{
                                                            paddingTop: "0px",
                                                            paddingBottom: "0px",
                                                            marginTop: "0px",
                                                            marginBottom: "0px",
                                                        }}
                                                    >
                                                        <ListItemText primary={`- No Platforms`} />
                                                    </ListItem>
                                                )}
                                            </List>
                                        </div>
                                    </Grid>
                                    <Grid item xs={6} md={6} lg={6}>
                                        {/* <TextField
                                label="Publisher"
                                variant="outlined"
                                style={{ width: "100%" }}
                                value={
                                    props.outputDetails["Publisher"] != undefined
                                        ? props.splitSemiColon(props.outputDetails["Publisher"])
                                        : "Not available"
                                }
                                multiline={true}
                                disabled={!platformPublisherChecked}
                            /> */}
                                        <div
                                            style={{
                                                paddingTop: "0px",
                                                paddingBottom: "0px",
                                                marginTop: "0px",
                                                marginBottom: "0px",
                                            }}
                                        >
                                            <List
                                                dense={true}
                                                subheader={
                                                    <ListSubheader
                                                        component="div"
                                                        id="publishers-list-subheader"
                                                        style={
                                                            !platformPublisherChecked
                                                                ? {
                                                                    color: "rgba(0, 0, 0, 0.38)",
                                                                    fontWeight: "bold",
                                                                }
                                                                : {
                                                                    color: "rgba(0, 0, 0, 0.87)",
                                                                    fontWeight: "bold",
                                                                }
                                                        }
                                                    >
                                                        Publishers
                                                    </ListSubheader>
                                                }
                                                style={
                                                    !platformPublisherChecked
                                                        ? { color: "rgba(0, 0, 0, 0.38)" }
                                                        : { color: "rgba(0, 0, 0, 0.87)" }
                                                }
                                            >
                                                {props.outputDetails.publisher != undefined ? (
                                                    splitSemiColon(props.outputDetails.publisher).map(
                                                        (item, index) => {
                                                            return (
                                                                <ListItem
                                                                    key={index}
                                                                    style={{
                                                                        paddingTop: "0px",
                                                                        paddingBottom: "0px",
                                                                        marginTop: "0px",
                                                                        marginBottom: "0px",
                                                                    }}
                                                                >
                                                                    <ListItemText primary={`- ${item}`} />
                                                                </ListItem>
                                                            );
                                                        }
                                                    )
                                                ) : (
                                                    <ListItem
                                                        style={{
                                                            paddingTop: "0px",
                                                            paddingBottom: "0px",
                                                            marginTop: "0px",
                                                            marginBottom: "0px",
                                                        }}
                                                    >
                                                        <ListItemText primary={`- No Publishers`} />
                                                    </ListItem>
                                                )}
                                            </List>
                                        </div>
                                    </Grid>
                                </Grid>
                                <Grid item xs={1} md={1} lg={1}>
                                    <Checkbox
                                        className={
                                            platformPublisherChecked ? classes.greenCheckbox : ""
                                        }
                                        onChange={handleCheckbox("platformPublisherChecked")}
                                        value={platformPublisherChecked}
                                        color="default"
                                    />
                                </Grid>
                                <Grid item xs={12} md={12} lg={12}>
                                    <TextField
                                        label="Creative Link"
                                        variant="outlined"
                                        style={{ width: "100%" }}
                                        value={creativeLink}
                                        onChange={handleCreativeLinkChange}
                                        multiline={true}
                                        size="small"
                                        placeholder="Please paste in the creative link."
                                        // error={() => {
                                        //     var platformArr = ["TV", "VOD"];
                                        //     var isForTvVod = false;
                                        //     if (platformArr.some(p => props.outputDetails.platform.includes(p))) {
                                        //         isForTvVod = true;
                                        //     }
                                        //     console.log(`creativeLink.match(driveFolderRegex): ${creativeLink.match(driveFolderRegex)}`)
                                        //     console.log(`creativeLink.match(driveShareRegex): ${creativeLink.match(driveShareRegex)}`)
                                        //     return isForTvVod ? (creativeLink.match(driveShareRegex) != null ? false : true)
                                        //     : ((creativeLink.match(driveFolderRegex) != null || creativeLink.match(driveShareRegex) != null) ? false : true)
                                        // }}
                                        helperText={
                                            <>
                                                (TV, VOD):
                                                <br />      https://drive.google.com/file/d/FILE_ID/view?usp=sharing
                                                <br />
                                                <br />(Other platform types):
                                                <br />      https://drive.google.com/drive/folders/FOLDER_ID
                                                <br />      https://drive.google.com/file/d/FILE_ID/view?usp=sharing
                                            </>

                                        }
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    md={12}
                                    lg={12}
                                    style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                    }}
                                >
                                    <Button
                                        variant="contained"
                                        size="small"
                                        color="primary"
                                        disabled={!readyToDeliver}
                                        onClick={handleOpenConfirmation}
                                    >
                                        Deliver
                                    </Button>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </Modal>
            )}
        </div>
    );
}

export default DeliverPopup;