import { Menu, MenuItem } from "@material-ui/core";
import React, { useRef, useState } from "react";
import TimeInput from "../../Shared/Inputs/TimeInput";
import ConfirmationDialogWithInput from "../../Shared/ConfirmationDialogWithInput";

const TimeTrackerAllocationInput = ({
  timeEntry,
  onTimeChange,
  initialMinutes,
  onDescriptionChange
}) => {
  const inputRef = useRef(null);
  const [openEditNote, setOpenEditNote] = useState(false);
  const [contextMenu, setContextMenu] = useState(null);
  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
          }
        : null
    );
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  const handleSaveDescription = (description) => {
    onDescriptionChange(description)
    setOpenEditNote(false);
    handleClose();
  };

  return (
    <div
      ref={inputRef}
      onContextMenu={handleContextMenu}
      className="time-tracker-time-allocation-container add-background text-alignment"
    >
      {openEditNote && (
        <ConfirmationDialogWithInput
          open={openEditNote}
          handleClose={() => {
            setOpenEditNote(false);
            handleClose();
          }}
          handleSubmit={handleSaveDescription}
          submitLabel={"Save"}
          description={"Please enter a comment for this time entry."}
          textFieldLabel={"Note"}
          title={"Note"}
          value={timeEntry?.description}
        />
      )}
      <TimeInput initialMinutes={initialMinutes} onTimeChange={onTimeChange} mask="09:99"/>
      {timeEntry?.timeEntryId && (
        <Menu
          open={contextMenu !== null}
          onClose={handleClose}
          anchorReference="anchorPosition"
          anchorPosition={
            contextMenu !== null
              ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
              : undefined
          }
        >
          {timeEntry?.description ? (
            <MenuItem onClick={() => setOpenEditNote(true)}>Edit Note</MenuItem>
          ) : (
            <MenuItem onClick={() => setOpenEditNote(true)}>Add Note</MenuItem>
          )}
        </Menu>
      )}
    </div>
  );
};

export default TimeTrackerAllocationInput;
