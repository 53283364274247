import React from "react";
import "./brandBadge.scss";
import { Typography } from "@material-ui/core";
import { getOrganisationName } from "../../../utils/OrganisationUtils";

const BrandBadge = ({
  organisationName,
  organisationId,
  size = "default"
}) => {
  return (
    <div className={`brand-badge-root brand-badge-hover-${organisationId}`}>
      <Typography
        variant="caption"
        className={size === "small" ? "brand-badge-small" : "brand-badge-default"}
      >
        {organisationName !== null ? organisationName : getOrganisationName(organisationId)}
      </Typography>
    </div>
  );
};

export default BrandBadge;
