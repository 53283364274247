import React from 'react';
import {
    Card,
    CardHeader,
    CardMedia,
    CardContent,
    CardActions,
    Collapse,
    Avatar,
    IconButton,
    Typography,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    Table,
    Fab
} from "@material-ui/core";
import {
    Add as AddIcon,
    Refresh as RefreshIcon
} from "@material-ui/icons";
import { numberWithThousandsSeperator } from "../../utils/utils";

const InfluencerBasicCard = (props) => {
    const {
        influencer,
        type
    } = props;

    const InstagramStatisticsTable = () => {
        return (
            <CardContent>
                <Typography variant="body2" color="text.secondary">
                    {`${influencer.profileDescription ?? "No Biography"}`}
                </Typography>
                <div style={{ marginTop: 10, display: "grid", gridTemplateColumns: "1fr 1fr", gap: 10 }}>
                    <Typography variant="body2" color="text.secondary">
                        <strong>Followers: </strong>{`${numberWithThousandsSeperator(influencer.followers)}`}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        <strong>Posts: </strong>{`${numberWithThousandsSeperator(influencer.posts)}`}
                    </Typography>
                    {influencer.id !== 0 && (
                        <React.Fragment>
                            {/* <Typography variant="body2" color="text.secondary">
                                <strong>Engagment Rate: </strong>{`${influencer.engagementRate ?? "Data Requested"}`}
                            </Typography> */}
                            {/* <Typography variant="body2" color="text.secondary">
                                <strong>Avg Likes: </strong>{`${influencer.avgLikes ?? "Data Requested"}`}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                <strong>Avg Comments: </strong>{`${influencer.avgComments ?? "Data Requested"}`}
                            </Typography>
                             <Typography variant="body2" color="text.secondary">
                                <strong>GRate Followers: </strong>{`${influencer.grateFollowers ?? "Data Requested"}`}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                <strong>GRate Comments: </strong>{`${influencer.grateComments ?? "Data Requested"}`}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                <strong>GRate Engagmenet Rate: </strong>{`${influencer.gRateEngagementRate ?? "Data Requested"}`}
                            </Typography> */}
                        </React.Fragment>
                    )}
                </div>
            </CardContent>
        )
    }

    const TikTokStatisticsTable = () => {
        return (
            <div>
                <CardContent>
                    <Typography variant="body2" color="text.secondary">
                        {`${influencer.profileDescription ?? "No Biography"}`}
                    </Typography>
                    <div style={{ marginTop: 10, display: "grid", gridTemplateColumns: "1fr 1fr", gap: 10 }}>
                        <Typography variant="body2" color="text.secondary">
                            <strong>Followers: </strong>{`${numberWithThousandsSeperator(influencer.followers)}`}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            <strong>Hearts: </strong>{`${numberWithThousandsSeperator(influencer.hearts)}`}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            <strong>Videos: </strong>{`${numberWithThousandsSeperator(influencer.videos)}`}
                        </Typography>
                        {influencer.id !== 0 && (
                            <React.Fragment>
                                <Typography variant="body2" color="text.secondary">
                                    <strong>Engagment Rate: </strong>{`${influencer.engagementRate ?? "Data Requested"}`}
                                </Typography>
                                {/*<Typography variant="body2" color="text.secondary">
                                    <strong>Avg Hearts: </strong>{`${influencer.avgHearts ?? "Data Requested"}`}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    <strong>Avg Plays: </strong>{`${influencer.avgPlays ?? "Data Requested"}`}
                                </Typography>
                                 <Typography variant="body2" color="text.secondary">
                                    <strong>GRate Followers: </strong>{`${influencer.grateFollowers ?? "Data Requested"}`}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    <strong>GRate Hearts: </strong>{`${influencer.grateHearts ?? "Data Requested"}`}
                                </Typography> */}
                            </React.Fragment>
                        )}
                    </div>

                </CardContent>
            </div>
        )
    }

    const getSubHeader = () => {
        var link = `https://www.instagram.com/${influencer.influencerId}`;
        if (type === "tiktok") {
            link = `https://www.tiktok.com/@${influencer.influencerId}`;
        }
        return <a href={link} target='_blank'><strong>{influencer.influencerId}</strong></a>
    }

    if (influencer !== null && influencer.influencerId) {
        return (
            <Card elevation={9}>
                <CardHeader
                    avatar={<Avatar
                        style={{
                            height: 110,
                            width: 110
                        }}
                        alt={`${influencer.profileUsername}`} src={`${influencer.avatar}`}>
                    </Avatar>}
                    title={<strong>{influencer.profileUsername}</strong>}
                    subheader={getSubHeader()}
                    // action={ influencer.id !== 0 ? (
                    //     <IconButton aria-label="refresh">
                    //         <RefreshIcon />
                    //     </IconButton>) : ""
                    // }
                />
                {type === "instagram" ? InstagramStatisticsTable() : type === "tiktok" ? TikTokStatisticsTable() : ""}
            </Card>
        );
    } else {
        return (
            <Card elevation={9}>
                <CardHeader
                    avatar={<Avatar
                        style={{
                            height: 110,
                            width: 110
                        }}
                        alt={`Nothing selected`}
                        src={"https://source.boringavatars.com/beam"}>
                    </Avatar>}
                    title={<strong>Nothing selected</strong>}
                />
                <CardContent>
                    <Typography variant="body2" color="text.secondary">
                        Nothing to display
                    </Typography>
                </CardContent>
            </Card>
        )
    }
}

export default InfluencerBasicCard;