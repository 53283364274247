import { Button } from "@material-ui/core";
import React from "react";
import "./newCampaignButton.scss";
const NewCampaignButton = ({ handleClick }) => {
  return (
    <div className="new-campaign-button-root">
      <Button
        size="small"
        className="default-button"
        variant="contained"
        onClick={handleClick}
      >
        New Campaign
      </Button>
    </div>
  );
};

export default NewCampaignButton;
