import { IconButton, TableCell, TableRow, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { Edit as EditIcon } from "@material-ui/icons";
import BlockIcon from "@material-ui/icons/Block";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import ConfirmationDialogActions from "../../Shared/ConfirmationDialogActions";
import UserDetailDialog from "./UserDetailDialog";
const UsersTableRow = ({ user, updateUser, updateUserStatus }) => {
  const [openChangeStatusDialog, setOpenChangeStatusDialog] = useState(false);
  const [openUpdateDialog, setOpenUpdateDialog] = useState(false);

  const handleConfirmChangeStatus = () => {
    updateUserStatus(user.id, user.isArchived ? false : true);
    setOpenChangeStatusDialog(false);
  };

  return (
    <>
      {openChangeStatusDialog && (
        <ConfirmationDialogActions
          title={"Are you sure?"}
          open={openChangeStatusDialog}
          contentText={`This action will set user status to ${
            user.isArchived ? "ACTIVE" : "INACTIVE"
          }`}
          button1Action={() => setOpenChangeStatusDialog(false)}
          button1Text={"Cancel"}
          button2Text={"Confirm"}
          button2Action={handleConfirmChangeStatus}
        />
      )}
      {openUpdateDialog && (
        <UserDetailDialog
          user={user}
          updateUser={updateUser}
          open={openUpdateDialog}
          handleClose={() => setOpenUpdateDialog(false)}
        />
      )}
      <TableRow>
        <TableCell>
          <Typography variant="caption">{`${user.firstName} ${user.lastName}`}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="caption">{user.email}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="caption">{user.phoneNumber}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="caption">{user.role}</Typography>
        </TableCell>
        <TableCell>
          <Typography
            variant="caption"
            style={{ color: user.isArchived ? "red" : "green" }}
          >
            {user.isArchived ? "INACTIVE" : "ACTIVE"}
          </Typography>
        </TableCell>
        <TableCell>
          <div>
            <IconButton
              size="small"
              onClick={() => {
                setOpenUpdateDialog(true);
              }}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              size="small"
              onClick={() => {
                setOpenChangeStatusDialog(true);
              }}
            >
              {user.isArchived ? <CheckCircleOutlineIcon /> : <BlockIcon />}
            </IconButton>
          </div>
        </TableCell>
      </TableRow>
    </>
  );
};

export default UsersTableRow;
