import React, { Fragment, useState, useEffect, useLayoutEffect } from "react";
import BuildIcon from "@material-ui/icons/Build";
import DirectionsCarIcon from "@material-ui/icons/DirectionsCar";
import { Tooltip, Typography, IconButton, Button } from "@material-ui/core";
import { withStyles, styled, makeStyles } from "@material-ui/core/styles";
import EquipmentAndTravelPopup from "../../Project/Schedule/ScheduleItemTable/PopUps/EquipmentAndTravelPopup";
import axios from "axios";
import { connect } from "react-redux";
import { getBudget } from "../../../actions/budget";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snacky from "../../Shared/Snacky";
import InformationDialogy from "../../Shared/InformationDialogy";
import { CompareArrowsOutlined } from "@material-ui/icons";
import { getDistance } from "../../../utils/DistanceUtils";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const AddEquipmentAndTravel = (props) => {
  const {
    scheduleItem,
    projectId,
    scheduleId,
    locations,
    lineIndex,
    compIndex,
    parent,
    reloadPrepostScheduleItemsList
  } = props;
  const [equipmentModal, setequipmentModal] = useState(false);
  const [equipmentCheckbox, setequipmentCheckbox] = useState(false);
  const [accounts, setaccounts] = useState();
  const [fee, setfee] = useState({
    account: null,
    fee: null,
  });
  const [equipmentFee, setequipmentFee] = useState({
    account: null,
    fee: null,
  });
  // const [travelObj, settravelObj] = useState();
  const [travelDistance, settravelDistance] = useState();
  const [travelContribution, settravelContribution] = useState();

  const [loading, setloading] = useState(false);

  const [showSnackbar, setshowSnackbar] = useState({
    open: false,
    state: null,
  });

  const closePopup = () => {
    setequipmentCheckbox(false);
    setaccounts();
    setfee({
      account: null,
      fee: null,
    });
    setequipmentFee({
      account: null,
      fee: null,
    });
    settravelDistance();
    setequipmentModal(false);
  };

  const handleFeeChange = (field, value) => {
    setfee({ ...fee, [field]: value });
  };

  const handleEquipmentFeeChange = (field, value) => {
    setequipmentFee({
      ...equipmentFee,
      [field]: value,
    });
  };

  const handleEquipmentCheckbox = () => {
    setequipmentCheckbox(!equipmentCheckbox);
  };

  useLayoutEffect(() => {
    if (equipmentCheckbox) {
      setfee({ ...fee, fee: 0 });
      if (scheduleItem.equipmentHire) {
        setequipmentFee({
          ...equipmentFee,
          fee: (
            Number(scheduleItem.equipmentHire.rate) +
            Number(scheduleItem.allocatedRates)
          ).toString(),
        });
      } else {
        setequipmentFee({ ...equipmentFee, fee: scheduleItem.allocatedRates });
      }
    } else {
      setfee({ ...fee, fee: scheduleItem.allocatedRates });
      if (scheduleItem.equipmentHire || scheduleItem.equipmentFkNavigation) {
        setequipmentFee({
          ...equipmentFee,
          fee: parent === "schedule" ? (scheduleItem.equipmentHire.rate?.toString()) :
            (scheduleItem.equipmentFkNavigation.rate?.toString()),
        });
      } else {
        setequipmentFee({
          ...equipmentFee,
          fee: "",
        });
      }
    }
  }, [equipmentCheckbox]);

  useEffect(() => {
    console.log("scheduleItem")
    console.log(scheduleItem)
    axios
      .get("TravelContributions/GetTravelContribution")
      .then(({ data }) => {
        settravelContribution(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    console.log("equipmentFee")
    console.log(equipmentFee)
  }, [equipmentFee]);

  const handleSnacky = (value, state) => {
    setshowSnackbar({
      open: value,
      state: state,
    });
  };

  // const getDistance = async (locations) => {
  //   const directionsService = new window.google.maps.DirectionsService();

  //   let distance = 0;
  //   locations.push({
  //     locationFkNavigation: {
  //       address: "5 Owens Road, Epsom, Auckland, New Zealand",
  //     },
  //   });
  //   locations.unshift({
  //     locationFkNavigation: {
  //       address: "5 Owens Road, Epsom, Auckland, New Zealand",
  //     },
  //   });
  //   for (let i = 0; i < locations.length - 1; i++) {
  //     const request = {
  //       origin: locations[i].locationFkNavigation.address,
  //       destination: locations[i + 1].locationFkNavigation.address,
  //       travelMode: window.google.maps.DirectionsTravelMode.DRIVING,
  //     };
  //     try {
  //       await directionsService.route(request, function (response, status) {
  //         if (status == window.google.maps.DirectionsStatus.OK) {
  //           // travel =
  //           //   i < locations.length - 2
  //           //     ? {
  //           //         ...travel,
  //           //         go: (travel.go +=
  //           //           Number(response.routes[0].legs[0].distance.value) / 1000),
  //           //       }
  //           //     : {
  //           //         ...travel,
  //           //         return:
  //           //           Number(response.routes[0].legs[0].distance.value) / 1000,
  //           //       };
  //           distance +=
  //             Number(response.routes[0].legs[0].distance.value) / 1000;
  //         } else {
  //           return false;
  //         }
  //       });
  //     } catch (e) {
  //       console.log(e);
  //     }
  //   }
  //   // settravelObj(travel);
  //   // settravelDistance(distance);
  //   return distance;
  // };

  const getValues = () => {
    axios
      .get(`/BudgetItems/GetBudgetForProject/${projectId}`)
      .then(({ data }) => {
        setaccounts(data);
        const _account = data.find((a) => a.category === scheduleItem.category);
        //Has existing entry on table
        if (scheduleItem.equipmentHire && scheduleItem.category !== "Unpaid") {
          const __account = data.find(
            (a) => a.category === scheduleItem.equipmentHire.accountName
          );
          setequipmentFee({
            ...equipmentFee,
            fee: scheduleItem.equipmentHire.rate,
            account: __account,
          });
          setequipmentCheckbox(
            scheduleItem.equipmentHire.equipmentOnly === 1 ? true : false
          );
        } else if (scheduleItem.equipmentFkNavigation && scheduleItem.category !== "Unpaid") {
          const __account = data.find(
            (a) => a.category === scheduleItem.equipmentFkNavigation.accountName
          );
          setequipmentFee({
            ...equipmentFee,
            fee: scheduleItem.equipmentFkNavigation.rate,
            account: __account,
          });
        } else {
          setequipmentFee({
            ...equipmentFee,
            fee: "",
            account: _account,
          });
        }
        setfee({
          ...fee,
          account: _account,
          fee: scheduleItem.allocatedRates,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (lineIndex === compIndex) {
      handleClick();
    }
  }, [lineIndex]);

  const handleClick = async () => {
    setloading(true);
    if (parent === "schedule") {
      // const locs = locations.map((l) => {
      //   if (scheduleItem.startTime >= l.startTime && scheduleItem.endTime <= l.endTime) {
      //     return l;
      //   }
      const locs = locations.filter((l) => {
        if (scheduleItem.startTime >= l.startTime && scheduleItem.endTime <= l.endTime) {
          return l;
        }
      });

      const distance = await getDistance(locs);
      getValues(distance);
      setequipmentModal(true);
      settravelDistance(distance);
    } else {
      getValues();
      setequipmentModal(true);
      settravelDistance(0);
    }
  };
  const handleloading = () => {
    setloading(false);
  };

  return (
    <Fragment>
      <Snacky
        snackprops={
          showSnackbar.state === "Success"
            ? {
              open: showSnackbar.open,
              severity: "success",
              text: "Processed successfully",
            }
            : {
              open: showSnackbar.open,
              severity: "error",
              text: "Something went wrong",
            }
        }
        setSnackBarProps={() => handleSnacky(false)}
      />
      <EquipmentAndTravelPopup
        disableFields={
          !(
            scheduleItem.contractStatus == "Send" ||
            scheduleItem.contractStatus == "Disabled"
          )
        }
        equipmentModal={equipmentModal}
        closePopup={closePopup}
        scheduleItem={scheduleItem}
        projectId={projectId}
        scheduleId={scheduleId}
        equipmentCheckbox={equipmentCheckbox}
        accounts={accounts}
        equipmentFee={equipmentFee}
        fee={fee}
        handleEquipmentCheckbox={handleEquipmentCheckbox}
        handleFeeChange={handleFeeChange}
        handleEquipmentFeeChange={handleEquipmentFeeChange}
        handleloading={handleloading}
        travelDistance={travelDistance}
        travelContribution={travelContribution}
        getBudget={getBudget}
        handleSnacky={handleSnacky}
        reloadPrepostScheduleItemsList={() => props.reloadPrepostScheduleItemsList()}
        parent={parent}
      />
      <HtmlTooltip
        title={
          <Typography color="inherit">Add equipment and travel fee</Typography>
        }
        interactive
        arrow
        placement="left"
      >
        <span>
          {/* old icons */}
          {/* <IconButton
            aria-label="equipment"
            onClick={() => setequipmentModal(true)}
            style={{backgroundColor: "rgb(200, 200, 200)"}}
          >
            <BuildIcon style={{ color: "white" }} />
            <DirectionsCarIcon style={{ color: "white" }} />
          </IconButton> */}

          {/* New button with both icons */}

          <Button
            variant="contained"
            color="default"
            startIcon={<BuildIcon />}
            endIcon={<DirectionsCarIcon />}
            onClick={handleClick}
            disabled={loading}
            className={
              (parent === "schedule" && scheduleItem.equipmentHire === null) || (parent === "prepost" && scheduleItem.equipmentFkNavigation === null)
                ? "equipment-button-style-incomplete"
                : "equipment-button-style-complete"
            }
          >
            {loading && (
              <span>
                <CircularProgress color="primary" size={15} />
              </span>
            )}
          </Button>
        </span>
      </HtmlTooltip>
    </Fragment>
  );
};

const mapStateToProps = (state, ownProps) => ({
  locations: state.schedules.schedule.scheduleLocations,
});
export default connect(mapStateToProps, {
  getBudget,
})(AddEquipmentAndTravel);
