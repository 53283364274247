import React, { useState } from "react";
import {
  resetProject,
  resetSchedule,
  resetScheduleTable,
} from "../actions/schedules";
import { resetScheduleItems } from "../actions/scheduleItems";
import { resetBudget } from "../actions/budget";
import ProjectsTable from "./ProjectsTable";
import { connect } from "react-redux";
import Header from "./Header";

export const AllProjects = (props) => {
  const [view, setView] = useState(
    props.location?.pathname?.toLowerCase() === "/archive"
      ? "archivedProjects"
      : props.location?.pathname?.toLowerCase() === "/projects"
      ? "activeProjects"
      : "activeProjects"
  );

  return (
    <>
      <Header setView={setView} view={view} />
      <div style={{ padding: "15px", height: "auto" }}>
        <ProjectsTable
          view={view}
          setView={setView}
          resetProject={resetProject}
          resetBudget={resetBudget}
          resetSchedule={resetSchedule}
          resetScheduleItems={resetScheduleItems}
          resetScheduleTable={resetScheduleTable}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state, ownProps) => ({});
// https://react-redux.js.org/api/connect
export default connect(mapStateToProps, {
  resetProject,
  resetBudget,
  resetSchedule,
  resetScheduleItems,
  resetScheduleTable,
})(AllProjects);
