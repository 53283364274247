import React, { useState, useEffect, useCallback } from "react";
import "./organisationHomePage.scss";
import HomepageClientCard from "./HomepageClientCard";
import axios from "axios";
import { useAuth } from "../../context/auth/AuthContext";
import Snacky from "../Shared/Snacky";
import { Button, TextField } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { useHistory } from "react-router-dom";

const OrganisationHomepage = () => {
  const history = useHistory();
  const { user } = useAuth();
  const queryParameters = new URLSearchParams(window.location.search);
  const departmentFromQuery = queryParameters.get("department");

  const [companies, setCompanies] = useState(null);
  const [keyword, setKeyword] = useState("");
  const [showHidden, setShowHidden] = useState(false);
  const [loading, setLoading] = useState(false);
  const [snackBarProps, setSnackBarProps] = useState({
    open: false,
    severity: "success",
    text: "",
  });

  const handleApiError = useCallback((message) => {
    console.error(message);
    setSnackBarProps({
      open: true,
      severity: "warning",
      text: message,
    });
  }, []);

  const handleApiSuccess = useCallback((message) => {
    setSnackBarProps({
      open: true,
      severity: "success",
      text: message,
    });
  }, []);

  const updateProjectStatusInCompanies = useCallback(
    (companyId, jobId, projectStatusDto) => {
      setCompanies((prevCompanies) =>
        prevCompanies.map((company) =>
          company.companyId === companyId
            ? {
                ...company,
                projects: company.projects.map((project) =>
                  project.jobId === jobId
                    ? {
                        ...project,
                        projectStatus: project.projectStatus.map((status) =>
                          status.projectId === projectStatusDto.projectId
                            ? { ...projectStatusDto }
                            : status
                        ),
                      }
                    : project
                ),
              }
            : company
        )
      );
    },
    []
  );

  const deleteProjectStatusInCompanies = useCallback(
    (companyId, jobId, projectStatusDto) => {
      setCompanies((prevCompanies) =>
        prevCompanies
          .map((company) => {
            if (company.companyId === companyId) {
              const updatedProjects = company.projects.map((project) => {
                if (project.jobId === jobId) {
                  const updatedProjectStatus = project.projectStatus.filter(
                    (status) => status.projectId !== projectStatusDto.projectId
                  );
                  return { ...project, projectStatus: updatedProjectStatus };
                }
                return project;
              });
              const nonEmptyProjects = updatedProjects.filter(
                (project) => project.projectStatus.length > 0
              );
              return { ...company, projects: nonEmptyProjects };
            }
            return company;
          })
          .filter((company) => company.projects.length > 0)
      );
    },
    []
  );

  const updateProjectStatus = (
    companyId,
    jobId,
    projectStatusId,
    propertyName,
    isUndo
  ) => {
    axios
      .put(
        `ProjectStatus/UpdateProjectStatusItem/${projectStatusId}/${propertyName}`,
        {},
        {
          params: { updatedBy: user.email, isUndo },
        }
      )
      .then((res) => {
        updateProjectStatusInCompanies(companyId, jobId, res.data);
        handleApiSuccess("The status was saved successfully");
      })
      .catch((e) => handleApiError("Failed to update status"));
  };

  const hideProjectStatus = (companyId, jobId, projectStatusId) => {
    axios
      .put(
        `ProjectStatus/hideProjectStatus/${projectStatusId}`,
        {},
        {
          params: { updatedBy: user.email },
        }
      )
      .then((res) => {
        deleteProjectStatusInCompanies(companyId, jobId, res.data);
        handleApiSuccess("Archived successfully");
      })
      .catch((e) => handleApiError("Failed to hide project"));
  };

  const unhideProjectStatus = (companyId, jobId, projectStatusId) => {
    axios
      .put(
        `ProjectStatus/unhideProjectStatus/${projectStatusId}`,
        {},
        {
          params: { updatedBy: user.email },
        }
      )
      .then((res) => {
        deleteProjectStatusInCompanies(companyId, jobId, res.data);
        handleApiSuccess("Restored successfully");
      })
      .catch((e) => handleApiError("Failed to restore project"));
  };

  const getWelcomeText = useCallback(() => {
    if (departmentFromQuery) {
      return `Here is a list of ${departmentFromQuery} projects.`;
    }
    const departments = [
      "Flying Tiger",
      "Socialites",
      "People of Influence",
      "The Social Club",
    ];
    if (departments.includes(user.department)) {
      return `Here is a list of ${user.department} projects.`;
    }
    return "Here is a list of all projects.";
  }, [departmentFromQuery, user.department]);

  const toggleHiddenStatuses = useCallback(() => {
    setShowHidden((prev) => !prev);
  }, []);

  const getProjectStatuses = useCallback(() => {
    const departmentFilter = [
      "Flying Tiger",
      "Socialites",
      "People of Influence",
      "The Social Club",
    ].includes(user.department)
      ? user.department
      : null;

    setLoading(true);

    axios
      .get(`ProjectStatus/GetProjectStatus`, {
        params: {
          department: departmentFromQuery ?? departmentFilter,
          searchText: keyword,
          showHidden,
        },
      })
      .then((res) => setCompanies(res.data))
      .catch(() => handleApiError("Failed to fetch data"))
      .finally(() => setLoading(false));
  }, [
    departmentFromQuery,
    user.department,
    keyword,
    showHidden,
    handleApiError,
  ]);

  useEffect(() => {
    getProjectStatuses();
  }, [showHidden]);

  const handleSearchInputChange = (e) => {
    setKeyword(e.target.value);
  };

  const handleSearchKeyDown = (e) => {
    if (e.key === 13 || e.key === "Enter") {
      getProjectStatuses();
    }
  };
  return (
    <div className="org-homepage-container">
      <Snacky snackprops={snackBarProps} setSnackBarProps={setSnackBarProps} />
      <div>
        <p className="org-homepage-header">{`Hello ${user.firstName},`}</p>
        <p className="org-homepage-subheader">{getWelcomeText()}</p>
        <div className="org-homepage-search-header">
          <div className="container">
            <div className="default-text-field-container column-1">
              <TextField
                label="Global Search"
                variant="filled"
                fullWidth
                value={keyword}
                size="small"
                onChange={handleSearchInputChange}
                onKeyDown={handleSearchKeyDown}
              />
            </div>
            <div>
              <Button className="default-button" onClick={toggleHiddenStatuses}>
                {showHidden ? "Show active projects" : "Show hidden projects"}
              </Button>
            </div>
            {(departmentFromQuery ?? user.department)?.toLowerCase() === "the social club" && (
              <div>
                <Button
                  className="default-button"
                  onClick={() => {
                    history.push(`/Campaign/MasterStatus`);
                  }}
                >
                  Master Influencer List
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="org-homepage-body">
        {loading && (
          <div>
            <Skeleton height={100} />
            <Skeleton height={100} />
            <Skeleton height={100} />
            <Skeleton height={100} />
            <Skeleton height={100} />
          </div>
        )}
        {!loading &&
          companies &&
          companies.map((company) => (
            <HomepageClientCard
              key={`${company.companyId}-client-card`}
              client={company}
              updateProjectStatus={updateProjectStatus}
              hideProjectStatus={hideProjectStatus}
              unhideProjectStatus={unhideProjectStatus}
              showHidden={showHidden}
              department={departmentFromQuery ?? user.department}
            />
          ))}
      </div>
    </div>
  );
};

export default OrganisationHomepage;
