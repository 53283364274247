export const providerList = (props) => {
  let providerArray = [];
  if (props.companies && props.people) {
    if (props.providerType === "Contractor") {
      props.companies.forEach((company) => {
        let provider = {};
        provider.Type = "Company";
        provider.Name = company.name;
        provider.Email = "dev@magnesium.nz";
        provider.Id = company.id;
        providerArray.push(provider);
      });
      props.people.forEach((person) => {
        if (person.personCategory !== "Staff") {
          let provider = {};
          provider.Type = "Person";
          provider.Name = person.fullName;
          provider.Email = person.Email;
          provider.Id = person.id;
          providerArray.push(provider);
        }
      });
    } else if (props.providerType === "Staff") {
      props.people.forEach((person) => {
        if (person.personCategory === "Staff") {
          let provider = {};
          provider.Type = "Person";
          provider.Name = person.fullName;
          provider.Email = person.Email;
          provider.Id = person.id;
          providerArray.push(provider);
        }
      });
    }
  }
  return providerArray;
};

export const taskOrExpense = (props) => {
  if (props.budgetItems) {
    var budgetItemArray = [];
    if (props.providerType === "Contractor") {
      props.budgetItems.forEach((budgetItem) => {
        if (budgetItem.itemType === "Expense") {
          budgetItemArray.push(budgetItem);
        }
      });
    } else if (props.providerType === "Staff") {
      props.budgetItems.forEach((budgetItem) => {
        if (budgetItem.itemType === "Task") {
          budgetItemArray.push(budgetItem);
        }
      });
    }
  }
  return budgetItemArray;
};

export const contractorOrStaff = (props, prePostString = "") => {
  if (props.scheduleItems) {
    if (prePostString !== "PrePost") {
      if (props.providerType === "Contractor") {
        var contractorsArr = [];
        for (let i = 0; i < props.scheduleItems.length; i++) {
          if (
            props.scheduleItems[i].isClient === 0 &&
            props.scheduleItems[i].isStaff === 0
          ) {
            contractorsArr.push(props.scheduleItems[i]);
          }
        }
        console.log(contractorsArr);
        return contractorsArr;
      } else if (props.providerType === "Staff") {
        var staffArr = [];
        for (let i = 0; i < props.scheduleItems.length; i++) {
          if (props.scheduleItems[i].isStaff === 1) {
            staffArr.push(props.scheduleItems[i]);
          }
        }
        return staffArr;
      } else if (props.providerType === "Talent") {
        var talentArr = [];
        for (let i = 0; i < props.scheduleItems.length; i++) {
          if (props.scheduleItems[i].isStaff === 2) {
            talentArr.push(props.scheduleItems[i]);
          }
        }
        return talentArr;
      }
    } else {
      var talentArr = [];
      for (let i = 0; i < props.scheduleItems.length; i++) {
        if (
          props.scheduleItems[i].isStaff === 2 ||
          (props.scheduleItems[i].isClient === 0 &&
            props.scheduleItems[i].isStaff === 0)
        ) {
          talentArr.push(props.scheduleItems[i]);
        }
      }
      return talentArr;
    }
  }
};
