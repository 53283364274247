import React, { Fragment, useEffect, useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Collapse,
  IconButton,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { format } from "date-fns";
import ProjectOutputsTable from "./ProjectOutputsTable";
import { useHistory } from "react-router-dom";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontWeight: "bold",
  },
  body: {
    // fontSize: 14,
    // maxWidth: "50px",
    // height: "50px",
    // overflow: "hidden"
    padding: "20px",
    overflow: "hidden",
    width: "100px",
  },
  root: {
    padding: "0 15px",
    maxHeight: "50px",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);

const HomeTableRow = ({
  project,
  selectedProject,
  setSelectedProject,
  selectedOutput,
  setSelectedOutput,
  handleDeliverOutputDialog,
  getAllProjectActiveItems,
  resetActiveItemStore,
  openAllProjects,
  displayOverdueWarning,
  lockProductionCore
}) => {
  const history = useHistory();
  const [openProjectOutputs, setOpenProjectOutputs] = useState(false);

  useEffect(() => {
    if (openAllProjects) {
      setOpenProjectOutputs(true);
    } else {
      setOpenProjectOutputs(false);
    }
  }, [openAllProjects]);

  const goToProject = (e) => {
    e.preventDefault();
    history.push(
      `/project/home/${project.id.toString().split("-").length == 1
        ? project.id
        : project.id.split("-")[2]
      }/0`
    );
  };

  return (
    <Fragment>
      <StyledTableRow
        onClick={e => (lockProductionCore && project.daysOverdue === 0 && project.project.isInternalProject !== 1) ? displayOverdueWarning() : goToProject(e)}//
        style={(lockProductionCore && project.daysOverdue > 0 && project.project.isInternalProject !== 1) ? { backgroundColor: "#ffcccb" } : {}}//
        // onClick={e => goToProject(e)}
        // style={{}}
      >
        <StyledTableCell>
          <IconButton aria-label="expand row" size="small">
            {openProjectOutputs ? (
              <KeyboardArrowUpIcon
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedProject(null);
                  setSelectedOutput(null);
                  setOpenProjectOutputs(false);
                }}
              />
            ) : (
              <KeyboardArrowDownIcon
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedProject(project.project);
                  setSelectedOutput(null);
                  getAllProjectActiveItems(project.id);
                  setOpenProjectOutputs(true);
                }}
              />
            )}
          </IconButton>
        </StyledTableCell>
        <StyledTableCell>{`${project.projectName}${project.jobId ? ` [${project.jobId}]` : ""}`}</StyledTableCell>
        <StyledTableCell>{project.projectTag}</StyledTableCell>
        <StyledTableCell>{project.customerName}</StyledTableCell>
        <StyledTableCell>
          {project.nextDeadline &&
            format(new Date(project.nextDeadline), "iiii, dd MMM yyyy")}
        </StyledTableCell>
        <StyledTableCell>{project.daysToDeadline}</StyledTableCell>
      </StyledTableRow>
      <StyledTableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={openProjectOutputs} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <ProjectOutputsTable
                project={project.project}
                outputs={project.outputs}
                setSelectedOutput={setSelectedOutput}
                selectedOutput={selectedOutput}
                handleDeliverOutputDialog={handleDeliverOutputDialog}
                selectedProject={selectedProject}
                setSelectedProject={setSelectedProject}
              />
            </Box>
          </Collapse>
        </TableCell>
      </StyledTableRow>
    </Fragment>
  );
};

export default HomeTableRow;
