import React from "react";
import Spinner from "../../Shared/Spinner/Spinner";

const PayableFileViewer = ({ payable, loadingPdf, setLoadingPdf }) => {
  return (
    <React.Fragment>
      {loadingPdf && (
        <Spinner loaderClass="dot-windmill" />
      )}
      <div
        className="reconciliation-payable-row-iframe-container"
        style={{ display: loadingPdf && "none" }}
      >
        <iframe
          allow="clipboard-read; clipboard-write"
          src={`https://drive.google.com/file/d/${payable.driveId}/preview`}
          className={"reconciliation-payable-row-pdf-iframe"}
          width={"100%"}
          height={"100%"}
          onLoad={() => setLoadingPdf(false)}
          title={`document-image-${payable.driveId}`}
        ></iframe>
      </div>
    </React.Fragment>
  );
};

export default PayableFileViewer;
